import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
// import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
/* For localization */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TreeModule } from '@ali-hm/angular-tree-component';
// import { ChartModule } from 'angular2-chartjs';
// import { TextMaskModule } from 'angular2-text-mask';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { MultiselectDropdownModule } from '../assets/plugin/angular-2-dropdown-multiselect';
// import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserMapComponent } from './browser-map/browser-map.component';
// import { DummyComponent } from './common/components/dummy/dummy.component';
// import { KnownIssuesComponent } from './common/components/known-issues/known-issues.component';
// import { LocationUpdateComponent } from './common/components/location-update/location-update.component';
import { ModalComponent, ModalContentComponent } from './common/components/modal/modal.component';
import { TerminateUrlSesstionComponent } from './common/components/terminate-url-sesstion/terminate-url-sesstion.component';
import { UnauthorizedPageComponent } from './common/components/unauthorized-page/unauthorized-page.component';
import { AutofocusDirective } from './common/directives/autofocus.directive';
import { ScrollableDirective } from './common/directives/scrollableDirective';
// import { ViewMoreDirective } from './common/directives/view-more-directive.directive';
import { LoaderModule } from './common/modules/loader/loader.module';
import { NotificationModule } from './common/modules/notification';
// import { OrderModule } from './common/modules/order-pipe/ngx-order.module';
import { RangeSliderModule } from './common/modules/range-slider/range-slider.module';
import { ReadMoreModule } from './common/modules/read-more/read-more.module';
// import { TextAnalysisLimiterModule } from './common/modules/text-analysis-limiter/text-analysis-limiter.module';
// import { ReplaceDate } from './common/pipes/accessedDateReplace.pipe';
import { TitlecasePipe } from './common/pipes/titlecase.pipe';
import { FacetService } from './common/services/facet.service';
// import { SearchService } from './common/services/search.service';
import { UrlUtilityService } from './common/services/url-utility.service';
import { UtilityService } from './common/services/utility.service';
// import { DragonImageViewerComponent } from './dragon-image-viewer/dragon-image-viewer.component';
import { DrillDownChartComponent } from './drill-down-chart/drill-down-chart.component';
// import { FilterControlComponent } from './filter-control/filter-control.component';
import { HeaderComponent } from './header/header.component';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { LandingPageWrapperComponent } from './landing-page-wrapper/landing-page-wrapper.component';
import { MultiselectDropdownComponent } from './multiselect-dropdown/multiselect-dropdown.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
// import { SearchResultByContentTypeComponent } from './search-result-by-content-type/search-result-by-content-type.component';
// import { SearchResultDetailsLeftmenuComponent } from './search-result-details-leftmenu/search-result-details-leftmenu.component';
// import { SearchResultDetailsWrapperComponent } from './search-result-details-wrapper/search-result-details-wrapper.component';
// import { SearchResultDetailsComponent } from './search-result-details/search-result-details.component';
// import { SearchResultGraphBySubjectComponent } from './search-result-graph-by-subject/search-result-graph-by-subject.component';
import { WileyBodyComponentComponent } from './wiley-body-component/wiley-body-component.component';
import { WileySearchResultWrapperComponent } from './wiley-search-result-wrapper/wiley-search-result-wrapper.component';
import { WileySearchResultComponent } from './wiley-search-result/wiley-search-result.component';
// tslint:disable-next-line:max-line-length
import { WileySearchresultContentComponentComponent } from './wiley-searchresult-content-component/wiley-searchresult-content-component.component';
// tslint:disable-next-line:max-line-length
import { WileySearchresultLeftmenuComponentComponent } from './wiley-searchresult-leftmenu-component/wiley-searchresult-leftmenu-component.component';
import { FlimStripComponent } from './flim-strip/flim-strip.component';
// import {NgxToggleModule} from 'ngx-toggle';
import { NgToggleModule } from '@nth-cloud/ng-toggle';
import { KeysPipe } from './common/pipes/keyValuePair.pipe';
// import { NewImageViewerFrameComponent } from './new-image-viewer-frame/new-image-viewer-frame.component';
// import { LazyLoadImageModule } from '../assets/Js/ng-lazyload-image';
import { Attributes, LazyLoadImageModule } from 'ng-lazyload-image';
import { AuthInterceptor } from './auth.interceptor';
import { interval } from 'rxjs';
// import { Attributes } from '../assets/Js/ng-lazyload-image/src/types';
import { UnicodeEncodeEntityPipe } from './common/pipes/filter';
import { FooterModule } from './common/modules/footer/footer.module';
// import { ExplorerModule } from './explorer/explorer.module';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './custom-url-serializer';
import { VersionCheckService } from './common/services/version-check.service';
import { ExplorerCoverflowComponent } from './search-result-details/explorer-coverflow/explorer-coverflow.component';
// import { MapModule } from './common/modules/map/map.module';
// import { HighlightSelectDirective } from './common/directives/highlight-select.directive';
// import { ModalfocusDirective } from './common/directives/modalfocus.directive';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { OsdImageViewerComponent } from './osd-image-viewer/osd-image-viewer.component';
// import { ChartsModule } from 'ng2-charts';
// import { DisplayStringPipe } from './common/pipes/display-string.pipe';
import { GlobalErrorHandler } from './global-error-handler';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ShareDocumentComponent } from './share-document/share-document.component';
// import { DeviceDetectorModule } from 'ngx-device-detector';
// import { UnicodeConverterModule } from './unicode/unicode-converter/unicode-converter.module';
import { GoogleAnalyticsService } from './common/services/google-analytics.service';
import { SharedModule } from './common/modules/shared/shared.module';
// import { UndermaintenanceComponent } from './undermaintenance/undermaintenance.component';
import { DisableRightClickService } from './common/services/disable-right-click.service';
// import { FindPagesComponent } from './find-pages/find-pages.component';
// import { FindpagesDownloadContentComponent } from './findpages-download-content/findpages-download-content.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask'
import { NgChartjsModule } from 'ng-chartjs';
// import { ShareCollectionModule } from './common/modules/share-collection/share-collection.module';


// import CustomUrlSerializer from './custom-url-serializer';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?t=' + new Date().getTime());
}
// This will trigger an event every second
export function getObservable(attributes: Attributes) {
  return interval(1000);
}
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   //  suppressScrollY: true,
//   //  suppressScrollX: true,
//   useBothWheelAxes: true,
//   minScrollbarLength: 50

// };
// This will trigger an callback if the image is not loaded
export function setErrorImage({ element, errorImagePath, useSrcset }) {
  if (localStorage.getItem('accessType') !== 'guest') {
    location.reload();
  }
  element.src = errorImagePath;
}

@NgModule({
  declarations: [
    AppComponent,
    // ImageViewerFrameComponent,
    // NewImageViewerFrameComponent,
    HeaderComponent,
    // DragonImageViewerComponent,
    // ImageViewerFrameOldComponent,
    LandingPageWrapperComponent,
    WileyBodyComponentComponent,
    WileySearchResultWrapperComponent,
    // WileySearchresultContentComponentComponent,
    WileySearchresultLeftmenuComponentComponent,
    WileySearchResultComponent,
    // FilterControlComponent,
    TitlecasePipe,
    // MultiselectDropdownComponent,
    // SearchFilterComponent,
    DrillDownChartComponent,
    // ImageSwipeSliderComponent,
    // SearchResultDetailsLeftmenuComponent,
    // SearchResultDetailsComponent,
    // SearchResultDetailsWrapperComponent,
    // ViewMoreDirective, 
    ModalComponent, ModalContentComponent,
    // BrowserMapComponent,
    // KnownIssuesComponent,
    AutofocusDirective,
    // SearchResultByContentTypeComponent,
    // SearchResultGraphBySubjectComponent,
    ScrollableDirective,
    UnauthorizedPageComponent,
    // ReplaceDate,
    // PhotoExplorerComponent,
    // LocationUpdateComponent,
    // DummyComponent,
    TerminateUrlSesstionComponent,
    FlimStripComponent,
    KeysPipe,
    // SafePipe,
    ExplorerCoverflowComponent,
    // HighlightSelectDirective,
    // ModalfocusDirective,
    DocumentViewerComponent,
    OsdImageViewerComponent,
    // DisplayStringPipe,
    // DecodeEntitiesDirective,
    UnicodeEncodeEntityPipe,
    // UndermaintenanceComponent,
    // FindPagesComponent,
    // FindpagesDownloadContentComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),
    // MultiselectDropdownModule,
    // TextMaskModule,
    TooltipModule.forRoot(),
    // ChartModule,
    TreeModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    // LeafletModule,
    // LeafletDrawModule,
    // OrderModule,
    NotificationModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    ReadMoreModule,
    LoaderModule,
    // TextAnalysisLimiterModule,
    RangeSliderModule,
    PaginationModule.forRoot(),
    // NgxToggleModule,
    NgToggleModule,
    LazyLoadImageModule,
    FooterModule,
    // ExplorerModule,
    // MapModule,
    // ChartsModule,
    // BaseChartDirective,
    NgChartjsModule,
    TypeaheadModule,
    // UnicodeConverterModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    // ShareCollectionModule,
    ShareDocumentComponent,
    // HeatMapModule
    BrowserMapComponent,
    WileySearchresultContentComponentComponent,
    SearchFilterComponent,
    MultiselectDropdownComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: 'Window', useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // SearchService,
    UrlUtilityService,
    UtilityService,
    FacetService,
    DisableRightClickService,
    // SearchResultDetailService,
    // RequestCacheService,
    // { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    // { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    VersionCheckService,
    GoogleAnalyticsService,
    DeviceDetectorService,
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
