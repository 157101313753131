/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
export class AdvancedFilterParams {
  contentType: Array<any>;
  illustrationType: Array<any>;
  docSubType: Array<any>;
  contentCollection: Array<any>;
  languages: Array<any>;
  contentSources: Array<any>;
  subject: Array<any>;
  publishedDateFrom: string;
  publishedDateTo: string;
  searchKeywords: Array<any>;
  publishedDatePrefix: string;
  publishedDateIncludesNull: boolean;
  archive: Array<any>;
  groupLimit: number;
  currentOffset: number;
  currentManuscriptsOffset: any;
  currentMonographsOffset: number;
  currentPhotographsOffset: number;
  currentContentTypeOffset: number;
  sortFields: any;
  facetFields: any;

  constructor(obj?: any) {
    const defaultSearchKeys = [{
      fieldName: 'ALL',
      fieldValue: '',
      operator: 'AND'
    }, {
      fieldName: 'ALL',
      fieldValue: '',
      operator: 'AND'
    }, {
      fieldName: 'ALL',
      fieldValue: '',
      operator: 'AND'
    }];
    if (obj) {
      this.contentType = obj.contentType ? obj.contentType : [];
      this.illustrationType = obj.illustrationType ? obj.illustrationType : [];
      this.docSubType = obj.docSubType ? obj.docSubType : [];
      this.contentCollection = obj.contentCollection ? obj.contentCollection : [];
      this.contentSources = obj.contentSources ? obj.contentSources : [];
      this.languages = obj.languages ? obj.languages : [];
      this.subject = obj.subject ? obj.subject : [];
      this.publishedDateFrom = obj.publishedDateFrom ? obj.publishedDateFrom : '';
      this.publishedDateTo = obj.publishedDateTo ? obj.publishedDateTo : '';
      this.publishedDatePrefix = obj.publishedDatePrefix ? obj.publishedDatePrefix : '';
      this.publishedDateIncludesNull = obj.publishedDateIncludesNull ? obj.publishedDateIncludesNull : false;
      this.searchKeywords = Array.isArray(obj.searchKeywords) && obj.searchKeywords.length > 0 ? obj.searchKeywords : defaultSearchKeys;
      this.archive = Array.isArray(obj.archive) && obj.archive.length > 0 ? obj.archive : [];
      this.groupLimit = obj.groupLimit ? obj.groupLimit : 5;
      this.currentOffset = obj.currentOffset ? obj.currentOffset : 0;
      this.currentManuscriptsOffset = obj.currentManuscriptsOffset ? obj.currentManuscriptsOffset : 0;
      this.currentMonographsOffset = obj.currentMonographsOffset ? obj.currentMonographsOffset : 0;
      this.currentPhotographsOffset = obj.currentPhotographsOffset ? obj.currentPhotographsOffset : 0;
      this.currentContentTypeOffset = obj.currentContentTypeOffset ? obj.currentContentTypeOffset : 0;
      this.sortFields = obj.sortFields ? obj.sortFields : {};
      if (this.searchKeywords.length < 3) {
        do {
          this.searchKeywords.push({
            fieldName: 'ALL',
            fieldValue: '',
            operator: 'AND'
          });
        } while (this.searchKeywords.length < 3);
      }
      this.facetFields = obj.facetFields ? obj.facetFields : [];
    } else {
      this.contentType = [];
      this.illustrationType = [];
      this.docSubType = [];
      this.contentCollection = [];
      this.contentSources = [];
      this.languages = [];
      this.subject = [];
      this.publishedDateFrom = '';
      this.publishedDateTo = '';
      this.publishedDatePrefix = '';
      this.publishedDateIncludesNull = false;
      this.searchKeywords = defaultSearchKeys;
      this.archive = [];
      this.groupLimit = 5;
      this.currentOffset = 0;
      this.currentManuscriptsOffset = 0;
      this.currentMonographsOffset = 0;
      this.currentPhotographsOffset = 0;
      this.currentContentTypeOffset = 0;
      this.sortFields = {};
      this.facetFields = [];
    }
  }
}
