
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { APIURLS } from '../../../environments/environment';
import { CollocatesService } from './collocates.service';
import { Subject } from 'rxjs';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../common/services/sharedService';
import { TranslateService, LangChangeEvent, TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';

@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule, PopoverModule],
  selector: 'app-collocates',
  templateUrl: './collocates.component.html',
  styleUrls: ['./collocates.component.css'],
  providers: [CollocatesService]
})
export class CollocatesComponent implements OnInit, OnDestroy, AfterViewInit {
  stop$: Subject<any> = new Subject();
  lang: string;
  loading: Boolean = true;
  sessioninput: any;
  filterArchive: any = [];
  currentTerms: any = [];
  termsArray: any = [];
  analysisRows: any = [];
  archiveRowsArray: any = [];
  isArabicLanguage: Boolean = false;
  setArchives: any = [];
  archiveArray: any = [];
  _filterInputs: any;
  private subjectObserver: Subject<void> = new Subject<void>();
  _advancedFilterParam: any;
  newFilterParams: any;
  @Input('filterInputs')
  set filterInputs(value) {
    this._filterInputs = value;
    this._advancedFilterParam = this._filterInputs.advancedFilterParam;
    if (this._filterInputs.preventLimiters) {
      this._filterInputs.filterParams.filterParams = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
    }
  }
  resultset: any = {};
  constructor(
    private httpClient: HttpClient,
    private collocatesService: CollocatesService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
      if (this._filterInputs.terms.length > 0) {
          if (this._filterInputs.termsFromSession.length > 0) {
            this.getfilterData();
          }  else {
            this.getfilterData();
          }
        } else {
        // this.getJsonData();
      }
    }
     getfilterData() {
        this._filterInputs.terms.forEach(elementNew => {
          this.resultset[elementNew] = {};
          this.resultset[elementNew].loading = true;
          this.newFilterParams = this._filterInputs.filterParams.filterParams;
          if (this.newFilterParams.length > 0) {
            for(let k =0; k < this.newFilterParams.length; k++) {
              if(this.newFilterParams[k].fieldName === "archive") {
               const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
               const haveRGS = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RGS'); 
               if (haveRGS) {
                const haveRGS1 = getSelectedArchives.includes('RGS1');
                if(haveRGS1) {
                  this.newFilterParams[k].fieldValues.push('RGS1');
                }
                const haveRGS2 = getSelectedArchives.includes('RGS2');
                if(haveRGS2) {
                  this.newFilterParams[k].fieldValues.push('RGS2');
                }
                this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RGS',this.newFilterParams[k].fieldValues);
               }
               const haveRCP = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RCP'); 
               if (haveRCP) {
                const haveRCP1 = getSelectedArchives.includes('RCP1');
                if(haveRCP1) {
                  this.newFilterParams[k].fieldValues.push('RCP1');
                }
                const haveRCP2 = getSelectedArchives.includes('RCP2');
                if(haveRCP2) {
                  this.newFilterParams[k].fieldValues.push('RCP2');
                }
                this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RCP',this.newFilterParams[k].fieldValues);
               }
              }
            }
            if(this.newFilterParams) {
              this._filterInputs.filterParams.filterParams = this.newFilterParams;
            }
          }

          this.commonService.getAnalysisDetailsForAdvancedSearch( this._filterInputs.filterParams, elementNew)
            .takeUntil(this.subjectObserver).subscribe(response  => {
              if (response.analysisResponse) {
                this.resultset[elementNew] = {'label': elementNew, 'result' : response.analysisResponse[0]};
              }
              this.resultset[elementNew].loading = false;
            }, (err) => {this.resultset[elementNew].loading = false; });
        });
     }
     removeSelectedValFromArray(val, arr) {
      let value = val;
      arr = arr.filter(item => item !== value)
      return arr;
    }

     getJsonData() {
      this.httpClient.get<CollocatesArchive>(APIURLS.getArchiveCollocation).pipe(
        takeUntil(this.subjectObserver)).subscribe(response => {
         this.setArchives = response.analysisResponse;
         this.setArchives.forEach(element => {
           if (element.rows != null) {
              this.archiveArray.push(element);
           }
         });
       }, (err) => { });
     }


  ngAfterViewInit() {
    this.translate.onLangChange.pipe(takeUntil(this.subjectObserver)).subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      // tslint:disable-next-line:max-line-length
      takeUntil(this.subjectObserver)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });

  }

  gotoDocumentDetailsPage(searchTerm, collocate) {
    sessionStorage.setItem('tatOldTerm', this._filterInputs['searchTerm']);
    sessionStorage.setItem('isFromAnalysisHubPage', 'true');
    this.collocatesService.gotoDocumentDetailsPage(searchTerm, collocate, this._filterInputs.filterParams);
  }
  ngOnDestroy(): void {
    this.subjectObserver.next();
    this.subjectObserver.complete();
  }

}
export interface CollocatesArchive {
  analysisResponse: Array<Collocatesresponse>;
}
export interface Collocatesresponse {
  archive: String;
  rows: Array<{collocate: string, count: number, term: string}>;
}
