<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<!-- <div class="top_nav" *ngIf="pageContent">
<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <ng-container *ngIf="!removeWithSearch">
      <div class="toggle-filterdiv row" *ngIf="pageContent.showLeftMenu">
        <div class="col-md-10 col-sm-11 toggle-filterdivleft padding-left-0 padding-right-0">
          <label [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.FILTERS' | translate }}</label>
        </div>
        <div class="col-md-2 col-sm-1 toggle-filterright toggle-filterright-arrow padding-left-0 padding-right-0">
          <a title="{{ 'SearchResult.FILTERLeft' | translate }}" class="sidebar-toggle" role="button" tabindex="0"
            (click)="toggleFilters()" (keyup.enter)="toggleFilters()">
            <span class="spriteico leftToggleIcon"></span>
          </a>
        </div>
      </div>
      <div class="toggle-filterrightdiv" *ngIf="!pageContent.showLeftMenu">
        <a title="{{ 'SearchResult.FILTERRight' | translate }}" class="sidebar-toggle" tabindex="0"
          (click)="toggleFilters()" (keyup.enter)="toggleFilters()" role="button">
          <span class="spriteico rightToggleIcon"></span>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="removeWithSearch">
      <div class="toggle-filterdiv" >
        <label class="downTitle">Download Content</label>
      </div>
      <div class="toggle-filterrightdiv" *ngIf="!pageContent.showLeftMenu">
        <a title="{{ 'SearchResult.FILTERRight' | translate }}" class="sidebar-toggle" tabindex="0"
          (click)="toggleFilters()" (keyup.enter)="toggleFilters()" role="button">
          <span class="spriteico rightToggleIcon"></span>
        </a>
      </div>
    </ng-container>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="mynavbar">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)">Link</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)">Link</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)">Link</a>
        </li>
      </ul>
      <form class="d-flex">
        <input class="form-control me-2" type="text" placeholder="Search">
        <button class="btn btn-primary" type="button">Search</button>
      </form>
    </div>
  </div>
</nav>
</div> -->



@if (pageContent) {
  <div class="top_nav">
    <div class="nav_menu">
      <div class="row">
        @if (!removeWithSearch && pageContent.showLeftMenu) {
          <div class="col-md-3 pe-0">
            <nav>
              <div class="toggle-filterdivleft padding-left-0 padding-right-0">
                <label [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.FILTERS' | translate }}</label>
              </div>
              <div class="toggle-filterright toggle-filterright-arrow padding-left-0 padding-right-0">
                <a title="{{ 'SearchResult.FILTERLeft' | translate }}" class="sidebar-toggle" role="button" tabindex="0"
                  (click)="toggleFilters()" (keyup.enter)="toggleFilters()">
                  <span class="spriteico leftToggleIcon"></span>
                </a>
              </div>
            </nav>
          </div>
        }
        @if (removeWithSearch) {
          <div class="col-md-3 pe-0">
            <nav>
              <div class="fw-bold">
                <label class="downTitle">Download Content</label>
              </div>
              <!-- <div class="toggle-filterrightdiv" *ngIf="!pageContent.showLeftMenu">
              <a title="{{ 'SearchResult.FILTERRight' | translate }}" class="sidebar-toggle" tabindex="0"
                (click)="toggleFilters()" (keyup.enter)="toggleFilters()" role="button">
                <span class="spriteico rightToggleIcon"></span>
              </a>
            </div> -->
          </nav>
        </div>
      }
      <div [ngClass]="(!removeWithSearch && pageContent.showLeftMenu) ? 'col-md-9': removeWithSearch ? 'col-md-9':'col-md-12'">
        <!-- <div [ngClass]="!removeWithSearch && pageContent.showLeftMenu ? 'col-md-9' : 'col-md-12'"> -->
        @if (!removeWithSearch && !pageContent.showLeftMenu) {
          <nav>
            <div class="toggle-filterrightdiv">
              <a title="{{ 'SearchResult.FILTERRight' | translate }}" class="sidebar-toggle" tabindex="0"
                (click)="toggleFilters()" (keyup.enter)="toggleFilters()" role="button">
                <span class="spriteico rightToggleIcon"></span>
              </a>
            </div>
          </nav>
        }
        <form name="simpleSearchForm" [ngClass]="removeWithSearch ? 'float-end': ''">
          <div class="filter-search">
            <div class="d-flex" [class.checkFilter]="pageContent.showLeftMenu">
              @if (!removeWithSearch) {
                <span class="input-group-addon search-checkbox d-flex">
                  <label [class.arabic-style]="isArabicLanguage" class="radio-inline showall-container"
                    title="{{ 'SearchResult.SearchWithin' | translate }}" for="searchin"
                    [ngClass]="{'active-filter': pageContent.isSearchWithIn, 'inactive-filter':!pageContent.isSearchWithIn}">
                    <input tabindex="0" [class.arabic-style]="isArabicLanguage" type="radio" name="optradio" id="searchin"
                      [checked]="pageContent.isSearchWithIn" [disabled]="!hasResults" [value]="true"
                      (change)="searchSettingChange($event.target.value)">{{ 'SearchResult.SearchWithin' | translate }}
                      <span class="showall-checkmark"></span>
                    </label>
                    <label [class.arabic-style]="isArabicLanguage" class="radio-inline showall-container"
                      title="{{ 'SearchResult.NewSearch' | translate }}" for="newsearch"
                      [ngClass]="{'active-filter': !pageContent.isSearchWithIn, 'inactive-filter':pageContent.isSearchWithIn}">
                      <input tabindex="0" type="radio" name="optradio" id="newsearch" [value]="false"
                        [checked]="!pageContent.isSearchWithIn" [class.arabic-style]="isArabicLanguage"
                        (change)="searchSettingChange($event.target.value)">{{ 'SearchResult.NewSearch' | translate }}
                        <span class="showall-checkmark"></span>
                      </label>
                    </span>
                  }
                  @if (!removeWithSearch) {
                    <label [class.arabic-style]="isArabicLanguage" class="sr-only"
                    for="search_textbox">{{ 'AdvancedSearch.WhatAreYouSearchingFor' | translate }}</label>
                  }
                  @if (pageContent.isSearchWithIn && !removeWithSearch) {
                    <input tabindex="0" [class.arabic-style]="isArabicLanguage"
                      aria-label="What are you searching for?" name="docSearch" appAutofocus
                      title="{{ 'Common.Popups.searchWithInPlaceHolder' | translate }}" type="text" id="search_textbox"
                      class="form-control filter-search-texbox seach-input" [placeholder]="pageContent?.showAdvancedFilter ? performingAdvSearch: searchWithinPlaceHolder"
                      [value]="searchWithinKeyWord ? searchWithinKeyWord : ''" #searchTermValue autocomplete="off"
                      [disabled]="pageContent?.showAdvancedFilter">
                  }
                  @if (!pageContent.isSearchWithIn  && !removeWithSearch ) {
                    <div class="searchParent">
                    <input tabindex="0" [class.arabic-style]="isArabicLanguage"
                      aria-label="What are you searching for?" name="docSearch" appAutofocus
                      title="{{ 'AdvancedSearch.WhatAreYouSearchingFor' | translate }}" type="text" id="search_textbox"
                      class="form-control filter-search-texbox seach-input b"
                      [placeholder]="pageContent?.showAdvancedFilter ? performingAdvSearch:  newSearchPlaceHolder"
                      [value]="searchTerm ? searchTerm : ''" #searchTermValue autocomplete="off"
                      [disabled]="pageContent?.showAdvancedFilter" (input)="onSearchChange($event.target.value)" >
                      @if (suggestedSearchTerms?.length > 0 || spellCheckSearchTerms?.length > 0) {
                        <div class="pharse-div searchOperator" [class.floatLeft]="isChangeOperator !==''">
                          @if (spellCheckSearchTerms?.length > 0) {
                            <div class="spell-check-div">
                              <span class="text-means">Did you mean "<span class="spell-check-text" (click)="spellCheckSearchTerm(spellCheckSearchTerms)">{{spellCheckSearchTerms}}</span>"  ? </span>
                            </div>
                          }
                          @if (spellCheckSearchTerms?.length === 0 && isChangeOperator !=='' && !typeaheadLoading && !noSuggestedTermsFound && !showAddQuotesOn) {
                            <div>
                              <span class="text-means">Did you mean "<span>{{isChangeOperator}}</span>" Operator ? </span>
                              @if (!ischangeToOperators) {
                                <span class="operator-text" (click)="changeToOperators()"> Yes</span>
                              }
                              @else {
                                <span class="operator-text" (click)="changeToOperators()"> No</span>
                              }
                            </div>
                          }
                          @if (suggestedSearchTerms?.length > 0) {
                            <div [class.wholeStringBreak]="isChangeOperator !==''">
                              <label class="switch">
                                <input type="checkbox" (change)="toggle($event)">
                                <span class="slider round">
                                  <span class="slider-info-on" [class.on-btn]="showAddQuotesOn"> ON </span>
                                  <span class="slider-info-off" [class.on-btn]="!showAddQuotesOn" > OFF </span>
                                </span>
                              </label>
                              <span class="pharse-string-txt"> Search whole string</span>
                              <ul class="suggested-terms-list" [style.width]="suggestedTermsListWidth"
                                [class.change-operator]="isChangeOperator !=='' && !typeaheadLoading && !noSuggestedTermsFound">
                                @for (suggestedterm of suggestedSearchTerms; track suggestedterm; let i = $index) {
                                  <li class="suggested-list" [class.active-suggested-term]="currentIndex === i + 1" #suggestedTerms
                                    tabindex="{{i + 1}}"
                                    (mouseenter)="suggestedTermsMouseEnter(suggestedterm.term, i + 1)"
                                    (mouseleave)="suggestedTermsMouseLeave(suggestedterm.term, i + 1)" (click)="typeaheadOnSelect(suggestedterm.term, i + 1)">
                                    <p class="sugg-term" title="{{ suggestedterm.term }}"
                                    > {{suggestedterm.term}} </p>
                                  </li>
                                }
                              </ul>
                            </div>
                          }
                        </div>
                      }
                      <!-- @if (suggestedSearchTerms?.length > 0) {
                        
                      } -->
                      @if (typeaheadLoading) {
                        <div class="suggested-loading-text">{{'HomePage.suggestedTermLoadingText' | translate}}</div>
                      }
                      @if (noSuggestedTermsFound && !typeaheadLoading) {
                        <div class="suggested-loading-text">{{'HomePage.noSuggestedTerms' | translate}}</div>
                      }
                    </div>

                    }
                  <!-- Revise button -->
                  <div class="dropdown-box text-end parent searchbox-revise d-flex">
                    @if (!removeWithSearch) {
                      <span class="helpIcon" type="submit" tabindex="1" (click)="openHelp($event);"
                        (keyup.enter)="openHelp($event);">
                        <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                          title="{{ 'Common.Help' | translate }}">
                          <i class="spriteico helpIcon"></i>
                        </a>
                      </span>
                    }
                    <button [class.arabic-style]="isArabicLanguage"
                      title="{{ 'Common.REVISE' | translate }} ({{controlShortcut}} + J)" type="button"
                      class="btn btn-secondary btn-adv-search" tabindex="0" (keyup.enter)="revise();$event.stopPropagation();"
                      (click)="revise();$event.stopPropagation();" id="menu1">{{'Common.REVISE' | translate }}
                    </button>
                    <app-search-filter [page-content]="pageContent" [show-filter]="pageContent?.showAdvancedFilter"
                    (filter-close)="advFilterClosed($event)" [customeClass]='fromSearchResult' (disable-search)="disableSearch($event)" (estimated-count)="estimatedCount($event)"></app-search-filter>
                    <div class="help-container" id="help_box" [class.show-help]="showHelp" (click)="$event.stopPropagation();">
                      <div class="help-scrollbar">
                        <!-- <perfect-scrollbar [config]="config" class="help-scrollbar"> -->
                        <p [class.arabic-style]="isArabicLanguage">
                          <b>{{ 'AdvancedSearch.BasicSearch' | translate }}</b> -
                          {{ 'AdvancedSearch.BasicSearchContent' | translate }}
                        </p>
                        <p [class.arabic-style]="isArabicLanguage">{{ 'AdvancedSearch.Examples' | translate }}:</p>
                        <ul [class.arabic-style]="isArabicLanguage">
                          <li [class.arabic-style]="isArabicLanguage">
                            <b>{{ 'AdvancedSearch.SimpleSearch' | translate }}</b>:
                            <span [class.arabic-style]="isArabicLanguage"
                            class="underline">{{ 'AdvancedSearch.ExecutiveCommittee' | translate }} </span> ({{ 'AdvancedSearch.ReturnsDocuments' | translate
                            }})
                          </li>
                          <li [class.arabic-style]="isArabicLanguage">
                            <b>{{ 'AdvancedSearch.SimpleSearch' | translate }}</b>
                            ({{ 'AdvancedSearch.UsingQuotes' | translate }}):
                            <span [class.arabic-style]="isArabicLanguage"
                            class="underline">"{{ 'AdvancedSearch.ExecutiveCommittee' | translate }}" </span>({{ 'AdvancedSearch.RetrievesOnlyDocuments'
                          | translate }})</li>
                          <li [class.arabic-style]="isArabicLanguage">
                            <b>{{ 'AdvancedSearch.BooleanSearch' | translate }}</b>
                            {{ 'AdvancedSearch.usingANDORNOT' | translate }}:
                            <span [class.arabic-style]="isArabicLanguage"
                            class="underline">{{ 'AdvancedSearch.ExecutiveORcommittee' | translate }}</span>
                          </li>
                          <li [class.arabic-style]="isArabicLanguage">
                            <b>{{ 'AdvancedSearch.ProximitySearch' | translate }}</b>:
                            <span [class.arabic-style]="isArabicLanguage"
                            class="underline">"{{ 'AdvancedSearch.ExecutiveCommittee' | translate }}"~3</span> ({{ 'AdvancedSearch.matchesCommitteewithin3words'
                          | translate }})</li>
                          <li [class.arabic-style]="isArabicLanguage">
                            <b>{{ 'AdvancedSearch.ExactMatch' | translate }}</b>:
                            <span [class.arabic-style]="isArabicLanguage" class="underline">"Color"</span>
                          ({{ 'AdvancedSearch.DoNotUseFuzzySearch' | translate }})</li>
                          <li [class.arabic-style]="isArabicLanguage">
                            <b>{{ 'AdvancedSearch.WildcardSearch' | translate }}</b> (* -
                            {{ 'AdvancedSearch.AnyNumber' | translate }},
                            ? - {{ 'AdvancedSearch.OneChar' | translate }}, ! - {{ 'AdvancedSearch.OneOrNoChar' | translate }}):
                            <span [class.arabic-style]="isArabicLanguage"
                            class="underline">{{ 'AdvancedSearch.MistakeColor' | translate }}</span>
                          </li>
                        </ul>
                        <p [class.arabic-style]="isArabicLanguage">
                          <b>{{ 'AdvancedSearch.Tips' | translate }}:</b>
                        </p>
                        <ul>
                          <li [class.arabic-style]="isArabicLanguage">{{ 'AdvancedSearch.YouDoNotNeedToEnter' | translate }},
                            <span class="underline">Résumé</span> {{ 'AdvancedSearch.IsSameAs' | translate }}
                            <span class="underline">Resume</span>
                          </li>
                          <li [class.arabic-style]="isArabicLanguage">{{ 'AdvancedSearch.StopWordsSuchAs' | translate }} "a",
                            "an", "the" etc. {{ 'AdvancedSearch.AreIgnored' |
                          translate }}</li>
                        </ul>
                      <!-- </perfect-scrollbar> -->
                    </div>
                  </div>
                </div>
                <!-- Search BUtton -->
                <span class="input-group-btn search-input-group-btn">
                  <button [class.arabic-style]="isArabicLanguage" class="btn btn-primary filter-search-btn"
                    id="simpleSearchBtn" type="submit" [disabled]="pageContent?.showAdvancedFilter && checkEstimatedCount === 0"
                    (click)="simpleSearch()" tabindex="0" (keyup.enter)="simpleSearch()"
                    title="{{ 'Common.Search' | translate }}">
                    <i class="fa fa-search" style="display:inline"></i>
                    <span [class.arabic-style]="isArabicLanguage"
                    class="search-text">&nbsp;{{ 'Common.CapsSEARCH' | translate }}</span>
                  </button>
                </span>
                <!-- search suggestion code start -->
               
                
                <!-- search suggestion code end -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    @if (!removeWithSearch) {
      <div class="search-contenttype-dispaly">
        <div class="row">
          <div class="col-md-9 c-type-left">
            <ul class="c-type" id="myDiv">
              @if (contentTypesFacets.length > 0) {
                <li [class.arabic-style]="isArabicLanguage" class="pull-left">{{ 'Common.ContentType' | translate }}: </li>
              }
              @for (type of contentTypesFacets; track type; let i = $index) {
                <li class="order{{i+1}}"
                  (click)="showContentType(type)" (keyup.enter)="showContentType(type)">
                  <span class="i-{{type.value}}-icons fa "></span>
                  <a (contextmenu)="disableRightClick($event)" tabindex="0" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                    title="{{type.value | titlecase}}" class="contenttype-link">{{type.value | titlecase}}
                    <span [class.arabic-style]="isArabicLanguage" class="count-badge">({{type.count}})</span>
                  </a>
                </li>
              }
            </ul>
            @if (contentTypesFacets.length > 0) {
              <ul class="drop-downDiv">
                <li class="mobileContentType">
                  <!-- <a (contextmenu)="disableRightClick($event)" href="#" class="profile-image" > -->
                  <button type="button" class="btn contentButton dropdown-toggle" (contextmenu)="disableRightClick($event)" id="dropdownMenuContentType" data-bs-toggle="dropdown" aria-expanded='false'>
                    @if (!selectedContentType) {
                      <span [class.arabic-style]="isArabicLanguage">
                      {{ 'Common.ContentType' | translate }} </span>
                    }
                    @else {
                      <span>
                        <span [class.arabic-style]="isArabicLanguage" title="{{ 'Common.ContentType' | translate }}"
                          class="contenttype-link">{{selectedContentType}}
                        </span>
                      </span>
                    }
                    <!-- <b class="caret"></b> -->
                    <i class="fa fa-caret-down" style="float: right;"></i>
                  </button>
                <!-- </a> -->
                <ul class="dropdown-menu" [class.arabic-style]="isArabicLanguage" aria-labelledby="dropdownMenuContentType">
                  @for (type of contentTypesFacets; track type; let i = $index) {
                    <li (click)="showContentType(type)"
                      class="{{type.value}}-1">
                      <span class="i-{{type.value}}-icons fa "></span>
                      <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage" title="{{ 'Common.ContentType' | translate }}"
                        class="contenttype-link">{{type.value | titlecase}}
                        <span [class.arabic-style]="isArabicLanguage" class="count-badge">({{type.count}})</span>
                      </a>
                    </li>
                  }
                </ul>
              </li>
            </ul>
          }
        </div>
        <div class="col-md-3 c-type-right">
          <form class="form-inline pull-right">
            <div class="form-group" align="center">
              <label [class.arabic-style]="isArabicLanguage" class="sortBy"
              for="sel1">{{ 'Sort.SortBy' | translate }}</label>
              <span class="custom-dropdown big" [class.isFirefox]="isFirefox">
                <label [class.arabic-style]="isArabicLanguage" for="relevance_sort" class="sr-only">{{ 'Sort.Relevance' | translate }}</label>
                <select tabindex="0" [class.arabic-style]="isArabicLanguage"
                  class="form-select firefoxText" title="{{ 'Sort.SortBy' | translate }} ({{shortcutKey}} + R)"
                  id="relevance_sort" [ngModel]="currentSort" [ngModelOptions]="{standalone:true}"
                  (change)="onSortOrderChange($event.target.value)">
                  <option [class.arabic-style]="isArabicLanguage" value="">{{ 'Sort.Relevance' | translate }} </option>
                  <option [class.arabic-style]="isArabicLanguage" value="asc">{{ 'Sort.OldestFirst' | translate }}
                  </option>
                  <option [class.arabic-style]="isArabicLanguage" value="desc">{{ 'Sort.LatestFirst' | translate }}
                  </option>
                </select>
              </span>
              <i class="relevanceIcon" title="{{ 'Sort.relevanceRanking' | translate }}" tabindex="0"
              (click)="openRelevanceHelp($event);" (keyup.enter)="openRelevanceHelp($event);"></i>
              <div class="relevance-help-container" [class.show-showRelevanceHelp]="showRelevanceHelp"
                (click)="$event.stopPropagation();">
                <div class="help-scrollbar">
                  <p [class.arabic-style]="isArabicLanguage">
                    <span> {{ 'Sort.relevanceContent' | translate }} </span>
                    <span class="wiley-primary-text-color"> {{ 'Sort.term' | translate }} </span>
                    <span> {{ 'Sort.relevanceContentcontinues' | translate }} </span>
                    <span class="wiley-primary-text-color"> {{ 'Sort.term' | translate }} </span>
                    <span> {{ 'Sort.relevanceContentcontinuesText' | translate }} </span>
                    <span class="wiley-primary-text-color"> {{ 'Sort.relevanceContentText' | translate }} </span>
                    <span> {{ 'Sort.document' | translate }}, </span>
                    <span class="wiley-primary-text-color"> {{ 'Sort.overallRelevanceSearch' | translate }} </span>
                    <span> {{ 'Sort.priorityOrderText' | translate }} </span>
                  </p>
                  <ul [class.arabic-style]="isArabicLanguage">
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.DocumentTitle' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.fullText' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'HomePage.publicationDate' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.itemCollectionTitle' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.authors' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.documentItemnotes' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.subjectsKeywords' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.contentTypeSubtype' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.Language' | translate }} </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Sort.sourceInstitutionAndLocation' | translate }}
                      </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage">
                      <span [class.arabic-style]="isArabicLanguage">{{ 'Detail.wileyDocumentNumber' | translate }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  }
  <input type="text" id="handleFocusOnSuggestedTerms" autocomplete="off" style="position: absolute;width: 0;left: 0;opacity: 0;" />
</div>
}
