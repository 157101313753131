/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from './http.service';
import { environment, APIURLS } from './../../../environments/environment';
import { get as _get } from 'lodash';

@Injectable()
export class FacetService {
    public static facetObservable: Subject<any>;

    private cache: any = {};

    constructor(
        private httpService: HttpService
    ) {
        FacetService.facetObservable = new Subject();
    }

    getFacetByLatLon(lat: number, lon: number, radius?: number) {
        const key = this.getCacheKey(lat, lon, radius);
        // if (this.cache[key]) {
        //     return Observable.of(this.cache[key]);
        // }

        const payload = {
            'spatialFilterParams': [{
                'fieldName': 'latlon',
                'shape': 'circle',
                'radius': (radius / 1000) || '5.0',
                'location': {
                    'lat': lat,
                    'lon': lon
                }
            }]
        };

        this.httpService.post(environment.APIUrl + APIURLS['spatialSearch'], payload, response => {
            const facetValues = this.parseResult(response);
            if (facetValues.length) {
                this.cache[key] = facetValues;
            }
            FacetService.facetObservable.next(facetValues);
        }, err => {
            FacetService.facetObservable.next([]);
        });
    }

    getFacetByLatLonRange(lat1, lon1, lat2, lon2, type?, radius?) {
        const key = this.getCacheKey(lat1, lon1, lat2, lon2);
        // if (this.cache[key]) {
        //     return Observable.of(this.cache[key]);
        // }
        let payload: any;
        if (radius) {
            payload = {
                'spatialFilterParams': [{
                    'fieldName': 'latlon',
                    'shape': 'circle',
                    'radius': (radius / 1000) || '5.0',
                    'location': {
                        'lat': lon1,
                        'lon': lat1
                    }
                }]
            };
        }else {
            payload = {
                'spatialFilterParams': [{
                    'fieldName': 'latlon',
                    'shape': type || 'rectangle',
                    'lowerLeft': {
                        'lat': lon2,
                        'lon': lat2
                    },
                    'topRight': {
                        'lat': lon1,
                        'lon': lat1
                    }
                }]
            };
        }
       this.httpService.post(environment.APIUrl + APIURLS['spatialSearch'], payload, res => {
           const facetValues = this.parseResult(res);
           if (facetValues.length) {
                   this.cache[key] = facetValues;
               }
           FacetService.facetObservable.next(facetValues);
        }, err => {
        });
    }

    parseResult(response: any): any[] {
        const facetResults = _get(response, 'results.facetResults', []);

        const groupedFacetValues = facetResults.filter((facet) => {
            return facet.facetField === 'contentType';
        }).map((facet) => {
            return facet.facetContent;
        }).reduce((group, facetContents) => {
            facetContents.forEach((facetContent) => {
                const _key = facetContent.value;
                group[_key] = (group[_key] || 0) + facetContent.count;
            });
            return group;
        }, {});

        if (groupedFacetValues) {
            return Object.keys(groupedFacetValues).map((group) => {
                return {
                    value: group,
                    count: groupedFacetValues[group]
                };
            });
        }

        return [];
    }

    getCacheKey(...args): string {
        return args.join('_');
    }
}
