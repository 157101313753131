<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<div class="col-md-12 contenttype-chart">
  <div class="barChartContainer" id="chartContainer" >
    <div id="tooltipForContentType">
      <div  id = 'tooltipContentDivision' [innerHtml]="tooltipContent">
      </div>
    </div>
    <!-- <canvas id="barchart" #byContentChart>
  </canvas> -->
  <!-- <canvas *ngIf="lineChartData.length > 0" baseChart width="400" height="400"
  [datasets]="lineChartData"
  [labels]="xAxisLabel"
  [options]="lineChartOptions"
  [legend]="lineChartLegend"
  [colors]="lineChartColors"
  [chartType]="lineChartType"
  (chartClick)="chartClicked($event)">
</canvas> -->
@if (lineChartData && lineChartData.length > 0) {
  <canvas ngChartjs
    [datasets]="lineChartData"
    [labels]="xAxisLabel"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [colors]="lineChartColors"
    [chartType]="lineChartType"
    [inlinePlugins]="inlinePlugin"
    (chartClick)="chartClicked($event)">
  </canvas>
}
</div>
</div>
@if (byContentTypeYearFrom && byContentTypeYearTo) {
  <div class="col-md-12 slider-control term-frequency_slider">
    <app-range-slider [(ngModel)]="byContentTypeRangeArray" [value]="byContentTypeRangeArray" [minValue]="byContentTypeYearFromChart"
      (RangeChanged)="byContentTypeSliderRangeChanged($event)" [maxValue]="byContentTypeYearToChart" [stepValue]="1" [range]="true"
    name="rangeArrayN"></app-range-slider>
    @if (byContentTypeYearFrom && byContentTypeYearTo) {
      <span>{{byContentTypeYearFrom}} - {{byContentTypeYearTo}}</span>
    }
  </div>
}

