
import {takeUntil} from 'rxjs/operators';

/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import {
  Component,
  Input,
  TemplateRef,
  ElementRef,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  AfterContentInit,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription ,  Subject } from 'rxjs';
import { PageSettingsService } from './../common/services/page-settings.service';
import { SearchService } from './../common/services/search.service';
import { HttpService } from './../common/services/http.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalService } from '../common/services/modal.service';
import { SharedService } from '../common/services/sharedService';
import { environment, APIURLS, GenericWarningArchives, availableArchives } from '../../environments/environment';
import { map as _map, orderBy as _orderBy, uniq as _uniq } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../common/services/common.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { DefaultLangChangeEvent } from '@ngx-translate/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import * as $ from '../../../node_modules/jquery';
import { HttpClient } from '@angular/common/http';

export const values = {
  tat: 'TAT',
  browseMap: 'browse',
  explorer: {
    photo: 'photo',
    map: 'map'
  }
};

@Component({
  selector: 'app-wiley-body-component',
  templateUrl: './wiley-body-component.component.html',
  styleUrls: ['./wiley-body-component.component.css']
})
export class WileyBodyComponentComponent implements AfterContentInit, OnInit, OnDestroy {
  // private downloadPDFmodalRef: BsModalRef;
  // @ViewChild('downloadDocsTemplate')
	// private downloadDocsTemplate: TemplateRef<any>;
  // @ViewChild('downloadDocsTemplate', { static: false }) private downloadDocsTemplate: TemplateRef<any>;

  // moreBAASContentLink = '../assets/BAAS_Complete_Content_List.xlsx';
  rgsArchives = [];
  rcpArchives = [];
  addSessionArchies = false;
  // showMoreBAASContentLink = false;
  showJisclogo = false;
  // spellCheckSearchTerms: any = [];
  isChangeOperator: String = '';
  currentIndex = 1;
  currLanguage: any;
  matchedOperator: any = [];
  // @ViewChild('suggestedTerms') suggestedTerms: ElementRef;
  @ViewChild('suggestedTerms', { static: false }) suggestedTerms: ElementRef;
  typeaheadLoading: boolean;
  suggestedTimeOut: any;
  otherSearchArg: any;
  isWileyLibraryHelp: Boolean = false;
  searchTermwithSpaces: any;
  private photoMapsExplorerRef: BsModalRef;
  // @ViewChild('photoMapsExplorerTemplate') private photoMapsExplorerTemplate: TemplateRef<any>;
  // @ViewChild('homepageSeach') private homepageSeach: TabsetComponent;
  // @ViewChild('searchKeyId') searchKeyId: ElementRef;
  // @ViewChild('warningsTemplate') private warningsTemplate: TemplateRef<any>;
  @ViewChild('photoMapsExplorerTemplate', { static: false }) private photoMapsExplorerTemplate: TemplateRef<any>;
  @ViewChild('homepageSeach', { static: false }) private homepageSeach: TabsetComponent;
  @ViewChild('searchKeyId', { static: false }) searchKeyId: ElementRef;
  @ViewChild('warningsTemplate', { static: false }) private warningsTemplate: TemplateRef<any>;

  // tslint:disable-next-line:no-input-rename
  @Input('option-html') private optionHTML: TemplateRef<any>;
  @Output() isAdvanceSearchOpen = new EventEmitter<boolean>();
  isArabicLanguage: Boolean = false;
  availableContentTypes: any = [];
  // documentIdList:any= [];
  selectedArchives: any;
  // isArchiveChanged: any;
  // isMultiSelectValueChanged: boolean;
  activateAdvSearch: any;
  selectArchive: any;
  isResetFilter = false;
  downloadDocs:	any[];
  estimatedCount: any = 0;
  actiateBasicSearch = true;
  unSubTranlate2: Subscription;
  unSubTranlate: Subscription;
  // defaultperformingAdvSearch: any = 'Performing Advanced Search';
  performingAdvSearch: any = 'Performing Advanced Search';
  searchPlaceholder: any = 'What are you searching for?';
  disableSimpleSearch = false;
  copySearchTerm: any;
  // searchWithAdvParams = false;
  unSubPageSettingsObservable: any;
  unSubDropdownToggle: Subscription;
  message: string;
  titleName: string;
  controlShortcut: any;
  operationSystem: any;
  // iosPlatforms: any;
  // windowsPlatforms: any;
  // macosPlatforms: any;
  // platform: any;
  // userAgent: any;
  helpDropDown: any;
  isFiltertoggle = false;
  private contentType: String;
  private searchKey: String;
  private currentArchive: any;
  // private currentLocation: any;
  showHelp: Boolean = false;
  isadvancedSearchOpenCheck: any;
  isMultiselectDropdownOpen: any;
  private homePageUrl: string;
  isCollapsed: Boolean = true;
  pageContent: any;
  // private paramsObservable: Subscription;
  // private errorMsg: string;
  warningObj: Array<any> = [];
  private warningssmodalRef: BsModalRef;
  scrollDisplay: any;
  // private toggleWarning: Boolean = false;
  // private toggleRAIWarning = false;
  authPage = false;
  // popupconfirmContent = '';
  // config = {
  //   animated: true,
  //   keyboard: false,
  //   backdrop: true,
  //   ignoreBackdropClick: false
  // };
  // modalRef: any;
  archivesDropdownText: String;
  showPubDate: boolean;
  controlShift: any;
  dropdownValue: any;
  isBrowseByContentTypePhoto: Boolean = false;
  isBrowseByContentTypeMap: Boolean = false;
  isShowWarningPopup: Boolean = false;
  showLoadingTxt: Boolean = false;
  showFilter: Boolean = false;
  getFilterValue: any;
  stop$: Subject<void> = new Subject();
  suggestedSearchTerms: Array<string> = [];
  suggestedTermsListWidth: any;
  // showAddPharseBtn: Boolean = false;
  // isProceedSubmitSearch: Boolean = false;
  noSuggestedTermsFound: boolean;
  isDownArrowClicked: boolean;
  showAddQuotesOn: Boolean = false;
  ischangeToOperators: Boolean = false;
  bothRCPAvailable: boolean;
  bothRGSAvailable: boolean;
  wileySubscribedArchives = [];
  unsubscribedArchives: string[];
  // haveShortNameRGS1 = false;
  // haveShortNameRCP1 = false;
//   tabs = [
//     { title: 'First', content: 'First Tab Content' },
//     { title: 'Second', content: 'Second Tab Content', active: true },
//     { title: 'Third', content: 'Third Tab Content', removable: true },
//     { title: 'Four', content: 'Fourth Tab Content', disabled: true }
//  ];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private pageSettingService: PageSettingsService,
    private httpService: HttpService,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    this.modalService.showLoader();
    localStorage.removeItem('fromAdditionalArchive');
    localStorage.removeItem('wileyDCSelectedArchive');
    sessionStorage.removeItem('explorerDropDownLimiter');
    // sessionStorage.removeItem('explorerMapDropDownLimiter');
    // to avoid the unwanted click in header part (loader)
    // this.modalService.showLoader();
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      if (localStorage.getItem('accessType') === 'guest') {
        sessionStorage.clear();
        localStorage.clear();
        this.httpService.stopAllServices();
        this.router.navigate([`/${environment.defaultArchive}/auth`]);

      }
    });

    this.unSubTranlate2 = this.translate.get('HomePage').pipe(takeUntil(this.stop$)).subscribe((res: any) => {
      this.searchPlaceholder = res.searchPlaceholder || 'What are you searching for?';
    });
    // const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
    // this.bothRCPAvailable = personalizationData.bothRCPAvailable;
    // this.bothRGSAvailable = personalizationData.bothRGSAvailable;

    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.pageContent = data;
      // if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      //   if (this.bothRCPAvailable) {
      //     this.pageContent.customerArchives = this.pageContent.customerArchives.filter(item => item.shortName !== 'RCP1');
      //     this.pageContent.customerArchives = this.pageContent.customerArchives.filter(item => item.shortName !== 'RCP2');
      //   }
      //   if (this.bothRGSAvailable) {
      //     this.pageContent.customerArchives = this.pageContent.customerArchives.filter(item => item.shortName !== 'RGS1');
      //     this.pageContent.customerArchives = this.pageContent.customerArchives.filter(item => item.shortName !== 'RGS2');
      //   }
      //   const rcpObject = {
      //     'logo': 'assets/config/rcp/rcp_logo.png',
      //     'name': 'The Royal College of Physicians',
      //     'shortName': 'RCP',
      //     'trialArchive': false
      //   };
      //   const rgsObject = {
      //     'logo': 'assets/config/rgs/rgs-logo-other.png',
      //     'name': 'Royal Geographical Society (with IBG)',
      //     'shortName': 'RGS',
      //     'trialArchive': false
      //   };
      //   if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      //     let isRGSavailable = false;
      //     let isRCPAvailable = false;

      //     this.pageContent.customerArchives.forEach(element => {
      //       if (element.shortName === 'RGS') {
      //         isRGSavailable = true;
      //       }
      //       if (element.shortName === 'RCP') {
      //         isRCPAvailable = true;
      //       }
      //     });

      //     if (this.bothRCPAvailable && !isRGSavailable && !isRCPAvailable) {
      //       this.pageContent.customerArchives.push(rcpObject);
      //     }
      //     if (this.bothRGSAvailable && !isRGSavailable && !isRCPAvailable) {
      //       this.pageContent.customerArchives.push(rgsObject);
      //     }
      //   }
      // }
      if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
        const rcpObject = {
          'shortName': 'RCP',
          'name': 'The Royal College of Physicians',
          'trialArchive': false,
          'logo': 'assets/config/rcp/rcp_logo.png',
        };
        const rgsObject = {
          'shortName': 'RGS',
          'name': 'The Royal Geographical Society (with IBG)',
          'trialArchive': false,
          'logo': 'assets/config/rgs/rgs-logo-other.png',
        };
        const getlocalVal = JSON.parse(localStorage.getItem('personalizationData'));
        if(getlocalVal.bothRCPAvailable && getlocalVal.bothRGSAvailable){
          for(let k=0; k< this.pageContent.customerArchives.length;k++){
          if (this.pageContent.customerArchives[k].shortName === 'RCP1') {
              this.pageContent.customerArchives.push(rcpObject);
            } else if(this.pageContent.customerArchives[k].shortName === 'RGS1') {
              this.pageContent.customerArchives.push(rgsObject);
            }
          }
        } else {
          if (getlocalVal.bothRCPAvailable){
            for(let k=0; k< this.pageContent.customerArchives.length;k++){
              if (this.pageContent.customerArchives[k].shortName === 'RCP1') {
                  this.pageContent.customerArchives.push(rcpObject);
              }
            }
          } else {
            for(let k=0; k< PageSettingsService.pageSettings.customerArchives.length; k++) {
              if(PageSettingsService.pageSettings.customerArchives[k].shortName === "RCP1") {
                const rcp1Object = {
                  'shortName': 'RCP',
                  'name': 'The Royal College of Physicians - Part I (1101 – 1862)',
                  'trialArchive': false,
                  'logo': 'assets/config/rcp/rcp_logo.png',
                };
                this.pageContent.customerArchives.push(rcp1Object);
              } else if(PageSettingsService.pageSettings.customerArchives[k].shortName === "RCP2") {
                const rcp2Object = {
                  'shortName': 'RCP',
                  'name': 'The Royal College of Physicians - Part II (1863 – 2000)',
                  'trialArchive': false,
                  'logo': 'assets/config/rcp/rcp_logo.png',
                };
                this.pageContent.customerArchives.push(rcp2Object);
              }
            }
          }
          if (getlocalVal.bothRGSAvailable) {
            for(let k=0; k< this.pageContent.customerArchives.length;k++){
             if(this.pageContent.customerArchives[k].shortName === 'RGS1') {
                  this.pageContent.customerArchives.push(rgsObject);
                }
              }
          } else {
            for(let k=0; k< PageSettingsService.pageSettings.customerArchives.length; k++) {
              if(PageSettingsService.pageSettings.customerArchives[k].shortName === "RGS1") {
                const rgs1Object = {
                  'shortName': 'RGS',
                  'name': 'The Royal Geographical Society (with IBG) - Part I (1485 -1899)',
                  'trialArchive': false,
                  'logo': 'assets/config/rgs/rgs-logo-other.png',
                };
                this.pageContent.customerArchives.push(rgs1Object);
              } else if(PageSettingsService.pageSettings.customerArchives[k].shortName === "RGS2") {
                const rgs2Object = {
                  'shortName': 'RGS',
                  'name': 'The Royal Geographical Society (with IBG) - Part II (1900 - 1983)',
                  'trialArchive': false,
                  'logo': 'assets/config/rgs/rgs-logo-other.png',
                };
                this.pageContent.customerArchives.push(rgs2Object);
              }
            }
          }
        }
        // for(let k=0; k< PageSettingsService.pageSettings.customerArchives.length; k++) {
        //   if(PageSettingsService.pageSettings.customerArchives[k].shortName === "RCP1") {
        //     this.haveShortNameRCP1 = true;
        //     this.pageContent.customerArchives.push(rcpObject);
        //   } else if(PageSettingsService.pageSettings.customerArchives[k].shortName === "RCP2") {
        //     if(!this.haveShortNameRCP1) {
        //       this.pageContent.customerArchives.push(rcpObject);
        //     }
        //   } else if(PageSettingsService.pageSettings.customerArchives[k].shortName === "RGS1") {
        //     this.haveShortNameRGS1 = true;
        //     this.pageContent.customerArchives.push(rgsObject);
        //   } else if(PageSettingsService.pageSettings.customerArchives[k].shortName === "RGS2") {
        //    if (!this.haveShortNameRGS1) {
        //     this.pageContent.customerArchives.push(rgsObject);
        //    }
        //   }
        // }
        }
        this.pageContent.customerArchives = this.removeSelectedValFromArray('RCP1',this.pageContent.customerArchives);
        this.pageContent.customerArchives = this.removeSelectedValFromArray('RCP2',this.pageContent.customerArchives);
        this.pageContent.customerArchives = this.removeSelectedValFromArray('RGS1',this.pageContent.customerArchives);
        this.pageContent.customerArchives = this.removeSelectedValFromArray('RGS2',this.pageContent.customerArchives);
        this.pageContent.availableFields = _orderBy(this.pageContent.availableFields, ['itemName'], ['asc']);
    });
    //setTimeout(() => {

    // }, 1000);

    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      const currentParams = JSON.parse(JSON.stringify(params));
      const checkparamValue = currentParams['display'];
      this.scrollDisplay = checkparamValue;
    });
    this.translate.get('HomePage.archivesDropdownTitle').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.archivesDropdownText = res;
    });

  }
  removeSelectedValFromArray(val, arr) {
    let value = val;
    arr = arr && arr.filter(item => item.shortName !== value)
    return arr;
  }
  ngAfterContentInit() {
    // setTimeout(() => {
    //   // to avoid the unwanted click in header part
    //   this.modalService.hideLoader();
    // }, 2500);
    // setTimeout(() => {
    //   // if (document.querySelector('.grecaptcha-badge')) {
    //   //   (document.querySelector('.grecaptcha-badge') as HTMLElement).style.visibility = 'hidden';
    //   //   (document.querySelector('.grecaptcha-badge') as HTMLElement).style.opacity = '0';
    //   // }
    //   this.modalService.hideLoader();
    // }, 3500);
    const browseMapVisitedCount = sessionStorage.getItem('browseMapVisitedCount');
    if (browseMapVisitedCount) {
      sessionStorage.removeItem('browseMapVisitedCount');
    }
    this.sharedService.language.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    });
    this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.dropdownValue = res;
      if (this.dropdownValue.help === true) {
        this.showHelp = true;
      } else {
        this.showHelp = false;
      }
      if (this.dropdownValue.wolhelp === true) {
        this.isWileyLibraryHelp = true;
      } else {
        this.isWileyLibraryHelp = false;
      }
    });
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.operationSystem = 'Shift + Alt';
      this.controlShift = 'Ctrl + Shift';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.operationSystem = 'Shift + Option';
      this.controlShift = 'Cmd + Shift';
    }
    this.unSubTranlate = this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: any) => {
      this.selectArchive = res.selectArchive;
      this.performingAdvSearch = res.performingAdvSearch || 'Performing Advanced Search';
    });
    this.translate.onDefaultLangChange.pipe(takeUntil(this.stop$)).subscribe((event: DefaultLangChangeEvent) => {
      this.performingAdvSearch = event.translations.Common.performingAdvSearch || 'Performing Advanced Search';
      this.searchPlaceholder = event.translations.HomePage.searchPlaceholder || 'What are you searching for?';
    });
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      this.currLanguage = event.lang;
      if (event.translations.Common.performingAdvSearch) {
        this.performingAdvSearch = event.translations.Common.performingAdvSearch || 'Performing Advanced Search';
      }
      if (event.translations.HomePage.searchPlaceholder) {
        this.searchPlaceholder = event.translations.HomePage.searchPlaceholder || 'What are you searching for?';
      }
    });
    this.translate.get('warnings.rai.titleName').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.titleName = res;
    });
    this.translate.get('warnings.rai.message').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.message = res;
    });
    this.currentArchive = PageSettingsService.pageSettings.currentArchive;
    setTimeout(() => {
      this.homePageUrl = `/${(this.currentArchive + '').toLowerCase()}`;
    });
    sessionStorage.removeItem('mapView');
    this.unSubDropdownToggle = this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.helpDropDown = res.help;
    });
    setTimeout(() => {
      let response;
      const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
      if (getVal) {
          response = getVal;
          if (getVal) {
            getVal.forEach(element => {
              this.wileySubscribedArchives.push(element.shortName);
              if (element.shortName === 'RGS1') {
                this.rgsArchives.push(element);
              }
              if (element.shortName === 'RGS2') {
                this.rgsArchives.push(element);
              }
              if (element.shortName === 'RCP1') {
                this.rcpArchives.push(element);
              }
              if (element.shortName === 'RCP2') {
                this.rcpArchives.push(element);
              }
            });
            setTimeout(() => {
              this.unsubscribedArchives = availableArchives.filter(e => this.wileySubscribedArchives.indexOf(e) < 0);
              // sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
              // sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
      
              localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
              localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
            }, 1000);
            // if (sessionStorage.getItem('subscribedArchivesForFacet')) {
            //   sessionStorage.removeItem('subscribedArchivesForFacet')
            // }
            // if (localStorage.getItem('subscribedArchivesForFacet')) {
            //   localStorage.removeItem('subscribedArchivesForFacet')
            // }
            // sessionStorage.setItem('subscribedArchivesForFacet', JSON.stringify(getVal));
            // localStorage.setItem('subscribedArchivesForFacet', JSON.stringify(getVal));
            // setTimeout(() => {
            if (window.location.pathname.includes('shibboleth') && localStorage.getItem('auth_token')) {
              this.router.navigate(['wiley']);
            } else {
              if (this.currentArchive.toUpperCase() !== 'WILEY' &&
                window.location.pathname.split('/')[1].toUpperCase() !== 'RGS' &&
                window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
                const customerSubscribedArchives = _map(response, 'shortName');
                if (customerSubscribedArchives.indexOf(this.currentArchive.toUpperCase()) === -1) {
                  this.authPage = false;
                  this.router.navigate([this.homePageUrl + '/unauthorized-page']);
                } else {
                  this.authPage = true;
                }
              } else {
                const customerSubscribedArchives1 = _map(response, 'shortName');
                let currentArchiveName;
                if (this.currentArchive.toUpperCase() === 'RGS') {
                  if (customerSubscribedArchives1.includes('RGS1')) {
                    currentArchiveName = 'RGS1';
                  } else {
                    currentArchiveName = 'RGS2';
                  }
                } else if (this.currentArchive.toUpperCase() === 'RCP') {
                  if (customerSubscribedArchives1.includes('RCP1')) {
                    currentArchiveName = 'RCP1';
                  } else {
                    currentArchiveName = 'RCP2';
                  }
                }
                setTimeout(() => {
                  if ((this.currentArchive.toUpperCase() === 'WILEY' ||
                    customerSubscribedArchives1.includes(currentArchiveName.toUpperCase()))) {
                    this.authPage = true;
                  } else {
                    this.authPage = false;
                    this.router.navigate([this.homePageUrl + '/unauthorized-page']);
                  }
                }, 500);
              }
            }
          }
      } else {
        let locId = localStorage.getItem('currentLocation');
        this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + locId).pipe(
          takeUntil(this.stop$)).subscribe((res: any) => {
            response = res;
            if (res) {
              res.forEach(element => {
                this.wileySubscribedArchives.push(element.shortName);
                if (element.shortName === 'RGS1') {
                  this.rgsArchives.push(element);
                }
                if (element.shortName === 'RGS2') {
                  this.rgsArchives.push(element);
                }
                if (element.shortName === 'RCP1') {
                  this.rcpArchives.push(element);
                }
                if (element.shortName === 'RCP2') {
                  this.rcpArchives.push(element);
                }
              });
              setTimeout(() => {
                this.unsubscribedArchives = availableArchives.filter(e => this.wileySubscribedArchives.indexOf(e) < 0);
                // sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
                // sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
        
                localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
                localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
              }, 1000);
              // if (sessionStorage.getItem('subscribedArchivesForFacet')) {
              //   sessionStorage.removeItem('subscribedArchivesForFacet')
              // }
              // if (localStorage.getItem('subscribedArchivesForFacet')) {
              //   localStorage.removeItem('subscribedArchivesForFacet')
              // }
              // sessionStorage.setItem('subscribedArchivesForFacet', JSON.stringify(res));
              localStorage.setItem('subscribedArchivesForFacet', JSON.stringify(res));
              // setTimeout(() => {
              if (window.location.pathname.includes('shibboleth') && localStorage.getItem('auth_token')) {
                this.router.navigate(['wiley']);
              } else {
                if (this.currentArchive.toUpperCase() !== 'WILEY' &&
                  window.location.pathname.split('/')[1].toUpperCase() !== 'RGS' &&
                  window.location.pathname.split('/')[1].toUpperCase() !== 'RCP') {
                  const customerSubscribedArchives = _map(response, 'shortName');
                  if (customerSubscribedArchives.indexOf(this.currentArchive.toUpperCase()) === -1) {
                    this.authPage = false;
                    this.router.navigate([this.homePageUrl + '/unauthorized-page']);
                  } else {
                    this.authPage = true;
                  }
                } else {
                  const customerSubscribedArchives1 = _map(response, 'shortName');
                  let currentArchiveName;
                  if (this.currentArchive.toUpperCase() === 'RGS') {
                    if (customerSubscribedArchives1.includes('RGS1')) {
                      currentArchiveName = 'RGS1';
                    } else {
                      currentArchiveName = 'RGS2';
                    }
                  } else if (this.currentArchive.toUpperCase() === 'RCP') {
                    if (customerSubscribedArchives1.includes('RCP1')) {
                      currentArchiveName = 'RCP1';
                    } else {
                      currentArchiveName = 'RCP2';
                    }
                  }
                  setTimeout(() => {
                    if ((this.currentArchive.toUpperCase() === 'WILEY' ||
                      customerSubscribedArchives1.includes(currentArchiveName.toUpperCase()))) {
                      this.authPage = true;
                    } else {
                      this.authPage = false;
                      this.router.navigate([this.homePageUrl + '/unauthorized-page']);
                    }
                  }, 500);
                }
              }
            }
        });
      }
      const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
      if (personalizationData) {
        const archiveArry = _map(personalizationData.archives, 'shortName');
        const archives =
          PageSettingsService.pageSettings.currentArchive === 'WILEY' ? archiveArry : [PageSettingsService.pageSettings.currentArchive];
        if (archives.length > 0) {
          this.commonService.getDropdownLimiters(archives, res => {
            if (res) {
              this.availableContentTypes = _orderBy(res.availableContentTypes, ['name'], ['asc']);
            }
          });
        }
      } else {
        const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
        if (personalizationV2) {
          // this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] + localStorage.getItem('currentLocation') ||
          // PageSettingsService.pageSettings.currentLocation, (res) => {
            // if (res) {
              localStorage.setItem('personalizationData', JSON.stringify(personalizationV2));
              const archiveArry = _map(personalizationData.archives, 'shortName');
              const archives =
                PageSettingsService.pageSettings.currentArchive === 'WILEY' ?
                  archiveArry : [PageSettingsService.pageSettings.currentArchive];
              if (archives.length > 0) {
                this.commonService.getDropdownLimiters(archives, limiterRes => {
                  if (limiterRes) {
                    this.availableContentTypes = _orderBy(limiterRes.availableContentTypes, ['name'], ['asc']);
                  }
                });
              }
            // }
          // }, err => { });
        } else {
          this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] + localStorage.getItem('currentLocation') ||
          PageSettingsService.pageSettings.currentLocation, (res) => {
            if (res) {
              localStorage.setItem('personalizationData', JSON.stringify(response));
              const archiveArry = _map(personalizationData.archives, 'shortName');
              const archives =
                PageSettingsService.pageSettings.currentArchive === 'WILEY' ?
                  archiveArry : [PageSettingsService.pageSettings.currentArchive];
              if (archives.length > 0) {
                this.commonService.getDropdownLimiters(archives, limiterRes => {
                  if (limiterRes) {
                    this.availableContentTypes = _orderBy(limiterRes.availableContentTypes, ['name'], ['asc']);
                  }
                });
              }
            }
          }, err => { });
        }
      }
    }, 100);
    this.suggestedTermsListWidth = $('#searchid').width();
  }
  ngOnInit() {
    this.sharedService.updateFacetFlag(false);
    // if (sessionStorage.getItem('wileySubscribedArchives_tab1')) {
    //   sessionStorage.removeItem('wileySubscribedArchives_tab1');
    // }
    // if (sessionStorage.getItem('unsubscribedArchives_tab2')) {
    //   sessionStorage.removeItem('unsubscribedArchives_tab2');
    // }
    // if (localStorage.getItem('wileySubscribedArchives_tab1')) {
    //   localStorage.removeItem('wileySubscribedArchives_tab1');
    // }
    // if (localStorage.getItem('unsubscribedArchives_tab2')) {
    //   localStorage.removeItem('unsubscribedArchives_tab2');
    // }
    if (sessionStorage.getItem('SearchResultsPageURLFromSearch')) {
      sessionStorage.removeItem('SearchResultsPageURLFromSearch');
    }
    if (sessionStorage.getItem('currentSearchDocumentId')) {
      sessionStorage.removeItem('currentSearchDocumentId');
    }
    if (sessionStorage.getItem('currentOffsetIndex')) {
      sessionStorage.removeItem('currentOffsetIndex');
    }
    // let archivesArr: any;
    // const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
    //   if (getVal) {
    //     archivesArr = getVal;
    //     archivesArr.forEach(element => {
    //       this.wileySubscribedArchives.push(element.shortName);
    //       if (element.shortName === 'RGS1') {
    //         this.rgsArchives.push(element);
    //       }
    //       if (element.shortName === 'RGS2') {
    //         this.rgsArchives.push(element);
    //       }
    //       if (element.shortName === 'RCP1') {
    //         this.rcpArchives.push(element);
    //       }
    //       if (element.shortName === 'RCP2') {
    //         this.rcpArchives.push(element);
    //       }
    //     });
    //     setTimeout(() => {
    //       this.unsubscribedArchives = availableArchives.filter(e => this.wileySubscribedArchives.indexOf(e) < 0);
    //       sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
    //       sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
  
    //       localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
    //       localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
    //     }, 1000);
    //   } else {
    //     this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + localStorage.getItem('currentLocation')).pipe(takeUntil(this.stop$)).subscribe((res) => {
    //       archivesArr = res;
    //       archivesArr.forEach(element => {
    //         this.wileySubscribedArchives.push(element.shortName);
    //         if (element.shortName === 'RGS1') {
    //           this.rgsArchives.push(element);
    //         }
    //         if (element.shortName === 'RGS2') {
    //           this.rgsArchives.push(element);
    //         }
    //         if (element.shortName === 'RCP1') {
    //           this.rcpArchives.push(element);
    //         }
    //         if (element.shortName === 'RCP2') {
    //           this.rcpArchives.push(element);
    //         }
    //       });
    //       setTimeout(() => {
    //         this.unsubscribedArchives = availableArchives.filter(e => this.wileySubscribedArchives.indexOf(e) < 0);
    //         sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
    //         sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
    
    //         localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
    //         localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
    //       }, 1000);
    //     }, err => { });
    //   }
    if (window.location.pathname.includes('baas')) {
      this.showJisclogo = true;
    } else {
      this.showJisclogo = false;
    }
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }



  }
  getFilterFormValue(formValues) {
    this.isShowWarningPopup = formValues.status;
  }

  verifyAndGotoSearch(searchKey, contentType?: String, arg?: any) {
    let selectedArchives = JSON.parse(localStorage.getItem('wileySelectedArchive'));
    if (selectedArchives) {
      if (selectedArchives.length) {
        if (selectedArchives.includes('RCP')) {
          selectedArchives = [...selectedArchives, ...['RCP1', 'RCP2']];
          selectedArchives.splice(selectedArchives.indexOf('RCP'), 1);
          //.filter(item => item!=='RCP');
        }
        if (selectedArchives.includes('RGS')) {
          selectedArchives = [...selectedArchives, ...['RGS1', 'RGS2']];
          selectedArchives.splice(selectedArchives.indexOf("RGS"), 1);
        }
      }
    }

    if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
    } else {
      if (PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP') {
      } else {
        selectedArchives = [
          PageSettingsService.pageSettings.currentArchive
        ];
      }
    }
    // sessionStorage.setItem('wileySelectedArchive', JSON.stringify(selectedArchives));
    localStorage.setItem('wileySelectedArchive', JSON.stringify(selectedArchives));
    sessionStorage.removeItem('selectedContentType');
    // if (!this.isProceedSubmitSearch) {
    if (sessionStorage.getItem('wileySuggestedterms')) {
      sessionStorage.removeItem('wileySuggestedterms');
    }
    setTimeout(() => {
      if (contentType === 'Photographs') {
        this.isBrowseByContentTypePhoto = true;
        this.isBrowseByContentTypeMap = false;
        this.sharedService.checkContentTypePhoto(this.isBrowseByContentTypePhoto);
        this.sharedService.checkContentTypeMap(this.isBrowseByContentTypeMap);
      } else if (contentType === 'Maps') {
        this.isBrowseByContentTypeMap = true;
        this.isBrowseByContentTypePhoto = false;
        this.sharedService.checkContentTypeMap(this.isBrowseByContentTypeMap);
        this.sharedService.checkContentTypePhoto(this.isBrowseByContentTypePhoto);
      } else {
        this.isBrowseByContentTypeMap = false;
        this.isBrowseByContentTypePhoto = false;
        this.sharedService.checkContentTypeMap(this.isBrowseByContentTypeMap);
        this.sharedService.checkContentTypePhoto(this.isBrowseByContentTypePhoto);
      }
      // this.modalService.showLoader();
      let warning: any;
      this.searchKey = searchKey;
      this.searchKey = this.searchKey ? this.searchKey.trim() : '';
      this.contentType = contentType;
      this.otherSearchArg = arg;
      let crossArchive: any;
      crossArchive = PageSettingsService.pageSettings.currentArchive === 'WILEY';
      if (crossArchive) {
        if (contentType || arg) {
          const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
          warning = _map(personalizationData.archives, 'shortName');
        } else {
          warning = this.pageContent.currentSelectedArchives;
        }
      } else {
        warning = [PageSettingsService.pageSettings.currentArchive];
      }
      warning = warning instanceof Array ? warning : [warning];
      if (warning.length === 0) {
        this.modalService.showNotification('WARNING', 'Warning', this.selectArchive || 'Please select at least one archive.');
        // this.modalService.hideLoader();
        return;
      }
      const newArchives = [];
      const sessionWarning = sessionStorage.getItem('warning');
      let archives;
      if (sessionWarning) {
        archives = sessionWarning.split(',');
      } else { archives = []; }

      this.warningObj = [];
      this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(res => {
        /*
        * 1.get data from location config json, if the flag show is true then show the dialog
        * 2.display dialog only once per session
        * 3.if mutiple arhives has show flag as true then display all messages in same dialog
        * user gets the dialog archive 1 for first time then the settings were changed next time
        * it must show the last update archive warning with step1,2 and 3
        */
        warning = warning instanceof Array ? warning : [warning];
        warning.forEach(obj => {
          if (res.warnings.archives[obj]) {
            if (res.warnings.archives[obj].show) {
              if (sessionWarning) {
                if (sessionWarning.indexOf(obj) > -1) {
                  if (!(archives.indexOf(obj) > -1)) {
                    archives.push(obj);
                  }
                } else {
                  /*
                  * If the session warning object available and not exist in session warning array,
                  * push data in warningObj and keep copy of the object in archives array
                  *
                  * if it's cross archive and either has content type or other arguments will display generic message
                  * otherwise
                  * show archive specific warning
                  */
                  newArchives.push(obj);
                  if (crossArchive && (newArchives.length > 1 || contentType || arg)) {
                    if ((newArchives.indexOf('RAI') > -1 || newArchives.indexOf('RGS') > -1 || newArchives.indexOf('RCP') > -1)) {
                      this.warningObj = [{
                        message: res.warnings.archives[obj].crossArchiveMessage
                      }];
                      this.addSessionArchies = false;
                    } else {
                      this.warningObj = [];
                      this.addSessionArchies = true;
                    }
                  } else {
                    if (crossArchive && (newArchives.indexOf('RAI') > -1 ||
                      newArchives.indexOf('RGS') > -1 || newArchives.indexOf('RCP') > -1)) {
                      this.warningObj = [{
                        message: res.warnings.archives[obj].crossArchiveMessage
                      }];
                    } else {
                      if (crossArchive && (newArchives.indexOf('BAAS') > -1 || newArchives.indexOf('NYAS') > -1)) {
                        this.warningObj = [];
                        this.addSessionArchies = true;
                      } else {
                        this.warningObj.push({
                          title: res.warnings.archives[obj].titleName,
                          message: res.warnings.archives[obj].message
                        });
                      }
                    }
                  }
                }
              } else {
                /*
                * If warning not available in session, push data in warningObj and keep copy of the object in archives array
                * Show RGS cross search warning popup (if archives is crossArchive and RGS)
                */
                newArchives.push(obj);
                if (crossArchive && (newArchives.length > 1 || contentType || arg)) {
                  if ((newArchives.indexOf('RAI') > -1 || newArchives.indexOf('RGS') > -1 || newArchives.indexOf('RCP') > -1)) {
                    this.warningObj = [{
                      message: res.warnings.archives[obj].crossArchiveMessage
                    }];
                    this.addSessionArchies = false;
                  } else {
                    this.warningObj = [];
                    this.addSessionArchies = true;
                  }
                } else {
                  if (crossArchive && (newArchives.indexOf('RAI') > -1 ||
                    newArchives.indexOf('RGS') > -1 || newArchives.indexOf('RCP') > -1)) {
                    this.warningObj = [{
                      message: res.warnings.archives[obj].crossArchiveMessage
                    }];
                  } else {
                    if (crossArchive && (newArchives.indexOf('BAAS') > -1 || newArchives.indexOf('NYAS') > -1 || newArchives.indexOf('ENV') > -1 )) {
                      this.warningObj = [];
                      this.addSessionArchies = true; 
                    } else {
                      this.warningObj.push({
                        title: res.warnings.archives[obj].titleName,
                        message: res.warnings.archives[obj].message
                      });
                    }
                  }
                }
              }
            }
          }
        });
        /*
        * Finalize the display data and the warning object has more then one object then show the Dialog
        * And set those archives in session as well as.
        */
        if (this.warningObj.length > 0) {
          if (this.warningObj[0].title === 'warnings.env.titleName') {
            this.gotoRespectivePages();
            this.addSessionArchies = true;
          } else {
            this.warningssmodalRef = this.bsModalService.show(
              this.warningsTemplate, Object.assign({}, { backdrop: true, ignoreBackdropClick: true }, { class: 'gray modal-lg' }));
          }
        } else {
          this.gotoRespectivePages();
          this.addSessionArchies = true;
        }
        archives = archives.concat(newArchives);
        warning.forEach(element => {
          if (GenericWarningArchives.includes(element)) {
            archives = [...archives, ...GenericWarningArchives];
          }
        });
        archives = _uniq(archives);
        if (!this.addSessionArchies) {
          sessionStorage.setItem('warning', archives.toString());
        }
      }, err => {
        // this.modalService.hideLoader();
      });
      if (searchKey) {
        sessionStorage.setItem('searchTerm', searchKey.toString());
      }

      // setTimeout(() => {
        // this.modalService.hideLoader();
      // }, 1500);
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        wolhelp: false,
        togglewileyarchivemenu: false
      });
    }, 200);


    // }
  }

  goToSearchPage(searchKey) {
    sessionStorage.removeItem('termPopularityUrl');
    sessionStorage.removeItem('termFrequencyUrl');
    sessionStorage.removeItem('currentAvtiveIndex');
    sessionStorage.removeItem('termFrequency');
    sessionStorage.removeItem('termPopularity');
    sessionStorage.removeItem('termGroups');
    sessionStorage.removeItem('display');
    sessionStorage.removeItem('TFLimiter');
    sessionStorage.setItem('isInMapView', 'false');
    sessionStorage.removeItem('TATLimiters');
    sessionStorage.removeItem('explorerLimiter');
    sessionStorage.removeItem('lastResultPageSearchHistory');
    if (this.pageContent.showAdvancedFilter) {
      this.sharedService.invokeAdvSearchCall(this.pageContent.showAdvancedFilter);
      return;
    }
    const searchvalue = searchKey.trim();
    this.sharedService.updateFacetFlag(false);
    this.searchService.goToSearchPage(searchvalue, true);
  }

  closeWarningPopup() {
    this.warningssmodalRef.hide();
    this.gotoRespectivePages();
  }

  setContentType(contentType, mapFlag?: any, isFromRightClick?: any) {
    sessionStorage.removeItem('termPopularityUrl');
    sessionStorage.removeItem('termFrequencyUrl');
    sessionStorage.removeItem('currentAvtiveIndex');
    sessionStorage.removeItem('termFrequency');
    sessionStorage.removeItem('termPopularity');
    sessionStorage.removeItem('termGroups');
    sessionStorage.removeItem('display');
    sessionStorage.removeItem('TFLimiter');
    sessionStorage.removeItem('TATLimiters');
    sessionStorage.removeItem('explorerLimiter');
    sessionStorage.setItem('isInMapView', 'false');
    sessionStorage.removeItem('lastResultPageSearchHistory');
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.docSubType = [];
    currentSearchSettings.contentType = [];
    if (contentType === 'Photographs') {
      currentSearchSettings.docSubType = ['Photograph'];
    } else if (contentType === 'Maps') {
      currentSearchSettings.docSubType = ['Map'];
    } else {
      currentSearchSettings.contentType = [contentType];
    }
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
    const currentSelectedArchivesSet = _map(personalizationData.archives, 'shortName');
    /**
     * update page setting service dropdown values
     */
    setTimeout(() => {
      this.sharedService.setAdvSearchArchiveSelection(currentSelectedArchivesSet);
    }, 2000);
    this.commonService.getDropdownLimiters(currentSelectedArchivesSet, callback => { }, true);
    if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      currentSearchSettings.archive = currentSelectedArchivesSet;
    } else if (PageSettingsService.pageSettings.currentArchive === 'RGS') {
      currentSearchSettings.archive = ['RGS1', 'RGS2'];
    } else if (PageSettingsService.pageSettings.currentArchive === 'RCP') {
      currentSearchSettings.archive = ['RCP1', 'RCP2'];
    } else {
      currentSearchSettings.archive = [PageSettingsService.pageSettings.currentArchive];
    }
    if (currentSelectedArchivesSet.length > 0) {
      this.searchService.
        goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm,
          currentSearchSettings, contentType, '', isFromRightClick);
    }

  }

  gotoSearchResultPage() {
    this.searchService.loadMapPos = [];
    let archives: any;
    if (PageSettingsService.pageSettings.currentArchive === 'WILEY'
      || PageSettingsService.pageSettings.currentArchive === 'RGS' || PageSettingsService.pageSettings.currentArchive === 'RCP') {
      const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
      archives = _map(personalizationData.archives, 'shortName');
    } else {
      archives = [PageSettingsService.pageSettings.currentArchive];
    }
    const currentSearchSettings = PageSettingsService.pageSettings.advancedFilterParam;
    currentSearchSettings.archive = archives;
    sessionStorage.setItem('isInMapView', 'true');
    this.searchService.goToAdvancedSearchPage(PageSettingsService.pageSettings.searchTerm, currentSearchSettings);

  }
  onSelectedArchivesChange(selectedArchives) {
    const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
    currentSelectedArchivesSetting.currentSelectedArchives = selectedArchives;
    // tslint:disable-next-line:max-line-length
    this.selectedArchives = { archive: currentSelectedArchivesSetting.currentSelectedArchives ? currentSelectedArchivesSetting.currentSelectedArchives : [currentSelectedArchivesSetting.currentArchive], random: Math.random() };
    this.pageSettingService.updatePageSettings(currentSelectedArchivesSetting);
    if (selectedArchives.length === 0) {
      this.disableSimpleSearch = true;
    } else {
      this.disableSimpleSearch = false;
    }
    // sessionStorage.setItem('wileySelectedArchive', JSON.stringify(selectedArchives));
    localStorage.setItem('wileySelectedArchive', JSON.stringify(selectedArchives));
    let getStoredArchives = JSON.parse(localStorage.getItem('wileySelectedArchive'));
    let getSelectedArchives = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
    let initialArry= [];
    for(let k=0;k<getSelectedArchives.length;k++) {
      initialArry.push(getSelectedArchives[k].shortName);
    }
    if (getStoredArchives.length > 0) {
      if(getStoredArchives.includes('RCP')) {
        const haveRCP1 = initialArry.includes('RCP1');
        if(haveRCP1) {
          getStoredArchives.push('RCP1');
        }
        const haveRCP2 = initialArry.includes('RCP2');
        if(haveRCP2) {
          getStoredArchives.push('RCP2');
        }
        getStoredArchives = this.removeValueFromArray('RCP',getStoredArchives);
      }
      if (getStoredArchives.includes('RGS')){
        const haveRGS1 = initialArry.includes('RGS1');
        if(haveRGS1) {
          getStoredArchives.push('RGS1');
        }
        const haveRGS2 = initialArry.includes('RGS2');
        if(haveRGS2) {
          getStoredArchives.push('RGS2');
        }
        getStoredArchives = this.removeValueFromArray('RGS',getStoredArchives);
      }
    } 
    // sessionStorage.setItem('wileySelectedArchive', JSON.stringify(getStoredArchives));
    localStorage.setItem('wileySelectedArchive', JSON.stringify(getStoredArchives));
  }
  removeValueFromArray(val, arr) {
    let value = val;
    arr = arr.filter(item => item !== value)
    return arr;
  }

  gotoAnalysisHub(page: string = 'frequency') {
    sessionStorage.removeItem('termPopularityUrl');
    sessionStorage.removeItem('currentAvtiveIndex');
    sessionStorage.removeItem('termFrequency');
    sessionStorage.removeItem('termPopularity');
    sessionStorage.removeItem('termGroups');
    sessionStorage.removeItem('display');
    sessionStorage.removeItem('TFLimiter');
    sessionStorage.removeItem('explorerLimiter');
    sessionStorage.removeItem('TATLimiters');
    sessionStorage.removeItem('lastResultPageSearchHistory');
    sessionStorage.removeItem('TGLastTileIfo');
    sessionStorage.removeItem('selectedGroup');
    sessionStorage.removeItem('TGTabSelection');
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe(pageSettings => {
      this.searchService.buildFilterParams(pageSettings.advancedFilterParam, (paramsBuilt) => {
        this.searchService.buildURL(paramsBuilt, (url) => {
          const data = {};
          data['preventLimiters'] = true;
          data['filterParam'] = url;
          data['invokedFrom'] = 'home-page';
          // data['searchTerm'] = pageSettings.searchTerm ? pageSettings.searchTerm : '';
          // this.router.navigate([`${this.homePageUrl}/analysis-hub/${page}`, data]);
          this.router.navigate([`${this.homePageUrl}/analysis-hub`, data]);
        });
      });
    });
    if (sessionStorage.getItem('termFrequency')) {
      sessionStorage.removeItem('termFrequency');
    }
    if (sessionStorage.getItem('wileySuggestedterms')) {
      sessionStorage.removeItem('wileySuggestedterms');
    }
  }
  goToTermFrequency(index) {
    sessionStorage.removeItem('termPopularityUrl');
    sessionStorage.removeItem('currentAvtiveIndex');
    sessionStorage.removeItem('termFrequency');
    sessionStorage.removeItem('termPopularity');
    sessionStorage.removeItem('termGroups');
    sessionStorage.removeItem('display');
    sessionStorage.removeItem('TFLimiter');
    sessionStorage.removeItem('explorerLimiter');
    sessionStorage.removeItem('TATLimiters');
    sessionStorage.removeItem('lastResultPageSearchHistory');
    sessionStorage.removeItem('TGLastTileIfo');
    sessionStorage.removeItem('selectedGroup');
    sessionStorage.removeItem('TGTabSelection');
    let searchTerm = null;
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      searchTerm = params.searchTerm;
    });
    const filterParam = PageSettingsService.pageSettings.advancedFilterParam;
    const searchresultFlag = false;
    const isFromTATTools = false;
    let archives: any;
    if (PageSettingsService.pageSettings.currentArchive === 'WILEY') {
      const personalizationData = JSON.parse(localStorage.getItem('personalizationData'));
      archives = _map(personalizationData.archives, 'shortName');
    } else {
      archives = [PageSettingsService.pageSettings.currentArchive];
    }
    filterParam.archive = archives;
    if (archives.length > 0) {
      this.searchService.goToTermFrequencyPage(searchTerm, filterParam, index, searchresultFlag, isFromTATTools);
    }
  }
  goToTermPopularity(index) {
    sessionStorage.removeItem('termPopularityUrl');
    sessionStorage.removeItem('termFrequency');
    sessionStorage.removeItem('termPopularity');
    sessionStorage.removeItem('termGroups');
    sessionStorage.removeItem('display');
    sessionStorage.removeItem('currentAvtiveIndex');
    sessionStorage.removeItem('TFLimiter');
    sessionStorage.removeItem('explorerLimiter');
    sessionStorage.removeItem('TATLimiters');
    sessionStorage.removeItem('lastResultPageSearchHistory');
    sessionStorage.removeItem('TGLastTileIfo');
    sessionStorage.removeItem('selectedGroup');
    sessionStorage.removeItem('TGTabSelection');
    let searchTerm = null;
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      searchTerm = params.searchTerm;
    });
    const filterParam = PageSettingsService.pageSettings.advancedFilterParam;
    this.searchService.goToTermFrequencyPage(searchTerm, filterParam, index);
  }
  goToBrowseMap(index) {
    let searchTerm = null;
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      searchTerm = params.searchTerm;
    });
    const filterParam = PageSettingsService.pageSettings.advancedFilterParam;
    this.searchService.goToTermFrequencyPage(searchTerm, filterParam, index);
  }
  /* bar code issue fix (i commented the this.pageContent.showAdvancedFilter check,
  because when we select archive after the advaced limits this causes issue (this is not carrying advanced search search terms)) */
  receiveMessage(event) {
    this.isMultiselectDropdownOpen = !this.isMultiselectDropdownOpen;
    // when ever archives changed limitor dropdown values also should change
    this.pageSettingService.populateDropDowns('');
    this.showHelp = false;
  }
  receiveAdvSelectMessage(event) {
    this.isadvancedSearchOpenCheck = event;
  }
  showAdvDropdown() {
    this.ADSE(true);
    this.isFiltertoggle = true;
    this.isMultiselectDropdownOpen = false;
    localStorage.setItem('wileySelectedArchive', localStorage.getItem('wileySubscribedArchives_tab1'));
    // sessionStorage.setItem('wileySelectedArchive', localStorage.getItem('wileySubscribedArchives_tab1'));
  }
  ADSE(event: any) {
    this.isadvancedSearchOpenCheck = event;
    this.pageContent.showAdvancedFilter = true;
    if (this.pageContent.showAdvancedFilter) {
      if (this.pageContent.searchTerm) {
        this.copySearchTerm = this.pageContent.searchTerm;
        this.pageContent.searchTerm = '';
      }
    } else {
      this.pageContent.searchTerm = this.copySearchTerm;
    }
    this.showHelp = false;
    this.sharedService.changeadvValue('true');

  }
  openHelp(event) {
    this.isMultiselectDropdownOpen = event;
    this.isadvancedSearchOpenCheck = event;
    event.stopPropagation();
    /*
    * send updated valued to all dropdowns
    * and open help dropdown
    */
    if (!this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: true,
        language: false,
        shareDocument: false

      });
    } else if (this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false
      });
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.currentArchive === 'WILEY') {
      const totalWidth = $('#searchid').width() + 62;
      $('ul.suggested-terms-list').attr('style', 'width:' + totalWidth + 'px');
      $('.suggested-loading-text').attr('style', 'width:' + totalWidth + 'px');
      $('.pharse-div').attr('style', 'width:' + totalWidth + 'px');
      $('.pharse-div.searchOperator.single-archive').attr('style', 'width:' + totalWidth + 'px !important');
      $('ul.suggested-terms-list.single-archive').attr('style', 'width:' + totalWidth + 'px');
      $('.suggested-loading-text.single-archive').attr('style', 'width:' + totalWidth + 'px');
      if (window.outerWidth < 1232) {
        const fromLeft = $('.input-group-btn.archive-btnopen').width();
        const fromLeft1 = $('.input-group-btn.archive-btnopen').width() + 14;
        $('.pharse-div').css({ 'margin-left': + fromLeft + 'px', 'width': + totalWidth + 'px' });
        $('ul.suggested-terms-list').attr('style', 'left:' + fromLeft1 + 'px !important;width: ' + totalWidth + 'px !important');
        $('.suggested-loading-text').attr('style', 'left:' + fromLeft1 + 'px !important;width: ' + totalWidth + 'px !important');
      }
    } else {
      const totalWidth = $('#searchid').width() + 62;
      $('ul.suggested-terms-list').attr('style', 'width:' + totalWidth + 'px !important');
      $('.suggested-loading-text').attr('style', 'width:' + totalWidth + 'px !important');
      $('.pharse-div').attr('style', 'width:' + totalWidth + 'px !important');
      $('.suggested-loading-text.other-archives-suggested-textbox').attr('style', 'width:' + totalWidth + 'px !important');
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.showPubDate = false;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.code === "Enter") {
      if (this.suggestedSearchTerms) {
        if (this.isDownArrowClicked) {
          if (this.suggestedSearchTerms.length > 0) {
            const searchTerm = $('.active-suggested-term .sugg-term').text().trim();
            if (this.pageContent.searchTerm.includes('"')) {
              this.pageContent.searchTerm = '"' + searchTerm + '"';
            } else {
              this.pageContent.searchTerm = searchTerm;
            }
            setTimeout(() => {
              document.getElementById('searchid').focus();
            }, 500);
          }
        }
      }
    }
    if (e.code === "ArrowDown") {
      if (this.suggestedSearchTerms) {
        if (this.suggestedSearchTerms.length > 0) {
          if (this.currentIndex < this.suggestedSearchTerms.length) {
            this.currentIndex++;
            this.isDownArrowClicked = true;
            document.getElementById('searchid').blur();
            document.getElementById('handleFocusOnSuggestedTerms').focus();
          }
        }
      }
    }
    if (e.code === "ArrowUp") {
      if (this.suggestedSearchTerms) {
        if (this.suggestedSearchTerms.length > 0) {
          if (this.currentIndex > 1) {
            this.isDownArrowClicked = true;
            this.currentIndex--;
            document.getElementById('searchid').blur();
            document.getElementById('handleFocusOnSuggestedTerms').focus();
          }
        }
      }
    }
    if (e.ctrlKey && e.code ===  "Enter") {
      setTimeout(() => {
        if (this.pageContent.searchTerm) {
          this.pageContent.searchTerm = '"' + this.pageContent.searchTerm + '"';
        }
      }, 500);
    }
    if (e.shiftKey && e.altKey) {
      switch (e.code) {
        case "KeyM":
          this.verifyAndGotoSearch('', 'Manuscripts');
          break;
        case "KeyO":
          const contentTypeArr = [];
          this.pageContent.availableContentTypes.forEach(contentType => {
            contentTypeArr.push(contentType.name);
          });
          if (contentTypeArr.includes('Monographs')) {
            this.verifyAndGotoSearch('', 'Monographs');
          }
          break;
        case "KeyP":
          this.verifyAndGotoSearch('', 'Photographs');
          break;
        case "KeyU":
          this.verifyAndGotoSearch('', 'Maps');
          break;
        case "KeyJ":
          this.verifyAndGotoSearch('', '', values.tat);
          break;
        case "KeyG":
          this.verifyAndGotoSearch('', '', values.explorer.photo);
          break;
        case "KeyY":
          this.verifyAndGotoSearch('', '', values.tat);
          break;
      }
    } else if (e.ctrlKey && e.altKey) {
      switch (e.code) {
        case "KeyM":
          this.verifyAndGotoSearch('', '', values.browseMap);
          break;
        case "KeyJ":
          this.homepageSeach.tabs[1].active = true;
          break;
        case "KeyA":
          if ($('.option-list').hasClass('show-options')) {
            $('.option-list').hide();
            $('.option-list').removeClass('show-options');
            $('.archive-btnopen .dropdown-fa').removeClass('fa-chevron-up').addClass('fa-chevron-down');
          } else {
            $('.option-list').show();
            $('.option-list').addClass('show-options');
            $('.archive-btnopen .dropdown-fa').removeClass('fa-chevron-down').addClass('fa-chevron-up');
          }
          e.preventDefault();
          break;
      }
    } else if (e.ctrlKey && e.shiftKey) {
      switch (e.code) {
        case "KeyS":
          this.homepageSeach.tabs[0].active = true;
          break;
      }
    }
  }
  ngOnDestroy() {
    if (this.unSubPageSettingsObservable) {
      this.unSubPageSettingsObservable.unsubscribe();
    }
    if (this.unSubDropdownToggle) {
      this.unSubDropdownToggle.unsubscribe();
    }
    this.stop$.next();
    this.stop$.complete();
  }

  advFilterClosed(event) {
    if (this.copySearchTerm) {
      this.pageContent.searchTerm = this.copySearchTerm;
    }
  }
  disableSearch(event) {
    this.disableSimpleSearch = !event;
  }
  onSelect(data: TabDirective) { }
  isShowLoadingText(event: boolean) {
    this.showLoadingTxt = event;
  }
  receiveEstimatedCount(event: any) {
    this.estimatedCount = event;
  }

  resetClicked(event) {
    this.isResetFilter = true;
    setTimeout(() => {
      this.isResetFilter = false;
    }, 1000);
    event.stopPropagation();

  }
  /*
  * DESC: Redirect to Wiley Online Library Search Page with Search term and wileyOnlineSearchAudit service used for audit purpose
  * INPUT: Search term
  */
  gotoWileyDigitalMasterPage(searchTerm) {
    const params = { searchTerm: searchTerm };
    this.httpService.post(environment.APIUrl + APIURLS['wileyOnlineSearchAudit'], params, response => { },
      error => { });
    window.open(APIURLS.wileyOnlineLibraryURL + '=' + searchTerm, '_blank');
  }

  gotoPhotoExplorer() {
    sessionStorage.removeItem('TFLimiter');
    sessionStorage.removeItem('explorerLimiter');
    sessionStorage.removeItem('TATLimiters');
    this.router.navigate([`${this.homePageUrl}/explorer/photo`]);
    if (this.photoMapsExplorerRef) {
      this.photoMapsExplorerRef.hide();
    }
  }
  gotoMapExplorer() {
    sessionStorage.removeItem('TFLimiter');
    sessionStorage.removeItem('explorerLimiter');
    sessionStorage.removeItem('TATLimiters');
    this.router.navigate([`${this.homePageUrl}/explorer/map`]);
    if (this.photoMapsExplorerRef) {
      this.photoMapsExplorerRef.hide();
    }
  }
  // openPhotoMapsExplorer() {
  //   this.photoMapsExplorerRef = this.bsModalService.show(this.photoMapsExplorerTemplate,
  //     { backdrop: true, ignoreBackdropClick: true, class: 'explorer-popup' });
  // }
  // closeExploereModal() {
  //   this.photoMapsExplorerRef.hide();
  // }
  openWOLHelp(event) {
    event.stopPropagation();
    /*
    * send updated valued to all dropdowns
    * and open help dropdown
    */
    // this.homepageSeach.tabs[2].active = true;
    if (!this.isWileyLibraryHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        wolhelp: true,
        togglewileyarchivemenu: false

      });
    } else if (this.isWileyLibraryHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        wolhelp: false,
        togglewileyarchivemenu: false
      });
    }

  }
  /*
  * DESC: Navigate to respective pages and on selection after verfing warning popup(s)
  */
  gotoRespectivePages() {
    switch (true) {
      case (this.contentType ? true : false):
        this.setContentType(this.contentType);
        break;
      case (this.otherSearchArg === values.tat):
        // this.goToTermFrequency(0);
        this.gotoAnalysisHub();
        break;
      case (this.otherSearchArg === values.explorer.photo):
        this.gotoPhotoExplorer();
        break;
      case (this.otherSearchArg === values.explorer.map):
        this.gotoMapExplorer();
        break;
      case (this.otherSearchArg === values.browseMap):
        this.gotoSearchResultPage();
        break;
      default:
        this.goToSearchPage(this.searchKey);
        break;
    }
  }

  // removeOperatorsFromTerms() {
  //   const converetedText = [];
  //   const splittedTermsArray = this.pageContent.searchTerm.toUpperCase().match(/[^\s"]+|"([^"])+"~[0-9]{1,5}|"([^"])+"/g);
  //   splittedTermsArray.forEach(element => {
  //     if (element === this.matchedOperator) {
  //       converetedText.push(element);
  //     } else {
  //       converetedText.push(element.toLowerCase());
  //     }
  //   });
  //   this.pageContent.searchTerm = converetedText.join(' ');
  //   setTimeout(() => {
  //     $('.active-suggested-term').focus();
  //   }, 100);
  // }
  changeToOperators() {
    this.ischangeToOperators = !this.ischangeToOperators;
    const and = / and /gi;
    const or = / or /gi;
    const not = / not /gi;
    if (this.ischangeToOperators) {
      this.pageContent.searchTerm = this.pageContent.searchTerm.replace(and, ' AND ');
      this.pageContent.searchTerm = this.pageContent.searchTerm.replace(or, ' OR ');
      this.pageContent.searchTerm = this.pageContent.searchTerm.replace(not, ' NOT ');
    } else if (!this.ischangeToOperators) {
      this.pageContent.searchTerm = this.pageContent.searchTerm.replace(and, ' and ');
      this.pageContent.searchTerm = this.pageContent.searchTerm.replace(or, ' or ');
      this.pageContent.searchTerm = this.pageContent.searchTerm.replace(not, ' not ');
    }
    setTimeout(() => {
      $('.active-suggested-term').focus();
    }, 100);
  }
  stringtoArray(terms) {
    const checkOperators = ['AND', 'OR', 'NOT'];
    const newOperatorArray = [];
    this.matchedOperator = [];
    const splittedTermsArray = terms.trim().split(' ');
    splittedTermsArray.forEach(element => {
      if (checkOperators.indexOf(element) === -1) {
        newOperatorArray.push(element.toUpperCase());
      }
    });
    checkOperators.forEach(element => {
      if (newOperatorArray.indexOf(element) !== -1) {
        this.matchedOperator.push(element);
      }
    });
    return this.matchedOperator;
  }
  onSearchChange(term) {
    this.isDownArrowClicked = false;
    this.noSuggestedTermsFound = false;
    const checkOperators = ['AND', 'OR', 'NOT'];
    term = term.trim();
    const splittedTermsArray = this.stringtoArray(term);
    this.isChangeOperator = '';
    this.isChangeOperator = splittedTermsArray.join('","');

    const searchTerms = term.match(/[^\s"]+|"([^"])+"~[0-9]{1,5}|"([^"])+"/g);
    if (searchTerms) {
      searchTerms.forEach(key => {
        if (/^".*"$/.test(key)) {
          this.isChangeOperator = '';
        }
      });
    }

    this.currentIndex = 1;
    if (this.suggestedTimeOut) {
      clearTimeout(this.suggestedTimeOut);
    }
    const archive = PageSettingsService.pageSettings.currentArchive === 'WILEY' ?
      PageSettingsService.pageSettings.currentSelectedArchives : [PageSettingsService.pageSettings.currentArchive];
    this.suggestedTimeOut = setTimeout(() => {
      if (term.includes('"')) {
        term = term.replace(/"/g, ' ');
      }
      const searchTermwithSpaces = (/\s/.test(term));
      let stopWordsFromString;
      if (searchTermwithSpaces) {
        stopWordsFromString = term.split(' ');
      }
      if (stopWordsFromString) {
        const BooleanOperators = ['AND', 'OR', 'NOT'];
        stopWordsFromString.forEach((op, index) => {
          if ((BooleanOperators.indexOf(stopWordsFromString[index]) > -1 &&
            BooleanOperators.indexOf(stopWordsFromString[index + 1]) > -1) ||
            stopWordsFromString[0] === 'AND' || stopWordsFromString[0] === 'OR') {
            stopWordsFromString[index] = '';
          }
          if (stopWordsFromString[index] === '' && BooleanOperators.indexOf(stopWordsFromString[index + 1]) > -1) {
            stopWordsFromString[index + 1] = '';
          }
          if ((stopWordsFromString[stopWordsFromString.length - 1] === 'AND') ||
            (stopWordsFromString[stopWordsFromString.length - 1] === 'OR') ||
            ((stopWordsFromString[stopWordsFromString.length - 1] === 'NOT'))) {
            stopWordsFromString.pop();
          }
        });
        stopWordsFromString = stopWordsFromString.filter(elem => {
          return elem.trim() !== '';
        });
        term = stopWordsFromString.toString().replace(/,/g, ' ');
        this.callSuggestedTerms(term, archive);
      } else {
        this.callSuggestedTerms(term, archive);
      }
    }, 1000);
  }
  callSuggestedTerms(term, archive) {
    const wileySelectedArchiveList = JSON.parse((localStorage.getItem('wileySelectedArchive')));
    if (this.currentArchive === 'RGS' || this.currentArchive === 'RCP') {
      archive = wileySelectedArchiveList;
    }
    if (term.length > 2 && archive.length > 0) {
      term = term.replace(/[^a-zA-Z0-9 ?*-]/g, '');
      this.suggestedSearchTerms = [];
      if (term !== '') {
        this.typeaheadLoading = true;
        // tslint:disable-next-line:max-line-length
        this.httpClient.post(environment.APIUrl + APIURLS['getSuggestedTerms'], { 'lookup': term, 'archive': archive }).pipe(takeUntil(this.stop$)).subscribe(res => {
          if (res) {
            if (res['suggesterResult']) {
              if (res['suggesterResult'].length > 0) {
                this.suggestedSearchTerms = res['suggesterResult'];
                this.showAddQuotesOn = false;
                this.typeaheadLoading = false;
                this.noSuggestedTermsFound = false;
              } else {
                // if (res['spellCheckResult'].length > 0 && res['suggesterResult'].length > 0) {
                this.suggestedSearchTerms = [];
                this.typeaheadLoading = false;
                this.noSuggestedTermsFound = true;
                // }
              }
            } else {
              this.suggestedSearchTerms = [];
              this.typeaheadLoading = false;
              this.noSuggestedTermsFound = true;
            }
            // if (res['spellCheckResult']) {
            //   const spellchkarray = [];
            //   if (res['spellCheckResult'].length > 0) {
            //     res['spellCheckResult'].forEach(obj => {
            //       spellchkarray.push(obj);
            //     });
            //     this.spellCheckSearchTerms = spellchkarray[0];
            //     this.noSuggestedTermsFound = false;
            //     this.typeaheadLoading = false;
            //   } else {
            //     this.spellCheckSearchTerms = [];
            //     this.noSuggestedTermsFound = true;
            //     this.typeaheadLoading = false;
            //   }
            //   if (res['suggesterResult']) {
            //     if (res['suggesterResult'].length > 0) {
            //       this.suggestedSearchTerms = res['suggesterResult'];
            //       this.showAddQuotesOn = false;
            //       this.typeaheadLoading = false;
            //       this.noSuggestedTermsFound = false;
            //     } else {
            //       if (res['spellCheckResult'].length > 0 && res['suggesterResult'].length > 0) {
            //         this.suggestedSearchTerms = [];
            //         this.typeaheadLoading = false;
            //         this.noSuggestedTermsFound = true;
            //       }
            //     }
            //   } else {
            //     this.suggestedSearchTerms = [];
            //     this.typeaheadLoading = false;
            //     this.noSuggestedTermsFound = true;
            //   }
            // }
          }
        });
      }
    } else {
      this.suggestedSearchTerms = [];
    }
  }
  suggestedTermsMouseEnter(term, index) {
    this.currentIndex = index;
  }
  suggestedTermsMouseLeave(term, index) {

  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(term, index) {
    this.currentIndex = index;
    this.pageContent.searchTerm = term;
  }
  addQuotes(term, index) {
    this.currentIndex = index;
    this.pageContent.searchTerm = '"' + term + '"';
  }
  addPharse() {
    if (this.pageContent.searchTerm.includes('"')) {
    } else {
      this.pageContent.searchTerm = '"' + this.pageContent.searchTerm + '"';
    }
  }
  removePharse() {
    if (this.pageContent.searchTerm.includes('"')) {
      this.pageContent.searchTerm = this.pageContent.searchTerm.replace(/"/g, '');
    }
  }

  toggle(e) {
    if (e.target.checked === true) {
      this.showAddQuotesOn = true;
      this.addPharse();
    } else {
      this.showAddQuotesOn = false;
      this.removePharse();
    }
  }
  spellCheckSearchTerm(term) {
    this.pageContent.searchTerm = term;
  }
  gotoAboutPage() {
    this.router.navigate([this.homePageUrl + '/about-us']);
  }
  // moreBAASContentOnMouseEnter() {
  //   this.showMoreBAASContentLink = true;
  // }
  // moreBAASContentOnMouseLeave() {
  //   this.showMoreBAASContentLink = false;
  // }
  // showMoreBAASDocumentsOnClick() {
  //   this.showMoreBAASContentLink = false;
  //   window.open(this.moreBAASContentLink, '_blank');
  // }
  onRightClick(contentType, event) {
    event.stopPropagation();
    event.preventDefault();
    this.setContentType(contentType, '', 'true');
    if (contentType === 'Maps') {
      sessionStorage.setItem('selectedContentType', contentType);
    } else if (contentType === 'Photographs') {
      sessionStorage.setItem('selectedContentType', contentType);
    } else {
      sessionStorage.removeItem('selectedContentType');
    }
  }

  // goDownloadeContent(){   
  
  //  this.downloadPDFmodalRef = this.bsModalService.show(this.downloadDocsTemplate, { backdrop: true, ignoreBackdropClick: true });
  //   }

  //  closeDownloadModal() {
	// 	this.downloadPDFmodalRef.hide();
	// } 
}


