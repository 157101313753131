/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../../common/services/modal.service';
// import { PageSettingsService } from '../.././../common/services/page-settings.service';

@Component({
  selector: 'app-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.css']
})
export class UnauthorizedPageComponent implements AfterViewInit {
  homePageUrl: string = '';
  constructor(
    private router: Router,
    private modalService: ModalService
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.homePageUrl = '/';
      this.homePageUrl += ('wiley' + ''
      ).toLowerCase();
      this.modalService.hideWelcomePopup();
    }, 1500);
  }

  backtoHome() {
    this.router.navigate([this.homePageUrl]);
  }
}
