/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

// const MODAL_TYPE: any = {
//   ALERT: 'alert',
//   CONFIRM: 'alert'
// };

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})

export class ModalComponent {
  private bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService) { }

  openModal(title, content, isAlert, okCallBack, cancelCallBack) {
    this.bsModalRef = this.modalService.show(ModalContentComponent);
    this.bsModalRef.content.title = title;
    this.bsModalRef.content.body = content;
    this.bsModalRef.content.okClick = okCallBack;
    this.bsModalRef.content.cancelClick = cancelCallBack;
    this.bsModalRef.content.isAlert = isAlert;
  }
}

/* This is a component which we pass in modal*/

@Component({
  selector: 'app-modal-content',
  template: `
      <div class="modal-header row">
        <div class='col-md-12'>
          <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
          </button>
        </div>
        <div class='col-md-12'>
          <h4 class="modal-title sa-icon sa-warning pulseWarning">
            <span class="sa-body pulseWarningIns"></span>
            <span class="sa-dot pulseWarningIns"></span>
          </h4>
        </div>
      </div>
      <div class="modal-body">
        {{body}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="okCallBack();$event.stopPropagation();">Ok</button>
        @if (!isAlert) {
          <button type="button" class="btn btn-default" (click)="cancelCallBack();$event.stopPropagation();">Cancel</button>
        }
      </div>
      `,
    styleUrls: ['./modal.component.css']
})
export class ModalContentComponent {
  title: string;
  body: string;
  okClick;
  cancelClick;
  isAlert;
  constructor(public bsModalRef: BsModalRef) { }

  okCallBack() {
    if (typeof this.okClick === 'function') {
      this.okClick();
      this.bsModalRef.hide();
    } else {
      this.bsModalRef.hide();
    }
  }

  cancelCallBack() {
    if (typeof this.cancelClick === 'function') {
      this.cancelClick();
      this.bsModalRef.hide();
    } else {
      this.bsModalRef.hide();
    }
  }
}
