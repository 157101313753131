<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<section class="container-fluid banner-content" *ngIf="pageContent && authPage">

  <h1 class="sr-only">{{ 'HomePage.searchPlaceholder' | translate }}</h1>
  <div class="bg-overlay {{pageContent.currentArchive}}" [style.background-image]="pageContent?.backgroundUrl ? 'url('+ pageContent?.backgroundUrl +')' : ''">
    <img [src]="pageContent?.backgroundUrl" class="img-fluid mobileViewBanner" alt="{{'Alt.banner' | translate}}" />
    <div id="overlay-search" class="mx-auto d-block" [ngClass]="pageContent.currentArchive === 'WPA' || pageContent.currentArchive === 'WPA1' ? 'overlay-wpa' : 'overlay-search'">
      <tabset>
        <tab heading="{{ 'HomePage.basicSearch' | translate }}" title="{{ 'HomePage.basicSearch' | translate }} ({{controlShift}} + S)" (selectTab)="activateAdvSearch = false;actiateBasicSearch = true;isFiltertoggle = false;pageContent.showAdvancedFilter=false;">
          <div class="d-flex">
            <!-- multiselect dropdown -->
            <ng-container *ngIf="actiateBasicSearch && pageContent?.customerArchives">
              <multiselect-dropdown class="multiselect" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'" [options]="pageContent.customerArchives" [text-key]="'name'" [value-key]="'shortName'"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="pageContent.currentSelectedArchives" (ngModelChange)="onSelectedArchivesChange($event)"
                [ngClass]="{'single-archive' : pageContent.customerArchives.length === 1}"></multiselect-dropdown>
            </ng-container>
            <ng-container *ngIf="actiateBasicSearch && currentArchive === 'RGS'">
              <multiselect-dropdown class="multiselect" [class.hide-option]="rgsArchives.length === 1 || rgsArchives.length === 2" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'" [options]="rgsArchives" [text-key]="'name'" [value-key]="'shortName'"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="pageContent.currentSelectedArchives" (ngModelChange)="onSelectedArchivesChange($event)"
                [ngClass]="{'single-archive' : currentArchive.length === 1}"></multiselect-dropdown>
            </ng-container>
            <ng-container *ngIf="actiateBasicSearch && currentArchive === 'RCP'">
              <multiselect-dropdown class="multiselect" [class.hide-option]="rcpArchives.length === 1 || rcpArchives.length === 2" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
                [placeholder]="'ARCHIVES'" [options]="rcpArchives" [text-key]="'name'" [value-key]="'shortName'"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="pageContent.currentSelectedArchives" (ngModelChange)="onSelectedArchivesChange($event)"
                [ngClass]="{'single-archive' : currentArchive.length === 1}"></multiselect-dropdown>
            </ng-container>
            <!-- search area -->
            <label for="searchid" class="sr-only">{{ 'HomePage.search' | translate }}</label>
            <input [class.other_archives]="!pageContent.customerArchives" [class.arabic-style]="isArabicLanguage" aria-label="What are you searching for?"
              type="text" id="searchid" name="searchKeyword" #searchKeyId class="form-control search-texbox archives-search-textbox"
              [placeholder]="searchPlaceholder" (input)="onSearchChange($event.target.value)" autocomplete="off" [(ngModel)]="pageContent.searchTerm" [ngClass]="{'without-archive': !pageContent.customerArchives}">
            <a href="javascript:void(0)" class="help-btn" title="{{ 'Common.Help' | translate }}" (click)="openHelp($event);">
              <i class="spriteico helpIcon"></i>
              <span class="sr-only">{{ 'HomePage.helpIcon' | translate }}</span>
            </a>
            <!-- search  -->
            <span class="input-group-btn">
              <button class="btn btn-primary search-btn g-search-btn" title="{{ 'Common.Search' | translate }}" type="submit" [disabled]='pageContent.currentSelectedArchives.length === 0' (click)='verifyAndGotoSearch(pageContent.searchTerm, "")'>
                <i class="fa fa-search"></i>
                <span class="sr-only">{{ 'Common.Search' | translate }}</span>
              </button>
            </span>
          </div>
        </tab>
        <tab heading="{{'HomePage.advancedSearch'|translate}}" title="{{'HomePage.advancedSearch'|translate}} ({{controlShortcut}} + J)" [disabled]="pageContent?.currentSelectedArchives.length<=0 " (selectTab)="showAdvDropdown();actiateBasicSearch=false;activateAdvSearch=true;showFilter = true">
        <div class="d-flex">
          <!-- multiselect dropdown -->
          <ng-container *ngIf="activateAdvSearch && pageContent.customerArchives">
            <multiselect-dropdown class="multiselect" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
              [placeholder]="'ARCHIVES'" [options]="pageContent.customerArchives" [text-key]="'name'" [value-key]="'shortName'"
              [(ngModel)]="pageContent.currentSelectedArchives" (ngModelChange)="onSelectedArchivesChange($event)"></multiselect-dropdown>
          </ng-container>
          <ng-container *ngIf="activateAdvSearch && currentArchive === 'RGS'">
            <multiselect-dropdown class="multiselect" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
              [placeholder]="'ARCHIVES'" [options]="rgsArchives" [text-key]="'name'" [value-key]="'shortName'"
              [(ngModel)]="pageContent.currentSelectedArchives" (ngModelChange)="onSelectedArchivesChange($event)"></multiselect-dropdown>
          </ng-container>
          <ng-container *ngIf="activateAdvSearch && currentArchive === 'RCP'">
            <multiselect-dropdown class="multiselect" (isMutliDropdownOpen)="receiveMessage($event)" [advance-dropdown-status]="isadvancedSearchOpenCheck"
              [placeholder]="'ARCHIVES'" [options]="rcpArchives" [text-key]="'name'" [value-key]="'shortName'"
              [(ngModel)]="pageContent.currentSelectedArchives" (ngModelChange)="onSelectedArchivesChange($event)"></multiselect-dropdown>
          </ng-container>
          <!-- search part -->
          <label for="searchid" class="sr-only">{{ 'HomePage.search' | translate }}</label>
          <p *ngIf="activateAdvSearch" class="limiter_count search-texbox" [class.other_archives]="!pageContent.customerArchives">
            <label class="estimated-count-div">
              <span [class.arabic-style]="isArabicLanguage" class="text-italic estimated-count">{{ 'AdvancedSearch.estimatedCount' | translate }} </span>
              <span [class.arabic-style]="isArabicLanguage" *ngIf="(estimatedCount == 0 && !showLoadingTxt) || (disableSimpleSearch && !showLoadingTxt)"
                class="blink-wordlbl mb-0">
                {{ 'HomePage.noResults' | translate }}
              </span>
              <span [class.arabic-style]="isArabicLanguage" *ngIf="((estimatedCount > 0 && !showLoadingTxt) || (disableSimpleSearch && showLoadingTxt))"
                class="blink-wordlbl mb-0">
                {{ estimatedCount }} {{ 'HomePage.documents' | translate }}
              </span>
              <span *ngIf="showLoadingTxt">
                {{'AdvancedSearch.Loading' | translate}}
              </span>
            </label>
            <span class="pull-right reset_link" title="{{ 'AdvancedSearch.CapsReset' | translate }}" (click)="resetClicked($event)">{{ 'AdvancedSearch.CapsReset' | translate }}</span>
          </p>
          <a href="javascript:void(0)" class="help-btn" title="{{ 'Common.Help' | translate }}" (click)="openHelp($event);">
            <i class="spriteico helpIcon"></i>
            <span class="sr-only">{{ 'HomePage.helpIcon' | translate }}</span>
          </a>
          <!-- search button -->
          <span class="input-group-btn ">
            <button class="btn btn-primary search-btn g-search-btn" title="{{ 'Common.Search' | translate }}" [disabled]="estimatedCount == 0 || disableSimpleSearch && pageContent.showAdvancedFilter"
              type="submit" (click)='verifyAndGotoSearch(pageContent.searchTerm, "")'>
              <i class="fa fa-search"></i>
              <span class="sr-only">{{ 'Common.Search' | translate }}</span>
            </button>
          </span>
        </div>
         <!-- filters -->
         <div class="dropdown-box text-end">
          <app-search-filter (isAdvanceSearchOpen)="receiveAdvSelectMessage($event)" [page-content]="pageContent" [show-filter]="showFilter"
            (disable-search)="disableSearch($event)" (show-loading-text)="isShowLoadingText($event)"
            (estimated-count)="receiveEstimatedCount($event)" [fromHomepage]="true" [resetFilter]="isResetFilter" [filterToggle]="isFiltertoggle"
            [showPubDateval]="showPubDate" [getFilterValue]="getFilterValue" (passFilterValue)="getFilterFormValue($event)"></app-search-filter>
        </div>
        </tab>
        <tab [customClass]="'wiley-homePageSearch wol-tab'" >
          <ng-template tabHeading>
            <label class="wiley-digital-master-tab" title="{{'HomePage.wileyLibraryOnlineSearch'|translate}}">{{'HomePage.wileyLibraryOnlineSearch'|translate}}
              <span title="{{ 'Common.Help' | translate }}" (keyup.enter)="openWOLHelp($event);" (click)="openWOLHelp($event);"
                class="spriteico wol-helpIcon"></span>
            </label>
          </ng-template>
          <div class="d-flex">
            <label for="searchid" class="sr-only">{{ 'HomePage.search' | translate }}</label>
            <input [class.arabic-style]="isArabicLanguage" aria-label="What are you searching for?" type="text" id="searchid" name="searchKeyword"
              #searchKeyId [(ngModel)]="pageContent.searchTerm" class="form-control search-texbox archives-search-textbox wiley-homePageSearch-inputbox wol-input-box"
              placeholder="{{'HomePage.wileyLibraryOnlineSearch'|translate}}" autocomplete="off">
            <span class="input-group-btn">
              <button class="btn btn-primary search-btn wol-search-btn g-search-btn" title="{{ 'Common.Search' | translate }}" type="submit"
                (click)='gotoWileyDigitalMasterPage(pageContent.searchTerm, "")'>
                <i class="fa fa-search"></i>
                <span class="sr-only">{{ 'Common.Search' | translate }}</span>
              </button>
            </span>
          </div>
        </tab>
        <div [class.arabic-style]="isArabicLanguage" class="wol-help-container" id="help_box" [class.show-wol-help]="isWileyLibraryHelp"
        (click)="$event.stopPropagation();" [class.arabic-align-right]="isArabicLanguage">
          <p class="wol-content">
            {{ 'HomePage.wolHelpContent' | translate }}
          </p>
        </div>
     </tabset>
      <input type="text" id="handleFocusOnSuggestedTerms" autocomplete="off" style="position: absolute;width: 0;left: -100%;opacity: 0;"/>

      <div [class.arabic-style]="isArabicLanguage" class="help-container" id="help_box" [class.show-help]="showHelp" (click)="$event.stopPropagation();">
          <p>
            <b>{{ 'AdvancedSearch.BasicSearch' | translate }}</b> - {{ 'AdvancedSearch.BasicSearchContent' | translate }}
          </p>
          <p>{{ 'AdvancedSearch.Examples' | translate }}:</p>
          <ul>
            <li>
              <b>{{ 'AdvancedSearch.SimpleSearch' | translate }}</b>:
              <span class="underline">{{ 'AdvancedSearch.ExecutiveCommittee' | translate }} </span> ({{ 'AdvancedSearch.ReturnsDocuments' | translate
              }})
            </li>
            <li>
              <b>{{ 'AdvancedSearch.SimpleSearch' | translate }}</b> ({{ 'AdvancedSearch.UsingQuotes' | translate }}):
              <span class="underline">"{{ 'AdvancedSearch.ExecutiveCommittee' | translate }}" </span>({{ 'AdvancedSearch.RetrievesOnlyDocuments'
              | translate }})</li>
            <li>
              <b>{{ 'AdvancedSearch.BooleanSearch' | translate }}</b> {{ 'AdvancedSearch.usingANDORNOT' | translate }}:
              <span class="underline">{{ 'AdvancedSearch.ExecutiveORcommittee' | translate }}</span>
            </li>
            <li>
              <b>{{ 'AdvancedSearch.ProximitySearch' | translate }}</b>:
              <span class="underline">"{{ 'AdvancedSearch.ExecutiveCommittee' | translate }}"~3</span> ({{ 'AdvancedSearch.matchesCommitteewithin3words'
              | translate }})</li>
            <li>
              <b>{{ 'AdvancedSearch.ExactMatch' | translate }}</b>:
              <span class="underline">"Color"</span> ({{ 'AdvancedSearch.DoNotUseFuzzySearch' | translate }})</li>
            <li>
              <b>{{ 'AdvancedSearch.WildcardSearch' | translate }}</b> (* - {{ 'AdvancedSearch.AnyNumber' | translate }}, ?
              - {{ 'AdvancedSearch.OneChar' | translate }}, ! - {{ 'AdvancedSearch.OneOrNoChar' | translate }}):
              <span class="underline">{{ 'AdvancedSearch.MistakeColor' | translate }}</span>
            </li>
          </ul>
          <p>
            <b>{{ 'AdvancedSearch.Tips' | translate }}:</b>
          </p>
          <ul>
            <li>{{ 'AdvancedSearch.YouDoNotNeedToEnter' | translate }},
              <span class="underline">Résumé</span> {{ 'AdvancedSearch.IsSameAs' | translate }}
              <span class="underline">Resume</span>
            </li>
            <li>{{ 'AdvancedSearch.StopWordsSuchAs' | translate }} "a", "an", "the" etc. {{ 'AdvancedSearch.AreIgnored' | translate
              }}
            </li>
          </ul>
        <!-- </perfect-scrollbar> -->
      </div>


    </div>
    <br>
    <br>
    <br>
    <!-- <div class="banner-container">
      <label *ngIf="pageContent.currentArchive === 'ENV'" class="banner-new">&nbsp;<i class="fa fa-info-circle doc-count-eye-icn" (click)="goDownloadeContent()"></i> {{ 'SearchResult.moreENVContentLink' | translate }} <a class="click" (click)="goDownloadeContent()"> {{ 'Common.clickHeretext' | translate }}.</a></label>
    </div> -->
    <button [hidden]="true" (keyup.enter)="ADSE($event)" (click)="ADSE($event)" id="menu1">This for advanced search dropdown
    </button>
   </div>
</section>
<section class="container-fluid column-content" *ngIf="authPage">
  <div class="col-container row">
    <div [ngClass]="pageContent.currentArchive === 'WPA'  || pageContent.currentArchive === 'WPA1' ? 'col-md-6' : 'col-md-4'" class="col mob-browser-width">
      <h2 [class.arabic-style]="isArabicLanguage" class="column-content-heading">
        <span [ngClass]="pageContent.currentArchive === 'WPA'  || pageContent.currentArchive === 'WPA1' ? 'browsecontentIconWpa' : 'browsecontentIcon'" class="spriteico"></span>
        <span class="column-content-ellipse" title="{{ 'HomePage.browsebyContentTypes' | translate }}">{{ 'HomePage.browsebyContentTypes' | translate }}</span>
      </h2>
      <div class="col-md-12 content-blocks">
        <div class="row">
          <div class="col-md-12 col-xs-12 col-sm-12">
            <ul class="column-content-list">
              <li *ngFor="let contentType of availableContentTypes" [ngSwitch]="contentType.name"  (click)='verifyAndGotoSearch("", contentType.name)'>
                <a (contextmenu)="onRightClick(contentType.name, $event)" tabindex="0" *ngSwitchCase="'Manuscripts'" 
                [tooltip]="feedbackTooltip" placement="top">
                  <span class="spriteico contentArrowIcon"></span>{{contentType.name}} 
                  <ng-template #feedbackTooltip>
                    <div class="content-tooltip">
                      <p>{{contentType.name}} ({{operationSystem}} + M)</p>
                      <p>Right click to open link in new tab</p>
                    </div>
                  </ng-template>
                </a>
                <a (contextmenu)="onRightClick(contentType.name, $event)" tabindex="0" 
                [tooltip]="feedbackTooltip" placement="top" *ngSwitchCase="'Monographs'" >
                  <span class="spriteico contentArrowIcon"></span>{{contentType.name}}

                  <ng-template #feedbackTooltip>
                    <div class="content-tooltip">
                      <p>{{contentType.name}} ({{operationSystem}} + O)</p>
                      <p>Right click to open link in new tab</p>
                    </div>
                  </ng-template>
                </a>
                <a (contextmenu)="onRightClick(contentType.name, $event)" [tooltip]="feedbackTooltip" placement="top" 
                tabindex="0" *ngSwitchCase="'Photographs'">
                  <span class="spriteico contentArrowIcon"></span>{{contentType.name}} 
                  <ng-template #feedbackTooltip>
                    <div class="content-tooltip">
                      <p>{{contentType.name}} ({{operationSystem}} + P)</p>
                      <p>Right click to open link in new tab</p>
                    </div>
                  </ng-template>
                </a>
                <a (contextmenu)="onRightClick(contentType.name, $event)" [tooltip]="feedbackTooltip" placement="top" 
                tabindex="0" *ngSwitchCase="'Maps'" >
                  <span class="spriteico contentArrowIcon"></span>{{contentType.name}} 
                  <ng-template #feedbackTooltip>
                    <div class="content-tooltip">
                      <p>{{contentType.name}} ({{operationSystem}} + U)</p>
                      <p>Right click to open link in new tab</p>
                    </div>
                  </ng-template>
                </a>
                <a (contextmenu)="onRightClick(contentType.name, $event)" tabindex="0" [tooltip]="feedbackTooltip" placement="top"
                 *ngSwitchDefault  >
                  <span class="spriteico contentArrowIcon"></span>{{contentType.name}} 
                  <ng-template #feedbackTooltip>
                    <div class="content-tooltip">
                      <p>{{contentType.name}} ({{operationSystem}} + M)</p>
                      <p>Right click to open link in new tab</p>
                    </div>
                  </ng-template>
                </a>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="pageContent.currentArchive === 'WPA' || pageContent.currentArchive === 'WPA1' ? 'col-md-6' : 'col-md-4'" class="col plr-0 mob-browser-width">
      <h2 [class.arabic-style]="isArabicLanguage" class="column-content-heading">
        <span [ngClass]="pageContent.currentArchive === 'WPA' || pageContent.currentArchive === 'WPA1' ? 'headingtoolIconWpa' : 'headingtoolIcon'" class="spriteico"></span>
        <span class="column-content-ellipse" title="{{ 'Titles.AnalysisToolsHomePage' | translate }}">{{ 'Titles.AnalysisToolsHomePage' | translate }}</span>
      </h2>
      <div class="col-md-12 col-sm-12 content-blocks">
        <!-- <div class="row"> -->
          <div class="column-content-grid row" role="button">
            <div class="col-md-4 col-sm-4 termFrequencyDiv">
              <a title="{{ 'HomePage.analysisTools.termFrequency' | translate }} ({{operationSystem}} + J / {{operationSystem}} + Y)" (click)="verifyAndGotoSearch('','', 'TAT')"
                (keyup.enter)="verifyAndGotoSearch('','', 'TAT')">
                <div class="text-center bg-color">
                  <div class="termImage">
                    <img src="../assets/images/icons/termfrequency,.png" alt="{{'Titles.TermFrequencyOrPopularity' | translate}} ({{operationSystem}} + J)"
                      class="img-fluid mx-auto d-block analysis-image">
                  </div>
                  <a tabindex="0" href="javascript:void(0)" [class.arabic-style]="isArabicLanguage" class="list-label tool-label text-decoration-none" [class.minfontSize]="currLanguage === 'ta'">{{ 'HomePage.analysisTools.termFrequency' | translate }}
                  </a>
                </div>
              </a>
            </div>
            <div class="col-md-4 col-sm-4 termGroupDiv">
              <div class="text-center bg-color padding-none">
                <div tabindex="0" (keyup.enter)="verifyAndGotoSearch('','', 'photo');" class="photo-image" (click)="verifyAndGotoSearch('','', 'photo');"
                  title="{{ 'HomePage.analysisTools.termGroups' | translate }}">
                  <img src="../assets/images/explorer.png" alt="{{ 'HomePage.analysisTools.explorer' | translate }}" class="img-fluid mx-auto d-block explorerimg">
                  <a href="javascript:void(0)" [class.arabic-style]="isArabicLanguage" class="explorer-btn list-label tool-label text-decoration-none" [class.minfontSize]="currLanguage === 'ta'">{{ 'HomePage.analysisTools.explorer' | translate }}
                  </a>
                </div>
                <div class="photo-map-content">
                  <div tabindex="0" class="photo-exp-content" (keyup.enter)="verifyAndGotoSearch('','', 'photo');" (click)="verifyAndGotoSearch('','', 'photo');">
                    <i class="fa fa-picture-o photo-icn" title=" {{ 'HomePage.analysisTools.photo' | translate }}" aria-hidden="true"></i>
                    <a href="javascript:void(0)" title="{{ 'HomePage.analysisTools.photo' | translate }} {{ 'HomePage.analysisTools.explorer' | translate }} "
                      [class.arabic-style]="isArabicLanguage" class="list-label">
                      {{ 'HomePage.analysisTools.photo' | translate }}
                    </a>
                  </div>
                  <div role="button" tabindex="0" aria-pressed="false" class="map-exp-content" (click)="verifyAndGotoSearch('','', 'map');"
                    (keyup.enter)="verifyAndGotoSearch('','', 'map');">
                    <i class="fa fa-map-marker map-icn" title="{{ 'HomePage.analysisTools.map' | translate }}" aria-hidden="true"></i>
                    <a href="javascript:void(0)" title="{{ 'HomePage.analysisTools.map' | translate }} {{ 'HomePage.analysisTools.explorer' | translate }}"
                      [class.arabic-style]="isArabicLanguage" class="list-label">
                      {{ 'HomePage.analysisTools.map' | translate }}
                    </a>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-4 col-sm-4 visualBrowserDiv">
              <a title="{{ 'HomePage.analysisTools.publicationBrowser' | translate }} ({{controlShortcut}} + M)" (keyup.enter)="verifyAndGotoSearch('','', 'browse' );"
                (click)="verifyAndGotoSearch('','', 'browse' );">
                <div class="text-center bg-color">
                  <div class="toolImage">
                    <img src="../assets/images/icons/visualbrowsermaps.png" alt="{{ 'HomePage.analysisTools.publicationBrowser' | translate }}"
                      class="img-fluid mx-auto d-block visual-image">
                  </div>
                  <a tabindex="0" href="javascript:void(0)" [class.arabic-style]="isArabicLanguage" class="list-label tool-label text-decoration-none" [class.minfontSize]="currLanguage === 'ta'">{{ 'HomePage.analysisTools.publicationBrowser' | translate }}</a>
                </div>
              </a>
            </div>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <div [ngClass]="pageContent.currentArchive === 'WPA' || pageContent.currentArchive === 'WPA1' ? 'col-md-12' : 'col-md-4'" class="col mob-content-width">
      <h2 [class.arabic-style]="isArabicLanguage" class="column-content-heading">
        <span [ngClass]="pageContent.currentArchive === 'WPA'  || pageContent.currentArchive === 'WPA1' ? 'headingnyasicon-defaultWpa' : 'headingnyasicon-default'" class="spriteico   heading{{pageContent.currentArchive | lowercase}}icon "></span>
        <span [innerHTML]="pageContent?.description?.title | translate" [class.baas-heading]="showJisclogo" title="{{pageContent?.description?.title | translate}}">
        </span>
      </h2>
      <div class="col-md-12 content-blocks">
        <!-- <div [class.arabic-style]="isArabicLanguage" class="digi-archive-collapse column-content-list text_justify"
          [class.collapsed]="!isCollapsed" [class.landing-page_digi-archive]="isCollapsed" tooltipPlacement="top" tooltip="{{ pageContent?.description?.content | translate}}">
          {{ pageContent?.description?.content | translate}}
        </div> -->
        <div [class.arabic-style]="isArabicLanguage" [ngClass]="pageContent.currentArchive === 'WPA' || pageContent.currentArchive === 'WPA1' ? 'wpaArchive' : ''" class="digi-archive-collapse column-content-list text_justify"
          [class.collapsed]="!isCollapsed" [class.landing-page_digi-archive]="isCollapsed && pageContent.currentArchive !== 'WPA' || isCollapsed && pageContent.currentArchive !==  'WPA1'" [class.landing-page_wpa-archive]="isCollapsed && pageContent.currentArchive === 'WPA' || isCollapsed && pageContent.currentArchive === 'WPA1'" [innerHtml]="pageContent?.description?.content | translate">
        </div>
        
        <div class="view-more-text" (click)="gotoAboutPage()">
          <div [class.arabic-style]="isArabicLanguage" [ngClass]="pageContent.currentArchive === 'WPA'  || pageContent.currentArchive === 'WPA1' ? 'AddBottomSpace' : ''" class="btn-primary" [ngStyle]="{'display': isCollapsed ? 'block': 'none'}">
            <a tabindex="0" href="javascript:void(0)" title="{{ 'HomePage.viewMore' | translate }}">
              <span id="toggleButtonMore" translate>{{ 'HomePage.viewMore'}}</span>
              <i class="btn btn-down-arrow fa fa-angle-down" aria-hidden="true" style="color:#fff"></i>
            </a>
          </div>
          <!-- <div class="btn-primary view-less-btn-btm" [ngStyle]="{'display': isCollapsed ? 'none': 'block'}">
            <a tabindex="0" href="javascript:void(0)" title="{{ 'HomePage.viewLess' | translate }}">
              <span id="toggleButtonLess">{{ 'HomePage.viewLess' | translate }}</span>
              <i class="btn btn-up-arrow fa fa-angle-up" aria-hidden="true"></i>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #warningsTemplate>
  <div class="modal-header warning-modal-header text-center d-block">
    <h6 [class.arabic-style]="isArabicLanguage" class="warnings-headerlbl no-margin "> {{ 'HomePage.warning' | translate }} </h6>
  </div>
  <div class="modal-body warning_bdy" [class.arabic-style]="isArabicLanguage">
    <ng-container *ngFor="let wo of warningObj;let last = last">
      <h5 class="text-left" *ngIf="wo && wo.title" [innerHTML]="wo?.title | translate"></h5>
      <div [class.arabic-style]="isArabicLanguage" class="warning-message text-justify" [innerHTML]="wo?.message | translate"></div>
      <hr *ngIf="!last" class="divider">
    </ng-container>
  </div>

  <div class="modal-footer warnings-footer">
    <button tabindex="0" [class.arabic-style]="isArabicLanguage" type="button" appAutofocus class="btn btn-primary" (click)="closeWarningPopup();$event.stopPropagation();">
      {{ 'HomePage.closeBtn' | translate }}
    </button>
  </div>
</ng-template>

<!-- =================newData============== -->
<!-- <ng-template #downloadDocsTemplate>
  <div class="modal-header  download-title">        
    {{ 'SearchResult.moreENVContentLink' | translate }}
  </div>
  <div class="modal-body warning_bdy">
    <div class="more-body-text">
      {{ 'SearchResult.envmoreContentText' | translate }}
    </div>
  </div>
  <div class="modal-footer">
      <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" (click)="closeDownloadModal();$event.stopPropagation();">
          {{ 'Detail.Close' | translate }}
        </button>
  </div>
</ng-template> -->
