
import {takeUntil} from 'rxjs/operators';
/* Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved */
import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { ConcordanceService } from './concordance.service';
import { Subject } from 'rxjs';
import { SharedService } from '../../common/services/sharedService';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
// declare let $: any;

@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule],
  selector: 'app-concordance',
  templateUrl: './concordance.component.html',
  styleUrls: ['./concordance.component.css'],
  providers: [ConcordanceService]
})
export class ConcordanceComponent implements OnInit, OnDestroy {
  lang: string;
  disableAllButton: any;
  // private stop$: Subject<void> = new Subject<void>();
  stop$: Subject<void> = new Subject<void>();
  terms: Array<String> = [];
  resultSet: any = {};
  concordanceDataCache$: any = {};
  limit: Number = 20;
  sliderValue: Number = 5;
  minValue: Number = 1;
  maxValue: Number = 15;
  step: Number = 1;
  _filterInputs: any;
  _advancedFilterParam: any;
  @Input('filterInputs')
  set filterInputs(value) {
    this._filterInputs = value;
    this._advancedFilterParam = this._filterInputs.advancedFilterParam;
    if (this._filterInputs.preventLimiters) {
      this._filterInputs.filterParams.filterParams = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
    }
    if (value.terms.length > 0) {
      this.getConcordance();
    }
  }
  isArabicLanguage: Boolean = false;
  constructor(
    private concordanceService: ConcordanceService,
    private changeDetectorRef: ChangeDetectorRef,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    const currentLanguage = sessionStorage.getItem('lang');
    this.lang = currentLanguage;
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
  }
  getConcordance() {
    const terms = this._filterInputs.terms;
    terms.forEach(term => {
      this.resultSet[`${term}`] = { data: [], loading: true, sliderValue: 5 };
      this.concordanceService.getConcordanceData(this._filterInputs.filterParams, term,
        this.limit, this.resultSet[`${term}`].sliderValue, this.resultSet[`${term}`].sliderValue).pipe(
        takeUntil(this.stop$)).subscribe(res => {
          this.concordanceProcess(res, term);
        }, err => {
          this.resultSet[`${term}`].loading = false;
          this.resultSet[`${term}`].data = [];
        });
    });
  }

  concordanceProcess(res, term) {
    if (res) {
      if (res['analysisResponse']) {
        if (res['analysisResponse'][0]) {
          if (res['analysisResponse'][0]['rows']) {
            this.concordanceDataCache$[JSON.stringify(this._filterInputs.filterParams) + term] = res['analysisResponse'][0]['rows'];
            this.resultSet[`${term}`].data = res['analysisResponse'][0]['rows'];
          } else {
            this.resultSet[`${term}`].data = [];
          }
        } else {
          this.resultSet[`${term}`].data = [];
        }
      } else {
        this.resultSet[`${term}`].data = [];
      }
    }
    this.resultSet[`${term}`].loading = false;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * get term and calculate width if that column
   * @param term : string
   * @returns : width of the column
   */
  getWidth(term) {
    let width;
    switch (true) {
      case term.length < 6:
        width = (term.length * 20) + 'px';
        break;
      case term.length > 6:
        width = (term.length * 10) + 'px';
        break;
      default:
        width = '100px';
        break;
    }
    return width;
  }
  /**
 * @description go to docume details page
 * @param searchTerm : term
 * @param docId : document number for each term
 */
  gotoDocumentDetailsPage(searchTerm, docId) {
    this.concordanceService.gotoDocumentPage(searchTerm, docId);
  }
  /**
   * @description :receive slider value
   * @param concordanceSize : slider value as number
   */
  receiveSliderValue(concordanceSize: number, term: String) {
    this.resultSet[`${term}`].sliderValue = concordanceSize;
    this.resultSet[`${term}`].loading = true;
    this.resultSet[`${term}`].data = [];
    this.concordanceService.getConcordanceData(this._filterInputs.filterParams, term, this.limit, concordanceSize, concordanceSize).pipe(
    takeUntil(this.stop$)).subscribe(res => {
        this.concordanceProcess(res, term);
      }, err => {
        this.resultSet[`${term}`].loading = false;
      });
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
