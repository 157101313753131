/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
export const CARROT_CIRCLE_OBJECT = {
    id: 'carrot-circles',
    // pixelRatio: Math.min(1.5, window.devicePixelRatio || 1),
    // centerx: 170,
    // centery: '100%',
    ringScaling: 1.5,
    diameter: 280 * 2,
    angleStart: 0,
    angleWidth: 360,
    deferLabelRedraws: 0,
    labelRedrawFadeInTime: 0.5,
    // ringScaling: 3,
    modelChangeAnimations: 'sequential',
    // backgroundColor: 'rgba(0,0,0,0)',
    attributionPositionX: '98%',
    attributionPositionY: '2%',
    rolloutTime: 0.5,
    rolloutAnimation: 'implode',
    groupFontFamily: 'sans-serif',
    groupMaxFontSize: 15,
    groupMinFontSize: 12,
    captureMouseEvents: true,
    showZeroWeightGroups: true,
    // visibleGroupCount: 2,
    titleBar: 'topbottom',
    titleBarBackgroundColor: 'rgba(0,0,0,.2)',
    titleBarTextColor: '#000',
    titleBarTextPaddingTopBottom: 10,
    titleBarFontFamily: 'sans-serif',
    groupLabelFontFamily: 'sans-serif',
    pixelRatio: 2,
    // zoom: ['1.1', '2.1'],
    // ratioAngularPadding: 2,
    // Wire up events
    onGroupClick: function (e) {  },
    onBeforeZoom: function (e) { return false; },
    onBeforeSelection: function (e) { return false; },

    // Our data model.
    dataObject: {
        open: true,
        groups: null
    }
};
