
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, OnDestroy, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription ,  Subject } from 'rxjs';
import { PageSettingsService } from './../common/services/page-settings.service';
import { environment, APIURLS, availableArchives } from '../../environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../common/services/auth.service';
import { SharedService } from '../common/services/sharedService';
import { ModalService } from '../common/services/modal.service';
import { map as _map } from 'lodash';
import { CommonService } from '../common/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
// declare let $: any;

@Component({
  selector: 'app-landing-page-wrapper',
  templateUrl: './landing-page-wrapper.component.html',
  styleUrls: ['./landing-page-wrapper.component.css'],
  providers: [PageSettingsService]
})
export class LandingPageWrapperComponent implements AfterViewInit, OnDestroy {
  stop$: Subject<void> = new Subject();
  welcomeNoteTutorialContent: string;
  welcomeMessageTitle: string;
  welcomeContent: string;
  welcomeTitle: any;
  showKnownIssues: any;
  currentArchive: any;
  pageSettings;
  // private navObservable: Subscription;
  currentLocation: any;
  welcomeMessage: any;
  // @ViewChild('messageTemplate') private messageTemplate: TemplateRef<any>;
  // @ViewChild('messageTemplate', { static: false }) private messageTemplate: TemplateRef<any>;

  messagemodalRef: BsModalRef;
  issuesUrl: SafeResourceUrl;
  welcomeContent1: string;
  welcomeContent2: string;
  appVersion: string;
  homePageUrl: string;
  unsubscribedArchives: any[];
  wileySubscribedArchives = [];

  constructor(
    private pageSettingService: PageSettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private sharedService: SharedService,
    private modalService: ModalService,
    private commonService: CommonService,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      this.currentArchive = params.archive;
      this.currentLocation = params.col;
    });
    this.translate.get('welcomeMessage.welcomeContent').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.welcomeContent = res;
    });
    this.translate.get('welcomeMessage.welcomeContent1').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.welcomeContent1 = res;
    });
    this.translate.get('welcomeMessage.welcomeContent2').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.welcomeContent2 = res;
    });
    this.translate.get('welcomeMessage.welcomeTitle').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.welcomeMessageTitle = res;
    });
    this.translate.get('welcomeMessage.welcomeNoteTutorialContent').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.welcomeNoteTutorialContent = res;
    });
      PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe(data => {
        this.pageSettings = data;
        this.pageSettings  = this.removeDuplicate(this.pageSettings)
      });
      this.router.events.subscribe(events => {
      // this.router.events.pipe(takeUntil(this.stop$)).subscribe(events => {
        if (events instanceof NavigationEnd) {
          this.pageSettingService.refreshPageSettings().pipe(takeUntil(this.stop$)).subscribe(data => {
            this.pageSettings = data;
            this.pageSettings  = this.removeDuplicate(this.pageSettings)
          });
        }
      });
    /*
    * Check if the location has accesstoken, then show the welcome mwssage only once per session
    */
      if (this.authService.isAuthenticated) {
        // this.httpService.get('../assets/config/locations-config.json', response => {
        this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(response => {
          if (response) {
            // const response: any = sessionStorage.getItem('locationConfigData');
            if (response['welcome_message']) {
              this.welcomeMessage = this.sanitizer.bypassSecurityTrustHtml(response['welcome_message']);
            }
            if (response.welcome_title) {
              this.welcomeTitle = this.sanitizer.bypassSecurityTrustHtml(response.welcome_title);
            }
            if (response.show_known_issues) {
              this.showKnownIssues = response.show_known_issues;
            }
          }
        
        let archiveFound = false;
        /*
        * Receive Peronalization data after loading from service
        */
       this.pageSettingService.personalizationData.pipe(takeUntil(this.stop$)).subscribe(res => {
         if (res) {
           const customerSubscribedArchives = _map(res['archives'], 'shortName');
           customerSubscribedArchives.push('WILEY');
           if (customerSubscribedArchives.indexOf(PageSettingsService.pageSettings.currentArchive.toUpperCase()) !== -1) {
             archiveFound = true;
            } else {
              archiveFound = false;
            }
            // if (archiveFound && !this.authService.isGuestUser) {
            //   if (this.welcomeMessage && !sessionStorage.getItem('welcomeMessage')) {
            //         // this.modalService.showWelcomePopup(this.messageTemplate);
            //         // sessionStorage.setItem('welcomeMessage', this.welcomeContent1);
            //       }
            //     }
              }
            });
        }, error => { });
        this.sharedService.popupChanges('true');
      }
    this.issuesUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/content/pdfs/WDA_KnownIssues_complete.pdf');
  }
  removeDuplicate(data) {
    const ids = data._wileyArchives.map(o => o.shortName)
    const filtered = data._wileyArchives.filter(({shortName}, index) => !ids.includes(shortName, index + 1))
    data._wileyArchives = filtered;
    return data;
  }
  ngAfterViewInit() {
    const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
      if (getVal) {
          // if (sessionStorage.getItem('subscribedArchivesForFacet')) {
          //   sessionStorage.removeItem('subscribedArchivesForFacet')
          // }
          // if (localStorage.getItem('subscribedArchivesForFacet')) {
          //   localStorage.removeItem('subscribedArchivesForFacet')
          // }
          // sessionStorage.setItem('subscribedArchivesForFacet', JSON.stringify(getVal));
          // localStorage.setItem('subscribedArchivesForFacet', JSON.stringify(getVal));
          getVal.forEach(element => {
            this.wileySubscribedArchives.push(element.shortName);
          });
          setTimeout(() => {
            this.unsubscribedArchives = availableArchives.filter(e=>this.wileySubscribedArchives.indexOf(e)<0);
            // sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
            // sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
    
            localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
            localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
          }, 1000);
      } else {
        this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + 
        localStorage.getItem('currentLocation')).pipe(takeUntil(this.stop$)).subscribe((res: any) => {
          // if (sessionStorage.getItem('subscribedArchivesForFacet')) {
          //   sessionStorage.removeItem('subscribedArchivesForFacet')
          // }
          // if (localStorage.getItem('subscribedArchivesForFacet')) {
          //   localStorage.removeItem('subscribedArchivesForFacet')
          // }
          // sessionStorage.setItem('subscribedArchivesForFacet', JSON.stringify(res));
          localStorage.setItem('subscribedArchivesForFacet', JSON.stringify(res));
          res.forEach(element => {
            this.wileySubscribedArchives.push(element.shortName);
          });
          setTimeout(() => {
            this.unsubscribedArchives = availableArchives.filter(e=>this.wileySubscribedArchives.indexOf(e)<0);
            // sessionStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
            // sessionStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
    
            localStorage.setItem('wileySubscribedArchives_tab1', JSON.stringify(this.wileySubscribedArchives));
            localStorage.setItem('unsubscribedArchives_tab2', JSON.stringify(this.unsubscribedArchives));
          }, 1000);
        }, err => { });
      }
    

    // if ($('.grecaptcha-badge')) {
    //   $('.grecaptcha-badge').hide();
    // }
    
    this.appVersion = environment.version;
    const documentList: any = {};
    if (!localStorage.getItem('documentList')) {
      localStorage.setItem('documentList', JSON.stringify(documentList));
    }
    if (!sessionStorage.getItem('wileyShowTopBar')) {
      sessionStorage.setItem('wileyShowTopBar', 'false');
      this.sharedService.setHeader('false');
    }
    // if (!sessionStorage.getItem('currentUrl')) {
    //   const currentUrl: any = '';
    //   sessionStorage.setItem('currentUrl', currentUrl);
    // }

    if (!localStorage.getItem('detailsHistory')) {
      const currentUrl: any = '';
      localStorage.setItem('detailsHistory', currentUrl);
    }

    const historyList: any = [];
    if (!localStorage.getItem('HistoryList')) {
      localStorage.setItem('HistoryList', JSON.stringify(historyList));
    }
  }

  closeMessagePopup() {
    this.sharedService.popupChanges('false');
    this.modalService.hideWelcomePopup();
  }
 

  gotoPrivacyPolicy(): void {
    this.router.navigate([`/${localStorage.getItem('currentArchive') || environment.defaultArchive}/about-us`, {privacy: true}]);
    this.sharedService.popupChanges('false');
    this.modalService.hideWelcomePopup();
  }
  gotoCookies(): void {
    this.router.navigate([`/${localStorage.getItem('currentArchive') || environment.defaultArchive}/about-us`, {cookies: true}]);
    this.sharedService.popupChanges('false');
    this.modalService.hideWelcomePopup();
  }
  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
