
import { takeUntil } from 'rxjs/operators';

import {
  Component,
  AfterContentInit,
  TemplateRef,
  ViewChild,
  Inject,
  OnDestroy,
  ElementRef,
  HostListener,
  Renderer2,
  OnInit
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Subscription, Subject } from 'rxjs';
import { PageSettingsService } from './../common/services/page-settings.service';
import { UrlUtilityService } from '../common/services/url-utility.service';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from './../common/services/modal.service';
import { HttpService } from './../common/services/http.service';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment, APIURLS, rtlLanguages } from '../../environments/environment';
import { SharedService } from '../common/services/sharedService';
import { map as _map, uniq as _uniq, uniqBy as _uniqBy } from 'lodash';
import { CommonService } from '../common/services/common.service';
import { isObject } from 'util';
import { DocumentViewerService } from './document-viewer.service';
import { SearchService } from '../common/services/search.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { AdvancedFilterParams } from '../common/models/AdvancedFilterParams';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
// import { TreeComponent } from '@circlon/angular-tree-component';
import { UtilityService } from '../common/services/utility.service';
// import { isNumeric } from 'rxjs/util/isNumeric';
import { GoogleAnalyticsService } from '../common/services/google-analytics.service';
// import { TreeComponent } from '@ali-hm/angular-tree-component';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
declare let $: any;
// declare let addthis: any;
// declare let OpenSeadragon: any;
// export enum KEY_CODE {
//   ESCAPE_KEYCODE = 27,
//   UP_ARROW = 34,
//   DOWN_ARROW = 33
// }
export const maxPrintPages = 30;

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css'],
  providers: [DocumentViewerService]
})

export class DocumentViewerComponent implements AfterContentInit, OnDestroy, OnInit {
  ENVArchiveFullName = 'Environmental Science and History';
  archivefullName = 'British Association for the Advancement of Science (Collections on the History of Science: 1830 - 1970)';
  showATRContent: Boolean = false;
  disclimerContentText: any;
  ismultiGeoTiffExists: Boolean = false;
  downloadableCSVUrl: any;
  downloadUrlForCSV: string;
  isdisplayAllNotes: Boolean = false;
  documentSourceLanguage: any;
  ignoreFullText: Boolean = false;
  mapCoordinatesNotNull: Boolean = false;
  isMobileView: boolean;
  burgerMenu: boolean;
  mobileView: Boolean = false;
  isGeoTiffCheckBoxEnabled: Boolean = false;
  enableGeotiffCheckBox: Boolean = false;
  matchedDocumentTitles: any[];
  showLearnMoreContent: Boolean = false;
  isTiffAvailable: Boolean = false;
  isFromBookmark: Boolean = false;
  warningObj: any = [];
  private warningssmodalRef: BsModalRef;
  // @ViewChild('warningsTemplate') private warningsTemplate: TemplateRef<any>;
  @ViewChild('warningsTemplate', { static: false }) private warningsTemplate: TemplateRef<any>;
  // @ViewChildren(OsdImageViewerComponent) children: QueryList<OsdImageViewerComponent>;

  isDownLoadPdf: Boolean;
  docutypeDetails: any;
  translatedDocument: any;
  clipBoardValue: any;
  private sharemodalRef: BsModalRef;
  // @ViewChild('shareHtmlPageTemplate') private shareHtmlPageTemplate: TemplateRef<any>;
  @ViewChild('shareHtmlPageTemplate', { static: false }) private shareHtmlPageTemplate: TemplateRef<any>;

  shareMailLoader: Boolean = false;
  shareMailData: ShareMail = {
    'topic': '',
    'message': '',
    'to': '',
    'from': ''
  };
  itemsInOrderResponse: any;
  // @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  // @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  // @ViewChild('PerfectScrollbarComponent', { static: false }) componentRef?: PerfectScrollbarComponent;
  // @ViewChild('PerfectScrollbarDirective', { static: false }) directiveRef?: PerfectScrollbarDirective;

  brightnessValue = 0;
  toggleMenu = false;
  // @ViewChild('itemsTabset') private itemsTabset: TabsetComponent;
  // @ViewChild('documentSearch') documentSearchField: ElementRef;
  @ViewChild('itemsTabset', { static: false }) private itemsTabset: TabsetComponent;
  @ViewChild('documentSearch', { static: false }) documentSearchField: ElementRef;

  shiftContrlKey: string;
  shortcutKeyTitle: string;
  controlShortcut: string;
  // @ViewChild('TreeComponent') private treeComponent: TreeComponent;
  // @ViewChild('TreeComponent', { static: false }) private treeComponent: TreeComponent;

  showNavigationBtn: Boolean = true;
  showFilterbySearchTerms: Boolean = false;
  private itemNotesmodalRef: BsModalRef;
  // @ViewChild('itemNotesTemplate') private itemNotesTemplate: TemplateRef<any>;
  @ViewChild('itemNotesTemplate', { static: false }) private itemNotesTemplate: TemplateRef<any>;

  itemNotes: any;
  isItemsNoteAvailable: Boolean = false;
  isTitleAvailable: Boolean = false;
  currentSelectedPageId: any;
  illustrationsLoader: Boolean = false;
  illustrationPreviewPosition: any;
  // @ViewChild('illustrationsTab') illustrationsTabElement: ElementRef;
  // @ViewChild('illustrationPreviewWindow') illustrationPreviewElement: ElementRef;
  @ViewChild('illustrationsTab', { static: false }) illustrationsTabElement: ElementRef;
  @ViewChild('illustrationPreviewWindow', { static: false }) illustrationPreviewElement: ElementRef;

  illustrationDocumentImage: any;
  homePageUrl: string;
  searchTermFromExplorer: any;
  copyAdvancedFilterParam: any;
  toggleCoverFlow: any;
  showExplorerCoverFlowData: Boolean = false;
  imageviewerframeLoader: Boolean = false;
  searchTerm: any;
  showFillterByRelevantPage: Boolean = false;
  toggleCollectionNotesIcon: never;
  toggleNotesIcon: never;
  toggleFulltextIcon: never;
  tooglePagesIcon: never;
  toggleMetadataIcon: never;
  keyword: any;
  // @Output('docViewerClosed') docViewerClosed: EventEmitter<any> = new EventEmitter();
  private advancedFilterParam: AdvancedFilterParams;
  advancedFilterParamForCoverFlow: any;
  isFromResultPage = false;
  isGuestUser: boolean;
  currentSelectedLanguageValue: any;
  documentViewer = true;
  checkModel: any = { document: true, fullText: false };
  relatedVolumes = [];
  illustrationresultLoader: Boolean = false;
  illustrationNodes: any[];
  monographResultLoader: Boolean = false;
  monographTableofContentNodes: any[];
  // @ViewChild('tree') tree;
  @ViewChild('tree', { static: false }) tree;

  documentNotesFromNotes = [];
  showItemNotes: Boolean = false;
  collectionLoading: Boolean = false;
  collectionId: any;
  collectionNotes: any[];
  loader = { relatedVolume: false, relatedSubjects: false };
  relatedSubjectsData: any;
  manuscriptResultLoader: Boolean = false;
  manuscriptOrderTableofContentNodes: any[];
  sequenceFullTitle: string;
  parentItemTile: string;
  contentType: any;
  documentData: any;
  isRefershPage = false;
  tabs: any[];
  spatialFilterParams: any;
  showWebsites: any;
  // _selectedPage;
  isFromPhotoExplorerPage: boolean;
  guestAuthToken: Boolean = false;
  isFromTermGroupsPage: boolean;
  startValueFromParams: any;
  isFromCollection: Boolean = false;
  viewList: any;
  viewListCount: any;
  currentDocumentImages: any = [];
  accessToken: string;
  isBookmarkPopupOpen: any;
  docTab: boolean;
  showClipBoardSuccessAlert: boolean;
  private bookDetailsMarkmodalRef: BsModalRef;
  bookmarkPageURL: String;
  // @ViewChild('bookmarkHtmlPageTemplate') bookmarkHtmlPageTemplate: TemplateRef<any>;
  // @ViewChild('citationPageTemplate') citationPageTemplate: TemplateRef<any>;
  @ViewChild('bookmarkHtmlPageTemplate', { static: false }) bookmarkHtmlPageTemplate: TemplateRef<any>;
  @ViewChild('citationPageTemplate', { static: false }) citationPageTemplate: TemplateRef<any>;

  citationRes: any;
  private citationmodalRef: any;
  private sourceCitationmodalRef: BsModalRef;
  // @ViewChild('sourceCitationTemplate') private sourceCitationTemplate: TemplateRef<any>;
  @ViewChild('sourceCitationTemplate', { static: false }) sourceCitationTemplate: TemplateRef<any>;

  showHelp: Boolean = false;
  toggleviewlist: Boolean = false;
  toggleArrow: Boolean = true;
  fulltextDisable: Boolean = true;
  hideCoverFlow: any;
  shareUrl: string;
  documentCurrentIndex: number;
  pageId: any;
  isGridViewEnable: Boolean = true;
  documentImages = [];
  currentDocNumberFromThumbnail: any;
  pageContentData: any;
  searchTermCopy: any;
  detailsRedirectParams: any;
  defaultImageToBeLoaded: any;
  isFromMapExplorerPage: any;
  toggledisclaimerShow = false;
  showNoCOntentMessage: boolean;
  currentDocumentNumber: any;
  selectedPageId: any = '';
  isFullTextActive: Boolean = false;
  invalidPagenumber: any;
  limitReachExceded: any;
  limitReachExcededPage: any;
  currentLanguageCode: any;
  errorTranslatedDocument: any;
  documentFulltext: any;
  currentLanguageForTranslate: any;
  scrollToPosition: Object;
  isSearchTermIncludesPhrase: boolean;
  initialSearchTerm: any;
  searchTermwithSpaces: boolean;
  searchtermWithSpaceTerms: any;
  documentSearchTermSplitted = [];
  splitedSearchTerms: any;
  overlaysForTermSearch: any;
  isNoRecodesFound: Boolean = false;
  matchedMetadata: any;
  matchedItemTitles = [];
  fullTextObj: any;
  searchWithInLoading: boolean;
  toggleSearch: Boolean = false;
  showIllustrations: boolean;
  pageLength: string;
  itemFullTitle: string;
  manuscriptTypeTableofContentNodes = [];
  itemsTypeResultLoader: Boolean = false;
  unSubPageId: Subscription;
  pageFullTextObj = [];
  pageFullTextPageIds = [];
  pageFulltextMatchedData = [];
  isFillterByRelevantPage: boolean;
  documentPageImages: any = [];
  // @ViewChild('leftMenuTabset') private leftMenuTabset: TabsetComponent;
  @ViewChild('leftMenuTabset', { static: false }) leftMenuTabset: TabsetComponent;

  imageConfig: Object;
  osdOverlays: any;
  isShowFloatIcons: Boolean = true;
  options: any = {
    height: 950,
    showBrightness: true,
    showZoom: true,
    showRotate: true,
    showShare: true,
    showFullScreen: true,
    showPrint: true,
    showDownload: true,
    showExpand: true,
    showDocument: false,
  };
  showFloatingMenu: Boolean = false;
  hoverFloatingMenu: Boolean = false;
  isPrintMenuOpen = false;
  isDownloadMenuOpen = false;
  isBrightnessMenuOpen = false;
  isImageviewerMenuOpen = false;
  currentZoomLevel = 1;
  currentBrightness = 0;
  currentContrast = 0;
  currentAngle = 0;
  _callZoomOut: { random: number; };
  _callZoomIn: { random: number; };
  contrastAdjusted: Boolean = false;
  brightnessAdjusted: Boolean = false;
  fullTextLoader: Boolean = false;
  isInverted: Boolean = false;
  isCheckedInvertCbx = '';
  isDownloadingInProgress: Boolean;
  isPrintInProgress: Boolean;
  showDragonViewer: Boolean = true;
  fitToWidth: Boolean = true;
  showFittoHeight: Boolean = false;
  restoreOriginalImage: Boolean = false;
  selection: Boolean = false;
  enableOverlay: any = true;
  clipImageToastMessage: any;
  downloadImagesModel: any;
  private validPage: Boolean = false;
  pagesToDownload = '';
  private validPageNumber;
  private notvalidPageNumber;
  private isValidationPage: Boolean = false;
  private downloadPDFmodalRef: BsModalRef;
  printImageModel: any;
  pagesToPrint: any;
  errorMsg: any = '';
  isInvalidPageNumber: Boolean = true;
  // private validPageNumberforpdf;
  enterPageNumber: any;
  invalidRange: any;
  invalidPageRange: any;
  downloadPageURL: Object;
  isInFullScreenMode: Boolean = false;
  fullScreenForIpad: Boolean;
  isPageResized: Boolean = false;
  copytoClipboard: any;
  citationCopiedtoClipboard: any;
  translatedValues: any;
  searchTermNotFound: String = '';
  searchResults: String = '';
  popupBlocked: String = '';
  unblockPopup: String = '';
  printingError: String = '';
  errorWhileDownload: String = '';
  useFollowingLinktoAccessContent: String = '';
  mailSent: String = '';
  emailNotDelivered: String = '';
  pagesText: String = '';
  downloadingText: String = '';
  downloadText: String = '';
  printText: String = '';
  retrievingText: String = '';
  downloadasPdfText: String = '';
  textCopiedtoClipboard: any;
  downloadTablemodalRef: BsModalRef;
  // @ViewChild('downloadTableTemplate') private downloadTableTemplate: TemplateRef<any>;
  @ViewChild('downloadTableTemplate', { static: false }) private downloadTableTemplate: TemplateRef<any>;

  downloadableUrl: string;
  downloadUrlFor: string;
  selectLanguageNotofication: string;
  entireDocumentData: any = [];
  languagemodalRef: BsModalRef;
  sessionDownloadDocuments: any;
  // @ViewChild('languagePageTemplate') private languagePageTemplate: TemplateRef<any>;
  @ViewChild('languagePageTemplate', { static: false }) private languagePageTemplate: TemplateRef<any>;

  // @ViewChild('imageEditorContainer', { read: ElementRef }) imageEditorContainer: ElementRef;
  @ViewChild('imageEditorContainer', { static: false, read: ElementRef }) imageEditorContainer: ElementRef;

  isEditorInitialized: Boolean = false;
  private downloadPrintmodalRef: BsModalRef;
  // @ViewChild('downloadPrintTemplate') private downloadPrintTemplate: TemplateRef<any>;
  @ViewChild('downloadPrintTemplate', { static: false }) private downloadPrintTemplate: TemplateRef<any>;

  private validPrintPage: Boolean = false;
  isTrialCustomerUser: Boolean = false;
  geoBoundaries: any = [];
  isTamilLanguage: Boolean = false;
  documentImagesCount: any;
  bookmarkShortURL: any;
  documentTitleReceived: Boolean = false;
  showRelavantPagesLoader: Boolean = false;
  downLoadTiffImageURL: Object;
  isGuestShare: Boolean;
  downLoadGeoText: String = ' ';
  getDocumentDetailsData: any;
  pdfImage: any;
  pdfPage: number;
  pdfZoom: any;
  totalPages: any;
  hideNexBTN: boolean = false;
  hidePreviousBtn: boolean = false;
  isDragging: any;
  rotation: number;
  brightnesspdf: number;
  contrastpdf: any;
  invertedpdf: boolean;
  pdf: any;
  pdfzoomscale: string = 'page-width';
  // @Input('selected-page')
  // set selectedPage(page: any) {
  // }
  // get selectedPage() {
  //   return this._selectedPage;
  // }
  dynamicComponentData: any;
  // @Input('searchParams')
  // set searchParams(value) {

  //   this.dynamicComponentData = value;
  //   // if (this.dynamicComponentData) {
  //   //   this.isDynamicComponentAvailable = true;
  //   // }
  //   this.currentDocumentId = this.dynamicComponentData['docId'];
  //   if (value.isFromResultsPage) {
  //     this.isFromResultPage = true;
  //   }
  //   // this.getDocumentDetails();
  //   this.callGetDocumentImages();
  //   this.searchTerm = this.dynamicComponentData.searchTerm.concat(' ', this.dynamicComponentData.advancedSearchTerm);
  //   this.documentViewerService.processSearchTerm(this.searchTerm ? this.searchTerm : '', res => {
  //     this.callSearchWithData(res);
  //     this.callgetMetaDatasFromKeyword(this.currentDocumentId, res.toString());
  //     this.callgetPageFulltextFromKeyword(this.currentDocumentId, res.toString());
  //   });
  //   this.isRefershPage = true;
  //   if (this.dynamicComponentData['start']) {
  //     this.startValueFromParams = this.dynamicComponentData['start'];
  //   }
  //   if (this.dynamicComponentData['spatialFilterParams']) {
  //     this.spatialFilterParams = this.dynamicComponentData['spatialFilterParams'];
  //   }
  // }
  // @Output('itemsTitle') private itemsTitle: EventEmitter<any> = new EventEmitter();
  currentDocumentId: String;
  translateLanguageValues: any;
  isInListViewMode: any;
  isSessionGuest: any;
  isArabicLanguage: Boolean = false;
  // isFromFrequencyDistribution: Boolean = false;
  isFromConcordance: Boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  downloadTiffModel: any;
  isArabicTransDoc: Boolean = false;
  toggleTranslate: any;
  isTiffAvailableFromDocInfo: Boolean = false;
  downloadGText: String = '';
  notesGeoBoundaries: any;
  constructor(
    private documentViewerService: DocumentViewerService,
    private httpService: HttpService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private pageSettingService: PageSettingsService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private sharedService: SharedService,
    private urlUtilityService: UrlUtilityService,
    @Inject(DOCUMENT) private dom: any,
    private renderer: Renderer2,
    private router: Router,
    private searchService: SearchService,
    private http: HttpClient,
    private location: Location,
    private translate: TranslateService,
    private utilityService: UtilityService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    localStorage.removeItem('fullScreen');
    // this.modalService.showLoader();
    this.isGuestUser = false;
    this.documentCurrentIndex = 1;
    this.downloadImagesModel = 'all-pages';
    this.printImageModel = 'all-pages';
    this.downloadTiffModel = '';
    this.accessToken = this.httpService.getCurrentAccessToken();
    PageSettingsService.ViewListObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.viewList = data.viewList;
      this.viewListCount = data.count;
    });
    this.getParamsFromRoute();
    this.captureAdvancedFilterParams();
    const advParam = JSON.parse(sessionStorage.getItem('explorerLimiter'));
    if (advParam) {
      this.copyAdvancedFilterParam = new AdvancedFilterParams(advParam);
    }
    setTimeout(() => {
      this.homePageUrl = '/';
      this.homePageUrl += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
    });
    this.getTextFromTranslateService();
    this.translate.get('Detail').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.disclimerContentText = res['disclaimerContent'];
    });
  }
  ngOnInit() {
    if (window.innerWidth <= 1024) {
      // this.toggleMenu = true;
      // this.isGridViewEnable = true;
    }
    if (window.screen.width < 476) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
    ngAfterContentInit() {
    // this.cdr.detectChanges();
    setTimeout(async () => {
      let accessedArchives;
      let locId = localStorage.getItem('currentLocation');
      await this.getDocumentDetails('havetext');
      if (this.getDocumentDetailsData) {
        if (locId) {
          const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
          if (getVal) {
            getVal.forEach(element => {
              if (element.shortName === this.getDocumentDetailsData.shortName) {
                if (element.trialArchive === true) {
                  this.isTrialCustomerUser = true;
                } else {
                  this.isTrialCustomerUser = false;
                }
              }
            });
          } else {
            this.http.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + locId).pipe(
              takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
                if (res) {
                  res.forEach(element => {
                    if (element.shortName === this.getDocumentDetailsData.shortName) {
                      if (element.trialArchive === true) {
                        this.isTrialCustomerUser = true;
                      } else {
                        this.isTrialCustomerUser = false;
                      }
                    }
                  });
                }

              });
          }
        } else {
          if (localStorage.getItem('limitorArchivess')) {
            accessedArchives = JSON.parse(localStorage.getItem('limitorArchives'));
            accessedArchives.forEach(element => {
              if (element.shortName === this.getDocumentDetailsData.shortName) {
                if (element.trialArchive === true) {
                  this.isTrialCustomerUser = true;
                } else {
                  this.isTrialCustomerUser = false;
                }
              }
            });
          } else {
            accessedArchives = JSON.parse(localStorage.getItem('personalizationData'));
            accessedArchives.archives.forEach(element => {
              if (element.shortName === this.getDocumentDetailsData.shortName) {
                if (element.trialArchive === true) {
                  this.isTrialCustomerUser = true;
                } else {
                  this.isTrialCustomerUser = false;
                }
              }
            });
          }
        }
      }
    }, 1000);
    setTimeout(() => {
      const sharedURL = sessionStorage.getItem('sharedURL');
      if (sharedURL) {
        let wileySelectedArchives: any;
        if (localStorage.getItem('wileySelectedArchive')) {
          wileySelectedArchives = JSON.parse(localStorage.getItem('wileySelectedArchive'));
          if (wileySelectedArchives.indexOf(sharedURL) === -1) {
            this.isGuestShare = true;
          } else {
            this.isGuestShare = false;
          }
        }
      } else {
        this.isGuestShare = false;
      }
      // if (document.querySelector('.grecaptcha-badge')) {
      //   (document.querySelector('.grecaptcha-badge') as HTMLElement).style.visibility = 'hidden';
      //   (document.querySelector('.grecaptcha-badge') as HTMLElement).style.opacity = '0';
      // }
    }, 3500);



    //   this.documentViewerService.getDocumentDetail(this.currentDocumentId)
    //     .takeUntil(this.ngUnsubscribe).subscribe((response: any) => {
    //       console.log(response);

    //       if (response) {
    //         if (locId) {
    //           this.http.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + locId)
    //             .takeUntil(this.ngUnsubscribe).subscribe((res: any) => {
    //               if (res) {
    //                 res.forEach(element => {
    //                   if (element.shortName === response.shortName) {
    //                     if (element.trialArchive === true) {
    //                       this.isTrialCustomerUser = true;
    //                     } else {
    //                       this.isTrialCustomerUser = false;
    //                     }
    //                   }
    //                 });
    //               }

    //             });
    //         } else {
    //           if (localStorage.getItem('limitorArchivess')) {
    //             accessedArchives = JSON.parse(localStorage.getItem('limitorArchives'));
    //             accessedArchives.forEach(element => {
    //               if (element.shortName === response.shortName) {
    //                 if (element.trialArchive === true) {
    //                   this.isTrialCustomerUser = true;
    //                 } else {
    //                   this.isTrialCustomerUser = false;
    //                 }
    //               }
    //             });
    //           } else {
    //             accessedArchives = JSON.parse(localStorage.getItem('personalizationData'));
    //             accessedArchives.archives.forEach(element => {
    //               if (element.shortName === response.shortName) {
    //                 if (element.trialArchive === true) {
    //                   this.isTrialCustomerUser = true;
    //                 } else {
    //                   this.isTrialCustomerUser = false;
    //                 }
    //               }
    //             });
    //           }
    //         }

    //       }
    //     });
    // }, 1000);
    // setTimeout(() => {
    //   const sharedURL = sessionStorage.getItem('sharedURL');
    //   if (sharedURL) {
    //     let wileySelectedArchives: any;
    //     if (sessionStorage.getItem('wileySelectedArchive')) {
    //       wileySelectedArchives = JSON.parse(sessionStorage.getItem('wileySelectedArchive'));
    //       if (wileySelectedArchives.indexOf(sharedURL) === -1) {
    //         this.isGuestShare = true;
    //       } else {
    //         this.isGuestShare = false;
    //       }
    //     }
    //   } else {
    //     this.isGuestShare = false;
    //   }
    //   if (document.querySelector('.grecaptcha-badge')) {
    //     (document.querySelector('.grecaptcha-badge') as HTMLElement).style.visibility = 'hidden';
    //     (document.querySelector('.grecaptcha-badge') as HTMLElement).style.opacity = '0';
    //   }
    // }, 3500);

    const browseMapVisitedCount = sessionStorage.getItem('browseMapVisitedCount');
    if (browseMapVisitedCount) {
      sessionStorage.removeItem('browseMapVisitedCount');
    }
    this.sharedService.dropdownToggle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res.help === true) {
        this.showHelp = true;
      } else {
        this.showHelp = false;
      }
      if (res.shareDocument === true) {
        this.toggleviewlist = true;
      } else {
        this.toggleviewlist = false;
      }
      if (res) {
        this.hideCoverFlow = { show: false, random: Math.random() };
      }
    });

    this.httpService.get(environment.APIUrl + APIURLS['getDocumentNotes'] + '/' + this.currentDocumentId, res => {
      if (res) {
        this.showItemNotes = true;
        this.docutypeDetails = res['notes'];
        this.isdisplayAllNotes = res.displayAllNotes;
        if (res['notes']) {
          const documentttype = Object.keys(res['notes']).map(key => ({ type: key, value: res['notes'][key] }));
          this.documentNotesFromNotes = documentttype;
          this.documentNotesFromNotes.map((obj, i) => {
            if (obj.type === 'Geographical Coordinates') {
              this.documentNotesFromNotes.splice(i, 1);
              this.documentNotesFromNotes.push(obj);
            }
          });
          if (this.docutypeDetails) {
            if (this.docutypeDetails['Geographical Coordinates']) {
              this.processGeoCoordinates(this.docutypeDetails['Geographical Coordinates'], 'Notes');
            }
          }
        }
      } else {
        this.showItemNotes = false;
        /*
        * @DESC: remove notes tab if there is no content to display
        */
        for (let index = 0; index < this.tabs.length; index++) {
          if (this.tabs[index].id === 'notesTab') {
            this.tabs.splice(index, 1);
          }
        }
      }
    }, error => { });
    this.sharedService.isGuest.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isGuestUser = res ? true : false;
    });

    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.shortcutKeyTitle = 'Shift + Alt';
      this.shiftContrlKey = 'Ctrl + Shift';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.shortcutKeyTitle = 'Shift + Option';
      this.shiftContrlKey = 'Cmd + Shift';
    }
    this.sessionDownloadDocuments = JSON.parse(sessionStorage.getItem('checkDownloadStatus'));
    if (this.sessionDownloadDocuments) {
      this.sessionDownloadDocuments.forEach(obj => {
        const downloadDocumentlanguageCode = obj.key;
        if (downloadDocumentlanguageCode) {
          this.httpService.get(environment.APIUrl + APIURLS['getTranslatefulltextstatus'] + '/' +
            this.currentDocumentId + '/' + obj.key, res => {
              if (res.status.toUpperCase() === 'SUCCESS') {
                const downloadableLang =
                  sessionStorage.getItem('checkDownloadStatus') ? JSON.parse(sessionStorage.getItem('checkDownloadStatus')) : [];
                for (let index = 0; index < downloadableLang.length; index++) {
                  if (downloadableLang[index]['key'] === obj['key']) {
                    downloadableLang.splice(index, 1);
                  }
                }
                sessionStorage.setItem('checkDownloadStatus', JSON.stringify(downloadableLang));
                setTimeout(() => {
                  // tslint:disable-next-line: max-line-length
                  this.modalService.showNotification('SUCCESS', this.translatedDocument, (this.translatedValues.belowLanguage || 'Below Language - ') + obj.language);
                }, 500);
              }
            }, error => { });
        }
      });
    }
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: LangChangeEvent) => {
      this.errorTranslatedDocument = event.translations['Common']['Popups']['errorTranslatedDocument'];
    });
    if (localStorage.getItem('isTrialCustomerUser') === 'true') {
      // this.isTrialCustomerUser = true;
    }
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
      if (currentLanguage.toLowerCase() === 'ta') {
        this.isTamilLanguage = true;
      } else {
        this.isTamilLanguage = false;
      }
    }
    this.sharedService.language.pipe(
      // tslint:disable-next-line:max-line-length
      takeUntil(this.ngUnsubscribe)).subscribe(res => {
        (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false);
        if (res === 'ta') {
          this.isTamilLanguage = true;
        } else {
          this.isTamilLanguage = false;
        }
      });

    const currentUrl = window.location.href;
    const requestParams = { 'url': currentUrl + ';fromBookmark=true' };
    // this.modalService.showLoader();
    this.httpService.post(environment.APIUrl + APIURLS.bookmarkShortUrl, requestParams, (response) => {
      this.bookmarkShortURL = response.url;
      // this.modalService.hideLoader();
    }, () => {
      // this.modalService.hideLoader();
    });

    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      if (params['mapCoordinatesNotNull']) {
        this.mapCoordinatesNotNull = params['mapCoordinatesNotNull'];
      }
      if (params['ignoreFullText']) {
        this.ignoreFullText = params['ignoreFullText'];
      }
      if (params['fromBookmark']) {
        this.isFromBookmark = true;
      }
      if (params['pageId']) {
        if (params['isFromPhotoExplorer'] || params['isFromMapExplorer']) {
          // this.getFullTextView(this.currentDocumentId, params['pageId'], '');
        }
      }
    });
    if (window.innerWidth <= 768) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    if (window.location.pathname.includes('search') || window.location.pathname.includes('detail')) {
      sessionStorage.setItem('lastSessionPath', window.location.pathname + window.location.search);
    }
    // get page click action for google analytics
    // this.router.events.subscribe(event => {
    //   // this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // ga('set', 'page', event.urlAfterRedirects);
    //     // ga('send', 'pageview');
    //   }
    // });

  }

  getSupportedLanguagesList() {
    this.httpService.get(APIURLS.supportedLanguages, (response) => {
      let isSourceLanguageExist = false;
      this.translateLanguageValues = response.languages;
      this.translateLanguageValues.findIndex((obj, index) => {
        if (!isSourceLanguageExist) {
          if (this.documentData) {
            if (this.documentData.languages.length === 1 && this.documentData.languages[0] === obj.key) {
              this.currentLanguageCode = obj.value;
              this.translateLanguageValues.unshift(this.translateLanguageValues.splice(index, 1)[0]);
              isSourceLanguageExist = true;
              this.currentLanguageForTranslate = this.documentData.languages[0];
            } else {
              // this.currentLanguageForTranslate = 'SELECT';
              this.currentLanguageForTranslate = this.documentData.languages[0];
            }
          }
        }
      });
      if (!isSourceLanguageExist) {
        if (this.documentData) {
          this.documentData.languages.forEach(element => {
            if (this.translateLanguageValues.indexOf(element) > -1) {
              // this.currentLanguageForTranslate = this.documentData.languages[0];
            } else {
              this.translateLanguageValues.unshift({ 'value': '', 'key': element, 'selectedlang': true });
            }
          });
          if (this.documentData.languages.length > 1) {
            this.currentLanguageForTranslate = 'SELECT';
          }
        }
      }
    }, err => { });
    this.sharedService.isGuest.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isGuestUser = res ? true : false;
    });
  }

  getTextFromTranslateService() {
    this.translate.get('Common.Popups').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.translatedValues = res;
      this.searchTermNotFound = res['searchTermNotFound'];
      this.searchResults = res['searchResults'];
      this.invalidRange = res['invalidRange'];
      this.enterPageNumber = res['enterPageNumber'];
      this.invalidPageRange = res['invalidPageRange'];
      this.popupBlocked = res['popupBlocked'];
      this.unblockPopup = res['unblockPopup'];
      this.printingError = res['printingError'];
      this.errorWhileDownload = res['errorWhileDownload'];
      this.clipImageToastMessage = res['clicktoDrawClipBox'];
      this.limitReachExcededPage = res['limitReachExcededPage'];
      this.invalidPagenumber = res['invalidPagenumber'] || 'Invalid Page Number';
      this.copytoClipboard = res['copytoClipboard'] || 'Copy to Clipboard';
      this.citationCopiedtoClipboard = res['citationCopiedtoClipboard'];
      this.useFollowingLinktoAccessContent = res['useFollowingLinktoAccessContent'];
      this.mailSent = res['mailSent'];
      this.emailNotDelivered = res['emailNotDelivered'];
      this.translatedDocument = res['translatedDocument'];
      this.errorTranslatedDocument = res['errorTranslatedDocument'];
      this.selectLanguageNotofication = res['selectLanguageNotofication'] || 'Please select language to download a file';
    });
    this.translate.get('Common.pages').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.pagesText = res;
    });
    this.translate.get('Common').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.downloadingText = res['downloading'];
      this.downloadText = res['download'];
      this.downloadGText = res['download'];
    });
    this.translate.get('Common.print').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.printText = res;
    });
    this.translate.get('Common.retrievingText').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.retrievingText = res;
    });
    this.translate.get('SearchResult').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.clipBoardValue = res;
    });
    this.translate.get('Detail').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this.downLoadGeoText = res['downLoadGeoText'];
      this.downloadasPdfText = res['downloadAsPDF'];
    });
  }

  hideIllustrations(): void {
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].id === 'illustrationTab') {
        this.tabs.splice(index, 1);
      }
    }
  }

  floatingToolbarMouseEnter() {
    this.hoverFloatingMenu = true;
    this.showNavigationBtn = true;
    this.unTrueMenuOptions();
  }

  floatingToolbarMouseLeave() {
    this.hoverFloatingMenu = false;
    this.showFloatingMenu = false;
    this.isImageviewerMenuOpen = false;
  }

  unTrueMenuOptions() {
    this.isDownloadMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isBrightnessMenuOpen = false;
  }

  openImageviewerMenu() {
    this.isImageviewerMenuOpen = !this.isImageviewerMenuOpen;
    this.showNavigationBtn = false;
    this.unTrueMenuOptions();
  }

  openBrightnessMenu() {
    this.isDownloadMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isBrightnessMenuOpen = !this.isBrightnessMenuOpen;
  }

  downloadAsPDF() {
    this.isPrintMenuOpen = false;
    this.isInvalidPageNumber = false;
    this.isBrightnessMenuOpen = false;
    this.isDownloadMenuOpen = !this.isDownloadMenuOpen;
    this.downloadImagesModel = 'all-pages';
    this.downloadTiffModel = 'all-pages';
    if (this.isGeoTiffCheckBoxEnabled) {
      $('.tiff-check-box').trigger('click');
    }
  }

  printImageMenu() {
    this.isPrintMenuOpen = !this.isPrintMenuOpen;
    this.isInvalidPageNumber = false;
    this.isDownloadMenuOpen = false;
    this.isBrightnessMenuOpen = false;
    this.errorMsg = '';
    this.printImageModel = 'all-pages';
  }

  adjustBrightness(value) {
    this.brightnessAdjusted = true;

    if (value <= -255) {
      value = -255;
    }
    if (value > 255) {
      value = 255;
    }
    this.currentBrightness = parseFloat(value);

  }

  adjustContrast(value) {
    this.contrastAdjusted = true;
    if (value < 0) {
      value = 0;
    }
    if (value > 5) {
      value = 5;
    }
    this.currentContrast = parseFloat(value);

  }

  rotateImage(value) {
    this.unTrueMenuOptions();
    this.currentAngle = (value + this.currentAngle) % 4;
    this.rotation = this.currentAngle;

  }
  invertImage() {
    if (this.isInverted) {
      this.isCheckedInvertCbx = 'checked';
    }
    this.isInverted = !this.isInverted;
  }

  zoomImage(factor) {
    this.unTrueMenuOptions();
    if (factor > 0) {
      if (this.currentZoomLevel < 0) {
        this.currentZoomLevel = 0;
      }
      this._callZoomIn = { random: Math.random() };
    } else {
      if (this.currentZoomLevel > 0) {
        this.currentZoomLevel = 0;
      }
      this._callZoomOut = { random: Math.random() };
    }
    this.currentZoomLevel += factor;

  }

  resetToolbar() {
    this.currentContrast = 0;
    this.currentBrightness = 0;
    this.isInverted = false;
    this.contrastAdjusted = false;
    this.brightnessAdjusted = false;
    $('#invertChkbox').prop('checked', '');

  }
  clickedNegative() {
    const elm = document.querySelector<HTMLElement>('.canvasWrapper')!;
    if (this.invertedpdf === true) {
      this.invertedpdf = false;
      elm.style.filter = 'none';
    } else {
      this.invertedpdf = true;
      elm.style.filter = 'grayscale(100%)';
    }
  }
  closeBrightnessToolbar() {
    this.isBrightnessMenuOpen = false;
  }
  fitImageToWidth(flag) {
    this.unTrueMenuOptions();
    this.fitToWidth = flag;
    if (flag === true) {
      this.showFittoHeight = true;
    } else {
      this.showFittoHeight = false;
    }

  }

  resetImage() {
    this.fitImageToWidth(false);
    this.openImageviewerMenu();
    this.unTrueMenuOptions();
    this.restoreOriginalImage = !this.restoreOriginalImage;
    this.currentContrast = 0;
    this.currentBrightness = 0;
    this.isInverted = false;
    this.contrastAdjusted = false;
    this.brightnessAdjusted = false;
    this.currentAngle = 0;
    // this.enableOverlay = false;
    this.selection = false;
    $('#invertChkbox').prop('checked', '');
  }

  selectImage() {
    this.isShowFloatIcons = !this.isShowFloatIcons;
    this.isBrightnessMenuOpen = false;
    this.isPrintMenuOpen = false;
    this.isDownloadMenuOpen = false;
    this.selection = !this.selection;
    if (this.selection) {
      this.modalService.showNotification('INFO', 'INFO', this.clipImageToastMessage || 'Click and drag to draw a box to clip', 2000);
      $('.image-viewer-table').addClass('hide-mouse-action');
      $('.image-photo-highlight').addClass('hide-mouse-action');
      // this.rotationValue.emit({ rotateValue: this.currentAngle, random: Math.random() });
      this.getRotationDirection();
    } else {
      $('.image-viewer-table').removeClass('hide-mouse-action');
      $('.image-photo-highlight').removeClass('hide-mouse-action');
    }
  }

  getRotationDirection() {
    $('#openSeaDragonCanvas').removeClass();
    if (this.currentAngle === 1 || this.currentAngle === -3) {
      $('#openSeaDragonCanvas').addClass('rotateFirst');
    } else if (this.currentAngle === 2 || this.currentAngle === -2) {
      $('#openSeaDragonCanvas').addClass('rotateSecond');
    } else if (this.currentAngle === 3 || this.currentAngle === -1) {
      $('#openSeaDragonCanvas').addClass('rotateThird');
    } else if (this.currentAngle === -0 || this.currentAngle === 0) {
      $('#openSeaDragonCanvas').addClass('rotatezero');
    }
  }

  downloadImageToPDF(isFromPrint?) {
    this.googleAnalyticsService.eventEmitter('Download', 'Click', 'PDF', this.currentDocumentId.toString());
    let urlFromService: any;
    urlFromService = this.documentViewerService.downLoadImage(
      this.isValidationPage,
      this.downloadImagesModel,
      this.validPage,
      this.currentDocumentId,
      this.documentCurrentIndex,
      this.pagesToDownload,
      this.validPageNumber
    );
    if (urlFromService) {
      // this.downloadPageURL = urlFromService + '?access_token=' + this.accessToken;
      this.downloadPageURL = { url: urlFromService + '?access_token=' + this.accessToken, random: Math.random() };
    } else {
      this.downloadPDFmodalRef.hide();
    }
    if (!isFromPrint) {
      this.isDownLoadPdf = true;
      setTimeout(() => {
        this.isDownLoadPdf = false;
      });
      // this.downloadPDFmodalRef = this.bsModalService.show(this.downloadPageasPdfTemplate, { backdrop: true, ignoreBackdropClick: true });
    }
    this.clearSelectPage();
  }

  downloadGeoTiff() {
    let tiffUrlFromService: any;
    tiffUrlFromService = this.documentViewerService.dowLoadTiffImageServiceReq(
      this.downloadTiffModel,
      this.validPage,
      this.currentDocumentId,
      this.currentSelectedPageId,
      this.isGeoTiffCheckBoxEnabled,
      this.ismultiGeoTiffExists
    );
    if (tiffUrlFromService) {
      this.downLoadTiffImageURL = { url: tiffUrlFromService + '&access_token=' + this.accessToken, random: Math.random() };
      this.isDownLoadPdf = true;
    } else {
      this.downloadPDFmodalRef.hide();
    }
    this.clearSelectPage();
  }

  clearSelectPage(arg?: string) {
    this.pagesToDownload = '';
    this.pagesToPrint = '';
    this.errorMsg = '';
    this.isInvalidPageNumber = false;
  }

  closeDownloadPdfModal() {
    this.downloadPDFmodalRef.hide();
  }

  downloadImagesPDF(value) {
    this.downloadImagesModel = value;
  }
  checkEnableGeoTiff(e?) {
    this.isGeoTiffCheckBoxEnabled = !this.isGeoTiffCheckBoxEnabled;
    if (this.isGeoTiffCheckBoxEnabled) {
      this.downloadGText = this.downLoadGeoText;
    } else {
      this.downloadGText = this.downloadText;
    }
  }
  downloadTiffImages(value) {
    this.enableGeotiffCheckBox = true;
    if (value.currentTarget) {
      this.downloadTiffModel = '';
      this.downloadTiffModel = value.currentTarget.checked;
    } else {
      this.downloadTiffModel = '';
      this.downloadTiffModel = value;
      if (this.downloadTiffModel === 'all-pages') {
        if (this.isGeoTiffCheckBoxEnabled) {
          $('.tiff-check-box').trigger('click');
        }
      }
    }
  }
  setPrintImageModel(value) {
    this.printImageModel = value;
  }
  printWarning() {
    this.printImageFromServer();
  }
  printImage(continuePrint?) {
    this.googleAnalyticsService.eventEmitter('Print', 'Click', 'PDF', this.currentDocumentId.toString());
    const ImageUrls: any = [];
    const pagesToPrint: any = [];
    const citationURL = environment.APIUrl + APIURLS['fileUrlImage'] + this.currentDocumentId + '/' +
      this.currentDocumentId + '-citation.jpg' + '?access_token=' + this.accessToken + '&WDALoc=' +
      localStorage.getItem('currentLocation') || environment.defaultLocation;
    if (this.printImageModel === 'all-pages') {
      if (this.currentDocumentImages.length >= maxPrintPages) {
        this.printWarning();
        return;
      } else {
        this.currentDocumentImages.forEach((imgObj, index) => {
          if (this.documentData.citationFileExists) {
            if (index === 0) {
              ImageUrls.push(citationURL, imgObj.file);
            } else {
              ImageUrls.push(imgObj.file);
            }
          } else {
            ImageUrls.push(imgObj.file);
          }
        });
      }
    } else if (this.printImageModel === 'current-page') {
      const currentImageObj = this.currentDocumentImages.find(obj => {
        if (this.currentSelectedPageId === obj.imageId) {
          return obj;
        }
      });
      if (this.documentData.citationFileExists) {
        ImageUrls.push(citationURL, currentImageObj.file);
      } else {
        ImageUrls.push(currentImageObj.file);
      }
    } else if (this.printImageModel === 'selected-page') {
      if (this.pagesToPrint) {
        if (this.pagesToPrint.split(',')) {
          const range = this.pagesToPrint.split(',');
          let j = 0;
          range.forEach(obj => {
            if (range[j].indexOf('-') > -1) {
              const rangeArray = range[j].split('-');
              // tslint:disable-next-line:radix
              for (let iRangeArray = parseInt(rangeArray[0]); iRangeArray <= parseInt(rangeArray[1]); iRangeArray++) {
                pagesToPrint.push(iRangeArray);
              }
            } else {
              // tslint:disable-next-line:radix
              pagesToPrint.push(parseInt(obj));
            }
            j++;
          });
        }
        if (pagesToPrint.length > maxPrintPages) {
          this.printWarning();
          return;
        } else {
          pagesToPrint.forEach((imageIndex, index) => {
            if (this.documentData.citationFileExists) {
              if (index === 0) {
                // tslint:disable-next-line:radix
                ImageUrls.push(citationURL, this.currentDocumentImages[parseInt(imageIndex) - 1].file);
              } else {
                // tslint:disable-next-line:radix
                ImageUrls.push(this.currentDocumentImages[parseInt(imageIndex) - 1].file);
              }
            } else {
              // tslint:disable-next-line:radix
              ImageUrls.push(this.currentDocumentImages[parseInt(imageIndex) - 1].file);
            }
          });
        }
      }

    }
    let imageTag: String = '';
    ImageUrls.forEach((image) => {
      imageTag += '<img  class="printImg" alt="print Image" src=' + image + '>';
    });
    this.printImages(imageTag, continuePrint);
  }
  printImages(images, continuePrint?) {
    // tslint:disable-next-line: max-line-length
    // this.modalService.showNotification('INFO', this.translatedValues.pleaseWait || 'Please Wait', this.translatedValues.printWillTakeSomeTime || 'Print will take some time. Please wait.', 2000);
    this.modalService.showNotification('INFO', 'INFO', this.translatedValues.printWillTakeSomeTime || 'Print will take some time. Please wait.', 2000);
    let printContent = '<html><body onload="printDoc();">';
    printContent += images;
    printContent += '</body><script>' +
      'function printDoc(){' +
      'window.print();' +
      '}' +
      '</script>' +
      '<style> @media print { .printImg { page-break-after:always; }}' +
      'html,body{ width:100%;height:100%;margin:0;text-align:center;}' +
      '.printImg{ max-width: 100%; max-height: 100%;vertical-align: middle;}' +
      '</style>' +
      '</html>';
    const newWin = window.frames['printf'];
    newWin.document.write(printContent);
    newWin.document.close();
    this.clearSelectPage();
  }

  clearSelectPages() {
    this.isInvalidPageNumber = true;
  }

  printImageFromServer() {
    this.isPrintInProgress = true;
    this.downloadPrintmodalRef = this.bsModalService.show(this.downloadPrintTemplate, { backdrop: true, ignoreBackdropClick: true });
    let requestParams: any;
    const pagesToPrint: any = [];
    if (this.printImageModel === 'all-pages') {
      this.validPrintPage = true;
      requestParams = this.currentDocumentId;
    } else if (this.printImageModel === 'current-page') {
      this.validPrintPage = true;
      requestParams = this.currentDocumentId + '/' + (this.documentCurrentIndex);
    } else if (this.printImageModel === 'selected-page') {
      if (this.pagesToPrint === '') {
        this.validPrintPage = false;
        this.errorMsg = this.translatedValues.enterPageNumber;
        this.isPrintInProgress = false;
        this.downloadPrintmodalRef.hide();
      }
      if (this.pagesToPrint) {
        const pagesForSplit = this.pagesToPrint.split(/[ , -]+/);
        pagesForSplit.forEach(obj => {
          const pageNumber = parseInt(obj, 10);
          if (!(pageNumber > this.currentDocumentImages.length) && !this.notvalidPageNumber) {
            if (this.isValidationPage) {
              this.validPrintPage = true;
            }
          } else {
            this.validPrintPage = false;
            this.isPrintInProgress = false;
            this.downloadPrintmodalRef.hide();
          }
        });
      }
      requestParams = this.currentDocumentId + '/' + this.validPageNumber;
    } else {
    }
    if (this.validPrintPage === true) {
      this.documentViewerService.printFromServer(requestParams, (response) => {
        this.downloadPrintmodalRef.hide();
      }, error => {
        this.downloadPrintmodalRef.hide();
      });
      this.isPrintInProgress = false;
      this.downloadPrintmodalRef.hide();
      this.clearSelectPage();
    }


  }
  selectPageChanges() {
    let pageNumber;
    const pageNumArray = [];
    if (this.pagesToDownload || this.pagesToPrint) {
      const pageToValidate = (this.pagesToDownload || this.pagesToPrint);
      const pagesForSplits = pageToValidate.split(/[,]+/);
      pagesForSplits.forEach(ele => {
        const pageElement = ele;
        if (pageElement.includes('-')) {
          const pageToCompare = pageElement.split(/[-]+/);
          if (parseInt(pageToCompare[1], 10)) {
            if (parseInt(pageToCompare[0], 10) < parseInt(pageToCompare[1], 10)) {
              pageNumArray.push('false');
            } else if (parseInt(pageToCompare[0], 10) > parseInt(pageToCompare[1], 10)) {
              pageNumArray.push('true');
            } else {
              pageNumArray.push('false');
            }
          }
        }
      });
      if (pageNumArray) {
        if (pageNumArray.indexOf('true') < 0 || pageNumArray.length === 0) {
          this.notvalidPageNumber = false;
        } else {
          this.notvalidPageNumber = true;
        }
      }
      const pagesForSplit = pageToValidate.split(/[ , -]+/);
      pagesForSplit.forEach(el => {
        pageNumber = parseInt(el, 10);
        if (pageNumber > this.currentDocumentImages.length || pageNumber <= 0) {
          this.notvalidPageNumber = true;
        }
      });
      if (this.notvalidPageNumber) {
        this.isInvalidPageNumber = true;
        this.errorMsg = this.invalidRange;
      } else {
        this.isInvalidPageNumber = false;
        // this.validPageNumberforpdf = true;
        this.isValidationPage = /^(\s*\d+\s*(-\s*\d+\s*)?)(,\s*\d+\s*(-\s*\d+\s*)?)*$/.test(pageToValidate);
        if (this.isValidationPage) {
          this.validPageNumber = pageToValidate.replace(/\s/g, '');
          this.errorMsg = '';
        } else {
          this.isInvalidPageNumber = true;
          this.errorMsg = this.invalidPageRange;
        }
      }
    } else {
      this.errorMsg = this.enterPageNumber;
      this.isInvalidPageNumber = true;
    }
  }

  browserFullScreen(str?) {
    if(str) {
      localStorage.setItem('fullScreen', 'true');
    } else {
      localStorage.setItem('fullScreen', 'false');
    }
    this.openImageviewerMenu();
    this.isImageviewerMenuOpen = !this.isImageviewerMenuOpen;
    if (this.isInFullScreenMode) {
      // try {
      if (this.dom.exitFullscreen) {
        this.dom.exitFullscreen();
      } else if (this.dom.webkitExitFullscreen) {
        this.dom.webkitExitFullscreen();
      } else if (this.dom.mozCancelFullScreen) {
        this.dom.mozCancelFullScreen();
      } else if (this.dom.msExitFullscreen) {
        this.dom.msExitFullscreen();
      } else if (this.dom.webkitExitFullscreen) {
        this.dom.webkitExitFullscreen();
      }
      // } catch (error) {}
      this.isInFullScreenMode = false;
      if (window.innerWidth <= 1024) {
        this.fullScreenForIpad = false;
        this.sharedService.setFullScreen('false');
      }
      // this.renderer.setStyle(this.imageEditorContainer.nativeElement, 'height', '74.5vh');
      // this.documentCurrentIndex = this.documentCurrentIndex -1;
      // const imageObject = this.currentDocumentImages[this.documentCurrentIndex];
      // imageObject.invokedFrom = 'FILMSTRIP';
      // this.loadImage(imageObject, 'imgClick');
      // this.documentCurrentIndex = this.documentCurrentIndex +1;
    } else {
      const elem = this.imageEditorContainer.nativeElement;
      // try {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
      // } catch (error) {}
      this.isInFullScreenMode = true;
      if (window.innerWidth <= 1024) {
        this.fullScreenForIpad = true;
        this.sharedService.setFullScreen('true');
      }
      this.renderer.setStyle(this.imageEditorContainer.nativeElement, 'height', '100vh');
    }
    this.documentCurrentIndex = this.documentCurrentIndex -1;
    const imageObject = this.currentDocumentImages[this.documentCurrentIndex];
    imageObject.invokedFrom = 'FILMSTRIP';
    this.loadImage(imageObject, 'fullscreen');
    this.documentCurrentIndex = this.documentCurrentIndex +1;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isPageResized = !this.isPageResized;
    // if (OpenSeadragon.isFullScreen()) {
    //   this.isInFullScreenMode = true;
    //   this.renderer.setStyle(this.imageEditorContainer.nativeElement, 'height', '100vh');
    // } else {
    //   this.isInFullScreenMode = false;
    //   this.renderer.setStyle(this.imageEditorContainer.nativeElement, 'height', '74.5vh');
    // }
    if (window.innerWidth <= 1024) {
      // this.toggleMenu = true;
      this.isGridViewEnable = true;
    } else {
      this.toggleMenu = false;
    }
    if (window.screen.width < 476) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' && $('#Document-link').hasClass('active')) {
      this.gotoNextDocumentFullText();
    }
    if (event.key === 'ArrowDown' && $('#Document-link').hasClass('active')) {
      this.gotoPreviousDocumentFullText();
    }
    // if (event.key === 'Escape') {
    //   $('#fullScreenModal').hide();
    //   setTimeout(() => {
    //     if (OpenSeadragon.isFullScreen()) {
    //       this.renderer.setStyle(this.imageEditorContainer.nativeElement, 'height', '100vh');
    //     } else {
    //       this.renderer.setStyle(this.imageEditorContainer.nativeElement, 'height', '74.5vh');
    //     }
    //   }, 2000);
    // }
  }
  itemsByType(params) {
    this.itemsTypeResultLoader = true;
    this.documentViewerService.getDocumentItembyType(params).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      const tableOfContent = response;
      // this.itemsTitle.emit(tableOfContent);
      const parentNodes = [];
      if (tableOfContent) {
        const contents = Object.keys(tableOfContent);
        contents.forEach((content, parentIndex) => {
          const childNodes = [];
          if (Array.isArray(tableOfContent[content]) && tableOfContent[content].length > 0) {
            tableOfContent[content].forEach((child, childIndex) => {
              const pages = child.pageNo.split(',');
              if (child.itemTitle == null) {
                child.itemTitle = 'Untitled';
              }
              this.pageLength = pages.length + ' Page(s)';
              childNodes.push({
                name: child.itemTitle + ', ' + this.pageLength,
                tooltipContent: this.itemFullTitle + ', ' + this.pageLength,
                children: [],
                pageId: child.pages[0] ? child.pages[0] : '',
                chilldPageIds: child.pages,
                label: child.label,
                notesAvailable: child.notesAvailable
              });
            });
          }
          parentNodes.push({
            name: content,
            children: childNodes
          });
        });
        this.manuscriptTypeTableofContentNodes = parentNodes;
        this.itemsTypeResultLoader = false;
        this.callItemsTabScrollToTop();
      }
    });
  }


  itemsInOrder(params) {
    this.manuscriptResultLoader = true;
    this.documentViewerService.getDocumentByItem(params).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      const tableOfContent = response;
      const parentNodes = [];
      tableOfContent.forEach((parent, childIndex) => {
        const childNodes = [];
        const pages = parent.pageNo.split(',');
        if (parent.itemTitle == null) {
          parent.itemTitle = 'Untitled';
        }
        this.pageLength = pages.length + 'Page(s)';
        this.parentItemTile = parent.subType + ', ';
        parentNodes.push({
          name: this.parentItemTile + ' ' + parent.itemTitle + ', ' + pages.length + ' Page(s)',
          tooltipContent: this.parentItemTile + ',' + this.sequenceFullTitle + ', ' + pages.length + ' Page(s)',
          children: childNodes,
          pageId: parent.pages[0] ? parent.pages[0] : '',
          chilldPageIds: parent.pages,
          label: parent.label,
          notesAvailable: parent.notesAvailable
        });
      });
      this.manuscriptOrderTableofContentNodes = parentNodes;
      this.manuscriptResultLoader = false;
      this.callItemsInOrderTabScrollToTop();
    });
  }

  itemsInToc(params) {
    this.monographResultLoader = true;
    this.documentViewerService.getDocumentItembyTOC(params).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      if (response) {
        const toc = Object.keys(response['tableOfContent']);
        const parentNodes = [];
        toc.forEach((key, value) => {
          const childNodes = [];
          if (response['tableOfContent'][key].length > 1) {
            response['tableOfContent'][key].forEach((childkey, childValue) => {
              const grandChildNodes = [];
              childNodes.push({
                name: childkey.title,
                children: grandChildNodes,
                pageId: childkey.pages[0] ? childkey.pages[0] : ''
              });
            });
            parentNodes.push({
              name: key,
              children: childNodes
            });
          } else {
            if (response['tableOfContent'][key].length === 1) {
              const childNode = [];
              if (response['tableOfContent'][key][0].title !== key) {
                childNode.push({
                  name: response['tableOfContent'][key][0].title,
                  children: [],
                  pageId: response['tableOfContent'][key][0].pages[0]
                });
                parentNodes.push({
                  name: key,
                  children: childNode
                });
              } else {
                parentNodes.push({
                  name: key,
                  children: [],
                  pageId: response['tableOfContent'][key][0].pages[0]
                });
              }
            }
          }
          this.monographTableofContentNodes = parentNodes;
          this.monographResultLoader = false;
        });
        this.callitemsInTocScrollTop();
      }
    });
  }

  illustraionTypes(params) {
    this.illustrationresultLoader = true;
    this.documentViewerService.getIllustraion(params).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      const tableOfContent = response;
      const parentNodes = [];
      if (tableOfContent) {
        const contents = Object.keys(tableOfContent);
        if (Object.keys(tableOfContent).length > 0) {
          this.showIllustrations = true;
        } else {
          this.hideIllustrations();
        }
        contents.forEach((content, parentIndex) => {
          const childNodes = [];
          if (Array.isArray(tableOfContent[content]) && tableOfContent[content].length > 0) {
            tableOfContent[content].forEach((child, childIndex) => {
              if (child.caption === null) {
                child.caption = 'Untitled Illustration';
              }
              const grandChildNodes = [];
              childNodes.push({
                name: child.caption, children: grandChildNodes, tableId: child.tableId ? child.tableId : '',
                pageId: child.pageId ? child.pageId : '', width: child.width ? child.width : '', height: child.height ? child.height : '',
                hpos: child.hpos ? child.hpos : '', vpos: child.vpos ? child.vpos : '', chilldPageIds: child.pageId
              });
            });
          }
          parentNodes.push({ name: content, children: childNodes });
        });
        this.illustrationNodes = parentNodes;
        this.illustrationresultLoader = false;
        this.callItemsIllustrationsScrollToTop();
      }
    });
  }


  getParamsFromRoute() {
    this.documentViewerService.getCurrentRouteParams(params => {
      if (params['guestAuthToken']) {
        this.guestAuthToken = true;
      }
      if (params['isFromPhotoExplorer']) {
        this.isFromPhotoExplorerPage = true;
      }
      if (params['isFromMapExplorer']) {
        this.isFromMapExplorerPage = true;
      }
      if (params['isFromTermGroupsPage']) {
        this.isFromTermGroupsPage = true;
      }
      if (params['isFromCollectionDetailsPage']) {
        this.isFromCollection = true;
      }
      // if (params['isFromFD']) {
      //   this.isFromFrequencyDistribution = true;
      // }
      if (params['isFromConcordance']) {
        this.isFromConcordance = true;
      }
      if (params['start']) {
        this.startValueFromParams = params['start'];
      }

      if (params['docID']) {

        this.currentDocumentId = params['docID'];
        // this.getDocumentDetails();
        this.callGetDocumentImages();
      }
      if (params['searchTerm']) {
        this.searchTermFromExplorer = params['searchTerm'];
      }
    });
  }


  setCurrentActiveImageIndex(event) {
    if (event.sequenceNumber) {
      this.documentCurrentIndex = event.sequenceNumber;
      event.invokedFrom = 'FILMSTRIP';
      this.loadImage(event, 'imgClick');
    }
  }

  loadImage(imageObject, tableId?) {
    var fullscreenVar = 'false';
    if(tableId === 'fullscreen') {
      fullscreenVar = "true";
      tableId = '';
    } else if (tableId === 'imgClick') {
      this.imageviewerframeLoader = true;
      tableId = '';
    }
    this.downloadTiffModel = 'all-pages';
    if (this.isGeoTiffCheckBoxEnabled) {
      $('.tiff-check-box').trigger('click');
    }
    if (imageObject.isgeoTiffExists) {
      this.isTiffAvailable = true;
    } else {
      this.isTiffAvailable = false;
    }
    if (imageObject.ismultiGeoTiffExists) {
      this.ismultiGeoTiffExists = true;
    } else {
      this.ismultiGeoTiffExists = false;
    }
    this.currentAngle = 0;
    this.fitToWidth = false;
    this.currentSelectedPageId = this.selectedPageId = imageObject.imageId;
    let clickedTableId = null;
    if (tableId) {
      clickedTableId = tableId;
    } else {
      if (imageObject.invokedTableId) {
        clickedTableId = imageObject.invokedTableId;
      }
    }

    if (this.contentType !== 'Maps') {
      this.imageConfig = {
        imageId: imageObject.imageId,
        type: 'image', url: imageObject.file + '?access_token=' + this.accessToken,
        title: imageObject.title ? imageObject.title : 'Untitled',
        invokedFrom: imageObject.invokedFrom ? imageObject.invokedFrom : null,
        clickedTableId: clickedTableId ? clickedTableId : null,
        droneMapExists: imageObject.isdroneMapExists ? imageObject.isdroneMapExists : null,
        preMapExists: imageObject.preMeridianMapExists ? imageObject.preMeridianMapExists : null
      };
      // this.children.forEach(child => child.OnResetParent(this.imageConfig));
    } else if (this.contentType === 'Maps') {
      if (this.documentData.shortName.toLowerCase() === 'env') {
        this.imageConfig = {
          imageId: imageObject.imageId,
          type: 'image', url: imageObject.file + '?access_token=' + this.accessToken,
          title: imageObject.title ? imageObject.title : 'Untitled',
          invokedFrom: imageObject.invokedFrom ? imageObject.invokedFrom : null,
          clickedTableId: clickedTableId ? clickedTableId : null,
          droneMapExists: imageObject.isdroneMapExists ? imageObject.isdroneMapExists : null,
          preMapExists: imageObject.preMeridianMapExists ? imageObject.preMeridianMapExists : null
        };
        // this.children.forEach(child => child.OnResetParent(this.imageConfig));
      } else {
        this.validateTileSource(imageObject.imageId, imageObject.file,
          imageObject.title, imageObject.isdroneMapExists,
          imageObject.preMeridianMapExists, imageObject.invokedFrom);
      }

    }
    this.listViewThumBImages();
    if (this.documentData) {
      if (this.documentData.languages[0]) {
        let sourceLanguage;
        if (this.translateLanguageValues) {
          if (this.documentData && this.translateLanguageValues.length > 0) {
            this.translateLanguageValues.forEach(lang => {
              if (lang.key.toLowerCase() === this.documentData.languages[0].toLowerCase()) {
                sourceLanguage = lang.value;
                this.documentSourceLanguage = lang.value;
              }
            });
          }
        }
        // if (sourceLanguage === this.currentSelectedLanguageValue) {
        //   this.getFullTextView(this.currentDocumentId, imageObject.imageId, '');
        // } else {
        let isFulltextAvailable;
        if (this.isFullTextActive) {
          this.getFullTextView(this.currentDocumentId, imageObject.imageId,
            this.currentSelectedLanguageValue ? this.currentSelectedLanguageValue : sourceLanguage);
        } else {
          this.getFullTextView(this.currentDocumentId, imageObject.imageId,
            this.currentSelectedLanguageValue ? this.currentSelectedLanguageValue : sourceLanguage, isFulltextAvailable = true);
        }
        // }
      }
    } else {
      setTimeout(() => {
        if (this.documentData) {
          if (this.documentData.languages[0]) {
            let sourceLanguage;
            if (this.translateLanguageValues) {
              if (this.documentData && this.translateLanguageValues.length > 0) {
                this.translateLanguageValues.forEach(lang => {
                  if (lang.key.toLowerCase() === this.documentData.languages[0].toLowerCase()) {
                    sourceLanguage = lang.value;
                  }
                });
              }
            }
            // if (sourceLanguage === this.currentSelectedLanguageValue) {
            //   this.getFullTextView(this.currentDocumentId, imageObject.imageId, '');
            // } else {
            let isFulltextAvailable;
            if (this.isFullTextActive) {
              this.getFullTextView(this.currentDocumentId, imageObject.imageId,
                this.currentSelectedLanguageValue ? this.currentSelectedLanguageValue : sourceLanguage);
            } else {
              this.getFullTextView(this.currentDocumentId, imageObject.imageId,
                this.currentSelectedLanguageValue ? this.currentSelectedLanguageValue : sourceLanguage, isFulltextAvailable = true);
            }
            // }
          }
        }
      }, 1500);
    }
    this.selectionPerformed();
    if(fullscreenVar === 'true') {
      setTimeout(() => {
        this.imageviewerframeLoader = false;
      }, 500);
    }
  }

  validateTileSource(imageId, imageUrl, imgTitle, droneMap, preMeridianMap, invokedFrom) {
    if (this.contentType === 'Maps') {
      const documentId = this.currentDocumentId || this.defaultImageToBeLoaded.docId;
      if (documentId !== '') {
        this.documentViewerService.getTileSourceData(documentId, imageId, (response) => {
          if (response) {
            this.imageConfig = {
              type: 'tiles', url: null, tileSource: response, imageId: imageId,
              title: imgTitle ? imgTitle : 'Untitled', droneMapExists: droneMap ? droneMap : null,
              preMeridianMapExist: preMeridianMap ? preMeridianMap : null,
              invokedFrom: invokedFrom ? invokedFrom : null
            };
          } else {
            this.imageConfig = {
              type: 'image', url: imageUrl + '?access_token=' + this.accessToken,
              droneMapExists: droneMap ? droneMap : null, preMeridianMapExist: preMeridianMap ? preMeridianMap : null,
              invokedFrom: invokedFrom ? invokedFrom : null
            };
          }
        }, (error) => { });
      }
    }
  }
  private responseID;
  isImageLoaded(imageConfig) {
    if (this.responseID !== imageConfig && imageConfig.imageId) {
      this.documentViewerService.getIllustrationCoordinatesV2(this.currentDocumentId, imageConfig.imageId, 'imageviewer', (res) => {
        if (res) {
          this.responseID = imageConfig.imageId;
          // setTimeout(() => {
          //    this.imageviewerframeLoader = false;
          // }, 1500);
          if (res.length > 0) {
            const tempOverlays = [];
            res.forEach(overlay => {
              overlay.invokedFrom = imageConfig.invokedFrom;
              if (imageConfig.clickedTableId && overlay.tableId === imageConfig.clickedTableId) {
                overlay.invokedTableId = imageConfig.clickedTableId ? imageConfig.clickedTableId : null;
              }
              tempOverlays.push(overlay);
            });
            this.osdOverlays = tempOverlays;
            this.imageviewerframeLoader = false;
          } else{
            this.imageviewerframeLoader = false; 
          }
        } else {
          this.imageviewerframeLoader = false;
        }
      }, () => { 
        this.imageviewerframeLoader = false;
      });
    }
    this.getRelevantPages(imageConfig.imageId);
  }


  getRelevantPages(pageId) {
    if (this.documentSearchTermSplitted.length > 0) {
      this.splitedSearchTerms = _map(this.documentSearchTermSplitted.filter(res => (res.checked === true)), 'value');
      this.splitedSearchTerms = this.splitedSearchTerms.toString().replace(/,/g, ' ');
    }
    // this.imageviewerframeLoader = true;
    this.documentViewerService.getRelevantPages(pageId, this.splitedSearchTerms, (response) => {
      const searchTermOverlay = [];
      if (response) {
        if (response.results) {
          if (response.results.hasOwnProperty('contents')) {
            if (response.results['contents'].length > 0) {
              response.results['contents'].forEach(imgObj => {
                if (imgObj.height < -1) {
                  imgObj.height = -(imgObj.height - 120);
                } else {
                  imgObj.height = imgObj.height;
                }
                imgObj.type = 'keyword';
                imgObj.xPos = imgObj.hpos;
                imgObj.yPos = imgObj.vpos;
                searchTermOverlay.push(imgObj);
              });
              this.osdOverlays = searchTermOverlay;
            }
          }
        }
        // setTimeout(() => {
        //   this.imageviewerframeLoader = false;
        // }, 1500);
      } else {
        // this.imageviewerframeLoader = false;
      }
    }, !this.toggleSearch, this.currentDocumentId);
    // setTimeout(() => {
    //   this.imageviewerframeLoader = false;
    // }, 1500);
    // this.documentViewerService.getHeadWordsByPages(pageId, (response) => {
    //   const headWordsOverlay = [];
    //   if (response) {
    //     if (response.results) {
    //       if (response.results.hasOwnProperty('contents')) {
    //         if (response.results['contents'].length > 0) {
    //           response.results['contents'].forEach(imgObj => {
    //             if (imgObj.height < -1) {
    //               imgObj.height = -(imgObj.height - 120);
    //             } else {
    //               imgObj.height = imgObj.height;
    //             }
    //             imgObj.type = 'headword';
    //             imgObj.xPos = imgObj.hpos;
    //             imgObj.yPos = imgObj.vpos;
    //             headWordsOverlay.push(imgObj);
    //           });
    //           this.osdOverlays = headWordsOverlay;
    //         }
    //       }
    //     }
    //   }
    // }, this.currentDocumentId);
  }
  callGetDocumentImages() {
      this.httpService.get(environment.APIUrl + APIURLS['getDocumentImagesById'] + '/' + this.currentDocumentId, (res) => {
        if (res) {
         this.documentImagesCount = res;
         const tempImages = [];
         res.forEach(image => {
           const split = image.id.split('-')[3];
           const seqNum: Number = split / 10;
           this.currentDocumentImages.push({
             imageId: image.id,
             file: environment.APIUrl + APIURLS['fileUrlImage'] + this.currentDocumentId + '/' + image.id
               + '?access_token=' + this.accessToken + '&WDALoc=' +
               localStorage.getItem('currentLocation') || environment.defaultLocation,
             thumb: environment.APIUrl + APIURLS['fileUrlThumb'] + this.currentDocumentId + '/' + image.id
               + '?type=small&access_token=' + this.accessToken + '&WDALoc=' +
               localStorage.getItem('currentLocation') || environment.defaultLocation,
             title: image.itemTitle,
             sequenceNumber: seqNum,
             isgeoTiffExists: image.geoTiffExists,
             ismultiGeoTiffExists: image.multiGeoTiffExists,
             isdroneMapExists: image.droneMapExists ? image.droneMapExists : false,
             preMapExists: image.preMeridianMapExists ? image.preMeridianMapExists : null
           });
         });
         let pageIdFromParams: String;
         this.documentViewerService.getCurrentRouteParams(response => {
           pageIdFromParams = response.pageId;
         }, (error) => { });
         if (this.dynamicComponentData) {
           if (this.dynamicComponentData.pageId) {
             this.getImageObjectFromCurrentDocImages(this.dynamicComponentData.pageId);
           } else {
             if (this.currentDocumentImages.length > 0) {
               this.getImageObjectFromCurrentDocImages(this.currentDocumentImages[0]['imageId']);
             }
           }
         } else if (pageIdFromParams) {
           this.getImageObjectFromCurrentDocImages(pageIdFromParams);
         } else {
           if (this.currentDocumentImages.length > 0) {
             this.getImageObjectFromCurrentDocImages(this.currentDocumentImages[0]['imageId']);
           }
         }
        } else {
          this.showNoCOntentMessage = true; 
        }
       }, (error) => { 
         if(error && error['error'] && error['error'].error === 'insufficient_scope') {
          this.showNoCOntentMessage = true; 
         }
       });
  }

  getImageObjectFromCurrentDocImages(imageId) {
    this.currentDocumentImages.map((imageObject) => {
      if (imageObject.imageId === imageId) {
        if (this.dynamicComponentData) {
          if (this.dynamicComponentData.invokedFrom) {
            imageObject.invokedFrom = this.dynamicComponentData.invokedFrom['caller'];
            if (this.dynamicComponentData.invokedFrom['caller'] === 'ILLUSTRATIONS') {
              if (this.dynamicComponentData.invokedFrom['invokedTableId']) {
                imageObject.invokedTableId = this.dynamicComponentData.invokedFrom['invokedTableId'];
              }
            }
          } else {
            imageObject.invokedFrom = 'THUMBNAILS';
          }
          if (this.dynamicComponentData.isContentSubTypeEnable) {
            setTimeout(() => {
              this.leftMenuTabset.tabs[3].active = true;
            }, 1000);
          }
        } else {
          imageObject.invokedFrom = 'THUMBNAILS';
        }
        this.loadImage(imageObject);
        this.documentCurrentIndex = imageObject['sequenceNumber'];
      }
    });
  }

  getDocumentDetails(str?) {
    return new Promise(resolve => {
    this.documentViewerService.getDocumentDetail(this.currentDocumentId).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe(response => {
        if (response) {
          this.getDocumentDetailsData = response;
          if (window.location.pathname.includes('guestAuthToken=')) {
            if (response['shortName']) {
              if (!str) {
                this.showWarning(response['shortName']);
              }
            }
          }
          this.contentType = response['docType'];
          this.documentData = response;
          if (this.documentData.geoTiffExists) {
            this.isTiffAvailableFromDocInfo = this.documentData.geoTiffExists;
          }
          this.getSupportedLanguagesList();
          this.getDocumentShareShortUrl();
          // if (this.documentData.isFromResultsPage) {
          //   this.isFromResultPage = true;
          // }
          this.isRefershPage = false;
          this.tabs = [];
          (this.documentData.docType === 'Monographs') ? this.hideItemsTab() : this.hideTocTab();
          if (this.documentData.docType === 'Monographs') {
            this.tabs.push({ id: 'tocTabs', customClass: 'tocIcon-tabs' });
          } else {
            this.tabs.push({ id: 'itemsTab', customClass: 'itemsIcon-tabs' });
          }
          if (this.documentData.docNoteExist || this.documentData.collectionNoteExist) {
            this.tabs.push({ id: 'notesTab', customClass: 'notesIcon-tabs' });
          }
          if (this.documentData.illustrationExist) {
            this.tabs.push({ id: 'illustrationTab', customClass: 'illustrationIcon-tabs' });
          }
          if (this.documentData.subjectExist) {
            this.tabs.push({ id: 'relatedSubjectsTab', customClass: 'relatedsubIcon-tabs' });
          }
          if (this.documentData.hasVolumes) {
            this.tabs.push({ id: 'volumesTab', customClass: 'relatedsubIcon-tabs' });
          }
          if (this.documentData['spatialFilterParams']) {
            this.spatialFilterParams = this.documentData['spatialFilterParams'];
          }
        }
        if (this.documentData) {
          /**
           * register in document history
           */
          this.registerDetailsHistory(this.documentData['wileyId'], this.documentData['title']);
          this.checkShowWebsite();
          this.documentTitleReceived = true; /* for implementing ghost loader */
        }
        resolve(true);
      }, (error) => {
        if (error && error['error'].statusMessage === 'restricted') {
          this.showNoCOntentMessage = true;
        }
        resolve(true);
        // this.modalService.hideLoader();
      });
    });
  }
  checkShowWebsite() {
    this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        if (this.documentData.shortName) {
          this.showWebsites = res['locations'][this.documentData.shortName].websites;
        }
      }
    });
  }
  hideItemsTab(): void {
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].id === 'itemsTab') {
        this.tabs.splice(index, 1);
      }
    }
  }
  hideTocTab(): void {
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].id === 'tocTabs') {
        this.tabs.splice(index, 1);
      }
    }
  }
  listClick(document) {
    let startValueFromParams;
    if (isNumeric(this.startValueFromParams)) {
      startValueFromParams = this.startValueFromParams;
    } else {
      startValueFromParams = 0;
    }
    this.pageSettingService.addToViewList({
      id: document.wileyId,
      title: document.title,
      citation: document.description,
      imageThumbnails: this.currentDocumentImages.map((thumb) => {
        return environment.APIUrl + 'file/thumb/' + document.wileyId + '/' + thumb.imageId + '?type=small&access_token=' + this.accessToken
          + '&WDALoc=' + localStorage.getItem('currentLocation') || environment.defaultLocation;
      })
    }, document.docType, startValueFromParams);
  }
  bookmarkPageClick(shortUrl) {
    this.googleAnalyticsService.eventEmitter('Bookmark', 'Click', 'Page', this.currentDocumentId.toString());
    if (!this.isBookmarkPopupOpen) {
      //   this.modalService.showLoader();
      this.copytoClipBoard(shortUrl);
      this.docTab = true;
      //  const currentUrl = window.location.href;
      //  const requestParams = { 'url': currentUrl + ';fromBookmark=true' };
      this.isBookmarkPopupOpen = true;
      this.bookmarkPageURL = shortUrl;
      this.bookDetailsMarkmodalRef = this.bsModalService.show(this.bookmarkHtmlPageTemplate,
        { backdrop: true, ignoreBackdropClick: true });
      // this.showClipBoardSuccessAlert = false;
      // this.httpService.post(environment.APIUrl + APIURLS.bookmarkShortUrl, requestParams, (response) => {
      //   this.bookmarkPageURL = response.url;
      //   this.modalService.hideLoader();
      //   this.bookDetailsMarkmodalRef = this.bsModalService.show(this.bookmarkHtmlPageTemplate,
      //     { backdrop: true, ignoreBackdropClick: true });
      // }, () => {
      //   this.isBookmarkPopupOpen = false;
      //   this.modalService.hideLoader();
      // });
    }
  }
  closeBookMarkModal() {
    this.docTab = false;
    this.bookDetailsMarkmodalRef.hide();
    this.isBookmarkPopupOpen = false;
  }
  getJSONKeys(obj) {
    if (typeof obj === 'object') {
      return Object.keys(obj);
    } else {
      return [];
    }
  }
  citationClick() {
    this.docTab = true;
    const requestParams = {
      'wileyId': this.currentDocumentId,
      'citationTypes': ['modern-language-association', 'chicago-author-date', 'apa']
    };
    // this.modalService.showLoader();
    this.documentViewerService.getCitationDetails(requestParams).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.citationRes = response;
      this.citationmodalRef = this.bsModalService.show(this.citationPageTemplate, {
        class: 'large citationPopup',
        backdrop: true, ignoreBackdropClick: true
      });
      // this.modalService.hideLoader();
    });
  }
  closeCitation() {
    this.docTab = false;
    this.citationmodalRef.hide();
  }
  openSoureceCitation() {
    this.sourceCitationmodalRef = this.bsModalService.show(this.sourceCitationTemplate,
      { class: 'large', backdrop: true, ignoreBackdropClick: true });
  }

  closeSourceCitation() {
    this.sourceCitationmodalRef.hide();
  }
  openImageHelp(event) {
    if (!this.showHelp) {
      this.sharedService.dropdownToggleOption({
        tools: false, otherArchives: false, archiveSelected: false, advanceSearch: false, help: true, language: false, shareDocument: false
      });
    } else if (this.showHelp) {
      this.getHeaderMenuStatus();
    }
    event.stopPropagation();
  }

  socialShareToggleList(event) {
    this.googleAnalyticsService.eventEmitter('SHARE_DOCUMENT', 'Click', 'Share', this.currentDocumentId.toString());
    this.commonService.getShareAudit('SHARE_DOCUMENT', this.currentDocumentId);
    // addthis.layers.refresh();
    this.hideCoverFlow = { show: false, random: Math.random() };
    // this.modalService.showLoader();
    this.getHeaderMenuStatus();
    if (this.toggleviewlist) {
      this.getHeaderMenuStatus();
      this.shareUrl = '';
      // addthis.layers.refresh();
      // addthis.update(this.translatedValues.share || 'share', this.translatedValues.url || 'url', this.shareUrl);
      // tslint:disable-next-line: max-line-length
      // addthis.update(this.translatedValues.share || 'share', this.translatedValues.title || 'title', this.documentData.title + this.translatedValues.shareContentThroughLinkDot ||
      //   '. Please use the following link to access content from the Wiley Digital Archives:');
      // tslint:disable-next-line: max-line-length
      // addthis.update(this.translatedValues.share || 'share', this.translatedValues.description || 'description', this.translatedValues.useFollowingLinktoAccessContent || 'Please use the following link to access content from the Wiley Digital Archives:');
      // this.modalService.hideLoader();
    } else {
      // addthis.layers.refresh();
      this.sharedService.dropdownToggleOption({
        tools: false, otherArchives: false, archiveSelected: false, advanceSearch: false, help: false, language: false, shareDocument: true
      });
      // addthis.update(this.translatedValues.share || 'share', this.translatedValues.url || 'url', this.shareUrl);
      // addthis.update(this.translatedValues.share || 'share', this.translatedValues.title || 'title', this.documentData.title +
      // tslint:disable-next-line: max-line-length
      // this.translatedValues.shareContentThroughLinkDot || '. Please use the following link to access content from the Wiley Digital Archives:');
      // tslint:disable-next-line: max-line-length
      // addthis.update(this.translatedValues.share || 'share', this.translatedValues.description || 'description', this.translatedValues.useFollowingLinktoAccessContent || 'Please use the following link to access content from the Wiley Digital Archives:');
      // this.modalService.hideLoader();
      //       const currentHost = this.urlUtilityService.getCurrentUrl();
      //       if (this.documentData) {
      //         if (this.currentDocumentId) {
      //           // this.httpService.get(environment.APIUrl + APIURLS.getGuestToken + this.currentDocumentId, (response) => {
      //           this.activatedRoute.params.takeUntil(this.ngUnsubscribe).subscribe((params) => {
      //             const currentParams = JSON.parse(JSON.stringify(params));
      //             // currentParams['guestAuthToken'] = response.authtoken.access_token;
      //             currentParams['searchTerm'] = '';
      //             delete currentParams['isFromResultsPage'];
      //             currentParams['location_id'] = localStorage.getItem('currentLocation') || environment.defaultLocation;
      //             const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
      //             const urlToShort = currentHost + this.router.createUrlTree([`/${currentArchive}/detail/${this.currentDocumentId}`,
      //               currentParams]).toString();
      //             this.urlUtilityService.getShortUrl(urlToShort, (shortUrlResponse) => {
      //               this.shareUrl = shortUrlResponse.url;
      //               addthis.layers.refresh();
      //               addthis.update(this.translatedValues.share || 'share', this.translatedValues.url || 'url', shortUrlResponse.url);
      //               addthis.update(this.translatedValues.share || 'share', this.translatedValues.title || 'title', this.documentData.title +
      // // tslint:disable-next-line: max-line-length
      //               this.translatedValues.shareContentThroughLinkDot || '. Please use the following link to access content from the Wiley Digital Archives:');
      // // tslint:disable-next-line: max-line-length
      //               addthis.update(this.translatedValues.share || 'share', this.translatedValues.description || 'description', this.translatedValues.useFollowingLinktoAccessContent || 'Please use the following link to access content from the Wiley Digital Archives:');
      //               this.modalService.hideLoader();
      //             }, () => {
      //               this.modalService.hideLoader();
      //             });
      //           });
      //           // }, () => {
      //           //   this.modalService.hideLoader();
      //           // }, 'json', true);
      //         }
      //         this.modalService.hideLoader();
      //       }
    }
    event.stopPropagation();
    this.hideCoverFlow = { show: false, random: Math.random() };
  }

  /* Copy to ClipBoard */
  copytoClipBoard(bookmarkPageURL) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      if (bookmarkPageURL) {
        $(bookmarkPageURL).addClass('welcome');
        const citationEle = bookmarkPageURL;
        const $input = $('#bookmarkURLonTemplate');
        $input.val();
        const el = $input.get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;
        const successful = document.execCommand('copy');
        // const successful = window.navigator['clipboard'].writeText("text to be copied");
        $input.blur();
      }
    } else {
      const textToBeCopied = (bookmarkPageURL).replace(/<(?:.|\n)*?>/gm, '');
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    const copiedToclipBoard = document.execCommand('copy');
    // const copiedToclipBoard = window.navigator['clipboard'].writeText("text to be copied");
    if (copiedToclipBoard) {
      //  this.showClipBoardSuccessAlert = true;
      this.modalService.showNotification('SUCCESS', this.copytoClipboard, this.textCopiedtoClipboard || 'TEXT copied to clipboard');
    }
  }

  /* Download this Page */
  saveTextAsFile() {
    // this.popupService.showLoader();
    if (this.documentData.languages.indexOf(this.currentLanguageForTranslate) > -1) {
      this.documentViewerService.getTextUrl(this.currentDocumentId, this.selectedPageId, '', this.documentSourceLanguage, res => {
        this.downloadableUrl = res;
        this.downloadUrlFor = 'fulltext';
        this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate, { backdrop: true, ignoreBackdropClick: true });
        // this.popupService.hideLoader();
      }, err => {
        // this.popupService.hideLoader();
      });
    } else {
      this.documentViewerService.getTextUrl(this.currentDocumentId, this.selectedPageId,
        ((this.currentLanguageForTranslate.toUpperCase() === 'SELECT') ? '' : this.currentLanguageCode),
        this.documentSourceLanguage, res => {
          this.downloadableUrl = res;
          this.downloadUrlFor = 'fulltext';
          this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate, { backdrop: true, ignoreBackdropClick: true });
          // this.popupService.hideLoader();
        }, err => {
          // this.popupService.hideLoader();
        });
    }
  }

  /* Download Entire Document */
  downloadFullText(docID) {
    if (this.documentData.languages.indexOf(this.currentLanguageForTranslate) > -1) {
      // this.popupService.showLoader();
      const destinationLanguage = '';
      this.documentViewerService.getDownloadfulltextUrl(docID, destinationLanguage,
        this.documentSourceLanguage ? this.documentSourceLanguage : '', res => {
          this.downloadableUrl = res;
          this.downloadUrlFor = 'fulltext';
          // this.popupService.hideLoader();
          this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate, { backdrop: true, ignoreBackdropClick: true });
        }, err => {
          // this.popupService.hideLoader();
        });
    } else {

      if (this.currentLanguageForTranslate.toUpperCase() === 'SELECT') {
        this.modalService.showNotification('WARNING', this.selectLanguageNotofication, '');
      } else {
        this.documentViewerService.getTranslateFulltextStatus(this.currentDocumentId, this.currentLanguageCode, (res) => {
          if (res.status.toUpperCase() === 'PENDING') {
            this.entireDocumentData.push({
              'id': this.currentDocumentId, 'language': this.currentLanguageForTranslate,
              'key': this.currentLanguageCode
            });
            this.languagemodalRef = this.bsModalService.show(this.languagePageTemplate, {
              class: 'download-document',
              backdrop: true, ignoreBackdropClick: true
            });
            sessionStorage.setItem('checkDownloadStatus', JSON.stringify(this.entireDocumentData));
          }
          if (res.status.toUpperCase() === 'SUCCESS') {
            this.downloadEntireDocumentFromService();
          }
          if (res.status.toUpperCase() === 'FAILURE') {
            // tslint:disable-next-line: max-line-length
            this.modalService.showNotification('ERROR', this.errorTranslatedDocument || 'Could not translate at this moment, Please try again later.', '');
          }
        }, err => { });
      }
    }
  }

  downloadEntireDocumentFromService() {
    // this.popupService.showLoader();
    this.documentViewerService.getDownloadfulltextUrl(this.currentDocumentId, this.currentLanguageCode,
      this.documentSourceLanguage ? this.documentSourceLanguage : '', res => {
        this.downloadableUrl = res;
        this.downloadUrlFor = 'fulltext';
        // this.popupService.hideLoader();
        this.downloadTablemodalRef = this.bsModalService.show(this.downloadTableTemplate, { backdrop: true, ignoreBackdropClick: true });
      }, err => {
        // this.popupService.hideLoader();
      });
    // this.documentViewerService.downloadEntireFulltext(this.currentDocumentId, this.currentLanguageCode);
    // this.sessionDownloadDocuments.forEach(obj => {
    //   delete this.sessionDownloadDocuments[obj.key];
    // });
  }

  getRelatedSubjects() {
    this.loader.relatedSubjects = true;
    this.documentViewerService.getRelatedSubjects(this.currentDocumentId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      if (response) {
        if (response.length > 0) {
          this.relatedSubjectsData = response;
        } else {
          this.removeRelatedSubjectTab();
        }
      } else {
        this.removeRelatedSubjectTab();
      }
      this.loader.relatedSubjects = false;
    }, (error) => {
      this.removeRelatedSubjectTab();
      this.loader.relatedSubjects = false;
    });
  }
  removeRelatedSubjectTab() {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].id === 'relatedSubjectsTab') {
        this.tabs.splice(this.tabs.indexOf(this.tabs[i]), 1);
      }
    }
  }
  getCollectionNotes(): void {
    this.collectionNotes = [];
    const collectionId: any = this.collectionId;
    this.collectionLoading = true;
    this.documentViewerService.getCollectionLevelNotes(this.currentDocumentId, this.documentData.collectionNumber).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        const keys = Object.keys(res['notes']).map(key => ({ type: key, value: res['notes'][key] }));
        this.collectionNotes = keys;
        this.collectionLoading = false;
      }
    }, err => {
      this.collectionLoading = false;
      // tslint:disable-next-line: max-line-length
      this.modalService.showNotification('ERROR', this.translatedValues.sorry || 'Sorry!', this.translatedValues.unableToGetCollectionMetadata || 'Unable to get Collection level metadata.');
    });
  }

  getVolumeDetails(): void {
    this.loader.relatedVolume = true;
    this.documentViewerService.getVolumeDetails(this.currentDocumentId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.loader.relatedVolume = false;
      if (res) {
        if (res.hasOwnProperty('volumes')) {
          if (res['volumes']) {
            this.relatedVolumes = res['volumes'];
          }
        }
      }
    }, err => {
      this.loader.relatedVolume = false;
    });
  }

  onDocViewerTabSelect(tabId): void {
    switch (tabId) {
      case 'notesTab':
        if (this.documentData.collectionNoteExist) {
          this.getCollectionNotes();
        }
        this.toggleMenu = false;
        break;
      case 'relatedSubjectsTab':
        this.getRelatedSubjects(); this.selectListView();
        this.toggleMenu = false;
        // this.itemTabEnable = false;
        break;
      case 'volumesTab':
        this.getVolumeDetails();
        break;
      case 'illustrationTab':
        this.illustraionTypes(this.currentDocumentId);
        this.toggleMenu = false;
        // this.itemTabEnable = false;
        break;
      case 'itemsTab':
        this.itemsByType(this.currentDocumentId);
        this.toggleMenu = false;
        // this.itemTabEnable = true;
        break;
      case 'tocTabs':
        this.itemsInToc(this.currentDocumentId);
        this.toggleMenu = false;
        // this.itemTabEnable = false;
        break;
    }
    this.listViewThumBImages();
  }
  expandTreeOnLoad(event) {
    setTimeout(() => {
      event.treeModel.expandAll();
    }, 0);
  }
  addPageIdAsClass(pageIds) {
    return pageIds;
  }
  checkPageIdExist(childNodePageIds) {
    if (childNodePageIds) {
      return childNodePageIds.includes(this.currentSelectedPageId);
    } else {
      return false;
    }
  }
  selectPagesTab() {
    setTimeout(() => {
      if (document.getElementById('thumb' + this.documentCurrentIndex)) {
        document.getElementById('thumb' + this.documentCurrentIndex).scrollIntoView({ behavior: 'smooth' });
      }
    }, 1500);
  }

  reletadSubjectsMouseEnter(subject, event) {
    if (subject) {
      if ($('.relatedsub-link').hasClass('active-sub')) {
        $('.relatedsub-link').removeClass('active-sub');
      }
      event.target.classList.add('active-sub');
    }

  }
  reletadSubjectsMouseLeave(subject, event) {
    if (subject) {
      event.target.classList.remove('active-sub');
    }
  }
  expandAllTree() {
    // setTimeout(() => {
      // this.tree.treeModel.expandAll();
    // }, 1500);
  }

  translateTrigger() {
    this.toggleArrow = !this.toggleArrow;
  }

  translateDoc(destinationLanguage, langKey) {
    this.currentLanguageCode = destinationLanguage;
    if (this.documentData.languages.indexOf(langKey) > -1) {
      let isFulltextAvailable;
      if (this.isFullTextActive) {
        this.getFullTextView(this.currentDocumentId, '' + this.selectedPageId, destinationLanguage);
      } else {
        this.getFullTextView(this.currentDocumentId, '' + this.selectedPageId, destinationLanguage, isFulltextAvailable = true);
      }
      this.currentSelectedLanguageValue = destinationLanguage;
      this.currentLanguageForTranslate = langKey;
      this.toggleArrow = true;
      if (rtlLanguages.indexOf(langKey.toUpperCase()) > -1) {
        $('#rtl-language').addClass('rtl-languages');
        this.isArabicTransDoc = true;
      } else {
        $('#rtl-language').removeClass('rtl-languages');
        this.isArabicTransDoc = false;
      }
    } else {
      let isFulltextAvailable;
      if (this.isFullTextActive) {
        this.getFullTextView(this.currentDocumentId, this.selectedPageId,
          ((this.currentLanguageForTranslate.toUpperCase() === 'SELECT') ? '' : this.currentLanguageCode));
      } else {
        this.getFullTextView(this.currentDocumentId, this.selectedPageId,
          ((this.currentLanguageForTranslate.toUpperCase() === 'SELECT') ? '' : this.currentLanguageCode), isFulltextAvailable = true);
      }
      this.currentSelectedLanguageValue = destinationLanguage;
      this.currentLanguageForTranslate = langKey;
      this.toggleArrow = true;
      if (rtlLanguages.indexOf(langKey.toUpperCase()) > -1) {
        $('#rtl-language').addClass('rtl-languages');
        this.isArabicTransDoc = true;
      } else {
        $('#rtl-language').removeClass('rtl-languages');
        this.isArabicTransDoc = false;
      }
    }
    setTimeout(() => {
      $('.disclaimer-div').trigger('click');
    }, 0);
  }

  getFullTextView(wileyId, pageId, transLangCode, isFulltextAvailable?) {
    if (isFulltextAvailable) {
      let fulltextURL = environment.APIUrl + APIURLS['fileTranslateText'] + '/' + wileyId + '/' + pageId;
      fulltextURL += '?destLanguage=' + transLangCode;
      fulltextURL += '&isFulltextAvailable=' + isFulltextAvailable;
      fulltextURL += '&sourceLanguage=' + this.documentSourceLanguage;
      this.httpService.get(fulltextURL, response => {
        if (response['fullTextExists']) {
          this.fulltextDisable = false;
        } else {
          this.fulltextDisable = true;
        }
      }, err => { });
    } else {
      this.fullTextLoader = true;
      if (transLangCode) {
        let fulltextURL = environment.APIUrl + APIURLS['fileTranslateText'] + '/' + wileyId + '/' + pageId;
        fulltextURL += '?destLanguage=' + transLangCode;
        fulltextURL += '&sourceLanguage=' + this.documentSourceLanguage;
        this.httpService.get(fulltextURL, response => {
          if (response) {
            if (response.status === 'FAILURE') {
              this.modalService.showNotification('ERROR', this.errorTranslatedDocument, '');
            }
            this.fulltextDisable = false;
            this.documentFulltext = response.data;
          } else {
            this.fulltextDisable = true;
            this.documentFulltext = null;
          }
          this.fullTextLoader = false;
        }, err => { });
      } else {
        let fulltextURL = environment.APIUrl + APIURLS['fileTranslateText'] + '/' + wileyId + '/' + pageId;
        fulltextURL += '?destLanguage=' + '';
        fulltextURL += '&sourceLanguage=' + this.documentSourceLanguage;
        this.httpService.get(fulltextURL, response => {
          if (response) {
            this.fulltextDisable = false;
            this.documentFulltext = response.data;
          } else {
            this.fulltextDisable = true;
            this.documentFulltext = null;
          }
          this.fullTextLoader = false;
        }, err => { });
      }
    }
  }

  fulltextCopy(fullText) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const $input = $('#disclaimerContents').text();
      $input.val();
      const el = $input.get(0);
      const editable = el.contentEditable;
      const readOnly = el.readOnly;
      el.contentEditable = true;
      el.readOnly = false;
      const range = document.createRange();
      range.selectNodeContents(el);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;
      const successful = document.execCommand('copy');
      // const successful = window.navigator['clipboard'].writeText("text to be copied");
      $input.blur();
      const msg = successful ? 'successful ' : 'un-successful ';
    } else {
      const textToBeCopied = $('#disclaimerContents').text();
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    const copiedToclipBoard = document.execCommand('copy');
    // const copiedToclipBoard = window.navigator['clipboard'].writeText("text to be copied");
    if (copiedToclipBoard) {
      this.modalService.showNotification('SUCCESS', this.copytoClipboard, this.textCopiedtoClipboard || 'TEXT copied to clipboard');
    }
  }


  changetoFullText(event) {
    this.isFullTextActive = false;
    event.document = true;
    event.fullText = false;
    this.documentViewer = true;
    setTimeout(() => {
      this.fitImageToWidth(false);
      this.unTrueMenuOptions();
      this.restoreOriginalImage = !this.restoreOriginalImage;
      this.isInverted = false;
      this.contrastAdjusted = false;
      this.brightnessAdjusted = false;
      this.currentAngle = 0;
      // this.enableOverlay = false;
      this.selection = false;
      $('#invertChkbox').prop('checked', '');
    }, 0);
  }
  doThisOnSelect(event) {
    this.isFullTextActive = true;
    event.document = false;
    event.fullText = true;
    this.documentViewer = false;

    if (this.currentSelectedLanguageValue) {
      this.translateDoc(this.currentSelectedLanguageValue, this.currentLanguageForTranslate);
    }
    let isFulltextAvailable;
    if (this.isFullTextActive) {
      this.getFullTextView(this.currentDocumentId, '' + this.selectedPageId, this.currentLanguageCode);
    } else {
      this.getFullTextView(this.currentDocumentId, '' + this.selectedPageId, this.currentLanguageCode, isFulltextAvailable = true);
    }
    /* apply arabic language style if the source language includes rtlLanguages */
    if (this.documentData) {
      if (rtlLanguages.indexOf(this.currentLanguageForTranslate.toUpperCase()) > -1) {
        $('#rtl-language').addClass('rtl-languages');
        this.isArabicTransDoc = true;
      } else {
        $('#rtl-language').removeClass('rtl-languages');
        this.isArabicTransDoc = false;
      }
    }
    /*
    * set navigation index on click of tab
    */
    const documentImageIds = _map(this.currentDocumentImages, function (obj) { return obj.imageId; });
    this.documentCurrentIndex = (documentImageIds.indexOf(this.selectedPageId) + 1);
  }

  gotoNextDocumentFullText(str?) {
    const imageObject = this.currentDocumentImages[this.documentCurrentIndex];
    imageObject.invokedFrom = 'FILMSTRIP';
    this.loadImage(imageObject, 'imgClick');
    if (this.documentCurrentIndex <= this.currentDocumentImages.length) {
      this.documentCurrentIndex++;
    }
  }
  gotoCurrentDocumentFulltext(event, doNotScrollOnclick?) {
    if (event.target) {
      if (event.target.value !== '' && event.key === 'Enter') {
        this.documentCurrentIndex = event.target.value;
      }
    } else {
      this.documentCurrentIndex = event;
    }
    if (this.documentCurrentIndex > this.currentDocumentImages.length) {
      this.modalService.showNotification('WARNING', this.invalidPagenumber, this.limitReachExcededPage + this.currentDocumentImages.length);
      this.documentCurrentIndex = this.currentDocumentImages.length;
    }
    const imageObject = this.currentDocumentImages[this.documentCurrentIndex - 1];
    imageObject.invokedFrom = 'FILMSTRIP';
    this.loadImage(imageObject);
  }
  gotoPreviousDocumentFullText() {
    const imageObject = this.currentDocumentImages[this.documentCurrentIndex - 2];
    imageObject.invokedFrom = 'FILMSTRIP';
    this.loadImage(imageObject, 'imgClick');
    if (this.documentCurrentIndex > 1) {
      this.documentCurrentIndex--;
    }

  }
  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.code !== "Backspace" && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onNodeSelect($event, pageID?) {
    if (pageID) {
      const nodeImageObj = [];
      nodeImageObj.push({
        imageId: pageID,
        file: environment.APIUrl + APIURLS['fileUrlImage'] + this.currentDocumentId + '/'
          + pageID + '?access_token=' + this.accessToken + '&WDALoc=' +
          localStorage.getItem('currentLocation') || environment.defaultLocation,
        thumb: environment.APIUrl + APIURLS['fileUrlThumb'] + this.currentDocumentId + '/'
          + pageID + '?type=small&access_token=' + this.accessToken + '&WDALoc=' +
          localStorage.getItem('currentLocation') || environment.defaultLocation
      });
      const imageObject = nodeImageObj[0];
      this.loadImage(imageObject);
      this.documentCurrentIndex = pageID.split('-')[3] / 10;
      this.changetoFullText(this.checkModel);
    } else {
      if (!this.isTitleAvailable) {
        if ($event.node.hasChildren === false) {
          if ($event.node.data['pageId']) {
            const item = $event.node.data.label;
            const nodeImageObj = [];
            nodeImageObj.push({
              imageId: $event.node.data['pageId'],
              // tslint:disable-next-line:max-line-length
              file: environment.APIUrl + APIURLS['fileUrlImage'] + this.currentDocumentId + '/' + $event.node.data['pageId'] + '?access_token=' + this.accessToken + '&WDALoc=' +
                localStorage.getItem('currentLocation') || environment.defaultLocation,
              // tslint:disable-next-line:max-line-length
              thumb: environment.APIUrl + APIURLS['fileUrlThumb'] + this.currentDocumentId + '/' + $event.node.data['pageId'] + '?type=small&access_token=' + this.accessToken + '&WDALoc=' +
                localStorage.getItem('currentLocation') || environment.defaultLocation,
              title: $event.node.data.name
            });
            this.loadImage(nodeImageObj[0]);
            this.documentCurrentIndex = $event.node.data['pageId'].split('-')[3] / 10;
            if ($event.node.data.notesAvailable) {
              this.isItemsNoteAvailable = true;
              this.httpService.get(environment.APIUrl + APIURLS['getDocumentNotes'] + '/' + this.currentDocumentId + '-' + item, res => {
                const itemDetails = res['notes'];
                const documentttype = Object.keys(itemDetails).map(key => ({ type: key, value: itemDetails[key] }));
                this.itemNotes = documentttype;
                setTimeout(() => {
                  if (document.getElementById('thumb' + this.documentCurrentIndex)) {
                    document.getElementById('thumb' + this.documentCurrentIndex).scrollIntoView({ behavior: 'smooth' });
                  }
                }, 1500);
              }, (error) => {
              });
            }
          }
        }

      }
    }
  }
  onTableSelect($event) {
    if ($event.node.hasChildren === false) {
      const tableID = $event.node.data['tableId'];
      const pageId = $event.node.data['pageId'];
      const nodeImageObj = [];
      nodeImageObj.push({
        imageId: pageId,
        file: environment.APIUrl + APIURLS['fileUrlImage'] + this.currentDocumentId + '/'
          + pageId + '?access_token=' + this.accessToken + '&WDALoc=' +
          localStorage.getItem('currentLocation') || environment.defaultLocation,
        thumb: environment.APIUrl + APIURLS['fileUrlThumb'] + this.currentDocumentId + '/'
          + pageId + '?type=small&access_token=' + this.accessToken + '&WDALoc=' +
          localStorage.getItem('currentLocation') || environment.defaultLocation,
        title: $event.node.data.name
      });
      const imageObject = nodeImageObj[0];
      imageObject.invokedFrom = 'ILLUSTRATIONS';
      // const tableIdWithFileName = $event.node.data['tableId'].split('.');
      this.loadImage(imageObject, $event.node.data['tableId']);
      this.documentCurrentIndex = pageId.split('-')[3] / 10;
    }
  }
  loadNodedataToItems($event) {
  }
  loadNodedataIllustrations($event) {
  }
  mouseLeave(treeNode) {
    this.renderer.removeClass(this.illustrationPreviewElement.nativeElement, 'show-illustrationimage');
  }
  mouseEnter(treeNode) {
    if (treeNode.hasChildren === false) {
      this.illustrationDocumentImage = '';
      if (treeNode.data.pageId) {
        const illustrationPageID = treeNode.data.pageId;
        this.illustrationsLoader = true;
        setTimeout(() => {
          // tslint:disable-next-line:max-line-length
          this.illustrationDocumentImage = `${environment.APIUrl}file/thumb/${this.currentDocumentId}/${illustrationPageID}?type=small&access_token=${this.accessToken}&WDALoc=${localStorage.getItem('currentLocation') || environment.defaultLocation}`;
          setTimeout(() => {
            this.illustrationsLoader = false;
          }, 500);
        }, 0);
        this.renderer.addClass(this.illustrationPreviewElement.nativeElement, 'show-illustrationimage');
        if (treeNode.position > (this.illustrationsTabElement.nativeElement.offsetHeight -
          (this.illustrationPreviewElement.nativeElement.offsetHeight + 65))) {
          this.illustrationPreviewPosition = (this.illustrationsTabElement.nativeElement.offsetHeight
            - this.illustrationPreviewElement.nativeElement.offsetHeight);
        } else {
          this.illustrationPreviewPosition = (treeNode.position);
        }
      }
    }

  }
  setSubject(subject) {
    const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
    currentSearchSettings.subject = [subject];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(this.searchTermCopy ? this.searchTermCopy : '', currentSearchSettings);
  }
  selectListView() {
    this.isGridViewEnable = true;
  }

  girdViewThumBImages() {
    this.isGridViewEnable = false;
    if (document.getElementById('thumb' + this.documentCurrentIndex)) {
      setTimeout(() => {
        const el = document.getElementById('thumb' + this.documentCurrentIndex);
        if (el) {
          el.scrollIntoView();
        }
      }, 0);
    }
  }

  listViewThumBImages() {
    this.isGridViewEnable = true;
    const val = this.documentCurrentIndex.toString();
    this.documentCurrentIndex = parseInt(val.replace(/^0+/, ''));
    if (document.getElementById('thumb' + this.documentCurrentIndex)) {
      setTimeout(() => {
        const el = document.getElementById('thumb' + this.documentCurrentIndex);
        if (el) {
          el.scrollIntoView();
        }
      }, 0);
    }
  }

  backToSearchResultsPage(isBrowserBack?) {
    if (this.dynamicComponentData) {
      if (this.dynamicComponentData.isDocViewerShow) {
        // this.docViewerClosed.emit(false);
        this.sharedService.sharebacktoDetailsPageFlag(false);
        if (this.dynamicComponentData.currentOffset) {
          $('html, body').animate({ scrollTop: (this.dynamicComponentData.currentOffset.top - 500) }, 100);
        }
        this.documentViewerService.getCurrentRouteParams(params => {
          const currentParams = JSON.parse(JSON.stringify(params));
          delete currentParams['pageId'];
          const url = this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString();
          this.location.replaceState(url);
        });
      } else {
        this.backToSearchResults();
      }
    } else {
      this.backToSearchResults();
    }
    this.urlUtilityService.setContentSubType(false);
  }

  backToSearchResults() {
    // this.router.navigateByUrl(searchResultsPageURL);
    let searchResultsPageURL = sessionStorage.getItem('SearchResultsPageURLFromSearch');
    if (searchResultsPageURL) {
      this.router.navigateByUrl(searchResultsPageURL);
    } else {
      this.searchService.backToSearchResult(this.searchTermCopy, this.advancedFilterParamForCoverFlow);
    }
  }

  captureAdvancedFilterParams() {
    this.documentViewerService.getCurrentRouteParams(params => {
      if (params) {
        this.isFromResultPage = params.isFromResultsPage;
        const filterParams = params['filterParam'] || params['filter'];
        if (filterParams) {
          this.urlUtilityService.parseUrl(filterParams).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
            this.advancedFilterParam = new AdvancedFilterParams(data);
            this.advancedFilterParamForCoverFlow = this.advancedFilterParam;
          });
        } else {
          const currentArchives = [];
          if (params.selectedArchives) {
            const selectedArchives = params.selectedArchives.split(',');
            const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
            selectedArchives.forEach(archive => {
              currentArchives.push(archive);
            });
            currentSearchSettings.archive = currentArchives;
            this.pageSettingService.updateSearchSettings(currentSearchSettings);
            this.advancedFilterParamForCoverFlow = currentSearchSettings;
          }
        }
        let advancedSearchKeyword = '';

        if (this.advancedFilterParamForCoverFlow) {
          if (this.advancedFilterParamForCoverFlow.searchKeywords) {
            const searchKeywordLength = this.advancedFilterParamForCoverFlow.searchKeywords.length;
            let counter = 1;
            this.advancedFilterParamForCoverFlow.searchKeywords.forEach(element => {
              if (element.fieldName === 'ALL') {
                if (counter < searchKeywordLength) {
                  advancedSearchKeyword += element.fieldValue + ' ';
                } else {
                  advancedSearchKeyword += element.fieldValue;
                }
                counter++;
              }
            });
          }
        }
        if (params['searchTerm'] || filterParams) {
          this.searchTermCopy = params['searchTerm'];
          if (params['searchTerm'] && !filterParams) {
            this.searchTerm = this.searchTermCopy;
          } else if (filterParams && params['searchTerm'] === '') {
            this.searchTerm = advancedSearchKeyword;
          } else if (filterParams && params['searchTerm']) {
            this.searchTerm = this.searchTermCopy.concat(' ', advancedSearchKeyword);
          } else {
            this.searchTerm = '';
          }
        }
        setTimeout(() => {
          this.documentViewerService.processSearchTerm(this.searchTerm ? this.searchTerm : '', res => {
            this.callSearchWithData(res);
            this.callgetMetaDatasFromKeyword(this.currentDocumentId, res.toString());
            // if (!this.isDynamicComponentAvailable) {
            this.callgetPageFulltextFromKeyword(this.currentDocumentId, res.toString());
            // }
          });
        }, 2000);
      }
    }, (err) => { });

  }

  callSearchWithData(res) {
    if (res) {
      this.documentSearchTermSplitted = [];
      if (res.length > 1) {
        this.showFilterbySearchTerms = true;
        res.forEach(obj => {
          this.documentSearchTermSplitted.push({ value: obj, checked: true });
        });
      } else {
        this.documentSearchTermSplitted = [{ value: res, checked: true }];
        if (this.documentSearchTermSplitted[0].value[0]) {
          this.showFilterbySearchTerms = true;
        } else {
          this.showFilterbySearchTerms = false;
        }
      }
    }
  }

  searchWithin(searchTerm) {
    if (this.keyword) {
      this.keyword = this.keyword.trim();
      this.documentViewerService.processSearchTerm(this.keyword ? this.keyword : '', res => {
        if (res) {
          this.searchWithInLoading = true;
          this.callSearchWithData(res);
          this.callgetMetaDatasFromKeyword(this.currentDocumentId, res.toString());
          this.callgetPageFulltextFromKeyword(this.currentDocumentId, res.toString());
        }
      });
    }
  }

  gotoPageFullText(selectedPageId) {
    const docImage = this.currentDocumentImages.find(obj => {
      if (selectedPageId === obj.imageId) {
        return obj;
      }
    });
    this.loadImage(docImage);
    this.doThisOnSelect(this.checkModel);
  }
  gotoCurrentItemTab(event, item) {
    if (this.manuscriptTypeTableofContentNodes) {
      this.manuscriptTypeTableofContentNodes.forEach(obj => {
        obj['children'].forEach(prop => {
          if (prop['label'] === item) {
            this.onNodeSelect(event, prop['pageId']);
          }
        });
      });
      this.leftMenuTabset.tabs[3].active = true;
    }
  }
  loadRelevantPagesFromSearchWithinTab() {
    if (this.pageFullTextObj.length > 0) {
      // this.isFillterByRelevantPage = true;
      this.loadRelaventPage(this.pageFullTextObj[0].pageId);
    }
    // console.log(this.documentPageImages);
    // setTimeout(() => {
    //   if (this.documentPageImages) {
    //     if (this.documentPageImages.length > 0) {
    //       this.loadRelaventPage(this.documentPageImages[0].imageId);
    //       this.modalService.hideLoader();
    //     }
    //   }
    // }, 500);
  }
  checkAccordionStatus(event: Event, accordionGroup) {
    if (typeof (event) === 'boolean') {
      switch (accordionGroup) {
        case 'toggleMetadataIcon':
          this.toggleMetadataIcon = event;
          break;
        case 'tooglePagesIcon':
          this.tooglePagesIcon = event;
          break;
        case 'toggleFulltextIcon':
          this.toggleFulltextIcon = event;
          break;
        case 'toggleNotesIcon':
          this.toggleNotesIcon = event;
          break;
        case 'toggleCollectionNotesIcon':
          this.toggleCollectionNotesIcon = event;
          break;
        default:
          break;

      }
    }
  }
  toggleFilter() {
    this.showFillterByRelevantPage = !this.showFillterByRelevantPage;
    this.loadRelevantPagesFromSearchWithinTab();
  }
  loadRelaventPage(pageNo: any) {
    const currentPageNumber = pageNo.split('-')[3] / 10;
    const relevantPage = this.currentDocumentImages[currentPageNumber - 1];
    relevantPage.invokedFrom = 'RELEVANT_PAGES';
    this.loadImage(relevantPage);
    this.documentCurrentIndex = currentPageNumber;
    this.changetoFullText(this.checkModel);
  }

  callgetMetaDatasFromKeyword(docId, searchterm) {
    if (searchterm) {
      searchterm = searchterm.replace(/,/g, ' ');
      this.documentViewerService.getMetaDatasFromKeyword(docId, searchterm, (response) => {
        this.matchedMetadata = [];
        const matchedDataList = [];
        if (response) {
          // this.modalService.hideLoader();
          this.isNoRecodesFound = false;
          matchedDataList.push(response.results.segregatedFields);
          matchedDataList.forEach(obj => {
            this.matchedMetadata = obj;
          });
          if (this.matchedMetadata) {
            if (this.matchedMetadata[docId]) {
              if (this.matchedMetadata[docId].length > 0) {
                this.matchedMetadata[docId].forEach((obj, index) => {
                  const key: any = Object.keys(obj);
                  if (key[0].toUpperCase() === 'ITEMS') {
                    this.matchedItemTitles = [];
                    this.fullTextObj = obj;
                    const matchedItems = Object.keys(this.fullTextObj).map(prop => ({ type: key, value: this.fullTextObj[key] }));
                    matchedItems.forEach(element => {
                      element.value.forEach(obj2 => {
                        obj2.snipplets.forEach(elem => {
                          this.matchedItemTitles.push(elem);
                        });
                      });
                    });
                  }
                  if (key[0].toUpperCase() === 'CITATION') {
                    this.matchedDocumentTitles = [];
                    this.fullTextObj = obj;
                    const matchedItems = Object.keys(this.fullTextObj).map(prop => ({ type: key, value: this.fullTextObj[key] }));
                    matchedItems.forEach(element => {
                      element.value.forEach(obj2 => {
                        obj2.snipplets.forEach(elem => {
                          this.matchedDocumentTitles.push(elem);
                        });
                      });
                    });
                  }
                });
              }
            }
          }
        } else {
          this.isNoRecodesFound = true;
        }
        this.searchWithInLoading = false;
      }, !this.toggleSearch);
    } else {
      this.searchWithInLoading = false;
    }
  }
  callgetPageFulltextFromKeyword(docId, searchterm, selectPage?) {
    if (searchterm) {
      searchterm = searchterm.replace(/,/g, ' ');
      this.documentViewerService.getPageFulltextFromKeyword(docId, searchterm, (response) => {
        this.pageFulltextMatchedData = [];
        if (response) {
          // this.modalService.hideLoader();
          this.isNoRecodesFound = false;
          this.pageFullTextObj = [];
          this.pageFullTextPageIds = [];
          this.pageFulltextMatchedData = response.results.segregatedFields;
          for (const prop in this.pageFulltextMatchedData) {
            if (prop) {
              if (this.pageFulltextMatchedData[prop]) {
                if (this.pageFulltextMatchedData[prop].length > 0) {
                  this.pageFulltextMatchedData[prop].forEach(obj => {
                    const obj1 = new Object(obj);
                    obj1['pageId'] = prop;
                    this.pageFullTextObj.push(obj1);
                  });
                }
              }
            }
          }
          if (this.pageFullTextObj.length > 0) {
            this.isFillterByRelevantPage = true;
            // sorting by pageId
            this.pageFullTextObj.sort((a, b) => {
              let aVal = a.pageId.split('-');
              let bVal = b.pageId.split('-');
              return aVal[aVal.length-1] - bVal[bVal.length-1];
            });
            // this.loadRelaventPage(this.pageFullTextObj[0].pageId);
          } else {
            this.isFillterByRelevantPage = false;
          }
          this.pageFullTextObj.forEach(obj => {
            this.pageFullTextPageIds.push({ id: obj['pageId'] });
          });
          /* relevant page initial load issuse while clicking on the show relevent page it doesn`t show any images issue */
          this.searchService.getImageURLs(docId, this.pageFullTextPageIds, (imageURLs) => {
            this.documentPageImages = imageURLs;
            this.documentPageImages = this.documentPageImages.sort((a, b) => a.pageNumber - b.pageNumber);
            if (this.documentPageImages.length > 0) {
              if (!this.isFillterByRelevantPage || this.leftMenuTabset.tabs[2].active === true) {
                // this._selectedPage = { pageId: this.documentPageImages[0].imageId };
                this.loadRelaventPage(this.documentPageImages[0].imageId);
              }
            }
          });
        }
        this.searchWithInLoading = false;
      }, !this.toggleSearch);
    } else {
      this.searchWithInLoading = false;
    }
  }

  getSimpleImageURl(imageId) {
    return environment.APIUrl + APIURLS['fileUrlThumb'] + this.currentDocumentId + '/' + imageId;
  }

  getImagePageNumber(imageId) {
    return (imageId.split('-')[3] / 10);
  }

  getRelevantPagesBySearchTerm(event) {
    this.searchWithInLoading = true;
    this.pageFullTextObj = [];
    this.matchedMetadata = [];
    this.splitedSearchTerms = [];
    this.documentSearchTermSplitted = _uniqBy(this.documentSearchTermSplitted, 'value');
    if (this.documentSearchTermSplitted.length > 0) {
      // this.modalService.showLoader();
      this.splitedSearchTerms = _map(this.documentSearchTermSplitted.filter(res => (res.checked === true)), 'value');
      this.splitedSearchTerms = this.splitedSearchTerms.toString().replace(/,/g, ' ');
      this.callgetMetaDatasFromKeyword(this.currentDocumentId, this.splitedSearchTerms);
      this.callgetPageFulltextFromKeyword(this.currentDocumentId, this.splitedSearchTerms);
      this.loadRelevantPagesFromSearchWithinTab();
    }
    if (this.splitedSearchTerms.length === 0) {
      this.isNoRecodesFound = true;
      this.searchWithInLoading = false;
      this.pageFullTextObj = [];
      this.pageFullTextPageIds = [];
      $('.toggleHighlight').hide();
      $('.image-keyword-highlight').addClass('hide');
    }
  }

  getSearchResultsBasedonParams(documentType) {
    this.showExplorerCoverFlowData = !this.showExplorerCoverFlowData;
    this.toggleCoverFlow = { show: true, random: Math.random() };
    this.getHeaderMenuStatus();
  }
  getSearchResultsFromPhotoExplorer() {
    this.showExplorerCoverFlowData = !this.showExplorerCoverFlowData;
    this.toggleCoverFlow = { show: true, random: Math.random() };
  }
  getSearchResultsFromMapExplorer() {
    this.showExplorerCoverFlowData = !this.showExplorerCoverFlowData;
    this.toggleCoverFlow = { show: true, random: Math.random() };
  }
  backToPhotoExplorer(spatialFilterParams) {
    const photoExplorerPageURL = sessionStorage.getItem('photoExplorerPageURL');
    this.router.navigateByUrl(photoExplorerPageURL ? photoExplorerPageURL : '');
  }
  backToMapExplorer(spatialFilterParams) {
    const mapExplorerPageURL = sessionStorage.getItem('mapExplorerPageURL');
    this.router.navigateByUrl(mapExplorerPageURL ? mapExplorerPageURL : '');
  }
  backToTermGroups() {
    const termGroupsPageURL = sessionStorage.getItem('termGroupsPageURL');
    this.router.navigateByUrl(termGroupsPageURL);
    // setTimeout(() => {
    //   sessionStorage.removeItem('TGLastTileIfo');
    //   sessionStorage.removeItem('selectedGroup');
    // }, 2500);
  }
  backToCollection() {
    const collectionPageURL = sessionStorage.getItem('collectionPageURL');
    // this.router.navigateByUrl(collectionPageURL);
    window.location.href = window.location.protocol + '//' + window.location.host + collectionPageURL;
  }
  togglefuzzySearch() {
    this.toggleSearch = !this.toggleSearch;
    let keyWords;
    keyWords = this.documentSearchTermSplitted.filter(res => {
      if (res.checked) { return res.value; }
    });
    keyWords = keyWords.map(res => {
      return res.value;
    });
    keyWords = keyWords.toString().replace(/,/g, ' ');
    this.documentViewerService.processSearchTerm(keyWords, res => {
      if (res) {
        this.callgetMetaDatasFromKeyword(this.currentDocumentId, res.toString());
        this.callgetPageFulltextFromKeyword(this.currentDocumentId, res.toString());
      }
    });
  }
  gotoAuthorDetail(author) {
    const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
    currentSearchSettings.searchKeywords = [{ fieldName: 'authors', fieldValue: author, operator: 'AND' }];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    const searchTerm = '';
    this.searchService.backToSearchResult(searchTerm, currentSearchSettings);
  }

  gotoCollectionDetails(collectionId) {
    this.router.navigate([this.homePageUrl + '/collection-details'],
      { queryParams: { archive: this.documentData.shortName, id: collectionId, scrollTo: this.currentDocumentId } });
  }
  gotoCorrespondingTab(metadata) {
    const metadataKey = Object.keys(metadata);
    if (metadataKey.toString() === 'CITATION') {
      this.leftMenuTabset.tabs[1].active = true;
    } else if (metadataKey.toString() === 'NOTES') {
      for (let i = 0; i < this.leftMenuTabset.tabs.length; i++) {
        const currentTabId = this.leftMenuTabset.tabs[i].id;
        if (currentTabId === 'notesTab') {
          this.leftMenuTabset.tabs[i].active = true;
        }
      }
    } else if (metadataKey.toString() === 'SUBJECT') {
      for (let i = 0; i < this.leftMenuTabset.tabs.length; i++) {
        const currentTabId = this.leftMenuTabset.tabs[i].id;
        if (currentTabId === 'relatedSubjectsTab') {
          this.leftMenuTabset.tabs[i].active = true;
        }
      }
    }
  }
  selectionPerformed(event?) {
    this.selection = false;
    this.isShowFloatIcons = true;
  }
  getItemNotes(item) {
    this.isTitleAvailable = true;
    this.isItemsNoteAvailable = false;
    this.httpService.get(environment.APIUrl + APIURLS['getDocumentNotes'] + '/' + this.currentDocumentId + '-' + item, res => {
      const itemDetails = res['notes'];
      const documentttype = Object.keys(itemDetails).map(key => ({ type: key, value: itemDetails[key] }));
      this.itemNotes = documentttype;
      this.itemNotes.forEach((element, i) => {
        if (element.type === 'Geographical Coordinates') {
          element.visible = true;
          this.itemNotes.splice(i, 1);
          this.itemNotes.push(element);
        } else {
          element.visible = false;
        }
      });
      if (itemDetails['Geographical Coordinates']) {
        this.processGeoCoordinates(itemDetails['Geographical Coordinates'], 'itemNotes');
      }
      this.itemNotesmodalRef = this.bsModalService.show(this.itemNotesTemplate, { backdrop: true, ignoreBackdropClick: true });
      $('.notes-content').hide();
    }, (error) => {
    });
  }
  closeItemsNotePopup() {
    this.itemNotesmodalRef.hide();
    this.isTitleAvailable = false;
  }
  ngOnDestroy() {
    this.isRefershPage = true;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  gotoHome() {
    this.router.navigate([this.homePageUrl]);
  }
  onOverlayClick(tableId) {
    if (isObject(tableId)) {
      tableId = tableId['tabId'];
    }
    this.accessToken = this.httpService.getCurrentAccessToken();
    if (tableId && this.currentDocumentId && (this.documentData.shortName
      ? this.documentData.shortName.toUpperCase() != 'WPA' && this.documentData.shortName.toUpperCase() != 'WPA1' : true)
    ) {
      // this.popupService.showLoader();
      this.documentViewerService.getDownloadTableUrl(this.currentDocumentId, tableId, res => {
        this.downloadableUrl = res;
        this.downloadUrlFor = 'excel';
        // this.popupService.hideLoader();
        $('#fullScreenDownloadPopup').modal('show');
        $('.docu-viewer-div').find('.modal-backdrop.fade.in').addClass('removeBackdrop');
        $('.docu-viewer-div>.backdrop-modal').addClass('activeModal');
      }, err => {
        // this.popupService.hideLoader();
        let tableDownloadErrorToast;
        this.translate.get('Detail.illustrationTableDownloadError').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
          // tslint:disable-next-line:max-line-length
          tableDownloadErrorToast = res || 'Table data file is not available at this time. We appreciate your patience while we continue to load all the files. Please check back soon';
        });
        this.modalService.showNotification('ERROR', this.translatedValues.noFileFound || 'No file found', tableDownloadErrorToast);
      });

      this.documentViewerService.getDownloadTableCSV(this.currentDocumentId, tableId, res => {
        this.downloadableCSVUrl = res;
        this.downloadUrlForCSV = 'csv';
        // this.popupService.hideLoader();
        $('#fullScreenDownloadPopup').modal('show');
        $('.docu-viewer-div').find('.modal-backdrop.fade.in').addClass('removeBackdrop');
        $('.docu-viewer-div>.backdrop-modal').addClass('activeModal');
      }, err => {
        // this.popupService.hideLoader();
        let tableDownloadErrorToast;
        this.translate.get('Detail.illustrationTableDownloadError').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
          // tslint:disable-next-line:max-line-length
          tableDownloadErrorToast = res || 'Table data file is not available at this time. We appreciate your patience while we continue to load all the files. Please check back soon';
        });
        this.modalService.showNotification('ERROR', this.translatedValues.noFileFound || 'No file found', tableDownloadErrorToast);
      });
    }
  }
  removeBackDrop() {
    $('.modal-backdrop.fade.in').removeClass('removeBackdrop');
    $('.backdrop-modal').removeClass('activeModal');
  }
  closeDownloadTableModal() {
    if (this.downloadTablemodalRef) {
      this.downloadTablemodalRef.hide();
    }
  }
  downIcon(val) {
    this.onOverlayClick(val);
  }
  // expandIllustrationTree() {
  //   // setTimeout(() => {
  //     // this.treeComponent.treeModel.expandAll();
  //   // }, 1500);
  // }
  copyShareUrl(sheredUrl) {
    const textToBeCopied = (sheredUrl).replace(/<(?:.|\n)*?>/gm, '');
    const textarea = this.dom.createElement('textarea');
    textarea.style.height = '0px';
    textarea.style.left = '-100px';
    textarea.style.opacity = '0';
    textarea.style.position = 'fixed';
    textarea.style.top = '-100px';
    textarea.style.width = '0px';
    this.dom.body.appendChild(textarea);
    textarea.value = textToBeCopied;
    textarea.select();
    const copiedToclipBoard = document.execCommand('copy');
    // const copiedToclipBoard = window.navigator['clipboard'].writeText("text to be copied");
    if (copiedToclipBoard) {
      this.modalService.showNotification('SUCCESS', this.clipBoardValue.URLCopiedToClipboard || 'URL Copied to clipboard.', '');
    }
  }

  shareMailClick(shareUrl) {
    if (!$('.modal').hasClass('in') && !this.shareMailLoader) {
      this.shareMailLoader = true;
      // this.modalService.showLoader();
      this.docTab = true;
      this.shareMailData = {
        'topic': this.documentData.title,
        'message': '',
        'to': ''
      };
      if (shareUrl) {
        this.shareMailData.message = this.useFollowingLinktoAccessContent + ' \n' + shareUrl;
        if (!$('.modal').hasClass('in')) {
          this.sharemodalRef = this.bsModalService.show(this.shareHtmlPageTemplate, { backdrop: true, ignoreBackdropClick: true });
          this.shareMailLoader = false;
        }
      }
      this.shareMailLoader = false;
      // this.modalService.hideLoader();
    }
  }

  closeShareModal() {
    this.docTab = false;
    this.sharemodalRef.hide();
    this.shareMailLoader = false;
  }

  shareEmail() {
    this.utilityService.sendToMailClient(this.shareMailData.topic,
      this.shareMailData.message, this.shareMailData.to, this.shareMailData.from).then(res => {
        this.modalService.showNotification('SUCCESS', this.mailSent || 'Mail Sent', '');
        this.closeShareModal();
      }, err => {
        this.modalService.showNotification('WARNING', this.emailNotDelivered || 'Email has not been Delivered', '');
        this.closeShareModal();
      });
  }
  activateLeftMenuTabset(tabId: string) {
    for (let i = 0; i < this.leftMenuTabset.tabs.length; i++) {
      if (this.leftMenuTabset.tabs[i].id === tabId) {
        this.leftMenuTabset.tabs[i].active = true;
      }
    }
  }

  @HostListener("document:fullscreenchange", ["$event"]) onKeydownHandler(
    event: KeyboardEvent) {
    // console.log(event)
    // has exited
    if (!document.fullscreenElement) { 
      localStorage.setItem('fullScreen', 'false');
      this.isInFullScreenMode = false;
      this.renderer.setStyle(this.imageEditorContainer.nativeElement, 'height', '74.5vh');
      this.documentCurrentIndex = this.documentCurrentIndex -1;
      const imageObject = this.currentDocumentImages[this.documentCurrentIndex];
      imageObject.invokedFrom = 'FILMSTRIP';
      this.loadImage(imageObject, 'imgClick');
      this.documentCurrentIndex = this.documentCurrentIndex +1;
    } else {
      localStorage.setItem('fullScreen', 'true');
      this.isInFullScreenMode = true;
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.key === 'PageDown') {
      if (this.documentImages.length !== this.documentCurrentIndex) {
        this.gotoNextDocumentFullText();
        e.preventDefault();
      }
    } else if (e.key === 'PageUp') {
      if (this.documentCurrentIndex > 1 || !this.documentCurrentIndex) {
        this.gotoPreviousDocumentFullText();
        e.preventDefault();
      }
    }
    if (e.shiftKey && e.altKey) {
      switch (e.key) {
        case 'n':
          if ($('#Brightness').hasClass('effect-active')) {
            $('#invertChkbox').trigger('click');
            $('#Brightness').addClass('effect-active');
          } else {
            $('#Brightness').trigger('click');
            setTimeout(() => {
              $('#invertChkbox').trigger('click');
            }, 0);
          }
          break;
        case 'y':
          this.activateLeftMenuTabset('itemsTab');
          break;
        case 'k':
          // if (!this.isBookmarkPopupOpen) {
          //   this.bookmarkPageClick();
          // } else {
          // }
          break;
        case 'w':
          this.leftMenuTabset.tabs[2].active = true;
          this.documentSearchField.nativeElement.focus();
          break;
        case 'z':
          this.toggleMenu = false;
          break;
        case 'ArrowDown':
          this.getSearchResultsBasedonParams(this.documentData.docType);
          break;
        case '/?':
          this.activateLeftMenuTabset('resultViewTab');
          break;
        case 'j':
          this.activateLeftMenuTabset('citationTab');
          break;
        case 's':
          this.activateLeftMenuTabset('SearchWithinDocTab');
          break;
        case 'b':
          this.activateLeftMenuTabset('illustrationTab');
          break;
        case 'm':
          this.activateLeftMenuTabset('notesTab');
          break;
        case 'q':
          this.itemsTabset.tabs[1].active = true;
          break;
        case 'p':
          this.activateLeftMenuTabset('relatedSubjectsTab');
          break;
        case 'u':
          this.activateLeftMenuTabset('volumesTab');
          break;
        case 'g':
          if (!this.toggleMenu) {
            if (this.isGridViewEnable) {
              this.girdViewThumBImages();
            } else if (!this.isGridViewEnable) {
              this.listViewThumBImages();
            }
          } else if (this.toggleMenu) {
            this.toggleMenu = false;
            if (this.isGridViewEnable) {
              this.girdViewThumBImages();
            } else if (!this.isGridViewEnable) {
              this.listViewThumBImages();
            }
          }
          break;
        case 'o':
          if (this.hoverFloatingMenu && !this.showFloatingMenu) {
            this.printImageMenu();
          } else if (!this.hoverFloatingMenu && !this.showFloatingMenu) {
            this.showFloatingMenu = true;
            this.printImageMenu();
          } else if (!this.hoverFloatingMenu && this.showFloatingMenu) {
            this.showFloatingMenu = false;
          }
          break;
        case '.>':
          const increasedBrightness = 10;
          if (this.brightnessValue === 0) {
            this.brightnessValue = increasedBrightness;
          } else {
            this.brightnessValue = this.brightnessValue + increasedBrightness;
          }
          if (this.brightnessValue <= -255) {
            this.brightnessValue = -255;
          }
          if (this.brightnessValue >= 255) {
            this.brightnessValue = 255;
          }
          this.adjustBrightness(this.brightnessValue);
          break;
        case ',<':
          const contrestValue = 10;
          if (this.brightnessValue > 0) {
            this.brightnessValue = this.brightnessValue - contrestValue;
          } else {
            this.brightnessValue = this.brightnessValue - contrestValue;
          }
          if (this.brightnessValue <= -255) {
            this.brightnessValue = -255;
          }
          if (this.brightnessValue >= 255) {
            this.brightnessValue = 255;
          }
          this.adjustBrightness(this.brightnessValue);
          break;
        case 'ArrowUp':
          this.browserFullScreen('full');
          break;
        case 'Insert':
          this.selectImage();
          break;
        case 'ArrowLeft':
          this.rotateImage(-1);
          break;
        case 'ArrowRight':
          this.rotateImage(1);
      }
    } else if (e.ctrlKey && e.altKey) {
      switch (e.key) {
        case 'l':
          this.listClick(this.documentData);
          break;
        case 'o':
          if (!$('.modal').hasClass('in')) {
            this.citationClick();
          }
          break;
        case 'z':
          if (this.dynamicComponentData) {
            if (this.dynamicComponentData.isDocViewerShow) {
              this.backToSearchResultsPage();
            } else {
              this.backToSearchResults();
            }
          } else {
            this.backToSearchResults();
          }
          break;
        case 'k':
          this.changetoFullText(this.checkModel);
          break;
        case 'p':
          if (!this.fulltextDisable) {
            this.doThisOnSelect(this.checkModel);
          }
          break;
        case 'n':
          this.leftMenuTabset.tabs[1].active = true;
          break;
        case 'q':
          this.itemsTabset.tabs[0].active = true;
          break;
        case 'e':
          this.toggleMenu = true;
          this.isGridViewEnable = true;
          break;
        case 'd':
          if (this.hoverFloatingMenu && !this.showFloatingMenu) {
            this.downloadAsPDF();
          } else if (!this.hoverFloatingMenu && !this.showFloatingMenu) {
            this.showFloatingMenu = true;
            this.downloadAsPDF();
          } else if (!this.hoverFloatingMenu && this.showFloatingMenu) {
            this.showFloatingMenu = false;
          }
          break;
      }
    } else if (e.ctrlKey && e.shiftKey) {
      switch (e.key) {
        case 'y':
          this.socialShareToggleList(e);
          break;
        case '\|':
          for (let i = 0; i < this.leftMenuTabset.tabs.length; i++) {
            const currentTabId = this.leftMenuTabset.tabs[i].id;
            if (currentTabId === 'tocTabs') {
              this.leftMenuTabset.tabs[i].active = true;
            }
          }
          break;
      }
    }
  }
  menuToggle() {
    if (this.toggleMenu) {
      this.toggleMenu = false;
      //  this.isGridViewEnable = false;
    } else if (!this.toggleMenu) {
      this.toggleMenu = true;
      this.isGridViewEnable = true;
      if (document.getElementById('thumb' + this.documentCurrentIndex)) {
        setTimeout(() => {
          const el = document.getElementById('thumb' + this.documentCurrentIndex);
          el.scrollIntoView();
        }, 0);
      }
    }
  }
  disclaimerToggle() {
    this.toggledisclaimerShow = !this.toggledisclaimerShow;
    if (this.toggledisclaimerShow) {
      localStorage.setItem('disclaimerToggle', this.toggledisclaimerShow.toString());
    } else if (!this.toggledisclaimerShow) {
      localStorage.setItem('disclaimerToggle', this.toggledisclaimerShow.toString());
    }
  }
  getHeaderMenuStatus() {
    this.sharedService.dropdownToggleOption({
      tools: false, otherArchives: false, archiveSelected: false, advanceSearch: false, help: false, language: false, shareDocument: false
    });
  }
  callItemsTabScrollToTop() {
    setTimeout(() => {
      const $container = $('#itemsByType');
      const $scrollTo = $('#itemsByType .node-content-wrapper-focused');
      this.callTreeviewScrollToTop($container, $scrollTo);
    }, 100);
  }

  callItemsInOrderTabScrollToTop() {
    setTimeout(() => {
      const $container = $('#itemsInOrder');
      const $scrollTo = $('#itemsInOrder .node-content-wrapper-focused');
      this.callTreeviewScrollToTop($container, $scrollTo);
    }, 100);
  }
  callItemsIllustrationsScrollToTop() {
    setTimeout(() => {
      const $container = $('#illustrationsTabset');
      const $scrollTo = $('#illustrationsTabset .node-content-wrapper-focused');
      this.callTreeviewScrollToTop($container, $scrollTo);
    }, 100);
  }
  callitemsInTocScrollTop() {
    setTimeout(() => {
      const $container = $('#itemsInTOCTab');
      const $scrollTo = $('#itemsInTOCTab .node-content-wrapper-focused');
      this.callTreeviewScrollToTop($container, $scrollTo);
    }, 100);
  }
  callTreeviewScrollToTop(parent, child) {
    if (child[0]) {
      parent.animate({ scrollTop: 0 }, 50);
      setTimeout(() => {
        parent.animate({
          scrollTop: child.offset().top - parent.offset().top - 10
        }, 'slow');
      }, 200);
    }
  }
  closelanguagePageTemplate() {
    this.languagemodalRef.hide();
    // this.getHeaderMenuStatus();
  }
  exportRIS(type) {
    this.documentViewerService.exportCitation(type, this.currentDocumentId);
  }

  copyCitation(element, citation?) {
    const copiedToclipBoard = this.documentViewerService.copyCitationText(element, citation);
    if (copiedToclipBoard) {
      this.modalService.showNotification('SUCCESS', this.copytoClipboard, this.citationCopiedtoClipboard);
    }
  }

  downloadCitation(citation) {
    this.documentViewerService.downloadCitation(citation, this.currentDocumentId);
    this.googleAnalyticsService.eventEmitter('Download', 'Click', 'Citation', this.currentDocumentId.toString());
  }

  /**
 * @description :Register in document history
 * @param docId : Document id
 * @param title : Document Title
 */
  registerDetailsHistory(docId, title) {
    const detailsHistory: any = localStorage.getItem('detailsHistory');
    let detailsHistoryArray = [];
    const dateViewed = Date.now();
    if (detailsHistory) {
      if (detailsHistory.indexOf(docId) <= -1) {
        detailsHistoryArray = JSON.parse(detailsHistory);
        detailsHistoryArray.push({ 'id': docId, 'date': dateViewed, 'title': title, 'path': window.location.pathname });
        localStorage.setItem('detailsHistory', JSON.stringify(detailsHistoryArray));
      }
    } else {
      detailsHistoryArray.push({ 'id': docId, 'date': dateViewed, 'title': title, 'path': window.location.pathname });
      localStorage.setItem('detailsHistory', JSON.stringify(detailsHistoryArray));
    }
  }
  // backToFrequencyDistribution() {
  //   const url = sessionStorage.getItem('fDPageURL');
  //   this.router.navigateByUrl(url);
  // }
  backToConcordance() {
    const url = sessionStorage.getItem('concordancePageURL');
    this.router.navigateByUrl(url);
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (!this.isFromMapExplorerPage && !this.isFromPhotoExplorerPage) {
      this.backToSearchResultsPage(true);
    }
  }
  processGeoCoordinates(coordinatesArray, checkTabName) {
    const tempCoordinatesArray = [];
    if (coordinatesArray) {
      coordinatesArray.forEach(polygonCoOrdinates => {
        const splitPolygonCoOrdinates = polygonCoOrdinates.split('|');
        let geoBoundary = splitPolygonCoOrdinates[2].replace('POLYGON((', '').replace('))', '');
        geoBoundary = geoBoundary.split(',');
        const polygonBoundaries: Array<any> = [];
        geoBoundary.forEach(element => {
          const splittedCoOrdinates = element.trim().split(' ');
          polygonBoundaries.push([splittedCoOrdinates[1], splittedCoOrdinates[0]]);
        });
        tempCoordinatesArray.push({
          'title': splitPolygonCoOrdinates[0],
          'docId': splitPolygonCoOrdinates[1],
          'coOrdinates': polygonBoundaries
        });
      });
      if (checkTabName === 'Notes') {
        this.geoBoundaries = tempCoordinatesArray;
      } else {
        this.notesGeoBoundaries = tempCoordinatesArray;
      }
    }
  }

  gotoDocumentById(pageId) {
    const data = {};
    this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, (param) => {
      this.searchService.buildURL(param, (url) => {
        if (this.searchTerm) {
          data['searchTerm'] = this.searchTerm ? this.searchTerm : '';
        }
        data['isFromResultsPage'] = true;
        data['filterParam'] = url;
        data['start'] = 0;
        if (pageId) {
          data['docID'] = pageId;
        }
        if (this.searchTerm) {
          data['searchTerm'] = this.searchTerm ? this.searchTerm.toString() : '';
        }
        // this.router.navigate([this.homePageUrl + '/detail/' + pageId, data], { relativeTo: this.activatedRoute });
        this.router.navigate([this.homePageUrl +'/detail/' + pageId, data],{ relativeTo: this.activatedRoute });
        setTimeout(() => {
          window.location.reload();
        }, 10);
      });
    });
  }
  getDocumentShareShortUrl() {
    const currentHost = this.urlUtilityService.getCurrentUrl();
    if (this.documentData) {
      if (this.currentDocumentId) {
        // this.httpService.get(environment.APIUrl + APIURLS.getGuestToken + this.currentDocumentId, (response) => {
        this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
          const currentParams = JSON.parse(JSON.stringify(params));
          // currentParams['guestAuthToken'] = response.authtoken.access_token;
          currentParams['searchTerm'] = '';
          delete currentParams['isFromResultsPage'];
          currentParams['location_id'] = localStorage.getItem('currentLocation') || environment.defaultLocation;
          const currentArchive = this.pageSettingService.getPageSettings().currentArchive.toLowerCase();
          const urlToShort = currentHost + this.router.createUrlTree([`/${currentArchive}/detail/${this.currentDocumentId}`]).toString();
          this.urlUtilityService.getShortUrl(urlToShort, (shortUrlResponse) => {
            this.shareUrl = shortUrlResponse.url;
            //  addthis.layers.refresh();
            // this.modalService.hideLoader();
          }, () => {
            // this.modalService.hideLoader();
          });
        });
        // }, () => {
        //   this.modalService.hideLoader();
        // }, 'json', true);
      }
      // this.modalService.hideLoader();
    }
  }
  // bookmarkToBrowserHistory(shareUrl) {
    // if (window.sidebar) { // Mozilla Firefox Bookmark
    //   window.sidebar.addPanel(location.href,document.title,"");
    // } else if(window.external) { // IE Favorite
    //   window.external.AddFavorite(location.href,document.title); }
    // else if(window.opera && window.print) { // Opera Hotlist
    //   this.title=document.title;
    //   return true;
    // }
  // }

  showWarning(archive) {
    if (window.location.pathname.includes('guestAuthToken=')) {
      const sessionWarning = sessionStorage.getItem('warning-shared');
      sessionStorage.getItem('sharedURL');
      let archives;
      if (sessionWarning) {
        archives = sessionWarning.split(',');
      } else { archives = []; }

      this.warningObj = [];
      this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        /*
        * 1.get data from location config json, if the flag show is true then show the dialog
        * 2.display dialog only once per session
        * 3.if mutiple arhives has show flag as true then display all messages in same dialog
        * user gets the dialog archive 1 for first time then the settings were changed next time
        * it must show the last update archive warning with step1,2 and 3
        */
        if (res.warnings.archives[archive]) {
          if (res.warnings.archives[archive].showShared) {
            if (sessionWarning) {
              if (sessionWarning.indexOf(archive) > -1) {
                if (!(archives.indexOf(archive) > -1)) {
                  archives.push(archive);
                } else {
                  this.warningObj.push({
                    title: res.warnings.archives[archive].titleName,
                    message: res.warnings.archives[archive].message
                  });
                  archives.push(archive);
                }
              } else {
                /*
                * If the session warning object available and not exist in session warning array,
                * push data in warningObj and keep copy of the object in archives array
                */
                this.warningObj.push({
                  title: res.warnings.archives[archive].titleName,
                  message: res.warnings.archives[archive].message
                });
                archives.push(archive);
              }
            } else {
              /*
              * If warning not available in session, push data in warningObj and keep copy of the object in archives array
              */
              this.warningObj.push({
                title: res.warnings.archives[archive].titleName,
                message: res.warnings.archives[archive].message
              });
              archives.push(archive);
            }
          }
        }
        /*
        * Finalize the display data and the warning object has more then one object then show the Dialog
        * And set those archives in session as well as.
        */
        if (this.warningObj.length > 0 ) {
          setTimeout(() => {
            this.warningssmodalRef = this.bsModalService.show(
              this.warningsTemplate, Object.assign({}, { backdrop: true, ignoreBackdropClick: true }, { class: 'gray modal-lg' }));
          }, 500);
        }
        sessionStorage.setItem('warning-shared', archives.toString());
        sessionStorage.setItem('sharedURL', archive.toString());
      }, err => { });
    }
  }
  closeWarningPopup() {
    if (this.warningssmodalRef) {
      this.warningssmodalRef.hide();
    }
  }
  gotoWileyHelpPage() {
    window.open(APIURLS.learnMoreLink, '_blank');
  }
  learnMoreMouseEnter() {
    this.showLearnMoreContent = true;
  }
  learnMoreMouseLeave() {
    this.showLearnMoreContent = false;
  }
  showDropdown(event) {
    this.burgerMenu = !this.burgerMenu;
  }
  atrMouseEnter() {
    this.showATRContent = true;
  }
  atrMouseLeave() {
    this.showATRContent = false;
  }
  // onHeadWordsOverlayClicked(headWord) {
  //   const data = {};
  //     if (headWord) {
  //       data['searchTerm'] = headWord ? headWord.toString() : '';
  //       this.router.navigate([this.homePageUrl + '/explorer/map', data]);
  //     }
  // }

  goDownloadeContent() {
    this.router.navigate(['wiley/downloadcontent']);
  }
  onDrag(event: MouseEvent, pdfViewer: any) {
    if (this.isDragging) {
      const x = pdfViewer.element.nativeElement.children[0].scrollLeft - event.movementX;
      const y = pdfViewer.element.nativeElement.children[0].scrollTop - event.movementY;
      pdfViewer.element.nativeElement.children[0].scrollTo(x, y);
    }
  }
  onDragStarted(event: MouseEvent) {
    this.isDragging = true;
  }
  onDragEnded(event: MouseEvent, note: 'up' | 'leave') {
    this.isDragging = false;
  }
}

interface ShareMail {
  topic?: String;
  message?: String;
  to?: String;
  from?: String;
}
