import { Injectable } from '@angular/core';
import { SearchService } from '../../common/services/search.service';
import { CommonService } from '../services/common.service';
declare let $: any;
declare let html2canvas: any;
@Injectable()
export class CollocatesService {

  collocatesCache$: any = {};
  constructor(
    private searchService: SearchService,
    private commonService: CommonService
  ) { }

  /**
   * @description get data from service
   * @param advancedFilters
   * @param searchTerm
   * @param successCallBack
   * @param errorCallback
   */

  /**
   * @description navigate to collocates page
   * @param searchTerm
   * @param collocate
   */
  gotoDocumentDetailsPage(searchTerm, collocate, advancedFilterParam) {
    const collocateTerm = `"${searchTerm} ${collocate}"`;
    this.searchService.backToSearchResult(collocateTerm, advancedFilterParam, true);
  }
  printCollocation(collcationId, term, advancedFilterParam) {
    this.commonService.processLimiterForPrint(advancedFilterParam, (limiterHtml) => {
    const printContent = document.getElementById(term);
    const termsList = document.querySelector('.addmoreterms-list');
    const newWin = window.frames['printf'];
    // tslint:disable-next-line:max-line-length
    newWin.document.write(`<html><head><style>@media print {body {margin: 0;}}.limiter-badge{background: #dadada;border-radius: 5px;color: #000;padding: 4px 7px 4px 15px}.limit-content{margin:5px}.limit-data{font-size: 13px;}.limit-title{font-size: 12px;font-weight: 500;font-family: 'Open Sans', sans-serif !important}@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i');@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700');table{width:100%;border:1px solid gray}table thead tr{text-align:left}th{padding:5px;color:#fff;background:#61165e}td{padding:5px;border-top:1px solid #e0e0e0;border-right:1px solid #e0e0e0}body{font-family:'Open Sans',sans-serif !important;-webkit-print-color-adjust:exact !important}.term-frequency{display:block;margin:20px}.badge{margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000}.badge{padding:1px 7px;margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000;cursor:pointer;min-width:120px;display:inline-block;text-align:left !important}ul{padding-left:0}.searchQry{margin-top:4px;font-size:14px;padding:5px}.display-badge{display:inline-block;overflow:hidden;min-height:20px;padding-top:0px}.searchList>li>span.badge{padding:6px 11px;line-height:1.3}.print-hide,.no-print,canvas{display:none}.collcationTable{margin-top:10px}.text-capitalize {text-transform: capitalize;}</style></head><body onload="setTimeout(function () { window.print(); }, 1000)"><h2 style="font-family: 'Source Serif Pro', serif !important;text-transform: capitalize;">Collocations</h2><p style="font-weight:600;margin:0;font-family: 'Source Serif Pro', serif !important;">Limiters</p>${limiterHtml}<div style="margin-top:20px"> ${printContent.innerHTML}</div></body></html>`);
    newWin.document.close();
  });
  }
  downloadCollocation(collocationId, term, advancedFilterParam) {
    this.commonService.processLimiterForPrint(advancedFilterParam, (limiterHtml) => {
    const printContent = document.getElementById(term);
    // const termsList = document.querySelector('.addmoreterms-list');
    // tslint:disable-next-line: max-line-length
    const htmlData = `<html><head><style>body{background-color:white  !important;}@media print {body {margin: 0;}}.limiter-badge{background: #dadada;border-radius: 5px;color: #000;padding: 4px 7px 4px 15px}.limit-content{margin:5px}.limit-data{font-size: 13px;}.limit-title{font-size: 12px;font-weight: 500;font-family: 'Open Sans', sans-serif !important}@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i');@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700');body{font-family:'Open Sans',sans-serif!important;-webkit-print-color-adjust:exact!important}table{width:100%;border:1px solid gray}table thead tr{text-align:left}th{padding:5px;color:#fff;background:#61165e}td{padding:5px;border-top:1px solid #e0e0e0;border-right:1px solid #e0e0e0}body{font-family:'Open Sans',sans-serif !important;-webkit-print-color-adjust:exact !important}.term-frequency{display:block;margin:20px}.badge{margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000}.badge{padding:1px 7px;margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000;cursor:pointer;min-width:120px;display:inline-block;text-align:left !important}ul{padding-left:0}.searchQry{margin-top:4px;font-size:14px;padding:5px}.display-badge{display:inline-block;overflow:hidden;min-height:20px;padding-top:0px}.searchList>li>span.badge{padding:6px 11px;line-height:1.3}.print-hide,.no-print,canvas{display:none}.collcationTable{margin-top:10px}.text-capitalize {text-transform: capitalize;}</style></head><body><h2 style="font-family: 'Source Serif Pro', serif !important;text-transform: capitalize;">Collocations</h2><p style="font-weight:600;margin:0;font-family: 'Source Serif Pro', serif !important;">Limiters</p>${limiterHtml}<div style="margin-top:20px"> ${printContent.innerHTML}</div></body></html>`;
    const that = this;
    const iframe = document.createElement('iframe');
    iframe.id = 'downloadCollocates';
    iframe.width = '35%';
    // iframe.height = '0px';
    iframe.style.visibility = 'hidden';
    $('body').append($(iframe));
    setTimeout(function () {
      const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
      $('body', $(iframedoc)).html(htmlData);
      html2canvas(iframedoc.body, {
        onrendered: function (canvas) {
          const chartURL = canvas.toDataURL();
          that.commonService.downloadImage(chartURL, term + '-collocates.png');
          setTimeout(() => {
            // document.getElementById('downloadCollocates').remove();
            const element = document.getElementById('downloadCollocates');
            element.parentNode.removeChild(element);
          }, 10);
        }
      });
    });
  });
  }
}
