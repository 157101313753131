
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
// import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ModalService } from '../common/services/modal.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { UtilityService } from '../common/services/utility.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

declare let $: any;
// declare let addthis: any;

@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule, ReactiveFormsModule, FormsModule],
  selector: 'app-share-document',
  templateUrl: './share-document.component.html',
  styleUrls: ['./share-document.component.css'],
})
export class ShareDocumentComponent implements OnInit {
  isArabicLanguage: Boolean = false;
  loadingPopup: any;
  isGuestUser: any;
  emailNotDelivered: any;
  mailSent: any;
  private sharemodalRef: BsModalRef;
  // @ViewChild('shareHtmlPageTemplate') private shareHtmlPageTemplate: TemplateRef<any>;
  @ViewChild('shareHtmlPageTemplate', { static: false }) private shareHtmlPageTemplate: TemplateRef<any>;

  shareMailLoader: Boolean = false;
  shareMailData: ShareMail = {
    'topic': '',
    'message': '',
    'to': '',
    'from': ''
  };
  toggleShareList: Boolean = false;
  bookmarkShortURL: any;
  translatedValues: any;
  shareUrl: any;
  currentSharedDocument: any = '';
  @Input() internalShareOnly: boolean;
  /*bookMarkShare varialble refers the Internal share link of enitre document in page (qb link) */
  @Input('bookMarkShare')
  set bookMarkShare(value) {
    if (value) {
      this.toggleShareList = value;
      // addthis.layers.refresh();
    }
  }

  /*bookmarkDocumentURL varialble refers the Internal share link of each/specific document in page (qb link) */
  @Input('bookmarkDocumentURL')
  set bookmarkDocumentURL(value) {
    if (value) {
      this.bookmarkShortURL = value;
    }
  }

  /*currentDocument variable refers the specfic document from search result page here we used the docuemnt title in the addthis*/
  @Input('currentDocument')
  set currentDocument(value) {
    if (value) {
      this.currentSharedDocument = value;
    }
  }

  /*bookMarkExternalShareUrl varialble refers the External share link of each/specific document in page (qs link) */
  @Input('bookMarkExternalShareUrl')
  set bookMarkExternalShareUrl(value) {
   // this.modalService.showLoader();
    if (value) {
      this.shareUrl = value;     
       // tslint:disable-next-line: max-line-length
      //  addthis.update(this.translatedValues.share || 'share', this.translatedValues.url || 'url', this.shareUrl);
      //  // tslint:disable-next-line: max-line-length
      //  addthis.update(this.translatedValues.share || 'share', this.translatedValues.title || 'title', this.currentSharedDocument.title + this.translatedValues.shareContentThroughLinkDot || '. Please use the following link to access content from the Wiley Digital Archives:');
      //  // tslint:disable-next-line: max-line-length
      //  addthis.update(this.translatedValues.share || 'share', this.translatedValues.description || 'description', this.translatedValues.useFollowingLinktoAccessContent || 'Please use the following link to access content from the Wiley Digital Archives:');
     //  this.modalService.hideLoader();
      }
  }
  stop$: Subject<any> = new Subject();
  useFollowingLinktoAccessContent: String = '';
  constructor(
    private utilityService: UtilityService,
    @Inject(DOCUMENT) private dom: Document,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private translate: TranslateService) {
      this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
        this.translatedValues = res;
        this.useFollowingLinktoAccessContent = res['useFollowingLinktoAccessContent'];
        this.mailSent = res['mailSent'];
        this.emailNotDelivered = res['emailNotDelivered'];
        this.loadingPopup = res['loading'];
      });
     }

  ngOnInit() {
    // addthis.layers.refresh();
  }

  shareMailClick(shareUrl) {
    if (!$('.modal').hasClass('in') && !this.shareMailLoader) {
      this.shareMailLoader = true;
      // this.modalService.showLoader();
      this.shareMailData = {
        'topic': this.currentSharedDocument ? this.currentSharedDocument.title : '',
        'message': '',
        'to': ''
      };
        this.shareMailData.message = this.useFollowingLinktoAccessContent + ' \n' + shareUrl;
        if (!$('.modal').hasClass('in')) {
          this.sharemodalRef = this.bsModalService.show(this.shareHtmlPageTemplate, { backdrop: true, ignoreBackdropClick: true });
          this.shareMailLoader = false;
        }
      this.shareMailLoader = false;
      // this.modalService.hideLoader();
    }
  }
  shareEmail() {
    this.utilityService.sendToMailClient(this.shareMailData.topic,
      this.shareMailData.message, this.shareMailData.to, this.shareMailData.from).then(res => {
        this.modalService.showNotification('SUCCESS', this.mailSent || 'Mail Sent', '');
        this.closeShareModal();
      }, err => {
        this.modalService.showNotification('WARNING', this.emailNotDelivered || 'Email has not been Delivered', '');
        this.closeShareModal();
      });
  }
  closeShareModal() {
    this.sharemodalRef.hide();
    this.shareMailLoader = false;
  }

  copyShareUrl(bookmarkURL) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      if (bookmarkURL) {
        // $(bookmarkURL).addClass('welcome');
        const citationEle = bookmarkURL;
        const textToBeCopied = (citationEle).replace(/<(?:.|\n)*?>/gm, '');
        const $input = $('#bookmarkURLonTemplate');
        $input.val();
        const el = $input.get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        // el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;

        const successful = document.execCommand('copy');
        $input.blur();
        const msg = successful ? 'successful ' : 'un-successful ';
      }
    } else {
      const textToBeCopied = (bookmarkURL).replace(/<(?:.|\n)*?>/gm, '');
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
       this.modalService.showNotification('SUCCESS', this.translatedValues.copytoClipboard , 'TEXT copied to clipboard');
     }
    }

}

interface ShareMail {
  topic?: String;
  message?: String;
  to?: String;
  from?: String;
}
