
import {finalize, takeUntil} from 'rxjs/operators';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs/Rx';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from './common/services/modal.service';
import { environment, APIURLS } from '../environments/environment';
import { HttpService } from './common/services/http.service';
import { CommonService } from './common/services/common.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor, OnDestroy {
    currentArchive: any;
    currentLocation: any;
    translatedValues: any;
    popupTranslatedValues: any;
    // haveAuth: any = 'false';
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private modalService: ModalService,
        private translate: TranslateService,
        private httpService: HttpService,
        private commonService: CommonService,
    ) {
        if (window.location.pathname.includes('downloadcontent')) {
            localStorage.setItem('initialPathName', window.location.pathname)
        } else {
            localStorage.removeItem('initialPathName');
        }
        this.currentLocation = localStorage.getItem('çurrentLocation') || environment.defaultLocation;
        this.currentArchive = (localStorage.getItem('currentArchive') || environment.defaultArchive);
        setTimeout(() => {
            if (window.location.pathname.includes('saml')) {
                // tslint:disable-next-line:max-line-length
                const redirectURI = 'https://qaapi.wileydigitalarchives.com/auth/saml/login/?idp=https://shib.wileydigitalarchives.com/idp/shibboleth';
                window.open(redirectURI, '_self');
            }
            this.translate.get('popUps').subscribe(res => {
                this.popupTranslatedValues = res;
            }, err => { });
            this.translate.get('Common.Popups').subscribe((res: any) => {
                this.translatedValues = res;
            });
        }, 1000);
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.modalService.addRequest();
        if((req.url.includes('illustrationsbypage') || req.url.includes('translatetext')) || (req.url.includes('relevantcontents')) ||
          (req.url.includes(APIURLS.bandWidthCheckImgUrl) || req.url.includes(environment.versionCheckURL))) {
        } else {
            this.modalService.showLoader();
        }
        const accessType = localStorage.getItem('accessType');
        let token: any;
        let newHeaders: any = req.headers;
        if (accessType === 'guest') {
            token = JSON.parse(sessionStorage.getItem('auth_token'));
        } else {
            token = JSON.parse(localStorage.getItem('auth_token'));
        }
        if (req.url.includes(APIURLS.authUrl2) || req.url.includes(APIURLS.detectLocation)
            || req.url.includes(APIURLS.getIp)) {
            return next.handle(req.clone({ headers: newHeaders })).pipe(
                finalize(() => {
                    this.finalizeCount();
                })
                );
        } else if (!token) {
            return next.handle(req.clone({ headers: newHeaders })).pipe(
                finalize(() => {
                    this.finalizeCount();
                })
            );
        } else {
            let contentType: any;
            let responseType1: any = 'json';
            if (req.url.includes('pagemapper/print/')) {
                contentType = 'application/octet-stream';
                responseType1 = 'blob';
            }
            if (req.url.includes(APIURLS.bandWidthCheckImgUrl)) {
                contentType = 'arraybuffer';
                responseType1 = 'arraybuffer';
            }

            if (req.url.includes('texturl/') || req.url.includes(APIURLS.downloadTableUrlV2)
                || req.url.includes(APIURLS.downloadTableCSV) || req.url.includes(APIURLS.downloadCollection) ||
                (req.headers.get('Content-Type') === 'text/plain' || req.url.includes(APIURLS.logout))) {
                responseType1 = 'text';
                contentType = 'text/plain; charset=utf-8';
            }
            if (req.url.includes(APIURLS.getCitationRISFile)) {
                responseType1 = 'text' as 'blob';
                contentType = 'application/json';
            }

            if (req.url.includes(APIURLS.sendToMailClient)) {
                token = token ? token : environment.tmpAccessToken;
                responseType1 = 'text';
            }
            if ((req.headers.get('Content-Type') === 'text/plain') || req.url.includes(APIURLS.sendToMailClient)) {
            } else {
                newHeaders = req.headers.set('Accept', contentType ? 'application/pdf' : 'application/json');
            }
            let loc = localStorage.getItem('currentLocation');
            if (loc) {
                loc = loc.trim();
            }
            if (!req.url.includes('iiif.wileydigitalarchives.com/')) {
                newHeaders = newHeaders.set('WDALoc', loc || environment.defaultLocation);
                // newHeaders = newHeaders.set('Application', 'WDA');
                newHeaders = newHeaders.set('withCredentials', 'true');
                newHeaders = newHeaders.set('Authorization', 'Bearer ' + token);
                newHeaders = newHeaders.set('Content-Type', contentType || 'application/json');
            }
            return next.handle(req.clone({ headers: newHeaders, responseType: responseType1 })).pipe(
                takeUntil(this.ngUnsubscribe)).retry(2)
                .do((event: HttpEvent<any>) => {
                 },(err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.ngUnsubscribe.next();
                            this.ngUnsubscribe.complete();
                            sessionStorage.setItem('lastSessionPath', window.location.pathname + window.location.search);
                            if (localStorage.getItem('accessType') === 'IP') {
                                // tslint:disable-next-line: max-line-length
                                this.modalService.showNotification('WARNING', this.translatedValues.sessionExpired || 'Session expired', this.translatedValues.authenticatingAgain || 'Authenticating again …');
                                this.httpService.validateToken(err);
                            } else {
                                this.httpService.validateToken(err);
                            }
                        } else if (err.status === 403) {
                            this.ngUnsubscribe.next();
                            this.ngUnsubscribe.complete();
                            sessionStorage.setItem('lastSessionPath', window.location.pathname + window.location.search);
                            if (localStorage.getItem('accessType') === 'IP') {
                                // tslint:disable-next-line: max-line-length
                                this.modalService.showNotification('WARNING', this.translatedValues.sessionExpired || 'Session expired', this.translatedValues.authenticatingAgain || 'Authenticating again …');
                                this.httpService.validateToken(err);
                            } else {
                                this.httpService.validateToken(err);
                            }
                        } else if (err.status === 500) {
                            // tslint:disable-next-line: max-line-length
                            this.modalService.showNotification('ERROR', this.translatedValues.somethingWrong || 'Something went wrong', this.translatedValues.contactSupport || 'Please contact Support.');
                            this.httpService.validateToken(err);
                        }
                        // _throw(err);
                    }
                }).finally(() => {
                    this.finalizeCount();
                })
        }
    }
    getLimiters(archives) {
        this.commonService.getPagesettingsDropdownLimiters(archives, res => { });
    }
    finalizeCount() {
        this.modalService.removeRequest();
        const loaderCount = this.modalService.getRequestCount();
        // console.log(loaderCount)
        if (loaderCount == 0){
          this.modalService.hideLoader();
        }
    }
    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
        if (this.ngUnsubscribe) {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
        }
    }
}
