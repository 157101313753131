/* Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageSettingsService } from '../../common/services/page-settings.service';
import { CommonService } from '../services/common.service';
declare let $: any;
declare let html2canvas: any;
@Injectable()
export class ConcordanceService {
  newFilterParams: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) {}

  /**
   * @description :get concordance date from service
   * @param advancedFilters :advanced filter params
   * @param term : term
   * @param limit : limit per request
   * @param concordancePreSize : concordance before word
   * @param concordancePostSize : concordance after word
   * @param archive : single archive
   */

  getConcordanceData(
    advancedFilters: any,
    term: String,
    limit: Number = 20,
    concordancePreSize: Number = 5,
    concordancePostSize: Number = 5,
    archive?: String
  ) {
    const advParam = JSON.parse(JSON.stringify(advancedFilters));
    if (term.includes('"')) {
      term = term.replace(/"/g, '');
    } else {
      term = term;
    }
    advParam.filterParams.forEach(obj => {
      if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
        obj.condition = 'All';
        obj.fieldValue = '';
      }
    });
    advParam['rows'] = limit;
    advParam['concordancePreSize'] = concordancePreSize;
    advParam['concordancePostSize'] = concordancePostSize;
    advParam.terms = [term];
    delete advParam['groupLimit'];
    delete advParam['searchParams'];
    delete advParam['searchTerm'];
    delete advParam['groupOffset'];
    this.newFilterParams = advParam.filterParams;
    if (this.newFilterParams.length > 0) {
      for(let k =0; k < this.newFilterParams.length; k++) {
        if(this.newFilterParams[k].fieldName === "archive") {
         const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
         const haveRGS = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RGS'); 
         if (haveRGS) {
          const haveRGS1 = getSelectedArchives.includes('RGS1');
          if(haveRGS1) {
            this.newFilterParams[k].fieldValues.push('RGS1');
          }
          const haveRGS2 = getSelectedArchives.includes('RGS2');
          if(haveRGS2) {
            this.newFilterParams[k].fieldValues.push('RGS2');
          }
          this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RGS',this.newFilterParams[k].fieldValues);
         }
         const haveRCP = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RCP'); 
         if (haveRCP) {
          const haveRCP1 = getSelectedArchives.includes('RCP1');
          if(haveRCP1) {
            this.newFilterParams[k].fieldValues.push('RCP1');
          }
          const haveRCP2 = getSelectedArchives.includes('RCP2');
          if(haveRCP2) {
            this.newFilterParams[k].fieldValues.push('RCP2');
          }
          this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RCP',this.newFilterParams[k].fieldValues);
         }
        }
      }
      if(this.newFilterParams) {
        advParam.filterParams = this.newFilterParams;
      }
    }
    return this.commonService.getConcordanceResult(advParam, term);
  }
  removeSelectedValFromArray(val, arr) {
    let value = val;
    arr = arr.filter(item => item !== value)
    return arr;
  }


  /**
   * @description :navigate to document details page based on @param: documentId
   * @param term : clicked term as simple search term
   * @param advancedFilterParam : advanced filter param and search optimizer
   * @param documentId :document id
   */
  gotoDocumentPage(term, documentId) {
    let homePageUrl = '/';
    homePageUrl += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
    const data = {};
    data['searchTerm'] = term;
    data['isFromConcordance'] = true;
    const CurrentURL = window.location.href.split(window.location.host);
    sessionStorage.setItem('concordancePageURL', CurrentURL[1]);
    this.router.navigate([homePageUrl + '/detail/' + documentId, data], { relativeTo: this.activatedRoute });
  }
  printConcordance(concordanceId, term, advancedFilterParam) {
    this.commonService.processLimiterForPrint(advancedFilterParam, (limiterHtml) => {
    const printContent = document.getElementById(term);
    const termsList = document.querySelector('.addmoreterms-list');
    const newWin = window.frames['printf'];
    // tslint:disable-next-line:max-line-length
    newWin.document.write(`<html><head><style>@media print {body {margin: 0;}}.limiter-badge{background: #dadada;border-radius: 5px;color: #000;padding: 4px 7px 4px 15px}.limit-content{margin:5px}.limit-data{font-size: 13px;}.limit-title{font-size: 12px;font-weight: 500;font-family: 'Open Sans', sans-serif !important}@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i');@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700');table{width:100%;border:1px solid gray}table thead tr{text-align:left;color:#fff;background:#61165e}th{padding:5px;color:#fff;background:#61165e}td{padding:5px;border-top:1px solid #e0e0e0;border-right:1px solid #e0e0e0}body{font-family:'Open Sans',sans-serif !important;-webkit-print-color-adjust:exact !important}.term-frequency{display:block;margin:20px}.badge{margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000}.badge{padding:1px 7px;margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000;cursor:pointer;min-width:120px;display:inline-block;text-align:left !important}ul{padding-left:0}.searchQry{margin-top:4px;font-size:14px;padding:5px}.display-badge{display:inline-block;overflow:hidden;min-height:20px;padding-top:0px}.searchList>li>span.badge{padding:6px 11px;line-height:1.3}.print-hide,.no-print,canvas{display:none}.concordanceTable{margin-top:10px}</style></head><body onload="setTimeout(function () { window.print(); }, 1000)"><h2 style="font-family: 'Source Serif Pro', serif !important;text-transform: capitalize;">Concordance</h2><p style="font-weight:600;margin:0;font-family: 'Source Serif Pro', serif !important;">Limiters</p>${limiterHtml}<div style="margin-top:20px"> ${printContent.innerHTML}</div></body></html>`);
    newWin.document.close();
    });
  }
  downloadConcordanceData1(term) {
    // $('#dummyContent').append($('#' + term + ' table'));
    $('#dummyDownloadContent').empty();
    const newHTMLData = $('#dummyDownloadContent').append($('#' + term).html());
    const that = this;
    const htmlData = $('#' + term);
    newHTMLData.find('#download').css('display', 'none');
    newHTMLData.find('#print').css('display', 'none');
    newHTMLData.find('table').addClass('collcates-table-hide');
    $('#img-out canvas').remove();
    html2canvas(newHTMLData, {
      onrendered: function (canvas) {
        document.body.appendChild(canvas);
        $('#img-out').append(canvas);
        setTimeout(() => {
          if (document.getElementsByTagName('canvas')[0]) {
            const chartURL = document.getElementsByTagName('canvas')[0].toDataURL();
            that.commonService.downloadImage(chartURL, term + '-concordance.png');
          }
          newHTMLData.find('#download').css('display', 'block');
          newHTMLData.find('#print').css('display', 'block');
          newHTMLData.find('table').removeClass('collcates-table-hide');
        }, 10);
      }
    });
  }
  downloadConcordanceData(term, advancedFilterParam) {
    this.commonService.processLimiterForPrint(advancedFilterParam, (limiterHtml) => {
    const printContent = document.getElementById(term);
    const termsList = document.querySelector('.addmoreterms-list');
    // tslint:disable-next-line: max-line-length
    const htmlData = `<html><head><style>body{ background-color:white  !important; padding: 20px;}.goto-doc a{width: auto;white-space: normal!important;word-wrap: break-word;-ms-word-wrap: break-word;;overflow: hidden;text-overflow: ellipsis;float: left !important;cursor: pointer;}.r-padding {padding-right: 10px !important;}.limiter-badge{background: #dadada;border-radius: 5px;color: #000;padding: 4px 7px 4px 15px}.limit-content{margin:5px}.limit-data{font-size: 13px;}.limit-title{font-size: 12px;font-weight: 500;font-family: 'Open Sans', sans-serif !important}@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i');@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700');table{width:100%;border:1px solid gray}table thead tr{text-align:left;color:#fff;background:#61165e}th{padding:5px;color:#fff;background:#61165e}td{padding:5px;border-top:1px solid #e0e0e0;border-right:1px solid #e0e0e0}body{font-family:'Open Sans',sans-serif!important;-webkit-print-color-adjust:exact!important}.term-frequency{display:block;margin:20px}.badge{margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000}.badge{padding:1px 7px;margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000;cursor:pointer;min-width:120px;display:inline-block;text-align:left!important}ul{padding-left:0}.searchQry{margin-top:4px;font-size:14px;padding:5px}.display-badge{display:inline-block;overflow:hidden;min-height:20px;padding-top:0}.searchList>li>span.badge{padding:6px 11px;line-height:1.3}.no-print,.print-hide,canvas{display:none} .concordanceTable{margin-top:10px}</style></head><body><h2 style="font-family: 'Source Serif Pro', serif !important;text-transform: capitalize;">Concordance</h2><p style="font-weight:600;margin:0;font-family: 'Source Serif Pro', serif !important;">Limiters</p>${limiterHtml}<div style="margin-top:20px"> ${printContent.innerHTML}</div></body></html>`;
    const that = this;
    const iframe = document.createElement('iframe');
    iframe.id = 'downloadConcordance';
    iframe.width = '100%';
    iframe.height = '0px';
    iframe.style.visibility = 'hidden';
    $('body').append($(iframe));
    setTimeout(function () {
      const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
      $('body', $(iframedoc)).html(htmlData);
      html2canvas(iframedoc.body, {
        onrendered: function (canvas) {
          const chartURL = canvas.toDataURL();
          that.commonService.downloadImage(chartURL, term + '-concordance.png');
          setTimeout(() => {
            // document.getElementById('downloadConcordance').remove();
            const element = document.getElementById('downloadConcordance');
            element.parentNode.removeChild(element);
          }, 10);
        }
      });
    });
  });
  }
}
