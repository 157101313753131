<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->

<div>
<app-map [heatData]="mapData" [valueField]="'count'" (spatialSearchEmits)="onSpatialSearchEmit($event)" [isFromSearchResults]="true"></app-map>
  <tabset #staticTabs id="brosweMapTab">
    <tab [customClass]="'docviewer-tabs'" heading="{{'SearchResult.byContentType' | translate}}">
      <p class="text-center">{{ 'SearchResult.byContentTypeChartTitle' | translate }}</p>
      <app-search-result-by-content-type></app-search-result-by-content-type>
    </tab>
    <tab [customClass]="'docviewer-tabs'" heading="{{'SearchResult.bySubject' | translate}}">
      <div class="col-md-12 contenttype-chart">
        <app-search-result-graph-by-subject></app-search-result-graph-by-subject>
      </div>
    </tab>
  </tabset>
</div>
