<div class="container-fluid" [ngClass]="{ 'hide-docviewer': docViewerMode, 'show-docviewer' : !docViewerMode}">
  <wiley-searchresult-content-component [contentTypeOrder]="contentTypeOrder" [isContentTypePhoto]="isPhotograph"  [isContentTypeMap]="isMap"  (enableScroller)="singleListModeEnabled($event)"></wiley-searchresult-content-component>
    <div class="row">
      <app-wiley-searchresult-leftmenu-component [page-content]="pageSettings" class="col-lg-2 padding-right-5 width-15 borderLeftMenu"
        (mapSelect)="mapRedir($event)" [mapViewToggle]="mapViewToggle" [ngStyle]="{'display': pageSettings.showLeftMenu ? 'block': 'none'}"
        [ngClass]="{'searchPageHeader': toggleHeader, 'nosearchPageHeader': !toggleHeader }"></app-wiley-searchresult-leftmenu-component>
      <wiley-search-result class="col-lg-10" [contentTypeOrderSearchResult]="contentTypeOrder" [isContentTypePhoto]="isPhotograph"  [isContentTypeMap]="isMap"  [enable-single-list]="enableSingleList"
        (mapToggleSelect)="mapToggle($event)" [ngClass]="{ 'showfullwidth-searchpage': !pageSettings.showLeftMenu}"
        [isInMapView]="isInMapView" [isDocViewerClosed]="isDocViewerOpen" [docIdForDocViewer]="docIdForDocViewer"
        (pagechanged)="activePageChanged($event)"></wiley-search-result>
    </div>
</div>
<div [ngStyle]="{'min-height': !docViewerMode ?  '600px !important' : '' }">
  <template #detailsDynamicContainer></template>
</div>
