/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from './notification.model';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  public notifications: NotificationModel[] = [];

  @Input()
  position: string;

  constructor(private notificationService: NotificationService) {

  }

  ngOnInit() {
    this.notifications = this.notificationService.getNotifications();
  }

  close(notification) {
      this.notificationService.remove(notification);
  }
}
