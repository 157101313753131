import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadMoreDirective } from './show-more-directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ReadMoreDirective],
  exports: [ReadMoreDirective]
})
export class ReadMoreModule { }
