<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<div class="adv_search">
  <div class="advSerach_scrollbar">
    <!-- <perfect-scrollbar class="advSerach_scrollbar"> -->
    <div class="row margin-bottom-5">
      @for (filter of listOfFilters; track filter; let i = $index) {
        <div class="col-md-4 col-sm-4 col-xs-6 mgn advance-search-textboxdiv padding-right-7">
          <label [for]="'EnterText' + i" class="sr-only">{{ 'AdvancedSearch.enterText' | translate }}</label>
          <input [id]="'EnterText' + i" class="form-control dropdown-search rightpadding-0 input-texbox text-box filter-control-input"
            aria-label="Enter Text" title="{{ 'AdvancedSearch.enterText' | translate }}" maxlength="100" placeholder="{{ 'AdvancedSearch.enterText' | translate }}"
            type="text" [(ngModel)]="listOfFilters[i].fieldValue" (ngModelChange)="filterChanged(listOfFilters[i].fieldValue, i)"
            autocomplete="off" [ngModelOptions]="{standalone:true}">
          </div>
          <div class="col-md-4 col-sm-4 col-xs-6 mgn no-padding">
            <label [for]="'EntireDocument' + i" class="sr-only">{{ 'AdvancedSearch.entireDocument' | translate }}</label>
            <select title="{{ 'AdvancedSearch.fieldName' | translate }}" tabindex="0" [id]="'EntireDocument' + i" [(ngModel)]="listOfFilters[i].fieldName"
              [ngModelOptions]="{standalone:true}" (ngModelChange)="onFieldChange($event,i)" class="form-select dropdown-search rightpadding-0 text-box">
              @for (option of fieldsDropdownOptions; track option; let i = $index) {
                <option [value]="option.itemValue">{{ 'AdvancedSearch.'+option.itemValue+'.name' | translate }}</option>
              }
            </select>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-6 mgn advance-search-operatordiv right-padding-0 padding-left-7">
            @if (i != listOfFilters?.length - 1) {
              <label [for]="'and' + i" class="sr-only">{{ 'AdvancedSearch.andText' | translate }}</label>
              <select title="{{'AdvancedSearch.operator'|translate}}" tabindex="0" [id]="'and' + i" [(ngModel)]="listOfFilters[i].operator"
                [ngModelOptions]="{standalone:true}" (ngModelChange)="onOperatorChange($event,i)" class="form-select dropdown-search rightpadding-0 text-box">
                @for (option of operatorsDropdownOptions; track option; let i = $index) {
                  <option [value]="option.itemValue">{{ 'AdvancedSearch.operators.'+option.itemName+'.name' | translate }}</option>
                }
              </select>
            }
          </div>
          <div class="col-md-1 col-sm-1 col-xs-6 mgn no-padding icon-left-align">
            <a href="javascript:void(0)" title="{{ 'AdvancedSearch.addFilter' | translate }}">
              @if (i!==7) {
                <i aria-hidden="true" class="fa fa-lg fa-plus-circle add-icon" (click)="addFilter(i);$event.stopPropagation()"></i>
              }
            </a>
            <a href="javascript:void(0)" title="{{ 'AdvancedSearch.removeFilter' | translate }}">
              @if (listOfFilters?.length > 1 && i !== 7) {
                <i aria-hidden="true" class="fa fa-lg fa-minus-circle delete-icon" (click)="removeFilter(i);$event.stopPropagation()"></i>
              }
            </a>
          </div>
        }
      </div>
    <!-- </perfect-scrollbar> -->
  </div>
</div>
