
import {map, takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable, OnDestroy } from '@angular/core';
// import { Response, ResponseContentType } from '@angular/common/http';
import { Subscription ,  Subject } from 'rxjs';
import { ModalService } from './modal.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './sharedService';
import { Router } from '@angular/router';

@Injectable( {
	providedIn: 'root'
})
export class HttpService implements OnDestroy {
	cache = {
		get: {},
		post: {}
	};
	translatedValues: any;
	APIS: any;
	currentLocation: any;
	currentArchive: any;
	private headers: any;
	private navObservable: Subscription;
	protected ngUnsubscribe: Subject<void> = new Subject<void>();
	 isInIpadMode: Boolean = false;
	constructor(
		private http: HttpClient,
		private router: Router,
		private modalService: ModalService,
		private translate: TranslateService,
		private sharedService: SharedService
	) {
		this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Application': 'WDA'});
		// this.headers = new Headers({ 'Content-Type': 'application/json', 'Application': 'WDA', observe: 'response' });
		setTimeout(() => {
			this.translate.get('Common.Popups').subscribe((res: any) => {
				this.translatedValues = res;
			});
		}, 1000);
		if (window.innerWidth <= 1024) {
			this.isInIpadMode = true;
		} else {
			this.isInIpadMode = false;
		}
	}

	post(url, requestParams, successCallback, errorCallback, responseType = 'json', docId?: any) {
		this.setTokenHeader(docId);
		let header: any;
		if (docId) {
			header = new HttpHeaders({ headers: this.headers, 'docId': docId});
		} else {
			header = new HttpHeaders({ headers: this.headers});
		}
		
		return this.http.post(url, requestParams).pipe(
			takeUntil(this.ngUnsubscribe))
			.toPromise()
			.then((response) => {
				this.cache[url + JSON.stringify(requestParams)] = response;
					successCallback(response);
			}).catch((error) => {
				setTimeout(() => {
					// if (this.modalService) {
						// this.modalService.hideLoader();
					// }
				}, 1500);
				this.validateToken(error);
				errorCallback(error);
			});
	}

	get(url, successCallback, errorCallback, responseType = 'JSON', bypassAuth = false, docId?: any,
	bypassAppHeader?: Boolean, additionalHeader?) {
	// get(url, successCallback, errorCallback, responseType = 'json', bypassAuth = false, docId?: any,
	// 		bypassAppHeader?: Boolean, additionalHeader?) {
		let responseContentType;
		if (responseType.toLowerCase() === 'json') {
			responseContentType = 'Json';
		} else if (responseType.toLowerCase() === 'blob') {
			responseContentType = 'Blob';
		} else if (responseType.toLowerCase() === 'text') {
			responseContentType = 'Blob';
		} else if (responseType.toLowerCase() === 'arrayBuffer') {
			responseContentType = 'ArrayBuffer';
		}
		// let header: any;
		// if (!bypassAuth) {
			this.setTokenHeader();
		// 	if (docId) {
		// 		header = new HttpHeaders({ headers: this.headers, responseType: responseContentType, docId: docId});
		// 	} else {
		// 		header = new HttpHeaders({ headers: this.headers, responseType: responseContentType});
		// 	}
			// this.setTokenHeader(docId);
			// if (bypassAppHeader) {
			// 	this.headers.delete('Application');
			// 	this.headers.delete('WDALoc');
			// 	this.headers.delete('wdaloc');
			// }
			// options = new RequestOptions({ headers: this.headers, responseType: responseContentType });
		// } else {
		// 	header = new HttpHeaders({ withCredentials: 'true' });
		// }
		if (additionalHeader) {
			this.headers.append(additionalHeader.key, additionalHeader.value);
		}
		// if (this.cache[url]) {
		// 	successCallback(this.cache[url]);
		// 	return;
		// }
		return this.http.get(url, { headers: this.headers}).pipe(



		// return this.http.get(url, options)
			takeUntil(this.ngUnsubscribe))
			// .retry(2)
			// .retryWhen(error => {
			// 	return error
			// 	   .flatMap((error: any) => {
			// 		  if(error.status  !== 500) {
			// 			return Observable.of(error.status).delay(1000);
			// 		  }
			// 		  return Observable.throw({error: 'No retry'});
			// 	   })
			// 	   .take(2)
			// 	   .concat(Observable.throw({error: 'Sorry, there was an error (after 5 retries)'}));
			// })
			.toPromise()
			.then((response) => {
				// console.log(response);
				// if (responseType === 'json') {
				// 	if (response) {
				// 		successCallback(response.json());
				// 	}
				// } else {
				// this.cache[url] = response;
					successCallback(response);
				// }
			}).catch((error) => {
				setTimeout(() => {
					if (this.modalService) {
						// this.modalService.hideLoader();
					}
				}, 1500);
				this.validateToken(error);
				errorCallback(error);
			});
	}
	downloadPDF(url, callback, errorCallback): any {
		// this.setTokenHeader();
		// this.headers.responseContentType = ResponseContentType.ArrayBuffer;
		// this.headers.set('Content-Type', 'ArrayBuffer');
		// if (this.isInIpadMode) {
		// 	this.headers.set('Content-Type', 'text');
		// } else {
		// 	this.headers.set('Content-Type', 'blob');
		// }
		return this.http.get(url);
	}

	downloadFile(url): any {
		// let respType;
		// this.setTokenHeader();
		// if (this.isInIpadMode) {
		// 	respType = ResponseContentType.Text;
		// } else {
		// 	respType = ResponseContentType.Blob;
		// }
		// const options: any = new HttpHeaders({ headers: this.headers, responseType: respType });
		return this.http.get(url).pipe(map((res) => {
			return res;
		}));
	}

	// private extractData(res: Response) {
	// 	const body = res;
	// 	return body || {};
	// }

	// private handleError(error: any): Promise<any> {
	// 	return Promise.reject(error.message || error);
	// }
	/*
	* Set location based auth token in every header
	*/
	private setTokenHeader(docId?: any) {
		this.headers.set('WDALoc', localStorage.getItem('currentLocation') || environment.defaultLocation);
		const accessType = localStorage.getItem('accessType');
		let token: any;
		if (accessType === 'guest') {
			token = sessionStorage.getItem('auth_token');
		} else {
			token = localStorage.getItem('auth_token');
		}
		if (token) {
			this.headers.set('Authorization', 'Bearer ' + JSON.parse(token));
		}
		if (docId) {
			this.headers.append('docId', docId);
		} else {
			if (this.headers.get('docId')) {
				this.headers.delete('docId');
			}
		}
	}
	/*
	* validate response of any error occured
	* check last login session and redirect to auth wrapper
	* remember last session expired path(with url)
	*/
	public validateToken(error) {
		this.sharedService.sendErrorToService(error);
		let count = 0;
		if (error.status === 500) {
			// tslint:disable-next-line:max-line-length
			this.modalService.showNotification('ERROR', this.translatedValues.somethingWrong || 'Something went wrong', this.translatedValues.contactSupport || 'Please contact Support.');
		} else if (error.status === 401 && !error.__zone_symbol__currentTask) {
			this.stopAllServices();
			// sessionStorage.setItem('lastSessionPath', document.location.pathname);
			if (localStorage.getItem('accessType') === 'IP') {
				if (count === 0) {
					count += 1;
					this.redirectToLloginPath();
				}
			} else if (localStorage.getItem('accessType') === 'DB') {
				if (count === 0) {
					count += 1;
					// tslint:disable-next-line:max-line-length
					this.modalService.showNotification('WARNING', this.translatedValues.sessionExpired, this.translatedValues.authenticatingAgain || 'Authenticating again …');
					this.redirectToLloginPath();
				}
			} else {
				if (count === 0) {
					count += 1;
					// tslint:disable-next-line:max-line-length
					this.modalService.showNotification('WARNING', this.translatedValues.sessionExpired || 'Session expired', this.translatedValues.authenticatingAgain || 'Authenticating again …');
					this.redirectToLloginPath();
				}
			}
		}
	}
	public redirectToLloginPath() {
		this.hideAll();
		localStorage.removeItem('auth_token');
		localStorage.removeItem('HistoryList');
		localStorage.removeItem('documentList');
		localStorage.removeItem('croppedImageList');
		localStorage.removeItem('detailsHistory');
		sessionStorage.removeItem('TATLimiters');
		sessionStorage.removeItem('termFrequency');
		sessionStorage.removeItem('TFLimiter');
		// localStorage.removeItem('personalizationData');
		// window.location.pathname = `${document.location.pathname.split('/')[1]}/auth`;
		setTimeout(() => {
			this.router.navigate([`${(document.location.pathname.split('/')[1])}/auth`]);
		});
	}

	hideAll(): void {
		// try to hide all active modals
		const openModals = document.querySelectorAll('.modal.in');
		if (openModals) {
			for (let i = 0; i < openModals.length; i++) {
				// Get the modal-header of the modal
				const modalHeader = openModals[i].getElementsByClassName('modal-header');
				const modalBody = openModals[i].getElementsByClassName('modal-body');
				const modalFooter = openModals[i].getElementsByClassName('modal-footer');
				if (modalHeader && modalHeader.length > 0) {
					// Get the close button in the modal header
					const closeButton: any = modalHeader[0].getElementsByTagName('BUTTON');
					if (closeButton && closeButton.length > 0) {
						// simulate click on close button
						closeButton[0].click();
					}
				}
				if (modalBody && modalBody.length > 0) {
					// Get the close button in the modal body
					const closeButton: any = modalBody[0].getElementsByTagName('BUTTON');
					if (closeButton && closeButton.length > 0) {
						// simulate click on close button
						closeButton[0].click();
					}
				}
				if (modalFooter && modalFooter.length > 0) {
					// Get the close button in the modal footer
					const closeButton: any = modalFooter[0].getElementsByTagName('BUTTON');
					if (closeButton && closeButton.length > 0) {
						// simulate click on close button
						closeButton[0].click();
					}
				}
			}
		}
	}

	ngOnDestroy() {
		if (this.navObservable) {
			this.navObservable.unsubscribe();
			this.ngUnsubscribe.next();
			this.ngUnsubscribe.complete();
		}
	}

	stopAllServices() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getCurrentAccessToken() {
		const accessType = localStorage.getItem('accessType');
		let token: any;
		if (accessType === 'guest') {
			token = sessionStorage.getItem('auth_token');
		} else {
			token = localStorage.getItem('auth_token');
		}
		return JSON.parse(token);
	}
	getDataObservable(url: string) {
    return this.http.get(url, {responseType: 'text' as 'json'});
	}
}
