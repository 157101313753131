<!-- Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<div class="row mainClassTerm">
  <div class="col-md-12 ps-0">
    <h5 [class.arabic-style]="isArabicLanguage" class="chart-query-container-title">
      <b [class.arabic-style]="isArabicLanguage">{{ 'textAnalysisPage.termPopularity' | translate }} - </b>
      @if (!isLimiterChanged) {
        <span>
          <b [class.arabic-style]="isArabicLanguage">{{'textAnalysisPage.AllDocuments'|translate}}</b>
          <br/>
          <p [class.arabic-style]="isArabicLanguage" class="small lh-17" [ngClass]="{'fontResponsive': lang === 'ta'}">
          {{'textAnalysisPage.popularityCaption'|translate}}</p>
        </span>
      }

      @if (isLimiterChanged) {
        <span>
          <b [class.arabic-style]="isArabicLanguage">{{'textAnalysisPage.LimitedDocuments'|translate}}</b>
          <br/>
          <p [class.arabic-style]="isArabicLanguage" class="small lh-17" [ngClass]="{'fontResponsive': lang === 'ta'}">
          {{'textAnalysisPage.popularityCaption'|translate}}</p>
        </span>
      }
    </h5>
  </div>

  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="marginOne">
      @if (showChartLoader) {
        <div class="chartLoader">
          <img src="assets/images/dot-loader-dark.gif">
        </div>
      }
      @if (lineChartData.length > 0) {
        <div class="chartAreaWrapper">
          <span [class.arabic-style]="isArabicLanguage" class="chart-label no-print"> {{ 'textAnalysisPage.popularity' | translate }} (%)</span>
          <!-- <canvas baseChart width="400" height="400"
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)"></canvas> -->
        <canvas ngChartjs
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          [colors]="lineChartColors"
          [chartType]="lineChartType"
          [inlinePlugins]="inlinePlugin"
          (chartClick)="chartClicked($event)">
        </canvas>
        <div class="clearfix"></div>
        @if (yearFrom && yearTo) {
          <div class="col-md-12 col-sm-12 col-xs-12 slider-control term-frequency_slider no-print">
            <app-range-slider [value]="rangeArray" [minValue]="yearFromChart" (RangeChanged)="sliderRangeChanged($event)"
            [maxValue]="yearToChart" [stepValue]="1" [range]="true"></app-range-slider>
            <span>{{yearFrom}} - {{yearTo}}</span>
          </div>
        }
      </div>
    }
  </div>
</div>
</div>