export const MAP_CONFIG = {
    BASE_MAP_URL: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    MAX_ZOOM: 18,
    MIN_ZOOM: 2,
    ZOOM: 2,
    ATTRIBUTION: '...',
    CONTROL_POSITION: 'topright',
    DEFAULT_FIELDS: {
        LAT: 'lat',
        LON: 'lon',
        VALUE: 'count'
    }
};
