/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit, Input, forwardRef, Output, SimpleChanges, OnChanges } from '@angular/core';
import { EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from '../../../../../../node_modules/jquery';
declare let $: any;
@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeSliderComponent),
      multi: true
    }
  ]
})
export class RangeSliderComponent implements AfterViewInit, ControlValueAccessor, OnChanges {
  private _minValue = -100;
  private _maxValue = 100;
  mapView: Boolean;
  @Input('fromMapView')
  set _fromMapView(value: Boolean) {
  this.mapView = value;
  }
  get _fromMapView() {
    return this.mapView;
  }
  @Input() private stepValue = 1;
  @Input() private name: string;
  @Input('range') private range = false;
  private sliderInstance: any;

  // @ViewChild('control', { read: ElementRef }) private sliderControl: ElementRef;
  @ViewChild('control', { static: false, read: ElementRef }) private sliderControl: ElementRef;

  @Output() RangeChanged: EventEmitter<any> = new EventEmitter();

  @Input('maxValue')
  set maxValue(value: number) {
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }
    if (value > this._minValue) {
      this._maxValue = value;
    }
    if (this.sliderInstance) {
      $(this.sliderControl.nativeElement).slider('option', 'max', this._maxValue);
    }
  }
  get maxValue() {
    return this._maxValue;
  }

  @Input('minValue')
  set minValue(value: number) {
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }
    this._minValue = value;
    if (this.sliderInstance) {
      $(this.sliderControl.nativeElement).slider('option', 'min', value);
    }
  }
  get minValue() {
    return this._minValue;
  }

  @Input('value')
  set _value(value: Array<any>) {
    this.sliderValue = value;
  }
  get _value() {
    return this.sliderValue;
  }

  private sliderValue: Array<any> = [];

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.initSlider();
  }
  /**
   * @description : detect changes from input and create/update slider
   * @param changes : detect changes from input
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes._value) {
        if (changes._value.currentValue) {
          if (this.mapView) {
                this. initSlider();
            }
        }
    }
  }
  initSlider() {
    this.sliderInstance = $(this.sliderControl.nativeElement).slider({
      range: this.range,
      min: parseInt(this._minValue + '', 10),
      max: parseInt(this._maxValue + '', 10),
      step: this.stepValue,
      values: this.sliderValue,
      create: function (event, ui) {
        this.isSliderInitialized = true;
      },
      slide: (event, ui) => {
        if (ui.values) {
          this.sliderValue = [ui.values];
        } else if (ui.value) {
          this.sliderValue = ui.value;
        }
        if (!this.mapView) {
          this.RangeChanged.emit(this.sliderValue);
          this.propagateChange(this.sliderValue);
        }
      },
      stop: (event, ui) => {
        if (ui.values) {
          this.sliderValue = [ui.values];
        } else if (ui.value) {
          this.sliderValue = ui.value;
        }
        if (this.mapView) {
          this.RangeChanged.emit(this.sliderValue);
          this.propagateChange(this.sliderValue);
        }
    }
    }).slider('instance');
  }
  writeValue(value: any) {
    if (this.range) {
      if (value !== null && value !== undefined) {
        let start = value[0];
        let end = value[1];
        if (typeof start === 'string') {
          start = parseInt(start, 10);
        }
        if (typeof end === 'string') {
          end = parseInt(end, 10);
        }
        this.sliderValue = [start, end];
        $(this.sliderControl.nativeElement).slider('values', this.sliderValue);
      }
    } else {
      if (value !== null && value !== undefined) {
        if (typeof value === 'string') {
          value = parseInt(value, 10);
        }
        this.sliderValue = value;
        $(this.sliderControl.nativeElement).slider('value', this.sliderValue);
      }
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {

  }
}
