<!-- Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<div class="row mainClassTerm">
  <div class="col-md-12 ps-0">
    <h5 [class.arabic-style]="isArabicLanguage" class="chart-query-container-title">
      <b [class.arabic-style]="isArabicLanguage" >{{ 'textAnalysisPage.TermFrequency' | translate }} -</b>
      @if (limiterList.length <= 1) {
        <span>
          <b [class.arabic-style]="isArabicLanguage" >{{'textAnalysisPage.AllDocuments'|translate}}</b>
          <br />
          <p class="small lh-17" [ngClass]="{'fontResponsive': lang === 'ta'}" [class.arabic-style]="isArabicLanguage" >{{ 'textAnalysisPage.frequencyCaption' | translate }}</p>
        </span>
      }
      @else {
        <span>
          <b [class.arabic-style]="isArabicLanguage" >{{'textAnalysisPage.LimitedDocuments'|translate}}</b>
          <br />
          <p class="small lh-17" [ngClass]="{'fontResponsive': lang === 'ta'}" [class.arabic-style]="isArabicLanguage" >{{ 'textAnalysisPage.frequencyCaption' | translate }}</p>
        </span>
      }
    </h5>
  </div>
  <div class="col-md-12 pe-0">
    <div style="margin:0px 0px 0px 1%;">
      @if (showChartLoader) {
        <div class="chartLoader">
          <img src="assets/images/dot-loader-dark.gif">
        </div>
      }
      @if (lineChartData.length > 0) {
        <div class="chartAreaWrapper">
          <span [class.arabic-style]="isArabicLanguage" class="chart-label no-print"> {{ 'textAnalysisPage.noOfDocuments' | translate }}</span>
          <canvas ngChartjs
            [datasets]="lineChartData"
            [labels]="xAxisLabel"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [colors]="lineChartColors"
            [chartType]="lineChartType"
            [inlinePlugins]="inlinePlugin">
          </canvas>
          <div class="clearfix"></div>
          @if (yearFrom && yearTo) {
            <div class="col-md-12 slider-control term-frequency_slider no-print">
              <app-range-slider [value]="rangeArray" [minValue]="minYearValue" (RangeChanged)="sliderRangeChanged($event)"
              [maxValue]="maxYearValue" [stepValue]="1" [range]="true"></app-range-slider>
              <span>{{yearFrom}} - {{yearTo}}</span>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>