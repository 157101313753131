
import {takeUntil} from 'rxjs/operators';
/* Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved */
import { Component, OnInit, OnDestroy, Input, AfterContentInit } from '@angular/core';
import { PageSettingsService } from '../../common/services/page-settings.service';
import { SearchService } from '../../common/services/search.service';
import { orderBy} from 'lodash';
import { SharedService } from '../../common/services/sharedService';
import { Subscription ,  Subject } from 'rxjs';
import { CommonService } from '../services/common.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgChartjsModule } from 'ng-chartjs';
import { RangeSliderModule } from '../../common/modules/range-slider/range-slider.module';
// declare let $: any;
@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule, NgChartjsModule, RangeSliderModule],
  selector: 'app-term-frequency',
  templateUrl: './term-frequency.component.html',
  styleUrls: ['./term-frequency.component.css'],
})
export class TermFrequencyComponent implements  OnInit, OnDestroy, AfterContentInit {
  lang: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  termFreqCache$ = {};
  _filterInputs: any;
  limiterList: any;
  newFilterParams = [];
  unSubPrintObservable: Subscription;
  inlinePlugin: any;
  textPlugin: any;
  @Input('filterInputs')
  set filterInputs(value) {
    this._filterInputs = value;
    this.limiterList = this._filterInputs.filterParams.filterParams;
    if (value.terms.length > 0 ) {
        this.getChartData();
    } else {
        this.drawEmptyChart();
    }
  }
  rangeArray: Number[] = [0, 0];
  maxYearValue: Number = 100;
  minYearValue: Number = 1;
  yearFrom: any;
  yearTo: any;
  private graphData: any;
  pageContent: any;
  showChartLoader: Boolean = false;
  isArabicLanguage: Boolean = false;
  sortedArray: any;
  chartDataYear: any = [];
  chartDataCount: any = [];
  currentTerms: any = { terms: [] };
  copyAdvancedFilterParam: any = { searchKeywords: {} };
  chartColors: any = ['#009891', '#4e6af1', '#1bbec6', '#61165E ', '#8a6d3b', '#e168f7', '#bc5e0b', '#860000'];
  lineChartData: Array<any> = [];
  xAxisLabel: Array<any> = [];
  lineChartOptions: any = {
    title: {
      display: false,
      text: ''
    },
    legend: { display: true },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
        }
      }
    }
  };
  lineChartColors: Array<any> = [];
  lineChartLegend: Boolean = true;
  lineChartType: String = 'line';

  // events
  chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if ( activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._datasetIndex;
        const label = chart.data.datasets[clickedElementIndex].label;
        const year = chart.data.labels[activePoints[0]._index];
        const searchTermFlag = [];
        const currentFilterParams = JSON.parse(JSON.stringify(this._filterInputs.filterParams.filterParams));
        currentFilterParams.archive = [];
        currentFilterParams.searchKeywords = [];
        currentFilterParams.publishedDateFrom = year.toString();
        currentFilterParams.publishedDatePrefix = 'ON';
        currentFilterParams.publishedDateIncludesNull = false;
        currentFilterParams.publishedDateTo = '';
        const newArchiveFilterArray = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
        if (newArchiveFilterArray.length > 0) {
          currentFilterParams.archive = newArchiveFilterArray[0].fieldValues;
        } else {
          currentFilterParams.archive = [];
        }
        // this.modalService.showLoader();
        this.searchService.goToAdvancedSearchPageFromTextAnalysis(label, currentFilterParams, searchTermFlag);
      }
    }
  }
  constructor(
    private searchService: SearchService,
    private commonService: CommonService,
    private sharedService: SharedService
  ) {
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        this.pageContent = data;
      }
    });
  }
  ngOnInit() {
    this.textPlugin = [{
      id: 'linePlugin',
      beforeDraw(chart: any): any {
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';
        const text = '';
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }];

    this.inlinePlugin = this.textPlugin;
  }
  ngAfterContentInit() {
    this.commonService.printChart.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res.status) {
        this.commonService.printTermFrequencyChart('Term Frequency', res.filterParams);
      }
    });
    const currentLanguage = sessionStorage.getItem('lang');
    this.lang = currentLanguage;
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      // tslint:disable-next-line:max-line-length
      takeUntil(this.ngUnsubscribe)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  getChartData() {
    this.showChartLoader = true;
    if (this._filterInputs.preventLimiters) {
      const newArchiveFilterArray = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
      this.newFilterParams = newArchiveFilterArray;
    } else {
      this.newFilterParams = this._filterInputs.filterParams.filterParams;
    }
    if (this.newFilterParams.length > 0) {
      for(let k =0; k < this.newFilterParams.length; k++) {
        if(this.newFilterParams[k].fieldName === "archive") {
         const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
         const haveRGS = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RGS'); 
         if (haveRGS) {
          const haveRGS1 = getSelectedArchives.includes('RGS1');
          if(haveRGS1) {
            this.newFilterParams[k].fieldValues.push('RGS1');
          }
          const haveRGS2 = getSelectedArchives.includes('RGS2');
          if(haveRGS2) {
            this.newFilterParams[k].fieldValues.push('RGS2');
          }
          this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RGS',this.newFilterParams[k].fieldValues);
         }
         const haveRCP = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RCP'); 
         if (haveRCP) {
          const haveRCP1 = getSelectedArchives.includes('RCP1');
          if(haveRCP1) {
            this.newFilterParams[k].fieldValues.push('RCP1');
          }
          const haveRCP2 = getSelectedArchives.includes('RCP2');
          if(haveRCP2) {
            this.newFilterParams[k].fieldValues.push('RCP2');
          }
          this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RCP',this.newFilterParams[k].fieldValues);
         }
        }
      }
// this.newFilterParams[0].fieldValues = ['RGS1',"RCP1", "RCP2","RGS2"]
    }

    const frequencyRequestParams = {
      filterParams : this.newFilterParams,
      searchParams : [],
      terms : this._filterInputs.terms,
      groupOffset : 0,
      groupLimit : 5,
      pubStartDate : this.pageContent.pubDates.startDate,
      pubEndDate : this.pageContent.pubDates.endDate
    };
    this.commonService.gettermFrequencyData(frequencyRequestParams).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if (response.status === 'SUCCESS') {
          this.termFreqCache$[JSON.stringify(frequencyRequestParams)] = response.results;
          this.createChart(response.results);
          this.graphData = response;
      }
    }, (error) => {
    });
  }
  removeSelectedValFromArray(val, arr) {
    let value = val;
    arr = arr.filter(item => item !== value)
    return arr;
  }

  createChart(freqChartData) {
    freqChartData.forEach((obj, index) => {
      this.sortedArray = orderBy(obj.statsResults[0].facetResults[0].facetContent, ['value'], ['asc']);
      if (this.sortedArray.length > 0) {
        this.xAxisLabel = [];
        this.chartDataYear = [];
        this.chartDataCount = [];
        for (const facetPubdateObj of this.sortedArray) {
          this.chartDataYear.push(facetPubdateObj.value);
          this.chartDataCount.push(facetPubdateObj.count);
        }
        this.xAxisLabel = this.chartDataYear;
        this.lineChartData.push({
          data:  this.chartDataCount,
          label: this._filterInputs.terms[index]
        });
        this.lineChartColors.push({
          backgroundColor: 'transparent',
          borderColor: this.chartColors[index],
          pointBackgroundColor: this.chartColors[index],
          pointBorderColor: '#fff',
          fill: false,
          pointHoverBackgroundColor: this.chartColors[index],
          pointHoverBorderColor: this.chartColors[index],
        });
      }
    });
    this.showChartLoader = false;
    this.rangeArray = [];
    if (this.chartDataYear.length > 0) {
      const chartArry = this.chartDataYear;
      this.minYearValue = Math.min.apply(Math, chartArry);
      this.maxYearValue = Math.max.apply(Math, chartArry);
      this.yearTo = +freqChartData[0].statsResults[0].max;
      this.yearFrom = +freqChartData[0].statsResults[0].min;
      this.rangeArray.push(Math.min.apply(Math, chartArry));
      this.rangeArray.push(Math.max.apply(Math, chartArry));
    }
  }
  sliderRangeChanged(event) {
    this.yearFrom = event[0][0];
    this.yearTo = (event[0][1]);
    this.filterChartData();
  }
  /*
  * Filter chart data based on year range from chart slider
  */
  filterChartData() {
    const resultsFromResponse = this.graphData.results;
    const yAxisArray: any = [];
    resultsFromResponse.forEach((obj, index) => {
      this.chartDataCount = [];
      this.sortedArray = orderBy(obj.statsResults[0].facetResults[0].facetContent, ['value'], ['asc']);
      if (this.sortedArray.length > 0) {
        this.xAxisLabel = [];
        this.chartDataYear = [];
        this.chartDataCount = [];
        for (const facetPubdateObj of this.sortedArray) {
          if (facetPubdateObj.value >= this.yearFrom && facetPubdateObj.value <= this.yearTo) {
            this.chartDataYear.push(facetPubdateObj.value);
            this.chartDataCount.push(facetPubdateObj.count);
          }
        }
        this.xAxisLabel = this.chartDataYear;
        yAxisArray.push({
          data:  this.chartDataCount,
          label: this._filterInputs.terms[index]
        });
        this.lineChartColors.push(
          {
            backgroundColor: 'transparent',
            borderColor: this.chartColors[index],
            pointBackgroundColor: this.chartColors[index],
            pointBorderColor: '#fff',
            fill: false,
            pointHoverBackgroundColor: this.chartColors[index],
            pointHoverBorderColor: this.chartColors[index],
          }
        );
      }
    });
    setTimeout(() => {
      this.lineChartData = yAxisArray;
    }, 200);
  }
  drawEmptyChart() {
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales : {
        yAxes: {
            ticks: {
            beginAtZero: true,
              // stepValue: 0,
              // steps: 10,
              // max : 10,
            }
        }
      }
    };
    this.lineChartLegend = false;
    this.xAxisLabel = [];
    this.lineChartData = [
      { data: [], label: '' },
    ];
  }
}
