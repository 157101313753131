
import { takeUntil } from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { environment } from './../../environments/environment';
import {
  Component, OnInit, Input, HostListener, OnDestroy, TemplateRef, ViewChild,
  AfterContentInit, Inject, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, fromEvent, Subject } from 'rxjs';
import { PageSettingsService } from './../common/services/page-settings.service';
import { ModalService } from './../common/services/modal.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SearchService } from './../common/services/search.service';
import { Title } from '@angular/platform-browser';
declare let $: any;
import { UrlUtilityService } from '../common/services/url-utility.service';
import { UtilityService } from '../common/services/utility.service';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpService } from './../common/services/http.service';
import { SharedService } from '../common/services/sharedService';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../common/services/common.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { LangChangeEvent } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { APIURLS } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wiley-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnDestroy, AfterContentInit, OnInit {
  // @ViewChild('exportRunTemplate') private exportRunTemplate: TemplateRef<any>;
  private exportRunTemplatemodalRef: BsModalRef;

  // @ViewChild('exportClipTemplate') private exportClipTemplate: TemplateRef<any>;
  private exportClipTemplatemodalRef: BsModalRef;
  private downloadPDFmodalRef: BsModalRef;
  // @ViewChild('downloadDocsTemplate', { static: false}) private downloadDocsTemplate: TemplateRef<any>;

  // @ViewChild('downloadDocsTemplate')
  // private downloadDocsTemplate: TemplateRef<any>;
  WDATrainingHubLink = 'https://www.wiley.com/network/wdatraining';
  archivesList: any = [];
  locationConfigData: any;
  currentClipImageForDeleted: any;
  downloadSize = 587202; /*5.6 MB Size image file*/
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatus: Boolean;
  clippedImage: any;
  currentLanguage: any;
  isArabicLanguage: Boolean = false;
  isLanguageDropdownOpen = false;
  languageName: any;
  languages: any;
  // @ViewChild('documentDetailsTab') private docDetailsTab: TabsetComponent;
  // @ViewChild('historyTab') private historyDetailsTab: TabsetComponent;
  currentSlide: any;
  activeSlideIndex = 0;
  sliderImages = [];
  clippedImageList = [];
  translatedValues: any;
  returnToLandingPage: string;
  controlShortcut: any;
  shortcutKeyTitle: any;
  currentOrigin: string;
  language = sessionStorage.getItem('lang');
  isGuestUser: any;
  deleteableItem: any;
  deleteableContentType: any;
  otherarchivesDropdown: any;
  toolsDropDown: any;
  historyObject: any[] = [];
  previousHistoryObject: any;
  currentLocation: string;
  showKnownIssues: any;
  guideUrl: SafeResourceUrl;
  private toolsCount = 0;
  // private otherarchiveCount = 0;
  isEndSessionOpen: Boolean = false;
  isFeedbackOpen: Boolean = false;
  isHelpOpen: Boolean = false;
  currentHistory: any;
  filterParams: any;
  _searchTerm: any;
  currentArchive: string;
  isViewListOpen: Boolean = false;
  isHistoryOpen: Boolean = false;
  togglePopup: Boolean = false;
  historyObj: any;
  historyDate: any;
  datetool: any[];
  filters: any;
  absolutePath: string;
  fieldVals: any;
  searchVal: Boolean = false;
  selectedContentType: any = 'All';
  homePageUrl: String = '';
  private clearHistorymodalRef: BsModalRef;
  private clipImageTemplatemodalRef: BsModalRef;
  private endSessionmodalRef: BsModalRef;
  private viewListmodalRef: BsModalRef;
  private viewCroppedImageListmodalRef: BsModalRef;
  private removeCroppedImageListmodalRef: BsModalRef;
  private removeListmodalRef: BsModalRef;
  toolsdropdownActive: Boolean = false;
  otherArchiveDropdown: Boolean = false;
  hideOnIpad: Boolean;
  headerContent: any;
  toggleArrow: Boolean = false;
  toggleArrowTimeout = 5000;
  ieBrowserEnabled: any;
  selectedLanguage: any;
  @ViewChild('pop', { static: false }) pop: PopoverDirective;
  @ViewChild('historypop', { static: false }) history_pop: PopoverDirective;
  @ViewChild('feedbackpop', { static: false }) feedbackpop: PopoverDirective;
  @ViewChild('clearHistoryTemplate', { static: false }) private clearHistoryTemplate: TemplateRef<any>;
  @ViewChild('clipImageTemplate', { static: false }) private clipImageTemplate: TemplateRef<any>;
  @ViewChild('endSessionTemplate', { static: false }) private endSessionTemplate: TemplateRef<any>;
  @ViewChild('viewListTemplate', { static: false }) private viewListTemplate: TemplateRef<any>;
  @ViewChild('viewCroppedImageListTemplate', { static: false }) private viewCroppedImageListTemplate: TemplateRef<any>;
  @ViewChild('removeCroppedImageListTemplate', { static: false }) private removeCroppedImageListTemplate: TemplateRef<any>;
  @ViewChild('removeListTemplate', { static: false }) private removeListTemplate: TemplateRef<any>;
  @ViewChild('helpLink', { static: false }) private help_Link: PopoverDirective;
  @ViewChild('connectionIndicator', { static: false, read: ElementRef }) connectionIndicator: ElementRef;
  @ViewChild('exportRunTemplate', { static: false }) private exportRunTemplate: TemplateRef<any>;
  @ViewChild('exportClipTemplate', { static: false }) private exportClipTemplate: TemplateRef<any>;
  @ViewChild('downloadDocsTemplate', { static: false }) private downloadDocsTemplate: TemplateRef<any>;
  @ViewChild('documentDetailsTab', { static: false }) private docDetailsTab: TabsetComponent;
  @ViewChild('historyTab', { static: false }) private historyDetailsTab: TabsetComponent;

  // @ViewChild('historyTab') private historyDetailsTab: TabsetComponent;
  // @ViewChild('pop') pop: PopoverDirective;
  // @ViewChild('historypop') history_pop: PopoverDirective;
  // @ViewChild('feedbackpop') feedbackpop: PopoverDirective;
  // @ViewChild('clearHistoryTemplate') private clearHistoryTemplate: TemplateRef<any>;
  // @ViewChild('clipImageTemplate') private clipImageTemplate: TemplateRef<any>;
  // @ViewChild('endSessionTemplate') private endSessionTemplate: TemplateRef<any>;
  // @ViewChild('viewListTemplate') private viewListTemplate: TemplateRef<any>;
  // @ViewChild('viewCroppedImageListTemplate') private viewCroppedImageListTemplate: TemplateRef<any>;
  // @ViewChild('removeCroppedImageListTemplate') private removeCroppedImageListTemplate: TemplateRef<any>;
  // @ViewChild('removeListTemplate') private removeListTemplate: TemplateRef<any>;
  // @ViewChild('helpLink') private help_Link: PopoverDirective;
  // @ViewChild('connectionIndicator', { read: ElementRef }) connectionIndicator: ElementRef;

  _archive: any;
  shiftControl: string;
  dropdownValue: any;
  viewListPop: Boolean = false;
  DOMLoadendtime: number;
  DOMcontentstartTime: number;
  imageData: Object;
  timeInterval: any;
  showConncetionStatus: Boolean = false;
  isGuestUserFromShortURL: Boolean = false;
  isBothRCPAccess: Boolean = false;
  isBothRGSAccess: Boolean = false;
  currentExportURL: any;
  currentRunQuery: string;
  rulQueryShortURL: any;
  viewContentCount: number;
  viewContentList: any;
  downloadDocs: any;
  savedFilters: any;

  // tslint:disable-next-line:no-input-rename
  @Input('page-content')
  set pageContent(data: string) {
    if (data) {
      data['archiveLogo'] = data['archiveLogo'] ? data['archiveLogo'] : '/assets/config/wiley/wiley_logo_old.png';
      this.headerContent = data;
    }
  }
  get pageContent() {
    return this.headerContent;
  }

  private headerfilterDropdownOptions: any = {
    headerarchivesName: [
      { id: 1, itemName: 'Monographs' },
      { id: 2, itemName: 'Manuscripts' },
      { id: 3, itemName: 'Newspaper' },
      { id: 4, itemName: 'Images' },
      { id: 5, itemName: 'Audio' },
      { id: 6, itemName: 'Photographs' }
    ]
  };
  private headerarchiveFilters: any = {
    headerarchivesName: []
  };
  private headeradvancedDropdownSettings: any = {
    headerarchivesName: {
      singleSelection: true,
      text: 'Wiley Archive',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      maxHeight: 200,
      classes: 'headerarchivesearch-Dropdowns'
    }
  };
  togglewileyarchivemenu: Boolean = false;
  private toggleotherearchivemenu: Boolean = false;
  private toggleMainHeader: Boolean = true;
  private toggleviewlist: Boolean = false;
  viewList;
  viewListCount: number;
  private pageTitle: any;
  showToolTipContent;
  private toggleheader: Boolean = false;
  headerTitleText: String;
  viewHistoryList: any;
  private viewHistoryUrl: any;
  private locations: any;
  private toggleviewhistory = false;
  private fieldVal = false;
  private searchValue: any;
  selectedViewList: any = [];
  private advancedFilterParam: AdvancedFilterParams;
  private host: any;
  private isDesc: Boolean = false;
  private column: String = 'date';
  private filedVal: Boolean = false;
  private sendMailData: SendMail = {
    topic: '',
    message: '',
    name: '',
    mail: ''
  };
  private unSubscribe: Observable<any>;
  fieldNames = {
    'ALL': 'Entire Document',
    'title': 'Document Title',
    'id': 'Document Number',
    'author': 'Authors',
    'authors': 'Authors',
    'bibID': 'Bib ID',
    'fullText': 'Full Text',
    'keyword': 'Keyword',
    'docLang': 'Language',
    'placeOfPub': 'Place of Publication',
    'subject': 'Subject',
    // 'pubTitle': 'Publication Title',
    'manuscriptNoV2': 'Reference Number',
    'barcode': 'Barcode',
    'docNotes': 'Location',
    'collectionCreators': 'Creator',
    'tableData': 'Table'
  };
  private _enableSubmit: Boolean = false;
  feedback: UntypedFormGroup;
  private content: any;
  private subject: any;
  private systemIP: any;
  private mail: any;
  private name: any;
  private location: any;
  private body: any;
  issuesUrl: SafeResourceUrl;
  documentHistory: any;
  private request: Observable<any>;
  private versionNumber: any;
  private defaultPageTitle: any;
  noImageUrl = 'assets/images/noimage.jpg';
  accessToken: any;
  accessibilityUrl: any;
  message: string;
  dropdownOpen: string;

  operationSystem: any;
  iosPlatforms: any;
  windowsPlatforms: any;
  macosPlatforms: any;
  platform: any;
  userAgent: any;
  isSessionGuest: any;
  locationConfigDetails: any;
  archivesWithShowFolderImage: any = [];
  RGSHomePageLogo = 'assets/config/rgs/rgs-logo-home.png';
  stop$: Subject<void> = new Subject();
  // @ViewChild('connectionIndicator', { read: ElementRef }) connectionIndicator: ElementRef;
  isDownloadContentOpen: Boolean = false;
  showDCIcon: boolean;
  weburl: any;
  constructor(
    private router: Router,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private pageSettingsService: PageSettingsService,
    private searchService: SearchService,
    private titleService: Title,
    private urlUtilityService: UrlUtilityService,
    private utilityService: UtilityService,
    // private builder: FormBuilder,
    public sanitizer: DomSanitizer,
    private httpService: HttpService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private commonService: CommonService,
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private dom: Document
    // private ref: ChangeDetectorRef
  ) {
    localStorage.removeItem('commonLimiters');
    this.isSessionGuest = localStorage.getItem('accessType');
    this.isGuestUser = false;
    this.toggleMainHeader = sessionStorage.getItem('wileyShowTopBar') === 'true' ? true : false;
    this.viewList = [];
    this.viewListCount = 0;
    this.viewContentCount = 0;
    this.headerTitleText = 'Expand Header';
    this.currentOrigin = window.location.origin;
    this.host = window.location.host;
    this.guideUrl = sanitizer.bypassSecurityTrustResourceUrl(
      window.location.origin + '/content/pdfs/WDA_QuickGuide_Complete_v2.0.pdf'
    );

    this.accessibilityUrl = sanitizer.bypassSecurityTrustResourceUrl(
      window.location.origin + '/content/pdfs/WDA_Accessibility.pdf'
    );

    this.createForm();
    this.issuesUrl = sanitizer.bypassSecurityTrustResourceUrl(
      window.location.origin + '/content/pdfs/WDA_KnownIssues_complete.pdf'
    );
    this.accessToken = this.httpService.getCurrentAccessToken();
    this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(persResponse => {
      if (persResponse) {
        localStorage.setItem('HeaderLogo', JSON.stringify(persResponse));
        this.locationConfigDetails = persResponse.locations;
        const locationCofigDetails = Object.keys(this.locationConfigDetails).map(key => ({
          type: key, value: this.locationConfigDetails[key]
        }));

        if (this.archivesWithShowFolderImage.length === 0) {
          locationCofigDetails.forEach(element => {
            if (element.value['showFolderImage'] === 'true') {
              this.archivesWithShowFolderImage.push(element.type);
            }
          });
        }
        if (persResponse.locations[PageSettingsService.pageSettings.currentArchive]) {
          this.translate.get(persResponse.locations[PageSettingsService.pageSettings.currentArchive]['pageTitle']).pipe(
            takeUntil(this.stop$)).subscribe((res: string) => {
              this.defaultPageTitle = res;
            });
        }
        if (this.headerContent) {
          //     if (this.headerContent.currentArchive === 'BAAS') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('British Association for the Advancement of Science (Collections on the History of Science: 1830—1970)' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'RAI') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('The Royal Anthropological Institute of Great Britain and Ireland' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'NYAS') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('The New York Academy of Sciences' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'RGS') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('Royal Geographical Society (with IBG)' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'RGS1') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('Royal Geographical Society (with IBG) – Part Ⅰ' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'RGS2') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('Royal Geographical Society (with IBG) – Part Ⅱ' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'RCP') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('The Royal College of Physicians' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'RCP1') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('The Royal College of Physicians – Part Ⅰ' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'RCP2') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('The Royal College of Physicians– Part Ⅱ' + ' - ' + environment.version);
          //     } else if (this.headerContent.currentArchive === 'RAITEST') {
          //       // tslint:disable-next-line:max-line-length
          //       this.titleService.setTitle('The Royal Anthropological Institute of Great Britain and Ireland - Test' + ' - ' + environment.version);
          //     } else {
          //       // this.titleService.setTitle((this.headerContent._pageTitle ?  this.headerContent._pageTitle : 'Wiley Digital Archives') + ' - ' + environment.version);
          //       this.titleService.setTitle( 'Wiley Digital Archives' + ' - ' + environment.version);

          //     }


          if (this.headerContent._pageTitle) {
            this.titleService.setTitle((this.headerContent._pageTitle || 'Wiley Digital Archives') + ' - ' + environment.version);
          } else {
            this.titleService.setTitle((this.defaultPageTitle || 'Wiley Digital Archives') + ' - ' + environment.version);
          }
        } else {
          this.titleService.setTitle((this.defaultPageTitle || 'Wiley Digital Archives') + ' - ' + environment.version);

        }

      }
    }, (error) => { });
    this.locationConfigData = JSON.parse(localStorage.getItem('HeaderLogo'));
    if (this.locationConfigData) {
    } else {
      this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(response => {
        localStorage.setItem('HeaderLogo', JSON.stringify(response));
        this.locationConfigData = response;
      });
    }
    this.getArchives(localStorage.getItem('currentLocation'));
  }

  ngOnInit() {
    this.setShortCuts();
    const languageDataReceivedData = JSON.parse(sessionStorage.getItem('languageDataReceived'));
    if (languageDataReceivedData) {
      if (languageDataReceivedData.length > 0) {
        this.languages = languageDataReceivedData;
        if (sessionStorage.getItem('lang')) {
          this.selectedLanguage = sessionStorage.getItem('lang');
        }
        this.languages.forEach(obj => {
          if (obj.value === this.selectedLanguage) {
            this.languageName = obj.key;
          }
        });
      }
    } else {
      this.httpService.get('../assets/config/translate-languages.json', (response) => {
        this.languages = response.languages;
        sessionStorage.setItem('languageDataReceived', JSON.stringify(this.languages));
        if (sessionStorage.getItem('lang')) {
          this.selectedLanguage = sessionStorage.getItem('lang');
        } else {
          this.selectedLanguage = this.translate.currentLang;
          sessionStorage.setItem('lang', this.translate.currentLang);
        }
        this.languages.forEach(obj => {
          if (obj.value === this.selectedLanguage) {
            this.languageName = obj.key;
          }
        });
      }, err => { });
    }
    this.sharedService.isGuest.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.isGuestUser = res ? true : false;
    });
    this.sharedService.currentMessage.pipe(takeUntil(this.stop$)).subscribe(message => {
      this.isLanguageDropdownOpen = false;
      this.toolsdropdownActive = false;
      this.toolsDropdownActive();
      this.otherArchiveDropdown = false;
    });
    this.sharedService.currentValue.pipe(takeUntil(this.stop$)).subscribe(message => {
      this.isLanguageDropdownOpen = false;
      this.togglewileyarchivemenu = false;
      this.toolsdropdownActive = false;
      this.toolsDropdownActive();
      this.otherArchiveDropdown = false;
    });
    this.sharedService.archiveValue.pipe(takeUntil(this.stop$)).subscribe(message => {
      this.isLanguageDropdownOpen = false;
      this.togglewileyarchivemenu = false;
      this.toolsdropdownActive = false;
      this.toolsDropdownActive();
      this.otherArchiveDropdown = false;
    });
    this.sharedService.otherArchiveValue.pipe(takeUntil(this.stop$)).subscribe(message => {
      this.isLanguageDropdownOpen = false;
      this.togglewileyarchivemenu = false;
      this.toolsdropdownActive = false;
      this.toolsDropdownActive();
    });
    this.sharedService.toolsValue.pipe(takeUntil(this.stop$)).subscribe(message => {
      this.isLanguageDropdownOpen = false;
      this.togglewileyarchivemenu = false;
      this.otherArchiveDropdown = false;
    });

    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.shortcutKeyTitle = 'Shift + Alt';
      this.shiftControl = 'Ctrl + Shift';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.shortcutKeyTitle = 'Shift + Option';
      this.shiftControl = 'Cmd + Shift';
    }
    this.currentLanguage = sessionStorage.getItem('lang');
    if (this.currentLanguage) {
      if (this.currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    }
    if (navigator.onLine) {
      this.connectionStatus = true;
      // this.downloadImageFile();
    }
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.pipe(takeUntil(this.stop$)).subscribe(e => {
      this.connectionStatus = true;
      if (this.connectionIndicator.nativeElement.classList.contains('no-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('no-singal-icon');
      }
      this.connectionIndicator.nativeElement.classList.add('high-singal-icon');
    }));

    this.subscriptions.push(this.offlineEvent.pipe(takeUntil(this.stop$)).subscribe(e => {
      this.connectionStatus = false;
      if (this.connectionIndicator.nativeElement.classList.contains('medium-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('medium-singal-icon');
      }
      if (this.connectionIndicator.nativeElement.classList.contains('high-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('high-singal-icon');
      }
      if (this.connectionIndicator.nativeElement.classList.contains('slow-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('slow-singal-icon');
      }
      this.connectionIndicator.nativeElement.classList.add('no-singal-icon');
    }));
    if (window.location.host === 'app.wileydigitalarchives.com') {
      this.weburl = 'app.wileydigitalarchives.com'
    } else if (window.location.host === 'qa.wileydigitalarchives.com') {
      this.weburl = 'qa.wileydigitalarchives.com'
    } else if (window.location.host === 'dev.wileydigitalarchives.com') {
      this.weburl = 'dev.wileydigitalarchives.com'
    } else {
      this.weburl = 'qa.wileydigitalarchives.com'
    }
  }
  switchLanguage(language: string) {
    // this.modalService.showLoader();
    // this.translate.use(language).pipe(takeUntil(this.stop$)).subscribe(res => {
    //   // this.modalService.hideLoader();
    // });
    this.language = language;
    sessionStorage.setItem('lang', language);
    this.languages.forEach(obj => {
      if (obj.value === language) {
        this.languageName = obj.key;
      }
    });
    if (language === 'ta') {
      $('.byitem-tab').addClass('items-tabset');
      $('.bytype-tab').addClass('types-tabset');
    } else {
      $('.byitem-tab').removeClass('items-tabset');
      $('.bytype-tab').removeClass('types-tabset');
    }
    this.sharedService.shareCurrentLanguage(language);
    this.isLanguageDropdownOpen = false;
  }
  createForm() {
    this.feedback = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      mail: new UntypedFormControl(''),
      topic: new UntypedFormControl('', Validators.required),
      message: new UntypedFormControl('', [Validators.required])
    });

    this.feedback.valueChanges.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (!this.feedback.controls['message'].value.replace(/\s/g, '').length) {
        this.feedback.controls['message'].setErrors({ 'incorrect': true });
      }
    });
  }

  setShortCuts() {
    if (localStorage.getItem('accessType') === 'guest') {
      this.userAgent = window.navigator.userAgent,
        this.platform = window.navigator.platform,
        this.macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        this.windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        this.iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        this.operationSystem = null;
      if (this.macosPlatforms.indexOf(this.platform) !== -1) {
        // sessionStorage.setItem('ShortcutKey', 'Shift + Option');
        // sessionStorage.setItem('ControlShortcutKey', 'Ctrl + Option');
        // localStorage.setItem('ShortcutKey', 'Shift + Option');
        // localStorage.setItem('ControlShortcutKey', 'Ctrl + Option');
      } else if (this.iosPlatforms.indexOf(this.platform) !== -1) {
        this.operationSystem = 'iOS';
      } else if (this.windowsPlatforms.indexOf(this.platform) !== -1) {
        // sessionStorage.setItem('ShortcutKey', 'Shift + Alt');
        // sessionStorage.setItem('ControlShortcutKey', 'Ctrl + Alt');
        // localStorage.setItem('ShortcutKey', 'Shift + Alt');
        // localStorage.setItem('ControlShortcutKey', 'Ctrl + Alt');
      }
    }
  }

  ngAfterContentInit() {
    const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
    if (personalizationV2) {
      this.isBothRCPAccess = personalizationV2.bothRCPAvailable;
      this.isBothRGSAccess = personalizationV2.bothRGSAvailable;
    } else {
      this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] + localStorage.getItem('currentLocation') ||
        PageSettingsService.pageSettings.currentLocation, (persResponse) => {
          if (persResponse) {
            localStorage.setItem('personalizationData', JSON.stringify(persResponse));
            this.isBothRCPAccess = persResponse.bothRCPAvailable;
            this.isBothRGSAccess = persResponse.bothRGSAvailable;
          }
        }, err => {
        });
    }
    if (window.location.href.includes('guestAuthToken')) {
      this.isGuestUserFromShortURL = true;
    } else {
      this.isGuestUserFromShortURL = false;
    }
    const browseMapVisitedCount = sessionStorage.getItem('browseMapVisitedCount');
    if (browseMapVisitedCount) {
      // sessionStorage.removeItem('browseMapVisitedCount');
    }
    this.sharedService.language.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    });
    this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.dropdownValue = res;
      if (this.dropdownValue.tools === true) {
        this.toggleviewlist = true;
      } else {
        this.toggleviewlist = false;
      }
      if (this.dropdownValue.otherArchives === true) {
        this.otherArchiveDropdown = true;
      } else {
        this.otherArchiveDropdown = false;
      }

      if (this.dropdownValue.language === true) {
        this.isLanguageDropdownOpen = true;
      } else {
        this.isLanguageDropdownOpen = false;
      }

      if (this.dropdownValue.togglewileyarchivemenu === true) {
        this.togglewileyarchivemenu = true;
      } else {
        this.togglewileyarchivemenu = false;
      }
    });
    // this.otherArchiveDropdown = this.sharedService.otherArchiveValue;
    this.sharedService.croppedImageListOberable.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.clippedImageList = res;
      if (this.clippedImageList.length > 0) {
        this.toggleArrow = true;
        setTimeout(() => {
          this.toggleArrow = false;
        }, 3500);
      }
    });
    this.clippedImageList = JSON.parse(localStorage.getItem('croppedImageList'));
    this.viewList = JSON.parse(localStorage.getItem('documentList'));
    // this.shortcutKeyTitle = localStorage.getItem('ShortcutKey');
    // this.controlShortcut = localStorage.getItem('ControlShortcutKey');
    this.utilityService.locationConfig.pipe(takeUntil(this.stop$)).subscribe((configVals) => {
      this.versionNumber = configVals.version;
    });
    this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
      setTimeout(() => {
        this.homePageUrl = '/';
        this.homePageUrl += this.currentArchive = (PageSettingsService
          .pageSettings.currentArchive + ''
        ).toLowerCase();
        // this.homePageUrl += '/location/';
        // this.homePageUrl += PageSettingsService.pageSettings.currentLocation;
        if (this.router.url === this.homePageUrl) {
          this.showToolTipContent = this.headerContent._pageTitle;
        } else {
          this.showToolTipContent = this.translatedValues.returnToLandingPage;
        }
        this.currentLocation = PageSettingsService.pageSettings.currentLocation;
      });
      setTimeout(() => {
        PageSettingsService.ViewListObservable.pipe(takeUntil(this.stop$)).subscribe(data => {
          this.viewList = this.selectedViewList = data.viewList;
          this.viewListCount = data.count;
        });
        const viewCont = JSON.parse(localStorage.getItem('contntList'));
        if (viewCont) {
          this.viewContentCount = viewCont.count;
        } else {
          this.viewContentCount = 0;
        }
      });
    });

    this.refreshHistory();
    this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(response => {
      if (response) {
        localStorage.setItem('HeaderLogo', JSON.stringify(response));
        this.showKnownIssues = response.show_known_issues;
      }
      // this.httpService.get('../assets/config/locations-config.json', response => {

    }, err => { }
    );
    this.sharedService.dropdownToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      //   this.isLanguageDropdownOpen = false;
      this.toolsDropDown = res.tools;
      this.otherarchivesDropdown = res.otherArchives;
    });
    this.sharedService.ipadFullScreen.pipe(takeUntil(this.stop$)).subscribe((changes) => {
      if (changes === 'true') {
        this.hideOnIpad = false;
      } else {
        this.hideOnIpad = true;
      }
    });
    let timeOutEnabled;
    this.sharedService.toolsList.pipe(takeUntil(this.stop$)).subscribe((changes) => {
      /*
      * require list from localstorage
      */
      setTimeout(() => {
        this.viewListSelection();
        //  this.viewList = this.selectedViewList = JSON.parse(localStorage.getItem('documentList'));
        //  const viewListArry = [];
        //  this.getJSONKeys(this.selectedViewList).forEach((obj: any) => {
        //    this.getJSONKeys(this.selectedViewList[obj]).forEach(obj1 => {
        //      viewListArry.push(obj1);
        //     });
        //   });
        //   this.viewListCount = viewListArry.length;
      }, 1000);
      if (changes) {
        if (changes === 'true') {
          this.toggleArrow = true;
          if (timeOutEnabled) {
            clearTimeout(timeOutEnabled);
          }
          timeOutEnabled = setTimeout(() => {
            this.toggleArrow = false;
          }, 5000);
        }

      }
    });
    if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
      this.ieBrowserEnabled = true;
    }

    // setTimeout(() => {
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      this.translatedValues = event.translations['Common']['Popups'];
    });
    // }, 1500);


    // if (this.connectionStatus) {
    //   this.downloadImageFile();
    // }

    this.timeInterval = setInterval(() => {
      this.downloadImageFile();
    }, 120000);

  }

  hide(query): void {
    this.reset();
    if (query === 'viewlist') {
      this.isHistoryOpen = false;
      this.isViewListOpen = true;
      this.viewListPop = true;
      this.isFeedbackOpen = false;
      this.isEndSessionOpen = false;
      $('#viewList').addClass('historyActive');
      $('#ViewHistory').removeClass('historyActive');
      $('#Feedback').removeClass('historyActive');
      if (this.history_pop) {
        this.history_pop.hide();
      }
      if (this.feedbackpop) {
        this.feedbackpop.hide();
      }
      this.isHelpOpen = false;
      this.isDownloadContentOpen = false;
      if (this.help_Link) {
        this.help_Link.hide();
      }
      this.clippedImageList = JSON.parse(localStorage.getItem('croppedImageList'));
    } else if (query === 'historyList') {
      $('#viewList').removeClass('historyActive');
      $('#ViewHistory').addClass('historyActive');
      $('#Feedback').removeClass('historyActive');

      this.isHistoryOpen = true;
      this.isViewListOpen = false;
      this.isEndSessionOpen = false;
      if (this.pop) {
        this.pop.hide();
      }
      if (this.feedbackpop) {
        this.feedbackpop.hide();
      }
      this.isFeedbackOpen = false;
      this.isHelpOpen = false;
      this.isDownloadContentOpen = false;
      if (this.help_Link) {
        this.help_Link.hide();
      }
      this.refreshHistory();
    } else if (query === 'feedback') {
      this.isHistoryOpen = false;
      this.isViewListOpen = false;
      this.isFeedbackOpen = true;
      this.isEndSessionOpen = false;
      $('#viewList').removeClass('historyActive');
      $('#ViewHistory').removeClass('historyActive');
      $('#Feedback').addClass('historyActive');

      if (this.pop) {
        this.pop.hide();
      }
      if (this.history_pop) {
        this.history_pop.hide();
      }
      this.isHelpOpen = false;
      this.isDownloadContentOpen = false;
      if (this.help_Link) {
        this.help_Link.hide();
      }
    } else if (query === 'endSession') {
      this.isHistoryOpen = false;
      this.isViewListOpen = false;
      this.isFeedbackOpen = false;
      this.isEndSessionOpen = true;
      if (this.pop) {
        this.pop.hide();
      }
      if (this.history_pop) {
        this.history_pop.hide();
      }
      if (this.feedbackpop) {
        this.feedbackpop.hide();
      }
      $('#viewList').removeClass('historyActive');
      $('#ViewHistory').removeClass('historyActive');
      $('#Feedback').removeClass('historyActive');
      this.isHelpOpen = false;
      this.isDownloadContentOpen = false;
      if (this.help_Link) {
        this.help_Link.hide();
      }
    } else if (query === 'helpLink') {
      this.isHistoryOpen = false;
      this.isViewListOpen = false;
      this.isFeedbackOpen = false;
      this.isEndSessionOpen = false;
      $('#viewList').removeClass('historyActive');
      $('#ViewHistory').removeClass('historyActive');
      $('#Feedback').removeClass('historyActive');
      if (this.pop) {
        this.pop.hide();
      }
      if (this.history_pop) {
        this.history_pop.hide();
      }
      if (this.feedbackpop) {
        this.feedbackpop.hide();
      }
      this.isHelpOpen = true;
      this.isDownloadContentOpen = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.stop$.next();
    this.stop$.complete();

    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }
  // downloadQuickGuide(): void {
  //   window.open(
  //     '',
  //     this.host + '/content/pdfs/WDA_Quickguide_complete.pdf',
  //     '_blank'
  //   );
  // }
  toggleMainHeaderFunction(): void {
    const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
    currentSelectedArchivesSetting.showAdvancedFilter = false;
    this.pageSettingsService.updatePageSettings(currentSelectedArchivesSetting);
    this.toggleMainHeader = !this.toggleMainHeader;
    if (this.toggleMainHeader) {
      this.translate.get('HomePage.hideHeader').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
        this.headerTitleText = res;
      });
    } else {
      this.translate.get('HomePage.expandHeader').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
        this.headerTitleText = res;
      });
    }
    sessionStorage.setItem('wileyShowTopBar', this.toggleMainHeader.toString());
    this.sharedService.setHeader(this.toggleMainHeader.toString());
  }

  onArchiveSelect(index, archive?): void {
    this.sharedService.updateFacetFlag(false);
    const personalizationV2 = JSON.parse(localStorage.getItem('personalizationData'));
    const subcribedArchive = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
    if (archive.shortName !== 'RGS' || archive.shortName !== 'RCP') {
      // if (personalizationV2) {
      //   localStorage.setItem('personalizationData', JSON.stringify(personalizationV2));
      // } else {
      if (!personalizationV2) {
        this.httpService.get(environment.APIUrl + APIURLS['personalizationV2'] + localStorage.getItem('currentLocation') ||
        PageSettingsService.pageSettings.currentLocation, (persResponse) => {
          if (persResponse) {
            localStorage.setItem('personalizationData', JSON.stringify(persResponse));
          }
        }, err => {
        });
      }
      // }
    }
    /*
    * get personalization service and store in localhost
    */
    localStorage.removeItem('commonLimiters');
    localStorage.removeItem('filter');
    if((personalizationV2 && archive !== undefined) && archive.shortName == 'RGS') {
      if (!personalizationV2.bothRGSAvailable) {
          if (subcribedArchive.length >0) {
            for(let k=0;k<subcribedArchive.length;k++) {
              if(subcribedArchive[k] === 'RGS1') {
                archive.shortName = 'rgs1';
              } else if (subcribedArchive[k] === 'RGS2'){
                archive.shortName = 'rgs2';
              }
            }
          }
      }
    } else if((personalizationV2 && archive !== undefined) && archive.shortName == 'RCP') {
      if(!personalizationV2.bothRCPAvailable) { 
        if (subcribedArchive.length >0) {
          for(let k=0;k<subcribedArchive.length;k++) {
            if(subcribedArchive[k] === 'RCP1') {
              archive.shortName = 'rcp1';
            } else if (subcribedArchive[k] === 'RCP2'){
              archive.shortName = 'rcp2';
            }
          }
        }
      }
    }
    if (archive !== undefined) {
      this.router.navigate(
        [archive.shortName.toLowerCase()], { replaceUrl: true });
    } else {
      if (this.headerContent.wileyArchives[index].shortName) {
        this.router.navigate(
          [
            this.headerContent.wileyArchives[index].shortName.toLowerCase()],
          { replaceUrl: true }
        );
      }
    }
    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false,
      revise: false
    });
    setTimeout(() => {
      if (archive.shortName === 'BAAS') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('British Association for the Advancement of Science (Collections on the History of Science: 1830—1970)' + ' - ' + environment.version);
      } else if (archive.shortName === 'RAI') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The Royal Anthropological Institute of Great Britain and Ireland' + ' - ' + environment.version);
      } else if (archive.shortName === 'NYAS') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The New York Academy of Sciences' + ' - ' + environment.version);
      } else if (archive.shortName === 'RGS') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The Royal Geographical Society (with IBG)' + ' - ' + environment.version);
      } else if (archive.shortName === 'RGS1') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The Royal Geographical Society (with IBG) – Part Ⅰ' + ' - ' + environment.version);
      } else if (archive.shortName === 'RGS2') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The Royal Geographical Society (with IBG) – Part Ⅱ' + ' - ' + environment.version);
      } else if (archive.shortName === 'RCP') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The Royal College of Physicians' + ' - ' + environment.version);
      } else if (archive.shortName === 'RCP1') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The Royal College of Physicians – Part Ⅰ' + ' - ' + environment.version);
      } else if (archive.shortName === 'RCP2') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The Royal College of Physicians– Part Ⅱ' + ' - ' + environment.version);
      } else if (archive.shortName === 'RAITEST') {
        // tslint:disable-next-line:max-line-length
        this.titleService.setTitle('The Royal Anthropological Institute of Great Britain and Ireland - Test' + ' - ' + environment.version);
      } else {
        // this.titleService.setTitle((this.headerContent._pageTitle ?  this.headerContent._pageTitle : 'Wiley Digital Archives') + ' - ' + environment.version);
        this.titleService.setTitle('Wiley Digital Archives' + ' - ' + environment.version);
      }
    }, 500);

  }
  @HostListener('document:keyup', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.ctrlKey && e.altKey) {
      switch (e.code) {
        case "Slash":
          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            $('#Help').trigger('click');
          } else {
            $('#toolsMenuActive').addClass('toolsActive');
            this.toolsToggle();
            $('#Help').trigger('click');
          }
          break;
        case "KeyC":
          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            $('#Feedback').trigger('click');
          } else {
            $('#toolsMenuActive').addClass('toolsActive');
            this.toolsToggle();
            $('#Feedback').trigger('click');
          }
          setTimeout(() => {
            const authType = localStorage.getItem('accessType');
            let maxLengthforListContainer = 6;
            if (authType === 'guest') {
              maxLengthforListContainer = 2;
            }
            if ($('.viewlist-listcontainerinner').children(':visible').length < maxLengthforListContainer) {
              $('#Feedback').trigger('click');
            }
          }, 500);
          break;
        case "KeyH":
          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            $('#ViewHistory').trigger('click');
          } else {
            $('#toolsMenuActive').addClass('toolsActive');
            this.toolsToggle();
            $('#ViewHistory').trigger('click');
          }
          break;
        case "KeyW":
          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            $('#viewList').trigger('click');
          } else {
            $('#toolsMenuActive').addClass('toolsActive');
            this.toolsToggle();
            $('#viewList').trigger('click');
          }
          break;
        case "KeyB":
          this.toolsToggle();
          break;
        case "KeyR":
          this.otherarchivesToggle();
          break;
        case "KeyU":
          this.gotoAboutPage();
          break;
        case "BracketLeft":
          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            this.toolsToggle();
          }
          this.goToQuickGuide();
          break;
        case "BracketRight":
          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            this.toolsToggle();
          }
          this.goToAccessbilityGuide();
          break;
        case "Digit1":
          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            if ($('#viewList').hasClass('historyActive')) {
              setTimeout(() => {
                this.docDetailsTab.tabs[0].active = true;
              }, 10);
            } else {
              $('#viewList').trigger('click');
              $('#viewList').addClass('historyActive');
              setTimeout(() => {
                this.docDetailsTab.tabs[0].active = true;
              }, 10);
            }
          } else {
            $('#toolsMenuActive').addClass('toolsActive');
            this.toolsToggle();
            $('#viewList').trigger('click');
            $('#viewList').addClass('historyActive');
            setTimeout(() => {
              this.docDetailsTab.tabs[0].active = true;
            }, 10);
          }
          // if ($('#ViewHistory').parent().hasClass('historyActive')) {
          //   $('#ViewHistory').parent().removeClass('historyActive');
          //   this.toolsToggle();
          //   $('#toolsMenuActive').addClass('toolsActive');
          //   $('#viewList').trigger('click');
          //   $('#viewList').parent().addClass('historyActive');
          //   setTimeout(() => {
          //     this.docDetailsTab.tabs[0].active = true;
          //   }, 10);
          // } else {
          //   $('#toolsMenuActive').addClass('toolsActive');
          //   $('#viewList').parent().addClass('historyActive');
          //   if ($('#viewlist-content').hasClass('showList')) {
          //     $('#viewList').parent().addClass('historyActive');
          //     this.docDetailsTab.tabs[0].active = true;
          //   } else {
          //     this.toolsToggle();
          //     this.toolsdropdownActive = true;
          //     $('#viewList').trigger('click');
          //     $('#viewList').parent().addClass('historyActive');
          //     setTimeout(() => {
          //       this.docDetailsTab.tabs[0].active = true;
          //     }, 10);
          //   }
          // }
          break;
        case "Digit2":

          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            if ($('#viewList').hasClass('historyActive')) {
              setTimeout(() => {
                this.docDetailsTab.tabs[1].active = true;
              }, 10);
            } else {
              $('#viewList').trigger('click');
              $('#viewList').addClass('historyActive');
              setTimeout(() => {
                this.docDetailsTab.tabs[1].active = true;
              }, 10);
            }
          } else {
            $('#toolsMenuActive').addClass('toolsActive');
            this.toolsToggle();
            $('#viewList').trigger('click');
            $('#viewList').addClass('historyActive');
            setTimeout(() => {
              this.docDetailsTab.tabs[1].active = true;
            }, 10);
          }

          // if ($('#ViewHistory').parent().hasClass('historyActive')) {
          //   $('#ViewHistory').parent().removeClass('historyActive');
          //   this.toolsToggle();
          //   $('#toolsMenuActive').addClass('toolsActive');
          //   $('#viewList').trigger('click');
          //   $('#viewList').parent().addClass('historyActive');
          //   setTimeout(() => {
          //     this.docDetailsTab.tabs[1].active = true;
          //   }, 10);
          // } else {
          //   $('#toolsMenuActive').addClass('toolsActive');
          //   $('#viewList').parent().addClass('historyActive');
          //   if ($('#viewlist-content').hasClass('showList')) {
          //     this.docDetailsTab.tabs[1].active = true;
          //     $('#viewList').parent().addClass('historyActive');
          //   } else {
          //     this.toolsToggle();
          //     this.toolsdropdownActive = true;
          //     $('#viewList').trigger('click');
          //     $('#viewList').parent().addClass('historyActive');
          //     setTimeout(() => {
          //       this.docDetailsTab.tabs[1].active = true;
          //     }, 10);

          //   }
          // }
          break;
        case "Digit3":

          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            if ($('#ViewHistory').hasClass('historyActive')) {
              setTimeout(() => {
                this.historyDetailsTab.tabs[0].active = true;
              }, 10);
            } else {
              $('#ViewHistory').trigger('click');
              $('#ViewHistory').addClass('historyActive');
              setTimeout(() => {
                this.historyDetailsTab.tabs[0].active = true;
              }, 10);
            }
          } else {
            $('#toolsMenuActive').addClass('toolsActive');
            this.toolsToggle();
            $('#ViewHistory').trigger('click');
            $('#ViewHistory').addClass('historyActive');
            setTimeout(() => {
              this.historyDetailsTab.tabs[0].active = true;
            }, 10);
          }

          // if ($('#viewList').parent().hasClass('historyActive')) {
          //   $('#viewList').parent().removeClass('historyActive');
          //   this.toolsToggle();
          //   $('#toolsMenuActive').addClass('toolsActive');
          //   $('#ViewHistory').trigger('click');
          //   $('#ViewHistory').parent().addClass('historyActive');
          //   setTimeout(() => {
          //     this.historyDetailsTab.tabs[0].active = true;
          //   }, 10);
          // } else {

          //   $('#toolsMenuActive').addClass('toolsActive');
          //   $('#ViewHistory').parent().addClass('historyActive');
          //   if ($('#viewlist-content').hasClass('showList')) {
          //     this.historyDetailsTab.tabs[0].active = true;
          //     $('#ViewHistory').parent().addClass('historyActive');
          //   } else {
          //     this.toolsToggle();
          //     this.toolsdropdownActive = true;
          //     $('#ViewHistory').trigger('click');
          //     $('#ViewHistory').parent().addClass('historyActive');
          //     setTimeout(() => {
          //       this.historyDetailsTab.tabs[0].active = true;
          //     }, 10);
          //   }
          // }
          break;
        case "Digit4":

          if ($('#toolsMenuActive').hasClass('toolsActive')) {
            if ($('#ViewHistory').hasClass('historyActive')) {
              setTimeout(() => {
                this.historyDetailsTab.tabs[1].active = true;
              }, 10);
            } else {
              $('#ViewHistory').trigger('click');
              $('#ViewHistory').addClass('historyActive');
              setTimeout(() => {
                this.historyDetailsTab.tabs[1].active = true;
              }, 10);
            }
          } else {
            $('#toolsMenuActive').addClass('toolsActive');
            this.toolsToggle();
            $('#ViewHistory').trigger('click');
            $('#ViewHistory').addClass('historyActive');
            setTimeout(() => {
              this.historyDetailsTab.tabs[1].active = true;
            }, 10);
          }

          // if ($('#viewList').parent().hasClass('historyActive')) {
          //   $('#viewList').parent().removeClass('historyActive');
          //   this.toolsToggle();
          //   $('#toolsMenuActive').addClass('toolsActive');
          //   $('#ViewHistory').trigger('click');
          //   $('#ViewHistory').parent().addClass('historyActive');
          //   setTimeout(() => {
          //     this.historyDetailsTab.tabs[1].active = true;
          //   }, 10);

          // } else {

          //   $('#toolsMenuActive').addClass('toolsActive');
          //   $('#ViewHistory').parent().addClass('historyActive');
          //   if ($('#viewlist-content').hasClass('showList')) {
          //     this.historyDetailsTab.tabs[1].active = true;
          //     $('#ViewHistory').parent().addClass('historyActive');
          //   } else {
          //     this.toolsToggle();
          //     this.toolsdropdownActive = true;
          //     $('#ViewHistory').trigger('click');
          //     $('#ViewHistory').parent().addClass('historyActive');
          //     setTimeout(() => {
          //       this.historyDetailsTab.tabs[1].active = true;
          //     }, 10);
          //   }
          // }
          break;
      }
    } else if (e.ctrlKey && e.shiftKey) {
      switch (e.code) {
        case "KeyL":
          if ($('.modal').hasClass('in')) {
          } else {
            this.endSessionConfirmation('');
          }
          break;
        case "KeyZ":
          this.languageDropdown();
          break;
      }
    } else if (e.shiftKey && e.altKey) {
      switch (e.code) {
        case "Home":
          this.gotoHomePage();
          break;
      }
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if ($('.modal-backdrop')) {
      if ($('.modal-backdrop').hasClass('in')) {
        if ($('#viewListPopup').is(':visible')) {
          $('#toolsMenuActive #viewlist-content').show();
          $('.viewlist-listcontainerinner #viewList').trigger('click');
          $('#viewList').addClass('list-active');
        }
      } else {
        $('#toolsMenuActive #viewlist-content').hide();
        this.togglewileyarchivemenu = false;
        this.toggleotherearchivemenu = false;
        this.sharedService.dropdownToggleOption({
          tools: false,
          otherArchives: false,
          archiveSelected: false,
          advanceSearch: false,
          help: false,
          language: false,
          shareDocument: false,
          revise: false
        });
        this.toggleheader = false;
        this.togglePopup = false;
        this.isHistoryOpen = false;
        this.isViewListOpen = false;
        this.isFeedbackOpen = false;
        this.isHelpOpen = false;
        this.isDownloadContentOpen = false;
        this.isEndSessionOpen = false;
        this.toolsCount = 0;
        // this.otherarchiveCount = 0;
        $('#toolsMenuActive').removeClass('toolsActive');
        $('#viewlist-content').removeClass('showList');
        $('#tollArrowToggle').removeClass('fa-chevron-up');
        $('#tollArrowToggle').addClass('fa-chevron-down');
        $('#otherarchivesMenuActive').removeClass('toolsActive');
        $('#wiley-menu-archive-content').removeClass('showList');
        $('#otherArchiveArrowToggle').removeClass('fa-chevron-up');
        $('#otherArchiveArrowToggle').addClass('fa-chevron-down');
        $('.bs-popover-left').hide();
        //  $('#viewList').trigger('click');
        this.toolsdropdownActive = false;
        // this.otherArchiveDropdown = false;
        // this.sharedService.changeotherArchivesValue('false');
        this.sharedService.dropdownToggleOption({
          tools: false,
          otherArchives: false,
          archiveSelected: false,
          advanceSearch: false,
          help: false,
          language: false,
          shareDocument: false,
          revise: false
        });
      }
      if (this.viewListPop) {
        $('#viewList').trigger('click');
        this.viewListPop = false;
      }
    } else {
      this.togglewileyarchivemenu = false;
      this.toggleotherearchivemenu = false;
      // this.toggleviewlist = false;
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false
      });
      this.toggleheader = false;
      this.togglePopup = false;
      this.isHistoryOpen = false;
      this.isViewListOpen = false;
      this.isFeedbackOpen = false;
      this.isHelpOpen = false;
      this.isDownloadContentOpen = false;
      this.isEndSessionOpen = false;
      this.toolsCount = 0;
      // this.otherarchiveCount = 0;
      $('#toolsMenuActive').removeClass('toolsActive');
      $('#viewlist-content').removeClass('showList');
      $('#tollArrowToggle').removeClass('fa-chevron-up');
      $('#tollArrowToggle').addClass('fa-chevron-down');
      $('#otherarchivesMenuActive').removeClass('toolsActive');
      $('#wiley-menu-archive-content').removeClass('showList');
      $('#otherArchiveArrowToggle').removeClass('fa-chevron-up');
      $('#otherArchiveArrowToggle').addClass('fa-chevron-down');
      $('.bs-popover-left').hide();
      this.toolsdropdownActive = false;
      this.otherArchiveDropdown = false;
    }
    if (this.viewListPop) {
      $('#viewList').trigger('click');
      this.viewListPop = false;
    }
    $('#Feedback').removeClass('historyActive');
  }

  getJSONKeys(obj): Array<String> {
    if (typeof obj === 'object') {
      if (obj) {
        return Object.keys(obj);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  goToDocumentDetailPage(docID, index): void {
    // const checkCurrentselectedArchives = JSON.parse(sessionStorage.getItem('wileySelectedArchive'));
    const checkCurrentselectedArchives = JSON.parse(localStorage.getItem('wileySelectedArchive'));
    const currentArchivesCount = Object.keys(checkCurrentselectedArchives).length;
    const data = {};
    if (currentArchivesCount > 0) {
      this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, (param) => {
        this.searchService.buildURL(param, (url) => {
          data['filterParam'] = url;
          data['start'] = index;
          data['isFromResultsPage'] = true;
          data['invokedFrom'] = '';
          if (PageSettingsService.pageSettings.searchTerm) {
            data['searchTerm'] = PageSettingsService.pageSettings.searchTerm ? PageSettingsService.pageSettings.searchTerm.toString() : '';
          }
          this.router.navigate([this.homePageUrl + '/detail/' + docID, data]);
        });
      });
    } else {
      // tslint:disable-next-line:max-line-length
      this.modalService.showNotification('WARNING', this.translatedValues.pleaseSelectOneArchive || 'Please select at least one archive to proceed', '');
    }
  }
  getSessionStorageItem(key: string): void {
    const keyName = key || '';
    return JSON.parse(window.sessionStorage.getItem(keyName));
  }

  renderDocumentListAsDOM(callBack): void {
    // this.modalService.showLoader();
    let htmlContent = `<html>
		 <body style="width:100%;display:inline-block;background: #000;">`;

    const docImages = [];
    Object.values(this.viewList).forEach((contentType, contentIndex) => {
      Object.values(contentType).forEach((document, docIndex) => {
        let thumbImage = '';
        if (
          this.headerContent.hasFolderImage &&
          Object.keys(this.viewList)[contentIndex].toUpperCase() ===
          'MANUSCRIPTS'
        ) {
          thumbImage = document.thumbnails[1];
        } else {
          thumbImage = document.thumbnails[0];
        }
        docImages.push({
          imageSrc: thumbImage,
          contentType: Object.keys(this.viewList)[contentIndex],
          docTitle: document.docTitle,
          citation: document.citation
        });
      });
    });

    const imageObservable = new Observable(observer => {
      let currentImageIndex = 0;
      const headerComponent = this;
      (function getUrl(imageIndex) {
        headerComponent.toDataURL(docImages[imageIndex].imageSrc, imgSrc => {
          docImages[imageIndex]['imageSrc'] = imgSrc;
          observer.next(docImages[imageIndex]);
          if (imageIndex === docImages.length - 1) {
            observer.complete();
          } else {
            imageIndex += 1;
            currentImageIndex = imageIndex;
            getUrl(imageIndex);
          }
        });
      })(currentImageIndex);
    });

    imageObservable.pipe(takeUntil(this.stop$)).subscribe(
      data => {
        htmlContent += `
					<div style="width: 100%;border: 1px solid #000;display: inline-block;">
					<div style="width: 100%;display: inline-block;">
					<h4 style="font-size: 14px;font-weight: 700;margin: 10px 0;">${data[
          'contentType'
          ]}</h4>
					</div>`;
        htmlContent += `<div style="width: 100%;padding: 0;border-bottom: 1px solid #707070;margin-bottom: 10px;display: inline-block;">`;
        htmlContent += `
					<div style="float: left;background: #000;padding: 4px;width: 200px;margin-bottom: 5px;min-height: 80px;">
					<img style="width: 30%;height: auto; margin-right: 20px" src="${data['imageSrc']}" alt="Display doc info"/>
					</div>`;
        htmlContent += `
					<div style="float: left;">
					<a style="font-size: 18px !important;marin-bottom:20px;color: #1883de;cursor: pointer;">${data['docTitle']}</a><br/>
					<span style="font-size: 14px;font-weight: 500;">${data['citation']}</span>
					</div>
					</div>
					</div><br>`;
      },
      () => {
        callBack(null);
        // this.modalService.hideLoader();
      },
      () => {
        htmlContent += `</body></html>`;
        // this.modalService.hideLoader();
        callBack(htmlContent, 'WileySearchResults');
      }
    );
  }

  exportPDF(): void {
    /*
    * Style Your content here
    */
    const htmlCode = `<html><head><title>Your List - Wiley Digital Archives</title><style>
		.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,
		.col-lg-8,.col-lg-9,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,
		.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,
		.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,
		.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9{
			position:relative;min-height:1px;padding-right:15px;padding-left:15px}
		.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,
		.col-md-8,.col-md-9{float:left}.col-md-12{width:100%}.col-md-11{width:91.66666667%}.col-md-10{width:83.33333333%}
		.col-md-9{width:75%}.col-md-8{width:66.66666667%}.col-md-7{width:58.33333333%}.col-md-6{width:50%}.col-md-5{width:41.66666667%}
		.col-md-4{width:33.33333333%}.col-md-3{width:25%}.col-md-2{width:16.66666667%}.col-md-1{width:8.33333333%}
		.img-responsive,img {display: block;max-width: 100%;max-height: 100%;margin: 0 auto;}img{vertical-align:middle;}img{border:0;}

		.export-btn,.viewlist-contenticon{float:right}
		.content_type_title{border-top: 3px solid #009ca9;}
		.viewlist-content-div{padding:0 0 0 15px;margin: 0 0 10px 0;}
    .viewlist-container-thumbnail{background: #414246;padding: 4px;margin-bottom: 5px;height: 80px !important;width: 100px;}
		.viewlist-container-content{padding:0;border-bottom:1px solid #009ca9;margin-bottom:5px;display: inline-block;width: 100%;}
		.viewlist-contenticon i{font-size:20px;margin-top:10px}.title-heading{font-weight:600;color:#615c5c}
		.viewlist-containertitle h4{font-size:14px;font-weight:700;margin:0 0 5px}
		.viewlist-container-parent{padding:0 0 15px;max-height:auto;overflow-x:hidden;overflow-y:auto;margin-top:1px}h4
		.viewlist-title{font-size:18px!important}
		.viewlist-container-parent::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);background-color:#F5F5F5}
		.viewlist-container-parent::-webkit-scrollbar{width:6px;background-color:#F5F5F5}
		.viewlist-container-parent::-webkit-scrollbar-thumb{background-color:#61165e;border-radius:25px}
		.viewlist-containertitle{width:100%;display:inline-block}
		.viewlist-containertitle-header{padding-bottom:5px}
		.viewlist-containertitle-header h4{margin-top:10px}
		#viewlist-content{width:250px;top:59px;right:-16px}
		.viewlist-listcontainerinner{padding:3px 0}
		.viewlist-listcontainerinner li{border:none!important}
		.viewlist-container-footer{padding-top:7px;border-top:2px solid #ccc}.result-tag li{float:left;margin-right:0;padding-top:0}
		.viewlist-containertitle
		.viewlist-title{float:left;width:65%}
		.viewlist-container-content:last-child{border:0;margin-bottom: 30px; }.content_type_title h4{margin:5px 0 15px}
		.print-hide{display:none;}.thumbnail-image-link-hide{align-items: center;}body{background-color: #fff;}
		.search-result-header-title a{text-decoration: none;cursor: pointer;color: #61165E !important;}
    .search-result-header-title a:hover{text-decoration: underline;}
    .search-result-citation {    width: 100%;}
    .search-result-header-title{    width: 100%;    display: inline-block;}
    .search-result-parent-div {    width: 100%;    display: inline-block;}
    @media print {.print-hide{display:none;}}a.search-result-header-title{cursor: pointer;font-size: 20px;color: #61165E !important;}
		</style></head><body >`;


    const monthNames: Array<String> = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const d = new Date();
    const htmlContent = [htmlCode + $('#tempID').html() + '</body></html>'];
    const blob = new Blob(htmlContent, { type: 'text/html' });
    const fileName = `ViewList-Export-${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}.html`;
    // if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
    //   window.navigator.msSaveBlob(blob, fileName);
    // } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    //   let htmlContents = '';
    //   const win = window.open('', '_blank');
    //   $(win.document).val('');
    //   htmlContents = htmlCode + $('#tempID').html() + '</body></html>';
    //   win.document.write(htmlContents);
    //   win.document.close();
    // } else {
    //   const doc = document.createElement('a');
    //   doc.href = URL.createObjectURL(blob);
    //   doc.download = fileName;
    //   doc.hidden = true;
    //   document.body.appendChild(doc);
    //   doc.click();
    // }

    let base64data;
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    // this.modalService.showLoader();
    reader.onloadend = () => {
      base64data = reader.result;
      if (base64data) {
        let params = {
          'base64Data': base64data,
          'fileName': this.currentLocation
        }
        this.httpClient.post<any>(environment.APIUrl + APIURLS.exportClipImages, params).pipe(takeUntil(this.stop$)).subscribe(res => {
          if (res) {
            this.currentExportURL = '';
            // this.modalService.hideLoader();
            this.currentExportURL = res.url;
            this.exportClipTemplatemodalRef = this.bsModalService.show(this.exportClipTemplate,
              { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large exportClipTemplate' });
          }
        }, (err) => {
          // this.modalService.hideLoader();
        });
      }
    }
  }

  toDataURL(url, callback, outputFormat?): void {
    const nuroImage = new Image();
    const request = new XMLHttpRequest();
    request.responseType = 'blob';
    request.onload = function () {
      nuroImage.src = URL.createObjectURL(request.response);
      callback(URL.createObjectURL(request.response));
    };
    request.open('GET', url);
    request.send();
  }
  runQuery(viewDoc) {
    localStorage.removeItem('fromAdditionalArchive');
    if (viewDoc && viewDoc.filterParams) {
      this.runHistory(viewDoc);
    } else if (viewDoc && viewDoc.url) {
      if (viewDoc.searchParams) {
       const splitSearch =  viewDoc.url.split(';')
       for (let k=0; k<splitSearch.length;k++) {
        if (splitSearch[k].includes('searchTerm')) {
          splitSearch[k] = splitSearch[k] + viewDoc.searchParams;
        }
       }
       viewDoc.url = splitSearch.join(';');
        this.router.navigateByUrl(viewDoc.url);
      } else {
        this.router.navigateByUrl(viewDoc.url);
      }
      let url = viewDoc.url.split(';');
      let getselectedArchives = url[url.length - 1];
      let splitArchives = getselectedArchives.split('=');
      let getArchives = splitArchives[splitArchives.length - 1].split(',');
      // sessionStorage.setItem('wileySelectedArchive', JSON.stringify(getArchives));
      localStorage.setItem('wileySelectedArchive', JSON.stringify(getArchives));
    }
  }
  runHistory(urlObj): void {
    this.currentHistory = urlObj;
    this.viewHistoryUrl = this.currentHistory.url;
    this._searchTerm = this.currentHistory.searchParams;
    if (this.currentHistory['filterParams']) {
      this.filterParams = this.currentHistory.filterParams;
      this._archive = this.filterParams.archive;
    }
    if (this.currentHistory['filters']) {
      this.savedFilters = this.currentHistory.filters;
    }
    if (urlObj.fromTextAnalysis) {
      this.router.navigateByUrl(this.viewHistoryUrl);
    }
    if (this._searchTerm && !urlObj.explorerType && !urlObj.fromTextAnalysis && !urlObj.fromCollocates) {
      let url = this.viewHistoryUrl.split('/')[1];
      const checkUrlArry = url.split('/');
      // if (checkUrlArry.length === 4) {
      url += '/search';
      // }
      if (this._searchTerm && this.filterParams) {
        this.router.navigateByUrl(this.viewHistoryUrl);
      }
      else {
        this.searchService.goToSearchFromHistoryPage(this._searchTerm, url);
      }
      // setTimeout(() => {
      //   const urlForHistory = window.location.pathname;
      //   const objDate = Date.now();
      //   const historyItem = {
      //     url: urlForHistory,
      //     filterParams: this.filterParams ? this.filterParams : [],
      //     searchParams: this._searchTerm || '',
      //     date: objDate,
      //   };
      //   const previousHistoryObject = JSON.parse(localStorage.getItem('HistoryList'));
      //   if (previousHistoryObject.length > 0) {
      //     previousHistoryObject.push(historyItem);
      //     localStorage.setItem('HistoryList', JSON.stringify(previousHistoryObject));

      //   } 
      //   else {
      //     const historyObject = [historyItem];
      //     localStorage.setItem('HistoryList', JSON.stringify(historyObject));
      //   }
      // });

      // tslint:disable-next-line:max-line-length
    } else if ((this._searchTerm === '' || this._searchTerm === undefined) && !this.filterParams && !urlObj.explorerType && !urlObj.fromCollocates) {
      let url = this.viewHistoryUrl.split(';')[0];
      const checkUrlArry = url.split('/');
      if (checkUrlArry.length === 4) {
        url += '/search';
      }
      this.searchService.goToSearchFromHistoryPage(this._searchTerm, this.viewHistoryUrl);
    } else {
      const objDate = Date.now();
      const historyArry = JSON.parse(localStorage.getItem('HistoryList'));
      if (this._archive) {
        // sessionStorage.setItem('wileySelectedArchive', JSON.stringify(this._archive));
        localStorage.setItem('wileySelectedArchive', JSON.stringify(this._archive));
      }
      if (this.savedFilters) {
        sessionStorage.setItem('filter', JSON.stringify(this.savedFilters));
        localStorage.setItem('filter', JSON.stringify(this.savedFilters));
      }
      if (historyArry.length > 0 && !urlObj.explorerType) {
        urlObj.date = objDate;
        historyArry.push(urlObj);
        localStorage.setItem('HistoryList', JSON.stringify(historyArry));
      }

      /*
        *DESC: Getting search term for photos/maps explorer history list
       */
      if (urlObj.explorerType) {
        const filterParams = urlObj['filterParams'];
        urlObj.date = objDate;
        historyArry.push(urlObj);
        localStorage.setItem('HistoryList', JSON.stringify(historyArry));
        sessionStorage.setItem('explorerLimiter', JSON.stringify(new AdvancedFilterParams(filterParams)));
        this.viewHistoryUrl = this.viewHistoryUrl + ';searchTerm=' + this._searchTerm;
      }
      if (urlObj.fromCollocates) {
        const filterParams = urlObj['filterParams'];
        this.viewHistoryUrl = this.viewHistoryUrl + ';searchTerm=' + this._searchTerm;
      }
      this.router.navigateByUrl(this.viewHistoryUrl);
    }
    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false,
      revise: false
    });
  }
  clearOpenModal(event): void {
    this.clearHistorymodalRef = this.bsModalService.show(this.clearHistoryTemplate, { backdrop: true, ignoreBackdropClick: true });
    if ($('.modal-backdrop')) {
      if ($('.modal-backdrop').hasClass('in')) {
        event.preventDefault();
      }
    }
    event.preventDefault();
  }

  clearCloseModal(): void {
    this.clearHistorymodalRef.hide();
  }

  clearHistory(): void {
    delete this.viewHistoryList;
    delete this.documentHistory;
    this.searchService.deleteHistory();
    this.searchService.deleteDocHistory();
    // tslint:disable-next-line:max-line-length
    this.modalService.showNotification('SUCCESS', this.translatedValues.clearHistory || 'Clear History', this.translatedValues.clearHistoryMessage || 'History cleared Successfully');
    this.hideTools();
    this.clearHistorymodalRef.hide();
  }

  historyActive(): void {
    $('.search-resultli').addClass('active');
  }
  historypop(): void {
    this.toggleviewhistory = !this.toggleviewhistory;
  }
  selectViewList(): void {
    if (this.selectedContentType === 'All') {
      this.selectedViewList = this.viewList;
    } else {
      const map = new Object();
      map[this.selectedContentType] = this.viewList[this.selectedContentType];
      this.selectedViewList = map;
    }
  }
  sort(property: any) {
    this.isDesc = !this.isDesc;
    this.column = property;
    const direction = this.isDesc ? -1 : 1;
    if (this.viewHistoryList) {
      this.viewHistoryList.sort((a, b) => {
        if (a[property] < b[property]) {
          return -1 * direction;
        } else if (a[property] > b[property]) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
    if (this.documentHistory) {
      this.documentHistory.sort((a, b) => {
        if (a[property] < b[property]) {
          return -1 * direction;
        } else if (a[property] > b[property]) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }

  navigateToCollection(): void {
    if (window.location.pathname.includes('unauthorized')) {
    } else {
      if (this.currentArchive === 'rgs' || this.currentArchive === 'rcp') {
        if (this.currentArchive === 'rgs' && this.isBothRGSAccess) {
          this.router.navigate([this.homePageUrl + '/collections'], {
            queryParams: { archive: 'RGS' }
          });
        } else if (this.currentArchive === 'rcp' && this.isBothRCPAccess) {
          this.router.navigate([this.homePageUrl + '/collections'], {
            queryParams: { archive: 'RCP' }
          });
        } else {
          this.router.navigate([this.homePageUrl + '/collections'], {
            queryParams: { archive: PageSettingsService.pageSettings.currentSelectedArchives[0].toLowerCase() }
          });
        }
      } else {
        this.router.navigate([this.homePageUrl + '/collections'], {
          queryParams: { archive: this.currentArchive }
        });
      }
    }
  }
  deleteViewlistConfirmation(event, item1, contentType): void {
    this.deleteableItem = item1;
    this.deleteableContentType = contentType;
    this.viewListmodalRef = this.bsModalService.show(this.viewListTemplate, { backdrop: true, ignoreBackdropClick: true });
    event.preventDefault();
  }
  deleteItem(): void {
    this.pageSettingsService.removeFromViewList(this.deleteableItem, this.deleteableContentType);
    this.viewListmodalRef.hide();
    if (this.getJSONKeys(this.viewList).length === 0) {
      this.hideTools();
    }
    this.selectedContentType = 'All';
  }
  viewlistCloseModal(): void {
    this.viewListmodalRef.hide();
    this.selectedContentType = 'All';
  }
  endSessionConfirmation(event): void {
    this.sharedService.popupChanges('true');
    this.endSessionmodalRef = this.bsModalService.show(this.endSessionTemplate, { backdrop: true, ignoreBackdropClick: true });
    document.getElementById('endSessionId').focus();
    event.preventDefault();
  }
  endSession(): void {
    this.httpClient.post<any>(environment.APIUrl + APIURLS.logout, null).pipe(takeUntil(this.stop$)).subscribe(res => { });
    this.currentArchive = localStorage.getItem('currentArchive');
    const authType = localStorage.getItem('accessType');
    // const currentCaptchaType = localStorage.getItem('isGoogleCaptchaAvailable');
    // tslint:disable-next-line:max-line-length
    this.modalService.showNotification('SUCCESS', this.translatedValues.endSession || 'End Session', this.translatedValues.endSessionNotification || 'This session is ended.');
    localStorage.clear();
    sessionStorage.clear();
    this.endSessionmodalRef.hide();
    // this.hideTools();
    this.hideToolsForLogout();
    this.sharedService.popupChanges('false');
    if (authType === 'IP') {
      setTimeout(() => {
        this.router.navigate([`${this.currentArchive || environment.defaultArchive}/auth/ip`, { source: 'logout' }]);
      });
    } else if (authType === 'guest') {
      this.router.navigate([`${this.currentArchive || environment.defaultArchive}/auth/pass`]);
    } else {
      this.router.navigate([`${this.currentArchive || environment.defaultArchive}/auth/pass`]);
    }
    // if (currentCaptchaType) {
    //   localStorage.setItem('isGoogleCaptchaAvailable', currentCaptchaType);
    // }
    // }, err => {
    //     this.modalService.showNotification('ERROR', this.translatedValues.endSession || 'End Session',
    //     this.translatedValues.endSessionNotificationFaild || 'Unable to end session, Please try again.');
    // });
    // setTimeout(() => {
    //   if ($('#loginBtn').is(':visible')) {
    //     $('.grecaptcha-badge').show();
    //   } else {
    //     $('.grecaptcha-badge').hide();
    //     $('.grecaptcha-badge').addClass('hidden');
    //   }
    // }, 1500);
  }
  sessionCloseModal(): void {
    this.sharedService.popupChanges('false');
    this.endSessionmodalRef.hide();
  }
  sendMail(): void {
    this.sendMailData = this.feedback.value;
    this.subject = 'WDA Feedback - ' + this.sendMailData.topic;
    this.mail = this.sendMailData.mail
      ? this.sendMailData.mail
      : 'Email Not Provided';
    this.content = this.sendMailData.message;
    this.name = this.sendMailData.name ? this.sendMailData.name : 'Anonymous';
    this.location = PageSettingsService.pageSettings.currentLocation;
    this.body =
      '<div style="text-align: left"><span style="color: #000">' +
      this.name +
      '(' +
      this.mail +
      ')' +
      ' left a feedback message. </span>  <br><span style="color: #000">Topic: ' +
      this.sendMailData.topic +
      '</span><br><span style="color: #000">Message: ' +
      this.content +
      '</span><br><br><br><br><span style="color: #000">Customer: ' +
      this.headerContent.currentInstitutionName +
      '</span><br><span style="color: #000">Location: ' +
      this.location +
      '</span></div>';
    this.utilityService
      .sendToMailClient(this.subject, this.body, environment.email)
      .then(
        res => {
          // tslint:disable-next-line:max-line-length
          this.modalService.showNotification('SUCCESS', this.translatedValues.receivedFeedbackMessage || 'We have received your feedback. Thank you!', '');
          this.feedbackpop.hide();
        },
        err => {
          this.modalService.showNotification('SUCCESS', this.translatedValues.pendingFeedback || 'Pending Feedback', '');
          this.feedbackpop.hide();
        }
      );
    this.reset();
    this.isFeedbackOpen = false;
    this.isHelpOpen = false;
    this.isDownloadContentOpen = false;
    this.hideTools();
    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false,
      revise: false,
      togglewileyarchivemenu: false
    });
  }
  onSubmit(value: any): void {
    this.reset();
  }
  reset(): void {
    this.createForm();
  }
  toolsToggle(): void {
    const getDownCOntentArray = JSON.parse(localStorage.getItem('personalizationData'));
    if (getDownCOntentArray.downloadContentArchives.length === 0) {
      this.showDCIcon = false;
    } else {
      this.showDCIcon = true;
    }
    if (this.router.url.includes('downloadcontent') || this.router.url.includes('downloadcontenttable')) {
      this.isDownloadContentOpen = true;
      this.isHelpOpen = false;
    } else {
      this.isDownloadContentOpen = false;
    }
    this.viewList = JSON.parse(localStorage.getItem('documentList'));
    this.refreshHistory();
    // this.otherarchiveCount = 0;
    if (this.viewListPop) {
      this.viewListPop = false;
      $('#viewList').trigger('click');
    }
    if (!this.toggleviewlist) {
      this.sharedService.dropdownToggleOption({
        tools: true,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false
      });

    } else {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false
      });
    }


    if (this.toolsCount === 0) {
      this.toolsCount = 1;
      this.viewList = this.selectedViewList = JSON.parse(localStorage.getItem('documentList'));
      const viewListArry = [];
      this.getJSONKeys(this.selectedViewList).forEach((obj: any) => {
        this.getJSONKeys(this.selectedViewList[obj]).forEach(obj1 => {
          viewListArry.push(obj1);
        });
      });
      this.viewListCount = viewListArry.length;
    } else {
      this.toolsCount = 0;
    }

    const viewCont = JSON.parse(localStorage.getItem('contntList'));
    if (viewCont) {
      this.viewContentCount = viewCont.count;
    } else {
      this.viewContentCount = 0;
    }
    /*
   * send updated valued to all dropdowns
   * and open tool dropdown
   */
    const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
    currentSelectedArchivesSetting.showAdvancedFilter = false;
    this.pageSettingsService.updatePageSettings(currentSelectedArchivesSetting);
    if ($('.imageviewer-menubar').hasClass('imageviewer-menubar-active')) {
      $('.imageviewer-menubar').trigger('click');
    }
    $('#viewList').removeClass('historyActive');
    $('#ViewHistory').removeClass('historyActive');
  }
  otherarchivesToggle(): void {
    this.toolsCount = 0;
    if (!this.otherArchiveDropdown) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: true,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false
      });
    } else if (this.otherArchiveDropdown) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false
      });
    }
    const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
    currentSelectedArchivesSetting.showAdvancedFilter = false;
    this.pageSettingsService.updatePageSettings(currentSelectedArchivesSetting);
    if ($('.imageviewer-menubar').hasClass('imageviewer-menubar-active')) {
      $('.imageviewer-menubar').trigger('click');
    }
  }
  gotoAboutPage(): void {
    if (window.location.pathname.includes('unauthorized')) {
    } else {
      this.router.navigate([this.homePageUrl + '/about-us']);
    }
  }
  runHistoryDoc(viewDoc): void {
    let documentUrl;
    let returnUrl;
    documentUrl = viewDoc.path;
    if (window.location.pathname === this.homePageUrl) {
      returnUrl = documentUrl.replace('isFromResultsPage=true', '');
    } else {
      returnUrl = documentUrl;
    }
    this.router.navigateByUrl(returnUrl);
  }
  insideClick(event): void {
    event.stopPropagation();
  }
  hideTools(): void {
    $('#viewlist-content').removeClass('showList');
    $('#toolsMenuActive').removeClass('toolsActive');
    $('#tollArrowToggle').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    $('.btn-adv-search').addClass('advDisabled');
    $('.dropdown-fa').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    this.sharedService.changeheaderDropdown('true');
    const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
    currentSelectedArchivesSetting.showAdvancedFilter = false;
    this.pageSettingsService.updatePageSettings(currentSelectedArchivesSetting);
    this.isLanguageDropdownOpen = false;

    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false,
      revise: false,
      togglewileyarchivemenu: true
    });
  }
  hideToolsForLogout(): void {
    $('#viewlist-content').removeClass('showList');
    $('#toolsMenuActive').removeClass('toolsActive');
    $('#tollArrowToggle').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    $('.btn-adv-search').addClass('advDisabled');
    $('.dropdown-fa').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    this.sharedService.changeheaderDropdown('true');
    // const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
    // currentSelectedArchivesSetting.showAdvancedFilter = false;
    // this.pageSettingsService.updatePageSettings(currentSelectedArchivesSetting);
    this.isLanguageDropdownOpen = false;

    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false,
      revise: false,
      togglewileyarchivemenu: true
    });
  }
  toolsDropdownActive(): void {
    $('.viewlist-listcontainerinner').removeClass('historyActive');
    $('#otherarchivesMenuActive').removeClass('toolsActive');
    $('#wiley-menu-archive-content').removeClass('showList');
    $('#otherArchiveArrowToggle').removeClass('fa-chevron-up');
    $('#otherArchiveArrowToggle').addClass('fa-chevron-down');
    $('.dropdown-fa').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    $('#toolsMenuActive').removeClass('toolsActive');
    // this.otherarchiveCount = 0;
    this.toolsCount = 0;
  }

  removeClippedImage(index: any): void {
    this.clippedImageList.splice(index, 1);
    localStorage.setItem('croppedImageList', JSON.stringify(this.clippedImageList));
    this.toggleArrow = true;
    setTimeout(() => {
      this.toggleArrow = false;
    }, 500);
    this.viewCroppedImageListmodalRef.hide();
    event.stopImmediatePropagation();
  }

  openOriginalDocument(): void {

  }
  gotoClipedImage(image): void {
    this.router.navigateByUrl(image);
  }
  openClipedImage(clipImage, index): void {
    this.clippedImage = this.clippedImageList[index];
    this.clippedImage['index'] = index;
    this.activeSlideIndex = index;
    this.clipImageTemplatemodalRef = this.bsModalService.show(this.clipImageTemplate,
      { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large' });
  }
  closePopup(): void {
    this.clipImageTemplatemodalRef.hide();
    event.stopImmediatePropagation();
  }
  slideChangeEvent(event: number): void { }

  exportClipImages(): void {
    /*
    * Style Your content here
    */
    const htmlCode = `<html><head><title>Clipped Image List - Wiley Digital Archives</title><style>
     body {font-family: 'Source Serif Pro', serif !important}
    .body {font-family: 'Source Serif Pro', serif !important}
		.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,
		.col-lg-8,.col-lg-9,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,
		.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,
		.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,
		.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9{
			position:relative;min-height:1px;padding-right:15px;padding-left:15px}
		.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,
		.col-md-8,.col-md-9{float:left}.col-md-12{width:100%}.col-md-11{width:91.66666667%}.col-md-10{width:83.33333333%}
		.col-md-9{width:75%}.col-md-8{width:66.66666667%}.col-md-7{width:58.33333333%}.col-md-6{width:50%}.col-md-5{width:41.66666667%}
		.col-md-4{width:33.33333333%}.col-md-3{width:25%}.col-md-2{width:16.66666667%}.col-md-1{width:8.33333333%}
		.img-responsive,img {display: block;max-width: 100%;max-height: 100%;margin: 0 auto;}img{vertical-align:middle;}img{border:0;}

		.export-btn,.viewlist-contenticon{float:right}
		.content_type_title{border-top: 3px solid #009ca9;}
		.viewlist-content-div{padding:0 0 0 15px;width: 100%;    margin-top: 10px;}
    .viewlist-container-thumbnail{background: #000;padding: 4px;height: 450px !important;width:100%;margin-bottom: 5px;overflow: hidden;
    min-height: 64px;}
		.viewlist-container-content{padding:0;border-bottom:1px solid #009ca9;margin-bottom:5px;display: inline-block;width: 100%;}
		.viewlist-contenticon i{font-size:20px;margin-top:10px}.title-heading{font-weight:600;color:#615c5c}
		.viewlist-containertitle h4{font-size:14px;font-weight:700;margin:0 0 5px}
		.viewlist-container-parent{padding:0 0 15px;max-height:auto;overflow-y:auto;overflow-x:hidden;margin-top:1px}h4
		.viewlist-title{font-size:18px!important}
		.viewlist-container-parent::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);background-color:#F5F5F5}
		.viewlist-container-parent::-webkit-scrollbar{width:6px;background-color:#F5F5F5}
		.viewlist-container-parent::-webkit-scrollbar-thumb{background-color:#61165e;border-radius:25px}
		.viewlist-containertitle{width:100%;display:inline-block}
		.viewlist-containertitle-header{padding-bottom:5px;border-bottom: 3px solid #009ca3;margin-bottom: 15px;}
		.viewlist-containertitle-header h4{margin-top:10px}
		#viewlist-content{width:250px;top:59px;right:-16px}
		.viewlist-listcontainerinner{padding:3px 0}
		.viewlist-listcontainerinner li{border:none!important}
		.viewlist-container-footer{padding-top:7px;border-top:2px solid #ccc}.result-tag li{float:left;margin-right:0;padding-top:0}
		.viewlist-containertitle
		.viewlist-title{float:left;width:65%}
		.viewlist-container-content:last-child{border:0;margin-bottom: 30px; }.content_type_title h4{margin:5px 0 15px}
		.print-hide{display:none;}.thumbnail-image-link-hide{display: flex;align-items: center;height: 100%;}body{background-color: #fff;}
		.search-result-header-title a{text-decoration: none;cursor: pointer;color: #61165E !important;}
    .search-result-header-title a:hover{text-decoration: underline;}
    .search-result-header-title {margin: 0;}
    .source-citation {margin: 8px 0;}
    .image-notes-header {margin: 0;}
    .image-notes {margin: 5px 0 10px 0;}
    .viewlist-clipimage-content {margin-bottom: 25px;}
    .viewlist-title.list-title {font-size: 18px !important;font-family: 'Source Serif Pro', serif !important;}
    .clipimage-imagesource {width: 100%;margin-top: 8px;}
    .clipped-image-title{cursor: default !important;text-decoration: none !important;}
    .image-cource-span {font-style: italic;color: #000;}
    .image-notes{font-weight: 200 !important;}
    @media print {.print-hide{display:none;}}a.search-result-header-title{cursor: pointer;font-size: 20px;color: #61165E !important;}
		</style></head><body >`;


    const monthNames: Array<String> = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const d = new Date();
    const htmlContent = [htmlCode + $('#clipImages').html() + '</body></html>'];
    const imageBlob = new Blob(htmlContent);
    const blob = new Blob(htmlContent, { type: 'text/html' });
    const fileName = `ClipImage-Export-${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}.html`;
    // if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
    //   window.navigator.msSaveBlob(blob, fileName);
    // } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    //   let htmlContents = '';
    //   const win = window.open('', '_blank');
    //   $(win.document).val('');
    //   htmlContents = htmlCode + $('#clipImages').html() + '</body></html>';
    //   win.document.write(htmlContents);
    //   win.document.close();
    // } else {

    // const doc = document.createElement('a');
    // doc.href = URL.createObjectURL(blob);
    // doc.download = fileName;
    // doc.hidden = true;

    // document.body.appendChild(doc);
    // doc.click();
    // }



    let base64data;
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    // this.modalService.showLoader();
    reader.onloadend = () => {
      base64data = reader.result;
      if (base64data) {
        let params = {
          'base64Data': base64data,
          'fileName': this.currentLocation
        }
        this.httpClient.post<any>(environment.APIUrl + APIURLS.exportClipImages, params).pipe(takeUntil(this.stop$)).subscribe(res => {
          if (res) {
            this.currentExportURL = '';
            // this.modalService.hideLoader();
            this.currentExportURL = res.url;
            this.exportClipTemplatemodalRef = this.bsModalService.show(this.exportClipTemplate,
              { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large exportClipTemplate' });
          }
        }, (err) => {
          // this.modalService.hideLoader();
        });
      }
    }
  }
  removeCroppedImage(image): void {
    this.currentClipImageForDeleted = image;
    this.viewCroppedImageListmodalRef = this.bsModalService.show(this.viewCroppedImageListTemplate,
      { backdrop: true, ignoreBackdropClick: true });
    event.preventDefault();
  }
  viewcroppedImageListCloseModal(): void {
    this.viewCroppedImageListmodalRef.hide();
    event.stopImmediatePropagation();
  }
  clearAll(): void {
    this.removeCroppedImageListmodalRef = this.bsModalService.show(this.removeCroppedImageListTemplate,
      { backdrop: true, ignoreBackdropClick: true });
    event.preventDefault();
  }
  removecroppedImageListCloseModal(): void {
    this.removeCroppedImageListmodalRef.hide();
  }
  clearAllCroppedImageList(): void {
    if (this.clippedImageList.length > 0) {
      this.clippedImageList = [];
      localStorage.setItem('croppedImageList', JSON.stringify(this.clippedImageList));
    }
    this.removeCroppedImageListmodalRef.hide();
    event.stopImmediatePropagation();
  }
  clearAllLists(): void {
    this.removeListmodalRef = this.bsModalService.show(this.removeListTemplate,
      { backdrop: true, ignoreBackdropClick: true });
  }
  clearAllDocumentList(): void {
    this.viewList = this.selectedViewList = {};
    this.viewListCount = 0;
    localStorage.setItem('documentList', JSON.stringify({}));
    this.removeListmodalRef.hide();
    PageSettingsService.ViewListObservable.next({
      viewList: this.viewList,
      count: this.viewListCount
    });
  }
  removeListCloseModal(): void {
    this.removeListmodalRef.hide();
  }
  viewListSelection(): void {
    this.viewList = this.selectedViewList = JSON.parse(localStorage.getItem('documentList'));
    const viewListArry = [];
    this.getJSONKeys(this.selectedViewList).forEach((obj: any) => {
      this.getJSONKeys(this.selectedViewList[obj]).forEach(obj1 => {
        viewListArry.push(obj1);
      });
    });
    this.viewListCount = viewListArry.length;
    if (this.clippedImageList) {
      if (this.viewListCount === 0 && this.clippedImageList.length > 0) {
        setTimeout(() => {
          if (this.docDetailsTab) {
            this.docDetailsTab.tabs[1].active = true;
          }
        }, 0);
      }
    }
    const viewCont = JSON.parse(localStorage.getItem('contntList'));
    if (viewCont) {
      this.viewContentCount = viewCont.count;
    } else {
      this.viewContentCount = 0;
    }
    this.selectedContentType = "All";
  }
  /*
  * if language dropdown is opened than
  * Close other dropdowns like archives and tools dropdown
  */
  languageDropdown() {
    if (!this.isLanguageDropdownOpen) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: true,
        shareDocument: false,
        revise: false
      });
    } else if (this.isLanguageDropdownOpen) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false
      });
    }
    const currentSelectedArchivesSetting = PageSettingsService.pageSettings;
    currentSelectedArchivesSetting.showAdvancedFilter = false;
    this.pageSettingsService.updatePageSettings(currentSelectedArchivesSetting);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event) {
    setTimeout(() => {
      // this.viewListSelection();
      this.clippedImageList = JSON.parse(localStorage.getItem('croppedImageList'));
    }, 1000);
  }
  goToQuickGuide(): void {
    window.open((window.location.origin + '/content/pdfs/WDA_QuickGuide_Complete_v2.0.pdf'), '_blank');
  }
  goToAccessbilityGuide(): void {
    window.open((window.location.origin + '/content/pdfs/WDA_Accessibility.pdf'), '_blank');
  }

  refreshHistory() {
    setTimeout(() => {
      this.viewHistoryList = JSON.parse(localStorage.getItem('HistoryList'));
      const dateAry: any[] = [];
      this.viewHistoryList = this.viewHistoryList instanceof Array ? this.viewHistoryList : [];
      if (this.viewHistoryList.length > 0) {
        this.viewHistoryList.forEach((obj, index) => {
          if (obj.isForTATTools) {
            this.viewHistoryList.splice(index, 1);
          }
        });
        this.viewHistoryList.forEach(element => {
          this.historyObj = element;
          this.historyDate = element.date;
          dateAry.push(element.date);
          this.datetool = dateAry.reverse();
          const searchValAry: any[] = [];
          if (element.hasOwnProperty('filterParams')) {
            this.filters = this.viewHistoryList.filterParams;
            if (element.filterParams.searchKeywords) {
              element.filterParams.searchKeywords.forEach(obj => {
                if (obj.fieldValue) {
                  element.filedVal = true;
                  searchValAry.push(obj.fieldValue);
                }
              });
              searchValAry.length <= 0
                ? (element.searchVal = true)
                : (element.searchVal = false);
            }
          }
        });
      }
    }, 1000);
    setTimeout(() => {
      if (localStorage.getItem('detailsHistory')) {
        this.documentHistory = JSON.parse(localStorage.getItem('detailsHistory'));
        if (this.documentHistory.length > 0) {
        } else {
          this.documentHistory = [];
        }
      } else {
        this.documentHistory = [];
      }
    }, 1500);
  }

  /**
   * @description: Move to previous image from clipped image list
   * and display selected object/image
   */
  gotoPreviousImage() {
    if (this.activeSlideIndex !== 0) {
      this.activeSlideIndex = +this.activeSlideIndex - 1;
    } else {
      this.activeSlideIndex = 0;
    }
    this.clippedImage = this.clippedImageList[this.activeSlideIndex];
    this.clippedImage['index'] = this.activeSlideIndex;
  }
  /**
   * @description: Move to Next image from clipped image list
   * and display selected object/image
   */
  gotoNextImage() {
    if (this.activeSlideIndex + 1 !== (this.clippedImageList.length)) {
      this.activeSlideIndex = +this.activeSlideIndex + 1;
    } else {
      this.activeSlideIndex = this.clippedImageList.length - 1;
    }
    this.clippedImage = this.clippedImageList[this.activeSlideIndex];
    this.clippedImage['index'] = this.activeSlideIndex;
  }
  gotoHomePage() {
    if (!this.isGuestUser) {
      if (PageSettingsService.pageSettings.searchTerm) {
        PageSettingsService.pageSettings.searchTerm = '';
      }
      if (PageSettingsService.pageSettings.advancedFilterParam) {
        PageSettingsService.pageSettings.advancedFilterParam = new AdvancedFilterParams();
      }
      this.router.navigate([this.homePageUrl]);
    }
  }
  showResults(endTime, startTime) {
    const duration = ((endTime - startTime) / 1000);
    const bitsLoaded = this.downloadSize * 8;
    const speedBps = (bitsLoaded / duration);
    const speedKbps: any = (speedBps / 1024).toFixed(2);
    const speedMbps: any = (speedKbps / 1024).toFixed(2);
    if (speedMbps > 0 && speedMbps < 0.10) {
      this.connectionIndicator.nativeElement.classList.add('slow-singal-icon');
      if (this.connectionIndicator.nativeElement.classList.contains('high-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('high-singal-icon');
      }
      if (this.connectionIndicator.nativeElement.classList.contains('medium-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('medium-singal-icon');
      }
      if (this.connectionIndicator.nativeElement.classList.contains('no-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('no-singal-icon');
      }
    } else if (speedMbps > 0.10 && speedMbps < 2) {
      this.connectionIndicator.nativeElement.classList.add('medium-singal-icon');
      if (this.connectionIndicator.nativeElement.classList.contains('high-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('high-singal-icon');
      }
      if (this.connectionIndicator.nativeElement.classList.contains('slow-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('slow-singal-icon');
      }
      if (this.connectionIndicator.nativeElement.classList.contains('no-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('no-singal-icon');
      }
    } else {
      this.connectionIndicator.nativeElement.classList.add('high-singal-icon');
      if (this.connectionIndicator.nativeElement.classList.contains('medium-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('medium-singal-icon');
      }
      if (this.connectionIndicator.nativeElement.classList.contains('slow-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('slow-singal-icon');
      }
      if (this.connectionIndicator.nativeElement.classList.contains('no-singal-icon')) {
        this.connectionIndicator.nativeElement.classList.remove('no-singal-icon');
      }
    }
  }

  downloadImageFile() {
    this.DOMcontentstartTime = (new Date()).getTime();
    // tslint:disable-next-line:max-line-length
    this.httpClient.get<any>(APIURLS.bandWidthCheckImgUrl + '?time=' + new Date().getTime(), { observe: 'response' }).pipe(takeUntil(this.stop$)).subscribe(data => {
      if (data) {
        this.DOMLoadendtime = new Date().getTime();
        this.showResults(this.DOMLoadendtime, this.DOMcontentstartTime);
      }
    });
  }
  connectionMouseEnter() {
    this.showConncetionStatus = true;
  }
  connectionMouseLeave() {
    this.showConncetionStatus = false;
  }
  togglewileyheaderarchivemenu() {
    if (!this.togglewileyarchivemenu) {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false,
        togglewileyarchivemenu: true
      });
    } else {
      this.sharedService.dropdownToggleOption({
        tools: false,
        otherArchives: false,
        archiveSelected: false,
        advanceSearch: false,
        help: false,
        language: false,
        shareDocument: false,
        revise: false,
        togglewileyarchivemenu: false
      });
    }
  }
  gotoHelpPage() {
    window.open(APIURLS.helpAndTrainingLink, '_blank');
  }
  gotoHome() {
    console.log(this.homePageUrl)
    const lastSelectedTile: any = sessionStorage.getItem('selectedGroup');
    if (lastSelectedTile) {
      sessionStorage.removeItem('selectedGroup');
    }
    if (sessionStorage.getItem('explorerLimiter')) {
      sessionStorage.removeItem('explorerLimiter');
    }
    localStorage.removeItem('filter');
    setTimeout(() => {
      window.location.reload();
    }, 600);
  }
  getArchives(locId) {
    let wileyTitle = '';
    let wileyContent = '';
    let wileyName = '';
    let wileyShortName = '';

    this.translate.get('wiley.description.title').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      wileyTitle = res;
    });

    this.translate.get('wiley.description.content').pipe(takeUntil(this.stop$)).subscribe((content: string) => {
      wileyContent = content;
    });

    this.translate.get('wiley.name').pipe(takeUntil(this.stop$)).subscribe((name: string) => {
      wileyName = name;
    });

    this.translate.get('wiley.shortName').pipe(takeUntil(this.stop$)).subscribe((shortName: string) => {
      wileyShortName = shortName;
    });
    if (locId) {
      const getVal = JSON.parse(localStorage.getItem('subscribedArchivesForFacet'));
      if (getVal) {
        let isavailable = false;
        let isRCPAvailable = false;
        if (Array.isArray(getVal)) {
          getVal.forEach(element => {
            if (element.shortName === 'RGS1' || element.shortName === 'RGS2') {
              isavailable = true;
            }
            if (element.shortName === 'RCP1' || element.shortName === 'RCP2') {
              isRCPAvailable = true;
            }
            if (this.locationConfigData) {
              if (this.locationConfigData.locations[element.shortName]) {
                getVal['archiveLogo'] = getVal['archiveLogo'] ? getVal['archiveLogo'] : '/assets/config/wiley/wiley_logo_old.png';
                element['logo'] = this.locationConfigData.locations[element.shortName].archiveLogo;
                this.archivesList.push(element.shortName);
              }
            }
          });
          this.archivesList = getVal;
          const rgs1value = 'RGS1';
          const rgs2value = 'RGS2';
          const rcp1value = 'RCP1';
          const rcp2value = 'RCP2';
          this.archivesList = this.archivesList.filter(item => item.shortName !== rgs1value);
          this.archivesList = this.archivesList.filter(item => item.shortName !== rgs2value);
          this.archivesList = this.archivesList.filter(item => item.shortName !== rcp1value);
          this.archivesList = this.archivesList.filter(item => item.shortName !== rcp2value);

          const rgsObject = {
            'logo': 'assets/config/rgs/rgs-logo-other.png',
            'name': 'The Royal Geographical Society (with IBG)',
            'shortName': 'RGS',
            'pageTitle': 'The Royal Geographical Society (with IBG)'
          };
          const rcpObject = {
            'logo': 'assets/config/rcp/rcp_logo.png',
            'name': 'The Royal College of Physicians',
            'shortName': 'RCP',
            'pageTitle': 'The Royal College of Physicians'
          };
          if (isavailable) {
            this.archivesList.push(rgsObject);
          }
          if (isRCPAvailable) {
            this.archivesList.push(rcpObject);
          }
          setTimeout(() => {
            const wileyObject = {
              'backgroundUrl': 'assets/config/wiley/wiley_landing_page_banner.png',
              'description': {
                'title': wileyTitle,
                // tslint:disable-next-line:max-line-length
                'content': wileyContent
              },
              'pageTitle': 'Wiley Digital Archives',
              'logo': 'assets/config/wiley/wiley_logo.png',
              'name': wileyName ? wileyName : 'Cross Search all of the Wiley Digital Archives',
              'shortName': wileyShortName ? wileyShortName : 'wiley',
              returnUrl: ''
            };
            this.archivesList.unshift(wileyObject);
          }, 600);
        }
      } else {
        this.httpClient.get(environment.APIUrl + APIURLS['getArchivesV2'] + '/' + locId).pipe(takeUntil(this.stop$)).subscribe((res) => {
          if (res) {
            let isavailable = false;
            let isRCPAvailable = false;

            if (Array.isArray(res)) {
              res.forEach(element => {
                if (element.shortName === 'RGS1' || element.shortName === 'RGS2') {
                  isavailable = true;
                }
                if (element.shortName === 'RCP1' || element.shortName === 'RCP2') {
                  isRCPAvailable = true;
                }
                if (this.locationConfigData) {
                  if (this.locationConfigData.locations[element.shortName]) {
                    res['archiveLogo'] = res['archiveLogo'] ? res['archiveLogo'] : '/assets/config/wiley/wiley_logo_old.png';
                    element['logo'] = this.locationConfigData.locations[element.shortName].archiveLogo;
                    this.archivesList.push(element.shortName);
                  }
                }
              });
              this.archivesList = res;
              const rgs1value = 'RGS1';
              const rgs2value = 'RGS2';
              const rcp1value = 'RCP1';
              const rcp2value = 'RCP2';
              this.archivesList = this.archivesList.filter(item => item.shortName !== rgs1value);
              this.archivesList = this.archivesList.filter(item => item.shortName !== rgs2value);
              this.archivesList = this.archivesList.filter(item => item.shortName !== rcp1value);
              this.archivesList = this.archivesList.filter(item => item.shortName !== rcp2value);

              const rgsObject = {
                'logo': 'assets/config/rgs/rgs-logo-other.png',
                'name': 'The Royal Geographical Society (with IBG)',
                'shortName': 'RGS',
                'pageTitle': 'The Royal Geographical Society (with IBG)'
              };
              const rcpObject = {
                'logo': 'assets/config/rcp/rcp_logo.png',
                'name': 'The Royal College of Physicians',
                'shortName': 'RCP',
                'pageTitle': 'The Royal College of Physicians'
              };
              if (isavailable) {
                this.archivesList.push(rgsObject);
              }
              if (isRCPAvailable) {
                this.archivesList.push(rcpObject);
              }
              setTimeout(() => {
                const wileyObject = {
                  'backgroundUrl': 'assets/config/wiley/wiley_landing_page_banner.png',
                  'description': {
                    'title': wileyTitle,
                    // tslint:disable-next-line:max-line-length
                    'content': wileyContent
                  },
                  'pageTitle': 'Wiley Digital Archives',
                  'logo': 'assets/config/wiley/wiley_logo.png',
                  'name': wileyName ? wileyName : 'Cross Search all of the Wiley Digital Archives',
                  'shortName': wileyShortName ? wileyShortName : 'wiley',
                  returnUrl: ''
                };
                this.archivesList.unshift(wileyObject);
              }, 600);
            }
          }
        }, err => { });
      }
    }
  }
  gotoWDATrainingHub() {
    window.open(this.WDATrainingHubLink, '_blank');
  }
  parsedText(text) {
    const dom = new DOMParser().parseFromString(
      '<!doctype html><body>' + text,
      'text/html');
    const decodedString = dom.body.textContent;
    return decodedString;
  }
  exportClipImagesList(clipList) {
    // console.log(clipList);

  }
  closeExportPopup() {
    this.exportClipTemplatemodalRef.hide();
  }

  copyShareUrl(sheredUrl) {
    const textToBeCopied = (sheredUrl).replace(/<(?:.|\n)*?>/gm, '');
    const textarea = this.dom.createElement('textarea');
    textarea.style.height = '0px';
    textarea.style.left = '-100px';
    textarea.style.opacity = '0';
    textarea.style.position = 'fixed';
    textarea.style.top = '-100px';
    textarea.style.width = '0px';
    this.dom.body.appendChild(textarea);
    textarea.value = textToBeCopied;
    textarea.select();
    const copiedToclipBoard = document.execCommand('copy');
    if (copiedToclipBoard) {
      this.modalService.showNotification('SUCCESS', 'URL Copied to clipboard.', '');
      // this.exportClipTemplatemodalRef.hide();
    }
  }
  copyRunTemp(viewHistory) {
    // this.modalService.showLoader();
    const host = window.location.host;
    const protocol = window.location.protocol;
    this.rulQueryShortURL = '';
    this.currentRunQuery = `${protocol}//${host}${viewHistory['url']}`;
    // this.currentRunQuery = `${protocol}//${host}${viewHistory['url']};searchTerm=${encodeURIComponent(viewHistory['searchParams'])};selectedArchives=${PageSettingsService.pageSettings.currentSelectedArchives}`;
    this.urlUtilityService.getBookmarkShortUrl(this.currentRunQuery, (shortUrlResponse: any) => {
      this.rulQueryShortURL = shortUrlResponse.url;
      this.exportRunTemplatemodalRef = this.bsModalService.show(this.exportRunTemplate,
        { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large' });
      // this.modalService.hideLoader();
    }, (err: any) => {
      // this.modalService.hideLoader();
    });
  }
  closeExportURLPopup() {
    this.exportRunTemplatemodalRef.hide();
  }
  exportSingleClipedImage(img, index) {
    /*
    * Style Your content here
    */
    const htmlCode = `<html><head><title>Clipped Image List - Wiley Digital Archives</title><style>
     body {font-family: 'Source Serif Pro', serif !important}
    .body {font-family: 'Source Serif Pro', serif !important}
		.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,
		.col-lg-8,.col-lg-9,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,
		.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,
		.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,
		.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9{
			position:relative;min-height:1px;padding-right:15px;padding-left:15px}
		.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,
		.col-md-8,.col-md-9{float:left}.col-md-12{width:100%}.col-md-11{width:91.66666667%}.col-md-10{width:83.33333333%}
		.col-md-9{width:75%}.col-md-8{width:66.66666667%}.col-md-7{width:58.33333333%}.col-md-6{width:50%}.col-md-5{width:41.66666667%}
		.col-md-4{width:33.33333333%}.col-md-3{width:25%}.col-md-2{width:16.66666667%}.col-md-1{width:8.33333333%}
		.img-responsive,img {display: block;max-width: 100%;max-height: 100%;margin: 0 auto;}img{vertical-align:middle;}img{border:0;}

		.export-btn,.viewlist-contenticon{float:right}
		.content_type_title{border-top: 3px solid #009ca9;}
		.viewlist-content-div{padding:0 0 0 15px;width: 100%;    margin-top: 10px;}
    .viewlist-container-thumbnail{background: #000;padding: 4px;height: 450px !important;width:100%;margin-bottom: 5px;overflow: hidden;
    min-height: 64px;}
		.viewlist-container-content{padding:0;border-bottom:1px solid #009ca9;margin-bottom:5px;display: inline-block;width: 100%;}
		.viewlist-contenticon i{font-size:20px;margin-top:10px}.title-heading{font-weight:600;color:#615c5c}
		.viewlist-containertitle h4{font-size:14px;font-weight:700;margin:0 0 5px}
		.viewlist-container-parent{padding:0 0 15px;max-height:auto;overflow-y:auto;overflow-x:hidden;margin-top:1px}h4
		.viewlist-title{font-size:18px!important}
		.viewlist-container-parent::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);background-color:#F5F5F5}
		.viewlist-container-parent::-webkit-scrollbar{width:6px;background-color:#F5F5F5}
		.viewlist-container-parent::-webkit-scrollbar-thumb{background-color:#61165e;border-radius:25px}
		.viewlist-containertitle{width:100%;display:inline-block}
		.viewlist-containertitle-header{padding-bottom:5px;border-bottom: 3px solid #009ca3;margin-bottom: 15px;}
		.viewlist-containertitle-header h4{margin-top:10px}
		#viewlist-content{width:250px;top:59px;right:-16px}
		.viewlist-listcontainerinner{padding:3px 0}
		.viewlist-listcontainerinner li{border:none!important}
		.viewlist-container-footer{padding-top:7px;border-top:2px solid #ccc}.result-tag li{float:left;margin-right:0;padding-top:0}
		.viewlist-containertitle
		.viewlist-title{float:left;width:65%}
		.viewlist-container-content:last-child{border:0;margin-bottom: 30px; }.content_type_title h4{margin:5px 0 15px}
		.print-hide{display:none;}.thumbnail-image-link-hide{display: flex;align-items: center;height: 100%;}body{background-color: #fff;}
		.search-result-header-title a{text-decoration: none;cursor: pointer;color: #61165E !important;}
    .search-result-header-title a:hover{text-decoration: underline;}
    .search-result-header-title {margin: 0;}
    .source-citation {margin: 8px 0;}
    .image-notes-header {margin: 0;}
    .image-notes {margin: 5px 0 10px 0;}
    .viewlist-clipimage-content {margin-bottom: 25px;}
    .viewlist-title.list-title {font-size: 18px !important;font-family: 'Source Serif Pro', serif !important;}
    .clipimage-imagesource {width: 100%;margin-top: 8px;}
    .clipped-image-title{cursor: default !important;text-decoration: none !important;}
    .image-cource-span {font-style: italic;color: #000;}
    .image-notes{font-weight: 200 !important;}
    @media print {.print-hide{display:none;}}a.search-result-header-title{cursor: pointer;font-size: 20px;color: #61165E !important;}
		</style></head><body >`;


    const monthNames: Array<String> = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const d = new Date();
    const htmlContent = [htmlCode + $(`#singleImage${index}`).html() + '</body></html>'];
    const imageBlob = new Blob(htmlContent);
    const blob = new Blob(htmlContent, { type: 'text/html' });
    const fileName = `ClipImage-Export-${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}.html`;
    let base64data;
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    // this.modalService.showLoader();
    reader.onloadend = () => {
      base64data = reader.result;
      if (base64data) {
        let params = {
          'base64Data': base64data,
          'fileName': this.currentLocation
        }
        this.httpClient.post<any>(environment.APIUrl + APIURLS.exportClipImages, params).pipe(takeUntil(this.stop$)).subscribe(res => {
          if (res) {
            this.currentExportURL = '';
            // this.modalService.hideLoader();
            this.currentExportURL = res.url;
            this.exportClipTemplatemodalRef = this.bsModalService.show(this.exportClipTemplate,
              { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large exportClipTemplate' });
          }
        }, (err) => {
          // this.modalService.hideLoader();
        });
      }
    }
  }

  exportSingleDocument(index) {
    /*
    * Style Your content here
    */
    const htmlCode = `<html><head><title>Your List - Wiley Digital Archives</title><style>
		.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,
		.col-lg-8,.col-lg-9,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,
		.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,
		.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,
		.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9{
			position:relative;min-height:1px;padding-right:15px;padding-left:15px}
		.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,
		.col-md-8,.col-md-9{float:left}.col-md-12{width:100%}.col-md-11{width:91.66666667%}.col-md-10{width:83.33333333%}
		.col-md-9{width:75%}.col-md-8{width:66.66666667%}.col-md-7{width:58.33333333%}.col-md-6{width:50%}.col-md-5{width:41.66666667%}
		.col-md-4{width:33.33333333%}.col-md-3{width:25%}.col-md-2{width:16.66666667%}.col-md-1{width:8.33333333%}
		.img-responsive,img {display: block;max-width: 100%;max-height: 100%;margin: 0 auto;}img{vertical-align:middle;}img{border:0;}

		.export-btn,.viewlist-contenticon{float:right}
		.content_type_title{border-top: 3px solid #009ca9;}
		.viewlist-content-div{padding:0 0 0 15px;margin: 0 0 10px 0;}
    .viewlist-container-thumbnail{background: #414246;padding: 4px;margin-bottom: 5px;height: 100px !important;width: 110px;}
		.viewlist-container-content{padding:0;border-bottom:1px solid #009ca9;margin-bottom:5px;display: inline-block;width: 100%;}
		.viewlist-contenticon i{font-size:20px;margin-top:10px}.title-heading{font-weight:600;color:#615c5c}
		.viewlist-containertitle h4{font-size:14px;font-weight:700;margin:0 0 5px}
		.viewlist-container-parent{padding:0 0 15px;max-height:auto;overflow-x:hidden;overflow-y:auto;margin-top:1px}h4
		.viewlist-title{font-size:18px!important}
		.viewlist-container-parent::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);background-color:#F5F5F5}
		.viewlist-container-parent::-webkit-scrollbar{width:6px;background-color:#F5F5F5}
		.viewlist-container-parent::-webkit-scrollbar-thumb{background-color:#61165e;border-radius:25px}
		.viewlist-containertitle{width:100%;display:inline-block}
		.viewlist-containertitle-header{padding-bottom:5px}
		.viewlist-containertitle-header h4{margin-top:10px}
		#viewlist-content{width:250px;top:59px;right:-16px}
		.viewlist-listcontainerinner{padding:3px 0}
		.viewlist-listcontainerinner li{border:none!important}
		.viewlist-container-footer{padding-top:7px;border-top:2px solid #ccc}.result-tag li{float:left;margin-right:0;padding-top:0}
		.viewlist-containertitle
		.viewlist-title{float:left;width:65%}
		.content_type_title h4{margin:5px 0 15px}
		.print-hide{display:none;}.thumbnail-image-link-hide{align-items: center;}body{background-color: #fff;}
		.search-result-header-title a{text-decoration: none;cursor: pointer;color: #61165E !important;}
    .search-result-header-title a:hover{text-decoration: underline;}
    .search-result-citation {    width: 100%;}
    .search-result-header-title{    width: 100%;    display: inline-block;}
    .search-result-parent-div {    width: 100%;    display: inline-block;}
    .viewlist-containertitle.viewlist-containertitle-header {border-bottom: 3px solid #009ca9;margin-bottom: 15px;}
    @media print {.print-hide{display:none;}}a.search-result-header-title{cursor: pointer;font-size: 20px;color: #61165E !important;}
		</style></head><body >`;


    const monthNames: Array<String> = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const d = new Date();
    const htmlContent = [htmlCode + '<div class="viewlist-containertitle viewlist-containertitle-header"><h4 _ngcontent-c4="" class="viewlist-title list-title">Your Lists</h4></div><div class="col-sm-12 viewlist-container-content">' + $(`#singleDoc${index}`).html() + '</div></body></html>'];
    const blob = new Blob(htmlContent, { type: 'text/html' });
    let base64data;
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    // this.modalService.showLoader();
    reader.onloadend = () => {
      base64data = reader.result;
      if (base64data) {
        let params = {
          'base64Data': base64data,
          'fileName': this.currentLocation
        }
        this.httpClient.post<any>(environment.APIUrl + APIURLS.exportClipImages, params).pipe(takeUntil(this.stop$)).subscribe(res => {
          if (res) {
            this.currentExportURL = '';
            // this.modalService.hideLoader();
            this.currentExportURL = res.url;
            this.exportClipTemplatemodalRef = this.bsModalService.show(this.exportClipTemplate,
              { backdrop: true, ignoreBackdropClick: true, class: 'modal-sm large exportClipTemplate' });
          }
        }, (err) => {
          // this.modalService.hideLoader();
        });
      }
    }
  }

  navToDCPage() {
    this.isDownloadContentOpen = true;
    this.sharedService.dropdownToggleOption({
      tools: false,
      otherArchives: false,
      archiveSelected: false,
      advanceSearch: false,
      help: false,
      language: false,
      shareDocument: false,
      revise: false
    });
    this.router.navigate(['/wiley/downloadcontent']);
  }
  clearAllDC() {
    localStorage.removeItem('contntList');
    this.viewContentCount = 0;
    this.pageSettingsService.clearViewContent();
    this.downloadPDFmodalRef.hide();
  }
  showViewCOntentList() {
    const viewCont = JSON.parse(localStorage.getItem('contntList'));
    if (viewCont && viewCont.count > 0) {
      // this.modalService.showLoader();
      this.searchService.downloadeDocument(viewCont.viewList).subscribe((document: any) => {
        this.downloadDocs = document;
        this.downloadPDFmodalRef = this.bsModalService.show(this.downloadDocsTemplate, { backdrop: true, ignoreBackdropClick: true });
        // this.modalService.hideLoader();
      });
    }
  }
  DownloadALlModal(downloadDocs) {
    if (downloadDocs) {
      for (let k = 0; k < downloadDocs.length; k++) {
        if (downloadDocs[k].signedUrl) {
          window.open(downloadDocs[k].signedUrl);
          this.downloadPDFmodalRef.hide();
        }
      }
    }
  }
  closeDownloadModal() {
    this.downloadPDFmodalRef.hide();
  }
}

interface SendMail {
  name?: String;
  mail?: String;
  topic?: String;
  message?: String;
}
