/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  public static loaderStateObservable: BehaviorSubject<any>;
  isLoaderShown;

  constructor() {
    LoaderComponent.loaderStateObservable = new BehaviorSubject(false);
    LoaderComponent.loaderStateObservable.subscribe((flag) => {
      this.isLoaderShown = flag;
    });
  }

  public static showLoader() {
    if (LoaderComponent.loaderStateObservable) {
      LoaderComponent.loaderStateObservable.next(true);
    }
  }

  public static hideLoader() {
    if (LoaderComponent.loaderStateObservable) {
      LoaderComponent.loaderStateObservable.next(false);
    }
  }

}
