<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->
<div class="row topDocsView">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <h1 class='docsHeader' *ngIf="documentData?.title" title="{{ documentData?.title}}" appDecodeEntities>{{
          documentData?.title }} </h1>
      </div>
    </div>
    <div class="row docsParaContent">
      <div class="col-md-7">
        <p *ngIf="documentData?.description" class="small result-details-p" [innerHtml]="documentData?.description"></p>
        <p *ngIf="documentData?.source && documentData?.shortName === 'BAAS'"
          class="small result-details-p source-attributio">
          <span class="source-span">Source:</span>
          {{documentData?.shortName === "BAAS" ? archivefullName : documentData?.source }}
        </p>
        <p *ngIf="documentData?.source && documentData?.shortName === 'ENV'"
          class="small result-details-p source-attributio">
          <span class="source-span">Source:</span>
          {{ documentData?.shortName === "ENV"? ENVArchiveFullName : documentData?.source}}
        </p>
        <p *ngIf="documentData?.source && documentData?.shortName !== 'BAAS' && documentData?.shortName !== 'ENV'"
          class="small result-details-p source-attributio">
          <span class="source-span">Source:</span>
          {{ documentData?.shortName === "BAAS"? archivefullName: documentData?.source }}
        </p>
      </div>
      <div class="col-md-5">
        <div class="page-content-right text-end pull-right" *ngIf="this.documentData?.wileyId">
          <ul class="page-contentul padding-0 docsShare">
            <li (click)="openImageHelp($event)" class="helpnemu-list dropdown">
              <span [class.arabic-style]="isArabicLanguage" class="help-btn" title="{{ 'HomePage.help' | translate }}">
                <i class="spriteico helpIcon"></i>
              </span>
              <div class="image-help-container dropdown-menu show-help" id="help_box" role="menu"
                [ngClass]="{ fullScreenHelpText: isInFullScreenMode }" (click)="$event.stopPropagation()"
                [class.display-help]="showHelp">
                <label [class.arabic-style]="isArabicLanguage" class="help-title">
                  {{ "Detail.useMouseWheel" | translate }}
                </label>
                <ul [class.arabic-style]="isArabicLanguage">
                  <li [class.arabic-style]="isArabicLanguage">
                    {{ "Detail.WithintheThumbnailStrip" | translate }}
                  </li>
                  <li [class.arabic-style]="isArabicLanguage">
                    {{ "Detail.wihtintheDocument" | translate }}
                  </li>
                  <li [class.arabic-style]="isArabicLanguage">
                    {{ "Detail.withintheGrayArea" | translate }}
                  </li>
                </ul>
                <label [class.arabic-style]="isArabicLanguage" class="help-title">
                  {{ "Detail.keyboardShortcuts" | translate }}
                </label>
                <ul [class.arabic-style]="isArabicLanguage">
                  <li [class.arabic-style]="isArabicLanguage">
                    {{ "Detail.keyboardPageupWindows" | translate }}
                  </li>
                  <li [class.arabic-style]="isArabicLanguage">
                    {{ "Detail.keyboardPageupMac" | translate }}
                  </li>
                </ul>
              </div>
            </li>
            <li class="menu-list dropdown" (click)="socialShareToggleList($event)">
              <a [class.arabic-style]="isArabicLanguage"
                title="{{ 'Detail.Share' | translate }} ({{shiftContrlKey }} + Y)" href="javascript:void(0)">
                <span class="spriteico shareIcon dropdown-toggle"></span>
              </a>
              <app-share-document class="shareDocumentClss" [bookMarkShare]="toggleviewlist"
              [currentDocument]="documentData" [bookmarkDocumentURL]="bookmarkShortURL"
              [bookMarkExternalShareUrl]="shareUrl"></app-share-document>
              <!-- <div class="dropdown-menu shareWidth" [class.visibleShare]="toggleviewlist" *ngIf="!isGuestUser"
                role="menu" [ngStyle]="{ display: toggleviewlist ? 'flex' : 'none' }">
                <div class="shareLimitedPanel">
                  <h4>{{ "Detail.shareIntenalUsers" | translate }}</h4>
                  <p>{{ "Detail.wileyInternalShareContent" | translate }}</p>
                  <span class="bookMarkLink">{{ bookmarkShortURL }}</span>
                  <ul [class.arabic-style]="isArabicLanguage" (click)="$event.stopPropagation()" id="shareIconsWidth">
                    <li style="display: inline-flex">
                      <a class="resp-sharing-button__link mailBtns" (click)="shareMailClick(bookmarkShortURL)"
                        title="{{ 'Detail.shareByMail' | translate }}">
                        <i class="fa fa-envelope copyIcon"></i>
                        <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ "Detail.mailIcon" | translate
                          }}</span>
                      </a>
                      <a class="resp-sharing-button__link copyBtn" (click)="copyShareUrl(bookmarkShortURL)"
                        title="{{ 'Detail.copytoClipboard' | translate }}">
                        <i class="fa fa-files-o fa-lg copyIcon" aria-hidden="true"></i>
                        <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ "Common.Copy" | translate
                          }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="vLine"></div>
                <div class="shareAllPanel">
                  <h4>{{ "Detail.shareExternalUsers" | translate }}</h4>
                  <p>{{ "Detail.wileyExternalShareContent" | translate }}</p>
                  <span class="bookMarkLink">{{ shareUrl }}</span>
                  <ul [class.arabic-style]="isArabicLanguage" (click)="$event.stopPropagation()" id="shareIconsWidth">
                    <li style="display: inline-flex">
                      <a class="resp-sharing-button__link mailBtns" (click)="shareMailClick(shareUrl)"
                        title="{{ 'Detail.shareByMail' | translate }}">
                        <i class="fa fa-envelope copyIcon"></i>
                        <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ "Detail.mailIcon" | translate
                          }}</span>
                      </a>
                      <a class="resp-sharing-button__link copyBtn" (click)="copyShareUrl(shareUrl)"
                        title="{{ 'Detail.copytoClipboard' | translate }}">
                        <i class="fa fa-files-o fa-lg copyIcon" aria-hidden="true"></i>
                        <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ "Common.Copy" | translate
                          }}</span>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://facebook.com/sharer/sharer.php?u=http%3A%2F%2Fsharingbuttons.io" target="_blank"
                        rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://www.tumblr.com/widgets/share/tool?posttype=link&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;caption=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;content=http%3A%2F%2Fsharingbuttons.io&amp;canonicalUrl=http%3A%2F%2Fsharingbuttons.io&amp;shareSource=tumblr_share_button"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--tumblr resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M13.5.5v5h5v4h-5V15c0 5 3.5 4.4 6 2.8v4.4c-6.7 3.2-12 0-12-4.2V9.5h-3V6.7c1-.3 2.2-.7 3-1.3.5-.5 1-1.2 1.4-2 .3-.7.6-1.7.7-3h3.8z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://pinterest.com/pin/create/button/?url=http%3A%2F%2Fsharingbuttons.io&amp;media=http%3A%2F%2Fsharingbuttons.io&amp;description=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--pinterest resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M12.14.5C5.86.5 2.7 5 2.7 8.75c0 2.27.86 4.3 2.7 5.05.3.12.57 0 .66-.33l.27-1.06c.1-.32.06-.44-.2-.73-.52-.62-.86-1.44-.86-2.6 0-3.33 2.5-6.32 6.5-6.32 3.55 0 5.5 2.17 5.5 5.07 0 3.8-1.7 7.02-4.2 7.02-1.37 0-2.4-1.14-2.07-2.54.4-1.68 1.16-3.48 1.16-4.7 0-1.07-.58-1.98-1.78-1.98-1.4 0-2.55 1.47-2.55 3.42 0 1.25.43 2.1.43 2.1l-1.7 7.2c-.5 2.13-.08 4.75-.04 5 .02.17.22.2.3.1.14-.18 1.82-2.26 2.4-4.33.16-.58.93-3.63.93-3.63.45.88 1.8 1.65 3.22 1.65 4.25 0 7.13-3.87 7.13-9.05C20.5 4.15 17.18.5 12.14.5z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://www.linkedin.com/shareArticle?mini=true&amp;url=http%3A%2F%2Fsharingbuttons.io&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;summary=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;source=http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="mailto:?subject=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;body=http%3A%2F%2Fsharingbuttons.io"
                        target="_self" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--email resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://reddit.com/submit/?url=http%3A%2F%2Fsharingbuttons.io&amp;resubmit=true&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--reddit resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M24 11.5c0-1.65-1.35-3-3-3-.96 0-1.86.48-2.42 1.24-1.64-1-3.75-1.64-6.07-1.72.08-1.1.4-3.05 1.52-3.7.72-.4 1.73-.24 3 .5C17.2 6.3 18.46 7.5 20 7.5c1.65 0 3-1.35 3-3s-1.35-3-3-3c-1.38 0-2.54.94-2.88 2.22-1.43-.72-2.64-.8-3.6-.25-1.64.94-1.95 3.47-2 4.55-2.33.08-4.45.7-6.1 1.72C4.86 8.98 3.96 8.5 3 8.5c-1.65 0-3 1.35-3 3 0 1.32.84 2.44 2.05 2.84-.03.22-.05.44-.05.66 0 3.86 4.5 7 10 7s10-3.14 10-7c0-.22-.02-.44-.05-.66 1.2-.4 2.05-1.54 2.05-2.84zM2.3 13.37C1.5 13.07 1 12.35 1 11.5c0-1.1.9-2 2-2 .64 0 1.22.32 1.6.82-1.1.85-1.92 1.9-2.3 3.05zm3.7.13c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9.8 4.8c-1.08.63-2.42.96-3.8.96-1.4 0-2.74-.34-3.8-.95-.24-.13-.32-.44-.2-.68.15-.24.46-.32.7-.18 1.83 1.06 4.76 1.06 6.6 0 .23-.13.53-.05.67.2.14.23.06.54-.18.67zm.2-2.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5.7-2.13c-.38-1.16-1.2-2.2-2.3-3.05.38-.5.97-.82 1.6-.82 1.1 0 2 .9 2 2 0 .84-.53 1.57-1.3 1.87z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://www.xing.com/app/user?op=share;url=http%3A%2F%2Fsharingbuttons.io;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--xing resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M10.2 9.7l-3-5.4C7.2 4 7 4 6.8 4h-5c-.3 0-.4 0-.5.2v.5L4 10 .4 16v.5c0 .2.2.3.4.3h5c.3 0 .4 0 .5-.2l4-6.6v-.5zM24 .2l-.5-.2H18s-.2 0-.3.3l-8 14v.4l5.2 9c0 .2 0 .3.3.3h5.4s.3 0 .4-.2c.2-.2.2-.4 0-.5l-5-8.8L24 .7V.2z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="whatsapp://send?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.%20http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://news.ycombinator.com/submitlink?u=http%3A%2F%2Fsharingbuttons.io&amp;t=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--hackernews resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
                              <path fill-rule="evenodd"
                                d="M60.94 82.314L17 0h20.08l25.85 52.093c.397.927.86 1.888 1.39 2.883.53.994.995 2.02 1.393 3.08.265.4.463.764.596 1.095.13.334.262.63.395.898.662 1.325 1.26 2.618 1.79 3.877.53 1.26.993 2.42 1.39 3.48 1.06-2.254 2.22-4.673 3.48-7.258 1.26-2.585 2.552-5.27 3.877-8.052L103.49 0h18.69L77.84 83.308v53.087h-16.9v-54.08z">
                              </path>
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="http://vk.com/share.php?title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--vk resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M21.547 7h-3.29a.743.743 0 0 0-.655.392s-1.312 2.416-1.734 3.23C14.734 12.813 14 12.126 14 11.11V7.603A1.104 1.104 0 0 0 12.896 6.5h-2.474a1.982 1.982 0 0 0-1.75.813s1.255-.204 1.255 1.49c0 .42.022 1.626.04 2.64a.73.73 0 0 1-1.272.503 21.54 21.54 0 0 1-2.498-4.543.693.693 0 0 0-.63-.403h-2.99a.508.508 0 0 0-.48.685C3.005 10.175 6.918 18 11.38 18h1.878a.742.742 0 0 0 .742-.742v-1.135a.73.73 0 0 1 1.23-.53l2.247 2.112a1.09 1.09 0 0 0 .746.295h2.953c1.424 0 1.424-.988.647-1.753-.546-.538-2.518-2.617-2.518-2.617a1.02 1.02 0 0 1-.078-1.323c.637-.84 1.68-2.212 2.122-2.8.603-.804 1.697-2.507.197-2.507z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://telegram.me/share/url?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--telegram resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> -->

              <div class="dropdown-menu shareWidth guestShare" [class.visibleShare]="toggleviewlist" role="menu"
                *ngIf="isGuestUser" [ngStyle]="{ display: toggleviewlist ? 'flex' : 'none' }">
                <div class="shareAllPanel guestShareCls">
                  <h4>{{ "Detail.shareExternalUsers" | translate }}</h4>
                  <p>{{ "Detail.wileyExternalShareContent" | translate }}</p>
                  <a href="{{ shareUrl }}" (click)="$event.preventDefault(); $event.stopPropagation()">{{ shareUrl
                    }}</a>
                  <ul [class.arabic-style]="isArabicLanguage" (click)="$event.stopPropagation()" id="shareIconsWidth">
                    <li style="display: inline-flex">
                      <a class="resp-sharing-button__link mailBtns" (click)="shareMailClick(shareUrl)"
                        title="{{ 'Detail.shareByMail' | translate }}">
                        <i class="fa fa-envelope copyIcon"></i>
                        <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ "Detail.mailIcon" | translate
                          }}</span>
                      </a>
                      <a class="resp-sharing-button__link copyBtn" (click)="copyShareUrl(shareUrl)"
                        title="{{ 'Detail.copytoClipboard' | translate }}">
                        <i class="fa fa-files-o fa-lg copyIcon" aria-hidden="true"></i>
                        <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ "Common.Copy" | translate
                          }}</span>
                      </a>
                      <a class="resp-sharing-button__link"
                        href="https://facebook.com/sharer/sharer.php?u=http%3A%2F%2Fsharingbuttons.io" target="_blank"
                        rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton Twitter -->
                      <a class="resp-sharing-button__link"
                        href="https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton Tumblr -->
                      <a class="resp-sharing-button__link"
                        href="https://www.tumblr.com/widgets/share/tool?posttype=link&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;caption=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;content=http%3A%2F%2Fsharingbuttons.io&amp;canonicalUrl=http%3A%2F%2Fsharingbuttons.io&amp;shareSource=tumblr_share_button"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--tumblr resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M13.5.5v5h5v4h-5V15c0 5 3.5 4.4 6 2.8v4.4c-6.7 3.2-12 0-12-4.2V9.5h-3V6.7c1-.3 2.2-.7 3-1.3.5-.5 1-1.2 1.4-2 .3-.7.6-1.7.7-3h3.8z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton Pinterest -->
                      <a class="resp-sharing-button__link"
                        href="https://pinterest.com/pin/create/button/?url=http%3A%2F%2Fsharingbuttons.io&amp;media=http%3A%2F%2Fsharingbuttons.io&amp;description=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--pinterest resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M12.14.5C5.86.5 2.7 5 2.7 8.75c0 2.27.86 4.3 2.7 5.05.3.12.57 0 .66-.33l.27-1.06c.1-.32.06-.44-.2-.73-.52-.62-.86-1.44-.86-2.6 0-3.33 2.5-6.32 6.5-6.32 3.55 0 5.5 2.17 5.5 5.07 0 3.8-1.7 7.02-4.2 7.02-1.37 0-2.4-1.14-2.07-2.54.4-1.68 1.16-3.48 1.16-4.7 0-1.07-.58-1.98-1.78-1.98-1.4 0-2.55 1.47-2.55 3.42 0 1.25.43 2.1.43 2.1l-1.7 7.2c-.5 2.13-.08 4.75-.04 5 .02.17.22.2.3.1.14-.18 1.82-2.26 2.4-4.33.16-.58.93-3.63.93-3.63.45.88 1.8 1.65 3.22 1.65 4.25 0 7.13-3.87 7.13-9.05C20.5 4.15 17.18.5 12.14.5z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton LinkedIn -->
                      <a class="resp-sharing-button__link"
                        href="https://www.linkedin.com/shareArticle?mini=true&amp;url=http%3A%2F%2Fsharingbuttons.io&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;summary=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;source=http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton E-Mail -->
                      <a class="resp-sharing-button__link"
                        href="mailto:?subject=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;body=http%3A%2F%2Fsharingbuttons.io"
                        target="_self" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--email resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton Reddit -->
                      <a class="resp-sharing-button__link"
                        href="https://reddit.com/submit/?url=http%3A%2F%2Fsharingbuttons.io&amp;resubmit=true&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--reddit resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M24 11.5c0-1.65-1.35-3-3-3-.96 0-1.86.48-2.42 1.24-1.64-1-3.75-1.64-6.07-1.72.08-1.1.4-3.05 1.52-3.7.72-.4 1.73-.24 3 .5C17.2 6.3 18.46 7.5 20 7.5c1.65 0 3-1.35 3-3s-1.35-3-3-3c-1.38 0-2.54.94-2.88 2.22-1.43-.72-2.64-.8-3.6-.25-1.64.94-1.95 3.47-2 4.55-2.33.08-4.45.7-6.1 1.72C4.86 8.98 3.96 8.5 3 8.5c-1.65 0-3 1.35-3 3 0 1.32.84 2.44 2.05 2.84-.03.22-.05.44-.05.66 0 3.86 4.5 7 10 7s10-3.14 10-7c0-.22-.02-.44-.05-.66 1.2-.4 2.05-1.54 2.05-2.84zM2.3 13.37C1.5 13.07 1 12.35 1 11.5c0-1.1.9-2 2-2 .64 0 1.22.32 1.6.82-1.1.85-1.92 1.9-2.3 3.05zm3.7.13c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9.8 4.8c-1.08.63-2.42.96-3.8.96-1.4 0-2.74-.34-3.8-.95-.24-.13-.32-.44-.2-.68.15-.24.46-.32.7-.18 1.83 1.06 4.76 1.06 6.6 0 .23-.13.53-.05.67.2.14.23.06.54-.18.67zm.2-2.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5.7-2.13c-.38-1.16-1.2-2.2-2.3-3.05.38-.5.97-.82 1.6-.82 1.1 0 2 .9 2 2 0 .84-.53 1.57-1.3 1.87z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton XING -->
                      <a class="resp-sharing-button__link"
                        href="https://www.xing.com/app/user?op=share;url=http%3A%2F%2Fsharingbuttons.io;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--xing resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M10.2 9.7l-3-5.4C7.2 4 7 4 6.8 4h-5c-.3 0-.4 0-.5.2v.5L4 10 .4 16v.5c0 .2.2.3.4.3h5c.3 0 .4 0 .5-.2l4-6.6v-.5zM24 .2l-.5-.2H18s-.2 0-.3.3l-8 14v.4l5.2 9c0 .2 0 .3.3.3h5.4s.3 0 .4-.2c.2-.2.2-.4 0-.5l-5-8.8L24 .7V.2z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton WhatsApp -->
                      <a class="resp-sharing-button__link"
                        href="whatsapp://send?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.%20http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton Hacker News -->
                      <a class="resp-sharing-button__link"
                        href="https://news.ycombinator.com/submitlink?u=http%3A%2F%2Fsharingbuttons.io&amp;t=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--hackernews resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
                              <path fill-rule="evenodd"
                                d="M60.94 82.314L17 0h20.08l25.85 52.093c.397.927.86 1.888 1.39 2.883.53.994.995 2.02 1.393 3.08.265.4.463.764.596 1.095.13.334.262.63.395.898.662 1.325 1.26 2.618 1.79 3.877.53 1.26.993 2.42 1.39 3.48 1.06-2.254 2.22-4.673 3.48-7.258 1.26-2.585 2.552-5.27 3.877-8.052L103.49 0h18.69L77.84 83.308v53.087h-16.9v-54.08z">
                              </path>
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton VK -->
                      <a class="resp-sharing-button__link"
                        href="http://vk.com/share.php?title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--vk resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M21.547 7h-3.29a.743.743 0 0 0-.655.392s-1.312 2.416-1.734 3.23C14.734 12.813 14 12.126 14 11.11V7.603A1.104 1.104 0 0 0 12.896 6.5h-2.474a1.982 1.982 0 0 0-1.75.813s1.255-.204 1.255 1.49c0 .42.022 1.626.04 2.64a.73.73 0 0 1-1.272.503 21.54 21.54 0 0 1-2.498-4.543.693.693 0 0 0-.63-.403h-2.99a.508.508 0 0 0-.48.685C3.005 10.175 6.918 18 11.38 18h1.878a.742.742 0 0 0 .742-.742v-1.135a.73.73 0 0 1 1.23-.53l2.247 2.112a1.09 1.09 0 0 0 .746.295h2.953c1.424 0 1.424-.988.647-1.753-.546-.538-2.518-2.617-2.518-2.617a1.02 1.02 0 0 1-.078-1.323c.637-.84 1.68-2.212 2.122-2.8.603-.804 1.697-2.507.197-2.507z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <!-- Sharingbutton Telegram -->
                      <a class="resp-sharing-button__link"
                        href="https://telegram.me/share/url?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
                        target="_blank" rel="noopener" aria-label="">
                        <div class="resp-sharing-button resp-sharing-button--telegram resp-sharing-button--small">
                          <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path
                                d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- </ul> -->
            </li>
            <li (click)="citationClick()">
              <a title="{{ 'Detail.citationTools' | translate }} ({{
                controlShortcut
              }} + O)" href="javascript:void(0)">
                <span class="spriteico citationIcon"></span>
              </a>
            </li>
            <ng-container *ngIf="!isGuestUser && !isGuestShare">
              <li *ngIf="
                viewList[documentData?.docType]
                  ? getJSONKeys(viewList[documentData?.docType])?.indexOf(
                      documentData?.wileyId
                    ) > -1
                  : false
              " (click)="listClick(documentData)">
                <a title="{{ 'SearchResult.RemoveFromList' | translate }}" href="javascript:void(0)">
                  <span class="spriteico removelistIcon"></span>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="!isGuestUser && !isGuestShare">
              <li *ngIf="
                viewList[documentData?.docType]
                  ? getJSONKeys(viewList[documentData?.docType])?.indexOf(
                      documentData?.wileyId
                    ) == -1
                  : true
              " (click)="listClick(documentData)">
                <a title="{{ 'SearchResult.AddToList' | translate }} ({{
                  controlShortcut
                }} + L)" href="javascript:void(0)">
                  <span class="spriteico addlistIcon"></span>
                </a>
              </li>
            </ng-container>
            <!-- isMainImageLoaded -->
            <li class="backToSearchResults mobile-backtoSearchResult">
              <ng-container *ngIf="
                !isGuestUser &&
                !isFromResultPage &&
                !isFromTermGroupsPage &&
                !isFromMapExplorerPage &&
                !isFromPhotoExplorerPage &&
                !isFromCollection &&
                !isFromConcordance
              ">
                <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
                <a href="javascript:void(0)" *ngIf="!hasDocumentAccess" class="backto-homelink" (click)="gotoHome()"
                  title="{{ 'Detail.backToSearchPage' | translate }}">{{ "Detail.backToSearchPage" | translate }}
                </a>
                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                  *ngIf="hasDocumentAccess && !isFromResultPage" class="backto-homelink" (click)="backToSearchResults()"
                  title="{{ 'Common.BackToSearchResults' | translate }} ({{
                  controlShortcut
                }} + Z)">
                  {{ "Common.BackToSearchResults" | translate }}
                </a>
              </ng-container>
              <ng-container *ngIf="
                !isGuestUser &&
                isFromResultPage &&
                !isFromTermGroupsPage &&
                !isFromMapExplorerPage &&
                !isFromPhotoExplorerPage &&
                !isFromCollection &&
                !isFromConcordance
              ">
                <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink"
                  (click)="backToSearchResultsPage(!isRefershPage)" title="{{ 'Common.BackToSearchResults' | translate }} ({{
                  controlShortcut
                }} + Z)">
                  {{ "Common.BackToSearchResults" | translate }}
                </a>
              </ng-container>
              <ng-container *ngIf="
                isFromTermGroupsPage &&
                !isGuestUser &&
                !isFromMapExplorerPage &&
                !isFromPhotoExplorerPage &&
                !isFromCollection
              ">
                <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink"
                  (click)="backToTermGroups()" title="{{ 'SearchResult.backtoTermGroups' | translate }}">{{
                  "SearchResult.backtoTermGroups" | translate }}
                </a>
              </ng-container>
              <ng-container *ngIf="
                !isFromTermGroupsPage &&
                !isGuestUser &&
                !isFromMapExplorerPage &&
                isFromPhotoExplorerPage &&
                !isFromCollection
              ">
                <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink"
                  (click)="backToPhotoExplorer(spatialFilterParams)"
                  title="{{ 'SearchResult.backToPhotoExplorer' | translate }}">{{ "SearchResult.backToPhotoExplorer" |
                  translate }}
                </a>
              </ng-container>
              <ng-container *ngIf="
                !isFromCollection &&
                !isFromTermGroupsPage &&
                !isGuestUser &&
                isFromMapExplorerPage &&
                !isFromPhotoExplorerPage
              ">
                <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink"
                  (click)="backToMapExplorer(spatialFilterParams)"
                  title="{{ 'SearchResult.backToMapExplorer' | translate }}">{{ "SearchResult.backToMapExplorer" |
                  translate }}
                </a>
              </ng-container>

              <ng-container *ngIf="
                isFromCollection &&
                !isFromTermGroupsPage &&
                !isGuestUser &&
                !isFromMapExplorerPage &&
                !isFromPhotoExplorerPage
              ">
                <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink"
                  (click)="backToCollection()" title="{{ 'SearchResult.backtoCollections' | translate }}">{{
                  "SearchResult.backtoCollections" | translate }}
                </a>
              </ng-container>
              <!-- <ng-container *ngIf="isFromFrequencyDistribution && !isGuestUser">
                <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink"
                  (click)="backToFrequencyDistribution()" title="{{
                  'SearchResult.backToFrequencyDistribution' | translate
                }}">{{ "SearchResult.backToFrequencyDistribution" | translate }}
                </a>
              </ng-container> -->
              <ng-container *ngIf="isFromConcordance && !isGuestUser">
                <i class="fa fa-angle-left backto-homeicn" aria-hidden="true"></i>
                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="backto-homelink"
                  (click)="backToConcordance()" title="{{ 'SearchResult.backToConcordance' | translate }}">{{
                  "SearchResult.backToConcordance" | translate }}
                </a>
              </ng-container>
            </li>
            <ng-container *ngIf="!isGuestUser && !guestAuthToken">
              <li class="mobile-backtoSearchList" *ngIf="
                isFromResultPage &&
                !isFromCollection &&
                !isFromTermGroupsPage &&
                !isFromMapExplorerPage &&
                !isFromPhotoExplorerPage &&
                !isFromConcordance
              ">
                <span (click)="
                  getSearchResultsBasedonParams(documentData?.docType);
                  $event.stopPropagation()
                ">
                  <span class="spriteico backtoSearchIcon mobile-backtoSearchIcon" title="{{ 'SearchResult.SearchResults' | translate }} ({{
                    shortcutKeyTitle
                  }} + Down Arrow)"></span>
                </span>
              </li>
              <!-- <li
              *ngIf="!isFromResultPage  && !isFromTermGroupsPage && !isFromMapExplorerPage && !isFromPhotoExplorerPage && !isFromCollection && !isFromFrequencyDistribution && !isFromConcordance">
              <span (click)="getSearchResultsBasedonParams(documentData?.docType);$event.stopPropagation();">
                <span class="spriteico backtoSearchIcon"
                  title="{{ 'SearchResult.SearchResults' | translate }} ({{shortcutKeyTitle}} + Down Arrow)"></span>
              </span>
            </li> -->
              <li class="mobile-backtoSearchList" *ngIf="
                isFromPhotoExplorerPage &&
                !isFromResultPage &&
                !isFromTermGroupsPage &&
                !isFromMapExplorerPage &&
                !isFromCollection &&
                !isFromConcordance
              ">
                <span (click)="
                  getSearchResultsFromPhotoExplorer();
                  $event.stopPropagation()
                ">
                  <span class="spriteico backtoSearchIcon mobile-backtoSearchIcon"
                    title="{{ 'SearchResult.SearchResults' | translate }}"></span>
                </span>
              </li>
              <li class="mobile-backtoSearchList" *ngIf="
                isFromMapExplorerPage &&
                !isFromResultPage &&
                !isFromTermGroupsPage &&
                !isFromPhotoExplorerPage &&
                !isFromCollection &&
                !isFromConcordance
              ">
                <span (click)="
                  getSearchResultsFromMapExplorer(); $event.stopPropagation()
                ">
                  <span class="spriteico backtoSearchIcon mobile-backtoSearchIcon"
                    title="{{ 'SearchResult.SearchResults' | translate }}"></span>
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="col-md-2 padding-0 slim-strip-container leftmenu-slimstrip" [class.toggled]="toggleMenu"
      [class.gridview-images]="!isGridViewEnable">
      <div class="relevant-image-frame-loader" [ngStyle]="{ display: searchWithInLoading ? 'block' : 'none' }">
        <img alt="{{ 'Alt.loaderImage' | translate }}" class="relevant-img-icon" src="assets/images/three-dots.svg" />
      </div>
      <div class="col-xs-12 padding-r0 toggle-left-menu">
        <span *ngIf="toggleMenu" title="{{ 'Detail.expandLeftMenu' | translate }} ({{
            shortcutKeyTitle
          }} + Z)" class="toggle-icon" [class.toggled]="toggleMenu" (click)="menuToggle()">
          <i class="fa-2x fa fa-angle-right" aria-hidden="true"></i>
        </span>
        <span *ngIf="!toggleMenu" title="{{ 'Detail.collapseLeftMenu' | translate }} ({{
            controlShortcut
          }} + E)" class="toggle-icon" [class.toggled]="toggleMenu" (click)="menuToggle()">
          <i class="fa-2x fa fa-angle-left" aria-hidden="true"></i>
        </span>
        <tabset #leftMenuTabset id="leftMenuTab" [vertical]="true" type="pills" [class.toggled-menu-list]="toggleMenu">
          <tab (selectTab)="
              selectPagesTab(); toggleMenu = false; itemTabEnable = false
            " [customClass]="'doc-tabs'" heading="" *ngIf="true" id="resultViewTab">
            <ng-template tabHeading>
              <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.resultsView' | translate }} ({{
                  shortcutKeyTitle
                }} + '/')">
                <span class="spriteico documentIcon"></span>
                <p class="tabicon-title">
                  {{ "Detail.resultsView" | translate }}
                </p>
              </label>
            </ng-template>
            <div class="tab-pane active" [class.toggled]="toggleMenu">
              <div class="col-md-12 toggle-container result-view">
                <div class="document-view document-parent">
                  <h4 [class.arabic-style]="isArabicLanguage"
                    class="pages-title socure-citation-linkbtn ellpisis-for-title"
                    title="{{ 'Detail.resultsView' | translate }}">
                    {{ "Detail.resultsView" | translate }}
                  </h4>

                  <div class="doc-right">
                    <div class="btn-group" *ngIf="!showDocSwitch">
                      <label [class.arabic-style]="isArabicLanguage" class="btn doc-fultext-btn ellpisis-for-label fntSize"
                        title="{{ 'Detail.showImageTooltip' | translate }} ({{
                          controlShortcut
                        }} + K)" [class.activeTab]="documentViewer" btnCheckbox tabindex="0"
                        (click)="changetoFullText(checkModel)" role="button">{{ "Detail.showImage" | translate
                        }}</label>
                      <label [class.atr-available]="
                          documentData?.processType === 'atr'
                        " [class.arabic-style]="isArabicLanguage" *ngIf="!fulltextDisable"
                        class="btn doc-fultext-btn doc-fulltext ellpisis-for-label fntSize" title="{{
                          'Detail.showFullTextTooltip' | translate
                        }} ({{ controlShortcut }} + P)" [class.activeTab]="!documentViewer" btnCheckbox tabindex="1"
                        (click)="doThisOnSelect(checkModel)" role="button">{{ "Detail.shortFullText" | translate }}
                        <img *ngIf="
                            documentData?.processType === 'atr' &&
                            documentViewer
                          " (mouseenter)="atrMouseEnter()" (mouseleave)="atrMouseLeave()" alt="ATR Icon"
                          class="atr-img-icon" src="assets/images/icons/ATR-Display1.png" />
                        <img *ngIf="
                            documentData?.processType === 'atr' &&
                            isFullTextActive
                          " (mouseenter)="atrMouseEnter()" (mouseleave)="atrMouseLeave()" alt="ATR Icon"
                          class="atr-img-icon" src="assets/images/icons/ATR-Display-Selected.png" />
                      </label>
                      <div class="atr-tooltip-content" *ngIf="showATRContent">
                        This handwritten document is searchable
                      </div>

                      <label [class.arabic-style]="isArabicLanguage" *ngIf="fulltextDisable"
                        class="btn doc-fultext-btn doc-fulltext text-disable ellpisis-for-label" title="{{ 'Detail.noFullText' | translate }} ({{
                          controlShortcut
                        }} + P)" [class.activeTab]="!documentViewer" btnCheckbox tabindex="1" role="button">{{
                        "Detail.shortFullText" | translate }}</label>
                    </div>
                    <div class="btn-group" *ngIf="showDocSwitch">
                      <label [class.arabic-style]="isArabicLanguage" class="btn doc-fultext-btn ellpisis-for-label"
                        [class.activeTab]="documentViewer" btnCheckbox tabindex="0"
                        (click)="changetoFullText(checkModel)" role="button">{{ "Detail.shortDocument" | translate
                        }}</label>
                      <label [class.arabic-style]="isArabicLanguage"
                        class="btn doc-fultext-btn doc-fulltext ellpisis-for-label" [class.activeTab]="!documentViewer"
                        btnCheckbox tabindex="1" (click)="doThisOnSelect(checkModel)" role="button">{{
                        "Detail.shortFullText" | translate }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 document-tab-view position-relative"  *ngIf="!showFillterByRelevantPage">
                <div class="document-view-pagination">
                  <p *ngIf="documentImagesCount?.length === 0;else other_content">
                    {{ "Common.Popups.pageLoadinFailed" | translate }}
                  </p>
                  <ng-template #other_content>
                    <p *ngIf="showNoCOntentMessage">
                      {{ "Common.Popups.contentLoadFailed" | translate }}
                    </p>
                  </ng-template>
                  <ul
                    class="document-view-pagination-list"
                    *ngIf="documentImagesCount?.length > 0">
                    <li title="{{ 'Detail.perviousPage' | translate }}">
                      <span (click)="gotoPreviousDocumentFullText(selectedPageId)" *ngIf="
                          !documentCurrentIndex || documentCurrentIndex > 1
                        ">
                        <i class="fa fa-chevron-left document-larrow"></i>
                      </span>
                    </li>
                    <li [class.arabic-style]="isArabicLanguage" class="page-section"
                      title="{{ 'Detail.image' | translate }}">
                      {{ "Detail.image" | translate }}
                    </li>
                    <li>
                      <input type="text" class="pagenum-input" (keypress)="keyPress($event)"
                        [ngModel]="documentCurrentIndex" (keyup.enter)="gotoCurrentDocumentFulltext($event)" />
                    </li>
                    <li [class.arabic-style]="isArabicLanguage" class="page-section page-of pageof-ellipsis"
                      title="{{ 'Detail.OF' | translate }}">
                      {{ "Detail.OF" | translate }}
                    </li>
                    <li [class.arabic-style]="isArabicLanguage"
                      class="page-section total-pages pageof-ellipsis totalpage-no"
                      title="{{ currentDocumentImages.length }}">
                      {{ currentDocumentImages.length }}
                    </li>
                    <li *ngIf="
                        currentDocumentImages.length != documentCurrentIndex
                      " (click)="gotoNextDocumentFullText(selectedPageId)" title="{{ 'Detail.nextPage' | translate }}">
                      <i class="fa fa-chevron-right document-rarrow"></i>
                    </li>

                    <span class="gridview-thumbnails" (click)="girdViewThumBImages()">
                      <span *ngIf="
                        isGridViewEnable && currentDocumentImages.length > 15
                      " title="{{ 'Detail.expandToRight' | translate }} ({{
                        shortcutKeyTitle
                      }} + G)" class="spriteico expandToRightIcon grid-thumbnails">
                      </span>
                    </span>
                    <span class="gridview-thumbnails" (click)="listViewThumBImages()">
                      <span *ngIf="!isGridViewEnable" title="{{ 'Detail.contractToLeft' | translate }} ({{
                        shortcutKeyTitle
                      }} + G)" class="spriteico contractToRightIcon grid-thumbnails">
                      </span>
                    </span>
                  </ul>
                </div>
              </div>

              <div [class.arabic-style]="isArabicLanguage" *ngIf="pageFullTextObj?.length > 0 && documentTitleReceived"
                class="filter-by-text">
                <a href="javascript:void(0)" class="toggle-filter" (click)="toggleFilter()">
                  <!-- <span [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.showAllPages' | translate }}"
                    *ngIf="showFillterByRelevantPage">
                    {{ "Detail.showAllPages" | translate }}
                  </span>
                  <span [class.arabic-style]="isArabicLanguage" title=" {{ 'Detail.relevantPages' | translate }}"
                    *ngIf="!showFillterByRelevantPage">
                    {{ "Detail.relevantPages" | translate }}
                  </span> -->
                  <button class="filterByRelevanceBtn" [class.arabic-style]="isArabicLanguage"
                   *ngIf="showFillterByRelevantPage">
                    <i class="fa fa-image" ></i>
                    {{ "Detail.showAllPages" | translate }}
                  </button>
                  <button class="filterByRelevanceBtn" [class.arabic-style]="isArabicLanguage"
                   *ngIf="!showFillterByRelevantPage">
                    <i class="fa fa-filter" ></i>
                    {{ "Detail.relevantPages" | translate }}
                  </button>
                </a>
              </div>
              <div *ngIf="showFillterByRelevantPage" class="filter-relevantpages-div">
                <div class="relevant-pages-div margin-0">
                  <!-- <perfect-scrollbar [config]="config" class="relevant-pages-div margin-0"> -->
                  <!-- <ng-container *ngFor="let image of documentPageImages; let i = index;">
                    <div *ngIf="image" (click)="loadRelaventPage(image.imageId, $event)" class="image-container relevant-image-container-div"
                      [ngClass]="{'active': documentCurrentIndex === documentPageImages[i]?.pageNumber}">
                      <ng-container>
                        <img name='Document-image' [title]="image?.title" class="relevant-image-container center-block" alt="Document Image" src="{{image?.thumbURL + '?type=small&access_token=' + accessToken}}">
                      </ng-container>
                      <div [class.arabic-style]="isArabicLanguage" class="relevant-sequence-number">
                        {{documentPageImages[i]?.pageNumber}}</div>
                    </div>
                  </ng-container> -->
                  <ng-container *ngFor="let image of pageFullTextObj; let i = index">
                    <div *ngIf="image" (click)="
                        loadRelaventPage(
                          image.pageId,
                          $event,
                          documentSearchTermSplitted
                        )
                      " class="image-container relevant-image-container-div" [ngClass]="{
                        active:
                          documentCurrentIndex ===
                          getImagePageNumber(image.pageId)
                      }">
                      <!--  -->
                      <ng-container>
                        <!-- [title]="getImageTitleForThumbs(image.pageId)" -->
                        <img name="Document-image" class="relevant-image-container center-block" alt="Document Image"
                          src="{{
                            getSimpleImageURl(image?.pageId) +
                              '?type=small&access_token=' +
                              accessToken
                          }}" />
                      </ng-container>
                      <div class="relevant-sequence-number">
                        {{ getImagePageNumber(image.pageId) }}
                      </div>
                    </div>
                  </ng-container>
                  <!-- </perfect-scrollbar> -->
                </div>
              </div>

              <div *ngIf="!showFillterByRelevantPage" class="relevantpages-div">
                <div [class.arabic-style]="isArabicLanguage" *ngIf="currentDocumentImages?.length == 0">
                  <p *ngIf="showRelavantPagesLoader" [class.arabic-style]="isArabicLanguage">
                    {{ "SearchResult.loadingPleaseWait" | translate }}
                  </p>
                </div>
                <app-flim-strip [documentImages]="currentDocumentImages" [currentActiveIndex]="documentCurrentIndex"
                  [documentTitleImages]="documentImagesWithTitles"
                  (currentActiveImageIndex)="setCurrentActiveImageIndex($event)"
                  [gridViewThumbActive]="gridViewThumbActive" [listViewThumbActive]="listViewThumbActive"
                  [scrollTo]="scrollToPosition" [passNewOverlayValue]="passNewOverlayValue"></app-flim-strip>
              </div>
            </div>
          </tab>
          <tab (selectTab)="
              selectListView(); toggleMenu = false; itemTabEnable = false
            " [customClass]="'citation-tabs'" heading="" *ngIf="true" id="citationTab">
            <ng-template tabHeading>
              <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.Citation' | translate }} ({{
                  shortcutKeyTitle
                }} + J)">
                <span class="spriteico citationIcon"></span>
                <p [class.arabic-style]="isArabicLanguage" class="tabicon-title">
                  {{ "Detail.Citation" | translate }}
                </p>
              </label>
            </ng-template>

            <div class="tab-pane" [class.toggled]="toggleMenu">
              <div class="row">
                <div class="col-md-12">
                  <h4 title="{{ 'Detail.Citation' | translate }}" [class.arabic-style]="isArabicLanguage"
                    class="reletaed-subject-heading pull-left socure-citation-linkbtn">
                    {{ "Detail.Citation" | translate }}
                  </h4>
                  <button *ngIf="documentImagesCount?.length > 0" title="{{ 'Detail.sourceCitation' | translate }}"
                    [class.arabic-style]="isArabicLanguage" class="btn btn-primary source-citation-btn pull-right"
                    (click)="openSoureceCitation()">
                    {{ "Detail.sourceCitation" | translate }}
                  </button>
                </div>
                <div class="col-md-12" *ngIf="documentImagesCount?.length > 0">
                  <div class="document-tab-view" #container>
                    <!-- <perfect-scrollbar [config]="config" class="document-tab-view" #container> -->
                    <div class="col-md-12 col-sm-12 padding-left-0 padding-top15">
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            "Detail.documentTitle" | translate }}</label>
                          <h4 class="citation-content">
                            {{ documentData?.title | unicodeencodeEntity }}
                          </h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.manuscriptNo">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            "Detail.referenceNo" | translate }}</label>
                          <h4 class="citation-content">
                            {{ documentData?.manuscriptNo }}
                          </h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="
                            documentData?.shortName === 'RAI' &&
                            documentData?.boxNo
                          ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ "Detail.boxNo" |
                            translate }}</label>
                          <h4 class="citation-content">
                            {{ documentData?.boxNo }}
                          </h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.barcode">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ "Detail.barcode"
                            | translate }}</label>
                          <h4 class="citation-content">
                            {{ documentData?.barcode }}
                          </h4>
                        </div>
                        <div *ngIf="documentData?.authors?.length > 0"
                          class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ "Detail.Authors"
                            | translate }}</label>
                          <div class="author-containor">
                            <ng-container *ngFor="
                                let author of documentData?.authors;
                                let last = last
                              ">
                              <ng-container *ngIf="!isGuestUser">
                                <a class="citation-content author-link font_18" title="{{ author }}"
                                  (click)="gotoAuthorDetail(author)">{{ author }}
                                  <span class="author-comma" *ngIf="!last">,
                                  </span>
                                </a>
                              </ng-container>
                              <ng-container *ngIf="isGuestUser">
                                <span title="{{ author }}" class="citation-content m-r-5 font_18">{{ author }}
                                  <span class="author-comma" *ngIf="!last">,
                                  </span>
                                </span>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.itemAuthors">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            "Detail.NameReferences" | translate }}</label>
                          <h4 class="citation-content">
                            <ng-container *ngFor="
                                let itemAuther of documentData?.itemAuthors;
                                let last = last
                              ">
                              <ng-container *ngIf="isGuestUser">
                                {{ itemAuther }}
                                <span *ngIf="!last">, </span>
                              </ng-container>
                              <ng-container *ngIf="!isGuestUser">
                                <a class="citation-content author-link font_18" title="{{ itemAuther }}"
                                  (click)="gotoAuthorDetail(itemAuther)">{{ itemAuther }}
                                  <span class="author-comma" *ngIf="!last">,
                                  </span>
                                </a>
                              </ng-container>
                            </ng-container>
                          </h4>
                        </div>
                        <div *ngIf="
                            (documentData?.authors?.length === 0 ||
                              documentData?.authors?.length === '' ||
                              documentData?.authors?.length === null) &&
                            documentData?.shortName === 'RCP'
                          " class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ "Detail.Authors"
                            | translate }}</label>
                          <div class="author-containor">
                            <span class="info-author">
                              {{ "Detail.informationNotAvailable" | translate }}
                            </span>
                          </div>
                        </div>
                        <div *ngIf="
                            (documentData?.authors?.length === 0 ||
                              documentData?.authors?.length === '' ||
                              documentData?.authors?.length === null) &&
                            documentData?.shortName === 'BAAS' &&
                            documentData?.docType == 'Monographs'
                          " class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ "Detail.Authors"
                            | translate }}</label>
                          <div class="author-containor">
                            <span class="info-author">
                              {{ "Detail.informationNotAvailable" | translate }}
                            </span>
                          </div>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.pubDate !== null">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            "HomePage.publicationDate" | translate }}</label>
                          <h4 class="citation-content">
                            {{ documentData?.pubDate }}
                          </h4>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            "Detail.Language" | translate }}</label>
                          <ng-container *ngFor="
                              let language of documentData?.languages;
                              let last = last
                            ">
                            <span title="{{ language }}" class="citation-content m-r-5 font_18">{{ language }}
                              <span class="author-comma" *ngIf="!last">, </span>
                            </span>
                          </ng-container>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20"
                          *ngIf="documentData?.collection !== null">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            "Detail.Collection" | translate }}
                          </label>
                          <a class="citation-content collection-link font_18" href="javascript:void(0)" (click)="
                              gotoCollectionDetails(
                                documentData?.collectionNumber
                              )
                            ">
                            {{ documentData?.collection }}
                          </a>
                        </div>
                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                            "Common.ContentType" | translate }}</label>
                          <h4 class="citation-content">
                            {{ documentData?.docType }}
                          </h4>
                        </div>

                        <div class="citation-content-div padding-0 margin-top20">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{ "Detail.Source"
                            | translate }}</label>
                          <h4 class="citation-content">
                            {{ documentData?.source }}
                          </h4>
                        </div>
                        <!-- <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.findingAidNumber !==null ">
                          <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">
                            {{ 'Detail.findingAidNumber' | translate }}</label>
                          <h4 class="citation-content">{{documentData?.findingAidNumber}}</h4>
                        </div> -->

                        <ng-container *ngIf="documentData?.placeOfWriting">
                          <div class="citation-content-div padding-0 margin-top20"
                            *ngIf="documentData?.placeOfWriting?.length > 0">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                              "Detail.placeOfWriting" | translate }}</label>

                            <ng-container *ngFor="
                                let place of documentData?.placeOfWriting;
                                let last = last
                              ">
                              <a class="citation-content font_18">{{ place }}
                                <span class="author-comma" *ngIf="!last">,
                                </span>
                              </a>
                            </ng-container>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="documentData?.collectionCreators">
                          <div class="citation-content-div padding-0 margin-top20"
                            *ngIf="documentData?.collectionCreators?.length > 0">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                              "Detail.creaters" | translate }}</label>
                            <h4 class="citation-content" *ngFor="
                                let creator of documentData?.collectionCreators
                              ">
                              {{ creator }}
                            </h4>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="documentData?.shortName === 'SAE'">
                          <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.wileyId">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                              "Detail.saeDocumentNumber" | translate
                              }}</label>
                            <h4 class="citation-content">
                              {{ documentData?.wileyId }}
                            </h4>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="documentData?.shortName !== 'SAE'">
                          <div class="citation-content-div padding-0 margin-top20" *ngIf="documentData?.wileyId">
                            <label [class.arabic-style]="isArabicLanguage" class="citation-contentlbl">{{
                              "Detail.wileyDocumentNumber" | translate
                              }}</label>
                            <h4 class="citation-content">
                              {{ documentData?.wileyId }}
                            </h4>
                          </div>
                        </ng-container>
                     
                    </div>
                    <!-- </perfect-scrollbar> -->
                  </div>
                </div>
              </div>
            </div>
          </tab>
          <tab *ngIf="true" (selectTab)="
              selectListView();
              loadRelevantPagesFromSearchWithinTab();
              toggleMenu = false;
              itemTabEnable = false
            " [customClass]="'searchWithinIcon-tabs'" heading="" id="SearchWithinDocTab">
            <ng-template tabHeading>
              <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.SearchWithinDoc' | translate }} ({{
                  shortcutKeyTitle
                }} + S)">
                <span class="spriteico searchWithinIcon"></span>
                <p [class.arabic-style]="isArabicLanguage" class="tabicon-title">
                  {{ "Detail.searchHeading" | translate }}
                </p>
              </label>
            </ng-template>
            <div class="tab-pane" [class.toggled]="toggleMenu">
              <div>
                <div href="javascript:void(0)" title="{{ 'Detail.SearchWithinDoc' | translate }}">
                  <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading">
                    {{ "Detail.SearchWithinDoc" | translate }}
                  </h4>
                </div>
                <div class="col-md-12 doc-tab-view" *ngIf="documentImagesCount?.length > 0">
                  <div class="col-lg-12 padding-0">
                    <div class="input-group">
                      <label [class.arabic-style]="isArabicLanguage" class="sr-only">{{ "Detail.EnterSearchKeyword" |
                        translate }}</label>

                      <input *ngIf="!mobileView" [class.arabic-style]="isArabicLanguage" #documentSearch type="text"
                        class="form-control" id="document_search" [(ngModel)]="keyword" title="{{ 'Detail.EnterSearchKeyword' | translate }} ({{
                          shortcutKeyTitle
                        }} + W) " autofocus placeholder="{{
                          'Detail.EnterSearchKeyword' | translate
                        }}" maxlength="100" autocomplete="off" (keyup.enter)="searchWithin(keyword)" />

                      <input *ngIf="mobileView" [class.arabic-style]="isArabicLanguage" #documentSearch type="text"
                        class="form-control" id="molile_document_search" [(ngModel)]="keyword" title="{{ 'Detail.EnterSearchKeyword' | translate }} ({{
                          shortcutKeyTitle
                        }} + W) " autofocus placeholder="{{
                          'Detail.EnterSearchKeyword' | translate
                        }}" maxlength="100" autocomplete="off" />

                      <span class="input-group-btn">
                        <button class="btn btn-primary" [class.disabled]="!keyword" type="button"
                          (click)="searchWithin(keyword)">
                          <i class="fa fa-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12 col-xs-12 no-padding">
                    <div *ngIf="
                        documentSearchTermSplitted?.length > 0 &&
                        showFilterbySearchTerms
                      ">
                      <label class="wiley-admin-text check-container">
                        <input type="checkbox" class="disable-label" [ngModel]="toggleSearch"
                          (change)="togglefuzzySearch()" name="active" />
                        <span [class.arabic-style]="isArabicLanguage" class="fuzzy-text">{{ "Detail.ExactMatch" |
                          translate }}</span>
                        <span class="checkmark"></span>
                      </label>
                      <div class="document-search-terms">
                        <label [class.arabic-style]="isArabicLanguage" *ngIf="documentSearchTermSplitted?.length > 0"
                          class="search-term-lbl">
                          {{ "Detail.searchTerm" | translate }}
                        </label>
                        <div class="search-term">
                          <div class="search-term-txt" #container>
                            <!-- <perfect-scrollbar [config]="config" class="search-term-txt" #container> -->
                            <ng-container *ngIf="documentSearchTermSplitted?.length > 0">
                              <span class="doc-search-term-label" *ngFor="let term of documentSearchTermSplitted">
                                <label class="wiley-admin-text check-container" title="{{ term?.value }}">
                                  <input type="checkbox" class="disable-label" [(ngModel)]="term.checked" (change)="
                                      getRelevantPagesBySearchTerm($event)
                                    " name="relevantterm" checked />
                                  <span [class.arabic-style]="isArabicLanguage" class="fuzzy-text doc-search-term">{{
                                    term?.value }}</span>
                                  <span class="checkmark"></span>
                                </label>
                              </span>
                            </ng-container>
                            <!-- </perfect-scrollbar> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="document-view-relavantItems">
                    <div class="col-xs-12 documents-tab-view document-view-parent docu-tab-view"
                      [class.scrollFix]="scrollFix" [class.scrollSix]="scrollSix" [class.scrollFour]="scrollFour"
                      [class.scrollTwo]="scrollTwo">
                      <div class="search-doc" #container>
                        <!-- <perfect-scrollbar [config]="config" class="search-doc" #container> -->
                        <div [class.arabic-style]="isArabicLanguage" *ngIf="isNoRecodesFound && !searchWithInLoading">
                          <p [class.arabic-style]="isArabicLanguage">
                            {{ "SearchResult.NoResultsFound" | translate }}
                          </p>
                        </div>
                        <div [class.arabic-style]="isArabicLanguage" *ngIf="!isNoRecodesFound && searchWithInLoading">
                          <p [class.arabic-style]="isArabicLanguage">
                            {{ "SearchResult.loading" | translate }}
                          </p>
                        </div>

                        <accordion *ngIf="pageFullTextObj?.length > 0">
                          <accordion-group class="details-accordion-header" [isOpen]="true" (isOpenChange)="
                              checkAccordionStatus($event, 'tooglePagesIcon')
                            ">
                            <div [class.arabic-style]="isArabicLanguage" accordion-heading
                              class="details-page-acc-heading">
                              <span>
                                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                                  class="header-items-label" title="{{ 'Detail.Pages' | translate }}">
                                  {{ "Detail.images" | translate }}
                                </a>
                              </span>
                              <i class="fa fa-chevron-down pull-right header-accordion-upicn" [ngStyle]="{
                                  display: !tooglePagesIcon ? 'block' : 'none'
                                }"></i>
                              <i class="fa fa-chevron-up pull-right header-accordion-upicn" [ngStyle]="{
                                  display: tooglePagesIcon ? 'block' : 'none'
                                }"></i>
                            </div>
                            <div class="col-md-12 col-xs-12 header-accordian-inner">
                              <div class="padding-left-0 header-accodion-panel header-metadats">
                                <div class="relevant-pages-page-div">
                                  <ng-container *ngFor="
                                      let image of pageFullTextObj;
                                      let i = index
                                    ">
                                    <div *ngIf="image" (click)="
                                        loadRelaventPage(
                                          image.pageId,
                                          $event,
                                          documentSearchTermSplitted
                                        )
                                      " class="image-container relevant-image-container-div" [ngClass]="{
                                        active:
                                          documentCurrentIndex ===
                                          getImagePageNumber(image.pageId)
                                      }">
                                      <!--  -->
                                      <ng-container>
                                        <!-- [title]="getImageTitleForThumbs(image.pageId)" -->
                                        <img name="Document-image" class="relevant-image-container center-block"
                                          alt="Document Image" src="{{
                                            getSimpleImageURl(image?.pageId) +
                                              '?type=small&access_token=' +
                                              accessToken
                                          }}" />
                                      </ng-container>
                                      <div class="relevant-sequence-number">
                                        {{ getImagePageNumber(image.pageId) }}
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </accordion-group>
                        </accordion>
                        <accordion *ngIf="matchedMetadata">
                          <accordion-group *ngIf="
                              matchedMetadata[currentDocumentId]?.length > 0
                            " class="details-accordion-header" [isOpen]="true" (isOpenChange)="
                              checkAccordionStatus($event, 'toggleMetadataIcon')
                            ">
                            <div [class.arabic-style]="isArabicLanguage" accordion-heading
                              class="details-page-acc-heading">
                              <span>
                                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                                  class="header-items-label" title="{{ 'Detail.metaData' | translate }}">
                                  {{ "Detail.metaData" | translate }}
                                </a>
                              </span>
                              <i class="fa fa-chevron-down pull-right header-accordion-upicn" [ngStyle]="{
                                  display: !toggleMetadataIcon
                                    ? 'block'
                                    : 'none'
                                }"></i>
                              <i class="fa fa-chevron-up pull-right header-accordion-upicn" [ngStyle]="{
                                  display: toggleMetadataIcon ? 'block' : 'none'
                                }"></i>
                            </div>
                            <div class="col-md-12 col-xs-12 header-accordian-inner">
                              <div class="padding-left-0 header-accodion-panel header-metadats">
                                <div class="accordian-panel-heading header-panel-Content" *ngIf="matchedMetadata">
                                  <ul class="metadata-list">
                                    <ng-container *ngFor="
                                        let matchedData of matchedMetadata[
                                          currentDocumentId
                                        ]
                                      ">
                                      <li class="pad-5" *ngIf="
                                          matchedData[matchedData | keys]
                                            ?.length > 0
                                        ">
                                        <ul>
                                          <li class="pad-5" *ngFor="
                                              let val of matchedData[
                                                matchedData | keys
                                              ]
                                            ">
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() == 'title'
                                              " title="{{
                                                'Detail.documentTitle'
                                                  | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">
                                              {{
                                              "Detail.documentTitle"
                                              | translate
                                              }}</span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                'collectionV2'
                                              " title="{{
                                                'Detail.Collection' | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Detail.Collection" | translate
                                              }}
                                            </span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() == 'id'
                                              " title="{{
                                                'Detail.wileyDocumentNumber'
                                                  | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Detail.wileyDocumentNumber"
                                              | translate
                                              }}</span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                'docLangV2'
                                              " title="{{
                                                'Detail.Language' | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Detail.Language" | translate
                                              }}</span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                  'sourceInstitutionV2' ||
                                                val.field.toString() ==
                                                  'archive'
                                              " title="{{
                                                'Detail.Source' | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Detail.Source" | translate
                                              }}</span>
                                            <!-- <span [class.arabic-style]="isArabicLanguage" *ngIf="val.field.toString() == 'findingAidNo'" title="{{ 'Detail.findingAidNumber' | translate }}"
                                              class="matcheddata-filedname" (click)="gotoCorrespondingTab(matchedData)">{{ 'Detail.findingAidNumber' | translate }}</span> -->
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                'authors'
                                              " title="{{
                                                'Detail.Authors' | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Detail.Authors" | translate
                                              }}</span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                'pubDate'
                                              " title="{{
                                                'HomePage.publicationDate'
                                                  | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "HomePage.publicationDate"
                                              | translate
                                              }}</span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                'docNotes'
                                              " title="{{
                                                'Detail.documentNotes'
                                                  | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Detail.documentNotes"
                                              | translate
                                              }}
                                            </span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                'itemNotesV2'
                                              " title="{{
                                                'Detail.itemNotes' | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Detail.itemNotes" | translate
                                              }}
                                            </span>
                                            <span *ngIf="
                                                val.field.toString() ==
                                                'itemTitles'
                                              " title="{{
                                                'Detail.itemTitle' | translate
                                              }}" class="matcheddata-filedname disable-cursor" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Detail.itemTitle" | translate
                                              }}</span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                'subjectV2'
                                              " title="{{
                                                'SearchResult.Subjects'
                                                  | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "SearchResult.Subjects"
                                              | translate
                                              }}</span>
                                            <span [class.arabic-style]="
                                                isArabicLanguage
                                              " *ngIf="
                                                val.field.toString() ==
                                                'docType'
                                              " title="{{
                                                'Common.ContentType' | translate
                                              }}" class="matcheddata-filedname" (click)="
                                                gotoCorrespondingTab(
                                                  matchedData
                                                )
                                              ">{{
                                              "Common.ContentType" | translate
                                              }}</span>
                                            <ul *ngFor="
                                                let citationSnippet of val.snipplets
                                              " class="matched-data-list">
                                              <li *ngIf="
                                                  val.field.toString() !==
                                                    'itemTitles' &&
                                                  val.field.toString() !==
                                                    'title' &&
                                                  val.field.toString() !==
                                                    'contentSubTypeV2'
                                                " [innerHtml]="citationSnippet" appDecodeEntities></li>
                                            </ul>
                                            <ng-container *ngIf="
                                                val.field.toString() ==
                                                  'itemTitles' &&
                                                val.field.toString() !== 'title'
                                              ">
                                              <ul *ngFor="
                                                  let itemTitle of matchedItemTitles
                                                " class="matched-data-list">
                                                <li class="primary-text-color item-page" (click)="
                                                    gotoCurrentItemTab(
                                                      $event,
                                                      itemTitle.split(':')[0]
                                                    )
                                                  " [innerHtml]="
                                                    itemTitle
                                                      .split(':')
                                                      .slice(1)
                                                      .join(':')
                                                  " appDecodeEntities></li>
                                              </ul>
                                            </ng-container>
                                            <ng-container *ngIf="
                                                val.field.toString() ==
                                                  'title' &&
                                                val.field.toString() !==
                                                  'itemTitles'
                                              ">
                                              <ul *ngFor="
                                                  let itemTitle of matchedDocumentTitles
                                                " class="matched-data-list">
                                                <li class="primary-text-color item-page" (click)="
                                                    gotoCorrespondingTab(
                                                      matchedData
                                                    )
                                                  " [innerHtml]="itemTitle" appDecodeEntities></li>
                                              </ul>
                                            </ng-container>
                                          </li>
                                        </ul>
                                      </li>
                                    </ng-container>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </accordion-group>
                        </accordion>

                        <accordion *ngIf="pageFullTextObj?.length > 0">
                          <accordion-group class="details-accordion-header" [isOpen]="true" (isOpenChange)="
                              checkAccordionStatus($event, 'toggleFulltextIcon')
                            ">
                            <div [class.arabic-style]="isArabicLanguage" accordion-heading
                              class="details-page-acc-heading">
                              <span>
                                <a href="javascript:void(0)" [class.arabic-style]="isArabicLanguage"
                                  class="header-items-label" title="{{ 'Detail.fullText' | translate }}">
                                  {{ "Detail.fullText" | translate }}
                                </a>
                              </span>
                              <i class="fa fa-chevron-down pull-right header-accordion-upicn" [ngStyle]="{
                                  display: !toggleFulltextIcon
                                    ? 'block'
                                    : 'none'
                                }"></i>
                              <i class="fa fa-chevron-up pull-right header-accordion-upicn" [ngStyle]="{
                                  display: toggleFulltextIcon ? 'block' : 'none'
                                }"></i>
                            </div>
                            <div class="col-md-12 col-xs-12 header-accordian-inner">
                              <div class="padding-left-0 header-accodion-panel header-metadats">
                                <div class="accordian-panel-heading header-panel-Content"
                                  *ngIf="pageFullTextObj.length > 0">
                                  <ul class="metadata-list pagefulltext-list">
                                    <li class="pad-5" *ngFor="
                                        let pageFulltext of pageFullTextObj
                                      ">
                                      <ul>
                                        <li class="pading-5" *ngFor="
                                            let val of pageFulltext.FULLTEXT
                                          ">
                                          <ul *ngFor="
                                              let citationSnippet of val.snipplets
                                            " class="matched-data-list">
                                            <i class="fa fa-chevron-right pagefulltext-left-icn"></i>
                                            <li (click)="
                                                gotoPageFullText(
                                                  pageFulltext.pageId
                                                )
                                              " [innerHtml]="
                                                '...' + citationSnippet + '...'
                                              "></li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </accordion-group>
                        </accordion>
                        <!-- </perfect-scrollbar> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab>
          <tab *ngFor="let tabz of tabs" [heading]="tabz.title" [active]="tabz.active"
            (selectTab)="tabz.active = true; onDocViewerTabSelect(tabz.id)" (deselect)="tabz.active = false"
            [disabled]="tabz.disabled" [removable]="tabz.removable" [customClass]="tabz.customClass" id="{{ tabz.id }}">
            <ng-container *ngIf="tabz?.id === 'tocTabs'">
              <ng-template tabHeading>
                <label (click)="expandAllTree()" [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.TableOfContents' | translate }} ({{
                    shiftContrlKey
                  }} + '\')">
                  <span class="spriteico tocIcon"></span>
                  <p [class.arabic-style]="isArabicLanguage" class="tabicon-title">
                    {{ "Detail.tocHeading" | translate }}
                  </p>
                </label>
              </ng-template>

              <div class="tab-pane" [class.toggled]="toggleMenu">
                <div *ngIf="documentData?.docType == 'Monographs'">
                  <div [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                    title="{{ 'Detail.TableOfContents' | translate }}">
                    <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading">
                      {{ "Detail.TableOfContents" | translate }}
                    </h4>
                  </div>
                  <div class="toc-tree-scroll" id="itemsInTOCTab" #container>
                    <!-- <perfect-scrollbar [config]="config" class="toc-tree-scroll" #container> -->
                    <div class="col-md-12 padding-0">
                      <p [class.arabic-style]="isArabicLanguage" *ngIf="monographResultLoader">
                        {{ "SearchResult.loadingPleaseWait" | translate }}
                      </p>
                      <tree-root #tree id="monographTree" (updateData)="expandTreeOnLoad($event)" [focused]="true"
                        [nodes]="monographTableofContentNodes" [options]="manuscripttableofContentOptions"
                        (activate)="onNodeSelect($event)" (deactivate)="loadNodedataToItems($event)">
                        <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                          <div class="tree-node" *ngIf="node">
                            <tree-node-expander [node]="node"></tree-node-expander>
                            <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                              [class.node-content-wrapper-focused]="
                                node?.data?.pageId === currentSelectedPageId
                              ">
                              <a [class.node-link]="node?.data?.chilldPageIds" id="node?.data?.pageId" [ngClass]="
                                  addPageIdAsClass(node?.data?.chilldPageIds)
                                " [class]="node.data.className" [class.title]="true" [attr.title]="node?.data?.name"
                                appDecodeEntities>{{ node?.data?.name }}</a>
                            </div>
                            <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                          </div>
                        </ng-template>
                      </tree-root>
                    </div>
                    <!-- </perfect-scrollbar> -->
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tabz?.id === 'itemsTab'">
              <ng-template tabHeading>
                <label (click)="expandAllTree()" [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.itemsTitle' | translate }}  ({{
                    shortcutKeyTitle
                  }} + Y)">
                  <span class="spriteico itemsIcon"></span>
                  <p [class.arabic-style]="isArabicLanguage" class="tabicon-title">
                    {{ "Detail.Items" | translate }}
                  </p>
                </label>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu">
                <div [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                  title="{{ 'Detail.itemsTitle' | translate }}">
                  <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading">
                    {{ "Detail.itemsTitle" | translate }}
                  </h4>
                </div>
                <tabset #itemsTabset id="itemsTabset">
                  <tab [customClass]="'items-by-type-tabs'" id="itemTab1" heading=""
                    (selectTab)="itemsByType(documentData?.wileyId)">
                    <ng-template tabHeading>
                      <span [class.arabic-style]="isArabicLanguage" class="items-by-type-tabs-span" title="{{ 'Detail.ByType' | translate }} ({{
                          controlShortcut
                        }} + Q)">{{ "Detail.ByType" | translate }}</span>
                    </ng-template>
                    <div class="manuscriptTree2-tree-scroll" id="itemsByType" #container>
                      <!-- [perfectScrollbar]="config" -->
                      <!-- <perfect-scrollbar [config]="config" class="manuscriptTree2-tree-scroll" #container> -->
                      <div class="items-treenode">
                        <div class="treenode-icons itemstabset-treenode">
                          <p [class.arabic-style]="isArabicLanguage" *ngIf="itemsTypeResultLoader">
                            {{ "SearchResult.loadingPleaseWait" | translate }}
                          </p>
                          <tree-root #tree id="manuscriptTree1" [focused]="true"
                            [nodes]="manuscriptTypeTableofContentNodes" (updateData)="expandTreeOnLoad($event)"
                            [options]="manuscripttableofContentOptions" (activate)="onNodeSelect($event)"
                            (deactivate)="loadNodedataToItems($event)">
                            <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                              <div class="tree-node" *ngIf="node">
                                <tree-node-expander [node]="node"></tree-node-expander>
                                <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                                  [class.node-content-wrapper-focused]="
                                    checkPageIdExist(node?.data?.chilldPageIds)
                                  ">
                                  <a href="javascript:void(0)" [class.node-link]="
                                      node?.data?.chilldPageIds
                                    " [ngClass]="
                                      addPageIdAsClass(
                                        node?.data?.chilldPageIds
                                      )
                                    " class="treenode-childnode" [class.title]="true" id="{{ node?.data?.pageId }}"
                                    [attr.title]="node?.data?.name" appDecodeEntities>{{ node?.data?.name }}</a>
                                  <a *ngIf="node?.data?.notesAvailable" (click)="getItemNotes(node?.data?.label)"
                                    class="item-notes-parent" href="javascript:void(0)">
                                    <span class="spriteico item-notes-icon" aria-hidden="true"></span>
                                  </a>
                                </div>
                                <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                              </div>
                            </ng-template>
                          </tree-root>
                        </div>
                      </div>
                      <!-- </perfect-scrollbar> -->
                    </div>
                  </tab>
                  <tab (click)="expandAllTree()" [customClass]="'items-by-type-tabs'" id="itemTab2" heading=""
                    (selectTab)="itemsInOrder(documentData?.wileyId)">
                    <ng-template tabHeading>
                      <span [class.arabic-style]="isArabicLanguage" class="items-by-type-tabs-span" title="{{ 'Detail.BySequence' | translate }} ({{
                          shortcutKeyTitle
                        }} + Q)">{{ "Detail.BySequence" | translate }}</span>
                    </ng-template>
                    <div class="manuscriptTree2-tree-scroll" id="itemsInOrder">
                      <!-- <perfect-scrollbar [config]="config" id="itemsInOrder" class="manuscriptTree2-tree-scroll"
                      #container> -->
                      <div class="items-treenode">
                        <div class="treenode-icons itemstabset-treenode">
                          <p [class.arabic-style]="isArabicLanguage" *ngIf="manuscriptResultLoader">
                            {{ "SearchResult.loadingPleaseWait" | translate }}
                          </p>
                          <tree-root #tree id="manuscriptTree2" [focused]="true"
                            [nodes]="manuscriptOrderTableofContentNodes" [options]="manuscripttableofContentOptions"
                            (activate)="onNodeSelect($event)" (deactivate)="loadNodedataToItems($event)">
                            <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                              <div class="tree-node">
                                <div class="node-content-wrapper" (click)="node.mouseAction('click', $event)"
                                  [class.node-content-wrapper-focused]="
                                    checkPageIdExist(node?.data?.chilldPageIds)
                                  ">
                                  <a href="javascript:void(0)" [class.node-link]="
                                      node?.data?.chilldPageIds
                                    " [ngClass]="
                                      addPageIdAsClass(
                                        node?.data?.chilldPageIds
                                      )
                                    " class="sequence-tree treenode-childnode m-w300" [attr.title]="node?.data?.name"
                                    [class.title]="true" appDecodeEntities>{{ node?.data?.name }}</a>
                                  <a *ngIf="node?.data?.notesAvailable" (click)="getItemNotes(node?.data?.label)"
                                    class="item-notes-parent" href="javascript:void(0)">
                                    <i class="spriteico item-notes-icon" aria-hidden="true"></i>
                                  </a>
                                </div>
                                <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                              </div>
                            </ng-template>
                          </tree-root>
                        </div>
                      </div>
                      <!-- </perfect-scrollbar> -->
                    </div>
                  </tab>
                </tabset>
              </div>
            </ng-container>
            <ng-container *ngIf="tabz?.id === 'notesTab'">
              <ng-template tabHeading>
                <span [class.arabic-style]="isArabicLanguage" class="spriteico notesIcon" title="{{ 'Detail.Notes' | translate }} ({{
                    shortcutKeyTitle
                  }} + M)"></span>
                <p class="tabicon-title" [class.arabic-style]="isArabicLanguage">
                  {{ "Detail.Notes" | translate }}
                </p>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu">
                <tabset #itemsTabset id="itemsTabset">
                  <tab *ngIf="documentData?.docNoteExist" [customClass]="'items-by-type-tabs'" id="itemTab1" heading="">
                    <ng-template tabHeading>
                      <span [class.arabic-style]="isArabicLanguage" class="items-by-type-tabs-span"
                        title="{{ 'Detail.documentNotes' | translate }}">{{ "Detail.documentNotes" | translate }}</span>
                    </ng-template>
                    <div class="">
                      <div class="col-xs-12 pad-r-0">
                        <div class="document-notes-tab" #container>
                          <!-- <perfect-scrollbar class="document-notes-tab" #container> -->
                          <div class="padding-right-5">
                            <ng-container *ngIf="
                                documentData?.shortName !== 'RCP' ||
                                (documentData?.shortName === 'RCP' &&
                                  documentData?.docType === 'Manuscripts')
                              ">
                              <ng-container *ngFor="let docuNotes of documentNotesFromNotes">
                                <div class="documentType">
                                  <label *ngIf="
                                      docuNotes?.type !== 'Websites' &&
                                      docuNotes?.type !==
                                        'Additional Physical
                                    Form Available Note' &&
                                      docuNotes?.type !==
                                        'Local_Tags_by_Library' &&
                                      docuNotes?.type !==
                                        'Local Tags by Library' &&
                                      docuNotes?.type !==
                                        'Additional Physical Form Available Note' &&
                                      docuNotes?.type !==
                                        'Immediate Source of Acquisition Note' &&
                                      docuNotes?.type !== 'Dimension Free' &&
                                      docuNotes?.type !==
                                        'Object History Note' &&
                                      docuNotes?.type !==
                                        'object_history_note' &&
                                      docuNotes?.type !== 'Dimensions'
                                    " class="citation-contentlbl">{{ docuNotes?.type }}</label>
                                  <label *ngIf="
                                      docuNotes?.type === 'Websites' &&
                                      showWebsites &&
                                      (docuNotes?.type === 'Dimension Free' ||
                                        docuNotes?.type === 'Dimensions')
                                    " class="citation-contentlbl">Dimensions</label>
                                  <label *ngIf="
                                      docuNotes?.type !== 'Websites' &&
                                      (docuNotes?.type === 'Dimension Free' ||
                                        docuNotes?.type === 'Dimensions')
                                    " class="citation-contentlbl">Dimensions</label>
                                  <label *ngIf="
                                      docuNotes?.type === 'Websites' &&
                                      showWebsites &&
                                      (docuNotes?.type !== 'Dimension Free' ||
                                        docuNotes?.type !== 'Dimensions')
                                    " class="citation-contentlbl">{{ docuNotes?.type }}</label>
                                  <label *ngIf="
                                      documentData?.docType !== 'Photographs' &&
                                      docuNotes?.type ===
                                        'Additional Physical Form Available Note'
                                    " class="citation-contentlbl">{{ docuNotes?.type }}</label>
                                  <label *ngIf="
                                      docuNotes?.type ===
                                        'Object History Note' ||
                                      docuNotes?.type === 'object_history_note'
                                    " class="citation-contentlbl">{{
                                    "Detail.objectHistory" | translate
                                    }}</label>
                                  <ng-container *ngIf="
                                      docuNotes?.type !==
                                        'Geographical Coordinates' &&
                                      docuNotes?.type !==
                                        'Local Tags by Library' &&
                                      docuNotes?.type !==
                                        'Local_Tags_by_Library' &&
                                      docuNotes?.type !==
                                        'Immediate Source of Acquisition Note'
                                    ">
                                    <ul *ngFor="let docValue of docuNotes?.value" class="document-type-list">
                                      <li *ngIf="
                                          docuNotes?.type !== 'Websites' &&
                                          docuNotes?.type !==
                                            'Additional Physical Form Available Note' &&
                                          docuNotes?.type !==
                                            'Immediate Source of Acquisition Note'
                                        " class="citation-content">
                                        {{ docValue }}
                                      </li>
                                      <li *ngIf="
                                          docuNotes?.type === 'Websites' &&
                                          showWebsites
                                        " [innerHtml]="
                                          getWebsiteLinkTags(docValue)
                                        " class="display-block-text primary-text-color"></li>
                                      <li *ngIf="
                                          documentData?.docType !==
                                            'Photographs' &&
                                          docuNotes?.type ===
                                            'Additional Physical Form Available Note'
                                        " class="citation-content">
                                        {{ docValue }}
                                      </li>
                                    </ul>
                                  </ng-container>

                                  <ng-container *ngIf="
                                      docuNotes?.type ===
                                      'Geographical Coordinates'
                                    " class="citation-content">
                                    <ul *ngFor="let docValue of geoBoundaries"
                                      class="document-type-list geoboundry-list">
                                      <ul class="document-type-list geoboundry-list">
                                        <p class="geo-cooridates-title">
                                          {{ docValue.title }}
                                        </p>
                                        <ng-container *ngFor="
                                            let val of docValue['coOrdinates'];
                                            let index = index
                                          ">
                                          <ng-container [ngSwitch]="index">
                                            <li *ngSwitchCase="0">
                                              <label class="coordinates-title">
                                                North West Latitude & Longitude
                                              </label>
                                              <p>
                                                {{
                                                docValue["coOrdinates"][index]
                                                }}
                                              </p>
                                            </li>
                                            <li *ngSwitchCase="1">
                                              <label class="coordinates-title">
                                                South West Latitude & Longitude
                                              </label>
                                              <p>
                                                {{
                                                docValue["coOrdinates"][index]
                                                }}
                                              </p>
                                            </li>
                                            <li *ngSwitchCase="2">
                                              <label class="coordinates-title">
                                                South East Latitude & Longitude
                                              </label>
                                              <p>
                                                {{
                                                docValue["coOrdinates"][index]
                                                }}
                                              </p>
                                            </li>
                                            <li *ngSwitchCase="3">
                                              <label class="coordinates-title">
                                                North East Latitude & Longitude
                                              </label>
                                              <p>
                                                {{
                                                docValue["coOrdinates"][index]
                                                }}
                                              </p>
                                            </li>
                                          </ng-container>
                                        </ng-container>
                                      </ul>
                                    </ul>
                                  </ng-container>
                                </div>
                              </ng-container>

                              <ng-container *ngIf="
                                  documentData?.shortName === 'RCP' &&
                                  documentData?.docType === 'Manuscripts'
                                ">
                                <div class="documentType">
                                  <ng-container *ngIf="
                                      docutypeDetails['Content Description']
                                        ?.length > 0;
                                      else contectDesc
                                    ">
                                  </ng-container>
                                  <ng-template #contectDesc>
                                    <label class="citation-contentlbl">{{
                                      "Detail.contentDesc" | translate
                                      }}</label>
                                    <ul class="document-type-list">
                                      <li>
                                        {{
                                        "Detail.infoNotProvided" | translate
                                        }}
                                      </li>
                                    </ul>
                                  </ng-template>
                                </div>

                                <div class="documentType">
                                  <ng-container *ngIf="
                                      docutypeDetails['Object History Note'] ||
                                        docutypeDetails['object_history_note']
                                          ?.length > 0;
                                      else ObjectHistory
                                    ">
                                  </ng-container>
                                  <ng-template #ObjectHistory>
                                    <label class="citation-contentlbl">{{
                                      "Detail.objectHistory" | translate
                                      }}</label>
                                    <ul class="document-type-list">
                                      <li>
                                        {{
                                        "Detail.infoNotProvided" | translate
                                        }}
                                      </li>
                                    </ul>
                                  </ng-template>
                                </div>

                                <div class="documentType">
                                  <ng-container *ngIf="
                                      docutypeDetails['PHYSICAL DESCRIPTION']
                                        ?.length > 0;
                                      else deploNote
                                    ">
                                  </ng-container>
                                  <ng-template #deploNote>
                                    <label class="citation-contentlbl">{{
                                      "Detail.physicalDesc" | translate
                                      }}</label>
                                    <ul class="document-type-list">
                                      <li>
                                        {{
                                        "Detail.infoNotProvided" | translate
                                        }}
                                      </li>
                                    </ul>
                                  </ng-template>
                                </div>

                                <div class="documentType">
                                  <ng-container *ngIf="
                                      docutypeDetails['Part of Reference']
                                        ?.length > 0;
                                      else partRef
                                    ">
                                  </ng-container>
                                  <ng-template #partRef>
                                    <label class="citation-contentlbl">{{
                                      "Detail.partOfReference" | translate
                                      }}</label>
                                    <ul class="document-type-list">
                                      <li>
                                        {{
                                        "Detail.infoNotProvided" | translate
                                        }}
                                      </li>
                                    </ul>
                                  </ng-template>
                                </div>

                                <div class="documentType">
                                  <ng-container *ngIf="
                                      docutypeDetails['Dimension Free']
                                        ?.length > 0;
                                      else dimensionRef
                                    ">
                                  </ng-container>
                                  <ng-template #dimensionRef>
                                    <label class="citation-contentlbl">{{
                                      "Detail.dimensionFree" | translate
                                      }}</label>
                                    <ul class="document-type-list">
                                      <li>
                                        {{
                                        "Detail.infoNotProvided" | translate
                                        }}
                                      </li>
                                    </ul>
                                  </ng-template>
                                </div>
                              </ng-container>
                            </ng-container>

                            <ng-container *ngIf="
                                documentData?.shortName === 'RCP' &&
                                documentData?.docType != 'Manuscripts' &&
                                !isdisplayAllNotes
                              ">
                              <div class="documentType">
                                <label class="citation-contentlbl">{{
                                  "Detail.dimensionFree" | translate
                                  }}</label>
                                <ng-container *ngIf="
                                    docutypeDetails['Dimension Free'] ||
                                      docutypeDetails['Dimensions']?.length > 0;
                                    else Dimension
                                  ">
                                  <ul *ngFor="
                                      let docValue of docutypeDetails[
                                        'Dimension Free'
                                      ] || docutypeDetails['Dimensions']
                                    " class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">
                                      {{ docValue }}
                                    </li>
                                  </ul>
                                </ng-container>
                                <ng-template #Dimension>
                                  <ul class="document-type-list">
                                    <li>
                                      {{ "Detail.infoNotProvided" | translate }}
                                    </li>
                                  </ul>
                                </ng-template>
                              </div>

                              <div class="documentType">
                                <label class="citation-contentlbl">{{
                                  "Detail.objectHistory" | translate
                                  }}</label>
                                <ng-container *ngIf="
                                    docutypeDetails['Object History Note'] ||
                                      docutypeDetails['object_history_note']
                                        ?.length > 0;
                                    else ObjectHistory
                                  ">
                                  <ul *ngFor="
                                      let docValue of docutypeDetails[
                                        'Object History Note'
                                      ] ||
                                        docutypeDetails['object_history_note']
                                    " class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">
                                      {{ docValue }}
                                    </li>
                                  </ul>
                                </ng-container>
                                <ng-template #ObjectHistory>
                                  <ul class="document-type-list">
                                    <li>
                                      {{ "Detail.infoNotProvided" | translate }}
                                    </li>
                                  </ul>
                                </ng-template>
                              </div>
                              <div class="documentType" *ngIf="
                                  docutypeDetails['Content Description'] ||
                                  docutypeDetails['content.description']
                                ">
                                <label class="citation-contentlbl">{{
                                  "Detail.contentDesc" | translate
                                  }}</label>
                                <ng-container *ngIf="
                                    docutypeDetails['Content Description']
                                      ?.length > 0;
                                    else contectDesc
                                  ">
                                  <ul *ngFor="
                                      let docValue of docutypeDetails[
                                        'Content Description'
                                      ]
                                    " class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">
                                      {{ docValue }}
                                    </li>
                                  </ul>
                                </ng-container>
                                <ng-container *ngIf="
                                    docutypeDetails['content.description']
                                      ?.length > 0;
                                    else contectDesc
                                  ">
                                  <ul *ngFor="
                                      let docValue of docutypeDetails[
                                        'content.description'
                                      ]
                                    " class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">
                                      {{ docValue }}
                                    </li>
                                  </ul>
                                </ng-container>
                              </div>
                              <div class="documentType" *ngIf="
                                  !docutypeDetails['content.description'] &&
                                  !docutypeDetails['Content Description']
                                ">
                                <label class="citation-contentlbl">{{
                                  "Detail.contentDesc" | translate
                                  }}</label>
                                <ul class="document-type-list">
                                  <li>
                                    {{ "Detail.infoNotProvided" | translate }}
                                  </li>
                                </ul>
                              </div>

                              <div class="documentType" *ngIf="
                                  docutypeDetails['part_of_reference'] ||
                                  docutypeDetails['Part of Reference']
                                ">
                                <label class="citation-contentlbl">{{
                                  "Detail.partofReference" | translate
                                  }}</label>
                                <ng-container *ngIf="
                                    docutypeDetails['Part of Reference']
                                      ?.length > 0;
                                    else referencePart
                                  ">
                                  <ul *ngFor="
                                      let docValue of docutypeDetails[
                                        'Part of Reference'
                                      ]
                                    " class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">
                                      {{ docValue }}
                                    </li>
                                  </ul>
                                </ng-container>
                                <ng-container *ngIf="
                                    docutypeDetails['part_of_reference']
                                      ?.length > 0;
                                    else referencePart
                                  ">
                                  <ul *ngFor="
                                      let docValue of docutypeDetails[
                                        'part_of_reference'
                                      ]
                                    " class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">
                                      {{ docValue }}
                                    </li>
                                  </ul>
                                </ng-container>
                              </div>
                              <div class="documentType" *ngIf="
                                  !docutypeDetails['part_of_reference'] &&
                                  !docutypeDetails['Part of Reference']
                                ">
                                <label class="citation-contentlbl">{{
                                  "Detail.partofReference" | translate
                                  }}</label>
                                <ul class="document-type-list">
                                  <li>
                                    {{ "Detail.infoNotProvided" | translate }}
                                  </li>
                                </ul>
                              </div>

                              <!-- <div class="documentType">
                                <label class="citation-contentlbl">{{ 'Detail.generalNotes' | translate }}</label>
                                <ng-container *ngIf="docutypeDetails['General Note']?.length > 0; else generalNotes">
                                  <ul *ngFor="let docValue of docutypeDetails['General Note']" class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">{{docValue}}
                                    </li>
                                  </ul>
                                </ng-container>
                                <ng-template #generalNotes>
                                  <ul class="document-type-list">
                                    <li> {{ 'Detail.infoNotProvided' | translate }}</li>
                                  </ul>
                                </ng-template>
                              </div> -->
                              <div class="documentType">
                                <label class="citation-contentlbl">{{
                                  "Detail.physicalDesc" | translate
                                  }}</label>
                                <ng-container *ngIf="
                                    docutypeDetails['PHYSICAL DESCRIPTION']
                                      ?.length > 0;
                                    else diplomaticNotes
                                  ">
                                  <ul *ngFor="
                                      let docValue of docutypeDetails[
                                        'PHYSICAL DESCRIPTION'
                                      ]
                                    " class="document-type-list">
                                    <li *ngIf="docuNotes?.type !== 'Websites'" class="citation-content">
                                      {{ docValue }}
                                    </li>
                                  </ul>
                                </ng-container>
                                <ng-template #diplomaticNotes>
                                  <ul class="document-type-list">
                                    <li>
                                      {{ "Detail.infoNotProvided" | translate }}
                                    </li>
                                  </ul>
                                </ng-template>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="
                                documentData?.shortName === 'RCP' &&
                                documentData?.docType != 'Manuscripts' &&
                                isdisplayAllNotes
                              ">
                              <ng-container *ngFor="let docValue of documentNotesFromNotes">
                                <label class="citation-contentlbl">{{
                                  docValue.type
                                  }}</label>
                                <ul class="document-type-list">
                                  <li class="citation-content">
                                    {{ docValue.value }}
                                  </li>
                                </ul>
                              </ng-container>
                            </ng-container>
                          </div>
                          <!-- </perfect-scrollbar> -->
                        </div>
                      </div>
                    </div>
                  </tab>
                  <tab *ngIf="documentData?.collectionNoteExist" [customClass]="'items-by-type-tabs'" id="itemTab2"
                    heading="" (selectTab)="getCollectionNotes()">
                    <ng-template tabHeading>
                      <span [class.arabic-style]="isArabicLanguage" class="items-by-type-tabs-span"
                        title="{{ 'Detail.collectionNotes' | translate }}">{{ "Detail.collectionNotes" | translate
                        }}</span>
                    </ng-template>
                    <div class="items-treenode">
                      <div class="col-xs-12 pad-r-0">
                        <div class="document-notes-tab" #container>
                          <!-- <perfect-scrollbar [config]="config" class="document-notes-tab" #container> -->
                          <div *ngIf="
                              collectionNotes?.length === 0 &&
                              !collectionLoading
                            ">
                            {{ "SearchResult.NoResultsFound" | translate }}
                          </div>
                          <div *ngIf="
                              collectionNotes?.length === 0 && collectionLoading
                            ">
                            {{ "SearchResult.loadingPleaseWait" | translate }}
                          </div>
                          <ng-container *ngFor="let collectionNotes of collectionNotes">
                            <div class="padding-right-15">
                              <div class="documentType">
                                <label class="citation-contentlbl" *ngIf="
                                    collectionNotes?.type ===
                                      'Dimension Free' ||
                                    collectionNotes?.type === 'Dimensions'
                                  ">Dimensions</label>
                                <label class="citation-contentlbl" *ngIf="
                                    collectionNotes?.type !==
                                      'Dimension Free' &&
                                    collectionNotes?.type !== 'Dimensions'
                                  ">{{ collectionNotes?.type }}</label>
                                <ul *ngFor="
                                    let collectionValue of collectionNotes?.value
                                  " class="document-type-list">
                                  <li class="citation-content">
                                    {{ collectionValue }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </ng-container>
                          <!-- </perfect-scrollbar> -->
                        </div>
                      </div>
                    </div>
                  </tab>
                </tabset>
              </div>
            </ng-container>
            <ng-container *ngIf="tabz?.id === 'relatedSubjectsTab'">
              <ng-template tabHeading>
                <label [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.RelatedSubjects' | translate }} ({{
                    shortcutKeyTitle
                  }} + P)">
                  <span class="spriteico relatedsubIcon"> </span>
                  <p [class.arabic-style]="isArabicLanguage" class="tabicon-title">
                    {{ "SearchResult.Subjects" | translate }}
                  </p>
                </label>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu">
                <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading">
                  {{ "Detail.RelatedSubjects" | translate }}
                </h4>
                <div class="document-tab-view" #container>
                  <!-- <perfect-scrollbar [config]="config" class="document-tab-view" #container> -->
                  <div class="col-md-12">
                    <ng-container *ngIf="loader?.relatedSubjects">
                      <span [class.arabic-style]="isArabicLanguage">{{
                        "SearchResult.loadingPleaseWait" | translate
                        }}</span>
                    </ng-container>
                    <ng-container *ngIf="
                        !loader?.relatedSubjects &&
                        relatedSubjectsData?.length === 0
                      ">
                      <span [class.arabic-style]="isArabicLanguage">{{
                        "Common.Noresultsfound" | translate
                        }}</span>
                    </ng-container>
                    <ng-container *ngIf="!loader?.relatedSubjects">
                      <ul class="relatedsub-ul" id="relatedSubjects">
                        <li *ngFor="let relatedSubject of relatedSubjectsData" class="relatedsub-list" [ngStyle]="{
                            cursor: isGuestUser ? 'default' : 'pointer'
                          }">
                          <ng-container *ngIf="!isGuestUser">
                            <a title="{{
                                'Detail.searchBySubject' | translate
                              }}  {{ relatedSubject }}" href="javascript:void(0)" class="relatedsub-link"
                              (click)="setSubject(relatedSubject)" (mouseenter)="
                                reletadSubjectsMouseEnter(
                                  relatedSubject,
                                  $event
                                )
                              " (mouseleave)="
                                reletadSubjectsMouseLeave(
                                  relatedSubject,
                                  $event
                                )
                              ">
                              {{ relatedSubject }}
                              <i class="fa fa-lg fa-search related-sub-icon sub-icon" style="visibility: hidden"></i>
                            </a>
                          </ng-container>
                          <ng-container *ngIf="isGuestUser">
                            <a title="{{
                                'Detail.searchBySubject' | translate
                              }}  {{ relatedSubject }}" href="javascript:void(0)" class="relatedsub-link" [ngStyle]="{
                                cursor: isGuestUser ? 'default' : 'pointer'
                              }">
                              {{ relatedSubject }}
                              <i class="fa fa-lg fa-search related-sub-icon sub-icon" style="visibility: hidden"></i>
                            </a>
                          </ng-container>
                        </li>
                      </ul>
                    </ng-container>
                  </div>
                  <!-- </perfect-scrollbar> -->
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tabz?.id === 'illustrationTab'">
              <ng-template tabHeading>
                <label (click)="expandIllustrationTree()" [class.arabic-style]="isArabicLanguage" title="{{
                    'SearchResult.Illustrations.Illustrations' | translate
                  }} ({{ shortcutKeyTitle }} + B)">
                  <span class="spriteico illustrationIcon"></span>
                  <p class="tabicon-title" [class.arabic-style]="isArabicLanguage">
                    {{ "SearchResult.Illustrations.Illustrations" | translate }}
                  </p>
                </label>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu" #illustrationsTab>
                <div id="illustrationTree">
                  <div [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{
                      'SearchResult.Illustrations.Illustrations' | translate
                    }}">
                    <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading">
                      {{
                      "SearchResult.Illustrations.Illustrations" | translate
                      }}
                    </h4>
                  </div>
                  <div class="illustration-tree-scroll" id="illustrationsTabset" #container>
                    <!-- <perfect-scrollbar [config]="config" class="illustration-tree-scroll" #container> -->
                    <p [class.arabic-style]="isArabicLanguage" *ngIf="illustrationresultLoader">
                      {{ "SearchResult.loadingPleaseWait" | translate }}
                    </p>

                    <tree-root #TreeComponent id="illustrationTree" [focused]="true" [nodes]="illustrationNodes"
                      [options]="manuscripttableofContentOptions" (activate)="onTableSelect($event)"
                      (deactivate)="loadNodedataIllustrations($event)">
                      <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                        <div *ngIf="node" class="tree-node">
                          <tree-node-expander [node]="node"></tree-node-expander>
                          <div class="node-content-wrapper child-node" [class.node-content-wrapper-focused]="
                              node?.data?.pageId === currentSelectedPageId
                            ">
                            <a [class.node-link]="node?.data?.pageId" [ngClass]="addPageIdAsClass(node?.data?.pageId)"
                              (mouseenter)="mouseEnter(node)" (mouseleave)="mouseLeave(node)"
                              class="illustration-tree treenode-childnode" [class]="node.data.className"
                              [attr.title]="node?.data?.name" [class.title]="true"
                              (click)="node.mouseAction('click', $event)" appDecodeEntities>{{ node?.data?.name }}</a>
                            <span *ngIf="
                                node?.data?.tableId &&
                                (this.documentData.shortName
                                  ? this.documentData.shortName.toUpperCase() !=
                                    'WPA'
                                  : true)
                              " class="download-illustration" (click)="
                                downIcon({
                                  tabId: node.data['tableId'],
                                  ScreenMode: false
                                })
                              ">
                              <i class="fa fa-download" title="{{
                                  'Detail.downLoadDataFile' | translate
                                }}"></i>
                            </span>
                            <span
                              *ngIf="
                                node?.data?.tableId &&
                                (this.documentData.shortName
                                  ? this.documentData.shortName.toUpperCase() !=
                                    'WPA1'
                                  : true)
                              "
                              class="download-illustration"
                              (click)="
                                downIcon({
                                  tabId: node.data['tableId'],
                                  ScreenMode: false
                                })
                              "
                            >
                              <i
                                class="fa fa-download"
                                title="{{
                                  'Detail.downLoadDataFile' | translate
                                }}"
                              ></i>
                            </span>
                          </div>
                          <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                        </div>
                      </ng-template>
                    </tree-root>

                    <!-- </perfect-scrollbar> -->
                  </div>
                </div>
                <div class="illustration-image-body" #illustrationPreviewWindow id="illustrationImageDiv"
                  [ngStyle]="{ 'top.px': illustrationPreviewPosition }" style="display: none">
                  <img class="accordian-img center-block img-responsive" (click)="gotoIlustrationDetailspage()"
                    [attr.src]="illustrationDocumentImage" alt="{{ 'Alt.imageThumbnails' | translate }}" />
                  <div class="illustration-image-loader" [ngStyle]="{
                      display: illustrationsLoader ? 'block' : 'none'
                    }">
                    <img alt="{{ 'Alt.loaderImage' | translate }}" class="img-icon"
                      src="assets/images/three-dots.svg" />
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tabz?.id === 'volumesTab'">
              <ng-template tabHeading>
                <span [class.arabic-style]="isArabicLanguage" class="spriteico volumeIcon" title="{{ 'Detail.RelatedVolumes' | translate }} ({{
                    shortcutKeyTitle
                  }} + U)">
                </span>
                <p [class.arabic-style]="isArabicLanguage" class="tabicon-title">
                  {{ "Detail.RelatedVolumes" | translate }}
                </p>
              </ng-template>
              <div class="tab-pane" [class.toggled]="toggleMenu">
                <h4 [class.arabic-style]="isArabicLanguage" class="reletaed-subject-heading">
                  {{ "Detail.RelatedVolumes" | translate }}
                </h4>
                <div class="document-tab-view" #container>
                  <!-- <perfect-scrollbar [config]="config" class="document-tab-view" #container> -->
                  <ng-container *ngIf="loader?.relatedVolume">
                    <span [class.arabic-style]="isArabicLanguage">{{
                      "SearchResult.loadingPleaseWait" | translate
                      }}</span>
                  </ng-container>
                  <ng-container *ngIf="
                      !loader?.relatedVolume && relatedVolumes?.length === 0
                    ">
                    <span [class.arabic-style]="isArabicLanguage">{{
                      "Common.Noresultsfound" | translate
                      }}</span>
                  </ng-container>
                  <ng-container *ngIf="!loader?.relatedVolume">
                    <div class="col-md-12 pad-r-0">
                      <ul class="relatedsub-ul" id="volumes-ul">
                        <li *ngFor="let rv of relatedVolumes" class="relatedsub-list1" title="{{ rv.title }}">
                          <a href="javascript:void(0)" class="relatedsub-link" (click)="gotoDocumentById(rv?.id)"
                            *ngIf="rv.title; else volumeLabel">
                            {{ rv?.volumeNo }} - {{ rv.title }}
                          </a>
                          <ng-template #volumeLabel>
                            <p class="relatedsub-link-disabled relatedsub-link">
                              {{ rv?.volumeNo }}
                            </p>
                          </ng-template>
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                  <!-- </perfect-scrollbar> -->
                </div>
              </div>
            </ng-container>
          </tab>
        </tabset>
      </div>
    </div>
    <div class="col-md-10 docviewer-imageframe position-relative" [class.toggled]="toggleMenu"
      [class.gridview-images]="!isGridViewEnable">
      <!-- *ngIf="pageContentData" -->
      <div class="filter-result padding-0">
        <!-- <div #imageEditorContainer [ngStyle]="{display:isEditorInitialized ? 'block':'none'}" class="set-full-width" style=" width: 100%;"> -->
        <!-- <div  class="set-full-width" style=" width: 100%;"> -->
        <div #imageEditorContainer class="col-md-12 col-sm-12 docu-viewer-div"
          (mouseenter)="floatingToolbarMouseEnter()" (mouseleave)="floatingToolbarMouseLeave()">
          <div [class.hideImage]="!checkModel.document">
            <!-- [defaultPage]="selectedPageId"  -->
            <!-- <app-new-image-viewer-frame [options]="imageViewerOptions" (pageFullText)="updatePageFullText($event)"
              (whenMainImageLoaded)="afterMainImageLoaded($event)" [source]="documentImages" [currentDocumentId]="defaultImageToBeLoaded"
              [showDragonViewer]="true" [image-id]="_selectedPage" [showRelevantImages]="showRelevantImages"
              (relevantStatusChanged)="onRelevantStatusChange($event)" (overlayClicked)="onOverlayClick($event)"
              [itemTitle]="itemTitle" (currentActiveImageIndex)="setCurrentActiveImageIndex($event)" [invokedFrom]="invokedFrom"
              [documentTitleImages]="documentImagesWithTitles" [pageOverlay]="_selectedPageOverlay" [documentType]="documentData?.docType"
              [overlayForSearchTerm]="overlaysForTermSearch" [urlToDownload]="downloadableUrl" [urlForDownload]="downloadUrlFor"
              [fuzzySearch]="toggleSearch" [overlayOption]="getoverlayOption" (newOverlayFloatingVal)="newOverlayFloatingVal($event)"
              (rotationValue)="getRotationValue($event)"></app-new-image-viewer-frame> -->
            <div class="floating-toolbar-menu" *ngIf="documentImagesCount?.length > 0"
              [class.showMenu]="showFloatingMenu || hoverFloatingMenu">
              <!-- hamburger menu -->
              <li class="image-viewer-flip-list" (click)="openImageviewerMenu(); $event.stopPropagation()"
                *ngIf="isShowFloatIcons">
                <span title="{{ 'Detail.imageViewerMenu' | translate }}"
                  class="fa fa-ellipsis-h imageviewer-float-image image-viewer-more-optn"></span>
                <ul (click)="$event.stopPropagation()"
                  class="dropdown-menu dropdown-menu-right imageviewer-menubar-body" role="menu" [ngStyle]="{
                    display: isImageviewerMenuOpen ? 'block' : 'none'
                  }">
                  <li *ngIf="
                      isShowFloatIcons && !isSharedDocument && !guestAuthToken
                    " class="image-viewer-flip-list image-viewvew-list" (click)="selectImage()">
                    <span class="crop-image-icon imageviewer-float-image spriteico_mob spriteico_mob-cropimage" title="{{ 'Detail.crop' | translate }} ({{
                        shortcutKeyTitle
                      }} + Insert)"></span>
                  </li>
                  <li *ngIf="
                      options.showExpand && showFittoHeight && isShowFloatIcons
                    " class="image-viewer-flip-list image-viewvew-list" title="{{ 'Detail.fittoHeight' | translate }}"
                    (click)="fitImageToWidth(false)">
                    <span
                      class="spriteico fittoHeight-icon imageviewer-float-image spriteico_mob spriteico_mob-fittoheight"></span>
                  </li>
                  <li *ngIf="
                      options.showExpand && !showFittoHeight && isShowFloatIcons
                    " class="image-viewer-flip-list image-viewvew-list" title="{{ 'Detail.fittoWidth' | translate }}"
                    (click)="fitImageToWidth(true)">
                    <span
                      class="spriteico fittoWidth-icon imageviewer-float-image spriteico_mob spriteico_mob-fittowidth"></span>
                  </li>
                  <li *ngIf="options.showRotate" class="image-viewer-flip-list image-viewvew-list"
                    (click)="rotateImage(-1)">
                    <span
                      class="spriteico rotateleft-flipicon imageviewer-float-image spriteico_mob spriteico_mob-rotateleft"
                      title="{{ 'Detail.rotateLeft' | translate }} ({{
                        shortcutKeyTitle
                      }} + Left Arrow)"></span>
                  </li>
                  <li *ngIf="options.showRotate" class="image-viewer-flip-list image-viewvew-list"
                    (click)="rotateImage(1)">
                    <span
                      class="spriteico rotateright-flipicon imageviewer-float-image spriteico_mob spriteico_mob-rotateright"
                      title="{{ 'Detail.rotateRight' | translate }} ({{
                        shortcutKeyTitle
                      }} + Right Arrow)"></span>
                  </li>
                  <li class="reset-button image-viewvew-list" (click)="resetImage()">
                    <span class="spriteico resetIcon imageviewer-float-image spriteico_mob spriteico_mob-resetimage"
                      title="{{ 'Detail.reset' | translate }}"></span>
                  </li>
                </ul>
              </li>
              <li *ngIf="
                  options.showFullScreen &&
                  !isInFullScreenMode &&
                  isShowFloatIcons
                " id="toggleFullScreenButton" title="{{ 'Detail.fullScreen' | translate }} ({{
                  shortcutKeyTitle
                }} + Up Arrow)"
                class="image-viewer-flip-list"
                (click)="browserFullScreen('full')"
              >
                <span
                  class="spriteico fullScreen-icon imageviewer-float-image spriteico_mob spriteico_mob-fullscreen"></span>
              </li>
              <li *ngIf="isInFullScreenMode && isShowFloatIcons" id="toggleFullScreenButton"
                class="image-viewer-flip-list" title="{{ 'Detail.smallScreen' | translate }} ({{
                  shortcutKeyTitle
                }} + Up Arrow)" (click)="browserFullScreen()">
                <span
                  class="spriteico smallScreen-icon imageviewer-float-image spriteico_mob spriteico_mob-smallscreen"></span>
              </li>
              <li class="brightness-button image-viewer-flip-list" *ngIf="!isInListViewMode && isShowFloatIcons"
                (click)="openBrightnessMenu()">
                <span class="spriteico brightness-icon imageviewer-float-image spriteico_mob spriteico_mob-brightness"
                  title="{{ 'Detail.brightnessandReset' | translate }}"></span>
                <ul (click)="$event.stopPropagation()" class="dropdown-menu dropdown-menu-left brightness-menubar-body"
                  role="menu" [ngStyle]="{
                    display: isBrightnessMenuOpen ? 'block' : 'none'
                  }">
                  <h4 class="brightness-menubar-title">
                    {{ "Detail.brightnessandReset" | translate }}
                  </h4>
                  <form #imageToolbarForm="ngForm" class="brightnessControl">
                    <div class="brightness-control imgviewer-brightness-control">
                      <label>{{ "Detail.Brightness" | translate }}</label>
                      <div class="btn-group img-viewer-btn-group">
                        <app-range-slider name="brightnessControl" (RangeChanged)="adjustBrightness($event)"
                          [minValue]="showDragonViewer ? -255 : -1" [maxValue]="showDragonViewer ? 255 : 1"
                          [stepValue]="showDragonViewer ? 1 : 0.01" title="{{
                            'Detail.Brightness' | translate
                          }} Increase({{ shortcutKeyTitle }} + '>')/ Decrease({{
                            shortcutKeyTitle
                          }} + '<') " [(ngModel)]="currentBrightness"></app-range-slider>
                      </div>
                    </div>
                    <div class="imgviewer-contrast-control contrast-control">
                      <label class="imgviewer-label">{{
                        "Detail.Contrast" | translate
                        }}</label>
                      <div class="btn-group img-viewer-btn-group">
                        <app-range-slider name="contrastControl" (RangeChanged)="adjustContrast($event)"
                          [minValue]="showDragonViewer ? -5 : -1" [stepValue]="showDragonViewer ? 1 : 0.01" [value]="1"
                          [maxValue]="showDragonViewer ? 5 : 1" title="{{ 'Detail.Contrast' | translate }}"
                          [(ngModel)]="currentContrast"></app-range-slider>
                      </div>
                    </div>
                    <div class="invert-control">
                      <label class="imgviewer-label" title=" {{ 'Detail.Negative' | translate }} ({{
                          shortcutKeyTitle
                        }} + N)">
                        <input id="invertChkbox" type="checkbox" name="isInvertedChkBox" [(ngModel)]="isInverted" />
                        {{ "Detail.Negative" | translate }}
                      </label>
                    </div>
                  </form>
                  <div class="float-right reset_btn">
                    <button class="btn btn-xs btn-primary imgviewer-reset-btn reset-mr-2" (click)="resetToolbar()">
                      {{ "Detail.reset" | translate }}
                    </button>
                    <button class="btn btn-xs btn-primary imgviewer-reset-btn" (click)="closeBrightnessToolbar()">
                      {{ "Common.Close" | translate }}
                    </button>
                  </div>
                </ul>
              </li>
              <li *ngIf="
                  options.showDownload &&
                  isShowFloatIcons &&
                  !isTrialCustomerUser &&
                  !isGuestUser
                " class="download-button image-viewer-flip-list" (click)="downloadAsPDF()">
                <span class="spriteico downloadPDFIcon imageviewer-float-image spriteico_mob spriteico_mob-download"
                  title="{{ 'Detail.download' | translate }}  ({{
                    controlShortcut
                  }} + D)"></span>
                <ul class="dropdown-menu dropdown-menu-left brightness-menubar-body dropdown-menu-position" [ngClass]="{
                    width427: isTiffAvailableFromDocInfo,
                    width250: !isTiffAvailableFromDocInfo
                  }" role="menu" [ngStyle]="{ display: isDownloadMenuOpen ? 'block' : 'none' }"
                  (click)="$event.stopPropagation()">
                  <div [ngClass]="{
                      downloadContainer: isTiffAvailableFromDocInfo,
                      'col-md-12': !isTiffAvailableFromDocInfo
                    }">
                    <div [ngClass]="{
                        downloadPDFContainer: isTiffAvailableFromDocInfo,
                        'col-md-12 padding0': !isTiffAvailableFromDocInfo
                      }">
                      <h4 class="brightness-menubar-title">
                        {{ "Detail.downloadAsPDF" | translate }}
                      </h4>
                      <div class="download-button-brightness-control brightness-control">
                        <label class="radio-inline showall-container download_img"
                          (click)="clearSelectPage(downloadImagesModel)">
                          <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel"
                            [ngModelOptions]="{ standalone: true }" [value]="'all-pages'"
                            (ngModelChange)="downloadImagesPDF($event)" checked />{{ "Detail.allPages" | translate }}
                          <span class="showall-checkmark"></span>
                        </label>
                        <label class="radio-inline showall-container download_img"
                          (click)="clearSelectPage(downloadImagesModel)">
                          <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel"
                            [ngModelOptions]="{ standalone: true }" [value]="'current-page'"
                            (ngModelChange)="downloadImagesPDF($event)" />{{ "Detail.currentPage" | translate }}
                          <span class="showall-checkmark"></span>
                        </label>
                        <label class="radio-inline showall-container download_img" (click)="clearSelectPages()">
                          <input type="radio" name="downloadImages" [ngModel]="downloadImagesModel"
                            [ngModelOptions]="{ standalone: true }" [value]="'selected-page'"
                            (ngModelChange)="downloadImagesPDF($event)" />{{ "Detail.selectPages" | translate }}
                          <span class="showall-checkmark"></span>
                        </label>
                        <input *ngIf="downloadImagesModel == 'selected-page'" [(ngModel)]="pagesToDownload"
                          (ngModelChange)="selectPageChanges()" [ngModelOptions]="{ standAlone: true }"
                          class="downloadimage-txtbox" type="text" value="" placeholder="1,5-7,9,11-12" />
                        <div class="error text-danger errorMsg">
                          {{ errorMsg }}
                        </div>
                        <div class="text-center downloadbtn-div">
                          <button class="btn btn-primary btn-download" [disabled]="
                              isDownloadingInProgress == true ||
                              isInvalidPageNumber == true
                            " (click)="downloadImageToPDF()">
                            {{
                            isDownloadingInProgress === true
                            ? downloadingText
                            : downloadText
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div [ngClass]="{
                        downloadGeoTiffContainer: isTiffAvailableFromDocInfo,
                        hideImage: !isTiffAvailableFromDocInfo
                      }" class="">
                      <h4 class="brightness-menubar-title">
                        {{ "Detail.downloadAsTiff" | translate }}
                      </h4>
                      <div class="download-button-brightness-control brightness-control">
                        <label class="radio-inline showall-container download_img"
                          (click)="clearSelectPage(downloadTiffImages)">
                          <input type="radio" name="downloadTiffImages" [ngModel]="downloadTiffModel"
                            [ngModelOptions]="{ standalone: true }" [value]="'all-pages'"
                            (ngModelChange)="downloadTiffImages($event)" checked />{{ "Detail.allImagesTiff" | translate
                          }}
                          <span class="showall-checkmark"></span>
                        </label>

                        <label *ngIf="!documentData?.preMeridianExists"
                          class="radio-inline showall-container download_img"
                          (click)="clearSelectPage(downloadTiffImages)">
                          <input type="radio" name="downloadTiffImages" [ngModel]="downloadTiffModel"
                            [ngModelOptions]="{ standalone: true }" [value]="'all-geopages'"
                            (ngModelChange)="downloadTiffImages($event)" checked />{{ "Detail.allImagesGeoTiff" |
                          translate }}
                          <span class="showall-checkmark"></span>
                        </label>

                        <label class="radio-inline showall-container download_img"
                          (click)="clearSelectPage(downloadTiffImages)">
                          <input type="radio" name="downloadTiffImages" [ngModel]="downloadTiffModel"
                            [ngModelOptions]="{ standalone: true }" [value]="'current-page'"
                            (ngModelChange)="downloadTiffImages($event)" />{{ "Detail.currentPage" | translate }}
                          <span class="showall-checkmark"></span>
                        </label>
                        <label class="wiley-admin-text check-container"
                          title="{{ 'Detail.downloadGeoTiff' | translate }}" *ngIf="!isTiffAvailable"
                          [ngClass]="{ disabled: !isTiffAvailable }">
                          <input type="checkbox" class="disable-label" name="downloadGeoTiff" />
                          <span>{{ "Detail.noCoordinates" | translate }}</span>
                          <span class="checkmark"></span>
                        </label>
                        <label class="wiley-admin-text check-container"
                          title="{{ 'Detail.downloadGeoTiff' | translate }}" *ngIf="isTiffAvailable" [ngClass]="{
                            disabled:
                              isTiffAvailable &&
                              downloadTiffModel === 'all-pages'
                          }">
                          <input type="checkbox" class="disable-label tiff-check-box"
                            (change)="checkEnableGeoTiff($event)" name="downloadGeoTiff" />
                          <span>{{
                            "Detail.downloadGeoTiff" | translate
                            }}</span>
                          <span>{{
                            "Detail.downloadGTsubText" | translate
                            }}</span>
                          <span class="checkmark"></span>
                        </label>
                        <div class="text-center downloadbtn-div">
                          <button class="btn btn-primary btn-download" [disabled]="isDownloadingInProgress == true"
                            (click)="downloadGeoTiff()">
                            {{
                            isDownloadingInProgress === true
                            ? downloadingText
                            : downloadGText
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li *ngIf="
                  options.showDownload &&
                  isShowFloatIcons &&
                  isTrialCustomerUser
                " class="download-button image-viewer-flip-list">
                <span class="spriteico downloadPDFIcon imageviewer-float-image cursor-disable"
                  title="{{ 'Detail.downloadNotAvailable' | translate }}"></span>
              </li>
              <li *ngIf="
                  options.showPrint &&
                  isShowFloatIcons &&
                  !isTrialCustomerUser &&
                  !isGuestUser
                " class="print-button image-viewer-flip-list" (click)="printImageMenu()">
                <span class="spriteico print-icon imageviewer-float-image spriteico_mob spriteico_mob-print" title="{{ 'Detail.printImage' | translate }} ({{
                    shortcutKeyTitle
                  }} + O)"></span>
                <ul class="dropdown-menu dropdown-menu-left brightness-menubar-body dropdown-menu-position" role="menu"
                  [ngStyle]="{ display: isPrintMenuOpen ? 'block' : 'none' }" (click)="$event.stopPropagation()">
                  <h4 class="brightness-menubar-title">
                    {{ "Detail.printImage" | translate }}
                  </h4>
                  <div class="image-viewer-brightness-control brightness-control">
                    <label class="radio-inline showall-container download_img"
                      (click)="clearSelectPage(printImageModel)">
                      <input type="radio" name="printImages" [ngModel]="printImageModel"
                        [ngModelOptions]="{ standalone: true }" [value]="'all-pages'"
                        (ngModelChange)="setPrintImageModel($event)" checked />{{ "Detail.allPages" | translate }}
                      <span class="showall-checkmark"></span>
                    </label>
                    <label class="radio-inline showall-container download_img"
                      (click)="clearSelectPage(printImageModel)">
                      <input type="radio" name="printImages" [ngModel]="printImageModel"
                        [ngModelOptions]="{ standalone: true }" [value]="'current-page'"
                        (ngModelChange)="setPrintImageModel($event)" />{{ "Detail.currentPage" | translate }}
                      <span class="showall-checkmark"></span>
                    </label>
                    <label class="radio-inline showall-container download_img" (click)="clearSelectPages()">
                      <input type="radio" name="printImages" [ngModel]="printImageModel"
                        [ngModelOptions]="{ standalone: true }" [value]="'selected-page'"
                        (ngModelChange)="setPrintImageModel($event)" />{{ "Detail.selectPages" | translate }}
                      <span class="showall-checkmark"></span>
                    </label>
                    <input *ngIf="printImageModel == 'selected-page'" [(ngModel)]="pagesToPrint"
                      [ngModelOptions]="{ standAlone: true }" (ngModelChange)="selectPageChanges()"
                      class="downloadimage-txtbox" type="text" value="" placeholder="1,5-7,9,11-12" />
                    <div class="error text-danger errorMsg">{{ errorMsg }}</div>
                    <div class="text-center downloadbtn-div">
                      <button class="btn btn-primary btn-download" [disabled]="
                          isPrintInProgress == true ||
                          isInvalidPageNumber == true
                        " (click)="printImage()">
                        {{
                        isPrintInProgress === true
                        ? retrievingText
                        : printText
                        }}
                      </button>
                    </div>
                  </div>
                </ul>
              </li>
              <li *ngIf="
                  options.showPrint && isShowFloatIcons && isTrialCustomerUser
                " class="spriteico print-icon imageviewer-float-image">
                <span class="spriteico print-icon imageviewer-float-image cursor-disable"
                  title="{{ 'Detail.printNotAvailable' | translate }}"></span>
              </li>
              <li *ngIf="isShowFloatIcons" class="image-viewer-flip-list" id="image-viewer-zoom-in"
                (click)="zoomImage(1)">
                <span class="spriteico spriteico_mob spriteico_mob-zoomin zoomFlipIcon imageviewer-float-image" title="{{ 'Detail.zoomIn' | translate }} ({{
                    shortcutKeyTitle
                  }} + '=')"></span>
              </li>
              <li *ngIf="options.showZoom && isShowFloatIcons" class="image-viewer-flip-list" id="image-viewer-zoom-out"
                (click)="zoomImage(-1)">
                <span class="spriteico zoomout-flipicon imageviewer-float-image spriteico_mob spriteico_mob-zoom-out"
                  title="{{ 'Detail.zoomOut' | translate }} ({{
                    shortcutKeyTitle
                  }} + '_')"></span>
              </li>
              <li *ngIf="!isShowFloatIcons" class="image-viewer-flip-list" (click)="selectImage()">
                <span class="crop-image-icon selected imageviewer-float-image" title="{{ 'Detail.crop' | translate }} ({{
                    shortcutKeyTitle
                  }} + Insert)"></span>
              </li>
            </div>
            <span *ngIf="documentCurrentIndex > 1 && !isImageviewerMenuOpen"
              class="fa-3x fa fa-arrow-circle-left left-fa-arrow" (click)="gotoPreviousDocumentFullText()"
              title="{{ 'Detail.pageUp' | translate }}"></span>
            <div class="image-viewer-frame-loader" [ngStyle]="{ display: imageviewerframeLoader ? 'block' : 'none' }">
              <img alt="{{ 'Alt.loaderImage' | translate }}" class="img-icon" src="assets/images/three-dots.svg" />
            </div>
            <iframe id="printf" [hidden]="true" name="printf" height="0px" width="0px"></iframe>
            <ng-container>
              <app-osd-image-viewer [isGeoTiffEnabled]="isGeoTiffCheckBoxEnabled" [isDownLoadPdf]="isDownLoadPdf"
                [downloadPageURL]="downloadPageURL" [downLoadTiffImageURL]="downLoadTiffImageURL"
                [isInFullScreenMode]="isInFullScreenMode" [angle]="currentAngle" [restoreImage]="restoreOriginalImage"
                [fitToWidth]="fitToWidth" [loadImage]="imageConfig" [zoomIn]="_callZoomIn" [zoomOut]="_callZoomOut"
                [contrast]="currentContrast" [invert]="isInverted" [brightness]="currentBrightness"
                [overlays]="osdOverlays" (imageloaded)="isImageLoaded($event)" [selection]="selection"
                (selectionPerformed)="selectionPerformed($event)" (overlayClicked)="onOverlayClick($event)"
                [currentArchiveName]="documentData">
              </app-osd-image-viewer>
            </ng-container>
            <span *ngIf="
                documentCurrentIndex < currentDocumentImages?.length &&
                !isImageviewerMenuOpen
              " class="fa-3x fa fa-arrow-circle-right right-fa-arrow" (click)="gotoNextDocumentFullText()"
              title="{{ 'Detail.pageDown' | translate }}"></span>
          </div>
          <div id="fullTextTab" [class.hideImage]="!checkModel.fullText">
            <ng-template>
              <span [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.fullText' | translate }} ({{
                  controlShortcut
                }} + P)">{{ "Detail.fullText" | translate }}
              </span>
            </ng-template>
            <div [class.arabic-style]="isArabicLanguage" class="disclaimer-toggle" (click)="disclaimerToggle()">
              <i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-down" title="Expand Disclaimer"
                *ngIf="toggledisclaimerShow" aria-hidden="true"></i>
              <i [class.arabic-style]="isArabicLanguage" class="fa fa-chevron-up" title="Collapse Disclaimer"
                *ngIf="!toggledisclaimerShow" aria-hidden="true"></i>
            </div>
            <div [class.arabic-style]="isArabicLanguage" class="col-md-12 col-sm-12 padding-top15 disclaimer-div"
              [class.toggledDisclaimer]="toggledisclaimerShow"
              [ngStyle]="{ 'font-size': isTamilLanguage ? '12px' : '' }"
              [innerHTML]="disclimerContentText ? disclimerContentText : 'Test'"></div>
            <div class="col-md-12 col-sm-12 padding-top1  5">
              <div class="full-text-wrapper language-popover">
                <ul class="full-text-clearMarginPadding" style="display: none">
                  <li *ngIf="!documentCurrentIndex || documentCurrentIndex > 1" class="full-text-left_arrow">
                    <span class="full-text-navigation-arrows-viewer i-full-text-leftArrow"
                      (click)="gotoPreviousDocumentFullText()">
                    </span>
                  </li>
                  <li>
                    <label [class.arabic-style]="isArabicLanguage" class="full-text-pagination-label">{{ "Detail.page" |
                      translate }}
                      <input [class.arabic-style]="isArabicLanguage" type="text" #pageNo (keypress)="keyPress($event)"
                        title="{{ 'Detail.pageNumber' | translate }} {{
                          documentCurrentIndex
                        }}" class="full-text-pagination-page" [ngModel]="documentCurrentIndex" (keyup.enter)="
                          gotoCurrentDocumentFulltext($event, false)
                        " />
                      {{ "Detail.OF" | translate }}
                      {{ documentImages?.length }}
                    </label>
                  </li>
                  <li *ngIf="documentImages.length != documentCurrentIndex" class="full-text-right_arrow">
                    <span class="full-text-navigation-arrows-viewer i-full-text-rightArrow"
                      (click)="gotoNextDocumentFullText()">
                    </span>
                  </li>
                </ul>

                <button [class.arabic-style]="isArabicLanguage"
                  class="btn-default btn btn-link copy-btn btn-xs full full-text-btn-height"
                  title="{{ currentLanguageForTranslate }}" type="button" [ngClass]="{ popoverSelect: toggleviewlist }"
                  aria-hidden="true" [outsideClick]="true" [popover]="translateDocTemplate" #translatepop="bs-popover"
                  triggers="click:click" [placement]="'bottom'" (click)="
                    translateTrigger(); toggleTranslate = !toggleTranslate
                  ">
                  <span class="fa fa-language"></span>&nbsp;
                  {{ currentLanguageForTranslate }}
                  <span class="fa translate-downicn" [class.fa-chevron-down]="toggleArrow"
                    [class.fa-chevron-up]="!toggleArrow"></span>
                </button>
                <ng-template #translateDocTemplate>
                  <ul class="popoverUl">
                    <div class="language-popover-scroll">
                      <!-- <perfect-scrollbar [config]="config" class="language-popover-scroll"> -->
                        <li *ngFor="let language of translateLanguageValues; let i=index;"  [ngClass]="{'selectedTextColor': language.selectedlang === true}">
                          <span *ngIf="this.documentData.languages.length > 0 && i == 0" class="langangeTitle AvailableSource">Document Language(s)</span>   
                          <span *ngIf="this.documentData.languages.length > 0 && i == this.documentData.languages.length" class="langangeTitle">Other Languages</span>  
                        <span class="popover_inner_span" [ngClass]="{
                            'primary-text-color':
                              language.key === currentLanguageForTranslate
                          }" (click)="
                            translatepop.hide();
                            translateDoc(language.value, language.key) ">{{ language.key }}</span>
                      </li>
                      <!-- </perfect-scrollbar> -->
                    </div>
                  </ul>
                </ng-template>
              </div>
              <div class="full_text float-right" *ngIf="documentFulltext">
                <button [class.arabic-style]="isArabicLanguage" *ngIf="
                    documentFulltext != null &&
                    documentFulltext != '' &&
                    documentFulltext != ' <br>'
                  " class="btn-default btn btn-link copy-btn btn-xs full full-text-btn-height fulltext-dowunload-btn"
                  type="button" (click)="fulltextCopy(documentFulltext)"
                  title="{{ 'Detail.copytoClipboard' | translate }}">
                  <i class="fa fa-copy"></i>&nbsp;{{
                  "Detail.copytoClipboard" | translate
                  }}
                </button>
                <button [class.arabic-style]="isArabicLanguage" *ngIf="
                    documentFulltext != null &&
                    documentFulltext != '' &&
                    documentFulltext != ' <br>' &&
                    !isTrialCustomerUser
                  "
                  class="btn-default btn btn-link copy-btn btn-xs full-text-btn-height fulltext-download-btn fulltext-dowunload-btn"
                  [ngClass]="{ popoverSelect: toggleviewlist }" type="button" (click)="saveTextAsFile()"
                  title="{{ 'Detail.dpwnloadThisPage' | translate }}">
                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp;{{
                    "Detail.dpwnloadThisPage" | translate
                    }}
                </button>
                <button *ngIf="!isTrialCustomerUser" [class.arabic-style]="isArabicLanguage"
                  class="btn-default btn btn-link copy-btn btn-xs full-text-btn-height fulltext-download-btn fulltext-dowunload-btn"
                  type="button" (click)="downloadFullText(documentData?.wileyId)"
                  title="{{ 'Detail.downloadEntire' | translate }}">
                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp;{{
                  "Detail.downloadEntire" | translate
                  }}
                </button>

                <button [class.arabic-style]="isArabicLanguage" *ngIf="
                    documentFulltext != null &&
                    documentFulltext != '' &&
                    documentFulltext != ' <br>' &&
                    isTrialCustomerUser
                  " class="btn-default btn btn-link copy-btn btn-xs full-text-btn-height cursor-disable"
                  [ngClass]="{ popoverSelect: toggleviewlist }" type="button"
                  title="{{ 'Detail.downloadNotAvailable' | translate }}">
                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp;{{
                  "Detail.dpwnloadThisPage" | translate
                  }}
                </button>
                <button *ngIf="isTrialCustomerUser" [class.arabic-style]="isArabicLanguage"
                  class="btn-default btn btn-link copy-btn btn-xs full-text-btn-height cursor-disable" type="button"
                  title="{{ 'Detail.downloadNotAvailable' | translate }}">
                  <i class="fa fa-download" aria-hidden="true"></i>&nbsp;{{
                  "Detail.downloadEntire" | translate
                  }}
                </button>
              </div>
              <br clear="all" />
              <div class="full-text-body"></div>
              <div id="disclaimerContents" class="disclaimer-contents disclaimer-content-div"
                [class.disclaimer-toggle-content]="toggledisclaimerShow" [class.ta-lang]="isTamilLanguage" *ngIf="
                  documentFulltext != null &&
                  documentFulltext != '' &&
                  documentFulltext != ' <br>'
                ">
                <div class="disclaimer-content-scroll">
                  <!-- <perfect-scrollbar [config]="config" class="disclaimer-content-scroll"> -->
                  <div class="fulltext-image-loader" [ngStyle]="{ display: fullTextLoader ? 'block' : 'none' }">
                    <!-- <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg"> -->
                    <img alt="{{ 'Alt.loaderImage' | translate }}" class="img-icon"
                      src="assets/images/dot-loader-dark.gif" />
                  </div>
                  <span id="disclaimerContents" class="disclaimer-contents fulltext-parent margin-bottom0">
                    <pre *ngIf="disclimerContentText" id="rtl-language" class="docText"
                      [class.rtl-languages]="isArabicTransDoc">{{ documentFulltext }}</pre>
                    <pre *ngIf="!disclimerContentText" id="rtl-language" class="docTextSize"
                      [class.rtl-languages]="isArabicTransDoc">{{ documentFulltext }}</pre>
                  </span>
                  <!-- </perfect-scrollbar> -->
                </div>
              </div>
              <p class="disclaimer-contents" *ngIf="
                  documentFulltext == null ||
                  documentFulltext == '' ||
                  documentFulltext == ' <br>'
                ">
                {{ "Detail.noFullTextAvailable" | translate }}
              </p>
            </div>
          </div>
          <div *ngIf="isInFullScreenMode">
            <app-notifications></app-notifications>
          </div>

          <div class="backdrop-modal"></div>
          <div class="modal fade fullScreenDownload-popup" id="fullScreenDownloadPopup" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title text-center center-block">
                    <img alt="View History" class="downloadPopupIcon"
                      src="assets/images/icons/download@2x.png " />
                  </h4>
                </div>
                <div class="modal-body url_body">
                  <div class="downloadTextContainer download-txt-container">
                    <a [class.arabic-style]="isArabicLanguage" class="downlod-txt primary-text-color"
                      href="{{ downloadableUrl }}" target="_self" *ngIf="downloadUrlFor === 'excel'">{{
                      "Detail.excelText" | translate }}</a>
                    <span class="or-span" *ngIf="downloadUrlForCSV === 'csv'">
                      (OR)
                    </span>
                    <a [class.arabic-style]="isArabicLanguage" class="downlod-txt primary-text-color"
                      href="{{ downloadableCSVUrl }}" target="_blank" *ngIf="downloadUrlForCSV === 'csv'">{{
                      "Detail.csvText" | translate }}</a>
                    <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{ downloadableUrl }}"
                      target="_self" *ngIf="downloadUrlFor === 'pdf'">{{ "Detail.downloadTextDoc" | translate }}</a>
                    <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{ downloadableUrl }}"
                      target="_self" *ngIf="downloadUrlFor === 'fulltext'">{{ "Detail.downloadFulltext" | translate
                      }}</a>
                  </div>

                  <div class="modal-download-text">
                    <span [class.arabic-style]="isArabicLanguage" class="modal-download-text-span">
                      {{
                      "Detail.clicktoDownloadFileMessage" | translate
                      }}</span>
                  </div>
                </div>
                <div class="modal-footer">
                  <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary"
                    data-dismiss="modal" (click)="removeBackDrop()">
                    {{ "Detail.Close" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
<ng-template #shareHtmlPageTemplate>
  <div class="modal-popup-parentDiv">
    <div class="modal-header emailpopup-header">
      <h4 [class.arabic-style]="isArabicLanguage" class="emailpopup-heading">
        {{ "Detail.sendEmail" | translate }}
      </h4>
    </div>
    <div class="modal-body">
      <form class="form-horizontal sendMail" #sendMailForm="ngForm">
        <div class="form-group">
          <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="email">{{
            "Detail.from" | translate }}
            <!-- <span class="glyphicon glyphicon-asterisk"></span> -->
            <i class="fa fa-asterisk" aria-hidden="true"></i>
          </label>
          <div class="col-sm-10">
            <input type="email" #contactemailfrom="ngModel" class="form-control" id="emailFrom"
              placeholder="{{ 'Detail.fromPlaceholder' | translate }}" name="contactemailfrom"
              [(ngModel)]="shareMailData.from" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              required />
            <div class="" [hidden]="contactemailfrom.valid || contactemailfrom.untouched">
              <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="
                  contactemailfrom.errors && contactemailfrom.errors.required
                ">
                <h4 [class.arabic-style]="isArabicLanguage">
                  {{ "Detail.emailIsRequired" | translate }}
                </h4>
              </div>
              <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="
                  contactemailfrom.errors && contactemailfrom.errors.pattern
                ">
                <h4 [class.arabic-style]="isArabicLanguage">
                  {{ "Detail.invalidEmail" | translate }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="email">{{
            "Detail.recipient" | translate }}
            <!-- <span class="glyphicon glyphicon-asterisk"></span> -->
            <i class="fa fa-asterisk" aria-hidden="true"></i>
          </label>
          <div class="col-sm-10">
            <input type="email" #contactemailto="ngModel" class="form-control" id="emailTo"
              placeholder="{{ 'Detail.recipientPlaceholder' | translate }}" name="contactemailto"
              [(ngModel)]="shareMailData.to" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              required />
            <div class="" [hidden]="contactemailto.valid || contactemailto.untouched">
              <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                *ngIf="contactemailto.errors && contactemailto.errors.required">
                <h4 [class.arabic-style]="isArabicLanguage">
                  {{ "Detail.emailIsRequired" | translate }}
                </h4>
              </div>
              <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                *ngIf="contactemailto.errors && contactemailto.errors.pattern">
                <h4 [class.arabic-style]="isArabicLanguage">
                  {{ "Detail.invalidEmail" | translate }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="subject">{{
            "Detail.subject" | translate }}
            <!-- <span class="glyphicon glyphicon-asterisk"></span> -->
            <i class="fa fa-asterisk" aria-hidden="true"></i>
          </label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="subject"
              placeholder="{{ 'Detail.subjectPlaceholder' | translate }}" name="subject"
              [(ngModel)]="shareMailData.topic" #subject="ngModel" required />
            <div class="error text-danger" [hidden]="subject.untouched || shareMailData.topic.trim() !== ''">
              <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="
                  (subject.errors && subject.errors.required) ||
                  shareMailData.topic.trim() === ''
                ">
                <h4 [class.arabic-style]="isArabicLanguage">
                  {{ "Detail.requiredSubject" | translate }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="message">{{
            "Detail.Message" | translate }}
            <!-- <span class="glyphicon glyphicon-asterisk"></span> -->
            <i class="fa fa-asterisk" aria-hidden="true"></i>
          </label>
          <div class="col-sm-10">
            <textarea type="text" wrap="hard" class="form-control message-textarea" id="message" rows="10" cols="50"
              placeholder="{{ 'Detail.messagePlaceholder' | translate }}" name="message"
              [(ngModel)]="shareMailData.message" #message="ngModel" required></textarea>
            <div class="error text-danger" [hidden]="
                message.untouched || shareMailData.message.trim() !== ''
              ">
              <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left" *ngIf="
                  (message.errors && message.errors.required) ||
                  shareMailData.message.trim() === ''
                ">
                <h4 [class.arabic-style]="isArabicLanguage">
                  {{ "Detail.requiredMessage" | translate }}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div class="emailSend submitBtn">
          <div class="col-sm-12">
            <button [class.arabic-style]="isArabicLanguage" type="button" [disabled]="
                !sendMailForm.form.valid ||
                shareMailData.message.trim() === '' ||
                shareMailData.topic.trim() === ''
              " class="btn-primary btn" aria-label="Close" (click)="shareEmail()">
              {{ "Detail.Send" | translate }}
            </button>
          </div>
        </div>
        <!-- <tooltip-content #sendMailFormTooltip
          [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
          <div class="error text-danger " [hidden]="contactemailfrom.valid">
            <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
              {{ 'Detail.fromIsRequired' | translate }}
            </div>

          </div>
          <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
            <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailto.untouched || contactemailto.invalid">
              {{ 'Detail.recipientIsRequired' | translate }}
            </div>
          </div>
          <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
            <div [class.arabic-style]="isArabicLanguage" *ngIf="subject.untouched || subject.invalid">
              {{ 'Detail.subjectIsRequired' | translate }}
            </div>
          </div>
          <div class="error text-danger"
            [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
            <div [class.arabic-style]="isArabicLanguage"
              *ngIf="message.untouched || message.invalid || shareMailData.message.trim() === ''">
              {{ 'Detail.messageIsRequired' | translate }}
            </div>
          </div>
        </tooltip-content> -->
        <!-- <ng-template #sendMailFormTooltip [ngClass]="{
            hide: sendMailForm.form.valid,
            show:
              !sendMailForm.form.valid || shareMailData.message.trim() === ''
          }">
          <div class="error text-danger" [hidden]="contactemailfrom.valid">
            <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
              {{ "Detail.fromIsRequired" | translate }}
            </div>
          </div>
          <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid">
            <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailto.untouched || contactemailto.invalid">
              {{ "Detail.recipientIsRequired" | translate }}
            </div>
          </div>
          <div class="error text-danger" [hidden]="
              contactemailfrom.invalid ||
              contactemailto.invalid ||
              subject.valid
            ">
            <div [class.arabic-style]="isArabicLanguage" *ngIf="subject.untouched || subject.invalid">
              {{ "Detail.subjectIsRequired" | translate }}
            </div>
          </div>
          <div class="error text-danger" [hidden]="
              contactemailfrom.invalid ||
              contactemailto.invalid ||
              subject.invalid
            ">
            <div [class.arabic-style]="isArabicLanguage" *ngIf="
                message.untouched ||
                message.invalid ||
                shareMailData.message.trim() === ''
              ">
              {{ "Detail.messageIsRequired" | translate }}
            </div>
          </div>
        </ng-template>  -->
        <div class="emailClose closeBtn">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="btn-default btn"
            (click)="closeShareModal()">
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #downloadTableTemplate>
  <div class="modal-header download-title">
    <h4 class="modal-title text-center center-block">
      <!-- <span class="spriteico downloadPopupIcon"></span> -->
      <img src="assets/images/icons/download@2x.png " alt="View History" class="center-block downloadPopupIcon" />
    </h4>
  </div>
  <div class="modal-body url_body download-body">
    <div [class.arabic-style]="isArabicLanguage" class="download-text">
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{ downloadableUrl }}" target="_blank"
        *ngIf="downloadUrlFor === 'table'">{{ "Detail.downloadSpreadsheetFile" | translate }}</a>
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{ downloadableUrl }}" target="_blank"
        *ngIf="downloadUrlFor === 'pdf'">{{ "Detail.downloadTextDoc" | translate }}</a>
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{ downloadableUrl }}" target="_blank"
        *ngIf="downloadUrlFor === 'fulltext'">{{ "Detail.downloadFulltext" | translate }}</a>
    </div>
    <div class="modal-download-text download-text-cmt" [class.arabic-style]="isArabicLanguage">
      <span [class.arabic-style]="isArabicLanguage" class="modal-download-text-span">
        {{ "Detail.clicktoDownloadFileMessage" | translate }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary"
      (click)="downloadTablemodalRef.hide(); $event.stopPropagation()">
      {{ "Detail.Close" | translate }}
    </button>
  </div>
</ng-template>

<!-- <ng-template #downloadPageasPdfTemplate>
  <div class="modal-header  download-title">
    <h4 class="modal-title text-center">
      <img src="assets/images/icons/download@2x.png " alt="View History" class="center-block downloadPopupIcon" />
    </h4>
  </div>
  <div class="modal-body url_body download-body">
    <div [class.arabic-style]="isArabicLanguage" class="download-text">
      <a [class.arabic-style]="isArabicLanguage" class="primary-text-color" href="{{downloadPageURL}}" target="_blank">{{ 'Detail.downloadPDF' | translate }}</a>
    </div>
    <div class="modal-download-text download-text-cmt" [class.arabic-style]="isArabicLanguage">
      <span [class.arabic-style]="isArabicLanguage" class="modal-download-text-span">
        {{ 'Detail.clicktoDownloadFileMessage' | translate }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary" (click)="closeDownloadPdfModal();$event.stopPropagation();">
      {{ 'Detail.Close' | translate }}
    </button>
  </div>
</ng-template> -->

<ng-template #sourceCitationTemplate>
  <div class="modal-header">
    <div class="citation-header padding-0">
      <h4 [class.arabic-style]="isArabicLanguage" class="citation-pageheader cpyTitle">
        {{ "Detail.sourceCitation" | translate }}
      </h4>
    </div>
  </div>
  <div class="modal-body url_body">
    <div class="col-md-12 citation_type">
      <ul>
        <li>
          <label [class.arabic-style]="isArabicLanguage" class="citation_type_header">{{ "Detail.MLA" | translate }}
            <span [ngClass]="{ diasbledBtn: emptyCitation }">
              <span *ngIf="!isInIpadMode">
                <i tooltip="{{ 'Detail.copyClipboard' | translate }}" class="fa fa-files-o copyCitation"
                  aria-hidden="true" (click)="copyCitation(documentData?.citations?.MLA, 'MLA')"></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i class="fa fa-files-o copyCitation" (click)="copyCitation(documentData?.citations?.MLA, 'MLA')"></i>
              </span>
            </span>
          </label>

          <label class="fontNormal" [ngClass]="{ mycopyCitation: citaionMLA }" id="citationMla"
            [innerHtml]="documentData?.citations?.MLA" *ngIf="!emptyCitation" appDecodeEntities></label>
          <label *ngIf="emptyCitation"> {{ "Common.N/A" | translate }}</label>
        </li>
        <li>
          <label [class.arabic-style]="isArabicLanguage" class="citation_type_header">{{ "Detail.CHICAGO" | translate }}
            <span [ngClass]="{ diasbledBtn: emptyCitation }">
              <span *ngIf="!isInIpadMode">
                <i tooltip="{{ 'Detail.copyClipboard' | translate }}" class="fa fa-files-o copyCitation"
                  aria-hidden="true" (click)="
                    copyCitation(documentData?.citations?.CHICAGO, 'CHICAGO')
                  "></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i class="fa fa-files-o copyCitation" (click)="
                    copyCitation(documentData?.citations?.CHICAGO, 'CHICAGO')
                  "></i>
              </span>
            </span>
          </label>
          <label class="fontNormal" #citationChicago [ngClass]="{ mycopyCitation: citaionCHICAGO }"
            [innerHtml]="documentData?.citations?.CHICAGO" *ngIf="!emptyCitation" appDecodeEntities></label>
          <label *ngIf="emptyCitation"> {{ "Common.N/A" | translate }}</label>
        </li>
        <li>
          <label [class.arabic-style]="isArabicLanguage" class="citation_type_header">{{ "Detail.APA" | translate }}
            <span [ngClass]="{ diasbledBtn: emptyCitation }">
              <span *ngIf="!isInIpadMode">
                <i tooltip="{{ 'Detail.copyClipboard' | translate }}" placement="top" class="fa fa-files-o copyCitation"
                  aria-hidden="true" (click)="copyCitation(documentData?.citations?.APA, 'APA')"></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i placement="top" class="fa fa-files-o copyCitation"
                  (click)="copyCitation(documentData?.citations?.APA, 'APA')"></i>
              </span>
            </span>
          </label>
          <label class="fontNormal" #citationApa [ngClass]="{ mycopyCitation: citaionAPA }"
            [innerHtml]="documentData?.citations?.APA" *ngIf="!emptyCitation" appDecodeEntities></label>
          <label *ngIf="emptyCitation"> {{ "Common.N/A" | translate }}</label>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary"
      (click)="closeSourceCitation()">
      {{ "Detail.Close" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #bookmarkHtmlPageTemplate>
  <div class="modal-header">
    <h4 class="bookmark-header">
      {{ "Detail.bookmarkURL" | translate }}
    </h4>
    <h4 class="modal-title sa-icon sa-info pulseWarning"></h4>
    <!-- <h4 *ngIf="!showClipBoardSuccessAlert" class="modal-title sa-icon sa-info pulseWarning">
    </h4> -->
    <!-- <h4 *ngIf="showClipBoardSuccessAlert" class="modal-title sa-icon sa-success pulseWarning">
      <span class="sa-line sa-tip animateSuccessTip"></span>
      <span class="sa-line sa-long animateSuccessLong"></span>
    </h4> -->
  </div>
  <div class="modal-body url_body">
    <!-- <div [class.arabic-style]="isArabicLanguage" *ngIf="showClipBoardSuccessAlert" class="alert alert-success">
      {{ 'Detail.urlCopied' | translate }}</div> -->
    <div class="well">
      <span id="bookmarkPageURLonTemplate">{{ bookmarkPageURL }}</span>
    </div>
    <div [class.arabic-style]="isArabicLanguage" *ngIf="bookmarkPageURL" class="share-doc-message">
      <span [class.arabic-style]="isArabicLanguage" class="share-doc-message-span">{{
        "Detail.shareDocumentBookmarkMessage" | translate }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary"
      (click)="closeBookMarkModal()">
      {{ "Detail.Close" | translate }}
    </button>
    <!-- <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" aria-label="Close"
      (click)="copytoClipBoard(bookmarkPageURL)">
      {{ 'Detail.copyClipboard' | translate }}
    </button> -->
  </div>
</ng-template>

<ng-template #citationPageTemplate>
  <div class="modal-header citationHeader">
    <h4 [class.arabic-style]="isArabicLanguage" class="modal-title illustration-title cpyTitle pull-left">
      {{ "Detail.copyCitation" | translate }}
    </h4>
    <button type="button" class="fa fa-close citation-closebtn" (click)="closeCitation()">
      <span class="sr-only">{{ "Common.closeButton" | translate }}</span>
    </button>
  </div>
  <div class="modal-body citationBody">
    <div class="citationInnerHtml">
      <ul>
        <li>
          <label class="citation_type_header">
            <span [class.arabic-style]="isArabicLanguage">{{ "Detail.MLA" | translate }}
            </span>
            <span class="float-right" [ngClass]="{ diasbledBtn: emptyCitation }">
              <span *ngIf="!isInIpadMode">
                <i class="copyCitation fa fa-copy" tooltip="{{ 'Detail.copyClipboard' | translate }}" aria-hidden="true"
                  (click)="copyCitation(citationRes?.MLA, 'ModelMLA')"></i>
                <i class="fa fa-download download-Citation" download
                  tooltip="{{ 'Detail.clickToDownload' | translate }}" aria-hidden="true"
                  (click)="downloadCitation(citationRes?.MLA)"></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i class="copyCitation fa fa-copy" (click)="copyCitation(citationRes?.MLA, 'ModelMLA')"></i>
                <i class="fa fa-download download-Citation" download (click)="downloadCitation(citationRes?.MLA)"></i>
              </span>
            </span>
          </label>
          <label id="citationMla" class="cslEntry" [ngClass]="{ mycopyCitation: modelcitaionMLA }" #citationMla
            [innerHtml]="citationRes?.MLA" *ngIf="!emptyCitation" appDecodeEntities></label>
          <label *ngIf="emptyCitation"> {{ "Common.N/A" | translate }}</label>
        </li>
        <li>
          <label class="citation_type_header">
            <span [class.arabic-style]="isArabicLanguage">{{ "Detail.CHICAGO" | translate }}
            </span>
            <span class="float-right" [ngClass]="{ diasbledBtn: emptyCitation }">
              <span *ngIf="!isInIpadMode">
                <i tooltip="{{ 'Detail.copyClipboard' | translate }}" class="copyCitation fa fa-copy" aria-hidden="true"
                  (click)="copyCitation(citationRes?.CHICAGO, 'ModelCHICAGO')"></i>
                <i class="fa fa-download download-Citation" tooltip="{{ 'Detail.clickToDownload' | translate }}"
                  aria-hidden="true" (click)="downloadCitation(citationRes?.CHICAGO)"></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i class="copyCitation fa fa-copy" (click)="copyCitation(citationRes?.CHICAGO, 'ModelCHICAGO')"></i>
                <i class="fa fa-download download-Citation" (click)="downloadCitation(citationRes?.CHICAGO)"></i>
              </span>
            </span>
          </label>
          <label id="citationChicago" #citationChicago [ngClass]="{ mycopyCitation: modelcitaionCHICAGO }"
            class="cslEntry" [innerHtml]="citationRes?.CHICAGO" *ngIf="!emptyCitation" appDecodeEntities></label>
          <label *ngIf="emptyCitation"> {{ "Common.N/A" | translate }}</label>
        </li>
        <li>
          <label class="citation_type_header">
            <span [class.arabic-style]="isArabicLanguage">{{ "Detail.APA" | translate }}
            </span>
            <span class="float-right" [ngClass]="{ diasbledBtn: emptyCitation }">
              <span *ngIf="!isInIpadMode">
                <i placement="top" class="copyCitation fa fa-copy" tooltip="{{ 'Detail.copyClipboard' | translate }}"
                  aria-hidden="true" (click)="copyCitation(citationRes?.APA, 'ModelAPA')"></i>
                <i class="fa fa-download download-Citation" tooltip="{{ 'Detail.clickToDownload' | translate }}"
                  aria-hidden="true" (click)="downloadCitation(citationRes?.APA)"></i>
              </span>
              <span *ngIf="isInIpadMode">
                <i placement="top" class="copyCitation fa fa-copy"
                  (click)="copyCitation(citationRes?.APA, 'ModelAPA')"></i>
                <i class="fa fa-download download-Citation" (click)="downloadCitation(citationRes?.APA)"></i>
              </span>
            </span>
          </label>
          <label id="citationApa" #citationApa class="cslEntry" [ngClass]="{ mycopyCitation: modelcitaionAPA }"
            [innerHtml]="citationRes?.APA" *ngIf="!emptyCitation" appDecodeEntities></label>
          <label *ngIf="emptyCitation"> {{ "Common.N/A" | translate }} </label>
        </li>
      </ul>
    </div>
    <div>
      <h4 [class.arabic-style]="isArabicLanguage" class="modal-title illustration-title">
        {{ "Detail.exportCitation" | translate }}
      </h4>
      <div>
        <ul class="exportUl" [ngClass]="{ diasbledBtn: emptyCitation }">
          <li class="export" (click)="exportRIS(EndNote)">
            <a class="exportCitation" id="EndNote" #EndNote>
              <span [class.arabic-style]="isArabicLanguage" class="btn-link">{{
                "Detail.exporttoEndNote" | translate
                }}</span>
            </a>
          </li>
          <li class="export" (click)="exportRIS(ProCite)">
            <a [class.arabic-style]="isArabicLanguage" class="exportCitation" id="ProCite" #ProCite>
              <span [class.arabic-style]="isArabicLanguage">{{
                "Detail.exportProCite" | translate
                }}</span>
            </a>
          </li>
          <li class="export" (click)="exportRIS(ReferenceManager)">
            <a [class.arabic-style]="isArabicLanguage" class="exportCitation" id="ReferenceManager" #ReferenceManager>
              <span [class.arabic-style]="isArabicLanguage">{{
                "Detail.exportReferenceManager" | translate
                }}</span>
            </a>
          </li>
          <li class="export" (click)="exportRIS(Zotero)">
            <a [class.arabic-style]="isArabicLanguage" class="exportCitation" id="Zotero" #Zotero>
              <span [class.arabic-style]="isArabicLanguage">{{
                "Detail.exportZotero" | translate
                }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div [class.arabic-style]="isArabicLanguage" class="float-left text_left">
      {{ "Detail.exportNote" | translate }}
    </div>
  </div>
</ng-template>

<ng-template #downloadPrintTemplate>
  <div class="modal-popup-parentDiv download-popup-div">
    <div class="modal-header download-popup-header"></div>
    <div class="modal-body">
      {{ retrievingText }}
    </div>
  </div>
</ng-template>
<ng-template #itemNotesTemplate>
  <div class="modal-header warning-modal-header text-center d-block">
    <h6 [class.arabic-style]="isArabicLanguage" class="warnings-headerlbl no-margin">
      {{ "Detail.itemNotes" | translate }}
    </h6>
  </div>
  <div class="modal-body itemnote-content">
    <ng-container *ngFor="let notes of itemNotes">
      <div class="documentType">
        <!-- <label class="citation-contentlbl">{{notes?.type}}</label> -->
        <label *ngIf="
            notes?.type !== 'Additional Physical Form Available Note' &&
            notes?.type !== 'Local_Tags_by_Library' &&
            notes?.type !== 'Local Tags by Library' &&
            notes?.type !== 'Immediate Source of Acquisition Note'
          " class="citation-contentlbl">{{ notes?.type }}</label>
        <label *ngIf="
            documentData?.docType !== 'Photographs' &&
            notes?.type === 'Additional Physical Form Available Note'
          " class="citation-contentlbl">{{ notes?.type }}</label>

        <ul *ngFor="let notesValue of notes?.value">
          <li *ngIf="
              notes?.type !== 'Geographical Coordinates' &&
              notes?.type !== 'Local_Tags_by_Library' &&
              notes?.type !== 'Additional Physical Form Available Note' &&
              notes?.type !== 'Local Tags by Library' &&
              notes?.type !== 'Immediate Source of Acquisition Note'
            " class="citation-content">
            {{ notesValue }}
          </li>
          <li *ngIf="
              documentData?.docType !== 'Photographs' &&
              notes?.type === 'Additional Physical Form Available Note'
            " class="citation-content">
            {{ notesValue }}
          </li>

          <!-- <ng-container *ngIf="notes?.type === 'Geographical Coordinates'" class="citation-content">

            <ul *ngFor="let docValue of geoBoundaries" class="document-type-list geoboundry-list">
              <ul class="document-type-list geoboundry-list item-geocoordinates-list">
                <p class="item-geo-cooridates-title">{{docValue.title}}</p>
                <ng-container *ngFor="let val of docValue['coOrdinates']; let index = index;">
                  <ng-container [ngSwitch]="index">
                    <li *ngSwitchCase="0">
                      <label class="item-coordinates-title">
                        North West Latitude & Longitude
                      </label>
                      <p class="co-ordinates-texts">{{ docValue['coOrdinates'][index] }}</p>
                    </li>
                    <li *ngSwitchCase="1">
                      <label class="item-coordinates-title">
                        South West Latitude & Longitude
                      </label>
                      <p class="co-ordinates-texts">{{ docValue['coOrdinates'][index] }}</p>
                    </li>
                    <li *ngSwitchCase="2">
                      <label class="item-coordinates-title">
                        South East Latitude & Longitude
                      </label>
                      <p class="co-ordinates-texts">{{ docValue['coOrdinates'][index] }}</p>
                    </li>
                    <li *ngSwitchCase="3">
                      <label class="item-coordinates-title">
                        North East Latitude & Longitude
                      </label>
                      <p class="co-ordinates-texts">{{ docValue['coOrdinates'][index] }}</p>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </ul>
          </ng-container> -->
        </ul>

        <ng-container *ngIf="notes?.visible" class="citation-content">
          <ul *ngFor="let docValue of notesGeoBoundaries" class="document-type-list geoboundry-list">
            <ul class="document-type-list geoboundry-list item-geocoordinates-list">
              <p class="item-geo-cooridates-title">{{ docValue.title }}</p>
              <ng-container *ngFor="let val of docValue['coOrdinates']; let index = index">
                <ng-container [ngSwitch]="index">
                  <li *ngSwitchCase="0">
                    <label class="item-coordinates-title">
                      North West Latitude & Longitude
                    </label>
                    <p class="co-ordinates-texts">
                      {{ docValue["coOrdinates"][index] }}
                    </p>
                  </li>
                  <li *ngSwitchCase="1">
                    <label class="item-coordinates-title">
                      South West Latitude & Longitude
                    </label>
                    <p class="co-ordinates-texts">
                      {{ docValue["coOrdinates"][index] }}
                    </p>
                  </li>
                  <li *ngSwitchCase="2">
                    <label class="item-coordinates-title">
                      South East Latitude & Longitude
                    </label>
                    <p class="co-ordinates-texts">
                      {{ docValue["coOrdinates"][index] }}
                    </p>
                  </li>
                  <li *ngSwitchCase="3">
                    <label class="item-coordinates-title">
                      North East Latitude & Longitude
                    </label>
                    <p class="co-ordinates-texts">
                      {{ docValue["coOrdinates"][index] }}
                    </p>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </ul>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer warnings-footer">
    <button [class.arabic-style]="isArabicLanguage" [class.arabic-style]="isArabicLanguage" type="button"
      class="btn btn-primary" (click)="closeItemsNotePopup(); $event.stopPropagation()">
      {{ "HomePage.closeBtn" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #languagePageTemplate>
  <div>
    <div class="modal-header">
      <h4 class="modal-title sa-icon sa-warning pulseWarning">
        <span class="sa-body pulseWarningIns"></span>
        <span class="sa-dot pulseWarningIns"></span>
      </h4>
    </div>
    <div class="modal-body search_body">
      <div [class.arabic-style]="isArabicLanguage" class="alert_warning download-status-text">
        {{ "Detail.downloadStatus" | translate }}
      </div>
    </div>
    <div class="modal-footer">
      <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" aria-label="Close"
        (click)="closelanguagePageTemplate()">
        {{ "Detail.closeLanguagePopup" | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #warningsTemplate>
  <div class="modal-header warning-modal-header text-center d-block">
    <h6 [class.arabic-style]="isArabicLanguage" class="warnings-headerlbl no-margin">
      {{ "HomePage.warning" | translate }}
    </h6>
  </div>
  <div class="modal-body warning_bdy">
    <ng-container *ngFor="let wo of warningObj; let last = last">
      <h4 class="text-left" [innerHTML]="wo.title | translate"></h4>
      <div class="warning-message" [innerHTML]="wo.message | translate"></div>
      <hr *ngIf="!last" class="divider" />
    </ng-container>
  </div>

  <div class="modal-footer warnings-footer">
    <button [class.arabic-style]="isArabicLanguage" type="button" appAutofocus class="btn btn-primary"
      (click)="closeWarningPopup(); $event.stopPropagation()">
      {{ "HomePage.closeBtn" | translate }}
    </button>
  </div>
</ng-template>

<ng-container *ngIf="isGuestUser">
  <span class="learn-more-icn learn-more-link" (click)="gotoWileyHelpPage()" (mouseenter)="learnMoreMouseEnter($event)"
    (mouseleave)="learnMoreMouseLeave($event)">
    <span class="learn-more-icon-span"></span></span>
</ng-container>
<ng-container *ngIf="showLearnMoreContent">
  <p class="learn-more-content">
    {{ "Detail.wileyLearnMoreText" | translate }}
  </p>
</ng-container>

<!-- explorer coverflow data  -->
<app-explorer-coverflow [contentType]="contentType" [start]="startValueFromParams"
  [advancedFilterParamForCoverFlow]="advancedFilterParamForCoverFlow" [documentData]="documentData"
  [toggleCoverFlow]="toggleCoverFlow" [mapCoordinatesNotNull]="mapCoordinatesNotNull" [ignoreFullText]="ignoreFullText"
  [spatialFilterParams]="spatialFilterParams" [dynamicComponentData]="dynamicComponentData"
  [hideCoverFlow]="hideCoverFlow"></app-explorer-coverflow>

<div class="dropdown-menu shareWidth" [class.visibleMobileShare]="toggleviewlist" *ngIf="!isGuestUser && isMobileView"
  role="menu" [ngStyle]="{ display: toggleviewlist ? 'flex' : 'none' }">
  <div class="shareLimitedPanel">
    <h4>{{ "Detail.shareIntenalUsers" | translate }}</h4>
    <p>{{ "Detail.wileyInternalShareContent" | translate }}</p>
    <span class="bookMarkLink">{{ bookmarkShortURL }}</span>
    <ul [class.arabic-style]="isArabicLanguage" (click)="$event.stopPropagation()" id="shareIconsWidth">
      <li style="display: inline-flex">
        <!-- <div class="mailBtn m-r-6">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon"
            (click)="shareMailClick(bookmarkShortURL)" title="{{ 'Detail.shareByMail' | translate }}">
            <i class="fa fa-envelope"></i>
            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Detail.mailIcon' | translate }}</span>
          </button>
        </div>
        <div class="mailBtn m-r-6">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon share-bg"
            (click)="copyShareUrl(bookmarkShortURL)" title="{{ 'Detail.copytoClipboard' | translate }}">
            <i class="fa fa-files-o fa-lg" aria-hidden="true"></i>
            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{'Common.Copy' |translate}}</span>
          </button>
        </div> -->
        <a class="resp-sharing-button__link mailBtns" (click)="shareMailClick(bookmarkShortURL)"
          title="{{ 'Detail.shareByMail' | translate }}">
          <i class="fa fa-envelope copyIcon"></i>
          <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{
            "Detail.mailIcon" | translate
            }}</span>
        </a>
        <a class="resp-sharing-button__link copyBtn" (click)="copyShareUrl(bookmarkShortURL)"
          title="{{ 'Detail.copytoClipboard' | translate }}">
          <i class="fa fa-files-o fa-lg copyIcon" aria-hidden="true"></i>
          <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{
            "Common.Copy" | translate
            }}</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="vLine"></div>
  <div class="shareAllPanel">
    <h4>{{ "Detail.shareExternalUsers" | translate }}</h4>
    <p>{{ "Detail.wileyExternalShareContent" | translate }}</p>
    <span class="bookMarkLink">{{ shareUrl }}</span>
    <ul [class.arabic-style]="isArabicLanguage" (click)="$event.stopPropagation()" id="shareIconsWidth">
      <li style="display: inline-flex">
        <!-- <div class="mailBtn m-r-6">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon"
            (click)="shareMailClick(shareUrl)" title="{{ 'Detail.shareByMail' | translate }}">
            <i class="fa fa-envelope"></i>
            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Detail.mailIcon' | translate }}</span>
          </button>
        </div>
        <div class="mailBtn m-r-6">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon share-bg"
            (click)="copyShareUrl(shareUrl)" title="{{ 'Detail.copytoClipboard' | translate }}">
            <i class="fa fa-files-o fa-lg" aria-hidden="true"></i>
            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{'Common.Copy' |translate}}</span>
          </button>
        </div> -->
        <a class="resp-sharing-button__link mailBtns" (click)="shareMailClick(shareUrl)"
          title="{{ 'Detail.shareByMail' | translate }}">
          <i class="fa fa-envelope copyIcon"></i>
          <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{
            "Detail.mailIcon" | translate
            }}</span>
        </a>
        <a class="resp-sharing-button__link copyBtn" (click)="copyShareUrl(shareUrl)"
          title="{{ 'Detail.copytoClipboard' | translate }}">
          <i class="fa fa-files-o fa-lg copyIcon" aria-hidden="true"></i>
          <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{
            "Common.Copy" | translate
            }}</span>
        </a>
        <!-- <div class="addthis_inline_share_toolbox"></div> -->
      </li>
    </ul>
  </div>
</div>

<div class="dropdown-menu shareWidth guestShare" [class.visibleMobileShare]="toggleviewlist" role="menu"
  *ngIf="isGuestUser && isMobileView" [ngStyle]="{ display: toggleviewlist ? 'flex' : 'none' }">
  <div class="shareAllPanel guestShareCls">
    <h4>{{ "Detail.shareExternalUsers" | translate }}</h4>
    <p>{{ "Detail.wileyExternalShareContent" | translate }}</p>
    <a href="{{ shareUrl }}" (click)="$event.preventDefault(); $event.stopPropagation()">{{ shareUrl }}</a>
    <ul [class.arabic-style]="isArabicLanguage" (click)="$event.stopPropagation()" id="shareIconsWidth">
      <li style="display: inline-flex">
        <!-- <div class="mailBtn m-r-6">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon"
            (click)="shareMailClick(shareUrl)" title="{{ 'Detail.shareByMail' | translate }}">
            <i class="fa fa-envelope"></i>
            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Detail.mailIcon' | translate }}</span>
          </button>
        </div>
        <div class="mailBtn m-r-6">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon share-bg"
            (click)="copyShareUrl(shareUrl)" title="{{ 'Detail.copytoClipboard' | translate }}">
            <i class="fa fa-files-o fa-lg" aria-hidden="true"></i>
            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{'Common.Copy' |translate}}</span>
          </button>
        </div> -->
        <a class="resp-sharing-button__link mailBtns" (click)="shareMailClick(shareUrl)"
          title="{{ 'Detail.shareByMail' | translate }}">
          <i class="fa fa-envelope copyIcon"></i>
          <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{
            "Detail.mailIcon" | translate
            }}</span>
        </a>
        <a class="resp-sharing-button__link copyBtn" (click)="copyShareUrl(shareUrl)"
          title="{{ 'Detail.copytoClipboard' | translate }}">
          <i class="fa fa-files-o fa-lg copyIcon" aria-hidden="true"></i>
          <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{
            "Common.Copy" | translate
            }}</span>
        </a>
        <!-- <div class="addthis_inline_share_toolbox"></div> -->
        <!-- Sharingbutton Facebook -->
        <a class="resp-sharing-button__link"
          href="https://facebook.com/sharer/sharer.php?u=http%3A%2F%2Fsharingbuttons.io" target="_blank" rel="noopener"
          aria-label="">
          <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Twitter -->
        <a class="resp-sharing-button__link"
          href="https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Tumblr -->
        <a class="resp-sharing-button__link"
          href="https://www.tumblr.com/widgets/share/tool?posttype=link&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;caption=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;content=http%3A%2F%2Fsharingbuttons.io&amp;canonicalUrl=http%3A%2F%2Fsharingbuttons.io&amp;shareSource=tumblr_share_button"
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--tumblr resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M13.5.5v5h5v4h-5V15c0 5 3.5 4.4 6 2.8v4.4c-6.7 3.2-12 0-12-4.2V9.5h-3V6.7c1-.3 2.2-.7 3-1.3.5-.5 1-1.2 1.4-2 .3-.7.6-1.7.7-3h3.8z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Pinterest -->
        <a class="resp-sharing-button__link"
          href="https://pinterest.com/pin/create/button/?url=http%3A%2F%2Fsharingbuttons.io&amp;media=http%3A%2F%2Fsharingbuttons.io&amp;description=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--pinterest resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M12.14.5C5.86.5 2.7 5 2.7 8.75c0 2.27.86 4.3 2.7 5.05.3.12.57 0 .66-.33l.27-1.06c.1-.32.06-.44-.2-.73-.52-.62-.86-1.44-.86-2.6 0-3.33 2.5-6.32 6.5-6.32 3.55 0 5.5 2.17 5.5 5.07 0 3.8-1.7 7.02-4.2 7.02-1.37 0-2.4-1.14-2.07-2.54.4-1.68 1.16-3.48 1.16-4.7 0-1.07-.58-1.98-1.78-1.98-1.4 0-2.55 1.47-2.55 3.42 0 1.25.43 2.1.43 2.1l-1.7 7.2c-.5 2.13-.08 4.75-.04 5 .02.17.22.2.3.1.14-.18 1.82-2.26 2.4-4.33.16-.58.93-3.63.93-3.63.45.88 1.8 1.65 3.22 1.65 4.25 0 7.13-3.87 7.13-9.05C20.5 4.15 17.18.5 12.14.5z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton LinkedIn -->
        <a class="resp-sharing-button__link"
          href="https://www.linkedin.com/shareArticle?mini=true&amp;url=http%3A%2F%2Fsharingbuttons.io&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;summary=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;source=http%3A%2F%2Fsharingbuttons.io"
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton E-Mail -->
        <a class="resp-sharing-button__link"
          href="mailto:?subject=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;body=http%3A%2F%2Fsharingbuttons.io"
          target="_self" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--email resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Reddit -->
        <a class="resp-sharing-button__link"
          href="https://reddit.com/submit/?url=http%3A%2F%2Fsharingbuttons.io&amp;resubmit=true&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--reddit resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M24 11.5c0-1.65-1.35-3-3-3-.96 0-1.86.48-2.42 1.24-1.64-1-3.75-1.64-6.07-1.72.08-1.1.4-3.05 1.52-3.7.72-.4 1.73-.24 3 .5C17.2 6.3 18.46 7.5 20 7.5c1.65 0 3-1.35 3-3s-1.35-3-3-3c-1.38 0-2.54.94-2.88 2.22-1.43-.72-2.64-.8-3.6-.25-1.64.94-1.95 3.47-2 4.55-2.33.08-4.45.7-6.1 1.72C4.86 8.98 3.96 8.5 3 8.5c-1.65 0-3 1.35-3 3 0 1.32.84 2.44 2.05 2.84-.03.22-.05.44-.05.66 0 3.86 4.5 7 10 7s10-3.14 10-7c0-.22-.02-.44-.05-.66 1.2-.4 2.05-1.54 2.05-2.84zM2.3 13.37C1.5 13.07 1 12.35 1 11.5c0-1.1.9-2 2-2 .64 0 1.22.32 1.6.82-1.1.85-1.92 1.9-2.3 3.05zm3.7.13c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9.8 4.8c-1.08.63-2.42.96-3.8.96-1.4 0-2.74-.34-3.8-.95-.24-.13-.32-.44-.2-.68.15-.24.46-.32.7-.18 1.83 1.06 4.76 1.06 6.6 0 .23-.13.53-.05.67.2.14.23.06.54-.18.67zm.2-2.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5.7-2.13c-.38-1.16-1.2-2.2-2.3-3.05.38-.5.97-.82 1.6-.82 1.1 0 2 .9 2 2 0 .84-.53 1.57-1.3 1.87z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton XING -->
        <a class="resp-sharing-button__link"
          href="https://www.xing.com/app/user?op=share;url=http%3A%2F%2Fsharingbuttons.io;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--xing resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M10.2 9.7l-3-5.4C7.2 4 7 4 6.8 4h-5c-.3 0-.4 0-.5.2v.5L4 10 .4 16v.5c0 .2.2.3.4.3h5c.3 0 .4 0 .5-.2l4-6.6v-.5zM24 .2l-.5-.2H18s-.2 0-.3.3l-8 14v.4l5.2 9c0 .2 0 .3.3.3h5.4s.3 0 .4-.2c.2-.2.2-.4 0-.5l-5-8.8L24 .7V.2z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton WhatsApp -->
        <a class="resp-sharing-button__link"
          href="whatsapp://send?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.%20http%3A%2F%2Fsharingbuttons.io"
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Hacker News -->
        <a class="resp-sharing-button__link"
          href="https://news.ycombinator.com/submitlink?u=http%3A%2F%2Fsharingbuttons.io&amp;t=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking."
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--hackernews resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
                <path fill-rule="evenodd"
                  d="M60.94 82.314L17 0h20.08l25.85 52.093c.397.927.86 1.888 1.39 2.883.53.994.995 2.02 1.393 3.08.265.4.463.764.596 1.095.13.334.262.63.395.898.662 1.325 1.26 2.618 1.79 3.877.53 1.26.993 2.42 1.39 3.48 1.06-2.254 2.22-4.673 3.48-7.258 1.26-2.585 2.552-5.27 3.877-8.052L103.49 0h18.69L77.84 83.308v53.087h-16.9v-54.08z">
                </path>
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton VK -->
        <a class="resp-sharing-button__link"
          href="http://vk.com/share.php?title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--vk resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M21.547 7h-3.29a.743.743 0 0 0-.655.392s-1.312 2.416-1.734 3.23C14.734 12.813 14 12.126 14 11.11V7.603A1.104 1.104 0 0 0 12.896 6.5h-2.474a1.982 1.982 0 0 0-1.75.813s1.255-.204 1.255 1.49c0 .42.022 1.626.04 2.64a.73.73 0 0 1-1.272.503 21.54 21.54 0 0 1-2.498-4.543.693.693 0 0 0-.63-.403h-2.99a.508.508 0 0 0-.48.685C3.005 10.175 6.918 18 11.38 18h1.878a.742.742 0 0 0 .742-.742v-1.135a.73.73 0 0 1 1.23-.53l2.247 2.112a1.09 1.09 0 0 0 .746.295h2.953c1.424 0 1.424-.988.647-1.753-.546-.538-2.518-2.617-2.518-2.617a1.02 1.02 0 0 1-.078-1.323c.637-.84 1.68-2.212 2.122-2.8.603-.804 1.697-2.507.197-2.507z" />
              </svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Telegram -->
        <a class="resp-sharing-button__link"
          href="https://telegram.me/share/url?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io"
          target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--telegram resp-sharing-button--small">
            <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z" />
              </svg>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="image-help-container dropdown-menu show-help mobile-help" id="help_box" role="menu"
  [ngClass]="{ fullScreenHelpText: isInFullScreenMode }" (click)="$event.stopPropagation()"
  [class.display-help]="showHelp" *ngIf="isMobileView">
  <label [class.arabic-style]="isArabicLanguage" class="help-title">
    {{ "Detail.useMouseWheel" | translate }}
  </label>
  <ul [class.arabic-style]="isArabicLanguage">
    <li [class.arabic-style]="isArabicLanguage">
      {{ "Detail.WithintheThumbnailStrip" | translate }}
    </li>
    <li [class.arabic-style]="isArabicLanguage">
      {{ "Detail.wihtintheDocument" | translate }}
    </li>
    <li [class.arabic-style]="isArabicLanguage">
      {{ "Detail.withintheGrayArea" | translate }}
    </li>
  </ul>
  <label [class.arabic-style]="isArabicLanguage" class="help-title">
    {{ "Detail.keyboardShortcuts" | translate }}
  </label>
  <ul [class.arabic-style]="isArabicLanguage">
    <li [class.arabic-style]="isArabicLanguage">
      {{ "Detail.keyboardPageupWindows" | translate }}
    </li>
    <li [class.arabic-style]="isArabicLanguage">
      {{ "Detail.keyboardPageupMac" | translate }}
    </li>
  </ul>
</div>