/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
export const FOAM_TREE_OBJECT = {
    id: 'visualization',
    dataObject: null,
    // fadeDuration: 600,
    groupBorderWidth: 2,
    groupInsetWidth: 2,
    groupFillType: 'plain',
    groupStrokeType: 'plain',
    showZeroWeightGroups: true,
    rainbowStartColor: 'hsl(44, 88%, 70%)',
    rainbowEndColor: 'hsl(33, 5%, 47%)',
    // groupFontFamily: 'sans-serif',
    // groupMaxFontSize: 20,
    // groupMinFontSize: 15,
    titleBar: 'topbottom',
    titleBarBackgroundColor: 'rgba(0,0,0,.2)',
    titleBarTextColor: '#000',
    titleBarTextPaddingTopBottom: 10,
    titleBarFontFamily: 'sans-serif',
    groupLabelFontFamily: 'sans-serif',
    // Show the relaxation
    relaxationVisible: true,         // show relaxation
    wireframeLabelDrawing: 'always', // show labels during relaxation
    relaxationInitializer: 'ordered',

    // Make the relaxation last longer
    relaxationQualityThreshold: 0,
    relaxationMaxDuration: 8000,
    // groupColorDecorator: function (options, params, vars) {
    //     vars.groupColor = params.group.color;
    //     vars.labelColor = 'auto';
    // },

    // Roll out each group individually
    rolloutMethod: 'individual',

    // Create a gentle scaling effect
    rolloutDuration: 3500,
    rolloutEasing: 'bounce',
    rolloutScalingStrength: -0.4,
    rolloutRotationStrength: 0,
    rolloutPolygonDrag: 0.015,
    onGroupClick: function (e) { return false; },
    // groupStrokeWidth: 2
};
