<!-- Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->

@if (noResultsFound) {
  <div class="wordcloud-search-div">
    <div class="input-group">
      <input
        [class.arabic-style]="isArabicLanguage"
        type="text"
        class="form-control"
        name="wordcloudText"
        id="wordCloudSearchBox"
        placeholder="{{ 'textAnalysisPage.findKeyword' | translate }}"
        [(ngModel)]="wordcloudText"
        maxlength="100"
        autocomplete="off"
        title="{{ 'textAnalysisPage.findKeyword' | translate }}"
        (keyup.enter)="findWordCloudWord(wordcloudText)"
        (input)="onSearchChange(wordcloudText)"
        />
        <span class="input-group-btn">
          <button
            class="btn btn-primary"
            type="button"
            [disabled]="!wordcloudText"
            (click)="findWordCloudWord(wordcloudText)"
            >
            <i class="fa fa-search"></i>
          </button>
        </span>
      </div>
      @if (currentAvailableArchives === 'WILEY') {
        <div
          class="view-archives-dropdown"
          >
          <button
            class="btn btn-primary pull-right archives-dropdown-btn"
            (click)="selectWordcloudBasedOnArchives()"
            >
            {{ "textAnalysisPage.viewbyArchives" | translate }}
          </button>
        </div>
      }
      <div
        class="archives-dropdown-list"
        [ngStyle]="{ display: showArchivesDropDownList ? 'block' : 'none' }"
        >
        <ul class="archives-dropdown-ul">
          @for (archive of newArchivesList; track archive; let i = $index) {
            @if (resultSet[archive]?.isWordCloudAvailable) {
              <li
                class="archives-dropdown-li"
                >
                <label class="wiley-admin-text check-container">
                  <input
                    type="checkbox"
                    class="disable-label"
                    (change)="toggleArchiveSelection($event, archive)"
                    name="archive"
                    checked
                    />
                    <span [class.arabic-style]="isArabicLanguage" class="fuzzy-text">{{
                      archive
                    }}</span>
                    <span class="checkmark"></span>
                  </label>
                </li>
              }
            }
          </ul>
        </div>
      </div>
    }
    <p
      class="add-terms-text"
      [class.arabic-style]="isArabicLanguage"
      [ngClass]="{ fontResponsive: lang === 'ta' }"
      >
      {{ "textAnalysisPage.analysisAccessedArchives" | translate }}
    </p>
    <h6 [class.arabic-style]="isArabicLanguage" class="chart-query-container-title">
      <span class="add-terms-text" >
        <p
          class="small lh-17"
          [class.arabic-style]="isArabicLanguage"
          [ngClass]="{ fontResponsive: lang === 'ta' }"
          >
          {{ "textAnalysisPage.wordcloudText" | translate }}
        </p></span
        >
      </h6>
      <!-- <div class="row"> -->
      <div class="row wordcloud-parentdiv">
        @for (archive of newArchivesList; track archive; let i = $index) {
          @if (resultSet[archive]?.isWordCloudAvailable) {
            <div
              id="content-box{{ archive }}"
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 content-box"
              >
              @if (resultSet[archive]) {
                <div
                  class="word-cloud_frame-loader print-hide"
                  [ngStyle]="{ display: resultSet[archive]?.loader ? 'block' : 'none' }"
                  >
                  <!-- <img
                  alt="{{ 'Alt.loaderImage' | translate }}"
                  class="print-hide"
                  src="assets/images/dot-loader-dark.gif"
                  /> -->
                </div>
              }
              <!-- <div class="word-cloud_frame-noresult" *ngIf="resultSet[archive]?.isNoResult && resultSet[archive]?.name === 'BAAS'">
              <p class="text-center primary-text-color text-capitalize">
                This feature is in development and will be coming soon.
              </p>
            </div>
            <div class="word-cloud_frame-noresult" *ngIf="resultSet[archive]?.isNoResult && currentArchiveName === 'BAAS'">
              <p class="text-center primary-text-color text-capitalize">
                This feature is in development and will be coming soon.
              </p>
            </div> -->
            @if (resultSet[archive]?.isWordCloudAvailable) {
              <a
                title="{{ 'textAnalysisPage.print' | translate }}"
                data-html2canvas-ignore="true"
                [ngClass]="{ disabled: resultSet[archive]?.isNoResult }"
                href="javascript:void(0)"
                class="btn btn-primary pull-right print-btn"
          (click)="
            wordCloudService.printWordCloud(
              'content-box' + archive,
              _advancedFilterParam
            )
          "
                ><i class="fa fa-print"></i
              ></a>
            }
            @if (resultSet[archive]?.isWordCloudAvailable) {
              <a
                title="{{ 'Common.download' | translate }}"
                data-html2canvas-ignore="true"
                [ngClass]="{ disabled: resultSet[archive]?.isNoResult }"
                href="javascript:void(0)"
                class="btn btn-primary pull-right download-btn"
                id="download-btn"
          (click)="
            wordCloudService.downloadWordCloudData(
              archive,
              _advancedFilterParam
            )
          "
                ><i class="fa fa-download"></i
              ></a>
            }
            <p
              class="text-uppercase text-center primary-text-color wordcloud-title"
          [title]="
            parsedText(
              locationConfigArchives[archive]?.pageTitle || 'Title' | translate
            )
          "
              >
              <label
                class="archiveTitle"
                [innerHTML]="locationConfigArchives[archive]?.pageTitle | translate"
              ></label>
            </p>
            <div
              class="word-cloud-parent"
              WordCloud
              [wordData]="resultSet[archive]?.data"
              [chartId]="archive"
              id="{{ archive }}"
              [maxWordCount]="maxWordCount"
              (selectedTerm)="receiveTerm($event)"
              [updateWordCloud]="updateWordCloud"
              (isWordCloudLoaded)="wordCloudLoaded($event)"
              >
              @if (resultSet[archive]?.isNoResult && (resultSet[archive]?.name === 'ENV' || resultSet[archive]?.name === 'WPA' || resultSet[archive]?.name ==='WPA1')) {
                <div class="word-cloud_frame-noresult">
                  <p class="text-center primary-text-color text-capitalize">
                    This feature is in development and will be coming soon.
                  </p>
                </div>
              }
              @if (resultSet[archive]?.isNoResult && (currentArchiveName === 'ENV' || currentArchiveName === 'WPA' || currentArchiveName === 'WPA1')) {
                <div class="word-cloud_frame-noresult">
                  <p class="text-center primary-text-color text-capitalize">
                    This feature is in development and will be coming soon.
                  </p>
                </div>
              }
            </div>
            @if (resultSet[archive]?.data?.length > 0) {
              <div
                class="col-sm-12 col-md-12 term-frequency_slider print-hide"
                >
                <div
                  class="col-md-12 slider-hide"
                  slider
                  [min]="minValue"
                  [max]="maxValue"
                  [step]="step"
                  [value]="resultSet[archive]?.sliderValue"
                  (sliderValue)="receiveSliderValue($event, archive)"
                ></div>
                <div class="col-md-12 sliderange">
                  <span
                    >{{ resultSet[archive]?.sliderValue || 50 }}
                    {{ "textAnalysisPage.wordcloudSliderValue" | translate }}</span
                    >
                  </div>
                </div>
              }
            </div>
          }
        }
      </div>
      <!-- For download html as image -->
      <div id="download">
        <img #canvas />
        <a #downloadLink></a>
      </div>
      <div id="img-out" style="display: none"></div>
    <!-- </div> -->
