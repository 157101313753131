/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';

@NgModule({
  imports:      [ CommonModule ],
  declarations: [
    NotificationsComponent,
    NotificationComponent
  ],
  exports: [
    NotificationsComponent,
    NotificationComponent
  ],
  providers:    [ NotificationService ]
})
export class NotificationModule {

}
