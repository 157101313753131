
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from './../common/services/http.service';
import { environment, APIURLS } from '../../environments/environment';
import { SearchService } from './../common/services/search.service';
import { PageSettingsService } from './../common/services/page-settings.service';
// import { ModalService } from '../common/services/modal.service';
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UrlUtilityService } from '../common/services/url-utility.service';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { SharedService } from '../common/services/sharedService';
import { isEmpty as _isEmpty, orderBy } from 'lodash';

import { RangeSliderModule } from '../common/modules/range-slider/range-slider.module';
import { NgChartjsModule } from 'ng-chartjs';
import { TranslateModule } from '@ngx-translate/core';

declare let $: any;
declare const Chart: any;

@Component({
  selector: 'app-search-result-graph-by-subject',
  standalone:true,
  imports: [NgChartjsModule, RangeSliderModule, TranslateModule],
  templateUrl: './search-result-graph-by-subject.component.html',
  styleUrls: ['./search-result-graph-by-subject.component.css']
})
export class SearchResultGraphBySubjectComponent implements OnInit, OnDestroy, AfterViewInit {
  isArabicLanguage: Boolean = false;
  unSubscribePageSettings: Subscription;
  subMapParam: Subscription;
  pubEndDate: any;
  pubStartDate: any;
  pageContent: any;
  sortedArray: any;
  private graphData: any;
  private facetResultSubscription: Subscription;
  results: any;
  resultSet: any;
  chartDataYear: any = [];
  chartDataCount: any = [];
  current: any;
  facetRangeGap: any;
  yearFrom: any;
  yearTo: any;
  // private paramObservable: Subscription;
  advancedFilterParam: AdvancedFilterParams;
  _searchTerm: any;
  private filterParams: any;
  private rangeArray: Number[] = [0, 0];
  private currentTerms: any;

  private stop$: Subject<void> = new Subject<void>();

  lineChartData: Array<any> = [];
  xAxisLabel: Array<any> = [];
  lineChartOptions: any = {
    title: {
      display: false,
      text: ''
    },
    legend: { display: true },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
          precision: 0
        }
      }
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          return tooltipItem[0]['xLabel'];
        },
        label: (tooltipItem, data) => {
          return tooltipItem.yLabel + ' Documents';
        }
      }
    },
  };
  lineChartColors: Array<any> = [];
  lineChartLegend: Boolean = true;
  lineChartType: String = 'line';
  maxYearValue: Number = 100;
  minYearValue: Number = 1;
  inlinePlugin: any;
  textPlugin: any;
  constructor(
    private searchService: SearchService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private urlUtilityService: UrlUtilityService,
    private sharedService: SharedService
  ) {

    // this.modalService.showLoader();
    this.activatedRoute.params.subscribe(params => {
      this._searchTerm = params['searchTerm'];
      this.filterParams = params['filter'];
      this.pubStartDate = params['startDate'];
      this.pubEndDate = params['endDate'];
      this.urlUtilityService.parseUrl(this.filterParams).subscribe((data) => {
        this.advancedFilterParam = new AdvancedFilterParams(data);
      });
    });
  }

  private chartColors: any = ['#009891', '#4e6af1', '#1bbec6', '#61165E ', '#8a6d3b', '#e168f7', '#bc5e0b', '#860000'];
  drawGraph() {
    // this.modalService.showLoader();
    const requestParams = this.currentTerms;
    const pubDate = {
        fieldName: 'pubStartDate',
        fieldEndValue: this.pubEndDate || this.pageContent.pubDates.endDate,
        fieldValue: this.pubStartDate || this.pageContent.pubDates.startDate,
        condition: 'BETWEEN',
        includeNull: false
      };
    this.getFrequencyData(requestParams);
  }

  getFrequencyData(requestParams) {
    requestParams['pubEndDate'] = this.pubEndDate || this.pageContent.pubDates.endDate;
    requestParams['pubStartDate'] = this.pubStartDate || this.pageContent.pubDates.startDate;
    if (requestParams['filterParams']) {
      requestParams.filterParams.forEach(obj => {
        if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
          obj.condition = 'All';
          obj.fieldValue = '';
        }
      });
    }
    this.httpService.post(environment.APIUrl + APIURLS['termfreqbysub'], requestParams, (response) => {
      this.processData(response);
      this.graphData = response;
    }, (error) => {
      // this.modalService.hideLoader();
    });
  }
  /*
  * Process all year in response group into chart label
  * construct dataset for chart from response fileds like  percentage, search keyword also line color
  */
 processData(response) {
    const resultsFromResponse = response.results.TermFrequencyBySubject;
    const yAxisArray: any = [];
    resultsFromResponse.forEach((obj, index) => {
      this.sortedArray =  orderBy(obj.statsResults[0].facetResults[0].facetContent, ['value'], ['asc']);
      if (this.sortedArray.length > 0) {
        this.xAxisLabel = [];
        this.chartDataYear = [];
        this.chartDataCount = [];
        for (const facetPubdateObj of this.sortedArray) {
          this.chartDataYear.push(facetPubdateObj.value);
          this.chartDataCount.push(facetPubdateObj.count);
        }
        this.xAxisLabel = this.chartDataYear;
        yAxisArray.push({
          data:  this.chartDataCount,
          label: response.results.Terms[index]
        });
        this.lineChartColors.push({
          backgroundColor: 'transparent',
          borderColor: this.chartColors[index],
          pointBackgroundColor: this.chartColors[index],
          pointBorderColor: '#fff',
          fill: false
        });
      }
    });
    setTimeout(() => {
      this.lineChartData = yAxisArray;
    }, 200);
    // this.modalService.hideLoader();
    this.rangeArray = [];
    if (this.chartDataYear.length > 0) {
      const chartArry = this.chartDataYear;
      this.minYearValue = Math.min.apply(Math, chartArry);
      this.maxYearValue = Math.max.apply(Math, chartArry);
      this.yearTo = +resultsFromResponse[0].statsResults[0].max;
      this.yearFrom = +resultsFromResponse[0].statsResults[0].min;
      this.rangeArray.push(Math.min.apply(Math, chartArry));
      this.rangeArray.push(Math.max.apply(Math, chartArry));
    }
  }
  ngOnInit() {
    this.textPlugin = [{
      id: 'linePlugin',
      beforeDraw(chart: any): any {
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';
        const text = '';
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }];
    this.inlinePlugin = this.textPlugin;
  }
  /*
  * Set default options and draw chart
  * Load Archive if the user has permission to access that particular archive(Use Pagesetting service to check the Permission)
  */
  ngAfterViewInit() {
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
    this.unSubscribePageSettings = PageSettingsService.pageSettingsObservable.subscribe((data) => {
      this.pageContent = data;

      this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, param => {
        if (this.pubEndDate) {
          param['pubEndDate'] = this.pubStartDate || this.pageContent.pubDates.endDate;
        } else {
          param['pubEndDate'] = this.pageContent.pubDates.endDate;
        }
        if (this.pubStartDate) {
          param['pubStartDate'] = this.pubStartDate || this.pageContent.pubDates.startDate;
        } else {
          param['pubStartDate'] = this.pageContent.pubDates.startDate;
        }
        if (param['pubEndDate'] && param['pubStartDate']) {
          this.getFrequencyData(param);
        }
      });
      // this.modalService.hideLoader();
      if (this.subMapParam) {
        this.subMapParam.unsubscribe();
      }
      this.subMapParam = this.sharedService.mapParam.pipe(takeUntil(this.stop$)).subscribe(res => {
        if (!_isEmpty(res)) {
          this.getFrequencyData(res);
          this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, param => {
            res = param;
          });
        }
      });
    }, err => {
    });
  }

  /*
 * Unsubscribe the facet result if it`s presents
 */
  ngOnDestroy() {
    if (this.facetResultSubscription) {
      this.facetResultSubscription.unsubscribe();
    }
    if (this.subMapParam) {
      this.subMapParam.unsubscribe();
    }
    if (this.unSubscribePageSettings) {
      this.unSubscribePageSettings.unsubscribe();
    }
    this.stop$.next();
    this.stop$.complete();
  }
  /*
  * If the user changes the Slider then refresh the filter the chart data and update the chart
  */
  sliderRangeChanged(event) {
    this.yearFrom = event[0][0];
    this.yearTo = (event[0][1]);
    this.filterChartData();
  }
  /*
  * Filter chart data based on year range from chart slider
  */
  filterChartData() {
    const resultsFromResponse = this.graphData.results.TermFrequencyBySubject;
    const yAxisArray: any = [];
    resultsFromResponse.forEach((obj, index) => {
      this.chartDataCount = [];
      this.sortedArray = orderBy(obj.statsResults[0].facetResults[0].facetContent, ['value'], ['asc']);
      if (this.sortedArray.length > 0) {
        this.xAxisLabel = [];
        this.chartDataYear = [];
        this.chartDataCount = [];
        for (const facetPubdateObj of this.sortedArray) {
          if (facetPubdateObj.value >= this.yearFrom && facetPubdateObj.value <= this.yearTo) {
            this.chartDataYear.push(facetPubdateObj.value);
            this.chartDataCount.push(facetPubdateObj.count);
          }
        }
        this.xAxisLabel = this.chartDataYear;
        yAxisArray.push({
          data:  this.chartDataCount,
          label: this.graphData.results.Terms[index]
        });
        this.lineChartColors.push(
          {
            backgroundColor: 'transparent',
            borderColor: this.chartColors[index],
            pointBackgroundColor: this.chartColors[index],
            pointBorderColor: '#fff',
            fill: false
          }
        );
      }
    });
    setTimeout(() => {
      this.lineChartData = yAxisArray;
    }, 200);
  }
}
