
import { debounceTime, map, takeUntil } from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, Input, forwardRef, HostListener, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { ActivatedRoute } from '@angular/router';
import { UrlUtilityService } from '../common/services/url-utility.service';
import { of, Subject } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';
// declare var $: any;
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'filter-control',
  standalone:true,
  imports: [TranslateModule, FormsModule, ReactiveFormsModule],
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterControlComponent),
      multi: true
    }
  ]
})
export class FilterControlComponent implements ControlValueAccessor,OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  currentfieldValue: any;
  currentFieldName: any;
  currentTerms: any;

  // private fieldsDropdownSettings = {
  //   singleSelection: true,
  //   text: 'Fields',
  //   selectAllText: 'Select All',
  //   unSelectAllText: 'UnSelect All',
  //   maxHeight: 200
  // };
  // tslint:disable-next-line:no-input-rename
  @Input('field-options') fieldsDropdownOptions;
  // tslint:disable-next-line:no-input-rename
  @Input('operator-options') operatorsDropdownOptions;
  _searchTerm: any;
  private filterParams: any;
  listOfFilters: any = [];
  advancedFilterParam: AdvancedFilterParams;
  newObse$: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private urlUtilityService: UrlUtilityService
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      this._searchTerm = params['searchTerm'];
      this.filterParams = params['filter'];
      this.urlUtilityService.parseUrl(this.filterParams).pipe(takeUntil(this.stop$)).subscribe(data => {
        this.advancedFilterParam = new AdvancedFilterParams(data);
      });
    });
  }

  filterChanged(value, index) {
    this.listOfFilters[index].fieldValue = this.listOfFilters[index].fieldValue.trim();
    // if (value.trim()) {
    // this.propagateChange(this.listOfFilters);
    // }
    this.newObse$ = of(this.listOfFilters);
      this.newObse$.pipe(
        debounceTime(2000),
        map(changedValue => this.propagateChange(changedValue)),
        takeUntil(this.stop$))
        .subscribe(() =>  
          {
          });
  }
  addFilter(index: number) {
    if (this.listOfFilters.length < 8) {
      let enterDocument: any = [];
      enterDocument = this.fieldsDropdownOptions.filter((f) => (f.itemName === 'Entire Document'))
      this.listOfFilters.splice(index + 1, 0, {
        fieldValue: '',
        operator: this.operatorsDropdownOptions[0].itemName,
        // fieldName: this.fieldsDropdownOptions[5].itemValue
        fieldName: enterDocument ? enterDocument[0].itemValue : this.fieldsDropdownOptions[5].itemValue
        // fieldName: this.fieldsDropdownOptions[0].itemValue
      });
      this.propagateChange(this.listOfFilters);
    }
  }

  removeFilter(index: number) {
    if (this.listOfFilters.length > 1) {
      this.listOfFilters.splice(index, 1);
    }
    this.propagateChange(this.listOfFilters);
  }

  onFieldChange(value, index) {
    if (value !== 'manuscriptNoV2') {
      this.listOfFilters.forEach((obj, i) => {
        if (obj.fieldValue.indexOf('"') >= 0 && i === index) {
          obj.fieldValue = obj.fieldValue.replace(/['"]+/g, '');
        }
      });
    }
    this.propagateChange(this.listOfFilters);
  }

  onOperatorChange(value, index) {
    this.propagateChange(this.listOfFilters);
  }

  onKeywordChange(value, index) {
    this.propagateChange(this.listOfFilters);
  }

  writeValue(filters: any) {
    this.listOfFilters = filters;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched() { }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.ctrlKey && e.altKey && e.code === "KeyA") {
      this.onFieldChange('', '');
    }
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
