
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '../../../services/sharedService';
import { Subject } from 'rxjs';
@Component({
  selector: 'wiley-footer',
  templateUrl: './wiley-footer-component.component.html',
  styleUrls: ['./wiley-footer-component.component.css']
})
export class WileyFooterComponentComponent implements OnInit, OnDestroy {
  showJisclogo = false;
  private stop$: Subject<void> = new Subject<void>();
  isArabicLanguage: Boolean = false;
  currentDateYear: Number;
  constructor(
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
    if (window.location.pathname.includes('baas')) {
      this.showJisclogo = true;
    } else {
      this.showJisclogo = false;
    }

    this.currentDateYear = new Date().getFullYear();
    // this.currentDateYear = 2018;
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });

  }
  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
  // gotoJiscPage() {
  //   // window.open('https://www.jisc.ac.uk/#', '_blank');
  // }

}
