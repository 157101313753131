<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<!-- <perfect-scrollbar [config]="config" class="body-container" #container id="bodyScrollBar"> -->
<div class="container container-header fade-in content ">
  <div class="container-fluid">
    @if (pageSettings?._currentArchive) {
      <wiley-header [page-content]="pageSettings"></wiley-header>
    }
    <div style="min-height: 70vh">
      <router-outlet></router-outlet>
    </div>
    @if (pageSettings?._currentArchive) {
      <wiley-footer></wiley-footer>
    }
    <app-loader></app-loader>
  </div>
</div>
<!-- </perfect-scrollbar> -->
<ng-template #messageTemplate>
  <div class="modal-header warning-modal-header text-center d-block">
    <h6 class="warnings-headerlbl no-margin" [innerHtml]='welcomeMessageTitle'></h6>
  </div>
  <div class="modal-body warning_bdy">
    <div class="welcome-message warning-modal-header">
      <span [innerHtml]='welcomeContent1'></span><strong>{{appVersion}}!</strong>&nbsp;<span [innerHtml]='welcomeContent2'></span>
    </div>
    <ng-container>
      <p class="welcome-training-message" [innerHtml]='welcomeNoteTutorialContent'> </p>
    </ng-container>
    <ng-container>
      <p class="welcome-cookies-message">
        {{ 'Common.cookiesContent' | translate }}
        <span class="privacy-policy-link" (click)="gotoCookies()">
        {{ 'Common.cookiesContentLink' | translate }}</span>. &nbsp;{{ 'Common.cookiesContents' | translate }}
      </p>
    </ng-container>
  </div>

  <div class="modal-footer warnings-footer">
    <!-- <a class="pull-left wiley-primary-text-color" role="button" [href]="issuesUrl" target="_blank" *ngIf="showKnownIssues">{{ 'Common.inProgress' | translate }}</a> -->
    <button type="button" appAutofocus class="btn btn-primary" (click)="closeMessagePopup();">
      {{ 'Common.Close' | translate }}
    </button>
  </div>
</ng-template>