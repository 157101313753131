<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->

<!-- <div [ngStyle]="{'display': facets?.length > 1 ? 'block':'none'}" class="chart-container">
<canvas id="myChart"></canvas>
</div> -->
@if (lineChartData.length > 0) {
  <div class="chart-container">
    <!-- <canvas baseChart width="400" height="400"
    [datasets]="lineChartData"
    [labels]="xAxisLabel"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [colors]="lineChartColors"
    [chartType]="lineChartType"
    (chartClick)="chartClicked($event)">
  </canvas> -->
  <canvas ngChartjs
    [datasets]="lineChartData"
    [labels]="xAxisLabel"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [colors]="lineChartColors"
    [chartType]="lineChartType"
    [inlinePlugins]="inlinePlugin"
    (chartClick)="chartClicked($event)">
  </canvas>
</div>
}
<label for="year" class="sr-only">year</label>
<app-range-slider [(ngModel)]="dateRange" [minValue]="rangeSliderMinRange" [value]="dateRange" (RangeChanged)="dateRangeChanged($event)"
[maxValue]="rangeSliderMaxRange" [stepValue]="1" [range]="true"></app-range-slider>
@if (yearFrom && yearTo) {
  <span>{{yearFrom}} - {{yearTo}}</span>
}
<div class="filter-content display-block rangeSlider">
  <div class="sliderValue">
    <input type="text" id="year" readonly style="border:0; color:#898989; font-weight:bold;">
  </div>
  <div class="updateBtn">
    <button [class.arabic-style]="isArabicLanguage" type="button" id="update-Btn" class="btn btn-primary btn-sm pull-right" (click)="onRangeSubmitClick()" title="{{ 'SearchResult.updateResults' | translate }}">{{ 'SearchResult.updateResults' | translate }}</button>
  </div>
</div>