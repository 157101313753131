
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, HostListener } from '@angular/core';
import { SearchService } from '../common/services/search.service';
import { PageSettingsService } from '../common/services/page-settings.service';
import { ActivatedRoute } from '@angular/router';
// import { ModalService } from '../common/services/modal.service';
import { HttpService } from '../common/services/http.service';
import { environment, APIURLS } from '../../environments/environment';
import { AfterViewInit, OnDestroy } from '@angular/core';
import { SharedService } from '../common/services/sharedService';
import { isEmpty as _isEmpty } from 'lodash';
import { Subscription ,  Subject } from 'rxjs';

import { RangeSliderModule } from '../common/modules/range-slider/range-slider.module';
import { NgChartjsModule } from 'ng-chartjs';
declare let $: any;

@Component({
  selector: 'app-search-result-by-content-type',
  standalone:true,
  imports: [NgChartjsModule, RangeSliderModule],
  templateUrl: './search-result-by-content-type.component.html',
  styleUrls: ['./search-result-by-content-type.component.css']
})
export class SearchResultByContentTypeComponent implements AfterViewInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();
  unSubscribePageSettings: Subscription;
  subMapParam: Subscription;
  chartDataCountCopy: any[];
  specialParams: any;
  graphData: any;
  chartDefaultOptions: any;
  sortedArray: any;
  chartDataCount: any[];
  chartDataYear: any[];
  pageContent: any;
  pubEndDate: any;
  pubStartDate: any;
  tooltipContent: any;

  // chartColors: any = ['#4e6af1', '#860000', '#009891', '#e168f7', '#61165E ', '#8a6d3b'];
  private advancedSearchFilterParams: any;
  // slider variables start
  byContentTypeYearFrom: any = 1990;
  byContentTypeYearTo: any = 2000;
  byContentTypeRangeArray: Number[] = [0, this.byContentTypeYearTo];
  byContentTypeYearToChart: Number = 2000;
  byContentTypeYearFromChart: Number = 1700;

  lineChartData: Array<any> = [];
  xAxisLabel: Array<any> = [];
  lineChartOptions: any = {
    title: {
      display: false,
      text: ''
    },
    legend: { display: false },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
        }
      }
    },
    tooltips: { enabled: false }
  };
  lineChartColors: Array<any> = [];
  lineChartLegend: Boolean = true;
  lineChartType: String = 'bar';
  step_Size: any;
  max_Count: any;
  inlinePlugin: any;
  textPlugin: any;
  constructor(
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    // private modalService: ModalService,
    private sharedService: SharedService,
  ) {
    this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, params => {
      this.advancedSearchFilterParams = params;
    });
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.pageContent = data;
      this.getContentTypeData(this.advancedSearchFilterParams);
    }, err => { });
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      this.pubStartDate = params['startDate'];
      this.pubEndDate = params['endDate'];
    });
   }
   ngOnInit() {
    this.textPlugin = [{
      id: 'linePlugin',
      beforeDraw(chart: any): any {
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';
        const text = '';
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }];

    this.inlinePlugin = this.textPlugin;
  }
  ngAfterViewInit() {
    $('#tooltipForContentType').hide();
    this.subMapParam = this.sharedService.mapParam.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (!_isEmpty(res)) {
        this.specialParams = res;
        this.getContentTypeData(res);
      }
    });
  }

  ngOnDestroy(): void {
    this.subMapParam.unsubscribe();
    if (this.unSubscribePageSettings) {
      this.unSubscribePageSettings.unsubscribe();
    }
    this.stop$.next();
    this.stop$.complete();
  }

  getContentTypeData(advancedSearchFilterParam) {
    if (!advancedSearchFilterParam) {
      return false;
    }
    // this.modalService.showLoader();
    const requestParams: any = Object.assign({}, advancedSearchFilterParam);
    this.graphData = [];
    requestParams['pubStartDate'] = this.pubEndDate || this.pageContent._pubDates.startDate;
    requestParams['pubEndDate'] = this.pubStartDate || this.pageContent._pubDates.endDate;
    if (requestParams) {
      if (requestParams.filterParams) {
        requestParams.filterParams.forEach(obj => {
          if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
            obj.condition = 'All';
            obj.fieldValue = '';
          }
        });
      }
    }
    delete requestParams['facetFields'];
    this.httpService.post(environment.APIUrl + APIURLS['statssearch'], requestParams, (res) => {
      this.processData(res);
      this.graphData = res;
    }, (error) => {
      this.resetChart();
      // this.modalService.hideLoader();
    });
    // }, err => {});
  }

  resetChart() {
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };
    this.lineChartLegend = false;
    this.xAxisLabel = [];
    this.lineChartData = [
      { data: [], label: '' },
    ];
  }
  /*
  * Process all year in response group into chart label
  * construct dataset for chart from response fileds like  percentage, search keyword also line color
  */
  processData(response) {
    const resultsFromResponse = response.results;
    resultsFromResponse.forEach((obj, index) => {
      this.sortedArray =  obj.statsResults[0].facetResults[0].facetContent.sort(function (a, b) { return (a.value - b.value); });
      if (this.sortedArray.length > 0) {
        this.xAxisLabel = [];
        this.chartDataYear = [];
        this.chartDataCount = [];
        for (const facetPubdateObj of this.sortedArray) {
          this.chartDataYear.push(facetPubdateObj.value);
          this.chartDataCount.push(facetPubdateObj.count);
        }
        this.chartDataCountCopy = Object.assign([], this.chartDataCount);
        this.xAxisLabel = this.chartDataYear;
        this.lineChartData.push({
          data:  this.chartDataCount,
          label: ''
        });
        this.lineChartColors.push({
          backgroundColor: 'rgb(0, 109, 118)',
          fill: false
        });
      }
    });
    // for getting min max value
    this.byContentTypeRangeArray = [];
    if (this.chartDataYear.length > 0) {
      const chartArry = this.chartDataYear;
      this.byContentTypeYearFromChart = Math.min.apply(Math, chartArry);
      this.byContentTypeYearToChart = Math.max.apply(Math, chartArry);
      this.byContentTypeYearTo = this.byContentTypeYearToChart;
      this.byContentTypeYearFrom = this.byContentTypeYearFromChart;
      this.byContentTypeRangeArray.push(Math.min.apply(Math, chartArry));
      this.byContentTypeRangeArray.push(Math.max.apply(Math, chartArry));
    }
    // this.modalService.hideLoader();
    this.setCustomChartOption();
  }

  setCustomChartOption() {
    this.step_Size = 20;
    const maxDataValue: any = Math.max(...this.chartDataCountCopy);
      if (maxDataValue <= 10) {
        this.step_Size = 1;
        this.max_Count = 10;
      } else if (maxDataValue <= 50 && maxDataValue > 10) {
        this.step_Size = 5;
        this.max_Count = 50;
      } else if (maxDataValue <= 100 && maxDataValue > 50) {
        this.step_Size = 10;
        this.max_Count = 100;
      } else if (maxDataValue <= 250 && maxDataValue > 100) {
        this.step_Size = 25;
        this.max_Count = 250;
      } else if (maxDataValue <= 500 && maxDataValue > 250) {
        this.step_Size = 25;
        this.max_Count = 500;
      } else if (maxDataValue <= 600 && maxDataValue > 500) {
        this.step_Size = 50;
        this.max_Count = 600;
      } else if (maxDataValue <= 800 && maxDataValue > 600) {
        this.step_Size = 75;
        this.max_Count = 800;
      } else if (maxDataValue <= 1000 && maxDataValue > 500) {
        this.step_Size = 100;
        this.max_Count = 1000;
      } else if (maxDataValue <= 1500 && maxDataValue > 1000) {
        this.step_Size = 150;
        this.max_Count = 1500;
      } else if (maxDataValue <= 2000 && maxDataValue > 1500) {
        this.step_Size = 200;
        this.max_Count = 2000;
      } else if (maxDataValue <= 3000 && maxDataValue > 2000) {
        this.step_Size = 200;
        this.max_Count = 3000;
      } else if (maxDataValue <= 4000 && maxDataValue > 3500) {
        this.step_Size = 200;
        this.max_Count = 4000;
      } else if (maxDataValue <= 5000 && maxDataValue > 4000) {
        this.step_Size = 500;
        this.max_Count = 5000;
      } else if (maxDataValue <= 6000 && maxDataValue > 5000) {
        this.step_Size = 500;
        this.max_Count = 6000;
      } else if (maxDataValue <= 7000 && maxDataValue > 6000) {
        this.step_Size = 500;
        this.max_Count = 7000;
      } else if (maxDataValue <= 10000 && maxDataValue > 7000) {
        this.step_Size = 1000;
        this.max_Count = 10000;
      } else if (maxDataValue > 10000) {
        this.step_Size = 1000;
        this.max_Count = maxDataValue;
      } else {
        this.step_Size = 20;
        this.max_Count = Math.max(...this.chartDataCountCopy);
      }
      this.lineChartOptions = {
        title: {
          display: false,
          text: ''
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: { enabled: false }
      };
  }

  chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if ( activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // const year = chart.data.datasets[0].data[activePoints[0]._index];
        const byContenTypeObject = this;
        let requestParams = byContenTypeObject.advancedSearchFilterParams;
        requestParams.searchParams = [];
        requestParams.searchParams.push({
          'fieldName': 'pubYear',
          'fieldValue': label,
          'condition': 'EQUAL'
        });
        requestParams['pubEndDate'] = byContenTypeObject.pageContent.pubDates.endDate;
        requestParams['pubStartDate'] = byContenTypeObject.pageContent.pubDates.startDate;
        if (byContenTypeObject.specialParams) {
          requestParams['spatialFilterParams'] = byContenTypeObject.specialParams.spatialFilterParams;
        }
        // byContenTypeObject.modalService.showLoader();
        byContenTypeObject.tooltipContent = '';
        requestParams.filterParams.forEach(obj => {
          if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
            obj.condition = 'All';
            obj.fieldValue = '';
          }
        });
        if (typeof requestParams === 'string') {
          requestParams = JSON.parse(requestParams);
        }
        for(let k=0; k<requestParams.filterParams && requestParams.filterParams.length; k++) {
          if (requestParams.filterParams[k].fieldName === "archive"){
            const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
            const includeWPA = getSelectedArchives && getSelectedArchives.includes("WPA");
            if (!includeWPA) {
              const havewpa = requestParams.filterParams[requestParams.filterParams[k] && k].fieldValues.includes("WPA");
              if (havewpa) {
                if (typeof requestParams.filterParams[k].fieldValues  === 'string') {
                  requestParams.filterParams[k].fieldValues = JSON.parse(requestParams.filterParams[k].fieldValues);
                 }
                 let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
               let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA");
                if(!havewpaInSubs){
                  requestParams.filterParams[k].fieldValues = requestParams.filterParams[k].fieldValues.filter(item => item !== 'WPA')
                }
              }
            }
            const includeWPA1 = getSelectedArchives && getSelectedArchives.includes("WPA1");
            if (!includeWPA1) {
              const havewpa = requestParams.filterParams[requestParams.filterParams[k] && k].fieldValues.includes("WPA1");
              if (havewpa) {
                if (typeof requestParams.filterParams[k].fieldValues  === 'string') {
                  requestParams.filterParams[k].fieldValues = JSON.parse(requestParams.filterParams[k].fieldValues);
                 }
                 let subscribedArchives: any = JSON.parse(localStorage.getItem('wileySubscribedArchives_tab1'));
               let havewpaInSubs = subscribedArchives && subscribedArchives.includes("WPA1");
                if(!havewpaInSubs){
                  requestParams.filterParams[k].fieldValues = requestParams.filterParams[k].fieldValues.filter(item => item !== 'WPA1')
                }
              }
            }
          }
        }
        byContenTypeObject.httpService.post(
          environment.APIUrl + APIURLS['advancedSearch'], requestParams, (response) => {
          if (response.results.groupResults[0].groupEntry.groupEntryContent.length > 0 ) {
            byContenTypeObject.tooltipContent = '';
                for (const resultSet of response.results.groupResults[0].groupEntry.groupEntryContent) {
                  if (resultSet.group && resultSet.totalGroupElements) {
                      byContenTypeObject.tooltipContent += '<label style="padding-left:10px; color:#FFF">'
                      + resultSet.group + ': ' + resultSet.totalGroupElements + '</label>';
                      byContenTypeObject.tooltipContent += '<label style="padding-left:10px; color:#FFF">'
                        + 'Year' + ': ' + label + '</label>';
                    } else {
                      byContenTypeObject.tooltipContent += '<label style="padding-left:10px; color:#FFF" >No documents found.</label>';
                    }
                }
                // byContenTypeObject.modalService.hideLoader();
            } else {
              byContenTypeObject.tooltipContent += '<label style="padding-left:10px; color:#FFF" >No documents found.</label>';
              // byContenTypeObject.modalService.hideLoader();
            }
            $('#tooltipForContentType').css({top: (yPosition + 25) , left: (xPosition + 25), position: 'absolute'});
            $('#tooltipForContentType').show();
        }, (error) => {
          // byContenTypeObject.modalService.hideLoader();
        });
        let xPosition;
        let yPosition;
        xPosition = e.event.offsetX;
        yPosition = e.event.offsetY;
      }
    }
  }

  byContentTypeSliderRangeChanged(event) {
    this.byContentTypeYearFrom = event[0][0];
    this.byContentTypeYearTo = (event[0][1]);
    this.filterChartData();
  }
  /*
  * Filter chart data based on year range from chart slider
  */
  filterChartData() {
    const resultsFromResponse = this.graphData.results;
    const yAxisArray: any = [];
    resultsFromResponse.forEach((obj, index) => {
      this.chartDataCount = [];
      this.sortedArray = obj.statsResults[0].facetResults[0].facetContent.sort(function (a, b) { return (a.value - b.value); });
      if (this.sortedArray.length > 0) {
        this.xAxisLabel = [];
        this.chartDataYear = [];
        this.chartDataCount = [];
        for (const facetPubdateObj of this.sortedArray) {
          if (facetPubdateObj.value >= this.byContentTypeYearFrom && facetPubdateObj.value <= this.byContentTypeYearTo) {
            this.chartDataYear.push(facetPubdateObj.value);
            this.chartDataCount.push(facetPubdateObj.count);
          }
        }
        this.chartDataCountCopy = Object.assign([], this.chartDataCount);
        this.xAxisLabel = this.chartDataYear;
        yAxisArray.push({
          data:  this.chartDataCount,
          label: ''
        });
        this.lineChartColors.push(
          {
            backgroundColor: 'rgb(0, 109, 118)',
            fill: false
          }
        );
      }
    });
    setTimeout(() => {
      this.lineChartData = yAxisArray;
    }, 200);
    this.setCustomChartOption();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    $('#tooltipForContentType').hide();
  }
}
