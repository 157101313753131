/* Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved */
import { Injectable } from '@angular/core';
import { SearchService } from '../../common/services/search.service';
import { CommonService } from '../services/common.service';
declare let html2canvas: any;
declare let $: any;
@Injectable()
export class WordCloudService {
  constructor(
    private searchService: SearchService,
    private commonService: CommonService
  ) { }
  getWordCloudData(searchTerm, advancedFilters, isinit, archive?, limit?: number) {
    const advParam = JSON.parse(JSON.stringify(advancedFilters));
    const newArchiveValue = advParam.archive;
    advParam.filterParams.forEach(obj => {
      if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
        obj.condition = 'All';
        obj.fieldValue = '';
      }
      if (obj.fieldName === 'archive') {
        obj.fieldValues = newArchiveValue;
      }
    });
    if (isinit === 'init') {
      if (archive) { archive.toUpperCase(); }
      return this.commonService.getWordCloudResult(advParam, archive);
    } else {
      if (limit) {
        advParam['rows'] = 500;
      }
      return this.commonService.getWordCloudResult(advParam);
    }
  }
  gotoSearchResultPage(term: string, advancedFilterParam: any) {
    // sessionStorage.removeItem('termFrequency');
    // sessionStorage.removeItem('sessionFilterInput');
    const isFromWordCloud = Math.random();
    this.searchService.backToSearchResult(term, advancedFilterParam, '', isFromWordCloud);
  }
  /**
   * @description : print the word cloud
   * @param id : innerHTML of printable id in html
   */
  printWordCloud(id, advancedFilterParam) {
    this.commonService.processLimiterForPrint(advancedFilterParam, (limiterHtml) => {
    const printContent = document.getElementById(id);
    const newWin = window.frames['printf'];
    // tslint:disable-next-line:max-line-length
    newWin.document.write(`<html><head><style>@media print {body {margin: 0;}}.limiter-badge{background: #dadada;border-radius: 5px;color: #000;padding: 4px 7px 4px 15px}.limit-content{margin:5px}.limit-data{font-size: 13px;}.limit-title{font-size: 12px;font-weight: 500;font-family: 'Open Sans', sans-serif !important}@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i');@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700');body {font-family: 'Open Sans', sans-serif !important;-webkit-print-color-adjust: exact !important;}.print-hide{display:none}.text-uppercase{text-transform:uppercase}.primary-text-color{color:#61165E !important}.text-center{text-align:center}.badge{margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000}.badge{padding:1px 7px;margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000;cursor:pointer;min-width:120px;display:inline-block;text-align:left !important}ul{padding-left:0}.searchQry{margin-top:4px;font-size:14px;padding:5px}.display-badge{display:inline-block;overflow:hidden;min-height:20px;padding-top:0px}.searchList>li>span.badge{padding:6px 11px;line-height:1.3}</style></head><body onload="setTimeout(function () { window.print(); }, 1000)"> <h2 style="font-family: 'Source Serif Pro', serif !important;text-transform: capitalize;">Word Cloud</h2><p style="font-weight:600;margin:0;font-family: 'Source Serif Pro', serif !important;">Limiters</p>${limiterHtml}<br>${printContent.innerHTML}</body></html>`);
    newWin.document.close();
    });
  }
  downloadWordCloudData(archive, advancedFilterParam) {
    let printContent: any;
    this.commonService.processLimiterForPrint(advancedFilterParam, (limiterHtml) => {
    printContent = document.getElementById('content-box' + archive);
    printContent = document.getElementById('content-box' + archive);
    const termsList = document.querySelector('.addmoreterms-list');
    // tslint:disable-next-line: max-line-length
    const htmlData = `<html><head><style>body{ background-color:white  !important;}@media print {body {margin: 0;}}.limiter-badge{background: #dadada;border-radius: 5px;color: #000;padding: 4px 7px 4px 15px}.limit-content{margin:5px}.limit-data{font-size: 13px;}.limit-title{font-size: 12px;font-weight: 500;font-family: 'Open Sans', sans-serif !important}@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i');@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700');body {font-family: 'Open Sans', sans-serif !important;-webkit-print-color-adjust: exact !important;}.print-hide{display:none}.text-uppercase{text-transform:uppercase}.primary-text-color{color:#61165E !important}</style></head><style>@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i');@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700');.print-hide{display:none}.text-uppercase{text-transform: uppercase;}.badge{margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000}.badge{padding:1px 7px;margin-right:5px;font-weight:600;border-radius:3px;background-color:#dedede;color:#000;cursor:pointer;min-width:120px;display:inline-block;text-align:left !important}ul{padding-left:0}.searchQry{margin-top:4px;font-size:14px;padding:5px}.display-badge{display:inline-block;overflow:hidden;min-height:20px;padding-top:0px}.searchList>li>span.badge{padding:6px 11px;line-height:1.3}</style></head><body><h2 style="font-family: 'Source Serif Pro', serif !important;text-transform: capitalize;">Word Cloud</h2><p style="font-weight:600;margin:0;font-family: 'Source Serif Pro', serif !important;">Limiters</p>${limiterHtml} <br>${printContent.innerHTML}</body></html>`;
    const that = this;
    const iframe = document.createElement('iframe');
    iframe.id = 'downloadWordCloud';
    iframe.width = '100%';
    iframe.height = '10px';
    iframe.style.visibility = 'hidden';
    $('body').append($(iframe));
    setTimeout(() => {
      const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
      $('body', $(iframedoc)).html(htmlData);
      html2canvas(iframedoc.body, {
        onrendered: function (canvas) {
          const chartURL = canvas.toDataURL();
          that.commonService.downloadImage(chartURL, archive + '-wordcloud.png');
          setTimeout(() => {
            // document.getElementById('downloadWordCloud').remove();
            const element = document.getElementById('downloadWordCloud');
            element.parentNode.removeChild(element);
          }, 10);
        }
      });
    });
  });
  }
}
