import {
  Injectable,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { DocumentViewerComponent } from '../../document-viewer/document-viewer.component';
import { TermFrequencyComponent } from '../../analysis-hub/term-frequency/term-frequency.component';
import { TermPopularityComponent } from '../../analysis-hub/term-popularity/term-popularity.component';
import { TermGroupsComponent } from '../../analysis-hub/term-groups/term-groups.component';
import { CollocatesComponent } from '../../analysis-hub/collocates/collocates.component';
import { WordCloudComponent } from '../../analysis-hub/word-cloud/word-cloud.component';
import { ConcordanceComponent } from '../../analysis-hub/concordance/concordance.component';
@Injectable({
  providedIn: 'root'
})
export class ComponentLoaderService {

  componentRef: any;
  rootViewContainer: any;
  // factoryResolver: any;
  dynamicComponent: any;
  domContainer: any;
  coverFlowDocumentId = new Subject();
  @ViewChild('detailsDynamicContainer', { static: false, read: ViewContainerRef }) entry: ViewContainerRef;
  constructor() {
    // this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  addDynamicComponent(inputObjects, entry) {
    this.domContainer = entry;
    // const factory = this.factoryResolver.resolveComponentFactory(SearchResultDetailsComponent);
    // const factory = this.factoryResolver.resolveComponentFactory(DocumentViewerComponent);
    const factory = DocumentViewerComponent;
    if (this.domContainer) {
      this.removeComponent();
    }
    this.dynamicComponent = this.domContainer.createComponent(factory);
    this.dynamicComponent.instance.searchParams = inputObjects;

    // this.dynamicComponent.instance.docIdForDocViewer = inputObjects.docIdForDocViewer;
    // this.dynamicComponent.instance['selected-page'] = inputObjects.activePage;
    // this.dynamicComponent.instance.searchTerm = inputObjects.searchWithInTerm;
    // this.dynamicComponent.instance.illustrationData = inputObjects.illustrationData;
    // this.dynamicComponent.instance.documentData = inputObjects.documentData;
    // this.dynamicComponent.instance.docIdInput = inputObjects.docIdForDocViewer;
    // this.dynamicComponent.instance.invokedFromTab = inputObjects.invokedFromTab;
    // this.dynamicComponent.instance.loadedFrom = inputObjects.loadedFrom;
    // this.dynamicComponent.instance.docViewerClosed.subscribe((res) => {
    //   if (res === false) {
    //     this.removeComponent();
    //   }
    // });
    // this.dynamicComponent.instance.coverFlowDocumentID.subscribe((res) => {
    //  this.removeComponent();
    //  this.coverFlowDocumentId.next(res);
    // });

    // this.dynamicComponent.instance.pagechanged.subscribe((res) => {
    //   this.sharedService.shareImageViewerHighlight(res);
    // });

  }
  addAnalysisHubDynamicComponent(entry, tabName, inputs?) {
    this.domContainer = entry;
    let factory: any;
    // console.log(entry)
    this.domContainer.clear();
    switch (tabName) {
      case 'frequency':
        // console.log(this.factoryResolver)
        // factory =this.viewContainerRef.createComponent(component
        // factory = this.domContainer.createComponent(TermFrequencyComponent);
        // this.changeDetectorRef.detectChanges();
        // this.component = this.viewContainerRef.createComponent<T>(component);
        factory = TermFrequencyComponent;
        break;
      case 'popularity':
        // factory = this.factoryResolver.createComponent(TermPopularityComponent);
        factory = TermPopularityComponent
        break;
      case 'groups':
        // factory = this.factoryResolver.resolveComponentFactory(TermGroupsComponent);
        factory = TermGroupsComponent;
        break;
      case 'collocations':
        // factory = this.factoryResolver.resolveComponentFactory(CollocatesComponent);
        factory = CollocatesComponent;
        break;
      case 'word-cloud':
        // factory = this.factoryResolver.resolveComponentFactory(WordCloudComponent);
        factory = WordCloudComponent;
        break;
      case 'concordance':
        // factory = this.factoryResolver.resolveComponentFactory(ConcordanceComponent);
        factory = ConcordanceComponent;
        break;
      default:
        // factory = this.factoryResolver.resolveComponentFactory(TermFrequencyComponent);
        factory = TermFrequencyComponent;
        break;
    }
    if (this.domContainer) {
      this.removeComponent();
    }
    //factory is component name
    // domcontainer is template(view), where you want to place this dynamic component
    this.dynamicComponent = this.domContainer.createComponent(factory);
    // if you want to pass any input to dynamic component we can use instance
    // filterInputs is variable name for that input. we can get this- 
    // filterInputs variable in dynamic component
    this.dynamicComponent.instance.filterInputs = inputs;
  }
  isComponentAlreadyExists () {
    if (this.domContainer) {
      return true;
    }
  }
  removeComponent() {
    this.domContainer.clear();
  }
}
