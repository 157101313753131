import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RangeSliderComponent } from './range-slider/range-slider.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [RangeSliderComponent],
  exports: [RangeSliderComponent]
})
export class RangeSliderModule { }
