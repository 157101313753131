<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->

@if (pageSettings.showLeftMenu) {
  <div class="leftMenuContainer">
    <!-- *ngIf="headerContent?.currentArchive == 'WILEY' || headerContent?.currentArchive == 'RGS' || headerContent?.currentArchive == 'RCP'"  -->
    <div [class.arabic-style]="isArabicLanguage" class="filter-options" >
      <accordion>
        <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'togglearchiveicon')">
          <div [class.arabic-style]="isArabicLanguage" accordion-heading>
            <span [class.tamil-style]="checkCurrentLanguage === 'ta'">
              <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="items-label" title="{{ 'Titles.Archives' | translate }}">
              {{ 'Titles.Archives' | translate }} </a>
            </span>
            <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !togglearchiveicon ? 'block': 'none'}"></i>
            <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': togglearchiveicon ? 'block': 'none'}"></i>
          </div>
          <div class="col-md-12 col-xs-12 accordian-inner">
            <div class="padding-left-0 accodion-panel archives">
              <div class="accordian-panel-heading panel-Content">
                <!-- <perfect-scrollbar [config]="config" class="accordian-panel-heading panel-Content"> -->
                @if (facets) {
                  <div [class.cursornotAllowed]="disableFacet" [disabled]="disabled">
                    @for (archive of archivesFacetResult; track archive) {
                      <div [class.pointernone]="disableFacet" class="filter-content">
                        @if (archive.count>0) {
                          <div class="filter-content display-block" (click)="setArchive(archive)">
                            <label [class.arabic-style]="isArabicLanguage">
                              <a [class.arabic-style]="isArabicLanguage" class="filter-select txtDecore"
                                [innerHTML]="locationData?.locations[archive.value | uppercase]?.accordionTitle | translate"
                                [title]="parsedText(locationData?.locations[archive.value | uppercase]?.accordionTitle | translate)">
                              </a>
                            </label>
                            <span [class.arabic-style]="isArabicLanguage" class="badge-count pull-right">{{archive.count}}</span>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
              <!-- </perfect-scrollbar> -->
            </div>
            <div class="view_more">
              @if (archiveHasMore == true) {
                <button [class.arabic-style]="isArabicLanguage" (click)="searchService.viewMoreFacets('archive')"
                class="btn btn-primary viewmore-bnt pull-right" type="button">{{ 'Common.ViewMore' | translate }}</button>
              }
            </div>
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
  <div [class.arabic-style]="isArabicLanguage" class="filter-options">
    <accordion>
      <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'toggleanalysisicon')">
        <div accordion-heading>
          <a [class.tamil-style]="checkCurrentLanguage === 'ta'" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="items-label" title="{{ 'HomePage.analysisTools.termFrequency' | translate }}">
          {{ 'HomePage.analysisTools.termFrequency' | translate }}</a>
          <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !toggleanalysisicon ? 'block': 'none'}"></i>
          <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': toggleanalysisicon ? 'block': 'none'}"></i>
        </div>
        <div class="col-md-12 col-xs-12 accordian-inner analysis-tools-parent" [class.show-analysis-tools-parent]="showAnalysisTools" [class.align-right]="isArabicLanguage">
          <div class="padding-left-0 accodion-panel">
            <div class="accordian-panel-heading">
              <div [class.pointernone]="disableFacet" class="filter-content display-block">
                <div class="col-md-12 facet-sourceDiv" (click)="gotoAnalysisHub('frequency')">
                  <label class="filter-select" [class.arabic-style]="isArabicLanguage">
                    <a [class.arabic-style]="isArabicLanguage" title="{{ 'Titles.TermFrequency' | translate }} ({{shortcutKey}} + J)"
                    class="items-label">{{ 'Titles.TermFrequency' | translate }}</a>
                  </label>
                </div>
                <div class="col-md-12 facet-sourceDiv" (click)="gotoAnalysisHub('popularity')">
                  <label class="filter-select" [class.arabic-style]="isArabicLanguage">
                    <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{ 'Titles.TermPopularity' | translate }} ({{shortcutKey}} + Y)"
                    class="items-label">{{ 'Titles.TermPopularity' | translate }}</a>
                  </label>
                </div>
                <div class="col-md-12 facet-sourceDiv" (click)="gotoAnalysisHub('groups')">
                  <label class="filter-select" [class.arabic-style]="isArabicLanguage">
                    <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{ 'Titles.TermGroups' | translate }} ({{shortcutKey}} + G)"
                    class="items-label">{{ 'Titles.TermGroups' | translate }}</a>
                  </label>
                </div>
                <div class="col-md-12 facet-sourceDiv">
                  <label class="filter-select" (click)="gotoAnalysisHub('collocations')" [class.arabic-style]="isArabicLanguage">
                    <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{ 'Titles.collocation' | translate }} ({{shortcutKey}} + O)"
                    class="items-label">{{ 'Titles.collocation' | translate }}</a>
                  </label>
                </div>
                <div class="col-md-12 facet-sourceDiv">
                  <label class="filter-select" (click)="gotoAnalysisHub('concordance')"
                    [class.arabic-style]="isArabicLanguage">
                    <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                      title="{{ 'Titles.concordance' | translate }} ({{shortcutKey}} + N)"
                    class="items-label">{{ 'Titles.concordance' | translate }}</a>
                  </label>
                </div>
                <div class="col-md-12 facet-sourceDiv">
                  <label class="filter-select" (click)="gotoAnalysisHub('word-cloud')" [class.arabic-style]="isArabicLanguage">
                    <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{ 'Titles.wordCloud' | translate }} ({{shortcutKey}} + W)"
                    class="items-label">{{ 'Titles.wordCloud' | translate }}</a>
                  </label>
                </div>
                <!-- <div class="col-md-12 facet-sourceDiv">
                <label class="filter-select" (click)="gotoAnalysisHub('distribution')" [class.arabic-style]="isArabicLanguage">
                  <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="{{ 'Titles.frequencyDistribution' | translate }} ({{controlShortcut}} + D)"
                  class="items-label">{{ 'Titles.frequencyDistribution' | translate }}</a>
                </label>
              </div> -->
              <div class="col-md-12 facet-sourceDiv">
                <label class="filter-select" (click)="onMapClick()" [class.arabic-style]="isArabicLanguage">
                  <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                    title="{{ 'Titles.publicationBrowser' | translate }} ({{shortcutKey}} + Z)"
                  class="items-label">{{ 'Titles.publicationBrowser' | translate }}</a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      @if (!showAnalysisTools) {
        <div class="view_more" [class.arabic-style]="isArabicLanguage">
          <button [class.arabic-style]="isArabicLanguage" (click)="viewMoreNaalysisTools()"
          class="btn btn-primary viewmore-bnt pull-right" title="{{ 'Common.ViewMore' | translate }}" type="button">{{ 'Common.ViewMore' | translate }}</button>
        </div>
      }
    </accordion-group>
  </accordion>
</div>
@if (contentSubTypeFacetResult?.length > 1) {
  <div class="filter-options">
    <accordion>
      <accordion-group class="filter-header filter-header-select filter-header filter-header-select-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'togglecontentSubicon')">
        <div accordion-heading [class.arabic-style]="isArabicLanguage">
          <a [class.tamil-style]="checkCurrentLanguage === 'ta'" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="items-label" title="{{ 'SearchResult.ContentSubType' | translate }}">
          {{ 'SearchResult.ContentSubType' | translate }} </a>
          <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !togglecontentSubicon ? 'block': 'none'}"></i>
          <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': togglecontentSubicon ? 'block': 'none'}"></i>
        </div>
        <div class="col-md-12 col-xs-12 accordian-inner">
          <div class="padding-left-0 accodion-panel">
            <div class="accordian-panel-heading panel-Content">
              <!-- <perfect-scrollbar [config]="config" class="accordian-panel-heading panel-Content"> -->
              <!-- <div class="accordian-panel-heading panel-Content"> -->
              @if (facets) {
                <div class="filter-content display-block" [class.pointernone]="disableFacet" >
                  @for (contentSubType of contentSubTypeFacetResult; track contentSubType) {
                    <div class="filter-content display-block" (click)="setContentSubtype(contentSubType)">
                      @if (contentSubType.count > 0) {
                        <div>
                          <label [attr.title]="contentSubType.value" class="filter-select">
                            <a class="items-label">{{contentSubType.value}}</a>
                          </label>
                          <span class="badge-count pull-right">{{contentSubType.count}}</span>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            <!-- </div> -->
          <!-- </perfect-scrollbar> -->
        </div>
        <div class="view_more" [class.arabic-style]="isArabicLanguage">
          @if (contentSubtypeHasMore == true && contentSubTypeCount !== 0) {
            <button [class.arabic-style]="isArabicLanguage" (click)="searchService.viewMoreFacets('contentSubType')"
            class="btn btn-primary viewmore-bnt pull-right" title="{{ 'Common.ViewMore' | translate }}" type="button">{{ 'Common.ViewMore' | translate }}</button>
          }
        </div>
      </div>
    </div>
  </accordion-group>
</accordion>
</div>
}
@if (collectionFacetResult?.length > 1) {
  <div class="filter-options">
    <accordion>
      <accordion-group class="filter-header filter-header-select filter-header filter-header-select-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'togglecollectionicon')">
        <div [class.arabic-style]="isArabicLanguage" accordion-heading>
          <a [class.tamil-style]="checkCurrentLanguage === 'ta'" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="items-label" title="{{ 'SearchResult.Collections' | translate }}">
          {{ 'SearchResult.Collections' | translate }} </a>
          <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !togglecollectionicon ? 'block': 'none'}"></i>
          <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': togglecollectionicon ? 'block': 'none'}"></i>
        </div>
        <div class="col-md-12 col-xs-12 accordian-inner">
          <div class="padding-left-0 accodion-panel">
            <!-- <perfect-scrollbar [config]="config" class="accordian-panel-heading panel-Content"> -->
            <div class="accordian-panel-heading panel-Content">
              @if (facets) {
                <div class="filter-content display-block" [class.pointernone]="disableFacet" >
                  @for (collection of collectionFacetResult; track collection) {
                    <div class="filter-content display-block" (click)="setCollection(collection)">
                      @if (collection.count > 0) {
                        <div>
                          <label [attr.title]="collection.value" class="filter-select">
                            <a class="items-label">{{collection.value}}</a>
                          </label>
                          <span class="badge-count pull-right">{{collection.count}}</span>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          <!-- </perfect-scrollbar> -->
          <div [class.arabic-style]="isArabicLanguage" class="view_more">
            @if (collectionHasMore == true && collectionCount !== 0) {
              <button [class.arabic-style]="isArabicLanguage" (click)="searchService.viewMoreFacets('collection')"
              class="btn btn-primary viewmore-bnt pull-right" title="{{ 'Common.ViewMore' | translate }}" type="button">{{ 'Common.ViewMore' | translate }}</button>
            }
          </div>
        </div>
      </div>
    </accordion-group>
  </accordion>
</div>
}
@if (pubDateFacetResult?.length > 1) {
  <div class="filter-options">
    <accordion>
      <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'toggledatatoolicon')">
        <div [class.arabic-style]="isArabicLanguage" accordion-heading>
          <a [class.tamil-style]="checkCurrentLanguage === 'ta'" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="items-label" title="{{ 'Titles.DateTool' | translate }}">
          {{ 'Titles.DateTool' | translate }} </a>
          <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !toggledatatoolicon ? 'block': 'none'}"></i>
          <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': toggledatatoolicon ? 'block': 'none'}"></i>
        </div>
        <div class="col-md-12 col-xs-12 accordian-inner">
          <div class="padding-left-0 accodion-panel">
            <div class="accordian-panel-heading">
              <div class="filter-content display-block" [class.pointernone]="disableFacet" >
                <app-drill-down-chart (rangeSubmit)="updateResultsFromDateRange($event)"></app-drill-down-chart>
              </div>
            </div>
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
}
@if (languageFacetResult?.length > 1) {
  <div class="filter-options">
    <accordion>
      <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'togglelanguageicon')">
        <div [class.arabic-style]="isArabicLanguage" accordion-heading>
          <a [class.tamil-style]="checkCurrentLanguage === 'ta'" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="items-label"> {{ 'SearchResult.Languages' | translate }} </a>
          <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !togglelanguageicon ? 'block': 'none'}"></i>
          <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': togglelanguageicon ? 'block': 'none'}"></i>
        </div>
        <div class="col-md-12 col-xs-12 accordian-inner">
          <div class="padding-left-0 accodion-panel">
            <!-- <perfect-scrollbar [config]="config" class="accordian-panel-heading panel-Content"> -->
            <div class="accordian-panel-heading panel-Content">
              @if (languageFacetResult) {
                <div class="filter-content display-block" [class.pointernone]="disableFacet">
                  @for (language of languageFacetResult; track language) {
                    <div class="col-md-12 facet-sourceDiv" (click)="setLanguage(language)">
                      @if (language.count > 0) {
                        <div>
                          <label [attr.title]="language.value" class="filter-select">
                            <a class="items-label">{{language.value | titlecase}}</a>
                          </label>
                          <span class="badge-count pull-right">{{language.count}}</span>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          <!-- </perfect-scrollbar> -->
          <div class="view_more" [class.arabic-style]="isArabicLanguage">
            @if (docLangHasMore == true && languageCount !== 0) {
              <button [class.arabic-style]="isArabicLanguage" (click)="searchService.viewMoreFacets('docLang')"
              class="btn btn-primary viewmore-bnt pull-right" title="{{ 'Common.ViewMore' | translate }}" type="button">{{ 'Common.ViewMore' | translate }}</button>
            }
          </div>
        </div>
      </div>
    </accordion-group>
  </accordion>
</div>
}
@if (subjectFacetResult?.length > 1) {
  <div class="filter-options">
    <accordion>
      <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'togglesubjecticon')">
        <div [class.arabic-style]="isArabicLanguage" accordion-heading>
          <a [class.tamil-style]="checkCurrentLanguage === 'ta'" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="items-label"> {{ 'SearchResult.Subjects' | translate }} </a>
          <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !togglesubjecticon ? 'block': 'none'}"></i>
          <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': togglesubjecticon ? 'block': 'none'}"></i>
        </div>
        <div class="col-md-12 col-xs-12 accordian-inner">
          <div class="padding-left-0 accodion-panel">
            <!-- <perfect-scrollbar [config]="config" class="accordian-panel-heading panel-Content"> -->
            <div class="accordian-panel-heading panel-Content">
              @if (facets) {
                <div class="filter-content display-block" [class.pointernone]="disableFacet">
                  @for (subject of subjectFacetResult; track subject) {
                    <div (click)="setSubject(subject)">
                      @if (subject.count > 0) {
                        <div>
                          <label [attr.title]="subject.value" class="filter-select">
                            <a class="items-label">{{subject.value}}</a>
                          </label>
                          <span class="badge-count pull-right">{{subject.count}}</span>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          <!-- </perfect-scrollbar> -->
          <div class="view_more">
            @if (subjectHasMore == true && subResultCount !== 0) {
              <button [class.arabic-style]="isArabicLanguage" (click)="searchService.viewMoreFacets('subject')"
              class="btn btn-primary viewmore-bnt pull-right" title="{{ 'Common.ViewMore' | translate }}" type="button">{{ 'Common.ViewMore' | translate }}</button>
            }
          </div>
        </div>
      </div>
    </accordion-group>
  </accordion>
</div>
}
@if (sourcesFacetResult?.length > 1 && headerContent?.currentArchive !== 'RGS' && headerContent?.currentArchive !== 'RCP' ) {
  <div class="filter-options no-border-bottom">
    <accordion>
      <accordion-group class="filter-header filter-header-select" [isOpen]="true" (isOpenChange)="checkAccordionStatus($event, 'togglesourceicon')">
        <div [class.arabic-style]="isArabicLanguage" accordion-heading>
          <a [class.tamil-style]="checkCurrentLanguage === 'ta'" [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" class="items-label"> {{ 'SearchResult.Sources' | translate }} </a>
          <i class="fa fa-chevron-down pull-right accordion-upicn" [ngStyle]="{'display': !togglesourceicon ? 'block': 'none'}"></i>
          <i class="fa fa-chevron-up pull-right accordion-upicn" [ngStyle]="{'display': togglesourceicon ? 'block': 'none'}"></i>
        </div>
        <div class="col-md-12 col-xs-12 accordian-inner">
          <div class="padding-left-0 accodion-panel panel-Content">
            @if (facets) {
              <div class="filter-content display-block" [class.pointernone]="disableFacet">
                @for (source of sourcesFacetResult; track source) {
                  <div class="col-md-12 facet-sourceDiv" (click)="setSource(source)">
                    @if (source.count > 0) {
                      <div>
                        <label [attr.title]="source.value" class="filter-select">
                          <a class="items-label">{{source.value}}</a>
                        </label>
                        <span class="badge-count pull-right">{{source.count}}</span>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
          <div [class.arabic-style]="isArabicLanguage" class="view_more">
            @if (sourceInstitutionHasMore == true && sourcesCount !== 0) {
              <button [class.arabic-style]="isArabicLanguage" (click)="searchService.viewMoreFacets('sourceInstitution')"
              class="btn btn-primary viewmore-bnt pull-right" type="button">{{ 'Common.ViewMore' | translate }}</button>
            }
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
}
</div>
}