<div class="position-relative">
  @if (isHiglightisOn) {
    <div class="overlay-list">
      <a
        [class.arabic-style]="isArabicLanguage"
        class="toggleHighlight"
        (click)="toggleHighlight()"
        >
        {{ "Detail.Highlight" | translate }}
        @if (!toggleHighlighter) {
          <span
            [class.arabic-style]="isArabicLanguage"
            >{{ "Detail.Off" | translate }}</span
            >
          }
          @else {
            <span [class.arabic-style]="isArabicLanguage">{{
              "Detail.On" | translate
            }}</span>
          }
        </a>
      </div>
    }

    <div
      id="openSeaDragonCanvas"
      #editorContainer
      (mouseover)="imageViewerMouseEnter()"
      (mouseleave)="imageViewerMouseLeave()"
      >
      <div
        class="image-viewer-frame-loader"
        [ngStyle]="{ display: imageviewerframeLoader ? 'block' : 'none' }"
        >
        <img
          alt="{{ 'Alt.loaderImage' | translate }}"
          class="img-icon"
          src="assets/images/three-dots.svg"
          />
        </div>
        @if (
          this.currentArchives && this.currentArchives.shortName
          ? this.currentArchives && this.currentArchives.shortName.toUpperCase() != 'WPA' && this.currentArchives && this.currentArchives.shortName.toUpperCase() != 'WPA1'
          : true) {
          @if (!hideTitle) {
            <div
              id="tableOverlayTooltip"
              class="table-overlay-tooltip"
              style="display: none"
              >
              <p [class.arabic-style]="isArabicLanguage" class="overlay-tooltip">
                {{ "HomePage.clickToDownloadFile" | translate }}
              </p>
            </div>
          }
        }
        @if (imageConfig?.droneMapExists && !hideDroneText) {
          <div
            class="drone-content-box">
            <p class="dronemap-textcontent">
              This large-format map was captured by a drone using an innovative
              technique. Some areas may be slightly unfocused.
            </p>
            <span
              class="fa fa-times hidetitle-droneclose"
              (click)="hideDronemapsText()"
            ></span>
          </div>
        }
      </div>
      @if (!hideOverlay && hideTitle) {
        <span
          class="fa fa-chevron-up hidetitle-rarrow show-uparrow"
          [class.itemtitle-overlay-open]="itemNotes"
          (click)="showTitleOverlay()"
        ></span>
      }
      @if (!hideTitle) {
        <div class="overlay">
          <span
            class="fa fa-chevron-down hidetitle-rarrow"
            [class.itemtitle-overlay-close]="itemNotes"
            (click)="hideTitleOverlay()"
          ></span>
          @if (
            imageConfig?.title &&
            (imageConfig !== undefined || imageConfig !== null)
            ) {
            <div
              appDecodeEntities
              [class.arabic-style]="isArabicLanguage"
              class="text-white item-overlay-txt"
              #textContent
              id="read-more{{ itemNote }}"
              [class.lessText]="showCaptionTitle && imageConfig?.title?.length > 170"
              [class.overlay-mobile-view]="!showCaptionTitle"
              >
              {{ "Detail.imageItemTitle" | translate }}: {{ imageConfig.title }}
            </div>
          }
          @if (imageConfig?.title?.length > 170) {
            <div
              class="viewMoreBtn-section"
              (click)="showViewAction()"
              >
              @if (showCaptionTitle) {
                <span style="display: flex"
                  >{{ "HomePage.viewMore" | translate }} &nbsp;<i
                  [class.arabic-style]="isArabicLanguage"
                  class="fa fa-chevron-down morebtn"
                  title="{{ 'HomePage.viewMore' | translate }}"
                  aria-hidden="true"
                  ></i
                ></span>
              }
              @else {
                <span
                  >{{ "HomePage.viewLess" | translate }} &nbsp;<i
                  [class.arabic-style]="isArabicLanguage"
                  class="fa fa-chevron-up"
                  title="{{ 'HomePage.viewLess' | translate }}"
                  aria-hidden="true"
                  ></i
                ></span>
              }
            </div>
          }
          <!-- <div *ngIf="itemNotes" class="text-white item-notes-content">
          <span [class.arabic-style]="isArabicLanguage" class="notes-content">{{ 'Detail.Notes' | translate }}:</span>
          <ng-container *ngFor="let notes of itemNotes">
            <span class="notes-content">{{notes?.value}}</span>
          </ng-container>
        </div>
        <ng-template *ngIf="itemNote">
          <div *ngIf="itemNote.length > 150" class="view-more-text">
            <a role="button" *ngIf='viewMorebtn' (click)='viewMorebtn = false'>
              <span class="less pull-right" >{{ 'HomePage.viewLess' | translate }}
                <i class="btn btn-up-arrow fa fa-angle-up" aria-hidden="true"></i>
              </span>
            </a>
            <a  role="button" >
              <span class="more pull-right" *ngIf='!viewMorebtn'  (click)='viewMorebtn = true'>{{ 'HomePage.viewMore' | translate }}
                <i class="btn btn-down-arrow fa fa-angle-down" aria-hidden="true"></i>
              </span>
            </a>
          </div>
        </ng-template> -->
      </div>
    }

    <div class="backdrop-modal"></div>
    <div
      class="modal fade fullScreenModal-popup"
      id="fullScreenModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      data-backdrop="static"
      >
      <div class="modal-dialog large" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4
              [class.arabic-style]="isArabicLanguage"
              class="cliped-image-details"
              >
              {{ "HomePage.describeClippedImage" | translate }}
            </h4>
            <button
              type="button"
              class="btn-close"
              data-dismiss="modal"
              (click)="removeBackDrop()"
              aria-label="Close"
              class="clipImageClose">
              <!-- <span aria-hidden="true">&times;</span> -->
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label
                  [class.arabic-style]="isArabicLanguage"
                  class="pull-left custom-title"
                  >
                  {{ "HomePage.clipImageTitle" | translate }}:</label
                  >
                  <span class="glyphicon glyphicon-asterisk mandatory-icon"></span>
                  <input
                    type="text"
                    maxlength="100"
                value="{{ 'HomePage.clipImage' | translate }} {{
                  (clippedImageList?.length || 0) + 1
                }}"
                    class="form-control"
                    name="customTitle"
                    #customTitleRefModal
                    />
                  </div>
                  <div class="col-md-12">
                    <label
                      [class.arabic-style]="isArabicLanguage"
                      class="pull-left custom-title"
                      >
                      {{ "HomePage.clipImageNotes" | translate }}:</label
                      >
                      <textarea
                        class="form-control custom-textarea"
                        maxlength="500"
                        name="customTitle"
                        #customNotesRefModal
                      ></textarea>
                    </div>
                    <div class="col-md-12">
                      <br />
                      <button
                        type="button"
                        class="btn btn-primary pull-right"
                (click)="
                  updateTitle(
                    customTitleRefModal.value.trim(),
                    customNotesRefModal.value.trim()
                  )
                "
                        [disabled]="!customTitleRefModal.value.trim()"
                        >
                        {{ "SearchResult.AddToList" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade fullScreenModal-popup"
            id="downloadPdf"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            data-backdrop="static"
            >
            <div class="modal-dialog large" role="document">
              <div class="modal-content">
                <!-- <ng-template #downloadPageasPdfTemplate> -->
                <div class="modal-header download-title d-flex align-items-center justify-content-center">
                  <h4 class="modal-title">
                    <!-- <span class="downloadPopupIcon"></span> -->
                    <!-- <span class="spriteico downloadPopupIcon"></span> -->
                    <img
                      src="assets/images/icons/download@2x.png "
                      alt="View History"
                      class="downloadPopupIcon"
                      />
                    </h4>
                  </div>
                  <div class="modal-body url_body download-body">
                    <div [class.arabic-style]="isArabicLanguage" class="download-text">
                      @if (downloadPageURL) {
                        <a
                          [class.arabic-style]="isArabicLanguage"
                          class="primary-text-color"
                          href="{{ downloadPageURL }}"
                          target="_blank"
                          >{{ "Detail.downloadPDF" | translate }}</a
                          >
                      }
                      @if (downLoadTiffImageURL) {
                        <a
                          [class.arabic-style]="isArabicLanguage"
                          class="primary-text-color"
                          href="{{ downLoadTiffImageURL }}"
                          target="_blank"
                          >
                          @if (!isGeoTiffEnabled) {
                            <span>{{
                              "Detail.downloadTiff" | translate
                            }}</span>
                          }
                          @else {
                            <span>{{
                              "Detail.downloadGeoTiffLinkTxt" | translate
                            }}</span>
                          }
                        </a>
                      }
                    </div>
                    <div
                      class="modal-download-text download-text-cmt"
                      [class.arabic-style]="isArabicLanguage"
                      >
                      <span
                        [class.arabic-style]="isArabicLanguage"
                        class="modal-download-text-span"
                        >
                        {{ "Detail.clicktoDownloadFileMessage" | translate }}</span
                        >
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        [class.arabic-style]="isArabicLanguage"
                        type="button"
                        class="btn btn-secondary"
                        (click)="closeDownloadPdfModal(); $event.stopPropagation()"
                        >
                        {{ "Detail.Close" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            <!-- </ng-template> -->
          </div>
