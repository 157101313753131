<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<!-- <button (click) = "callDocumentViewer()" >Click Doc viewer</button> -->
<div class="row marginSpace">
  <div class="col-md-12 padding-0">
    <!-- <div class="row"> -->
    <div class="filter-result">
      <div [class.arabic-style]="isArabicLanguage" class="pageheader">
        <div class="small-underline" [class.arabic-style]="isArabicLanguage"></div>

        <h1 class="search-res-header" [class.arabic-style]="isArabicLanguage"
        title="{{ 'SearchResult.SearchResults' | translate }}">{{ 'SearchResult.SearchResults' | translate }}</h1>
      </div>

      @if (fieldVal == true || pageContentData?.searchTerm || pageContentData?.advancedFilterParam.contentType.length > 0 ||
        pageContentData?.advancedFilterParam.docSubType.length > 0 || pageContentData?.advancedFilterParam.publishedDateFrom  != '' ||
        pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
        pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
        0 || pageContentData?.advancedFilterParam.illustrationType.length > 0) {
        <div class="col-md-12 searchResult">
          <ul class="result-tag">
            @if (fieldVal == true || pageContentData?.searchTerm) {
              <ul [class.arabic-style]="isArabicLanguage" class="col-sm-12 col-xs-12 advSearch searchQry search_qry_term"
              [ngClass]="{'border_btm': pageContentData?.advancedFilterParam.contentType.length > 0 
                || pageContentData?.advancedFilterParam.docSubType.length > 0 || pageContentData?.advancedFilterParam.publishedDateFrom  != ''
                || pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 
                || pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
                0 || pageContentData?.advancedFilterParam.illustrationType.length > 0}">
              @if (fieldVal == true || pageContentData?.searchTerm) {
<label [class.arabic-style]="isArabicLanguage" class="searchBy limiterLabel fw-bold" [ngClass]="{'mr49': addMargin}"
               >{{ 'SearchResult.Query' | translate }}</label>
}
  
              <li class="simple-query-list">
                @for (searchTerms of searchTermDatas; track searchTerms; let last = $last) {
<span class="simple-searchterms">
                  @if (searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT' && searchTerms !== '') {

                    @if (pageContentData?.searchTerm ? true : false) {
<label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                     >{{ 'SearchResult.EntireDocument' | translate }}
                    </label>
}
                  
}
                  @if (searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT' && searchTerms !== '') {

                    @if (searchTerms !== '') {
<span class="badge margin-left5">
                      <span [attr.title]="searchTerms">
                        <span class="mrgn-l0 simpleSearch select-qry-single">{{searchTerms}}</span>
                      </span>
                    </span>
}
                  
}
                  @if (searchTerms === 'AND' || searchTerms === 'OR' || searchTerms === 'NOT' && searchTerms !== '') {

                    @if (!last) {
<span [class.arabic-style]="isArabicLanguage" class="boolean-operator operator op-and">
                      {{searchTerms}}
                    </span>
}
                  
}
                  @if (searchTerms !== 'AND' && searchTerms !== 'OR' && searchTerms !== 'NOT' && searchTerms !== '') {

                    @if (!last) {
<span [class.arabic-style]="isArabicLanguage" class="operator op-and">
                      {{ 'Common.OR' | translate }}
                    </span>
}
                  
}
                </span>
}
              </li>  
              <li>
                @for (keyword of pageContentData?.advancedFilterParam?.searchKeywords; track keyword; let i = $index; let last = $last) {
<div class="searchQryDiv"
                 >
                  @if (keyword.fieldValue !== '') {
<div class="col-sm-9" class="search-reslbls"
                    [attr.title]="keyword.fieldValue">
                          <div class="searchResultDoc">
                            <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                            [attr.title]="fieldNames[keyword.fieldName]">{{fieldNames[keyword.fieldName] | titlecase}}</label>
                            <span class="badge margin-left5">
                              @if (keyword.fieldValue !== '') {
                                <span [class.arabic-style]="isArabicLanguage" class="select-qry-single"
                                  >{{keyword.fieldValue}}
                                </span>
                              }
                              <i class="fa fa-times close-icon" (click)="setKeywords(i)"></i>
                            </span>
                          </div>
                        </div>
                      }
                      @if (i !== pageContentData?.advancedFilterParam?.searchKeywords - 1 && !last) {
                        <span class="operator">
                          @if (fieldVal == true) {
                            <span>{{keyword.operator}}</span>
                          }
                        </span>
                      }
                    </div>
                  }
                </li>
              </ul>
            }
            @if (pageContentData?.advancedFilterParam.contentType.length > 0 || pageContentData?.advancedFilterParam.docSubType.length > 0 || pageContentData?.advancedFilterParam.publishedDateFrom  != '' || pageContentData?.advancedFilterParam.languages.length > 0 ||
              pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
              pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
              0 || pageContentData?.advancedFilterParam.illustrationType.length > 0) {
              <ul class="col-sm-12 col-xs-12 advSearch searchQry">
                @if (pageContentData?.advancedFilterParam.contentType.length > 0 || pageContentData?.advancedFilterParam.docSubType.length > 0 ||
                  pageContentData?.advancedFilterParam.publishedDateFrom  != '' || pageContentData?.advancedFilterParam.languages.length > 0 ||
                  pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
                  pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length >
                  0 || pageContentData?.advancedFilterParam.illustrationType.length > 0 ) {
                  <label [class.arabic-style]="isArabicLanguage" class="limitedBy limiterLabel fw-bold">{{
                  'SearchResult.Limiters' | translate }}</label>
                }
                @if (pageContentData?.advancedFilterParam.contentType.length > 0) {
                  <li class="contenttype-results">
                    <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl">{{ 'SearchResult.ContentTypes' |
                    translate }} </label>
                    @if (pageContentData?.advancedFilterParam.contentType.length < 2) {
                      <span class="result-ele-label">
                        @for (contentType of pageContentData.advancedFilterParam.contentType; track contentType; let i = $index) {
                          <span
                            class="margin-left5" style="padding: 0 !important;">
                            <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;"
                              [attr.title]="contentType">
                              <div class="searchqryAdv pull-left">{{contentType}}</div>
                            </div>
                            @if (!isOtherTabActive) {
                              <div class="close_content_single pull-right">
                                <i class="fa fa-times close-icon" (click)="setContentType(contentType)" role="button"></i>
                              </div>
                            }
                          </span>
                        }
                      </span>
                    }
                    @if (pageContentData?.advancedFilterParam.contentType.length > 1) {
                      <div
                        class="badge badgePop limiter-badge" [attr.title]="pageContentData.advancedFilterParam.contentType[0]"
                        (click)="limiterBadgeOnclick($event)" [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true"
                        [outsideClick]="true" [popover]="contentTypepopOverTemplate" [placement]="'bottom'">
                        <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.contentType[0]}}</div>
                        <div class="search-resultcount pull-left">+{{pageContentData?.advancedFilterParam.contentType.length
                          -1}}
                          <i class="fa fa-caret-down"
                          [ngClass]="{'fa-caret-up':toggleviewlist ,'fa-caret-down': !toggleviewlist  }"></i>
                        </div>
                        <div class="close_content pull-right">
                          <i class="fa fa-times close-icon" (click)="clearContentType(contentType)"></i>
                        </div>
                      </div>
                    }
                    <ng-template #contentTypepopOverTemplate>
                      <!-- <span *ngIf="i != pageContentData?.advancedFilterParam.contentType.length - 1" class="operator"> OR </span> -->
                      <ul class="popoverUl">
                        @for (contentType of pageContentData.advancedFilterParam.contentType; track contentType; let i = $index) {
                          <li>
                            <span class="popover_inner_span">{{contentType}}</span>
                            <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setContentType(contentType)"></i>
                          </li>
                        }
                      </ul>
                    </ng-template>
                  </li>
                }
                @if (pageContentData?.advancedFilterParam.docSubType.length > 0) {
                  <li class="contentSubType_result">
                    <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                      title="{{ 'SearchResult.ContentSubtypes' | translate }}">{{ 'SearchResult.ContentSubtypes' | translate
                    }} </label>
                    @if (pageContentData?.advancedFilterParam.docSubType.length < 2 ) {
                      <label class="resultset-lbl">
                        @for (contentsubType of pageContentData.advancedFilterParam.docSubType; track contentsubType; let i = $index) {
                          <span
                            class=" margin-left5">
                            <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;"
                              [attr.title]="contentsubType">
                              <div class="searchqryAdv pull-left">{{contentsubType}}</div>
                            </div>
                            @if (!isOtherTabActive) {
                              <div class="close_content_single pull-right">
                                <i class="fa fa-times close-icon" (click)="setContentSubType(contentsubType)" role="button"></i>
                              </div>
                            }
                            @if (i != pageContentData?.advancedFilterParam.docSubType.length - 1) {
                              <span [class.arabic-style]="isArabicLanguage"
                                class="operator">
                              {{ 'Common.OR' | translate }} </span>
                            }
                          </span>
                        }
                      </label>
                    }
                    @if (pageContentData?.advancedFilterParam.docSubType.length > 1) {
                      <div
                        class="badge badgePop limiter-badge" [attr.title]="pageContentData.advancedFilterParam.docSubType[0]"
                        (click)="limiterBadgeOnclick($event)" [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true"
                        [outsideClick]="true" [popover]="contentsubTypepopOverTemplate" [placement]="'bottom'">
                        <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.docSubType[0]}}</div>
                        <div class="search-resultcount pull-left">+{{pageContentData?.advancedFilterParam.docSubType.length -1}}
                          <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="close_content pull-right">
                          <i class="fa fa-times close-icon" (click)="clearContentSubType()"></i>
                        </div>
                      </div>
                    }
                    <ng-template #contentsubTypepopOverTemplate>
                      <ul class="popoverUl">
                        @for (contentsubType of pageContentData.advancedFilterParam.docSubType; track contentsubType; let i = $index) {
                          <li>
                            <span class="popover_inner_span">{{contentsubType}}</span>
                            <i class="fa fa-times icon-sizes" aria-hidden="true"
                            (click)="setContentSubType(contentsubType)"></i>
                          </li>
                        }
                      </ul>
                    </ng-template>
                  </li>
                }
                @if (pageContentData?.advancedFilterParam.languages.length > 0) {
                  <li class="languages_result">
                    <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                    title=" {{ 'SearchResult.Languages' | translate }}">{{ 'SearchResult.Languages' | translate }}</label>
                    @if (pageContentData?.advancedFilterParam.languages.length < 2) {
                      <label>
                        @for (language of pageContentData.advancedFilterParam.languages; track language; let i = $index) {
                          <span
                            class=" margin-left5">
                            <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;" [attr.title]="language">
                              <div class="searchqryAdv pull-left">{{language}}</div>
                            </div>
                            @if (!isOtherTabActive) {
                              <div class="close_content_single pull-right">
                                <i class="fa fa-times close-icon" (click)="setLanguage(language)" role="button"></i>
                              </div>
                            }
                            @if (i != pageContentData?.advancedFilterParam.languages.length - 1) {
                              <span [class.arabic-style]="isArabicLanguage"
                                class="operator">
                              {{ 'Common.OR' | translate }} </span>
                            }
                          </span>
                        }
                      </label>
                    }
                    <!-- <span *ngIf=" pageContentData?.advancedFilterParam.contentCollection.length > 0 || pageContentData?.advancedFilterParam.contentSources.length > 0  " class="operator">AND</span> -->
                    @if (pageContentData?.advancedFilterParam.languages.length > 1) {
                      <div
                        [attr.title]="pageContentData.advancedFilterParam.languages[0]" class="badge badgePop limiter-badge"
                        (click)="limiterBadgeOnclick($event)" [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true"
                        [outsideClick]="true" [popover]="languagePopOverTemplate" [placement]="'bottom'">
                        <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.languages[0]}}</div>
                        <div class="search-resultcount pull-left">+{{pageContentData?.advancedFilterParam.languages.length -1}}
                          <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="close_content pull-right">
                          <i class="fa fa-times close-icon" (click)="clearLanguage()"></i>
                        </div>
                      </div>
                    }
                    <ng-template #languagePopOverTemplate>
                      <ul class="popoverUl">
                        @for (language of pageContentData.advancedFilterParam.languages; track language; let i = $index) {
                          <li>
                            <span class="popover_inner_span">{{language}}</span>
                            <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setLanguage(language)"></i>
                          </li>
                        }
                      </ul>
                    </ng-template>
                  </li>
                }
                @if (pageContentData?.advancedFilterParam.contentCollection.length > 0) {
                  <li class="contentCollection_result"
                    >
                    <label class="search-resultlbl" title="{{ 'SearchResult.Collections' | translate }} ">{{
                    'SearchResult.Collections' | translate }} </label>
                    @if (pageContentData?.advancedFilterParam.contentCollection.length < 2) {
                      <label [class.arabic-style]="isArabicLanguage"
                        >
                        @for (collection of pageContentData.advancedFilterParam.contentCollection; track collection; let i = $index) {
                          <span
                            class=" margin-left5">
                            <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;" [attr.title]="collection">
                              <div class="searchqryAdv pull-left">{{collection}}</div>
                            </div>
                            @if (!isOtherTabActive) {
                              <div class="close_content_single pull-right">
                                <i class="fa fa-times close-icon" (click)="clearCollection(collection)" role="button"></i>
                              </div>
                            }
                            @if (i != pageContentData?.advancedFilterParam.contentCollection.length - 1) {
                              <span [class.arabic-style]="isArabicLanguage"
                                class="operator">
                              {{ 'Common.OR' | translate }} </span>
                            }
                          </span>
                        }
                      </label>
                    }
                    @if (pageContentData?.advancedFilterParam.contentCollection.length > 1) {
                      <div
                        [attr.title]="pageContentData.advancedFilterParam.contentCollection[0]"
                        class="badge badgePop limiter-badge" (click)="limiterBadgeOnclick($event)"
                        [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true" [outsideClick]="true"
                        [popover]="collectionsepopOverTemplate" [placement]="'bottom'">
                        <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.contentCollection[0]}}</div>
                        <div class="search-resultcount pull-left">
                          +{{pageContentData?.advancedFilterParam.contentCollection.length -1}}
                          <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="close_content pull-right">
                          <i class="fa fa-times close-icon" (click)="clearCollection()"></i>
                        </div>
                      </div>
                    }
                    <ng-template #collectionsepopOverTemplate>
                      <ul class="popoverUl">
                        @for (collection of pageContentData.advancedFilterParam.contentCollection; track collection; let i = $index) {
                          <li>
                            <span class="popover_inner_span">{{collection}}</span>
                            <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setCollection(collection)"></i>
                          </li>
                        }
                      </ul>
                    </ng-template>
                  </li>
                }
                @if (pageContentData?.advancedFilterParam.contentSources.length > 0) {
                  <li class="contentSources_result">
                    <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                    title="{{ 'SearchResult.Sources' | translate }}">{{ 'SearchResult.Sources' | translate }} </label>
                    @if (pageContentData?.advancedFilterParam.contentSources.length < 2) {
                      <label>
                        @for (source of pageContentData.advancedFilterParam.contentSources; track source; let i = $index) {
                          <span
                            class=" margin-left5">
                            <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;" [attr.title]="source">
                              <div class="searchqryAdv pull-left">{{source}}</div>
                            </div>
                            @if (!isOtherTabActive) {
                              <div class="close_content_single pull-right">
                                <i class="fa fa-times close-icon" (click)="clearSource(source)" role="button"></i>
                              </div>
                            }
                            @if (i != pageContentData?.advancedFilterParam.contentSources.length - 1) {
                              <span [class.arabic-style]="isArabicLanguage"
                                class="operator">
                              {{ 'Common.OR' | translate }} </span>
                            }
                          </span>
                        }
                      </label>
                    }
                    @if (pageContentData?.advancedFilterParam.contentSources.length > 1) {
                      <div
                        [attr.title]="pageContentData.advancedFilterParam.contentSources[0]"
                        class="badge badgePop limiter-badge" (click)="limiterBadgeOnclick($event)"
                        [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true" [outsideClick]="true"
                        [popover]="sourcepopOverTemplate" [placement]="'bottom'">
                        <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.contentSources[0]}}</div>
                        <div class="search-resultcount pull-left">+{{pageContentData?.advancedFilterParam.contentSources.length
                          -1}}
                          <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="close_content pull-right">
                          <i class="fa fa-times close-icon" (click)="clearSource()"></i>
                        </div>
                      </div>
                    }
                    <ng-template #sourcepopOverTemplate>
                      <ul class="popoverUl">
                        @for (source of pageContentData.advancedFilterParam.contentSources; track source; let i = $index) {
                          <li>
                            <span class="popover_inner_span">{{source}}</span>
                            <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setSource(source)"></i>
                          </li>
                        }
                      </ul>
                    </ng-template>
                  </li>
                }
                @if (pageContentData?.advancedFilterParam.illustrationType.length > 0) {
                  <li class="contentSources_result">
                    <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                      title="{{ 'SearchResult.IllustrationType' | translate }}">{{ 'SearchResult.IllustrationType' | translate
                    }} </label>
                    @if (pageContentData?.advancedFilterParam.illustrationType.length < 2) {
                      <label>
                        @for (illustration of pageContentData.advancedFilterParam.illustrationType; track illustration; let i = $index) {
                          <span
                            class=" margin-left5">
                            <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;"
                              [attr.title]="illustration">
                              <div class="searchqryAdv pull-left">{{illustration}}</div>
                            </div>
                            @if (!isOtherTabActive) {
                              <div class="close_content_single pull-right">
                                <i class="fa fa-times close-icon" (click)="clearIllustration(illustration)" role="button"></i>
                              </div>
                            }
                            @if (i != pageContentData?.advancedFilterParam.illustrationType.length - 1) {
                              <span [class.arabic-style]="isArabicLanguage"
                                class="operator">
                              {{ 'Common.OR' | translate }} </span>
                            }
                          </span>
                        }
                      </label>
                    }
                    @if (pageContentData?.advancedFilterParam.illustrationType.length > 1) {
                      <div
                        [attr.title]="pageContentData.advancedFilterParam.illustrationType[0]"
                        class="badge badgePop limiter-badge" (click)="limiterBadgeOnclick($event)"
                        [ngClass]="{'popoverSelect':toggleviewlist }" aria-hidden="true" [outsideClick]="true"
                        [popover]="illustrationpopOverTemplate" [placement]="'bottom'">
                        <div class="select-qry pull-left">{{pageContentData.advancedFilterParam.illustrationType[0]}}</div>
                        <div class="search-resultcount pull-left">
                          +{{pageContentData?.advancedFilterParam.illustrationType.length -1}}
                          <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="close_content pull-right">
                          <i class="fa fa-times close-icon" (click)="clearIllustration()"></i>
                        </div>
                      </div>
                    }
                    <ng-template #illustrationpopOverTemplate>
                      <ul class="popoverUl">
                        @for (illustration of pageContentData.advancedFilterParam.illustrationType; track illustration; let i = $index) {
                          <li>
                            <span class="popover_inner_span">{{illustration}}</span>
                            <i class="fa fa-times icon-sizes" aria-hidden="true" (click)="setIllustration(illustration)"></i>
                          </li>
                        }
                      </ul>
                    </ng-template>
                  </li>
                }
                @if (pageContentData?.advancedFilterParam.subject.length > 0) {
                  <li class="contentSources_result">
                    <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                    title="{{ 'SearchResult.Subjects' | translate }}">{{ 'SearchResult.Subjects' | translate }} </label>
                    @if (pageContentData?.advancedFilterParam.subject.length < 2) {
                      <label>
                        @for (subject of pageContentData.advancedFilterParam.subject; track subject; let i = $index) {
                          <span
                            class=" margin-left5">
                            <div class="badge select-qry pull-left" style="padding: 6px; height: 26px;" [attr.title]="subject">
                              <div class="searchqryAdv pull-left">{{subject}}</div>
                            </div>
                            @if (!isOtherTabActive) {
                              <div class="close_content_single pull-right">
                                <i class="fa fa-times close-icon" (click)="clearSubject(subject)" role="button"></i>
                              </div>
                            }
                            @if (i != pageContentData?.advancedFilterParam.subject.length - 1) {
                              <span [class.arabic-style]="isArabicLanguage"
                                class="operator">
                              {{ 'Common.OR' | translate }} </span>
                            }
                          </span>
                        }
                      </label>
                    }
                  </li>
                }
                @if (pageContentData?.advancedFilterParam.publishedDateFrom || pageContentData?.advancedFilterParam.publishedDateIncludesNull) {
                  <li
                    (click)="togglepublist = !togglepublist;$event.stopPropagation();"
                    [ngClass]="{'popoverSelect':toggleviewlist, 'publishedDate_result' : (pageContentData?.advancedFilterParam.publishedDateTo || pageContentData?.advancedFilterParam.publishedDateIncludesNull) }">
                    <label [class.arabic-style]="isArabicLanguage" class="search-resultlbl"
                      title="{{ 'SearchResult.PublicationDate' | translate }}">{{ 'SearchResult.PublicationDate' | translate
                    }}</label>
                    <br>
                      <!-- <span> -->
                      @if (pageContentData?.advancedFilterParam.publishedDateFrom) {
                        <label>
                          <span class="badge margin-left5">
                            <span [class.select-pub-otherarchive]="isOtherTabActive" class="select-qry-pub">
                              @if (pageContentData?.advancedFilterParam.publishedDateFrom !== 'All') {
                                <span
                                >{{pageContentData?.advancedFilterParam.publishedDatePrefix}}:</span>
                              }
                              {{pageContentData?.advancedFilterParam.publishedDateFrom}}
                              @if (pageContentData?.advancedFilterParam.publishedDateTo) {
                                <span>{{ 'Common.AND' | translate
                                }}</span>
                              }
                              @if (pageContentData?.advancedFilterParam.publishedDateTo.includes('-') && pageContentData?.advancedFilterParam.publishedDateTo.length > 5 && pageContentData?.advancedFilterParam.publishedDateTo.length > 8) {
                                <span
                                  >
                                  {{pageContentData?.advancedFilterParam.publishedDateTo}}
                                </span>
                              }
                              @if (pageContentData?.advancedFilterParam.publishedDateTo.length === 5) {
                                <span>
                                  {{pageContentData?.advancedFilterParam.publishedDateTo.slice(0,4)}}
                                </span>
                              }
                              @if (pageContentData?.advancedFilterParam.publishedDateTo.length < 5) {
                                <span>
                                  {{pageContentData?.advancedFilterParam.publishedDateTo}}
                                </span>
                              }
                              @if (pageContentData?.advancedFilterParam.publishedDateTo.length === 8) {
                                <span>
                                  {{pageContentData?.advancedFilterParam.publishedDateTo.slice(0,7)}}
                                </span>
                              }
                            </span>
                            @if (pageContentData?.advancedFilterParam.publishedDateIncludesNull) {
                              <span [class.arabic-style]="isArabicLanguage" class="undated"
                                >
                                {{ 'SearchResult.ANDUNDATED' | translate }}
                              </span>
                            }
                            <!-- <i class="fa fa-times close-icon" aria-hidden="true" (click)="setPublicationDate()"></i> -->
                            @if (!isOtherTabActive) {
                              <i class="fa fa-times close-icon" (click)="setPublicationDate()"></i>
                            }
                          </span>
                        </label>
                      }
                    <!-- </span> -->
                    <!-- <span *ngIf="pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 || pageContentData?.advancedFilterParam.contentSources.length > 0  " class="operator">AND</span> -->
                    @if (pageContentData.advancedFilterParam.publishedDatePrefix === 'BETWEEN') {
                      <i class="badge"
                      aria-hidden="true" [outsideClick]="true" [popover]="pubDatepopOverTemplate" [placement]="'bottom'"></i>
                    }
                    <ng-template #pubDatepopOverTemplate>
                      <ul class="popoverUl">
                        <li>
                          <i class="fa fa-times icon-sizes" aria-hidden="true"
                          (click)="setPublicationDate()"></i>{{pageContentData?.advancedFilterParam.publishedDatePrefix}}:
                          <span [class.select-pub-otherarchive]="isOtherTabActive"
                            class="select-qry-pub">{{pageContentData?.advancedFilterParam.publishedDateFrom}} {{
                            'Common.AND' | translate }} {{pageContentData?.advancedFilterParam.publishedDateTo}}
                          </span>
                        </li>
                      </ul>
                    </ng-template>
                  </li>
                }
              </ul>
            }
          </ul>
        </div>
      }
      @if ((searchResultCount) && !resultLoader ) {
        <div class="col-md-12 paddingBottom-0 marginBottom">
          <div class="floatLeft">
            <div class="paddingLeft searchRecord">
              @if (searchResultCount && !(pageContentData?.groupsToBeDisplayed.length > 0)) {
                <label class="resultlbl fw-bold"
                  title="{{searchResultCount}} {{ 'SearchResult.DocumentsFound' | translate }}">{{searchResultCount}}
                  <span [class.arabic-style]="isArabicLanguage" class="result-span">{{ 'SearchResult.DocumentsFound' |
                  translate }}</span>
                </label>
              }
              @if (pageContentData?.groupsToBeDisplayed.length > -1) {
                <label>
                  @for (group of groups; track group; let i = $index) {
                    <span class="resultlbl fw-bold">
                      <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'SearchResult.searchContent' |
                      translate }}</span>
                      @if (group.group === pageContentData?.groupsToBeDisplayed[0]) {
                        <label
                          title="{{group?.totalGroupElements}} {{ 'SearchResult.DocumentsFound' | translate }}">{{group?.totalGroupElements}}
                          <span [class.arabic-style]="isArabicLanguage" class="result-span">{{ 'SearchResult.DocumentsFound' |
                          translate }}</span>
                        </label>
                      }
                    </span>
                  }
                </label>
              }
            </div>
          </div>
          @if (fieldVal == true || pageContentData?.searchTerm || pageContentData?.advancedFilterParam.contentType.length > 0 ||
            pageContentData?.advancedFilterParam.docSubType.length > 0 || pageContentData?.advancedFilterParam.publishedDateFrom  != '' ||
            pageContentData?.advancedFilterParam.languages.length > 0 || pageContentData?.advancedFilterParam.contentCollection.length > 0 ||
            pageContentData?.advancedFilterParam.contentSources.length > 0 || pageContentData?.advancedFilterParam.subject.length > 0) {
            <div
              class="floatLeft cleardiv" (click)="resetSearchFilter()" tabindex="0" (keyup.enter)="resetSearchFilter()">
              <!-- && !(pageContentData?.groupsToBeDisplayed.length > 0) -->
              @if (searchResultCount) {
                <span class="spriteico clearIcon"></span>
              }
              @if (searchResultCount) {
                <label [class.arabic-style]="isArabicLanguage" class="bm-page fw-bold"
                  title="{{ 'SearchResult.ClearSearch' | translate }} ({{shortcutKeyTitle}} + Delete)">{{
                'SearchResult.ClearSearch' | translate }}</label>
              }
            </div>
          }
          <div class="floatRight">
            <div class="paddingRight">
              <ul class="search-resultul">
                <li>
                  <span class="i-Maps-icons"></span>
                </li>
                <li class="search-resultli right-seperator">
                  <span id="cursor-not-allowed-map">
                    <span tabindex="0" class="fa fa-globe" [ngClass]="{'active': _isInMapView}"
                      title="{{ 'SearchResult.ToggleMapView' | translate }} ({{shortcutKeyTitle}} + Z)" id="mapIcon"
                    aria-hidden="true" (click)="mapViewClick()" (keyup.enter)="mapViewClick()"></span>
                  </span>
                </li>
                <li class="search-resultli right-seperator">
                  <span id="CursorNotAllowed"
                    [ngClass]="{'cursornotallowed' : photographView || scrollDisplay === 'Photographs'}">
                    <span class="fa fa-list" title="{{ 'SearchResult.Listview' | translate }} ({{shortcutKeyTitle}} + L)"
                      id="ListIcon" [ngClass]="getStyleListDisplay()" aria-hidden="true" (click)="listViewClick('list')"
                      tabindex="0" (keyup.index)="listViewClick('list')" [ngClass]="{'disabled'
  : (scrollDisplay === 'Photographs')}"></span>
                  </span>
                </li>
                <li class="search-resultli vertical-line">
                  <span class="fa fa-th" title="{{ 'SearchResult.Gridview' | translate }} ({{shortcutKeyTitle}} + L)"
                    id="GridIcon" [ngClass]="getStyleGridDisplay()" aria-hidden="true" (click)="gridViewClick('grid')"
                  tabindex="0" (keyup.index)="gridViewClick('grid')"></span>
                </li>
                <li class="search-resultli dropdown internalShare" (click)="internalShareToggleList($event)">
                  <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                    title="{{ 'SearchResult.ShareSearchQryTxt' | translate }} ({{shiftContrlKey}} + Y)"
                    href="javascript:void(0)">
                    <!-- <span class="spriteico shareIcon dropdown-toggle"></span> -->
                    <span class="spriteico shareIcon"></span>
                    <label [class.arabic-style]="isArabicLanguage" class="bm-page fw-bold"> {{ 'SearchResult.ShareSearchQryTxt' |
                    translate }}</label>
                  </a>
                  @if (toggleInternalShareList) {
                    <app-share-document class="shareDocumentClss" [bookMarkShare]="toggleInternalShareList"
                    [currentDocument]="" [bookmarkDocumentURL]="bookmarkEntireDocURL" [internalShareOnly]=true></app-share-document>
                  }
                  <!-- <div class="dropdown-menu shareWidth" *ngIf="!isGuestUser" role="menu"
                  [ngStyle]="{'display': toggleInternalShareList ? 'flex': 'none'}">
                  <div class="shareLimitedPanel"bookmarkEntireDocURL>
                    <h6>{{'Detail.shareIntenalUsers' |translate}}</h6>
                    <p>{{'SearchResult.ShareSearchQueryContent' |translate}}</p>
                    <span class="bookMarkLink">{{}}</span>
                    <ul [class.arabic-style]="isArabicLanguage" (click)="$event.stopPropagation();"
                      id="shareIconsWidth">
                      <li style="display: inline-flex;">
                        <div class="mailBtn m-r-3">
                          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon"
                            (click)="shareMailClick(bookmarkEntireDocURL)"
                            title="{{ 'Detail.shareByMail' | translate }}">
                            <i class="fa fa-envelope"></i>
                            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Detail.mailIcon' |
                            translate }}</span>
                          </button>
                        </div>
                        <div class="mailBtn m-r-3">
                          <button [class.arabic-style]="isArabicLanguage" type="button" class="mailIcon share-bg"
                            (click)="copytoClipBoard(bookmarkEntireDocURL,isFromShare)"
                            title="{{ 'Detail.copytoClipboard' | translate }}">
                            <i class="fa fa-files-o fa-lg" aria-hidden="true"></i>
                            <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{'Common.Copy'
                            |translate}}</span>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> -->
              </li>
            </ul>
          </div>
        </div>
        @if (showOtherTab) {
          <div class="tabs">
            <ul class="archives-tab">
              <li class="docviewer-tabs" [ngClass]="{'active':!isOtherTabActive}"
              (click)="otherTabsetclick('selected')">Search Results</li>
              <li class="docviewer-tabs" [ngClass]="{'active':isOtherTabActive}"
              [attr.disabled]="isdisableOtherArchives" (click)="otherTabsetclick('other')">View Results from Additional Archives</li>
            </ul>
            @if (documentFromOtherArchives) {
              <div class="doc-access-link">
                <p class="bold-txt">For access to these additional archive(s) please contact your librarian.
                </p>
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
  @if (resultLoader) {
    <p [class.arabic-style]="isArabicLanguage">{{'SearchResult.loadingPleaseWait' | translate}}</p>
  }
  @if (!(searchResultCount) && !resultLoader) {
    <p [class.arabic-style]="isArabicLanguage">{{
      'SearchResult.NoResultsFound' | translate }}
      <a [class.arabic-style]="isArabicLanguage" class="backtosearch-link" (click)="returntoSearch()">{{
      'SearchResult.return' | translate }}</a> {{ 'SearchResult.toPreviousSearch' | translate }}</p>
    }
    <div class="row">
      <div class="padding-0" id="searchResultDiv" [ngClass]="{ 'col-xs-6 col-sm-5 col-md-4': _isInMapView, 'col-sm-12 defaultView': !_isInMapView, 'search-result-float-menu': _isInMapView, 'heatmap-result-leftbar': (showLeftMenu &&_isInMapView), 'collapsed-leftmenu': (!showLeftMenu &&_isInMapView) , 'isSliderOpen': isSliderPopover}">
        <!-- maps view -->
        <div class="row" [style.display]="_isInMapView ? 'block' : 'none'">
          <div class="col-sm-12 padding-0 toggle-filterdiv" [ngStyle]="{'display': showLeftMenu ? 'block': 'none'}">
            <div class="row">
              <div [class.arabic-style]="isArabicLanguage"
                class="col-sm-9 toggle-filterdivleft padding-left-0 padding-right-0">
                <label [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.publicationBrowser' | translate }}</label>
              </div>
              <div class="col-sm-3 toggle-filterrt toggle-filterright-arrow padding-left-0 padding-right-0" tabindex="0">
                <a class="sidebar-toggle" title="{{ 'SearchResult.CollapseTheListView' | translate }}" role="button"
                  (click)="toggleLeftMenu()" tabindex="0" (keyup.enter)="toggleLeftMenu()">
                  <i aria-hidden="true" class="fa fa-chevron-left fa-lg"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="toggle-filterrightdiv" [ngStyle]="{'display': showLeftMenu ? 'none': 'block'}">
            <a class="sidebar-toggle" title="{{ 'SearchResult.ExpandTheListView' | translate }}" role="button"
              (click)="toggleLeftMenu()" tabindex="0" (keyup.enter)="toggleLeftMenu()">
              <i aria-hidden="true" class="fa fa-chevron-right fa-lg"></i>
            </a>
          </div>
        </div>
        <!-- <div class="row"> -->
        @if (!resultLoader) {
          <div class="col-md-12 padding-0" scrollable (scrollPosition)="scrollHandler($event)"
            [ngClass]="{' search-result-float-menu-content' : _isInMapView}">
            @if (groups?.length == 0 && noResultInMap) {
              <div [class.arabic-style]="isArabicLanguage"
                [style.display]="_isInMapView ? 'block' : 'none'">
                <span [class.arabic-style]="isArabicLanguage" class="noResultFound">
                  {{ 'SearchResult.PleaseSelectArea' | translate }}
                  <span class="graphicDot"></span> {{ 'SearchResult.IsPresent' | translate }}
                </span>
              </div>
            }
            @if (groups?.length > 0) {
              <accordion [isAnimated]="true" [style.display]="(showLeftMenu || !_isInMapView) ? 'block' : 'none'"
                class="searchresult-accordion">
                @for (group of groups; track group; let i = $index) {
                  @if (pageContentData?.groupsToBeDisplayed?.length == 0 ? true : pageContentData?.groupsToBeDisplayed?.indexOf(group?.group) > -1) {
                    <accordion-group
                [ngClass]="{'order1':(group?.group == 'Manuscripts' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false)) || (isContentTypePhoto && group?.group == 'Photographs') || (isContentTypeMap && group?.group == 'Maps') || (contentTypeList.length>0 && group?.order== '1'),
              'order2':(group?.group == 'Monographs' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false))|| (isContentTypePhoto && group?.group == 'Manuscripts') || (isContentTypeMap && group?.group == 'Manuscripts') || (contentTypeList.length>0 && group?.order== '2'),
              'order3':(group?.group == 'Photographs' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false))  || (isContentTypePhoto && group?.group == 'Maps') || (isContentTypeMap && group?.group == 'Photographs') || (contentTypeList.length>0 && group?.order== '3'),
              'order4':(group?.group == 'Maps' && (!(contentTypeList.length>0)) && (isContentTypePhoto===false && isContentTypeMap=== false)) || (contentTypeList.length>0 && group?.order== '4') }"
               
                [isOpen]="true" (isOpenChange)="checkAccordionStatus(i, $event)">
                      <div class="heading" accordion-heading>
                        @if (group?.group == 'Monographs') {
                          <i class="acc_icon monographIcon" aria-hidden="true"></i>
                        }
                        @if (group?.group == 'Manuscripts') {
                          <i class="acc_icon manuscriptsIcon" aria-hidden="true"></i>
                        }
                        {{group?.group}}
                        <span class="doc-count"> {{' (' + group?.totalGroupElements + ')'}} </span>
                        @if (group?.group == 'Photographs') {
                          <i class="acc_icon photographIcon" aria-hidden="true"></i>
                        }
                        @if (group?.group == 'Maps') {
                          <i class="fa fa-map-marker  map-icon"
                          [ngClass]="{'map-icon-collapsed': !togglesearchicon[i]  }" aria-hidden="true"></i>
                        }
                        <i class="fa fa-chevron-down pull-right accordion-upicn accordionDownIcon{{i}}"
                        [ngStyle]="{'display': !togglesearchicon[i] ? 'block': 'none'}"></i>
                        <i class="fa fa-chevron-up pull-right accordion-upicn  accordionDownIcon{{i}}"
                        [ngStyle]="{'display': togglesearchicon[i] ? 'block': 'none'}"></i>
                      </div>
                      <div #getScrollElement class="row" [attr.data-scrollgroup]="group?.group"
                        [attr.data-scrolldoclength]="group?.documents.length"
                        [attr.data-scrolltotalgroup]="group?.totalGroupElements" [attr.data-gridviewscroll]="false"
                        [ngClass]="{'search-resultsRemove': scrollDisplay, 'nosearch': !scrollDisplay}">
                        @for (document of group?.documents; track document; let j = $index) {
                          <div [class.pad-btm-10]=" _isInMapView" [ngClass]="setGridClasses(group?.group)"
                            [class.mapView-enable]="_isInMapView" [class.gridview-enable]="showGridView"
                            [class.listview-enabled]="!showGridView" [class.listview-only]="!showGridView && !_isInMapView"
                            [class.pointernone]="documentFromOtherArchives && (group?.group !== 'Photographs')"
                            [class.pointersnone]="documentFromOtherArchives && (group?.group === 'Photographs')"
                            class="col-md-12 col-xs-12 accordian-inner listViewSwitch padding-right-0"
                            >
                            <div class="row position-relative">
                              @if (group?.documents.length > 0) {
                                <div [ngClass]="{ 'col-sm-4 col-lg-3': _isInMapView, 'col-sm-2 col-md-1 col-xs-3': !_isInMapView }"
                                  class="sub-content col-sm-4 col-xs-3 padding-left-0 img_pad {{group?.group}}class "
                                  >
                                  <div class="searchresult-imgdiv" aria-hidden="true">
                                    @if (((document.archive?.toUpperCase() === 'RAI') && (group?.group === 'Photographs'))) {
                                      <a [tooltip]="pageTitle" placement="auto" (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                                        (contextmenu)="onRightClick(document, group?.group, $event)" id="{{document?.id}}">
                                        <img class="accordian-img mx-auto d-block img-fluid" [ngClass]="{'p-2': phgroup?.group == 'Photographs'}"
                                    [attr.src]="document.folderImages ? document.folderImages[0]+ '&type=small' : pageContentData.hasFolderImage &&
                                                        showFolderImage?.locations[document.archive]?.showFolderImage == 'false' &&
                                                        (group?.group === 'Manuscripts' || group?.group === 'Photographs')?
                                                        (document.imageThumbnails?.length > 1  ? document.imageThumbnails[1] + '&type=small' : document.imageThumbnails[0] + '&type=small' ) : (document.imageThumbnails ? document.imageThumbnails[0] + '&type=small' : noImageUrl )"
                                    alt="{{'Alt.imageThumbnails' | translate}}" (error)="errorHandler($event)" />
                                        </a>
                                      }
                                      @if (!((document.archive?.toUpperCase() === 'RAI') && (group?.group === 'Photographs'))) {
                                        <a [tooltip]="pageTitle" placement="auto" (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                                          (contextmenu)="onRightClick(document, group?.group, $event)" id="{{document?.id}}">
                                          <img class="accordian-img mx-auto d-block img-fluid" [ngClass]="{'p-2': group?.group == 'Photographs'}"
                                    [attr.src]="pageContentData.hasFolderImage &&
                                                        showFolderImage?.locations[document.archive]?.showFolderImage == 'false' &&
                                                        (group?.group === 'Manuscripts' || group?.group === 'Photographs')?
                                                        (document.imageThumbnails?.length > 1  ? document.imageThumbnails[1] + '&type=small' : document.imageThumbnails[0] + '&type=small' ) : (document.imageThumbnails ? document.imageThumbnails[0] + '&type=small' : noImageUrl )"
                                    alt="{{'Alt.imageThumbnails' | translate}}" (error)="errorHandler($event)" />
                                          </a>
                                        }
                                        <ng-template #imageSliderPOpover>
                                          <!-- <div class=""> -->
                                          <app-image-swipe-slider [documentIndex]="j" [document]="document" [group]="group?.group"
                                            [thumbnailImages]="document.imageThumbnails"
                                            (callDocViewerOutput)="callDocViewerFromOutput($event)"
                                            [hasFolderImage]="pageContentData.hasFolderImage && showFolderImage?.locations[document.archive]?.showFolderImage == 'false' && (group?.group === 'Manuscripts' || group?.group === 'Photographs')">
                                          </app-image-swipe-slider>
                                        <!-- </div> -->
                                      </ng-template>
                                      <!-- Tooltip -->
                                      <ng-template #pageTitle>
                                        <div class="pagetitle-popoverdiv">
                                          <a (contextmenu)="onRightClick(document,group?.group, $event)"
                                            (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                                            title="{{ document?.title}}"
                                            [class.popup-doc-title-elipsis]="document?.title?.length > 70"
                                            class="search-result-header-title popup-document-title"
                                            [class.docVisited]="visitedDocList.indexOf(document.id)> -1"
                                          appDecodeEntities>{{document.title}} </a>
                                          <div [innerHtml]="document.description"></div>
                                          <div class="image_count-photograph">
                                            <span [class.arabic-style]="isArabicLanguage" class="img_count"> {{document.pageCount}}
                                            </span>
                                            {{ (document.pageCount > 1) ? ('SearchResult.ImagesInThis' | translate) :
                                            ('SearchResult.ImageInThis' | translate) }} {{(group?.group?.substr(-1) ===
                                            's')?group?.group?.substring(0,(group?.group?.length
                                            - 1)) :group?.group }} {{'SearchResult.imageCollection' | translate}}
                                          </div>
                                        </div>
                                      </ng-template>
                                      <!-- bottom Icons -->
                                      @if (!_isInMapView && !popoverforIpad) {
                                        <i class="view-icn {{group?.group}}-icon"
                                          title="{{ 'SearchResult.PeekAtTheImagesInDoc' | translate }}"
                                          (click)='imgsliderToggle($event)' tabindex="0" (keyup.enter)='imgsliderToggle($event)'
                                          tooltipPlacement="bottom" aria-hidden="true" [outsideClick]="true"
                                        [popover]="imageSliderPOpover" [placement]="'left'"></i>
                                        @if (document?.processType === 'atr') {
                                          <img
                                            tooltip="This handwritten document is searchable." placement="right" alt="ATR Icon"
                                            class="atr-list-img-icon" [class.full-wdth-atr-style]="!leftMenuStatus"
                                            [class.mapview-atr]="_isInMapView" [class.grid-atr-cls]="showGridView"
                                            src="assets/images/icons/ATRN3.png">
                                          }
                                          @if (document?.processType === 'drone') {
                                            <img
                                              tooltip="This large-format map was captured by a drone." placement="right" alt="Drone Icon"
                                              class="atr-list-img-icon" [class.full-wdth-atr-style]="!leftMenuStatus"
                                              [class.mapview-atr]="_isInMapView" [class.grid-atr-cls]="showGridView"
                                              src="assets/images/icons/Drone1.png">
                                            }
                                            @if (document?.isGeoBoundaryExist) {
                                              <i class="view-icn {{group?.group}}-icon geo-icon"
                                                title="{{ 'SearchResult.gotoMapsExplorer' | translate }}"
                                                (click)='gotoMapsExplorer(document?.id)' tabindex="0"
                                              (keyup.enter)='imgsliderToggle($event)' tooltipPlacement="bottom" aria-hidden="true"></i>
                                            }
                                            @if (group?.group == 'Photographs' || showGridView) {
                                              <div [class.arabic-style]="isArabicLanguage" class="photograph-image-count image-count"
                                                title="{{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) : ('SearchResult.image' | translate) }}">
                                                {{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) :
                                                ('SearchResult.image' | translate)
                                                }}
                                              </div>
                                            }
                                          }
                                          @if (_isInMapView && !popoverforIpad) {
                                            <i class="view-icn {{group?.group}}-icon"
                                              title="{{ 'SearchResult.PeekAtTheImagesInDoc' | translate }}" tabindex="0"
                                              (keyup.enter)='imgsliderToggle($event)' (click)='imgsliderToggle($event)'
                                              tooltipPlacement="bottom" aria-hidden="true" [outsideClick]="true"
                                            [popover]="imageSliderPOpover" [placement]="'right'"></i>
                                            @if (document?.processType === 'atr') {
                                              <img
                                                tooltip="This handwritten document is searchable." placement="right" alt="ATR Icon"
                                                class="atr-list-img-icon" [class.full-wdth-atr-style]="!leftMenuStatus"
                                                [class.grid-atr-cls]="showGridView" [class.mapview-atr]="_isInMapView"
                                                src="assets/images/icons/ATRN3.png">
                                              }
                                              @if (document?.processType === 'drone') {
                                                <img alt="Drone Icon"
                                                  [class.full-wdth-atr-style]="!leftMenuStatus" [class.grid-atr-cls]="showGridView"
                                                  [class.mapview-atr]="_isInMapView" class="atr-list-img-icon"
                                                  src="assets/images/icons/Drone1.png"
                                                  tooltip="This large-format map was captured by a drone." placement="right">
                                              }
                                              @if (document?.isGeoBoundaryExist) {
                                                <i class="view-icn {{group?.group}}-icon geo-icon"
                                                  title="{{ 'SearchResult.gotoMapsExplorer' | translate }}"
                                                  (click)='gotoMapsExplorer(document?.id)' tabindex="0"
                                                (keyup.enter)='imgsliderToggle($event)' tooltipPlacement="bottom" aria-hidden="true"></i>
                                              }
                                              @if (group?.group == 'Photographs' || showGridView) {
                                                <div [class.arabic-style]="isArabicLanguage" class="photograph-image-count image-count"
                                                  title="{{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) : ('SearchResult.image' | translate) }}">
                                                  {{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) :
                                                  ('SearchResult.image' | translate)
                                                  }}
                                                </div>
                                              }
                                            }
                                            @if (!_isInMapView && popoverforIpad) {
                                              <i class="view-icn" title="{{ 'SearchResult.PeekAtTheImagesInDoc' | translate }}"
                                                (click)='imgsliderToggle($event)' tabindex="0" (keyup.enter)='imgsliderToggle($event)'
                                                tooltipPlacement="bottom" aria-hidden="true" [outsideClick]="true"
                                              [popover]="imageSliderPOpover" [placement]="'right'"></i>
                                              @if (document?.processType === 'atr') {
                                                <img
                                                  tooltip="This handwritten document is searchable." placement="right" alt="ATR Icon"
                                                  class="atr-list-ipad atr-list-img-icon" [class.grid-atr-cls]="showGridView"
                                                  [class.full-wdth-atr-style]="!leftMenuStatus" [class.mapview-atr]="_isInMapView"
                                                  src="assets/images/icons/ATRN3.png">
                                                }
                                                @if (document?.processType === 'drone') {
                                                  <img alt="Drone Icon"
                                                    class="atr-list-ipad atr-list-img-icon" [class.grid-atr-cls]="showGridView"
                                                    [class.full-wdth-atr-style]="!leftMenuStatus" [class.mapview-atr]="_isInMapView"
                                                    src="assets/images/icons/Drone1.png"
                                                    tooltip="This large-format map was captured by a drone." placement="right">
                                                }
                                                @if (document?.isGeoBoundaryExist) {
                                                  <i class="view-icn {{group?.group}}-icon geo-icon"
                                                    title="{{ 'SearchResult.gotoMapsExplorer' | translate }}"
                                                    (click)='gotoMapsExplorer(document?.id)' tabindex="0"
                                                  (keyup.enter)='imgsliderToggle($event)' tooltipPlacement="bottom" aria-hidden="true"></i>
                                                }
                                                @if (group?.group == 'Photographs' || showGridView) {
                                                  <div [class.arabic-style]="isArabicLanguage" class="photograph-image-count image-count"
                                                    title="{{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) : ('SearchResult.image' | translate) }}">
                                                    {{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) :
                                                    ('SearchResult.image' | translate)
                                                    }}
                                                  </div>
                                                }
                                              }
                                              @if (_isInMapView && popoverforIpad) {
                                                <i class="view-icn" title="{{ 'SearchResult.PeekAtTheImagesInDoc' | translate }}" tabindex="0"
                                                  (click)='imgsliderToggle($event)' (keyup.enter)='imgsliderToggle($event)'
                                                  tooltipPlacement="bottom" aria-hidden="true" [outsideClick]="true"
                                                [popover]="imageSliderPOpover" [placement]="'right'"></i>
                                                @if (document?.processType === 'atr') {
                                                  <img
                                                    tooltip="This handwritten document is searchable." placement="right" alt="ATR Icon"
                                                    class="atr-list-img-icon" [class.mapview-atr]="_isInMapView"
                                                    [class.grid-atr-cls]="showGridView" [class.full-wdth-atr-style]="!leftMenuStatus"
                                                    src="assets/images/icons/ATRN3.png">
                                                  }
                                                  @if (document?.processType === 'drone') {
                                                    <img alt="Drone Icon" class="atr-list-img-icon"
                                                      [class.grid-atr-cls]="showGridView" [class.full-wdth-atr-style]="!leftMenuStatus"
                                                      src="assets/images/icons/Drone1.png" [class.mapview-atr]="_isInMapView"
                                                      tooltip="This large-format map was captured by a drone." placement="right">
                                                  }
                                                  @if (document?.isGeoBoundaryExist) {
                                                    <i class="view-icn {{group?.group}}-icon geo-icon"
                                                      title="{{ 'SearchResult.gotoMapsExplorer' | translate }}"
                                                      (click)='gotoMapsExplorer(document?.id)' tabindex="0"
                                                    (keyup.enter)='imgsliderToggle($event)' tooltipPlacement="bottom" aria-hidden="true"></i>
                                                  }
                                                  @if (group?.group == 'Photographs' || showGridView) {
                                                    <div [class.arabic-style]="isArabicLanguage" class="photograph-image-count image-count"
                                                      title="{{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) : ('SearchResult.image' | translate) }}">
                                                      {{document.pageCount}} {{ (document.pageCount > 1) ?( 'SearchResult.Images'| translate) :
                                                      ('SearchResult.image' | translate)
                                                      }}
                                                    </div>
                                                  }
                                                }
                                              </div>
                                            </div>
                                          }
                                          <div [ngClass]="{ 'col-md-7 col-sm-6 col-xs-7': _isInMapView ,'col-md-11 col-sm-10 col-xs-9' : !_isInMapView}"
                                            class="padding-left-0 padding-right-0 accodion-panel {{group?.group}}show getClassShow(group?.group)">
                                            <div class="row">
                                              <div class="col-md-12 col-sm-12 col-xs-12 gridview-panel" [ngClass]="paddingLeft(group?.group)">
                                                <div class="panel-heading"
                                                  [class.content-fullwidth]="group?.group !== 'Manuscripts' && !_isInMapView"
                                                  [ngClass]="getClassHide(group?.group)" [class.panel-block]="!_isInMapView">
                                                  <h2 data-toggle="tooltip1" data-placement="right">
                                                    <div class="searchResultHeaderContainer">
                                                      <!-- tabindex="0"  -->
                                                      <label title="{{ document.title }}"
                                                        [ngClass]="{'headerTitle': !_isInMapView || (_isInMapView && document?.title.length > 40) }"
                                                        class="search-result-header-title"
                                                        title="Right click to open link in new tab - {{document?.title}}" tabindex="0"
                                                        (keyup.enter)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                                                        (click)="callDocumentViewer(document?.id, '','', j, '', group?.group)"
                                                        (contextmenu)="onRightClick(document, group?.group, $event)" id="{{document?.id}}"
                                                        [class.docVisited]="visitedDocList.indexOf(document.id)> -1">{{document.title}}
                                                      </label>
                                                    </div>
                                                  </h2>
                                                  @if (!_isInMapView) {
                                                    <div class="row">
                                                      <div class="col-md-12 padding0" [innerHtml]="document.description"></div>
                                                      @if (pageContentData.currentArchive === 'WILEY') {
                                                        <div class="source-citation col-md-12 padding0" [class.arabic-style]="isArabicLanguage"
                                                          >
                                                          <label [class.arabic-style]="isArabicLanguage" class="left-source">{{'Common.Source' |
                                                          translate}} &nbsp;</label>
                                                          @if (document.archive ==='BAAS') {
                                                            <label class="right-source italic">{{document.archive
                                                              ==='BAAS' ? archivefullName :
                                                            document.sourceInstitution}}</label>
                                                          }
                                                          @if (document.archive ==='ENV') {
                                                            <label class="right-source italic">{{document.archive
                                                              ==='ENV' ? ENVArchiveFullName :
                                                            document.sourceInstitution}}</label>
                                                          }
                                                          @if (document.archive !=='BAAS' && document.archive !=='ENV') {
                                                            <label
                                                              class="right-source italic">{{document.archive ==='BAAS' ? archivefullName :
                                                            document.sourceInstitution}}</label>
                                                          }
                                                        </div>
                                                      }
                                                      @if (detailsRedirectParams['start']= j) {
                                                        @if (false) {
                                                          <span>{{SearchResult.coverflow | translate}}</span>
                                                        }
                                                      }
                                                      @if (group?.group == 'Manuscripts') {
                                                        <div class="image_count col-md-12 padding0">
                                                          <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-6 padding0">
                                                              <span [class.arabic-style]="isArabicLanguage" class="img_count">
                                                                {{document.pageCount}}
                                                              </span> {{ 'SearchResult.ImagesInThis' | translate }} {{(group?.group?.substr(-1) ===
                                                              's')?group?.group?.substring(0,(group?.group?.length
                                                              - 1)) :group?.group }}
                                                            </div>
                                                            <div
                                                              class="col-xs-12 col-sm-6 alignTextLeft col-md-6 pull-right padding0 text-end browse-collection-div">
                                                              @if (document?.collectionNo) {
                                                                <a [class.arabic-style]="isArabicLanguage"
                                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                                  class="open-new-tab-link collection-icon"
                                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                                                </a>
                                                              }
                                                              @if (document?.collectionNo) {
                                                                <a [class.arabic-style]="isArabicLanguage"
                                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                                  class="browse-collection-text"
                                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)"
                                                                  [ngStyle]="{'border-right': document?.collectionNo ? '1px solid #61165e': 'none', 'padding-right': document?.collectionNo ? '9px': 'none'}">
                                                                {{ 'SearchResult.browseThisCollection' | translate }} </a>
                                                              }
                                                              <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                                                title="{{ 'SearchResult.openInNewTab' | translate }}"
                                              class="open-new-tab-link open-tab-icon" [routerLink]="['../detail/' + document.id,
                                            detailsRedirectParams]" [ngStyle]="{'margin-right': document?.collectionNo ? '3px': 'none'}">
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      }
                                                      @if (group?.group == 'Monographs') {
                                                        <div class="image_count col-md-12 padding0">
                                                          <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-6 padding0">
                                                              <span [class.arabic-style]="isArabicLanguage" class="img_count">
                                                                {{document.pageCount}}
                                                              </span>
                                                              {{ (document.pageCount > 1) ? ('SearchResult.ImagesInThis' | translate) :
                                                              ('SearchResult.ImageInThis' | translate) }} {{(group?.group?.substr(-1)===
                                                              's')?group?.group?.substring(0,(group?.group?.length - 1)) :group?.group }}
                                                            </div>
                                                            <div class="col-xs-12 col-sm-6 alignTextLeft  col-md-6 pull-right padding0 text-end">
                                                              @if (document?.collectionNo) {
                                                                <a [class.arabic-style]="isArabicLanguage"
                                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                                  class="open-new-tab-link collection-icon"
                                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                                                </a>
                                                              }
                                                              @if (document?.collectionNo) {
                                                                <a [class.arabic-style]="isArabicLanguage"
                                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                                  class="browse-collection-text"
                                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)"
                                                                  [ngStyle]="{'border-right': document?.collectionNo ? '1px solid #61165e': 'none', 'padding-right': document?.collectionNo ? '9px': 'none'}">
                                                                {{ 'SearchResult.browseThisCollection' | translate }} </a>
                                                              }
                                                              <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                                                title="{{ 'SearchResult.openInNewTab' | translate }}"
                                            class="open-new-tab-link open-tab-icon" [routerLink]="['../detail/' + document.id,
                                              detailsRedirectParams]" [ngStyle]="{'margin-right': document?.collectionNo ? '3px': 'none'}"></a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      }
                                                      @if (group?.group == 'Photographs') {
                                                        <div class="image_count col-md-12 padding0">
                                                          <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-6  padding0">
                                                              <span [class.arabic-style]="isArabicLanguage" class="img_count">
                                                                {{document.pageCount}}
                                                              </span> {{ 'SearchResult.imagesInThisPhotographicCollection' | translate }}
                                                            </div>
                                                            <div class="col-xs-12 col-sm-6 alignTextLeft col-md-6 pull-right padding0 text-end">
                                                              @if (document?.collectionNo) {
                                                                <a [class.arabic-style]="isArabicLanguage"
                                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                                  class="open-new-tab-link collection-icon"
                                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                                                </a>
                                                              }
                                                              @if (document?.collectionNo) {
                                                                <a [class.arabic-style]="isArabicLanguage"
                                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                                  class="browse-collection-text"
                                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                                                {{ 'SearchResult.browseThisCollection' | translate }} </a>
                                                              }
                                                              <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                                                title="{{ 'SearchResult.openInNewTab' | translate }}"
                                                                class="open-new-tab-link open-tab-icon"
                                                              [routerLink]="['../detail/' + document.id, detailsRedirectParams]"></a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      }
                                                      @if (group?.group == 'Maps') {
                                                        <div class="image_count col-md-12 padding0">
                                                          <div class="row">
                                                            <div class="col-xs-12 col-sm-6 col-md-6 padding0">
                                                              <span [class.arabic-style]="isArabicLanguage" class="img_count">
                                                                {{document.pageCount}}
                                                              </span>
                                                              {{ (document.pageCount > 1) ? ('SearchResult.ImagesInThis' | translate) :
                                                              ('SearchResult.ImageInThis' | translate) }} {{(group?.group?.substr(-1)===
                                                              's')?group?.group?.substring(0,(group?.group?.length - 1)) :group?.group }}
                                                            </div>
                                                            <div class="col-xs-12 col-sm-6 col-md-6 alignTextLeft  pull-right text-end padding0">
                                                              @if (document?.collectionNo) {
                                                                <a [class.arabic-style]="isArabicLanguage"
                                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                                  class="open-new-tab-link collection-icon"
                                                                (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)"></a>
                                                              }
                                                              @if (document?.collectionNo) {
                                                                <a [class.arabic-style]="isArabicLanguage"
                                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                                  class="browse-collection-text"
                                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)"
                                                                  [ngStyle]="{'border-right': document?.collectionNo ? '1px solid #61165e': 'none', 'padding-right': document?.collectionNo ? '9px': 'none'}">
                                                                {{ 'SearchResult.browseThisCollection' | translate }} </a>
                                                              }
                                                              <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                                                title="{{ 'SearchResult.openInNewTab' | translate }}"
                                                                class="open-new-tab-link open-tab-icon" [routerLink]="['../detail/' + document.id, detailsRedirectParams]"
                                                              [ngStyle]="{'margin-right': document?.collectionNo ? '3px': 'none'}"> </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      }
                                                    </div>
                                                  }
                                                  @if (group?.group == 'Manuscripts' || group?.group == 'Monographs') {
                                                    <div class="mobileContentSubType"
                                                      [ngClass]="getClassHide(group?.group)">
                                                      <a [class.arabic-style]="isArabicLanguage" href="#" class="dropdown-toggle"
                                                        data-toggle="dropdown">
                                                        <button [class.arabic-style]="isArabicLanguage" type="button"
                                                          class="btn contentsubType">
                                                          {{ 'SearchResult.ContentSubtypes' | translate }}
                                                          <b class="caret"></b>
                                                        </button>
                                                      </a>
                                                      <ul class="dropdown-menu">
                                                        @for (docsubType of document?.docSubType?.split(','); track docsubType) {
                                                          <li
                                                            (click)="documentSubtypeList(document.id, docsubType, j)">
                                                            <a>{{docsubType}}</a>
                                                          </li>
                                                        }
                                                      </ul>
                                                    </div>
                                                  }
                                                </div>
                                                @if (group?.group == 'Manuscripts') {
                                                  <div class="panel-rightcontent" [style.display]="_isInMapView ? 'none' : 'block'"
                                                    [ngClass]="getClassHide(group?.group)">
                                                    <div class="tooltip-box">
                                                      <div class="tooltip-box-scroll"></div>
                                                      <!-- <perfect-scrollbar [config]="config" class="tooltip-box-scroll"> -->
                                                      @for (docsubType of document?.docSubType?.split(','); track docsubType) {
                                                        <a (contextmenu)="disableRightClick($event)" href="javascript:void(0)"
                                                          (click)="documentSubtypeList(document.id, docsubType, j)"
                                                        title="{{docsubType}}">{{docsubType}}</a>
                                                      }
                                                    <!-- </perfect-scrollbar> -->
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        @if (!_isInMapView) {
                                          <div class="{{group?.group}}GridIcon illustration_list col-md-12 padding-0" [ngClass]="{'icon-width' : _isInMapView, 'w-auto': group?.group !== 'Photographs'}">
                                            <div class="listview-icon search-div-icon">
                                              <ul class="search-iconsdiv photographwidth {{group?.group}}ListViewIcon"
                                                [class.positions]="document?.latlon" [ngClass]="getgridviewList(group?.group)"
                                                [class.gridviewBorder]="gridViewClick"
                                                [ngStyle]="{'border-left': group?.group == 'Manuscripts' ? 'none' : '1px solid #d2d2da'}">
                                                @if (document.isIllustrationExist == true  && group?.group !== 'Photographs' && group?.group !== 'Maps') {
                                                  <li tabindex="0" (keyup.enter)="getDocIllustrations(document.id, j)"
                                                    (click)="getDocIllustrations(document.id, j)"
                                                    >
                                                    <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                      href="javascript:void(0)" class="serch-icns img-icon"
                                                      (click)="imageClick(document.title)">
                                                      <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                      'SearchResult.BrowseIllustrations' | translate }}</span>
                                                      <span class="spriteico illustrationsIcon" [class.gridViewIcons]="showGridView"
                                                        title="{{ 'SearchResult.BrowseIllustrations' | translate }}" aria-hidden="true">
                                                        @if (document.illustrationCount) {
                                                          <span class="illustrationCount not-mapview"
                                                            [ngStyle]="{'left': group?.group == 'Monographs' ? '50px': '16px'}">
                                                            <div [class.arabic-style]="isArabicLanguage" class="illustration-badge">
                                                            {{document.illustrationCount}}</div>
                                                          </span>
                                                        }
                                                      </span>
                                                    </a>
                                                  </li>
                                                }
                                                @if (document.isIllustrationExist == false && group?.group !== 'Photographs' && group?.group !== 'Maps') {
                                                  <li tabindex="0" [class.gridviewIllustration]="gridViewClick"
                                                    >
                                                    <a (contextmenu)="disableRightClick($event)" href="javascript:void(0)"
                                                      class="serch-icns img-icon">
                                                      <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                      'SearchResult.BrowseIllustrationsNotFound' | translate }}</span>
                                                      <span class="spriteico illustrationsIcon img-disable"
                                                        [class.gridViewIcons]="showGridView" [class.illustrationfour]="document?.latlon"
                                                        title="{{ 'SearchResult.BrowseIllustrationsNotFound' | translate }}"
                                                      aria-hidden="true"></span>
                                                    </a>
                                                  </li>
                                                }
                                                <li class="menu-list dropdown documentLevelShare socialShare{{j}}"
                                                  (click)="socialShare(document,j, group?.group);$event.stopPropagation();">
                                                  <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                    title="{{ 'Detail.Share' | translate }}" href="javascript:void(0)">
                                                    <span class="spriteico shareIcon dropdown-toggle"></span>
                                                  </a>
                                                  @if (document.enableShare) {
                                                    <div>
                                                      <app-share-document class="shareDocumentClss" [bookMarkShare]="document.enableShare"
                                                        [currentDocument]="document" [bookmarkDocumentURL]="bookmarkURL"
                                                      [bookMarkExternalShareUrl]="shareUrl"></app-share-document>
                                                    </div>
                                                  }
                                                </li>
                                                @if (viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) == -1 : true) {
                                                  <li tabindex="0" (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                                                    (click)="pageSettingService.addToViewList(document, group?.group, j)"
                                                    [class.gridlist]="gridViewClick"
                                                    >
                                                    <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                      href="javascript:void(0)" class="serch-icns list-icon">
                                                      <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                      'SearchResult.AddToList' | translate }} </span>
                                                      <span class="spriteico addtolistIcon" [class.gridViewIcons]="showGridView"
                                                        [class.addtolistfour]="document?.latlon"
                                                      title="{{ 'SearchResult.AddToList' | translate }}" aria-hidden="true"></span>
                                                    </a>
                                                  </li>
                                                }
                                                @if (viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) >= 0 : false) {
                                                  <li tabindex="0" (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                                                    (click)="pageSettingService.addToViewList(document, group?.group, j)"
                                                    [class.gridviewList]="gridViewClick"
                                                    >
                                                    <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                      href="javascript:void(0)" class="serch-icns list-icon">
                                                      <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                      'SearchResult.RemoveFromList' | translate }}</span>
                                                      <span class="spriteico removefromlistIcon" [class.gridViewIcons]="showGridView"
                                                        [class.addtolistfour]="document?.latlon"
                                                      title="{{ 'SearchResult.RemoveFromList' | translate }}" aria-hidden="true"></span>
                                                    </a>
                                                  </li>
                                                }
                                                @if ((document.showDocs && document.paidArchive) && showDCIcon) {
                                                  <li tabindex="0" (keyup.enter)="pageSettingService.addToViewContent(document, group?.group)"
                                                    (click)="pageSettingService.addToViewContent(document, group?.group, j);getContentVal(document, group?.group, j, 'add')"
                                                    [class.gridlist]="gridViewClick">
                                                    <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                      class="serch-icns list-icon">
                                                      <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                      'SearchResult.AddToContent' | translate }} </span>
                                                      <span class="spriteico addtocontentIcon" [class.gridViewIcons]="showGridView"
                                                        [class.addtolistfour]="document?.latlon"
                                                      title="{{ 'SearchResult.AddToContent' | translate }}" aria-hidden="true"></span>
                                                    </a>
                                                  </li>
                                                }
                                                @if ((!document.showDocs && document.paidArchive) && showDCIcon) {
                                                  <li tabindex="0" (keyup.enter)="pageSettingService.removeToViewContent(document, group?.group)"
                                                    (click)="pageSettingService.removeToViewContent(document, group?.group, j); getContentVal(document, group?.group, j, 'remove')"
                                                    [class.gridviewList]="gridViewClick">
                                                    <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                      class="serch-icns list-icon">
                                                      <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                      'SearchResult.RemoveFromContent' | translate }}</span>
                                                      <span class="spriteico removefromcontentIcon" [class.gridViewIcons]="showGridView"
                                                        [class.addtolistfour]="document?.latlon"
                                                      title="{{ 'SearchResult.RemoveFromContent' | translate }}" aria-hidden="true"></span>
                                                    </a>
                                                  </li>
                                                }
                                                @if (group?.group === 'Photographs') {
                                                  <li tabindex="0" [class.gridlist]="gridViewClick">
                                                    @if (document?.collectionNo) {
                                                      <a [class.arabic-style]="isArabicLanguage"
                                                        title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                        class="open-new-tab-link collection-icon-new"
                                                        (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                                      </a>
                                                    }
                                                  </li>
                                                }
                                                @if (group?.group === 'Photographs') {
                                                  <li tabindex="0" [class.gridlist]="gridViewClick">
                                                    <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                                      title="{{ 'SearchResult.openInNewTab' | translate }}"
                                    class="open-new-tab-link open-tab-icon-new" [routerLink]="['../detail/' + document.id,
                                detailsRedirectParams]"></a>
                                                  </li>
                                                }
                                                @if ((document?.latlon)) {
                                                  <li tabindex="0" [class.arabic-style]="isArabicLanguage"
                                                    [class.position]="document?.latlon" (contextmenu)="disableRightClick($event)"
                                                    (keyup.enter)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                                                    (click)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                                                    tooltipPlacement="bottom" [class.gridlist]="gridViewClick" class="serch-icns"
                                                    title="{{ 'SearchResult.DropPinOnMap' | translate}}">
                                                    <span role="button" aria-pressed="false" class="mapIcon fa fa-map-marker browsemap_icon"
                                                      [class.gridViewIcons]="showGridView"
                                                    [ngStyle]="{'color': document['highlightSelected'] ? '#068853': '#61165e'}"></span>
                                                    <ng-template #dropPinTooltip>
                                                      Drop pin on Map
                                                    </ng-template>
                                                  </li>
                                                }
                                              </ul>
                                            </div>
                                          </div>
                                        }
                                        @if (_isInMapView) {
                                          <div class="map-group-icons" [class.full-wdth-style]="!leftMenuStatus"
                                            [class.browsemaps-map-gridview]="showGridView"
                                            [class.browser-photo-cls]="group?.group === 'Photographs'"
                                            [class.browser-maps-cls]="group?.group === 'Maps'">
                                            <i class="fa fa-ellipsis-v triple-dot" aria-hidden="true" [outsideClick]="true"
                                            [popover]="browsermapspopOverTemplate" [placement]="'left'"></i>
                                          </div>
                                        }
                                        @if (_isInMapView && !showGridView) {
                                          <div class="mapsclass-new position-absolute" [class.full-wdth-style]="!leftMenuStatus"
                                            >
                                            <div class="listview-icon search-div-icon">
                                              <ul class="search-iconsdiv photographwidth {{group?.group}}ListViewIcon"
                                                [class.positions]="document?.latlon" [ngClass]="getgridviewList(group?.group)"
                                                [class.gridviewBorder]="gridViewClick" style="margin:0px"
                                                [ngStyle]="{'border-left': group?.group == 'Manuscripts' ? 'none' : '1px solid #d2d2da'}">
                                                @if (!showGridView && document.isIllustrationExist == true  && group?.group !== 'Photographs' && group?.group !== 'Maps') {
                                                  <li tabindex="0" class="background-image"
                                                    (keyup.enter)="getDocIllustrations(document.id, j)"
                                                    (click)="getDocIllustrations(document.id, j)"
                                                    >
                                                    <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                      href="javascript:void(0)" class="serch-icns img-icon img-align"
                                                      (click)="imageClick(document.title)">
                                                      <span [class.arabic-style]="isArabicLanguage" class="browse-txt">{{
                                                      'SearchResult.BrowseIllustrations' | translate }}</span>
                                                      <span class="spriteico  spriteico-icon illustrationsIcon"
                                                        [class.gridViewIcons]="showGridView"
                                                        title="{{ 'SearchResult.BrowseIllustrations' | translate }}" aria-hidden="true">
                                                        @if (document.illustrationCount) {
                                                          <span class="illustrationCount"
                                                            [ngStyle]="{'left': group?.group == 'Monographs' ? '50px': '16px'}">
                                                            <div [class.arabic-style]="isArabicLanguage" class="illustration-badge">
                                                            {{document.illustrationCount}}</div>
                                                          </span>
                                                        }
                                                      </span>
                                                    </a>
                                                  </li>
                                                }
                                                @if (!showGridView && document.isIllustrationExist == false && group?.group !== 'Photographs' && group?.group !== 'Maps') {
                                                  <li tabindex="0" [class.gridviewIllustration]="gridViewClick"
                                                    >
                                                    <a (contextmenu)="disableRightClick($event)" href="javascript:void(0)"
                                                      class="serch-icns img-icon">
                                                      <!-- <span [class.arabic-style]="isArabicLanguage" class="browse-txt">{{
                                                    'SearchResult.BrowseIllustrationsNotFound' | translate }}</span> -->
                                                    <span class="spriteico illustrationsIcon img-disable"
                                                      [class.gridViewIcons]="showGridView" [class.illustrationfour]="document?.latlon"
                                                      title="{{ 'SearchResult.BrowseIllustrationsNotFound' | translate }}"
                                                    aria-hidden="true"></span>
                                                  </a>
                                                </li>
                                              }
                                              <!--
                                              <li class="menu-list dropdown documentLevelShare socialShare{{j}}"
                                                (click)="socialShare(document,j, group?.group);$event.stopPropagation();">
                                                <a [class.arabic-style]="isArabicLanguage" title="{{ 'Detail.Share' | translate }}"
                                                  href="javascript:void(0)">
                                                  <span class="spriteico shareIcon dropdown-toggle"></span>
                                                </a>
                                                <div *ngIf="document.enableShare">
                                                  <app-share-document class="shareDocumentClss" [bookMarkShare]="document.enableShare"
                                                    [currentDocument]="document" [bookmarkDocumentURL]="bookmarkURL"
                                                  [bookMarkExternalShareUrl]="shareUrl"></app-share-document>
                                                </div>
                                              </li>
                                              <li tabindex="0" (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                                                (click)="pageSettingService.addToViewList(document, group?.group, j)"
                                                [class.gridlist]="gridViewClick"
                                                *ngIf="viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) == -1 : true">
                                                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                                                  class="serch-icns list-icon">
                                                  <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                  'SearchResult.AddToList' | translate }} </span>
                                                  <span class="spriteico addtolistIcon" [class.gridViewIcons]="showGridView"
                                                    [class.addtolistfour]="document?.latlon"
                                                  title="{{ 'SearchResult.AddToList' | translate }}" aria-hidden="true"></span>
                                                </a>
                                              </li>
                                              <li tabindex="0" (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                                                (click)="pageSettingService.addToViewList(document, group?.group, j)"
                                                [class.gridviewList]="gridViewClick"
                                                *ngIf="viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) >= 0 : false">
                                                <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)"
                                                  class="serch-icns list-icon">
                                                  <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                  'SearchResult.RemoveFromList' | translate }}</span>
                                                  <span class="spriteico removefromlistIcon" [class.gridViewIcons]="showGridView"
                                                    [class.addtolistfour]="document?.latlon"
                                                  title="{{ 'SearchResult.RemoveFromList' | translate }}" aria-hidden="true"></span>
                                                </a>
                                              </li>
                                              <li tabindex="0" *ngIf="group?.group === 'Photographs'" [class.gridlist]="gridViewClick">
                                                <a *ngIf="document?.collectionNo" [class.arabic-style]="isArabicLanguage"
                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                  class="open-new-tab-link collection-icon-new"
                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                                </a>
                                              </li>
                                              <li tabindex="0" *ngIf="group?.group === 'Photographs'" [class.gridlist]="gridViewClick">
                                                <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                                  title="{{ 'SearchResult.openInNewTab' | translate }}"
                                    class="open-new-tab-link open-tab-icon-new" [routerLink]="['../detail/' + document.id,
                                detailsRedirectParams]"></a>
                                              </li>
                                              <li tabindex="0" [class.arabic-style]="isArabicLanguage" *ngIf="(document?.latlon)"
                                                [class.position]="document?.latlon"
                                                (keyup.enter)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                                                (click)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                                                tooltipPlacement="bottom" [class.gridlist]="gridViewClick" class="serch-icns"
                                                title="{{ 'SearchResult.DropPinOnMap' | translate}}">
                                                <span role="button" aria-pressed="false" class="mapIcon fa fa-map-marker browsemap_icon"
                                                  [class.gridViewIcons]="showGridView"
                                                [ngStyle]="{'color': document['highlightSelected'] ? '#068853': '#61165e'}"></span>
                                              </li> -->
                                            </ul>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                    @if (_isInMapView && showGridView) {
                                      <div class="browse-grid-style" [class.browsemaps-gridview]="showGridView"
                                        [class.full-wdth-style]="!leftMenuStatus">
                                        <div class="listview-icon search-div-icon">
                                          <ul class="search-iconsdiv photographwidth {{group?.group}}ListViewIcon"
                                            [class.positions]="document?.latlon" [ngClass]="getgridviewList(group?.group)"
                                            [class.gridviewBorder]="gridViewClick"
                                            [ngStyle]="{'border-left': group?.group == 'Manuscripts' ? 'none' : '1px solid #d2d2da'}">
                                            @if (document.isIllustrationExist == true  && group?.group !== 'Photographs' && group?.group !== 'Maps') {
                                              <li tabindex="0" (keyup.enter)="getDocIllustrations(document.id, j)"
                                                (click)="getDocIllustrations(document.id, j)"
                                                >
                                                <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                  href="javascript:void(0)" class="serch-icns img-icon"
                                                  (click)="imageClick(document.title)">
                                                  <span [class.arabic-style]="isArabicLanguage" class="browse-txt">{{
                                                  'SearchResult.BrowseIllustrations' | translate }}</span>
                                                  <span class="spriteico illustrationsIcon" [class.gridViewIcons]="showGridView"
                                                    title="{{ 'SearchResult.BrowseIllustrations' | translate }}" aria-hidden="true">
                                                    @if (document.illustrationCount) {
                                                      <span class="illustrationCount"
                                                        [ngStyle]="{'left': group?.group == 'Monographs' ? '50px': '16px'}">
                                                        <div [class.arabic-style]="isArabicLanguage" class="illustration-badge">
                                                        {{document.illustrationCount}}</div>
                                                      </span>
                                                    }
                                                  </span>
                                                </a>
                                              </li>
                                            }
                                            @if (document.isIllustrationExist == false && group?.group !== 'Photographs' && group?.group !== 'Maps') {
                                              <li tabindex="0" [class.gridviewIllustration]="gridViewClick"
                                                >
                                                <a (contextmenu)="disableRightClick($event)" href="javascript:void(0)"
                                                  class="serch-icns img-icon">
                                                  <span class="spriteico illustrationsIcon img-disable"
                                                    [class.gridViewIcons]="showGridView" [class.illustrationfour]="document?.latlon"
                                                    title="{{ 'SearchResult.BrowseIllustrationsNotFound' | translate }}"
                                                  aria-hidden="true"></span>
                                                </a>
                                              </li>
                                            }
                                          </ul>
                                        </div>
                                      </div>
                                    }
                                    <ng-template #browsermapspopOverTemplate>
                                      <div class="browser-map-popover">
                                        <ul class="browse-map-ul">
                                          <li class="browse-map-li menu-list dropdown documentLevelShare socialShare{{j}}"
                                            (click)="socialShare(document,j, group?.group);$event.stopPropagation();">
                                            <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                              title="{{ 'Detail.Share' | translate }}" href="javascript:void(0)">
                                              <span class="browsericn spriteico shareIcon dropdown-toggle"></span>
                                              <span class="share-txt">Share</span>
                                            </a>
                                            @if (document.enableShare) {
                                              <div>
                                                <app-share-document class="shareDocumentClss" [bookMarkShare]="document.enableShare"
                                                  [currentDocument]="document" [bookmarkDocumentURL]="bookmarkURL"
                                                [bookMarkExternalShareUrl]="shareUrl"></app-share-document>
                                              </div>
                                            }
                                          </li>
                                          @if (viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) == -1 : true) {
                                            <li tabindex="0" class="browse-map-li"
                                              (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                                              (click)="pageSettingService.addToViewList(document, group?.group, j)"
                                              [class.gridlist]="gridViewClick"
                                              >
                                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                href="javascript:void(0)" class="serch-icns list-icon">
                                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                'SearchResult.AddToList' | translate }} </span>
                                                <span class="browsericn spriteico addtolistIcon" [class.gridViewIcons]="showGridView"
                                                  [class.addtolistfour]="document?.latlon"
                                                title="{{ 'SearchResult.AddToList' | translate }}" aria-hidden="true"></span>
                                                <span class="share-txt">Add To List</span>
                                              </a>
                                            </li>
                                          }
                                          @if (viewList[group?.group] ? getJSONKeys(viewList[group?.group])?.indexOf(document.id) >= 0 : false) {
                                            <li tabindex="0" class="browse-map-li"
                                              (keyup.enter)="pageSettingService.addToViewList(document, group?.group)"
                                              (click)="pageSettingService.addToViewList(document, group?.group, j)"
                                              [class.gridviewList]="gridViewClick"
                                              >
                                              <a (contextmenu)="disableRightClick($event)" [class.arabic-style]="isArabicLanguage"
                                                href="javascript:void(0)" class="serch-icns list-icon">
                                                <span [class.arabic-style]="isArabicLanguage" class="sr-only sr-color">{{
                                                'SearchResult.RemoveFromList' | translate }}</span>
                                                <span class="browsericn spriteico removefromlistIcon"
                                                  [class.gridViewIcons]="showGridView" [class.addtolistfour]="document?.latlon"
                                                title="{{ 'SearchResult.RemoveFromList' | translate }}" aria-hidden="true"></span>
                                                <span class="share-txt">Remove From List</span>
                                              </a>
                                            </li>
                                          }
                                          @if (group?.group === 'Photographs') {
                                            <li tabindex="0" class="browse-map-li"
                                              [class.gridlist]="gridViewClick">
                                              @if (document?.collectionNo) {
                                                <a [class.arabic-style]="isArabicLanguage"
                                                  title="{{ 'SearchResult.browseThisCollection' | translate }}: {{document?.collection}}"
                                                  class="open-new-tab-link collection-icon-new"
                                                  (click)="gotoCollectionDetailsPage(document.collectionNo, document.archive, document.id)">
                                                </a>
                                              }
                                              <span class="photo-collection">Collection</span>
                                            </li>
                                          }
                                          @if (group?.group === 'Photographs') {
                                            <li tabindex="0" class="browse-map-li"
                                              [class.gridlist]="gridViewClick">
                                              <a [class.arabic-style]="isArabicLanguage" target="_blank"
                                                title="{{ 'SearchResult.openInNewTab' | translate }}"
                                  class="open-new-tab-link open-tab-icon-new" [routerLink]="['../detail/' + document.id,
                              detailsRedirectParams]">
                                              </a>
                                              <span class="photo-collection">Open New Tab</span>
                                            </li>
                                          }
                                          @if ((document?.latlon)) {
                                            <li tabindex="0" (contextmenu)="disableRightClick($event)"
                                              [class.arabic-style]="isArabicLanguage"
                                              [class.position]="document?.latlon"
                                              (keyup.enter)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                                              (click)="zoomIntoMap(document.latlon);droppinHighlight(document.id, i)"
                                              tooltipPlacement="bottom" [class.gridlist]="gridViewClick"
                                              class="browse-map-li serch-icns" title="{{ 'SearchResult.DropPinOnMap' | translate}}">
                                              <span role="button" aria-pressed="false"
                                                class="browsericn mapIcon fa fa-map-marker browsemap_icon"
                                                [class.gridViewIcons]="showGridView"
                                              [ngStyle]="{'color': document['highlightSelected'] ? '#068853': '#61165e'}"></span>
                                              <span class="share-txt">Drop Pin on Map</span>
                                            </li>
                                          }
                                        </ul>
                                      </div>
                                    </ng-template>
                                  </div>
                                }
                              </div>
                              @if ((group?.documents.length >= 6 && group?.documents.length != group?.totalGroupElements) ||( scrollDisplay && (group?.documents.length >= 3 && group?.documents.length != group?.totalGroupElements))) {
                                <div
                                  class="row text-center clearFix">
                                  @if (loadMoreLoader) {
                                    <span class="fast-right-spinner">
                                      <i class="fa fa-repeat" aria-hidden="true"></i>
                                    </span>
                                  }
                                  @if (!loadMoreLoader && ((group?.documents.length <= 5 || showGridView && group?.documents.length <= 25) && !_isInMapView)) {
                                    <div (click)="loadMoreResults('viewMoreFromTemplate')" class="viewMoreContent"
                                      >
                                      <div class="loadMoreResultsArrow"><i class="fa fa-angle-down"></i></div>
                                      <div [class.arabic-style]="isArabicLanguage">{{ 'SearchResult.ViewMore' | translate }}</div>
                                    </div>
                                  }
                                </div>
                              }
                              <div [class.arabic-style]="isArabicLanguage" [class.text-left]="gridViewClick" class="text-center"
                                [ngStyle]="{'display': (_isInMapView || scrollDisplay) ? 'none': 'inline-block'}">
                                @if ((group?.totalGroupPages > 1) && (group?.documents.length < group?.totalGroupElements)) {
                                  <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary mt-1 ted"
                                    (click)="viewMoreResultsGroup(group?.group, group?.totalGroupElements, group?.documents.length, contentType)"
                                  title="{{ 'SearchResult.ViewMore' | translate }}">{{ 'SearchResult.ViewMore' | translate }}</button>
                                }
                              </div>
                              @if (!resultDataLoading) {
                                <div [class.arabic-style]="isArabicLanguage" [class.text-left]="gridViewClick" class="text-center"
                                  [ngStyle]="{'display': (!_isInMapView) ? 'none': 'inline-block'}">
                                  @if ((group?.totalGroupPages > 1) && (group?.documents.length <=5) && (group?.documents.length < group?.totalGroupElements)) {
                                    <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary mt-5"
                                      (click)="viewMoreResultsGroup(group?.group, group?.totalGroupElements, group?.documents.length, contentType)"
                                    title="{{ 'SearchResult.ViewMore' | translate }}">{{ 'SearchResult.ViewMore' | translate }}</button>
                                  }
                                </div>
                              }
                              <button [class.arabic-style]="isArabicLanguage"
                                [ngStyle]="{'display': (!scrollDisplay || !hightScrollTop) ? 'none': 'block'}"
                                title="{{ 'SearchResult.GoToTop' | translate }}" class="btn btn-primary scrollTopPage"
                                (click)="scrollTopPage()">
                                <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'Common.scrollTopPage' | translate
                                }}</span>
                                <span class="spriteico topPageIcon"></span>
                              </button>
                            </accordion-group>
                          }
                        }
                      </accordion>
                    }
                  </div>
                }
              <!-- </div> -->
            </div>
          </div>
        <!-- </div> -->
        <ng-template tabindex="0" #bookmarkHtmlTemplate>
          <div class="modal-header">
            <h4 class="bookmark-header">
              {{ 'Detail.bookmarkURL' | translate }}
            </h4>
            @if (!showClipBoardSuccessAlert) {
              <h4 class="modal-title sa-icon sa-info pulseWarning">
              </h4>
            }
            @if (showClipBoardSuccessAlert) {
              <h4 class="modal-title sa-icon sa-success pulseWarning">
                <span class="sa-line sa-tip animateSuccessTip"></span>
                <span class="sa-line sa-long animateSuccessLong"></span>
              </h4>
            }
          </div>
          <div class="modal-body url_body">
            @if (showClipBoardSuccessAlert) {
              <div [class.arabic-style]="isArabicLanguage" class="alert alert-success">{{
              'SearchResult.URLCopiedToClipboard' | translate }}</div>
            }
            <div class="well">
              <span id="bookmarkURLonTemplate">{{bookmarkURL}}</span>
            </div>
            @if (bookmarkURL) {
              <div [class.arabic-style]="isArabicLanguage" class="share-doc-message">
                <span [class.arabic-style]="isArabicLanguage" class="share-doc-message-span">{{
                'Detail.shareQueryBookmarkMessage' | translate }}</span>
              </div>
            }
          </div>
          <div [class.arabic-style]="isArabicLanguage" class="modal-footer">
            <button tabindex="0" [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-secondary"
              (click)="closeBookMarkModal()">
              {{ 'Common.Close' | translate }}
            </button>
            <button tabindex="0" [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary"
              aria-label="Close" (click)="copytoClipBoard(bookmarkURL)">
              {{ 'SearchResult.CopyToClipboard' | translate }}
            </button>
          </div>
        </ng-template>
        <ng-template #bookmarkPageHtmlTemplate>
          <div class="modal-header">
            @if (!showClipBoardSuccessAlert) {
              <h4 class="modal-title sa-icon sa-success pulseWarning">
                <span class="sa-line sa-tip animateSuccessTip"></span>
                <span class="sa-line sa-long animateSuccessLong"></span>
              </h4>
            }
          </div>
          <div class="modal-body url_body">
            <div class="well">
              <span>{{bookmarkPageURL}}</span>
            </div>
          </div>
          <div [class.arabic-style]="isArabicLanguage" class="modal-footer">
            <button [class.arabic-style]="isArabicLanguage" type="button" class="btn-default btn"
              (click)="closeBookMarkModal()">
              {{ 'Common.Close' | translate }}
            </button>
            <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" aria-label="Close"
              (click)="copytoClipBoard(bookmarkPageURL)">
              {{ 'SearchResult.CopyToClipboard' | translate }}
            </button>
          </div>
        </ng-template>
        <ng-template #illustrationDataTemplate>
          <div [class.arabic-style]="isArabicLanguage" class="modal-header">
            <h4 tabindex="0" [class.arabic-style]="isArabicLanguage"
              class="modal-title illustration-title pull-left removeoutline">{{ 'SearchResult.Illustrations.Illustrations' |
            translate }}</h4>
            <p [class.arabic-style]="isArabicLanguage" class="float-right illustration-helptext"> * {{
            'SearchResult.Illustrations.MouseoverTheIllustration' | translate }} </p>
            <p [class.arabic-style]="isArabicLanguage" class="float-right mobIllustration-helptext"> * {{
            'SearchResult.Illustrations.LongPressTheIllustration' | translate }} </p>
          </div>
          <div class="modal-body illustration-modal">
            <!-- <perfect-scrollbar [config]="config" class="searchresultIllusrations-scroll" #container> -->
            <tree-root id="searchresultIllusrations" [focused]="true" [nodes]="illustrationNodes"
              (initialized)="afterIllustrationTreeInit($event)" (activate)="onTableSelect($event)"
              (contextMenu)="onTableBlur($event)">

              <ng-template #treeNodeFullTemplate let-node let-templates="templates">
                <div class="tree-node">
                  <tree-node-expander [node]="node"></tree-node-expander>
                  <div tabindex="0"
                    class="node-content-wrapper illustration-popover-content illustrationTitle removeoutline"
                    (keyup.enter)="node.toggleActivated(true)" (click)="node.toggleActivated(true)">
                    <a (mouseenter)="mouseEnter(node) " (mouseleave)="mouseLeave(node)"
                      containerClass="illustration-customclass" [class]="node.data.className" [attr.title]="node.data.name"
                    [class.title]="true">{{ node.data.name }}</a>
                  </div>
                  <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
                </div>
              </ng-template>
            </tree-root>
          <!-- </perfect-scrollbar> -->
          <!-- <div class="illustration-image-body" id="illustrationImageDiv" style="display:none">
          <img class="accordian-img mx-auto d-block img-fluid" [attr.src]="illustrationDocumentImage"
            alt="{{'Alt.imageThumbnails' | translate}}" />
            <div class="illustration-image-loader" [ngStyle]="{display:illustrationsLoader ? 'block':'none'}">
              <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
            </div>
          </div> -->



        </div>
        <div [class.arabic-style]="isArabicLanguage" class="modal-footer">
          <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary"
            (keyup.enter)="closeIllustrationModal()" (click)="closeIllustrationModal()">
            {{ 'Common.Close' | translate }}
          </button>
        </div>
      </ng-template>
      @if (_isInMapView) {
        <div class="col-md-12 padding-0">
          <app-browser-map (spatialSearchEmitsFromBrowserMap)="onSpatialSearchEmitFromBrowserMap($event)"></app-browser-map>
        </div>
      }
      <ng-template #shareHtmlTemplate>
        <div class="modal-popup-parentDiv">
          <div class="modal-header emailpopup-header">
            <h4 [class.arabic-style]="isArabicLanguage" class="emailpopup-heading">{{ 'Detail.sendEmail' | translate }}
            </h4>
          </div>
          <div class="modal-body shareTemplate">
            <form class="form-horizontal sendMail" #sendMailForm="ngForm">
              <div class="form-group">
                <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="email">{{
                  'Detail.from' | translate }}
                  <!-- <span class="glyphicon glyphicon-asterisk"></span> -->
                  <i class="fa fa-asterisk" aria-hidden="true"></i>
                </label>
                <div class="col-sm-10">
                  <input type="email" #contactemailfrom="ngModel" class="form-control" id="emailFrom"
                    placeholder="{{'Detail.fromPlaceholder' | translate}}" name="contactemailfrom"
                    [(ngModel)]="shareMailData.from" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    required>
                    <div class="" [hidden]="contactemailfrom.valid || contactemailfrom.untouched">
                      @if (contactemailfrom.errors && contactemailfrom.errors.required) {
                        <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                          >
                          <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.emailIsRequired' | translate }}</h4>
                        </div>
                      }
                      @if (contactemailfrom.errors && contactemailfrom.errors.pattern) {
                        <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                          >
                          <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.invalidEmail' | translate }}</h4>
                        </div>
                      }
                    </div>
                  </div>

                </div>
                <div class="form-group">
                  <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="email">{{
                    'Detail.recipient' | translate }}
                    <!-- <span class="glyphicon glyphicon-asterisk"></span> -->
                    <i class="fa fa-asterisk" aria-hidden="true"></i>
                  </label>
                  <div class="col-sm-10">
                    <input type="email" #contactemailto="ngModel" class="form-control" id="emailTo"
                      placeholder="{{'Detail.recipientPlaceholder' | translate}}" name="contactemailto"
                      [(ngModel)]="shareMailData.to" [email]="true" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      required>
                      <div class="" [hidden]="contactemailto.valid || contactemailto.untouched">
                        @if (contactemailto.errors && contactemailto.errors.required) {
                          <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                            >
                            <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.emailIsRequired' | translate }}</h4>
                          </div>
                        }
                        @if (contactemailto.errors && contactemailto.errors.pattern) {
                          <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                            >
                            <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.invalidEmail' | translate }}</h4>
                          </div>
                        }
                      </div>
                    </div>

                  </div>
                  <div class="form-group">
                    <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="subject">{{
                      'Detail.subject' | translate }}
                      <!-- <span class="glyphicon glyphicon-asterisk"></span> -->
                      <i class="fa fa-asterisk" aria-hidden="true"></i>
                    </label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" id="subject"
                        placeholder="{{'Detail.subjectPlaceholder' | translate}}" name="subject"
                        [(ngModel)]="shareMailData.topic" #subject="ngModel" required>
                        <div class="error text-danger" [hidden]=" subject.untouched || shareMailData.topic.trim() !== ''">
                          @if ((subject.errors && subject.errors.required ) || shareMailData.topic.trim() === '') {
                            <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                              >
                              <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.requiredSubject' | translate }}</h4>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label [class.arabic-style]="isArabicLanguage" class="control-label col-sm-2 emailGroup" for="message">{{
                        'Detail.Message' | translate }}
                        <!-- <span class="glyphicon glyphicon-asterisk"></span> -->
                        <i class="fa fa-asterisk" aria-hidden="true"></i>
                      </label>
                      <div class="col-sm-10">
                        <textarea type="text" wrap="hard" class="form-control message-textarea" id="message" rows="10" cols="50"
                          placeholder="{{'Detail.messagePlaceholder' | translate}}" name="message"
                        [(ngModel)]="shareMailData.message" #message="ngModel" required></textarea>
                        <div class="error text-danger" [hidden]="message.untouched || shareMailData.message.trim() !== ''">
                          @if ((message.errors && message.errors.required) || shareMailData.message.trim() === '') {
                            <div [class.arabic-style]="isArabicLanguage" class="alert-danger text-left"
                              >
                              <h4 [class.arabic-style]="isArabicLanguage">{{ 'Detail.requiredMessage' | translate }}</h4>
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                    <div class="emailSend submitBtn">
                      <div class="col-sm-12">
                        <button [class.arabic-style]="isArabicLanguage" type="button"
                          [disabled]="!sendMailForm.form.valid || shareMailData.message.trim() === '' || shareMailData.topic.trim() === ''"
                          class="btn-primary btn" aria-label="Close" (click)="shareEmail()">{{ 'Detail.Send' | translate
                        }}</button>

                      </div>
                    </div>
                    <!-- <tooltip-content #sendMailFormTooltip
                    [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
                    <div class="error text-danger " [hidden]="contactemailfrom.valid">
                      <div [class.arabic-style]="isArabicLanguage"
                        *ngIf="contactemailfrom.untouched || contactemailfrom.invalid">
                        {{ 'Detail.fromIsRequired' | translate }}
                      </div>

                    </div>
                    <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
                      <div [class.arabic-style]="isArabicLanguage" *ngIf="contactemailto.untouched || contactemailto.invalid">
                        {{ 'Detail.recipientIsRequired' | translate }}
                      </div>
                    </div>
                    <div class="error text-danger"
                      [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
                      <div [class.arabic-style]="isArabicLanguage" *ngIf="subject.untouched || subject.invalid">
                        {{ 'Detail.subjectIsRequired' | translate }}
                      </div>
                    </div>
                    <div class="error text-danger"
                      [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
                      <div [class.arabic-style]="isArabicLanguage"
                        *ngIf="message.untouched || message.invalid || shareMailData.message.trim() === ''">
                        {{ 'Detail.messageIsRequired' | translate }}
                      </div>
                    </div>
                  </tooltip-content> -->
                  <!-- <ng-template #sendMailFormTooltip  [ngClass]="{'hide': sendMailForm.form.valid , 'show': !sendMailForm.form.valid || shareMailData.message.trim() === '' }">
                    <div class="error text-danger " [hidden]="contactemailfrom.valid">
                      @if (contactemailfrom.untouched || contactemailfrom.invalid) {
                        <div [class.arabic-style]="isArabicLanguage"
                          >
                          {{ 'Detail.fromIsRequired' | translate }}
                        </div>
                      }

                    </div>
                    <div class="error text-danger" [hidden]="contactemailfrom.invalid || contactemailto.valid ">
                      @if (contactemailto.untouched || contactemailto.invalid) {
                        <div [class.arabic-style]="isArabicLanguage">
                          {{ 'Detail.recipientIsRequired' | translate }}
                        </div>
                      }
                    </div>
                    <div class="error text-danger"
                      [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.valid">
                      @if (subject.untouched || subject.invalid) {
                        <div [class.arabic-style]="isArabicLanguage">
                          {{ 'Detail.subjectIsRequired' | translate }}
                        </div>
                      }
                    </div>
                    <div class="error text-danger"
                      [hidden]="contactemailfrom.invalid || contactemailto.invalid || subject.invalid">
                      @if (message.untouched || message.invalid || shareMailData.message.trim() === '') {
                        <div [class.arabic-style]="isArabicLanguage"
                          >
                          {{ 'Detail.messageIsRequired' | translate }}
                        </div>
                      }
                    </div>
                  </ng-template> -->
                  <div class="emailClose closeBtn">
                    <button [class.arabic-style]="isArabicLanguage"  class="btn-default btn"
                      (click)="closeShareModal()">Close
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </ng-template>
          <!-- <ng-template #envDocsTemplate>
          <div class="modal-header  download-title">
            {{ 'SearchResult.moreENVsContentLink' | translate }}
          </div>
          <div class="modal-body warning_bdy">
            <div class="more-body-text">
              {{ 'SearchResult.envmoreContentText' | translate }}
            </div>
          </div>
          <div class="modal-footer">
            <button [class.arabic-style]="isArabicLanguage" type="button" class="btn btn-primary" (click)="closeEnvModal();$event.stopPropagation();">
              {{ 'Detail.Close' | translate }}
            </button>
          </div>
        </ng-template> -->
      </div>
    </div>
