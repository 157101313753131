import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from './common/services/common.service';
import { environment } from '../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {
        if (environment.envName === 'dev') {
            return true;
        }

        if (environment.envName === 'qa') {
        }

        const commonService = this.injector.get(CommonService);
        if (error instanceof HttpErrorResponse) {
           const message =  navigator.onLine ? error.message : 'No Internet Connection';
            commonService.logErrors('SERVICE', error.status, error.url, JSON.stringify(message));
        } else {
            let UIError: any;
            try {
                UIError = error.toString();
            } catch (err) {
                UIError = error;
            }
            commonService.logErrors('UI', 'CNERR', 'WDA-APP', UIError);
        }
    }
}
