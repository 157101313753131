<!-- Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12 collocates-blurb-div">
    <p class="add-terms-text" [class.arabic-style]="isArabicLanguage" [ngClass]="{'fontResponsive': lang === 'ta'}"> {{ 'textAnalysisPage.analysisAccessedArchives' | translate }} </p>
    <h6 [class.arabic-style]="isArabicLanguage" class="chart-query-container-title">
      <span class="add-terms-text">
        <p class="small lh-17" [ngClass]="{'fontResponsive': lang === 'ta'}" [class.arabic-style]="isArabicLanguage">{{ 'textAnalysisPage.concordanceBlurb' | translate }}</p>
      </span>
    </h6>
  </div>

  @for (term of _filterInputs.terms; track term; let i = $index) {
    @if (_filterInputs.terms?.length > 0 && !disableAllButton) {
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 concordance-table-wrapper">
        <div class="table-responsive" id="{{term}}">
          <label for="test" class="text-capitalize fw-bold"> {{ 'textAnalysisPage.top' | translate }} {{limit}} {{ 'textAnalysisPage.matches' | translate }}- {{term}} </label>
          <a href="javascript:void(0)" title="{{ 'Common.download' | translate }}" [ngClass]="{'disabled': resultSet[term]?.data?.length === 0}"  id="download" (click)="concordanceService.downloadConcordanceData(term, _advancedFilterParam)" class="btn btn-primary pull-right download-btn"><i class="fa fa-download"></i></a>
          <a href="javascript:void(0)" title="{{ 'textAnalysisPage.print' | translate }}" [ngClass]="{'disabled': resultSet[term]?.data?.length === 0}"  id="print" (click)="concordanceService.printConcordance('concordanceTable_' + i, term, _advancedFilterParam)" class="btn btn-primary pull-right print-btn"><i class="fa fa-print"></i></a>
          <div class="concordanceTable" id="concordanceTable_{{i}}">
            <table class="table table-hover table-bordered concordanceTable_{{i}}" >
              <thead class="tableBg-color">
                <tr>
                  <td title="{{ 'textAnalysisPage.concordanceLeft' | translate }}" [class.arabic-style]="isArabicLanguage" class="wiley-background text-white text-end">{{ 'textAnalysisPage.concordanceLeft' | translate }}</td>
                  <td title="{{ 'textAnalysisPage.word' | translate }}" [class.arabic-style]="isArabicLanguage" class="wiley-background text-white text-center" [style.width]="getWidth(term)">{{ 'textAnalysisPage.word' | translate }}</td>
                  <td title="{{ 'textAnalysisPage.concordanceRight' | translate }}" [class.arabic-style]="isArabicLanguage" class="wiley-background text-white">{{ 'textAnalysisPage.concordanceRight' | translate }}</td>
                  <td title="{{ 'textAnalysisPage.document' | translate }}" [class.arabic-style]="isArabicLanguage" class="wiley-background text-white goto-doc"><span class="r-padding">{{"textAnalysisPage.document" | translate}}</span><span>{{ "textAnalysisPage.title" | translate }}</span></td>
                </tr>
              </thead>
              <tbody>
                @if (resultSet[term]?.loading) {
                  <div class="concordance_frame-loader" [ngStyle]="{display:true ? 'block':'block'}">
                    <img alt="{{'Alt.loaderImage' | translate}}" src="assets/images/dot-loader-dark.gif">
                  </div>
                }
                @if (!resultSet[term]?.loading && resultSet[term]?.data?.length === 0 || !resultSet[term]?.data) {
                  <p class="text-center m-t-130">{{'Common.Noresultsfound' |translate}}</p>
                }
                @for (data of resultSet[term]?.data; track data; let i = $index) {
                  <tr>
                    <td class="text-end">{{data?.prefix}}</td>
                    <td class="text-center" [style.width]="getWidth(term)">{{data?.term}}</td>
                    <td class="text-left">{{data?.suffix}}</td>
                    <td class="goto-doc">
                      <a (click)="gotoDocumentDetailsPage(data.term, data.wileyId)" class="primary-text-color" title="{{data?.title}}">
                        <span>{{data?.title}}</span>
                      </a>
                    </td>
                  </tr>
                }
              </tbody>
            </table></div>
          </div>
          <div class="slider-wrapper">
            <div class="col-md-6 col-sm-6 col-xs-6 pull-right">
              <div slider [min]="minValue" [max]="maxValue" [step]="step" [value]="resultSet[term]?.sliderValue || sliderValue" (sliderValue)="receiveSliderValue($event, term)"></div>
              <p>{{resultSet[term]?.sliderValue || 5}}
                @if (resultSet[term]?.sliderValue > 1) {
                  <span>{{'textAnalysisPage.terms' | translate}}</span>
                }
                @else {
                  <span>{{'textAnalysisPage.term' | translate}}</span>
                }
              </p>
            </div>
          </div>
        </div>
      }
    }
    @if (disableAllButton) {
      <div class="no-results-text">
        <h4 class="primary-text-color text-center">{{ 'textAnalysisPage.noResultsFound' | translate }}</h4>
      </div>
    }
  </div>
  <div id="img-out" style="display: none;"></div>
