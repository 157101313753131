<!-- <header *ngIf="headerContent">
  <section
    id="main-header"
    [ngStyle]="{ display: toggleMainHeader ? 'block' : 'none' }"
  >
    <div class="main-header x_pane">
      <nav class="navbar x_content">
        <div class="back-menu">
          <ng-container *ngIf="!isGuestUser">
            <a [attr.href]="headerContent?.returnUrl" class="back-lib-menu">
              <div
                class="navbar-header"
                [class.arabic-style]="isArabicLanguage"
                title="{{ 'HomePage.returnToLib' | translate }}"
              >
                <img
                  *ngIf="headerContent.currentArchive !== 'wiley'"
                  [class.arabic-style]="isArabicLanguage"
                  alt="{{ 'Alt.libraryLogo' | translate }}"
                  [attr.src]="headerContent?.customerLogo"
                />
              </div>
            </a>
            <a class="training-hub-link" (click)="gotoWDATrainingHub()">
              WDA Training Hub
            </a>
          </ng-container>
          <ng-container *ngIf="isGuestUser">
            <a class="back-lib-menu">
              <div
                class="navbar-header"
                [class.arabic-style]="isArabicLanguage"
                title="{{ 'HomePage.returnToLib' | translate }}"
              >
                <img
                  *ngIf="headerContent.currentArchive !== 'wiley'"
                  [class.arabic-style]="isArabicLanguage"
                  alt="{{ 'Alt.libraryLogo' | translate }}"
                  [attr.src]="headerContent?.customerLogo"
                />
              </div>
            </a>
            <a class="training-hub-link" (click)="gotoWDATrainingHub()">
              WDA Training Hub
            </a>
          </ng-container>
        </div>
        <ul
          class="nav navbar-nav navbar-right"
          *ngIf="headerContent?.wileyArchives?.length > 0"
        >
          <li>
            <div class="dropdown text-end">
              <ng-container *ngIf="!isGuestUser">
                <button
                  class="btn btn-wiley dropdown-toggle"
                  [class.arabic-style]="isArabicLanguage"
                  tabindex="0"
                  (click)="
                    togglewileyheaderarchivemenu(); $event.stopPropagation()
                  "
                  id="wiley-archive-btn"
                  (keyup.enter)="
                    togglewileyheaderarchivemenu(); $event.stopPropagation()
                  "
                >
                  {{ "HomePage.apptitle" | translate }}
                  <span
                    class="fa dropdown-fa pull-right"
                    [ngClass]="{
                      'fa-chevron-down': !togglewileyarchivemenu,
                      'fa-chevron-up': togglewileyarchivemenu
                    }"
                  ></span>
                </button>
              </ng-container>
              <ng-container *ngIf="isGuestUser">
                <button
                  title="{{ 'HomePage.apptitle' | translate }}"
                  class="btn btn-wiley dropdown-toggle disabled"
                  [class.arabic-style]="isArabicLanguage"
                  id="wiley-archive-btn"
                >
                  {{ "HomePage.apptitle" | translate }}
                  <span
                    class="fa dropdown-fa pull-right fa-chevron-down"
                  ></span>
                </button>
              </ng-container>
              <ul
                class="dropdown-menu dropdown-menu-right"
                tabindex="0"
                role="menu"
                aria-labelledby="wiley-archive-btn"
                [ngStyle]="{
                  display: togglewileyarchivemenu ? 'block' : 'none'
                }"
                (click)="$event.stopPropagation()"
              >
                <li
                  role="presentation"
                  tabindex="0"
                  *ngFor="
                    let archive of headerContent?.wileyArchives;
                    let i = index
                  "
                >
                  <div class="row">
                    <div
                      class="col-md-12"
                      (click)="onArchiveSelect(i, archive)"
                      (keyup.enter)="onArchiveSelect(i, archive)"
                    >
                      <div
                        class="col-md-5 col-sm-4 col-xs-4"
                        [class.arabic-style]="isArabicLanguage"
                        [title]="
                          parsedText(
                            archive.shortName.toLowerCase() + '.name'
                              | translate
                          )
                        "
                      >
                        <img
                          class="center-block img-responsive"
                          [class.arabic-style]="isArabicLanguage"
                          alt="{{ 'HomePage.apptitle' | translate }}"
                          [src]="archive.logo"
                        />
                      </div>
                      <div class="col-md-7 col-sm-8 col-xs-8 padding-left-0">
                        <p
                          class="archive-content"
                          [class.arabic-style]="isArabicLanguage"
                          [innerHTML]="
                            archive.shortName.toLowerCase() + '.name'
                              | translate
                          "
                        ></p>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  [class.inactive]="isGuestUserFromShortURL"
                  role="presentation"
                  (click)="gotoAboutPage()"
                  class="moreAbout"
                >
                  <a
                    class="wiley-primary-text-color more-archives"
                    [class.arabic-style]="isArabicLanguage"
                    title="{{ 'HomePage.moreAboutArchives' | translate }}"
                    >{{ "HomePage.moreAboutArchives" | translate }}</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </section>

  <div class="header-toggle" title="{{ headerTitleText }}">
    <a
      [class.inactive]="isGuestUserFromShortURL"
      tabindex="0"
      (click)="toggleMainHeaderFunction()"
      (keyup.enter)="toggleMainHeaderFunction()"
    >
      <i
        [ngClass]="{
          arrowUpIcon: toggleMainHeader,
          arrowDownIcon: !toggleMainHeader
        }"
        class="spriteico arrowUpIcon"
        aria-hidden="true"
      ></i>
    </a>
  </div>
  <section id="wiley-header">
    <div class="wiley-header">
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggler collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#navbar1"
          aria-expanded="false"
          aria-controls="navbar1"
          tabindex="0"
        >
          <i class="fa fa-bars fa-lg"></i>
          <i class="fa fa-times-circle-o fa-lg"></i>
          <span class="sr-only">{{ "Common.bars" | translate }}</span>
        </button>
        <ng-container *ngIf="!isGuestUser">
          <a
            class="navbar-brand tooltip"
            title="{{ showToolTipContent }} (Shift + Alt + Home)"
            [routerLink]="homePageUrl"
          >
            <img
              *ngIf="
                headerContent.currentArchive !== 'WILEY' &&
                headerContent.currentArchive !== 'RGS'
              "
              alt="{{ headerContent._pageTitle }}"
              [attr.src]="headerContent?.archiveLogo"
            />
            <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
          </a>
          <a
            [class.inactive]="isGuestUserFromShortURL"
            class="navbar-brand tooltip"
            title="{{ showToolTipContent }} (Shift + Alt + Home)"
            [routerLink]="homePageUrl"
          >
            <img
              *ngIf="
                headerContent.currentArchive !== 'WILEY' &&
                headerContent.currentArchive == 'RGS'
              "
              alt="{{ headerContent._pageTitle }}"
              [attr.src]="RGSHomePageLogo"
            />
            <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
          </a>

          <a
            *ngIf="headerContent.currentArchive == 'WILEY'"
            class="returnto-homelink"
            title="{{ showToolTipContent }} (Shift + Alt + Home)"
            [routerLink]="homePageUrl"
            (click)="gotoHome()"
          >
            <img
              alt="{{ 'Alt.archiveLogo' | translate }}"
              class="img-responsive wileyLogoML pointer-cursor"
              [attr.src]="headerContent?.archiveLogo"
            />
            <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="isGuestUser">
          <a class="returnto-homelink" title="{{ showToolTipContent }}">
            <img
              *ngIf="
                headerContent.currentArchive !== 'WILEY' &&
                headerContent.currentArchive !== 'RGS'
              "
              [class.arabic-style]="isArabicLanguage"
              class="img-responsive wileyLogoMRL"
              alt="{{ 'Alt.archiveLogo' | translate }}"
              [attr.src]="headerContent?.archiveLogo"
            />
            <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
          </a>

          <a
            class="navbar-brand tooltip"
            title="{{ showToolTipContent }} (Shift + Alt + Home)"
            [routerLink]="homePageUrl"
          >
            <img
              *ngIf="
                headerContent.currentArchive !== 'WILEY' &&
                headerContent.currentArchive == 'RGS'
              "
              alt="{{ headerContent._pageTitle }}"
              [attr.src]="RGSHomePageLogo"
            />
            <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
          </a>

          <a
            *ngIf="headerContent.currentArchive == 'WILEY'"
            class="returnto-homelink"
            title="{{ showToolTipContent }} (Shift + Alt + Home)"
          >
            <img
              alt="{{ 'Alt.archiveLogo' | translate }}"
              [class.arabic-style]="isArabicLanguage"
              class="img-responsive wileyLogoML"
              [attr.src]="headerContent?.archiveLogo"
            />
            <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
          </a>
        </ng-container>
      </div>
      <div
        id="navbar1"
        class="collapse navbar-collapse navbar-responsive-collapse"
      >
        <ul
          class="nav navbar-nav navbar-right"
          (click)="$event.stopPropagation()"
          [ngClass]="{
            toolsActiveArrowShow: !toolsdropdownActive,
            toolsActiveArrowHide: toolsdropdownActive
          }"
        >
          <li
            tabindex="0"
            [routerLinkActive]="['active']"
            [ngClass]="{ 'language-active': isLanguageDropdownOpen }"
          >

            <a
              id="basic-link"
              tabindex="0"
              class="language-dropdown menu-list lan-dropdown"
              (click)="languageDropdown()"
              (keyup.enter)="languageDropdown()"
              aria-controls="basic-link-dropdown"
              [ngClass]="{ 'language-active': isLanguageDropdownOpen }"
              title="{{ languageName }} ({{ shiftControl }} + Z)"
            >
              <i
                [ngClass]="{
                  'gobe-fa-style ': !isLanguageDropdownOpen,
                  'gobe-fa-style--active ': isLanguageDropdownOpen
                }"
                aria-hidden="true"
              ></i>
              <span class="text-uppercase language-label">{{
                languageName
              }}</span>
              <span
                [ngClass]="{
                  'fa-chevron-up': isLanguageDropdownOpen,
                  'fa-chevron-down': !isLanguageDropdownOpen
                }"
                id="otherArchiveArrowToggle"
                class="fa fa-chevron-down dropdown-fa"
              ></span>
            </a>

            <ul
              id="basic-link-dropdown"
              class="dropdown-menu language-menu"
              role="menu"
              aria-labelledby="basic-link"
              [ngStyle]="{ display: isLanguageDropdownOpen ? 'block' : 'none' }"
            >
              <div class="language-drpdwn-scroll" #container>
                <li
                  *ngFor="let lan of languages"
                  [ngClass]="{
                    'active-language--li': languageName === lan.key
                  }"
                  tabindex="0"
                  (keydown.enter)="switchLanguage(lan.value); dropdown.hide()"
                >
                  <a
                    class="dropdown-item"
                    (click)="switchLanguage(lan.value)"
                    >{{ lan.key }}</a
                  >
                </li>
              </div>
            </ul>
          </li>
          <li tabindex="0" class="active" [routerLinkActive]="['active']">
            <a
              [class.inactive]="isGuestUserFromShortURL"
              class="menu-list"
              tabindex="0"
              title="{{ 'HomePage.AboutPage' | translate }} ({{
                controlShortcut
              }} + U)"
              [class.arabic-style]="isArabicLanguage"
              (click)="gotoAboutPage()"
            >
              <span
                class="spriteico aboutIcon"
                [class.arabic-style]="isArabicLanguage"
              >
              </span
              >{{ "HomePage.AboutPage" | translate }}
            </a>
          </li>
          <li
            [class.inactive]="isGuestUserFromShortURL"
            tabindex="0"
            *ngIf="
              headerContent.currentArchive.toLowerCase() != 'wiley' &&
              !isGuestUser
            "
          >
            <a
              tabindex="0"
              (click)="navigateToCollection()"
              (keyup.enter)="navigateToCollection()"
              title="{{ 'HomePage.browseCollections' | translate }} "
              class="menu-list"
              [class.arabic-style]="isArabicLanguage"
            >
              <span
                class="spriteico collectionIcon"
                [class.arabic-style]="isArabicLanguage"
              >
                </span
              >{{ "HomePage.collections" | translate }}</a
            >
          </li>
          <li
            [class.inactive]="isGuestUserFromShortURL"
            tabindex="0"
            *ngIf="!toggleMainHeader && !isGuestUser"
            id="otherarchivesMenuActive"
            [ngClass]="{
              toolsActive: otherArchiveDropdown,
              toolsInActive: !otherArchiveDropdown
            }"
          >
            <a
              href="javascript:void(0)"
              class="menu-list dropdown-toggle"
              [class.arabic-style]="isArabicLanguage"
              tabindex="0"
              (click)="otherarchivesToggle()"
              (keyup.enter)="otherarchivesToggle()"
              title="{{ 'HomePage.OtherArchivesDropdown' | translate }} ({{
                controlShortcut
              }} + R)"
            >
              <span
                class="spriteico archiveIcon"
                [class.arabic-style]="isArabicLanguage"
              >
              </span
              >{{ "HomePage.OtherArchivesDropdown" | translate }}
              <span
                [ngClass]="{
                  'fa-chevron-up': otherArchiveDropdown,
                  'fa-chevron-down': !otherArchiveDropdown
                }"
                id="otherArchiveArrowToggle"
                class="fa fa-chevron-down dropdown-fa"
              >
              </span>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              [ngStyle]="{ display: otherArchiveDropdown ? 'block' : 'none' }"
              id="wiley-menu-archive-content"
              #archivesOption
              aria-labelledby="wiley-archive-btn"
              (click)="$event.stopPropagation()"
            >
              <li
                role="presentation"
                *ngFor="let archive of archivesList; let i = index"
                (click)="onArchiveSelect(i, archive)"
                (keydown.enter)="onArchiveSelect(i, archive)"
                [attr.tabindex]="otherArchiveDropdown ? 0 : -1"
              >
                <div class="row no-margin">
                  <div
                    class="col-md-5 col-sm-4 col-xs-4"
                    [class.arabic-style]="isArabicLanguage"
                    [title]="
                      parsedText(
                        archive.shortName.toLowerCase() + '.name' | translate
                      )
                    "
                  >
                    <img
                      *ngIf="archive.logo"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      [src]="archive.logo"
                    />
                    <img
                      *ngIf="!archive.logo && archive.shortName == 'wiley'"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      src="assets/config/wiley/wiley_logo.png"
                    />
                    <img
                      *ngIf="!archive.logo && archive.shortName == 'BAAS'"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      src="assets/config/baas/baas_logo.png"
                    />
                    <img
                      *ngIf="!archive.logo && archive.shortName == 'ENV'"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      src="assets/config/env/env_logo.png"
                    />
                    <img
                      *ngIf="!archive.logo && archive.shortName == 'NYAS'"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      src="assets/config/nyas/nyas_logo.png"
                    />
                    <img
                      *ngIf="!archive.logo && archive.shortName == 'RAI'"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      src="assets/config/rai/rai_logo.png"
                    />
                    <img
                      *ngIf="!archive.logo && archive.shortName == 'RAITEST'"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      src="assets/config/rai/rai_logo.png"
                    />
                    <img
                      *ngIf="!archive.logo && archive.shortName == 'WPA'"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      src="assets/config/wiley/wiley_logo.png"
                    />
                    <img
                      *ngIf="!archive.logo && archive.shortName == 'WPA1'"
                      class="center-block img-responsive {{
                        archive?.shortName?.toLowerCase()
                      }}"
                      [class.arabic-style]="isArabicLanguage"
                      alt="{{ 'Alt.archiveName' | translate }}"
                      src="assets/config/wiley/wiley_logo.png"
                    />
                  </div>
                  <div class="col-md-7 col-sm-8 col-xs-8 padding-left-0">
                    <p
                      class="archive-content"
                      [class.arabic-style]="isArabicLanguage"
                      [innerHTML]="
                        archive.shortName.toLowerCase() + '.name' | translate
                      "
                    ></p>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          <li
            [class.inactive]="isGuestUserFromShortURL"
            tabindex="0"
            id="toolsMenuActive"
            [class.toolsActive]="toggleviewlist"
          >
            <a
              href="javascript:void(0)"
              [class.arabic-style]="isArabicLanguage"
              class="menu-list dropdown-toggle list-menu"
              (click)="toolsToggle()"
              tabindex="0"
              title="{{ 'HomePage.ToolsDropdown.title' | translate }} ({{
                controlShortcut
              }} + B)"
            >
              <span
                class="spriteico toolIcon"
                [class.arabic-style]="isArabicLanguage"
              >
              </span>
              {{ "HomePage.ToolsDropdown.title" | translate }}
              <span
                class="viewListCount"
                *ngIf="viewListCount + clippedImageList?.length > 0"
              >
                <div class="tools-badge badge">
                  {{ viewListCount + clippedImageList?.length }}
                </div>
              </span>
              <span
                [ngClass]="{
                  'fa-chevron-up': toggleviewlist,
                  'fa-chevron-down': !toggleviewlist
                }"
                id="tollArrowToggle"
                class="fa fa-chevron-down dropdown-fa tool-dropdownarrow"
              >
              </span>
          
            </a>
            <ul
              class="dropdown-menu dropdown-menu-right margin-right15"
              role="menu"
              id="viewlist-content"
              [ngStyle]="{ display: toggleviewlist ? 'block' : 'none' }"
            >
              <li role="presentation">
                <div class="viewlist-listcontainer">
                  <div
                    class="col-md-12 col-sm-12 viewlist-listcontainerinner"
                    [ngClass]="{
                      viewList: viewListCount + clippedImageList?.length > 0,
                      noCount: viewListCount + clippedImageList?.length == 0,
                      noHistory: !isViewListOpen
                    }"
                    *ngIf="isSessionGuest != 'guest'"
                  >
                    <li
                      tabindex="0"
                      id="viewList"
                      [class.arabic-style]="isArabicLanguage"
                      [class.list-active]="viewListPop"
                      title="{{
                        'HomePage.ToolsDropdown.viewList' | translate
                      }} ({{ controlShortcut }} + W)"
                      class="search-resultli"
                      [outsideClick]="false"
                      #pop="bs-popover"
                      triggers="click:click"
                      [popover]="listcountPopover"
                      [placement]="'left'"
                      (click)="hide('viewlist', $event); viewListSelection()"
                      (keyup.enter)="
                        pop.show();
                        hide('viewlist', $event);
                        viewListSelection()
                      "
                    >
                      <span class="fa fa-chevron-left"></span>
                      <span class="spriteico viewlistIcon"></span>
                      <label
                        class="bm-page"
                        [class.arabic-style]="isArabicLanguage"
                        >{{ "HomePage.ToolsDropdown.viewList" | translate }} ({{
                          viewListCount + clippedImageList?.length
                        }})
                      </label>
                    </li>
                  </div>

                  <div
                    class="col-md-12 col-sm-12 viewlist-listcontainerinner"
                    *ngIf="isSessionGuest != 'guest'"
                  >
                    <li
                      tabindex="0"
                      id="viewContent"
                      [class.arabic-style]="isArabicLanguage"
                      [class.list-active]="viewListPop"
                      title="{{
                        'HomePage.ToolsDropdown.viewContent' | translate
                      }} ({{ controlShortcut }} + W)"
                      class="search-resultli"
                      (click)="showViewCOntentList()"
                      (keyup.enter)="showViewCOntentList()"
                    >
                      <span class="fa fa-chevron-left"></span>
                      <span class="spriteico viewcontentIcon"></span>
                      <label
                        class="bm-page"
                        [class.arabic-style]="isArabicLanguage"
                        >{{
                          "HomePage.ToolsDropdown.viewContent" | translate
                        }}
                        ({{ viewContentCount }})
                      </label>
                    </li>
                  </div>

                  <div
                    class="col-md-12 col-sm-12 full-width viewlist-listcontainerinner history_container"
                    [ngClass]="{
                      historypop:
                        viewHistoryList?.length > 0 ||
                        documentHistory?.length > 0,
                      noHistory:
                        (viewHistoryList?.length == null ||
                          viewHistoryList?.length == 0 ||
                          viewHistoryList?.length == undefined) &&
                        (documentHistory?.length == null ||
                          documentHistory?.length == 0 ||
                          documentHistory?.length == undefined)
                    }"
                    *ngIf="isSessionGuest != 'guest'"
                  >
                    <li
                      tabindex="0"
                      id="ViewHistory"
                      [class.arabic-style]="isArabicLanguage"
                      title="{{
                        'HomePage.ToolsDropdown.viewHistory' | translate
                      }} ({{ controlShortcut }} + H)"
                      class="search-resultli"
                      [outsideClick]="false"
                      #historypop="bs-popover"
                      [popover]="historyPopover"
                      [placement]="'left'"
                      (click)="hide('historyList', $event)"
                      (keyup.enter)="
                        historypop.show(); hide('historyList', $event)
                      "
                    >
                      <span class="fa fa-chevron-left"></span>
                      <span class="spriteico viewhistoryIcon"></span>
                      <label
                        class="bm-page"
                        [class.arabic-style]="isArabicLanguage"
                        >{{
                          "HomePage.ToolsDropdown.viewHistory" | translate
                        }}</label
                      >
                      <ng-template #historyPopover>
                        <div id="editor"></div>
                        <div
                          id="temp"
                          class="col-md-12 history_popover"
                          *ngIf="
                            viewHistoryList?.length > 0 ||
                            documentHistory?.length > 0
                          "
                        >
                          <div
                            class="viewlist-containertitle viewlist-containertitle-header history_type_title"
                            [class.arabic-style]="isArabicLanguage"
                          >
                            <h4
                              class="viewlist-title"
                              [class.arabic-style]="isArabicLanguage"
                            >
                              {{
                                "HomePage.ToolsDropdown.historyList" | translate
                              }}
                            </h4>
                          </div>
                          <tabset
                            class="history-facet-tabset"
                            id="historyTabset"
                            #historyTab
                          >
                            <tab
                              [customClass]="'docviewer-tabs'"
                              heading=""
                              id="tab1"
                            >
                              <ng-template tabHeading>
                                <span
                                  [class.arabic-style]="isArabicLanguage"
                                  [class.arabic-style]="isArabicLanguage"
                                  title="{{
                                    'HomePage.ToolsDropdown.historyQueries'
                                      | translate
                                  }} ({{ controlShortcut }} + '3')"
                                  >{{
                                    "HomePage.ToolsDropdown.historyQueries"
                                      | translate
                                  }}</span
                                >
                              </ng-template>
                              <div class="viewlist-container-parent">
                                <div
                                  class="viewlist-container-parent-scroll"
                                  #container
                                >
                                  
                                  <div class="viewlist-container-inner">
                                    <div class="viewlist-containertitle">
                                      <h4 class="">
                                        <span
                                          class="sr-only"
                                          [class.arabic-style]="
                                            isArabicLanguage
                                          "
                                          >{{
                                            "HomePage.containerTitle"
                                              | translate
                                          }}</span
                                        >
                                      </h4>
                                    </div>
                                    <div
                                      class="col-md-12 viewlist-container-content"
                                      *ngIf="viewHistoryList?.length > 0"
                                    >
                                      <div class="viewlist-content-div">
                                        <div class="">
                                          <div class="col-md-12 padding-0">
                                            <table class="table history_table">
                                              <thead>
                                                <tr>
                                                  <th
                                                    class="pointer"
                                                    [class.arabic-style]="
                                                      isArabicLanguage
                                                    "
                                                    (click)="sort('date')"
                                                  >
                                                    {{
                                                      "HomePage.ToolsDropdown.date"
                                                        | translate
                                                    }}
                                                    <span class="pull-right">
                                                      <i
                                                        class="fa"
                                                        [ngClass]="{
                                                          'fa-sort':
                                                            column != 'date',
                                                          'fa-sort-asc':
                                                            column == 'date' &&
                                                            !isDesc,
                                                          'fa-sort-desc':
                                                            column == 'date' &&
                                                            isDesc
                                                        }"
                                                        aria-hidden="true"
                                                      >
                                                      </i>
                                                    </span>
                                                  </th>
                                                  <th
                                                    class="th-header historyDoc"
                                                    [class.arabic-style]="
                                                      isArabicLanguage
                                                    "
                                                  >
                                                    {{
                                                      "HomePage.ToolsDropdown.searchQuery"
                                                        | translate
                                                    }}
                                                  </th>
                                                  <th class="th-header">
                                                    {{
                                                      "HomePage.ToolsDropdown.action"
                                                        | translate
                                                    }}
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody class="history_body">
                                                <tr
                                                  *ngFor="
                                                    let viewHistory of viewHistoryList
                                                  "
                                                >
                                                  <td
                                                    class="col-sm-1 historyDate"
                                                  >
                                                    {{
                                                      viewHistory?.date
                                                        | date : "short"
                                                    }}
                                                  </td>
                                                  <td
                                                    class="col-sm-8 historyTable"
                                                  >
                                                    <ul class="result-tag">
                                                      <p
                                                        *ngIf="
                                                          viewHistory?.explorerType !==
                                                            'photo' &&
                                                          viewHistory?.explorerType !==
                                                            'map' &&
                                                          viewHistory?.fromCollocates !==
                                                            'wordcloud' &&
                                                          viewHistory?.fromCollocates !==
                                                            'collocate'
                                                        "
                                                        class="explorer-label"
                                                      >
                                                        {{
                                                          "explorer.documentSearch"
                                                            | translate
                                                        }}
                                                      </p>
                                                      <p
                                                        *ngIf="
                                                          viewHistory?.explorerType ==
                                                          'photo'
                                                        "
                                                        class="explorer-label"
                                                      >
                                                        {{
                                                          "explorer.photosPageTitle"
                                                            | translate
                                                        }}
                                                      </p>
                                                      <p
                                                        *ngIf="
                                                          viewHistory?.explorerType ==
                                                          'map'
                                                        "
                                                        class="explorer-label"
                                                      >
                                                        {{
                                                          "explorer.mapPageTitle"
                                                            | translate
                                                        }}
                                                      </p>
                                                      <p
                                                        *ngIf="
                                                          viewHistory?.fromCollocates ==
                                                          'collocate'
                                                        "
                                                        class="explorer-label"
                                                      >
                                                        {{
                                                          "Titles.collocationTitle"
                                                            | translate
                                                        }}
                                                      </p>
                                                      <p
                                                        *ngIf="
                                                          viewHistory?.fromCollocates ==
                                                          'wordcloud'
                                                        "
                                                        class="explorer-label"
                                                      >
                                                        {{
                                                          "Titles.wordCloudSearch"
                                                            | translate
                                                        }}
                                                      </p>
                                                      <label
                                                        [class.arabic-style]="
                                                          isArabicLanguage
                                                        "
                                                        class="search-resultlbl"
                                                        *ngIf="
                                                          !(
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.contentType
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.docSubType
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.languages
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.contentCollection
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.publishedDateFrom >
                                                              0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.publishedDatePrefix ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.publishedDateIncludesNull ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.contentSources
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.illustrationType
                                                              ?.length > 0 ||
                                                            viewHistory?.filedVal
                                                          ) &&
                                                          !viewHistory?.searchParams &&
                                                          viewHistory?.explorerType !==
                                                            'photo' &&
                                                          viewHistory?.explorerType !==
                                                            'map'
                                                        "
                                                      >
                                                        {{
                                                          "HomePage.AllDocuments"
                                                            | translate
                                                        }}
                                                      </label>
                                                      <ul
                                                        [class.arabic-style]="
                                                          isArabicLanguage
                                                        "
                                                        class="col-sm-12 col-xs-12 padding-0 searchQry search_qry_term"
                                                        *ngIf="
                                                          (viewHistory?.searchParams &&
                                                            viewHistory?.searchParams !=
                                                              ' ') ||
                                                          viewHistory?.filedVal
                                                        "
                                                      >
                                                        <li
                                                          [class.arabic-style]="
                                                            isArabicLanguage
                                                          "
                                                          class="searchKey col-sm-12"
                                                        >
                                                          <label
                                                            [class.arabic-style]="
                                                              isArabicLanguage
                                                            "
                                                            class="searchBy Query"
                                                            *ngIf="
                                                              (viewHistory?.searchParams &&
                                                                viewHistory?.searchParams !=
                                                                  ' ') ||
                                                              viewHistory?.filedVal
                                                            "
                                                            >{{
                                                              "HomePage.Query"
                                                                | translate
                                                            }}</label
                                                          >
                                                          <div
                                                            class="col-sm-10 search-Qry"
                                                          >
                                                            <div
                                                              class="searchResultDoc"
                                                            >
                                                              <div
                                                                class="search-reslbl"
                                                              >
                                                                <label
                                                                  [class.arabic-style]="
                                                                    isArabicLanguage
                                                                  "
                                                                  class="search-resultlbl"
                                                                  *ngIf="
                                                                    viewHistory?.searchParams
                                                                      ? true
                                                                      : false
                                                                  "
                                                                  >{{
                                                                    "HomePage.EntireDocument"
                                                                      | translate
                                                                  }}</label
                                                                >
                                                                <span
                                                                  class="badge margin-left5"
                                                                  *ngIf="
                                                                    viewHistory?.searchParams &&
                                                                    viewHistory?.searchParams !=
                                                                      ' '
                                                                  "
                                                                  [attr.title]="
                                                                    viewHistory?.searchParams
                                                                      ? viewHistory?.searchParams
                                                                      : ''
                                                                  "
                                                                  >{{
                                                                    viewHistory?.searchParams
                                                                      ? viewHistory?.searchParams
                                                                      : ""
                                                                  }}
                                                                </span>
                                                              </div>
                                                              <span
                                                                class="operator"
                                                                *ngIf="
                                                                  viewHistory?.searchParams &&
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.searchKeywords
                                                                    ?.length >
                                                                    0 &&
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.searchKeywords[0]
                                                                    .fieldValue !==
                                                                    ''
                                                                "
                                                              >
                                                                {{
                                                                  "AdvancedSearch.operators.AND.name"
                                                                    | translate
                                                                }}
                                                              </span>
                                                            </div>
                                                            <div
                                                              class="searchResultDoc"
                                                              *ngFor="
                                                                let keyword of viewHistory
                                                                  ?.filterParams
                                                                  ?.searchKeywords;
                                                                let i = index
                                                              "
                                                            >
                                                              <div
                                                                class="search-reslbl"
                                                                *ngIf="
                                                                  keyword.fieldValue !==
                                                                  ''
                                                                "
                                                              >
                                                                <label
                                                                  class="search-resultlbl"
                                                                  [attr.title]="
                                                                    fieldNames[
                                                                      keyword
                                                                        .fieldName
                                                                    ]
                                                                  "
                                                                  >{{
                                                                    fieldNames[
                                                                      keyword
                                                                        ?.fieldName
                                                                    ]
                                                                      | titlecase
                                                                  }}</label
                                                                >
                                                                <span
                                                                  class="badge margin-left5"
                                                                  [attr.title]="
                                                                    keyword.fieldValue !==
                                                                    ''
                                                                  "
                                                                  >{{
                                                                    keyword?.fieldValue
                                                                      | titlecase
                                                                  }}</span
                                                                >
                                                              </div>
                                                              <span
                                                                class="operator"
                                                                *ngIf="
                                                                  i !==
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.searchKeywords
                                                                      ?.length -
                                                                      1 &&
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.searchKeywords[
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.searchKeywords
                                                                      ?.length -
                                                                      1
                                                                  ]
                                                                    ?.fieldValue !==
                                                                    ''
                                                                "
                                                              >
                                                                {{
                                                                  keyword.operator
                                                                }}
                                                              </span>
                                                            </div>

                                                          </div>
                                                        </li>
                                                      </ul>
                                                      <ul
                                                        class="col-sm-12 padding-0 advSearch searchQry"
                                                        *ngIf="
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.archive?.length >
                                                            0 ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.contentType
                                                            ?.length > 0 ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.docSubType
                                                            ?.length > 0 ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.publishedDateFrom >
                                                            0 ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.publishedDatePrefix ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.publishedDateIncludesNull ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.languages
                                                            ?.length > 0 ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.contentCollection
                                                            ?.length > 0 ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.contentSources
                                                            ?.length > 0 ||
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.illustrationType
                                                            ?.length > 0
                                                        "
                                                      >
                                                        <label
                                                          [class.arabic-style]="
                                                            isArabicLanguage
                                                          "
                                                          *ngIf="
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.archive
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.contentType
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.docSubType
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.publishedDateFrom >
                                                              0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.publishedDatePrefix ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.publishedDateIncludesNull ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.languages
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.contentCollection
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.contentSources
                                                              ?.length > 0 ||
                                                            viewHistory
                                                              ?.filterParams
                                                              ?.illustrationType
                                                              ?.length > 0
                                                          "
                                                          class="searchBy col-sm-2 Query breakLabel"
                                                        >
                                                          {{
                                                            "HomePage.limiters"
                                                              | translate
                                                          }}
                                                        </label>
                                                        <ul
                                                          class="col-sm-10 padding-0"
                                                        >
                                                          <li
                                                            class="historyBatch col-sm-3"
                                                            *ngIf="
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.archive
                                                                ?.length > 0
                                                            "
                                                          >
                                                            <label
                                                              [class.arabic-style]="
                                                                isArabicLanguage
                                                              "
                                                              class="search-resultlbl"
                                                              >{{
                                                                "Titles.Archives"
                                                                  | translate
                                                              }}</label
                                                            >
                                                            <label
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.archive
                                                                  ?.length < 2
                                                              "
                                                            >
                                                              <span
                                                                *ngFor="
                                                                  let archive of viewHistory
                                                                    ?.filterParams
                                                                    ?.archive;
                                                                  let i = index
                                                                "
                                                                class="margin-left5"
                                                              >
                                                                <span
                                                                  *ngFor="
                                                                    let archiveObj of headerContent?._wileyArchives;
                                                                    let i = index
                                                                  "
                                                                  class="margin-left5"
                                                                >
                                                                  <ng-container
                                                                    *ngIf="
                                                                      archive ===
                                                                      archiveObj?.shortName
                                                                    "
                                                                  >
                                                                    <span
                                                                      class="badge"
                                                                      style="
                                                                        padding: 6px;
                                                                      "
                                                                      [attr.title]="
                                                                        archiveObj?.name
                                                                      "
                                                                    >
                                                                      {{
                                                                        archiveObj?.name
                                                                      }}
                                                                    </span>
                                                                  </ng-container>
                                                                </span>

                                                              
                                                                <span
                                                                  *ngIf="
                                                                    i !=
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.archive
                                                                      ?.length -
                                                                      1
                                                                  "
                                                                  class="badge-operator"
                                                                >
                                                                  {{
                                                                    "AdvancedSearch.operators.OR.name"
                                                                      | translate
                                                                  }}
                                                                </span>
                                                              </span>
                                                            </label>
                                                            <button
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.archive
                                                                  ?.length > 1
                                                              "
                                                              class="btn btn-default badge"
                                                              aria-hidden="true"
                                                              [outsideClick]="true"
                                                              [popover]="
                                                                archivepopOverTemplate
                                                              "
                                                              [placement]="'auto'"
                                                              triggers="click:click"
                                                            >
                                                              <span
                                                                *ngFor="
                                                                  let archiveObj of headerContent?._wileyArchives;
                                                                  let i = index
                                                                "
                                                              >
                                                                <ng-container
                                                                  *ngIf="
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.archive[0] ===
                                                                    archiveObj?.shortName
                                                                  "
                                                                >
                                                                  <span
                                                                    class="select-qry"
                                                                    [attr.title]="
                                                                      archiveObj?.name
                                                                    "
                                                                  >
                                                                    {{
                                                                      archiveObj?.name
                                                                    }}
                                                                  </span>
                                                                </ng-container>
                                                              </span>
                                                              <span
                                                                class="search-resultcount"
                                                                >+{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.archive
                                                                    ?.length - 1
                                                                }}
                                                                <i
                                                                  class="fa fa-caret-down"
                                                                ></i>
                                                              </span>
                                                            </button>
                                                            <ng-template
                                                              #archivepopOverTemplate
                                                            >
                                                              <ul
                                                                class="popoverUl"
                                                                *ngFor="
                                                                  let archive of viewHistory
                                                                    ?.filterParams
                                                                    ?.archive;
                                                                  let i = index
                                                                "
                                                                [attr.title]="
                                                                  archive
                                                                "
                                                              >
                                                                <li>
                                                                  <span
                                                                    *ngFor="
                                                                      let archiveObj of headerContent?._wileyArchives;
                                                                      let i = index
                                                                    "
                                                                    class="margin-left5"
                                                                  >
                                                                    <ng-container
                                                                      *ngIf="
                                                                        archive ===
                                                                        archiveObj?.shortName
                                                                      "
                                                                    >
                                                                      <span
                                                                        [attr.title]="
                                                                          archiveObj?.name
                                                                        "
                                                                      >
                                                                        {{
                                                                          archiveObj?.name
                                                                        }}
                                                                      </span>
                                                                    </ng-container>
                                                                  </span>

                                                                </li>
                                                              </ul>
                                                            </ng-template>
                                                          </li>

                                                          <li
                                                            class="historyBatch col-sm-3"
                                                            *ngIf="
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.contentType
                                                                ?.length > 0
                                                            "
                                                          >
                                                            <label
                                                              [class.arabic-style]="
                                                                isArabicLanguage
                                                              "
                                                              class="search-resultlbl"
                                                              >{{
                                                                "HomePage.contentTypes"
                                                                  | translate
                                                              }}</label
                                                            >
                                                            <label
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.contentType
                                                                  ?.length < 2
                                                              "
                                                            >
                                                              <span
                                                                *ngFor="
                                                                  let contentType of viewHistory
                                                                    ?.filterParams
                                                                    ?.contentType;
                                                                  let i = index
                                                                "
                                                                class="margin-left5"
                                                              >
                                                                <span
                                                                  class="badge"
                                                                  [attr.title]="
                                                                    contentType
                                                                  "
                                                                  >{{
                                                                    contentType
                                                                  }}
                                                                </span>
                                                                <span
                                                                  *ngIf="
                                                                    i !=
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.contentType
                                                                      ?.length -
                                                                      1
                                                                  "
                                                                  class="badge-operator"
                                                                >
                                                                  {{
                                                                    "AdvancedSearch.operators.OR.name"
                                                                      | translate
                                                                  }}
                                                                </span>
                                                              </span>
                                                            </label>
                                                            <button
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.contentType
                                                                  ?.length > 1
                                                              "
                                                              class="btn btn-default badge"
                                                              aria-hidden="true"
                                                              [popover]="
                                                                contentTypepopOverTemplate
                                                              "
                                                              [placement]="'bottom'"
                                                              triggers="focus"
                                                            >
                                                              <span
                                                                class="select-qry"
                                                                >{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.contentType[0]
                                                                }}</span
                                                              >
                                                              <span
                                                                class="search-resultcount"
                                                                >+{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.contentType
                                                                    ?.length - 1
                                                                }}
                                                                <i
                                                                  class="fa fa-caret-down"
                                                                ></i>
                                                              </span>
                                                            </button>
                                                            <ng-template
                                                              #contentTypepopOverTemplate
                                                            >
                                                              <ul
                                                                class="popoverUl"
                                                                *ngFor="
                                                                  let contentType of viewHistory
                                                                    ?.filterParams
                                                                    ?.contentType;
                                                                  let i = index
                                                                "
                                                                [attr.title]="
                                                                  contentType
                                                                "
                                                              >
                                                                <li>
                                                                  <span
                                                                    *ngIf="
                                                                      i != 0
                                                                    "
                                                                    class="badge-operator"
                                                                  >
                                                                    {{
                                                                      "AdvancedSearch.operators.OR.name"
                                                                        | translate
                                                                    }} </span
                                                                  >{{
                                                                    contentType
                                                                  }}
                                                                </li>
                                                              </ul>
                                                            </ng-template>
                                                          </li>
                                                          <li
                                                            class="historyBatch col-sm-3"
                                                            *ngIf="
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.docSubType
                                                                ?.length > 0
                                                            "
                                                          >
                                                            <label
                                                              [class.arabic-style]="
                                                                isArabicLanguage
                                                              "
                                                              class="search-resultlbl"
                                                              >{{
                                                                "HomePage.contentSubtypes"
                                                                  | translate
                                                              }}
                                                            </label>
                                                            <label
                                                              class="resultset-lbl"
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.docSubType
                                                                  ?.length < 2
                                                              "
                                                            >
                                                              <span
                                                                *ngFor="
                                                                  let contentsubType of viewHistory
                                                                    ?.filterParams
                                                                    ?.docSubType;
                                                                  let i = index
                                                                "
                                                                class="margin-left5"
                                                              >
                                                                <span
                                                                  class="badge"
                                                                  [attr.title]="
                                                                    contentsubType
                                                                  "
                                                                  >{{
                                                                    contentsubType
                                                                  }}</span
                                                                >
                                                                <span
                                                                  *ngIf="
                                                                    i !=
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.docSubType
                                                                      ?.length -
                                                                      1
                                                                  "
                                                                  class="badge-operator"
                                                                >
                                                                  {{
                                                                    "AdvancedSearch.operators.OR.name"
                                                                      | translate
                                                                  }}
                                                                </span>
                                                              </span>
                                                            </label>
                                                            <button
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.docSubType
                                                                  ?.length > 1
                                                              "
                                                              class="btn btn-default badge"
                                                              aria-hidden="true"
                                                              triggers="focus"
                                                              [popover]="
                                                                contentsubTypepopOverTemplate
                                                              "
                                                              [placement]="'bottom'"
                                                            >
                                                              <span
                                                                class="select-qry"
                                                                >{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.docSubType[0]
                                                                }}</span
                                                              >
                                                              <span
                                                                class="search-resultcount"
                                                                >+{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.docSubType
                                                                    ?.length - 1
                                                                }}
                                                                <i
                                                                  class="fa fa-caret-down"
                                                                ></i>
                                                              </span>
                                                            </button>
                                                            <ng-template
                                                              #contentsubTypepopOverTemplate
                                                            >
                                                              <ul
                                                                class="popoverUl"
                                                                *ngFor="
                                                                  let contentsubType of viewHistory
                                                                    ?.filterParams
                                                                    ?.docSubType;
                                                                  let i = index
                                                                "
                                                              >
                                                                <li>
                                                                  <span
                                                                    *ngIf="
                                                                      i != 0
                                                                    "
                                                                    class="badge-operator"
                                                                  >
                                                                    {{
                                                                      "AdvancedSearch.operators.OR.name"
                                                                        | translate
                                                                    }} </span
                                                                  >{{
                                                                    contentsubType
                                                                  }}
                                                                </li>
                                                              </ul>
                                                            </ng-template>
                                                          </li>
                                                          <li
                                                            class="historyBatch col-sm-3"
                                                            *ngIf="
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.languages
                                                                ?.length > 0
                                                            "
                                                          >
                                                            <label
                                                              [class.arabic-style]="
                                                                isArabicLanguage
                                                              "
                                                              class="search-resultlbl"
                                                              >{{
                                                                "HomePage.languages"
                                                                  | translate
                                                              }}
                                                            </label>
                                                            <label
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.languages
                                                                  ?.length < 2
                                                              "
                                                            >
                                                              <span
                                                                *ngFor="
                                                                  let language of viewHistory
                                                                    ?.filterParams
                                                                    ?.languages;
                                                                  let i = index
                                                                "
                                                                class="margin-left5"
                                                              >
                                                                <span
                                                                  class="badge"
                                                                  [attr.title]="
                                                                    language
                                                                  "
                                                                  >{{
                                                                    language
                                                                  }}</span
                                                                >
                                                              </span>
                                                            </label>
                                                            <button
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.languages
                                                                  ?.length > 1
                                                              "
                                                              class="btn btn-default badge"
                                                              aria-hidden="true"
                                                              triggers="focus"
                                                              [popover]="
                                                                lanpopOverTemplate
                                                              "
                                                              [placement]="'bottom'"
                                                            >
                                                              <span
                                                                class="select-qry"
                                                                >{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.languages[0]
                                                                }}</span
                                                              >
                                                              <span
                                                                class="search-resultcount"
                                                                >+{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.languages
                                                                    ?.length - 1
                                                                }}
                                                                <i
                                                                  class="fa fa-caret-down"
                                                                ></i>
                                                              </span>
                                                            </button>
                                                            <ng-template
                                                              #lanpopOverTemplate
                                                            >
                                                              <ul
                                                                class="popoverUl"
                                                                *ngFor="
                                                                  let language of viewHistory
                                                                    ?.filterParams
                                                                    ?.languages;
                                                                  let i = index
                                                                "
                                                              >
                                                                <li>
                                                                  <span
                                                                    *ngIf="
                                                                      i != 0
                                                                    "
                                                                    class="badge-operator"
                                                                  >
                                                                    {{
                                                                      "AdvancedSearch.operators.OR.name"
                                                                        | translate
                                                                    }} </span
                                                                  >{{
                                                                    language
                                                                  }}
                                                                </li>
                                                              </ul>
                                                            </ng-template>
                                                          </li>
                                                          <li
                                                            class="historyBatch col-sm-3"
                                                            *ngIf="
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.contentCollection
                                                                ?.length > 0
                                                            "
                                                          >
                                                            <label
                                                              [class.arabic-style]="
                                                                isArabicLanguage
                                                              "
                                                              class="search-resultlbl"
                                                              >{{
                                                                "HomePage.Collections"
                                                                  | translate
                                                              }}
                                                            </label>
                                                            <label
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.contentCollection
                                                                  ?.length < 2
                                                              "
                                                            >
                                                              <span
                                                                *ngFor="
                                                                  let collection of viewHistory
                                                                    ?.filterParams
                                                                    ?.contentCollection;
                                                                  let i = index
                                                                "
                                                                class="margin-left5"
                                                              >
                                                                <span
                                                                  class="badge"
                                                                  [attr.title]="
                                                                    collection
                                                                  "
                                                                  >{{
                                                                    collection
                                                                  }}</span
                                                                >
                                                                <span
                                                                  *ngIf="
                                                                    i !=
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.contentCollection
                                                                      ?.length -
                                                                      1
                                                                  "
                                                                  class="badge-operator"
                                                                >
                                                                  {{
                                                                    "AdvancedSearch.operators.OR.name"
                                                                      | translate
                                                                  }}
                                                                </span>
                                                              </span>
                                                            </label>
                                                            <button
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.contentCollection
                                                                  ?.length > 1
                                                              "
                                                              class="btn btn-default badge"
                                                              aria-hidden="true"
                                                              triggers="focus"
                                                              [popover]="
                                                                collectionsepopOverTemplate
                                                              "
                                                              [placement]="'bottom'"
                                                            >
                                                              <span
                                                                class="select-qry"
                                                                >{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.contentCollection[0]
                                                                }}</span
                                                              >
                                                              <span
                                                                class="search-resultcount"
                                                                >+{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.contentCollection
                                                                    ?.length - 1
                                                                }}
                                                                <i
                                                                  class="fa fa-caret-down"
                                                                ></i>
                                                              </span>
                                                            </button>
                                                            <ng-template
                                                              #collectionsepopOverTemplate
                                                            >
                                                              <ul
                                                                class="popoverUl"
                                                                *ngFor="
                                                                  let contentCollection of viewHistory
                                                                    ?.filterParams
                                                                    ?.contentCollection;
                                                                  let i = index
                                                                "
                                                              >
                                                                <li>
                                                                  <span
                                                                    *ngIf="
                                                                      i != 0
                                                                    "
                                                                    class="badge-operator"
                                                                  >
                                                                    {{
                                                                      "AdvancedSearch.operators.OR.name"
                                                                        | translate
                                                                    }} </span
                                                                  >{{
                                                                    contentCollection
                                                                  }}
                                                                </li>
                                                              </ul>
                                                            </ng-template>
                                                          </li>
                                                          <li
                                                            class="historyBatch col-sm-3"
                                                            *ngIf="
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.illustrationType
                                                                ?.length > 0
                                                            "
                                                          >
                                                            <label
                                                              [class.arabic-style]="
                                                                isArabicLanguage
                                                              "
                                                              class="search-resultlbl"
                                                              >{{
                                                                "SearchResult.IllustrationType"
                                                                  | translate
                                                              }}
                                                            </label>
                                                            <label
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.illustrationType
                                                                  ?.length < 2
                                                              "
                                                            >
                                                              <span
                                                                *ngFor="
                                                                  let illustration of viewHistory
                                                                    ?.filterParams
                                                                    ?.illustrationType;
                                                                  let i = index
                                                                "
                                                                class="margin-left5"
                                                              >
                                                                <span
                                                                  class="badge"
                                                                  [attr.title]="
                                                                    illustration
                                                                  "
                                                                  >{{
                                                                    illustration
                                                                  }}</span
                                                                >
                                                                <span
                                                                  *ngIf="
                                                                    i !=
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.illustrationType
                                                                      ?.length -
                                                                      1
                                                                  "
                                                                  class="badge-operator"
                                                                >
                                                                  {{
                                                                    "AdvancedSearch.operators.OR.name"
                                                                      | translate
                                                                  }}
                                                                </span>
                                                              </span>
                                                            </label>
                                                            <button
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.illustrationType
                                                                  ?.length > 1
                                                              "
                                                              class="btn btn-default badge"
                                                              aria-hidden="true"
                                                              triggers="focus"
                                                              [popover]="
                                                                illustrationTypepopOverTemplate
                                                              "
                                                              [placement]="'bottom'"
                                                            >
                                                              <span
                                                                class="select-qry"
                                                                >{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.illustrationType[0]
                                                                }}</span
                                                              >
                                                              <span
                                                                class="search-resultcount"
                                                                >+{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.illustrationType
                                                                    ?.length - 1
                                                                }}
                                                                <i
                                                                  class="fa fa-caret-down"
                                                                ></i>
                                                              </span>
                                                            </button>
                                                            <ng-template
                                                              #illustrationTypepopOverTemplate
                                                            >
                                                              <ul
                                                                class="popoverUl"
                                                                *ngFor="
                                                                  let illustrationType of viewHistory
                                                                    ?.filterParams
                                                                    ?.illustrationType;
                                                                  let i = index
                                                                "
                                                              >
                                                                <li>
                                                                  <span
                                                                    *ngIf="
                                                                      i != 0
                                                                    "
                                                                    class="badge-operator"
                                                                  >
                                                                    {{
                                                                      "AdvancedSearch.operators.OR.name"
                                                                        | translate
                                                                    }} </span
                                                                  >{{
                                                                    illustrationType
                                                                  }}
                                                                </li>
                                                              </ul>
                                                            </ng-template>
                                                          </li>
                                                          <li
                                                            class="historyBatch col-sm-3 padding-left-0"
                                                            *ngIf="
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.contentSources
                                                                ?.length > 0
                                                            "
                                                          >
                                                            <label
                                                              [class.arabic-style]="
                                                                isArabicLanguage
                                                              "
                                                              class="search-resultlbl"
                                                              >{{
                                                                "HomePage.Sources"
                                                                  | translate
                                                              }}
                                                            </label>
                                                            <label
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.contentSources
                                                                  ?.length < 2
                                                              "
                                                            >
                                                              <span
                                                                *ngFor="
                                                                  let source of viewHistory
                                                                    ?.filterParams
                                                                    ?.contentSources;
                                                                  let i = index
                                                                "
                                                                class="margin-left5"
                                                              >
                                                                <span
                                                                  class="badge"
                                                                  [attr.title]="
                                                                    source
                                                                  "
                                                                  >{{
                                                                    source
                                                                  }}</span
                                                                >
                                                              </span>
                                                            </label>
                                                            <button
                                                              *ngIf="
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.contentSources
                                                                  ?.length > 1
                                                              "
                                                              class="btn btn-default badge"
                                                              aria-hidden="true"
                                                              triggers="focus"
                                                              [popover]="
                                                                sourcepopOverTemplate
                                                              "
                                                              [placement]="'bottom'"
                                                            >
                                                              <span
                                                                class="select-qry"
                                                                >{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.contentSources[0]
                                                                }}</span
                                                              >
                                                              <span
                                                                class="search-resultcount"
                                                                >+{{
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.contentSources
                                                                    ?.length - 1
                                                                }}
                                                                <i
                                                                  class="fa fa-caret-down"
                                                                ></i>
                                                              </span>
                                                            </button>
                                                            <ng-template
                                                              #sourcepopOverTemplate
                                                            >
                                                              <ul
                                                                class="popoverUl"
                                                                *ngFor="
                                                                  let contentSources of viewHistory
                                                                    ?.filterParams
                                                                    ?.contentSources;
                                                                  let i = index
                                                                "
                                                              >
                                                                <li>
                                                                  <span
                                                                    *ngIf="
                                                                      i != 0
                                                                    "
                                                                    class="badge-operator"
                                                                  >
                                                                    {{
                                                                      "AdvancedSearch.operators.OR.name"
                                                                        | translate
                                                                    }} </span
                                                                  >{{
                                                                    contentSources
                                                                  }}
                                                                </li>
                                                              </ul>
                                                            </ng-template>
                                                          </li>

                                                          
                                                          <li
                                                            class="historyBatch col-sm-3"
                                                            *ngIf="
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.publishedDateFrom ||
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.publishedDateIncludesNull ||
                                                              viewHistory
                                                                ?.filterParams
                                                                ?.publishedDatePrefix
                                                            "
                                                            [ngClass]="{
                                                              publishedDate_result:
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.publishedDateTo ||
                                                                viewHistory
                                                                  ?.filterParams
                                                                  ?.publishedDateIncludesNull
                                                            }"
                                                          >
                                                            <label
                                                              [class.arabic-style]="
                                                                isArabicLanguage
                                                              "
                                                              class="search-resultlbl"
                                                              >{{
                                                                "HomePage.publicationDate"
                                                                  | translate
                                                              }}
                                                            </label>
                                                            <br />
                                                            <span>
                                                              <label
                                                                *ngIf="
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.publishedDateFrom ||
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.publishedDateIncludesNull ||
                                                                  viewHistory
                                                                    ?.filterParams
                                                                    ?.publishedDatePrefix
                                                                "
                                                              >
                                                                <span
                                                                  class="badge margin-left5"
                                                                >
                                                                  <span
                                                                    *ngIf="
                                                                      viewHistory
                                                                        ?.filterParams
                                                                        ?.publishedDateFrom !==
                                                                      'All'
                                                                    "
                                                                  >
                                                                    {{
                                                                      viewHistory
                                                                        ?.filterParams
                                                                        ?.publishedDatePrefix
                                                                    }}:</span
                                                                  >
                                                                  {{
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.publishedDateFrom
                                                                  }}
                                                                  <span
                                                                    [class.arabic-style]="
                                                                      isArabicLanguage
                                                                    "
                                                                    *ngIf="
                                                                      viewHistory
                                                                        ?.filterParams
                                                                        ?.publishedDateTo
                                                                    "
                                                                    >{{
                                                                      "Common.AND"
                                                                        | translate
                                                                    }}</span
                                                                  >
                                                                  {{
                                                                    viewHistory
                                                                      ?.filterParams
                                                                      ?.publishedDateTo
                                                                  }}
                                                                  <span
                                                                    *ngIf="
                                                                      viewHistory
                                                                        ?.filterParams
                                                                        ?.publishedDateIncludesNull
                                                                    "
                                                                    >&nbsp;
                                                                    <span
                                                                      [class.arabic-style]="
                                                                        isArabicLanguage
                                                                      "
                                                                      class="pub_date_operator"
                                                                      >{{
                                                                        "Common.AND"
                                                                          | translate
                                                                      }}</span
                                                                    >&nbsp;
                                                                    <span
                                                                      [class.arabic-style]="
                                                                        isArabicLanguage
                                                                      "
                                                                      class="pub_date"
                                                                      >{{
                                                                        "Common.UNDATED"
                                                                          | translate
                                                                      }}</span
                                                                    >
                                                                  </span>
                                                                </span>
                                                              </label>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                      </ul>
                                                    </ul>
                                                  </td>
                                                  <td
                                                    [class.arabic-style]="
                                                      isArabicLanguage
                                                    "
                                                    title="{{
                                                      'HomePage.ToolsDropdown.runQuery'
                                                        | translate
                                                    }}"
                                                    class="col-sm-3 padding-0 query-run"
                                                  >
                                                    <a
                                                      [class.arabic-style]="
                                                        isArabicLanguage
                                                      "
                                                      href="javascript:void(0)"
                                                      title="{{
                                                        'HomePage.ToolsDropdown.runQuery'
                                                          | translate
                                                      }}"
                                                    >
                                                      <span
                                                        [class.arabic-style]="
                                                          isArabicLanguage
                                                        "
                                                        class="runClass"
                                                        (click)="
                                                          runQuery(viewHistory)
                                                        "
                                                        >{{
                                                          "HomePage.ToolsDropdown.runQuery"
                                                            | translate
                                                        }}</span
                                                      >
                                                    </a>
                                                    <a
                                                      [class.arabic-style]="
                                                        isArabicLanguage
                                                      "
                                                      href="javascript:void(0)"
                                                      title="Copy Query"
                                                    >
                                                      <span
                                                        [class.arabic-style]="
                                                          isArabicLanguage
                                                        "
                                                        class="copyClass"
                                                        (click)="
                                                          copyRunTemp(
                                                            viewHistory
                                                          )
                                                        "
                                                        >Copy Query</span
                                                      >
                                                    </a>

                                                   
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <span
                                      [class.arabic-style]="isArabicLanguage"
                                      class="empty_list"
                                      *ngIf="viewHistoryList?.length == null"
                                      >{{
                                        "HomePage.ToolsDropdown.noDocumentsInHistory"
                                          | translate
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </tab>
                            <tab
                              [customClass]="'docviewer-tabs'"
                              heading=""
                              id="tab2"
                            >
                              <ng-template tabHeading>
                                <span
                                  [class.arabic-style]="isArabicLanguage"
                                  title="{{
                                    'HomePage.ToolsDropdown.documents'
                                      | translate
                                  }} ({{ controlShortcut }} + '4')"
                                  [class.arabic-style]="isArabicLanguage"
                                  >{{
                                    "HomePage.ToolsDropdown.documents"
                                      | translate
                                  }}</span
                                >
                              </ng-template>
                              <div class="viewlist-container-parent">
                                <div
                                  class="viewlist-container-parent-scroll"
                                  #container
                                >
                                 
                                  <div
                                    class="col-md-12 viewlist-container-content"
                                    *ngIf="documentHistory?.length > 0"
                                  >
                                    <div class="viewlist-content-div">
                                      <div class="">
                                        <div class="col-md-12 padding-0">
                                          <table class="table history_table">
                                            <thead>
                                              <tr>
                                                <th
                                                  class="pointer"
                                                  (click)="sort('date')"
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                >
                                                  {{
                                                    "HomePage.ToolsDropdown.date"
                                                      | translate
                                                  }}
                                                  <span class="pull-right">
                                                    <i
                                                      class="fa"
                                                      [ngClass]="{
                                                        'fa-sort':
                                                          column != 'date',
                                                        'fa-sort-asc':
                                                          column == 'date' &&
                                                          !isDesc,
                                                        'fa-sort-desc':
                                                          column == 'date' &&
                                                          isDesc
                                                      }"
                                                      aria-hidden="true"
                                                    >
                                                    </i>
                                                  </span>
                                                </th>
                                                <th
                                                  class="th-header historyDoc"
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                >
                                                  {{
                                                    "HomePage.ToolsDropdown.documentTitle"
                                                      | translate
                                                  }}
                                                </th>
                                                <th
                                                  class="th-header"
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                >
                                                  {{
                                                    "HomePage.ToolsDropdown.action"
                                                      | translate
                                                  }}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody class="history_body">
                                              <tr
                                                *ngFor="
                                                  let viewDoc of documentHistory
                                                "
                                              >
                                                <td
                                                  class="col-sm-1 historyDate"
                                                >
                                                  {{
                                                    viewDoc?.date
                                                      | date : "short"
                                                  }}
                                                </td>
                                                <td
                                                  class="historyTable col-sm-9"
                                                >
                                                  <div
                                                    class="viewdoc-parent-div"
                                                    title="{{ viewDoc?.title }}"
                                                    appDecodeEntities
                                                  >
                                                    {{ viewDoc?.title }}
                                                  </div>
                                                </td>
                                                <td
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  title="{{
                                                    'HomePage.ToolsDropdown.viewDocument'
                                                      | translate
                                                  }}"
                                                  class="col-sm-2 query-run padding-0"
                                                >
                                                  <span
                                                    [class.arabic-style]="
                                                      isArabicLanguage
                                                    "
                                                    class="runClass"
                                                    (click)="
                                                      runHistoryDoc(viewDoc)
                                                    "
                                                    >{{
                                                      "HomePage.ToolsDropdown.viewDocument"
                                                        | translate
                                                    }}</span
                                                  >
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <span
                                  class="empty_doc_list"
                                  [class.arabic-style]="isArabicLanguage"
                                  *ngIf="documentHistory?.length == 0"
                                  >{{
                                    "HomePage.ToolsDropdown.noDocumentsInHistory"
                                      | translate
                                  }}</span
                                >
                              </div>
                            </tab>
                          </tabset>
                          <div class="viewlist-container-footer">
                            <button
                              class="btn btn-primary btn-link export-btn"
                              [class.arabic-style]="isArabicLanguage"
                              (click)="clearOpenModal($event)"
                            >
                              {{
                                "HomePage.ToolsDropdown.clearHistory"
                                  | translate
                              }}
                            </button>
                          </div>
                        </div>
                        <span
                          class="empty_list"
                          [class.arabic-style]="isArabicLanguage"
                          *ngIf="
                            (viewHistoryList?.length == null ||
                              viewHistoryList?.length == 0 ||
                              viewHistoryList?.length == undefined) &&
                            (documentHistory?.length == null ||
                              documentHistory?.length == 0 ||
                              documentHistory?.length == undefined)
                          "
                          >{{
                            "HomePage.ToolsDropdown.noDocumentsInHistory"
                              | translate
                          }}</span
                        >
                      </ng-template>
                    </li>
                  </div>
                  <div
                    class="col-md-12 col-sm-12 full-width viewlist-listcontainerinner feedbacks"
                    [class.arabic-style]="isArabicLanguage"
                    [ngClass]="{ noHistory: !isFeedbackOpen }"
                  >
                    <li
                      tabindex="0"
                      id="Feedback"
                      title="{{
                        'HomePage.ToolsDropdown.feedback' | translate
                      }} ({{ controlShortcut }} + C)"
                      class="search-resultli"
                      [outsideClick]="true"
                      #feedbackpop="bs-popover"
                      [popover]="feedbackRef"
                      [placement]="'left'"
                      (click)="hide('feedback', $event)"
                      (keyup.enter)="
                        feedbackpop.show(); hide('feedback', $event)
                      "
                    >
                      <span class="fa fa-chevron-left"></span>
                      <span class="spriteico FeedbackIcon"></span>
                      <label
                        class="bm-page"
                        [class.arabic-style]="isArabicLanguage"
                        >{{
                          "HomePage.ToolsDropdown.feedback" | translate
                        }}</label
                      >
                      <ng-template #feedbackRef>
                        <div class="feedback-scroll" #container>
                         
                          <div class="col-md-12 feeback-container">
                            <form
                              [formGroup]="feedback"
                              (ngSubmit)="onSubmit(feedback.value)"
                              novalidate
                            >
                              <h4
                                class="feedbackHeader"
                                [class.arabic-style]="isArabicLanguage"
                              >
                                {{
                                  "HomePage.ToolsDropdown.feedback" | translate
                                }}
                              </h4>
                              <p
                                class="feedback-des"
                                [class.arabic-style]="isArabicLanguage"
                              >
                                {{
                                  "HomePage.ToolsDropdown.feedbackInfo"
                                    | translate
                                }}
                              </p>
                              <div class="feedback-form form-group">
                                <label
                                  class="control-label"
                                  for="name"
                                  [class.arabic-style]="isArabicLanguage"
                                  >{{
                                    "HomePage.ToolsDropdown.yourName"
                                      | translate
                                  }}:</label
                                >
                                <div class="">
                                  <input
                                    aria-label="Your Name"
                                    [class.arabic-style]="isArabicLanguage"
                                    title="{{
                                      'HomePage.ToolsDropdown.yourName'
                                        | translate
                                    }}"
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    placeholder=""
                                    name="yourname"
                                    formControlName="name"
                                  />
                                </div>
                              </div>
                              <div class="feedback-form form-group">
                                <label
                                  class="control-label"
                                  for="email"
                                  [class.arabic-style]="isArabicLanguage"
                                  >{{
                                    "HomePage.ToolsDropdown.yourEmail"
                                      | translate
                                  }}:</label
                                >
                                <div class="">
                                  <input
                                    aria-label="Your Email"
                                    [class.arabic-style]="isArabicLanguage"
                                    title="{{
                                      'HomePage.ToolsDropdown.yourEmail'
                                        | translate
                                    }}"
                                    type="email"
                                    class="form-control"
                                    id="email"
                                    placeholder=""
                                    name="youremail"
                                    [email]="
                                      sendMailData.mail != '' &&
                                      sendMailData.mail != null
                                    "
                                    formControlName="mail"
                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                  />
                                  <div
                                    class="error text-danger"
                                    [class.arabic-style]="isArabicLanguage"
                                    *ngIf="
                                      feedback.get('mail').dirty &&
                                      feedback.get('mail').invalid
                                    "
                                  >
                                    {{
                                      "HomePage.ToolsDropdown.invalidEmail"
                                        | translate
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="feedback-form form-group">
                                <label
                                  for="Topic"
                                  [class.arabic-style]="isArabicLanguage"
                                >
                                  {{
                                    "HomePage.ToolsDropdown.topic" | translate
                                  }}:
                                  <span
                                    class="glyphicon glyphicon-asterisk"
                                  ></span>
                                </label>
                                <select
                                  [class.arabic-style]="isArabicLanguage"
                                  title="{{
                                    'HomePage.ToolsDropdown.topic' | translate
                                  }}"
                                  name="feedback"
                                  class="form-control feedbackSelect"
                                  id="Topic"
                                  formControlName="topic"
                                >
                                  <option
                                    [class.arabic-style]="isArabicLanguage"
                                    value=""
                                    selected
                                  >
                                    {{
                                      "HomePage.ToolsDropdown.selectTopic"
                                        | translate
                                    }}
                                  </option>
                                  <option
                                    [class.arabic-style]="isArabicLanguage"
                                    value="Compliments"
                                  >
                                    {{
                                      "HomePage.ToolsDropdown.Compliments"
                                        | translate
                                    }}
                                  </option>
                                  <option
                                    [class.arabic-style]="isArabicLanguage"
                                    value="Issues"
                                  >
                                    {{
                                      "HomePage.ToolsDropdown.Issues"
                                        | translate
                                    }}
                                  </option>
                                  <option
                                    [class.arabic-style]="isArabicLanguage"
                                    value="Feature Requests"
                                  >
                                    {{
                                      "HomePage.ToolsDropdown.featureRequests"
                                        | translate
                                    }}
                                  </option>
                                  <option
                                    [class.arabic-style]="isArabicLanguage"
                                    value="Other"
                                  >
                                    {{
                                      "HomePage.ToolsDropdown.Other" | translate
                                    }}
                                  </option>
                                </select>
                                <div
                                  [class.arabic-style]="isArabicLanguage"
                                  class="error text-danger"
                                  *ngIf="
                                    feedback
                                      .get('topic')
                                      .hasError('required') &&
                                    feedback.get('topic').touched
                                  "
                                >
                                  {{
                                    "HomePage.ToolsDropdown.topicRequired"
                                      | translate
                                  }}
                                </div>
                              </div>
                              <div
                                [class.arabic-style]="isArabicLanguage"
                                class="feedback-form form-group"
                              >
                                <label for="Message">
                                  {{
                                    "HomePage.ToolsDropdown.Message" | translate
                                  }}
                                  <span
                                    class="glyphicon glyphicon-asterisk"
                                  ></span>
                                </label>
                                <textarea
                                  [class.arabic-style]="isArabicLanguage"
                                  aria-label="Message"
                                  title="{{
                                    'HomePage.ToolsDropdown.Message' | translate
                                  }}"
                                  name="message"
                                  id="Message"
                                  cols="30"
                                  rows="8"
                                  class="form-control feedbackSelect"
                                  formControlName="message"
                                ></textarea>
                                <div
                                  [class.arabic-style]="isArabicLanguage"
                                  class="error text-danger"
                                  *ngIf="
                                    feedback
                                      .get('message')
                                      .hasError('required') &&
                                    feedback.get('message').touched
                                  "
                                >
                                  {{
                                    "HomePage.ToolsDropdown.messageRequired"
                                      | translate
                                  }}
                                </div>
                                <div
                                  [class.arabic-style]="isArabicLanguage"
                                  class="error text-danger"
                                  *ngIf="
                                    feedback.get('message').invalid &&
                                    feedback.get('message').touched
                                  "
                                >
                                  {{
                                    "HomePage.ToolsDropdown.enterMessage"
                                      | translate
                                  }}
                                </div>
                              </div>
                            
                              <div
                                class="form-group pull-right"
                                [tooltip]="feedbackTooltip"
                                placement="left"
                              >
                                <button
                                  [class.arabic-style]="isArabicLanguage"
                                  title="{{
                                    'HomePage.ToolsDropdown.submit' | translate
                                  }}"
                                  type="submit"
                                  class="btn btn-link btn-primary btn-sm"
                                  (click)="sendMail(message)"
                                  [disabled]="feedback.invalid"
                                >
                                  {{
                                    "HomePage.ToolsDropdown.submit" | translate
                                  }}
                                </button>
                                <ng-template
                                  #feedbackTooltip
                                  [ngClass]="{
                                    show: feedback.invalid,
                                    hide: feedback.valid
                                  }"
                                >
                                  <div
                                    [class.arabic-style]="isArabicLanguage"
                                    class="error text-danger"
                                    *ngIf="
                                      feedback.get('topic').untouched ||
                                      feedback.get('topic').invalid
                                    "
                                  >
                                    {{
                                      "HomePage.ToolsDropdown.selectTopictoSubmit"
                                        | translate
                                    }}
                                  </div>
                                  <div
                                    class="error text-danger"
                                    [hidden]="feedback.get('topic').invalid"
                                  >
                                    <div
                                      [class.arabic-style]="isArabicLanguage"
                                      class="error text-danger"
                                      *ngIf="
                                        feedback.get('message').invalid ||
                                        feedback.get('message').untouched
                                      "
                                    >
                                      {{
                                        "HomePage.ToolsDropdown.enterMessagetoSubmit"
                                          | translate
                                      }}
                                    </div>
                                  </div>
                                  <div
                                    class="error text-danger"
                                    [hidden]="
                                      feedback.get('message').invalid ||
                                      feedback.get('topic').invalid
                                    "
                                  >
                                    <div
                                      [class.arabic-style]="isArabicLanguage"
                                      class="error text-danger"
                                      *ngIf="feedback.get('mail').invalid"
                                    >
                                      {{
                                        "HomePage.ToolsDropdown.enterValidMail"
                                          | translate
                                      }}
                                    </div>
                                  </div>
                                </ng-template>
                              </div>
                            </form>
                          </div>
                        </div>
                      </ng-template>
                    </li>
                  </div>
                  <div
                    *ngIf="showDCIcon"
                    class="col-md-12 col-sm-12 no-padding full-width viewlist-listcontainerinner helpguide"
                    [ngClass]="{ helpActive: isDownloadContentOpen }"
                  >
                    <li
                      tabindex="0"
                      id="Help"
                      [class.arabic-style]="isArabicLanguage"
                      title="{{
                        'HomePage.ToolsDropdown.downContent' | translate
                      }} ({{ controlShortcut }} + /)"
                      class="search-resultli helpClass"
                      [outsideClick]="true"
                      #helpLink="bs-popover"
                      [popover]="helpLinks"
                      [placement]="'left'"
                      (click)="navToDCPage()"
                      (keyup.enter)="navToDCPage()"
                    >
                      <span class="spriteico downcontentIcon"></span>
                      <label
                        class="bm-page"
                        [class.arabic-style]="isArabicLanguage"
                        >{{
                          "HomePage.ToolsDropdown.downContent" | translate
                        }}</label
                      >
                    </li>
                  </div>
                  <div
                    class="col-md-12 col-sm-12 no-padding full-width viewlist-listcontainerinner helpguide"
                    [ngClass]="{ helpActive: isHelpOpen, noHelp: !isHelpOpen }"
                  >
                    <li
                      tabindex="0"
                      id="Help"
                      [class.arabic-style]="isArabicLanguage"
                      title="{{
                        'HomePage.ToolsDropdown.helpAndTraining' | translate
                      }} ({{ controlShortcut }} + /)"
                      class="search-resultli helpClass"
                      [outsideClick]="true"
                      #helpLink="bs-popover"
                      [popover]="helpLinks"
                      [placement]="'left'"
                      (click)="hide('helpLink', $event); gotoHelpPage()"
                      (keyup.enter)="
                        helpLink.show();
                        hide('helpLink', $event);
                        gotoHelpPage()
                      "
                    >
                      <span class="spriteico helpIcon"></span>
                      <label
                        class="bm-page"
                        [class.arabic-style]="isArabicLanguage"
                        >{{
                          "HomePage.ToolsDropdown.helpAndTraining" | translate
                        }}</label
                      >
                      </li>
                  </div>
                  <div
                    class="col-md-12 col-sm-12 full-width viewlist-listcontainerinner"
                    (click)="hide('endSession', $event)"
                    [ngClass]="{
                      sessionActive: isEndSessionOpen,
                      sessionInActive: !isEndSessionOpen
                    }"
                  >
                    <li
                      tabindex="0"
                      [class.arabic-style]="isArabicLanguage"
                      title="{{
                        'HomePage.ToolsDropdown.endSession' | translate
                      }} ({{ shiftControl }} + L)"
                      class="search-resultli helpClass"
                      (click)="endSessionConfirmation($event)"
                      (keyup.enter)="endSessionConfirmation($event)"
                    >
                      <span class="spriteico endSessionIcon"></span>
                      <label
                        class="bm-page"
                        [class.arabic-style]="isArabicLanguage"
                        >{{
                          "HomePage.ToolsDropdown.endSession" | translate
                        }}</label
                      >
                    </li>
                  </div>
                </div>
              </li>
            </ul>
          </li>

          <li>
            <span
              #connectionIndicator
              class="spriteico signal-icon high-singal-icon"
              [class.arabic-style]="isArabicLanguage"
              (mouseenter)="connectionMouseEnter()"
              (mouseleave)="connectionMouseLeave()"
              (click)="downloadImageFile()"
            ></span>
            <ng-container>
              <div
                class="connection-indicator-div"
                *ngIf="showConncetionStatus"
              >
                <p class="connection-heading">
                  <b> {{ "HomePage.yourConnectionSpeed" | translate }} </b>
                </p>
                <div class="connection-child">
                  <span class="spriteico green-icon"></span>
                  <span class="connection-text">
                    - {{ "HomePage.excellent" | translate }}</span
                  >
                </div>
                <div class="connection-child">
                  <span class="spriteico red-icon"></span>
                  <span class="connection-text">
                    - {{ "HomePage.good" | translate }}</span
                  >
                </div>
                <div class="connection-child">
                  <span class="spriteico slow-icon"></span>
                  <span class="connection-text">
                    - {{ "HomePage.slow" | translate }}</span
                  >
                </div>
                <div class="connection-child">
                  <span class="spriteico gray-icon"></span>
                  <span class="connection-text">
                    - {{ "HomePage.noSignal" | translate }}</span
                  >
                </div>
              </div>
            </ng-container>
          </li>

          <div
            *ngIf="toggleArrow"
            class="spinner fa fa-arrow-up arrowForTools"
            [ngClass]="{
              toggleArrowShow: toggleArrow,
              toggleArrowHide: !toggleArrow,
              ieBrowserEnabled: ieBrowserEnabled
            }"
          ></div>
        </ul>
      </div>
    </div>
    <hr class="gradient-bg" />
  </section>
</header> -->
<header *ngIf="headerContent">
  <section id="main-header" [ngStyle]="{ display: toggleMainHeader ? 'block' : 'none' }">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <ng-container *ngIf="!isGuestUser">
          <a [attr.href]="headerContent?.returnUrl" class="back-lib-menu">
            <div class="navbar-header" [class.arabic-style]="isArabicLanguage" title="{{ 'HomePage.returnToLib' | translate }}">
              <img *ngIf="headerContent.currentArchive !== 'wiley'" [class.arabic-style]="isArabicLanguage"
                alt="{{ 'Alt.libraryLogo' | translate }}" [attr.src]="headerContent?.customerLogo"/>
            </div>
          </a>
          <a class="training-hub-link" (click)="gotoWDATrainingHub()">
            WDA Training Hub
          </a>
        </ng-container>
        <ng-container *ngIf="isGuestUser">
          <a class="back-lib-menu">
            <div class="navbar-header" [class.arabic-style]="isArabicLanguage" title="{{ 'HomePage.returnToLib' | translate }}" >
              <img *ngIf="headerContent.currentArchive !== 'wiley'" [class.arabic-style]="isArabicLanguage"
                alt="{{ 'Alt.libraryLogo' | translate }}"[attr.src]="headerContent?.customerLogo"/>
            </div>
          </a>
          <a class="training-hub-link" (click)="gotoWDATrainingHub()">
            WDA Training Hub
          </a>
        </ng-container>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0"> </ul>
          <ul class="nav navbar-nav navbar-right" *ngIf="headerContent?.wileyArchives?.length > 0">
            <ng-container *ngIf="!isGuestUser">
              <button class="btn btn-wiley dropdown-toggle" [class.arabic-style]="isArabicLanguage"
                tabindex="0" (click)="togglewileyheaderarchivemenu(); $event.stopPropagation()"
                id="wiley-archive-btn" (keyup.enter)="togglewileyheaderarchivemenu(); $event.stopPropagation()">
                {{ "HomePage.apptitle" | translate }}
                <span class="fa dropdown-fa pull-right" [ngClass]="{'fa-chevron-down': !togglewileyarchivemenu,'fa-chevron-up': togglewileyarchivemenu}"></span>
              </button>
            </ng-container>
            <ng-container *ngIf="isGuestUser">
              <button title="{{ 'HomePage.apptitle' | translate }}" class="btn btn-wiley dropdown-toggle disabled"
                [class.arabic-style]="isArabicLanguage" id="wiley-archive-btn"> {{ "HomePage.apptitle" | translate }}
                <span class="fa dropdown-fa pull-right fa-chevron-down"></span>
              </button>
            </ng-container>
            <ul class="dropdown-menu dropdown-menu-right" tabindex="0" role="menu" aria-labelledby="wiley-archive-btn"
            [ngStyle]="{display: togglewileyarchivemenu ? 'block' : 'none' }" (click)="$event.stopPropagation()" id="topHeadercontainer">
            <li role="presentation" tabindex="0" *ngFor="let archive of headerContent?.wileyArchives; let i = index"
            (click)="onArchiveSelect(i, archive)" (keyup.enter)="onArchiveSelect(i, archive)">
              <div class="row no-margin">
                  <div class="col-md-4 col-sm-3 col-xs-3" [class.arabic-style]="isArabicLanguage" id="topHeadermenu"
                    [title]="parsedText(archive.shortName.toLowerCase() + '.name' | translate) " >
                    <img class="center-block img-responsive img-fluid mx-auto d-block"
                      [class.arabic-style]="isArabicLanguage" alt="{{ 'HomePage.apptitle' | translate }}" [src]="archive.logo" />
                  </div>
                  <div class="col-md-8 col-sm-9 col-xs-9 padding-left-0">
                    <p class="archive-content" [class.arabic-style]="isArabicLanguage"
                      [innerHTML]=" archive.shortName.toLowerCase() + '.name' | translate"></p>
                  </div>
              </div>
            </li>
            <li [class.inactive]="isGuestUserFromShortURL" role="presentation" (click)="gotoAboutPage()" class="moreAbout" >
              <a class="wiley-primary-text-color more-archives" [class.arabic-style]="isArabicLanguage"
                title="{{ 'HomePage.moreAboutArchives' | translate }}">{{ "HomePage.moreAboutArchives" | translate }}</a >
            </li>
            </ul>
          </ul>
        </div>
      </div>
    </nav>
  </section>
  <!-- <section
    id="main-header"
    [ngStyle]="{ display: toggleMainHeader ? 'block' : 'none' }"
  >
    <div class="main-header x_pane">
      <nav class="navbar x_content">
        <div class="back-menu">
          <ng-container *ngIf="!isGuestUser">
            <a [attr.href]="headerContent?.returnUrl" class="back-lib-menu">
              <div
                class="navbar-header"
                [class.arabic-style]="isArabicLanguage"
                title="{{ 'HomePage.returnToLib' | translate }}"
              >
                <img
                  *ngIf="headerContent.currentArchive !== 'wiley'"
                  [class.arabic-style]="isArabicLanguage"
                  alt="{{ 'Alt.libraryLogo' | translate }}"
                  [attr.src]="headerContent?.customerLogo"
                />
              </div>
            </a>
            <a class="training-hub-link" (click)="gotoWDATrainingHub()">
              WDA Training Hub
            </a>
          </ng-container>
          <ng-container *ngIf="isGuestUser">
            <a class="back-lib-menu">
              <div
                class="navbar-header"
                [class.arabic-style]="isArabicLanguage"
                title="{{ 'HomePage.returnToLib' | translate }}"
              >
                <img
                  *ngIf="headerContent.currentArchive !== 'wiley'"
                  [class.arabic-style]="isArabicLanguage"
                  alt="{{ 'Alt.libraryLogo' | translate }}"
                  [attr.src]="headerContent?.customerLogo"
                />
              </div>
            </a>
            <a class="training-hub-link" (click)="gotoWDATrainingHub()">
              WDA Training Hub
            </a>
          </ng-container>
        </div>
        <ul
          class="nav navbar-nav navbar-right"
          *ngIf="headerContent?.wileyArchives?.length > 0"
        >
          <li>
            <div class="dropdown text-end">
              <ng-container *ngIf="!isGuestUser">
                <button
                  class="btn btn-wiley dropdown-toggle"
                  [class.arabic-style]="isArabicLanguage"
                  tabindex="0"
                  (click)="
                    togglewileyheaderarchivemenu(); $event.stopPropagation()
                  "
                  id="wiley-archive-btn"
                  (keyup.enter)="
                    togglewileyheaderarchivemenu(); $event.stopPropagation()
                  "
                >
                  {{ "HomePage.apptitle" | translate }}
                  <span
                    class="fa dropdown-fa pull-right"
                    [ngClass]="{
                      'fa-chevron-down': !togglewileyarchivemenu,
                      'fa-chevron-up': togglewileyarchivemenu
                    }"
                  ></span>
                </button>
              </ng-container>
              <ng-container *ngIf="isGuestUser">
                <button
                  title="{{ 'HomePage.apptitle' | translate }}"
                  class="btn btn-wiley dropdown-toggle disabled"
                  [class.arabic-style]="isArabicLanguage"
                  id="wiley-archive-btn"
                >
                  {{ "HomePage.apptitle" | translate }}
                  <span
                    class="fa dropdown-fa pull-right fa-chevron-down"
                  ></span>
                </button>
              </ng-container>
              <ul
                class="dropdown-menu dropdown-menu-right"
                tabindex="0"
                role="menu"
                aria-labelledby="wiley-archive-btn"
                [ngStyle]="{
                  display: togglewileyarchivemenu ? 'block' : 'none'
                }"
                (click)="$event.stopPropagation()"
              >
                <li
                  role="presentation"
                  tabindex="0"
                  *ngFor="
                    let archive of headerContent?.wileyArchives;
                    let i = index
                  "
                >
                  <div class="row">
                    <div
                      class="col-md-12"
                      (click)="onArchiveSelect(i, archive)"
                      (keyup.enter)="onArchiveSelect(i, archive)"
                    >
                      <div
                        class="col-md-5 col-sm-4 col-xs-4"
                        [class.arabic-style]="isArabicLanguage"
                        [title]="
                          parsedText(
                            archive.shortName.toLowerCase() + '.name'
                              | translate
                          )
                        "
                      >
                        <img
                          class="center-block img-responsive img-fluid mx-auto d-block"
                          [class.arabic-style]="isArabicLanguage"
                          alt="{{ 'HomePage.apptitle' | translate }}"
                          [src]="archive.logo"
                        />
                      </div>
                      <div class="col-md-7 col-sm-8 col-xs-8 padding-left-0">
                        <p
                          class="archive-content"
                          [class.arabic-style]="isArabicLanguage"
                          [innerHTML]="
                            archive.shortName.toLowerCase() + '.name'
                              | translate
                          "
                        ></p>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  [class.inactive]="isGuestUserFromShortURL"
                  role="presentation"
                  (click)="gotoAboutPage()"
                  class="moreAbout"
                >
                  <a
                    class="wiley-primary-text-color more-archives"
                    [class.arabic-style]="isArabicLanguage"
                    title="{{ 'HomePage.moreAboutArchives' | translate }}"
                    >{{ "HomePage.moreAboutArchives" | translate }}</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </section> -->
  <div class="header-toggle" title="{{ headerTitleText }}">
    <a
      [class.inactive]="isGuestUserFromShortURL"
      tabindex="0"
      (click)="toggleMainHeaderFunction()"
      (keyup.enter)="toggleMainHeaderFunction()"
    >
      <i
        [ngClass]="{
          arrowUpIcon: toggleMainHeader,
          arrowDownIcon: !toggleMainHeader
        }"
        class="spriteico arrowUpIcon"
        aria-hidden="true"
      ></i>
    </a>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <ng-container *ngIf="!isGuestUser">
        <a *ngIf="headerContent.currentArchive !== 'WILEY' && headerContent.currentArchive !== 'RGS'"
          class="navbar-brand tooltip" title="{{ showToolTipContent }} (Shift + Alt + Home)" [routerLink]="homePageUrl">
          <img alt="{{ headerContent._pageTitle }}" [attr.src]="headerContent?.archiveLogo" />
          <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
        </a>
        <a *ngIf="headerContent.currentArchive !== 'WILEY' && headerContent.currentArchive == 'RGS'"
          [class.inactive]="isGuestUserFromShortURL" class="navbar-brand tooltip" title="{{ showToolTipContent }} (Shift + Alt + Home)" [routerLink]="homePageUrl">
          <img alt="{{ headerContent._pageTitle }}" [attr.src]="RGSHomePageLogo"/>
          <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
        </a>
        <a *ngIf="headerContent.currentArchive == 'WILEY'" class="returnto-homelink"ti tle="{{ showToolTipContent }} 
        (Shift + Alt + Home)" [routerLink]="homePageUrl" (click)="gotoHome()" >
          <img alt="{{ 'Alt.archiveLogo' | translate }}" class="img-responsive img-fluid wileyLogoML pointer-cursor"
            [attr.src]="headerContent?.archiveLogo"/>
          <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="isGuestUser">
        <a class="returnto-homelink" title="{{ showToolTipContent }}" *ngIf="headerContent.currentArchive !== 'WILEY' &&  headerContent.currentArchive !== 'RGS'">
          <img [class.arabic-style]="isArabicLanguage" class="img-responsive img-fluid wileyLogoMRL"
            alt="{{ 'Alt.archiveLogo' | translate }}" [attr.src]="headerContent?.archiveLogo"/>
          <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
        </a>
        <a *ngIf="headerContent.currentArchive !== 'WILEY' && headerContent.currentArchive == 'RGS'"
          class="navbar-brand tooltip" title="{{ showToolTipContent }} (Shift + Alt + Home)" [routerLink]="homePageUrl" >
          <img alt="{{ headerContent._pageTitle }}" [attr.src]="RGSHomePageLogo" />
          <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
        </a>
        <a *ngIf="headerContent.currentArchive == 'WILEY'"
          class="returnto-homelink" title="{{ showToolTipContent }} (Shift + Alt + Home)">
          <img alt="{{ 'Alt.archiveLogo' | translate }}" [class.arabic-style]="isArabicLanguage"
            class="img-responsive img-fluid wileyLogoML" [attr.src]="headerContent?.archiveLogo" />
          <span class="sr-only">{{ "HomePage.wileyLogo" | translate }}</span>
        </a>
      </ng-container>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"> </ul>
        <ul class="nav navbar-nav navbar-right" (click)="$event.stopPropagation()"
        [ngClass]="{ toolsActiveArrowShow: !toolsdropdownActive, toolsActiveArrowHide: toolsdropdownActive}">
        <li tabindex="0" [routerLinkActive]="['active']" [ngClass]="{ 'language-active': isLanguageDropdownOpen }" id="languageMenu">
          <a id="basic-link" tabindex="0" class="language-dropdown menu-list lan-dropdown" (click)="languageDropdown()" (keyup.enter)="languageDropdown()"
            aria-controls="basic-link-dropdown" [ngClass]="{ 'language-active': isLanguageDropdownOpen }" title="{{ languageName }} ({{ shiftControl }} + Z)">
            <i [ngClass]="{'gobe-fa-style ': !isLanguageDropdownOpen, 'gobe-fa-style--active ': isLanguageDropdownOpen }" aria-hidden="true"></i>
            <span class="text-uppercase language-label">{{ languageName }}</span>
            <span [ngClass]="{'fa-chevron-up': isLanguageDropdownOpen,'fa-chevron-down': !isLanguageDropdownOpen }" id="otherArchiveArrowToggle" class="fa fa-chevron-down dropdown-fa"></span>
          </a>
          <ul id="basic-link-dropdown" class="dropdown-menu language-menu" role="menu"
            aria-labelledby="basic-link" [ngStyle]="{ display: isLanguageDropdownOpen ? 'block' : 'none' }" >
            <div class="language-drpdwn-scroll" #container>
              <li *ngFor="let lan of languages" [ngClass]="{'active-language--li': languageName === lan.key }" tabindex="0" (keydown.enter)="switchLanguage(lan.value); dropdown.hide()">
                <a class="dropdown-item" (click)="switchLanguage(lan.value)" >{{ lan.key }}</a>
              </li>
            </div>
          </ul>
      </li>
      <li tabindex="0" class="active" [routerLinkActive]="['active']">
        <a [class.inactive]="isGuestUserFromShortURL" class="menu-list"
          tabindex="0"title="{{ 'HomePage.AboutPage' | translate }} ({{
            controlShortcut }} + U)" [class.arabic-style]="isArabicLanguage" (click)="gotoAboutPage()">
          <span class="spriteico aboutIcon" [class.arabic-style]="isArabicLanguage" >
          </span>{{ "HomePage.AboutPage" | translate }}
        </a>
      </li>
      <li [class.inactive]="isGuestUserFromShortURL" tabindex="0" *ngIf="headerContent.currentArchive.toLowerCase() != 'wiley' &&
          !isGuestUser">
        <a tabindex="0" (click)="navigateToCollection()" (keyup.enter)="navigateToCollection()"
          title="{{ 'HomePage.browseCollections' | translate }}" class="menu-list" [class.arabic-style]="isArabicLanguage">
          <span class="spriteico collectionIcon" [class.arabic-style]="isArabicLanguage">
            </span>{{ "HomePage.collections" | translate }}</a>
      </li>
      <li [class.inactive]="isGuestUserFromShortURL" tabindex="0" *ngIf="!toggleMainHeader && !isGuestUser" id="otherarchivesMenuActive"
        [ngClass]="{ toolsActive: otherArchiveDropdown, toolsInActive: !otherArchiveDropdown}" >
        <a href="javascript:void(0)" class="menu-list dropdown-toggle" [class.arabic-style]="isArabicLanguage" tabindex="0"
          (click)="otherarchivesToggle()" (keyup.enter)="otherarchivesToggle()" title="{{ 'HomePage.OtherArchivesDropdown' | translate }} ({{controlShortcut }} + R)">
          <span class="spriteico archiveIcon" [class.arabic-style]="isArabicLanguage"></span>{{ "HomePage.OtherArchivesDropdown" | translate }}
          <span [ngClass]="{'fa-chevron-up': otherArchiveDropdown, 'fa-chevron-down': !otherArchiveDropdown }"
            id="otherArchiveArrowToggle" class="fa fa-chevron-down dropdown-fa" >
          </span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" role="menu" [ngStyle]="{ display: otherArchiveDropdown ? 'block' : 'none' }"
          id="wiley-menu-archive-content" #archivesOption aria-labelledby="wiley-archive-btn" (click)="$event.stopPropagation()" >
          <li role="presentation" *ngFor="let archive of archivesList; let i = index"
            (click)="onArchiveSelect(i, archive)" (keydown.enter)="onArchiveSelect(i, archive)"
            [attr.tabindex]="otherArchiveDropdown ? 0 : -1">
            <div class="row no-margin">
              <div class="col-md-4 col-sm-3 col-xs-3 padding-0" [class.arabic-style]="isArabicLanguage" [ngClass]="{'wpaImgClass': archive.shortName == 'WPA'}"
                [title]="parsedText(archive.shortName.toLowerCase() + '.name' | translate)" >
                <img *ngIf="archive.logo" class="center-block img-responsive img-fluid mx-auto d-block {{archive?.shortName?.toLowerCase()}}"
                  [class.arabic-style]="isArabicLanguage" alt="{{ 'Alt.archiveName' | translate }}" [src]="archive.logo" />
                <img *ngIf="!archive.logo && archive.shortName == 'wiley'" class="center-block img-responsive img-fluid mx-auto d-block {{archive?.shortName?.toLowerCase()}}"
                  [class.arabic-style]="isArabicLanguage" alt="{{ 'Alt.archiveName' | translate }}"  src="assets/config/wiley/wiley_logo.png" />
                <img *ngIf="!archive.logo && archive.shortName == 'BAAS'"
                  class="center-block img-responsive img-fluid mx-auto d-block {{archive?.shortName?.toLowerCase() }}"
                  [class.arabic-style]="isArabicLanguage"alt="{{ 'Alt.archiveName' | translate }}"
                  src="assets/config/baas/baas_logo.png" />
                <img *ngIf="!archive.logo && archive.shortName == 'ENV'"
                  class="center-block img-responsive img-fluid mx-auto d-block {{archive?.shortName?.toLowerCase()}}"
                  [class.arabic-style]="isArabicLanguage" alt="{{ 'Alt.archiveName' | translate }}"
                  src="assets/config/env/env_logo.png" />
                <img *ngIf="!archive.logo && archive.shortName == 'NYAS'"
                  class="center-block img-responsive img-fluid mx-auto d-block {{archive?.shortName?.toLowerCase()}}" [class.arabic-style]="isArabicLanguage"
                  alt="{{ 'Alt.archiveName' | translate }}" src="assets/config/nyas/nyas_logo.png"/>
                <img *ngIf="!archive.logo && archive.shortName == 'RAI'"
                  class="center-block img-responsive img-fluid mx-auto d-block {{archive?.shortName?.toLowerCase()}}" [class.arabic-style]="isArabicLanguage"
                  alt="{{ 'Alt.archiveName' | translate }}"  src="assets/config/rai/rai_logo.png"/>
                <img *ngIf="!archive.logo && archive.shortName == 'RAITEST'"
                  class="center-block img-responsive img-fluid mx-auto d-block {{ archive?.shortName?.toLowerCase() }}" [class.arabic-style]="isArabicLanguage"
                  alt="{{ 'Alt.archiveName' | translate }}"src="assets/config/rai/rai_logo.png"/>
                <img *ngIf="!archive.logo && archive.shortName == 'WPA'"
                  class="center-block img-responsive img-fluid mx-auto d-block {{ archive?.shortName?.toLowerCase() }}" [class.arabic-style]="isArabicLanguage"
                  alt="{{ 'Alt.archiveName' | translate }}"src="assets/config/wiley/wiley_logo.png"/>
              </div>
              <div class="col-md-8 col-sm-9 col-xs-9 padding-left-0">
                <p class="archive-content" [class.arabic-style]="isArabicLanguage"
                  [innerHTML]="archive.shortName.toLowerCase() + '.name' | translate " ></p>
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li [class.inactive]="isGuestUserFromShortURL" tabindex="0" id="toolsMenuActive" [class.toolsActive]="toggleviewlist">
        <a href="javascript:void(0)" [class.arabic-style]="isArabicLanguage"
          class="menu-list dropdown-toggle list-menu" (click)="toolsToggle()" tabindex="0"
          title="{{ 'HomePage.ToolsDropdown.title' | translate }} ({{controlShortcut }} + B)">
          <span class="spriteico toolIcon" [class.arabic-style]="isArabicLanguage"> </span>
          {{ "HomePage.ToolsDropdown.title" | translate }}
          <span class="viewListCount" *ngIf="viewListCount + clippedImageList?.length > 0" >
            <div class="tools-badge badge"> {{ viewListCount + clippedImageList?.length }} </div>
          </span>
          <span [ngClass]="{'fa-chevron-up': toggleviewlist, 'fa-chevron-down': !toggleviewlist  }"
            id="tollArrowToggle" class="fa fa-chevron-down dropdown-fa tool-dropdownarrow" >
          </span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right"
          role="menu" id="viewlist-content" [ngStyle]="{ display: toggleviewlist ? 'block' : 'none' }">
          <li role="presentation">
            <div class="viewlist-listcontainer">
              <div class="row m-0">
              <div class="col-md-12 col-sm-12 viewlist-listcontainerinner p-0" [ngClass]="{ viewList: viewListCount + clippedImageList?.length > 0,
                  noCount: viewListCount + clippedImageList?.length == 0, noHistory: !isViewListOpen }"  *ngIf="isSessionGuest != 'guest'">
                <li tabindex="0" id="viewList" [class.arabic-style]="isArabicLanguage" [class.list-active]="viewListPop"
                  title="{{ 'HomePage.ToolsDropdown.viewList' | translate }} ({{ controlShortcut }} + W)" class="search-resultli"[outsideClick]="false"
                  #pop="bs-popover" triggers="click:click" [popover]="listcountPopover" [placement]="'left'" (click)="hide('viewlist', $event); viewListSelection()"
                  (keyup.enter)="pop.show(); hide('viewlist', $event); viewListSelection() ">
                  <span class="fa fa-chevron-left"></span>
                  <span class="spriteico viewlistIcon"></span>
                  <label class="bm-page" [class.arabic-style]="isArabicLanguage">{{ "HomePage.ToolsDropdown.viewList" | translate }} ({{
                      viewListCount + clippedImageList?.length}}) </label>
                </li>
              </div>
              <div class="col-md-12 col-sm-12 viewlist-listcontainerinner p-0" *ngIf="isSessionGuest != 'guest'" >
                <li tabindex="0" id="viewContent" [class.arabic-style]="isArabicLanguage" [class.list-active]="viewListPop"
                  title="{{ 'HomePage.ToolsDropdown.viewContent' | translate }} ({{ controlShortcut }} + W)"
                  class="search-resultli" (click)="showViewCOntentList()" (keyup.enter)="showViewCOntentList()" >
                  <span class="fa fa-chevron-left"></span>
                  <span class="spriteico viewcontentIcon"></span>
                  <label class="bm-page" [class.arabic-style]="isArabicLanguage" >{{ "HomePage.ToolsDropdown.viewContent" | translate  }}  ({{ viewContentCount }})
                  </label>
                </li>
              </div>
              <div class="col-md-12 col-sm-12 full-width viewlist-listcontainerinner history_container p-0"
                [ngClass]="{  historypop:viewHistoryList?.length > 0 || documentHistory?.length > 0, noHistory:
                    (viewHistoryList?.length == null || viewHistoryList?.length == 0 || viewHistoryList?.length == undefined) &&
                    (documentHistory?.length == null || documentHistory?.length == 0 || documentHistory?.length == undefined) }"
                *ngIf="isSessionGuest != 'guest'">
                <li tabindex="0" id="ViewHistory" [class.arabic-style]="isArabicLanguage"
                  title="{{'HomePage.ToolsDropdown.viewHistory' | translate }} ({{ controlShortcut }} + H)" class="search-resultli"
                  [outsideClick]="true" #historypop="bs-popover" [popover]="historyPopover" [placement]="'left'"
                  (click)="hide('historyList', $event)" (keyup.enter)="historypop.show(); hide('historyList', $event) ">
                  <span class="fa fa-chevron-left"></span>
                  <span class="spriteico viewhistoryIcon"></span>
                  <label class="bm-page"
                    [class.arabic-style]="isArabicLanguage" >{{
                      "HomePage.ToolsDropdown.viewHistory" | translate  }}</label >
                  <ng-template #historyPopover>
                    <div id="editor"></div>
                    <div class="row">
                      <div class="col-md-12 history_popover" id="temp" *ngIf="viewHistoryList?.length > 0 || documentHistory?.length > 0 ">
                        <div class="viewlist-containertitle viewlist-containertitle-header history_type_title" [class.arabic-style]="isArabicLanguage" >
                          <h4 class="viewlist-title" [class.arabic-style]="isArabicLanguage">
                            {{ "HomePage.ToolsDropdown.historyList" | translate }}
                          </h4>
                        </div>
                        <tabset>
                          <tab heading="{{'HomePage.ToolsDropdown.historyQueries'| translate}}" title="{{'HomePage.ToolsDropdown.historyQueries'| translate}} ({{ controlShortcut }} + '3')" [class.arabic-style]="isArabicLanguage"> 
                            <div class="row">
                            <div class="col-md-12" *ngIf="viewHistoryList?.length > 0">
                              <div class="table-responsive historyScroll">
                                <table class="historytable table table-hover table-bordered">
                                  <thead class="thead-dark table-light sticky-top">
                                      <tr>
                                          <th [class.arabic-style]="isArabicLanguage" (click)="sort('date')">{{ "HomePage.ToolsDropdown.date" | translate}}</th>
                                          <th [class.arabic-style]="isArabicLanguage">{{ "HomePage.ToolsDropdown.searchQuery"| translate }}</th>
                                          <th>{{"HomePage.ToolsDropdown.action"  | translate }}</th>
                                        </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let viewHistory of viewHistoryList">
                                        <td>{{viewHistory?.date| date : "short" }}</td>
                                        <td>
                                        <ul class="result-tag">
                                          <p *ngIf="viewHistory?.explorerType !=='photo' && viewHistory?.explorerType !=='map' &&
                                              viewHistory?.fromCollocates !=='wordcloud' &&  viewHistory?.fromCollocates !=='collocate'"
                                            class="explorer-label">{{"explorer.documentSearch" | translate }}
                                          </p>
                                          <p *ngIf=" viewHistory?.explorerType =='photo'"class="explorer-label">
                                            {{"explorer.photosPageTitle" | translate }}
                                          </p>
                                          <p *ngIf="viewHistory?.explorerType == 'map'" class="explorer-label">
                                            {{ "explorer.mapPageTitle" | translate}}
                                          </p>
                                          <p *ngIf=" viewHistory?.fromCollocates =='collocate'" class="explorer-label">
                                            {{"Titles.collocationTitle"| translate }}
                                          </p>
                                          <p *ngIf="viewHistory?.fromCollocates =='wordcloud'" class="explorer-label">
                                            {{"Titles.wordCloudSearch"| translate}}
                                          </p>
                                          <label [class.arabic-style]=" isArabicLanguage" class="search-resultlbl"
                                            *ngIf="!(viewHistory?.filterParams?.contentType ?.length > 0 ||
                                                viewHistory?.filterParams?.docSubType?.length > 0 ||
                                                viewHistory?.filterParams?.languages ?.length > 0 ||
                                                viewHistory?.filterParams ?.contentCollection?.length > 0 ||
                                                viewHistory?.filterParams?.publishedDateFrom >0 ||
                                                viewHistory?.filterParams?.publishedDatePrefix ||
                                                viewHistory?.filterParams ?.publishedDateIncludesNull ||
                                                viewHistory?.filterParams ?.contentSources ?.length > 0 ||
                                                viewHistory?.filterParams?.illustrationType?.length > 0 || viewHistory?.filedVal) &&
                                                !viewHistory?.searchParams && viewHistory?.explorerType !== 'photo' && viewHistory?.explorerType !=='map'">
                                                {{"HomePage.AllDocuments" | translate }}
                                          </label>
                                          <ul [class.arabic-style]="isArabicLanguage "
                                            class="col-sm-12 col-xs-12 padding-0 searchQry search_qry_term"
                                            *ngIf="(viewHistory?.searchParams && viewHistory?.searchParams !=' ') || viewHistory?.filedVal">
                                            <li [class.arabic-style]="isArabicLanguage" class="searchKey col-sm-12">
                                              <label [class.arabic-style]=" isArabicLanguage" class="searchBy Query"
                                                *ngIf="(viewHistory?.searchParams && viewHistory?.searchParams !=' ') || viewHistory?.filedVal " >{{
                                                  "HomePage.Query" | translate}}</label>
                                              <div class="col-sm-10 search-Qry" >
                                                <div class="searchResultDoc" >
                                                  <div class="search-reslbl">
                                                    <label [class.arabic-style]=" isArabicLanguage "
                                                      class="search-resultlbl" *ngIf="viewHistory?.searchParams? true : false " >{{
                                                        "HomePage.EntireDocument"| translate }}
                                                    </label>
                                                    <span class="badge margin-left5" *ngIf=" viewHistory?.searchParams && viewHistory?.searchParams != ' '" [attr.title]="viewHistory?.searchParams? viewHistory?.searchParams : ''" >
                                                      {{ viewHistory?.searchParams ? viewHistory?.searchParams : "" }}
                                                    </span>
                                                  </div>
                                                  <span class="operator" *ngIf="viewHistory?.searchParams &&
                                                      viewHistory?.filterParams?.searchKeywords?.length >0 &&
                                                      viewHistory?.filterParams?.searchKeywords[0].fieldValue !==''">
                                                    {{ "AdvancedSearch.operators.AND.name"| translate }}
                                                  </span>
                                                </div>
                                                <div class="searchResultDoc" *ngFor="let keyword of viewHistory?.filterParams?.searchKeywords;let i = index" >
                                                  <div class="search-reslbl" *ngIf=" keyword.fieldValue !== '' ">
                                                    <label class="search-resultlbl" [attr.title]="fieldNames[keyword.fieldName]">{{
                                                        fieldNames[ keyword?.fieldName] | titlecase }}</label >
                                                    <span class="badge margin-left5"[attr.title]="keyword.fieldValue !==''" >{{ keyword?.fieldValue | titlecase }}</span>
                                                  </div>
                                                  <span class="operator"
                                                    *ngIf="i !== viewHistory?.filterParams?.searchKeywords?.length - 1 &&
                                                      viewHistory?.filterParams?.searchKeywords[viewHistory?.filterParams?.searchKeywords?.length -1] ?.fieldValue !==''">
                                                    {{ keyword.operator }}
                                                  </span>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                          <ul
                                            class="col-sm-12 padding-0 advSearch searchQry"
                                            *ngIf="
                                              viewHistory?.filterParams?.archive?.length >0 ||
                                              viewHistory?.filterParams?.contentType?.length > 0 ||
                                              viewHistory?.filterParams?.docSubType ?.length > 0 ||
                                              viewHistory
                                                ?.filterParams
                                                ?.publishedDateFrom >
                                                0 ||
                                              viewHistory
                                                ?.filterParams
                                                ?.publishedDatePrefix ||
                                              viewHistory
                                                ?.filterParams
                                                ?.publishedDateIncludesNull ||
                                              viewHistory
                                                ?.filterParams
                                                ?.languages
                                                ?.length > 0 ||
                                              viewHistory
                                                ?.filterParams
                                                ?.contentCollection
                                                ?.length > 0 ||
                                              viewHistory
                                                ?.filterParams
                                                ?.contentSources
                                                ?.length > 0 ||
                                              viewHistory
                                                ?.filterParams
                                                ?.illustrationType
                                                ?.length > 0
                                            "
                                          >
                                            <label
                                              [class.arabic-style]="
                                                isArabicLanguage
                                              "
                                              *ngIf="
                                                viewHistory
                                                  ?.filterParams
                                                  ?.archive
                                                  ?.length > 0 ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.contentType
                                                  ?.length > 0 ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.docSubType
                                                  ?.length > 0 ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.publishedDateFrom >
                                                  0 ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.publishedDatePrefix ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.publishedDateIncludesNull ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.languages
                                                  ?.length > 0 ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.contentCollection
                                                  ?.length > 0 ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.contentSources
                                                  ?.length > 0 ||
                                                viewHistory
                                                  ?.filterParams
                                                  ?.illustrationType
                                                  ?.length > 0
                                              "
                                              class="searchBy col-sm-2 Query breakLabel"
                                            >
                                              {{
                                                "HomePage.limiters"
                                                  | translate
                                              }}
                                            </label>
                                            <ul
                                              class="col-sm-10 padding-0"
                                            >
                                              <li
                                                class="historyBatch col-sm-3"
                                                *ngIf="
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.archive
                                                    ?.length > 0
                                                "
                                              >
                                                <label
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  class="search-resultlbl"
                                                  >{{
                                                    "Titles.Archives"
                                                      | translate
                                                  }}</label
                                                >
                                                <label
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.archive
                                                      ?.length < 2
                                                  "
                                                >
                                                  <span
                                                    *ngFor="
                                                      let archive of viewHistory
                                                        ?.filterParams
                                                        ?.archive;
                                                      let i = index
                                                    "
                                                    class="margin-left5"
                                                  >
                                                    <span
                                                      *ngFor="
                                                        let archiveObj of headerContent?._wileyArchives;
                                                        let i = index
                                                      "
                                                      class="margin-left5"
                                                    >
                                                      <ng-container
                                                        *ngIf="
                                                          archive ===
                                                          archiveObj?.shortName
                                                        "
                                                      >
                                                        <span
                                                          class="badge"
                                                          style="
                                                            padding: 6px;
                                                          "
                                                          [attr.title]="
                                                            archiveObj?.name
                                                          "
                                                        >
                                                          {{
                                                            archiveObj?.name
                                                          }}
                                                        </span>
                                                      </ng-container>
                                                    </span>
          
                                                  
                                                    <span
                                                      *ngIf="
                                                        i !=
                                                        viewHistory
                                                          ?.filterParams
                                                          ?.archive
                                                          ?.length -
                                                          1
                                                      "
                                                      class="badge-operator"
                                                    >
                                                      {{
                                                        "AdvancedSearch.operators.OR.name"
                                                          | translate
                                                      }}
                                                    </span>
                                                  </span>
                                                </label>
                                                <button
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.archive
                                                      ?.length > 1
                                                  "
                                                  class="btn btn-default badge"
                                                  aria-hidden="true"
                                                  [outsideClick]="true"
                                                  [popover]="
                                                    archivepopOverTemplate
                                                  "
                                                  [placement]="'auto'"
                                                  triggers="click:click"
                                                >
                                                  <span
                                                    *ngFor="
                                                      let archiveObj of headerContent?._wileyArchives;
                                                      let i = index
                                                    "
                                                  >
                                                    <ng-container
                                                      *ngIf="
                                                        viewHistory
                                                          ?.filterParams
                                                          ?.archive[0] ===
                                                        archiveObj?.shortName
                                                      "
                                                    >
                                                      <span
                                                        class="select-qry"
                                                        [attr.title]="
                                                          archiveObj?.name
                                                        "
                                                      >
                                                        {{
                                                          archiveObj?.name
                                                        }}
                                                      </span>
                                                    </ng-container>
                                                  </span>
                                                  <span
                                                    class="search-resultcount"
                                                    >+{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.archive
                                                        ?.length - 1
                                                    }}
                                                    <i
                                                      class="fa fa-caret-down"
                                                    ></i>
                                                  </span>
                                                </button>
                                                <ng-template
                                                  #archivepopOverTemplate
                                                >
                                                  <ul
                                                    class="popoverUl"
                                                    *ngFor="
                                                      let archive of viewHistory
                                                        ?.filterParams
                                                        ?.archive;
                                                      let i = index
                                                    "
                                                    [attr.title]="
                                                      archive
                                                    "
                                                  >
                                                    <li>
                                                      <span
                                                        *ngFor="
                                                          let archiveObj of headerContent?._wileyArchives;
                                                          let i = index
                                                        "
                                                        class="margin-left5"
                                                      >
                                                        <ng-container
                                                          *ngIf="
                                                            archive ===
                                                            archiveObj?.shortName
                                                          "
                                                        >
                                                          <span
                                                            [attr.title]="
                                                              archiveObj?.name
                                                            "
                                                          >
                                                            {{
                                                              archiveObj?.name
                                                            }}
                                                          </span>
                                                        </ng-container>
                                                      </span>
          
                                                    </li>
                                                  </ul>
                                                </ng-template>
                                              </li>
          
                                              <li
                                                class="historyBatch col-sm-3"
                                                *ngIf="
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.contentType
                                                    ?.length > 0
                                                "
                                              >
                                                <label
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  class="search-resultlbl"
                                                  >{{
                                                    "HomePage.contentTypes"
                                                      | translate
                                                  }}</label
                                                >
                                                <label
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.contentType
                                                      ?.length < 2
                                                  "
                                                >
                                                  <span
                                                    *ngFor="
                                                      let contentType of viewHistory
                                                        ?.filterParams
                                                        ?.contentType;
                                                      let i = index
                                                    "
                                                    class="margin-left5"
                                                  >
                                                    <span
                                                      class="badge"
                                                      [attr.title]="
                                                        contentType
                                                      "
                                                      >{{
                                                        contentType
                                                      }}
                                                    </span>
                                                    <span
                                                      *ngIf="
                                                        i !=
                                                        viewHistory
                                                          ?.filterParams
                                                          ?.contentType
                                                          ?.length -
                                                          1
                                                      "
                                                      class="badge-operator"
                                                    >
                                                      {{
                                                        "AdvancedSearch.operators.OR.name"
                                                          | translate
                                                      }}
                                                    </span>
                                                  </span>
                                                </label>
                                                <button
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.contentType
                                                      ?.length > 1
                                                  "
                                                  class="btn btn-default badge"
                                                  aria-hidden="true"
                                                  [popover]="
                                                    contentTypepopOverTemplate
                                                  "
                                                  [placement]="'bottom'"
                                                  triggers="focus"
                                                >
                                                  <span
                                                    class="select-qry"
                                                    >{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.contentType[0]
                                                    }}</span
                                                  >
                                                  <span
                                                    class="search-resultcount"
                                                    >+{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.contentType
                                                        ?.length - 1
                                                    }}
                                                    <i
                                                      class="fa fa-caret-down"
                                                    ></i>
                                                  </span>
                                                </button>
                                                <ng-template
                                                  #contentTypepopOverTemplate
                                                >
                                                  <ul
                                                    class="popoverUl"
                                                    *ngFor="
                                                      let contentType of viewHistory
                                                        ?.filterParams
                                                        ?.contentType;
                                                      let i = index
                                                    "
                                                    [attr.title]="
                                                      contentType
                                                    "
                                                  >
                                                    <li>
                                                      <span
                                                        *ngIf="
                                                          i != 0
                                                        "
                                                        class="badge-operator"
                                                      >
                                                        {{
                                                          "AdvancedSearch.operators.OR.name"
                                                            | translate
                                                        }} </span
                                                      >{{
                                                        contentType
                                                      }}
                                                    </li>
                                                  </ul>
                                                </ng-template>
                                              </li>
                                              <li
                                                class="historyBatch col-sm-3"
                                                *ngIf="
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.docSubType
                                                    ?.length > 0
                                                "
                                              >
                                                <label
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  class="search-resultlbl"
                                                  >{{
                                                    "HomePage.contentSubtypes"
                                                      | translate
                                                  }}
                                                </label>
                                                <label
                                                  class="resultset-lbl"
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.docSubType
                                                      ?.length < 2
                                                  "
                                                >
                                                  <span
                                                    *ngFor="
                                                      let contentsubType of viewHistory
                                                        ?.filterParams
                                                        ?.docSubType;
                                                      let i = index
                                                    "
                                                    class="margin-left5"
                                                  >
                                                    <span
                                                      class="badge"
                                                      [attr.title]="
                                                        contentsubType
                                                      "
                                                      >{{
                                                        contentsubType
                                                      }}</span
                                                    >
                                                    <span
                                                      *ngIf="
                                                        i !=
                                                        viewHistory
                                                          ?.filterParams
                                                          ?.docSubType
                                                          ?.length -
                                                          1
                                                      "
                                                      class="badge-operator"
                                                    >
                                                      {{
                                                        "AdvancedSearch.operators.OR.name"
                                                          | translate
                                                      }}
                                                    </span>
                                                  </span>
                                                </label>
                                                <button
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.docSubType
                                                      ?.length > 1
                                                  "
                                                  class="btn btn-default badge"
                                                  aria-hidden="true"
                                                  triggers="focus"
                                                  [popover]="
                                                    contentsubTypepopOverTemplate
                                                  "
                                                  [placement]="'bottom'"
                                                >
                                                  <span
                                                    class="select-qry"
                                                    >{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.docSubType[0]
                                                    }}</span
                                                  >
                                                  <span
                                                    class="search-resultcount"
                                                    >+{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.docSubType
                                                        ?.length - 1
                                                    }}
                                                    <i
                                                      class="fa fa-caret-down"
                                                    ></i>
                                                  </span>
                                                </button>
                                                <ng-template
                                                  #contentsubTypepopOverTemplate
                                                >
                                                  <ul
                                                    class="popoverUl"
                                                    *ngFor="
                                                      let contentsubType of viewHistory
                                                        ?.filterParams
                                                        ?.docSubType;
                                                      let i = index
                                                    "
                                                  >
                                                    <li>
                                                      <span
                                                        *ngIf="
                                                          i != 0
                                                        "
                                                        class="badge-operator"
                                                      >
                                                        {{
                                                          "AdvancedSearch.operators.OR.name"
                                                            | translate
                                                        }} </span
                                                      >{{
                                                        contentsubType
                                                      }}
                                                    </li>
                                                  </ul>
                                                </ng-template>
                                              </li>
                                              <li
                                                class="historyBatch col-sm-3"
                                                *ngIf="
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.languages
                                                    ?.length > 0
                                                "
                                              >
                                                <label
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  class="search-resultlbl"
                                                  >{{
                                                    "HomePage.languages"
                                                      | translate
                                                  }}
                                                </label>
                                                <label
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.languages
                                                      ?.length < 2
                                                  "
                                                >
                                                  <span
                                                    *ngFor="
                                                      let language of viewHistory
                                                        ?.filterParams
                                                        ?.languages;
                                                      let i = index
                                                    "
                                                    class="margin-left5"
                                                  >
                                                    <span
                                                      class="badge"
                                                      [attr.title]="
                                                        language
                                                      "
                                                      >{{
                                                        language
                                                      }}</span
                                                    >
                                                  </span>
                                                </label>
                                                <button
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.languages
                                                      ?.length > 1
                                                  "
                                                  class="btn btn-default badge"
                                                  aria-hidden="true"
                                                  triggers="focus"
                                                  [popover]="
                                                    lanpopOverTemplate
                                                  "
                                                  [placement]="'bottom'"
                                                >
                                                  <span
                                                    class="select-qry"
                                                    >{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.languages[0]
                                                    }}</span
                                                  >
                                                  <span
                                                    class="search-resultcount"
                                                    >+{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.languages
                                                        ?.length - 1
                                                    }}
                                                    <i
                                                      class="fa fa-caret-down"
                                                    ></i>
                                                  </span>
                                                </button>
                                                <ng-template
                                                  #lanpopOverTemplate
                                                >
                                                  <ul
                                                    class="popoverUl"
                                                    *ngFor="
                                                      let language of viewHistory
                                                        ?.filterParams
                                                        ?.languages;
                                                      let i = index
                                                    "
                                                  >
                                                    <li>
                                                      <span
                                                        *ngIf="
                                                          i != 0
                                                        "
                                                        class="badge-operator"
                                                      >
                                                        {{
                                                          "AdvancedSearch.operators.OR.name"
                                                            | translate
                                                        }} </span
                                                      >{{
                                                        language
                                                      }}
                                                    </li>
                                                  </ul>
                                                </ng-template>
                                              </li>
                                              <li
                                                class="historyBatch col-sm-3"
                                                *ngIf="
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.contentCollection
                                                    ?.length > 0
                                                "
                                              >
                                                <label
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  class="search-resultlbl"
                                                  >{{
                                                    "HomePage.Collections"
                                                      | translate
                                                  }}
                                                </label>
                                                <label
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.contentCollection
                                                      ?.length < 2
                                                  "
                                                >
                                                  <span
                                                    *ngFor="
                                                      let collection of viewHistory
                                                        ?.filterParams
                                                        ?.contentCollection;
                                                      let i = index
                                                    "
                                                    class="margin-left5"
                                                  >
                                                    <span
                                                      class="badge"
                                                      [attr.title]="
                                                        collection
                                                      "
                                                      >{{
                                                        collection
                                                      }}</span
                                                    >
                                                    <span
                                                      *ngIf="
                                                        i !=
                                                        viewHistory
                                                          ?.filterParams
                                                          ?.contentCollection
                                                          ?.length -
                                                          1
                                                      "
                                                      class="badge-operator"
                                                    >
                                                      {{
                                                        "AdvancedSearch.operators.OR.name"
                                                          | translate
                                                      }}
                                                    </span>
                                                  </span>
                                                </label>
                                                <button
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.contentCollection
                                                      ?.length > 1
                                                  "
                                                  class="btn btn-default badge"
                                                  aria-hidden="true"
                                                  triggers="focus"
                                                  [popover]="
                                                    collectionsepopOverTemplate
                                                  "
                                                  [placement]="'bottom'"
                                                >
                                                  <span
                                                    class="select-qry"
                                                    >{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.contentCollection[0]
                                                    }}</span
                                                  >
                                                  <span
                                                    class="search-resultcount"
                                                    >+{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.contentCollection
                                                        ?.length - 1
                                                    }}
                                                    <i
                                                      class="fa fa-caret-down"
                                                    ></i>
                                                  </span>
                                                </button>
                                                <ng-template
                                                  #collectionsepopOverTemplate
                                                >
                                                  <ul
                                                    class="popoverUl"
                                                    *ngFor="
                                                      let contentCollection of viewHistory
                                                        ?.filterParams
                                                        ?.contentCollection;
                                                      let i = index
                                                    "
                                                  >
                                                    <li>
                                                      <span
                                                        *ngIf="
                                                          i != 0
                                                        "
                                                        class="badge-operator"
                                                      >
                                                        {{
                                                          "AdvancedSearch.operators.OR.name"
                                                            | translate
                                                        }} </span
                                                      >{{
                                                        contentCollection
                                                      }}
                                                    </li>
                                                  </ul>
                                                </ng-template>
                                              </li>
                                              <li
                                                class="historyBatch col-sm-3"
                                                *ngIf="
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.illustrationType
                                                    ?.length > 0
                                                "
                                              >
                                                <label
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  class="search-resultlbl"
                                                  >{{
                                                    "SearchResult.IllustrationType"
                                                      | translate
                                                  }}
                                                </label>
                                                <label
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.illustrationType
                                                      ?.length < 2
                                                  "
                                                >
                                                  <span
                                                    *ngFor="
                                                      let illustration of viewHistory
                                                        ?.filterParams
                                                        ?.illustrationType;
                                                      let i = index
                                                    "
                                                    class="margin-left5"
                                                  >
                                                    <span
                                                      class="badge"
                                                      [attr.title]="
                                                        illustration
                                                      "
                                                      >{{
                                                        illustration
                                                      }}</span
                                                    >
                                                    <span
                                                      *ngIf="
                                                        i !=
                                                        viewHistory
                                                          ?.filterParams
                                                          ?.illustrationType
                                                          ?.length -
                                                          1
                                                      "
                                                      class="badge-operator"
                                                    >
                                                      {{
                                                        "AdvancedSearch.operators.OR.name"
                                                          | translate
                                                      }}
                                                    </span>
                                                  </span>
                                                </label>
                                                <button
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.illustrationType
                                                      ?.length > 1
                                                  "
                                                  class="btn btn-default badge"
                                                  aria-hidden="true"
                                                  triggers="focus"
                                                  [popover]="
                                                    illustrationTypepopOverTemplate
                                                  "
                                                  [placement]="'bottom'"
                                                >
                                                  <span
                                                    class="select-qry"
                                                    >{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.illustrationType[0]
                                                    }}</span
                                                  >
                                                  <span
                                                    class="search-resultcount"
                                                    >+{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.illustrationType
                                                        ?.length - 1
                                                    }}
                                                    <i
                                                      class="fa fa-caret-down"
                                                    ></i>
                                                  </span>
                                                </button>
                                                <ng-template
                                                  #illustrationTypepopOverTemplate
                                                >
                                                  <ul
                                                    class="popoverUl"
                                                    *ngFor="
                                                      let illustrationType of viewHistory
                                                        ?.filterParams
                                                        ?.illustrationType;
                                                      let i = index
                                                    "
                                                  >
                                                    <li>
                                                      <span
                                                        *ngIf="
                                                          i != 0
                                                        "
                                                        class="badge-operator"
                                                      >
                                                        {{
                                                          "AdvancedSearch.operators.OR.name"
                                                            | translate
                                                        }} </span
                                                      >{{
                                                        illustrationType
                                                      }}
                                                    </li>
                                                  </ul>
                                                </ng-template>
                                              </li>
                                              <li
                                                class="historyBatch col-sm-3 padding-left-0"
                                                *ngIf="
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.contentSources
                                                    ?.length > 0
                                                "
                                              >
                                                <label
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  class="search-resultlbl"
                                                  >{{
                                                    "HomePage.Sources"
                                                      | translate
                                                  }}
                                                </label>
                                                <label
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.contentSources
                                                      ?.length < 2
                                                  "
                                                >
                                                  <span
                                                    *ngFor="
                                                      let source of viewHistory
                                                        ?.filterParams
                                                        ?.contentSources;
                                                      let i = index
                                                    "
                                                    class="margin-left5"
                                                  >
                                                    <span
                                                      class="badge"
                                                      [attr.title]="
                                                        source
                                                      "
                                                      >{{
                                                        source
                                                      }}</span
                                                    >
                                                  </span>
                                                </label>
                                                <button
                                                  *ngIf="
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.contentSources
                                                      ?.length > 1
                                                  "
                                                  class="btn btn-default badge"
                                                  aria-hidden="true"
                                                  triggers="focus"
                                                  [popover]="
                                                    sourcepopOverTemplate
                                                  "
                                                  [placement]="'bottom'"
                                                >
                                                  <span
                                                    class="select-qry"
                                                    >{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.contentSources[0]
                                                    }}</span
                                                  >
                                                  <span
                                                    class="search-resultcount"
                                                    >+{{
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.contentSources
                                                        ?.length - 1
                                                    }}
                                                    <i
                                                      class="fa fa-caret-down"
                                                    ></i>
                                                  </span>
                                                </button>
                                                <ng-template
                                                  #sourcepopOverTemplate
                                                >
                                                  <ul
                                                    class="popoverUl"
                                                    *ngFor="
                                                      let contentSources of viewHistory
                                                        ?.filterParams
                                                        ?.contentSources;
                                                      let i = index
                                                    "
                                                  >
                                                    <li>
                                                      <span
                                                        *ngIf="
                                                          i != 0
                                                        "
                                                        class="badge-operator"
                                                      >
                                                        {{
                                                          "AdvancedSearch.operators.OR.name"
                                                            | translate
                                                        }} </span
                                                      >{{
                                                        contentSources
                                                      }}
                                                    </li>
                                                  </ul>
                                                </ng-template>
                                              </li>
          
                                              
                                              <li
                                                class="historyBatch col-sm-3"
                                                *ngIf="
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.publishedDateFrom ||
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.publishedDateIncludesNull ||
                                                  viewHistory
                                                    ?.filterParams
                                                    ?.publishedDatePrefix
                                                "
                                                [ngClass]="{
                                                  publishedDate_result:
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.publishedDateTo ||
                                                    viewHistory
                                                      ?.filterParams
                                                      ?.publishedDateIncludesNull
                                                }"
                                              >
                                                <label
                                                  [class.arabic-style]="
                                                    isArabicLanguage
                                                  "
                                                  class="search-resultlbl"
                                                  >{{
                                                    "HomePage.publicationDate"
                                                      | translate
                                                  }}
                                                </label>
                                                <br />
                                                <span>
                                                  <label
                                                    *ngIf="
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.publishedDateFrom ||
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.publishedDateIncludesNull ||
                                                      viewHistory
                                                        ?.filterParams
                                                        ?.publishedDatePrefix
                                                    "
                                                  >
                                                    <span
                                                      class="badge margin-left5"
                                                    >
                                                      <span
                                                        *ngIf="
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.publishedDateFrom !==
                                                          'All'
                                                        "
                                                      >
                                                        {{
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.publishedDatePrefix
                                                        }}:</span
                                                      >
                                                      {{
                                                        viewHistory
                                                          ?.filterParams
                                                          ?.publishedDateFrom
                                                      }}
                                                      <span
                                                        [class.arabic-style]="
                                                          isArabicLanguage
                                                        "
                                                        *ngIf="
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.publishedDateTo
                                                        "
                                                        >{{
                                                          "Common.AND"
                                                            | translate
                                                        }}</span
                                                      >
                                                      {{
                                                        viewHistory
                                                          ?.filterParams
                                                          ?.publishedDateTo
                                                      }}
                                                      <span
                                                        *ngIf="
                                                          viewHistory
                                                            ?.filterParams
                                                            ?.publishedDateIncludesNull
                                                        "
                                                        >&nbsp;
                                                        <span
                                                          [class.arabic-style]="
                                                            isArabicLanguage
                                                          "
                                                          class="pub_date_operator"
                                                          >{{
                                                            "Common.AND"
                                                              | translate
                                                          }}</span
                                                        >&nbsp;
                                                        <span
                                                          [class.arabic-style]="
                                                            isArabicLanguage
                                                          "
                                                          class="pub_date"
                                                          >{{
                                                            "Common.UNDATED"
                                                              | translate
                                                          }}</span
                                                        >
                                                      </span>
                                                    </span>
                                                  </label>
                                                </span>
                                              </li>
                                            </ul>
                                          </ul>
                                        </ul>
                                        </td>
                                        <td [class.arabic-style]="isArabicLanguage" title="{{'HomePage.ToolsDropdown.runQuery'| translate}}">
                                          <a [class.arabic-style]=" isArabicLanguage" href="javascript:void(0)" title="{{'HomePage.ToolsDropdown.runQuery'| translate}}">
                                          <span [class.arabic-style]="isArabicLanguage" class="runClass" (click)=" runQuery(viewHistory)" >{{"HomePage.ToolsDropdown.runQuery"| translate }}</span>
                                        </a>
                                        <a [class.arabic-style]="isArabicLanguage" href="javascript:void(0)" title="Copy Query" >
                                          <span [class.arabic-style]="isArabicLanguage" class="copyClass" (click)="copyRunTemp(viewHistory)">Copy Query</span>
                                        </a>
                                        </td>
                                      </tr>
                                  </tbody>
                              </table>
                              </div>
                            </div>
                            </div>
                          </tab>
                          <tab heading="{{'HomePage.ToolsDropdown.documents' | translate}}" title="{{ 'HomePage.ToolsDropdown.documents'| translate }} ({{ controlShortcut }} + '4')" [class.arabic-style]="isArabicLanguage">
                            <div class="row">
                              <div class="col-md-12" *ngIf="documentHistory?.length > 0">
                                <div class="table-responsive historyScroll">
                                  <table class="table table-hover history_table table-bordered">
                                    <thead class="table-light sticky-top">
                                      <tr>
                                        <th (click)="sort('date')" [class.arabic-style]="isArabicLanguage">{{"HomePage.ToolsDropdown.date" | translate}}</th>
                                        <th [class.arabic-style]="isArabicLanguage">{{"HomePage.ToolsDropdown.documentTitle" | translate }}</th>
                                        <th [class.arabic-style]="isArabicLanguage"> {{"HomePage.ToolsDropdown.action" | translate }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let viewDoc of documentHistory">
                                        <td>{{viewDoc?.date | date : "short"}}</td>
                                        <td title="{{ viewDoc?.title }}"> {{ viewDoc?.title }}</td>
                                        <td [class.arabic-style]="isArabicLanguage" title="{{'HomePage.ToolsDropdown.viewDocument' | translate}}">
                                          <span [class.arabic-style]="isArabicLanguage" (click)="runHistoryDoc(viewDoc)">
                                            {{ "HomePage.ToolsDropdown.viewDocument"| translate }}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <span class="empty_doc_list" [class.arabic-style]="isArabicLanguage" *ngIf="documentHistory?.length == 0">
                                  {{"HomePage.ToolsDropdown.noDocumentsInHistory"| translate}}
                                </span>
                              </div>
                            </div>
                          </tab>
                        </tabset>
                      </div>
                      <div class="col-md-12" *ngIf="viewHistoryList?.length > 0 || documentHistory?.length > 0 ">
                        <button class="btn btn-primary export-btn" [class.arabic-style]="isArabicLanguage" (click)="clearOpenModal($event)" >
                        {{"HomePage.ToolsDropdown.clearHistory"| translate }}
                        </button>
                      </div>
                      <div class="col-md-12">
                        <span class="empty_list" [class.arabic-style]="isArabicLanguage" *ngIf="(viewHistoryList?.length == null || viewHistoryList?.length == 0 || viewHistoryList?.length == undefined) &&(documentHistory?.length == null ||
                            documentHistory?.length == 0 || documentHistory?.length == undefined)"> {{ "HomePage.ToolsDropdown.noDocumentsInHistory"| translate}}</span>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </div>
              <div class="col-md-12 col-sm-12 full-width viewlist-listcontainerinner feedbacks p-0"
                [class.arabic-style]="isArabicLanguage"
                [ngClass]="{ noHistory: !isFeedbackOpen }">
                <li
                  tabindex="0"
                  id="Feedback"
                  title="{{
                    'HomePage.ToolsDropdown.feedback' | translate
                  }} ({{ controlShortcut }} + C)"
                  class="search-resultli"
                  [outsideClick]="true"
                  #feedbackpop="bs-popover"
                  [popover]="feedbackRef"
                  [placement]="'left'"
                  (click)="hide('feedback', $event)"
                  (keyup.enter)="
                    feedbackpop.show(); hide('feedback', $event)
                  "
                >
                  <span class="fa fa-chevron-left"></span>
                  <span class="spriteico FeedbackIcon"></span>
                  <label
                    class="bm-page"
                    [class.arabic-style]="isArabicLanguage"
                    >{{
                      "HomePage.ToolsDropdown.feedback" | translate
                    }}</label
                  >
                  <ng-template #feedbackRef>
                    <div class="feedback-scroll" #container>
                     <div class="row">
                      <div class="col-md-12 feeback-container">
                        <form class="feddForm"
                          [formGroup]="feedback"
                          (ngSubmit)="onSubmit(feedback.value)"
                          novalidate>
                          <h4
                            class="feedbackHeader"
                            [class.arabic-style]="isArabicLanguage">
                            {{ "HomePage.ToolsDropdown.feedback" | translate}}
                          </h4>
                          <p
                            class="feedback-des"
                            [class.arabic-style]="isArabicLanguage" >
                            {{"HomePage.ToolsDropdown.feedbackInfo"| translate }}
                          </p>
                          <div class="feedback-form form-group">
                            <label
                              class="control-label"
                              for="name"
                              [class.arabic-style]="isArabicLanguage">
                              {{"HomePage.ToolsDropdown.yourName" | translate}}:</label >
                            <div class="">
                              <input
                                aria-label="Your Name"
                                [class.arabic-style]="isArabicLanguage"
                                title="{{
                                  'HomePage.ToolsDropdown.yourName'
                                    | translate
                                }}"
                                type="text"
                                class="form-control"
                                id="name"
                                placeholder=""
                                name="yourname"
                                formControlName="name" />
                            </div>
                          </div>
                          <div class="feedback-form form-group">
                            <label
                              class="control-label"
                              for="email"
                              [class.arabic-style]="isArabicLanguage"
                              >{{
                                "HomePage.ToolsDropdown.yourEmail"
                                  | translate
                              }}:</label
                            >
                            <div class="">
                              <input
                                aria-label="Your Email"
                                [class.arabic-style]="isArabicLanguage"
                                title="{{
                                  'HomePage.ToolsDropdown.yourEmail'
                                    | translate
                                }}"
                                type="email"
                                class="form-control"
                                id="email"
                                placeholder=""
                                name="youremail"
                                [email]="
                                  sendMailData.mail != '' &&
                                  sendMailData.mail != null
                                "
                                formControlName="mail"
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                              />
                              <div
                                class="error text-danger"
                                [class.arabic-style]="isArabicLanguage"
                                *ngIf="
                                  feedback.get('mail').dirty &&
                                  feedback.get('mail').invalid
                                "
                              >
                                {{
                                  "HomePage.ToolsDropdown.invalidEmail"
                                    | translate
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="feedback-form form-group">
                            <label
                              for="Topic"
                              [class.arabic-style]="isArabicLanguage"
                            >
                              {{
                                "HomePage.ToolsDropdown.topic" | translate
                              }}:
                              <!-- <span
                                class="glyphicon glyphicon-asterisk"
                              ></span> -->
                              <i class="fa fa-asterisk" aria-hidden="true"></i>
                            </label>
                            <select
                              [class.arabic-style]="isArabicLanguage"
                              title="{{
                                'HomePage.ToolsDropdown.topic' | translate
                              }}"
                              name="feedback"
                              class="form-select feedbackSelect"
                              id="Topic"
                              formControlName="topic"
                            >
                              <option
                                [class.arabic-style]="isArabicLanguage"
                                value=""
                                selected
                              >
                                {{
                                  "HomePage.ToolsDropdown.selectTopic"
                                    | translate
                                }}
                              </option>
                              <option
                                [class.arabic-style]="isArabicLanguage"
                                value="Compliments"
                              >
                                {{
                                  "HomePage.ToolsDropdown.Compliments"
                                    | translate
                                }}
                              </option>
                              <option
                                [class.arabic-style]="isArabicLanguage"
                                value="Issues"
                              >
                                {{
                                  "HomePage.ToolsDropdown.Issues"
                                    | translate
                                }}
                              </option>
                              <option
                                [class.arabic-style]="isArabicLanguage"
                                value="Feature Requests"
                              >
                                {{
                                  "HomePage.ToolsDropdown.featureRequests"
                                    | translate
                                }}
                              </option>
                              <option
                                [class.arabic-style]="isArabicLanguage"
                                value="Other"
                              >
                                {{
                                  "HomePage.ToolsDropdown.Other" | translate
                                }}
                              </option>
                            </select>
                            <div
                              [class.arabic-style]="isArabicLanguage"
                              class="error text-danger"
                              *ngIf="
                                feedback
                                  .get('topic')
                                  .hasError('required') &&
                                feedback.get('topic').touched
                              "
                            >
                              {{
                                "HomePage.ToolsDropdown.topicRequired"
                                  | translate
                              }}
                            </div>
                          </div>
                          <div
                            [class.arabic-style]="isArabicLanguage"
                            class="feedback-form form-group"
                          >
                            <label for="Message">
                              {{
                                "HomePage.ToolsDropdown.Message" | translate
                              }}
                              <!-- <span
                                class="glyphicon glyphicon-asterisk"
                              ></span> -->
                              <i class="fa fa-asterisk" aria-hidden="true"></i>
                            </label>
                            <textarea
                              [class.arabic-style]="isArabicLanguage"
                              aria-label="Message"
                              title="{{
                                'HomePage.ToolsDropdown.Message' | translate
                              }}"
                              name="message"
                              id="Message"
                              cols="30"
                              rows="8"
                              class="form-control feedbackSelect"
                              formControlName="message"
                            ></textarea>
                            <div
                              [class.arabic-style]="isArabicLanguage"
                              class="error text-danger"
                              *ngIf="
                                feedback
                                  .get('message')
                                  .hasError('required') &&
                                feedback.get('message').touched
                              "
                            >
                              {{
                                "HomePage.ToolsDropdown.messageRequired"
                                  | translate
                              }}
                            </div>
                            <div
                              [class.arabic-style]="isArabicLanguage"
                              class="error text-danger"
                              *ngIf="
                                feedback.get('message').invalid &&
                                feedback.get('message').touched
                              "
                            >
                              {{
                                "HomePage.ToolsDropdown.enterMessage"
                                  | translate
                              }}
                            </div>
                          </div>
                        
                          <div
                            class="form-group pull-right"
                            [tooltip]="feedbackTooltip"
                            placement="left"
                          >
                            <button
                              [class.arabic-style]="isArabicLanguage"
                              title="{{
                                'HomePage.ToolsDropdown.submit' | translate
                              }}"
                              type="submit"
                              class="btn btn-primary btn-sm"
                              (click)="sendMail(message)"
                              [disabled]="feedback.invalid"
                            >
                              {{
                                "HomePage.ToolsDropdown.submit" | translate
                              }}
                            </button>
                            <ng-template
                              #feedbackTooltip
                              [ngClass]="{
                                show: feedback.invalid,
                                hide: feedback.valid
                              }"
                            >
                              <div
                                [class.arabic-style]="isArabicLanguage"
                                class="error text-danger"
                                *ngIf="
                                  feedback.get('topic').untouched ||
                                  feedback.get('topic').invalid
                                "
                              >
                                {{
                                  "HomePage.ToolsDropdown.selectTopictoSubmit"
                                    | translate
                                }}
                              </div>
                              <div
                                class="error text-danger"
                                [hidden]="feedback.get('topic').invalid"
                              >
                                <div
                                  [class.arabic-style]="isArabicLanguage"
                                  class="error text-danger"
                                  *ngIf="
                                    feedback.get('message').invalid ||
                                    feedback.get('message').untouched
                                  "
                                >
                                  {{
                                    "HomePage.ToolsDropdown.enterMessagetoSubmit"
                                      | translate
                                  }}
                                </div>
                              </div>
                              <div
                                class="error text-danger"
                                [hidden]="
                                  feedback.get('message').invalid ||
                                  feedback.get('topic').invalid
                                "
                              >
                                <div
                                  [class.arabic-style]="isArabicLanguage"
                                  class="error text-danger"
                                  *ngIf="feedback.get('mail').invalid"
                                >
                                  {{
                                    "HomePage.ToolsDropdown.enterValidMail"
                                      | translate
                                  }}
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </form>
                      </div>
                     </div>
                    </div>
                  </ng-template>
                </li>
              </div>
              <div *ngIf="showDCIcon" class="col-md-12 col-sm-12 full-width viewlist-listcontainerinner helpguide p-0"
                [ngClass]="{ helpActive: isDownloadContentOpen }">
                <li
                  tabindex="0"
                  id="Dc"
                  [class.arabic-style]="isArabicLanguage"
                  title="{{
                    'HomePage.ToolsDropdown.downContent' | translate
                  }} ({{ controlShortcut }} + /)"
                  class="search-resultli helpClass"
                  (click)="navToDCPage()"
                  (keyup.enter)="navToDCPage()">
                  <span class="spriteico downcontentIcon"></span>
                  <label
                    class="bm-page"
                    [class.arabic-style]="isArabicLanguage"
                    >{{
                      "HomePage.ToolsDropdown.downContent" | translate
                    }}</label
                  >
                </li>
              </div>
              <div class="col-md-12 col-sm-12 full-width viewlist-listcontainerinner helpguide p-0"
                [ngClass]="{ helpActive: isHelpOpen, noHelp: !isHelpOpen }" >
                <li
                  tabindex="0"
                  id="Help"
                  [class.arabic-style]="isArabicLanguage"
                  title="{{
                    'HomePage.ToolsDropdown.helpAndTraining' | translate
                  }} ({{ controlShortcut }} + /)"
                  class="search-resultli helpClass"
                  [outsideClick]="true"
                  #helpLink="bs-popover"
                  [popover]="helpLinks"
                  [placement]="'left'"
                  (click)="hide('helpLink', $event); gotoHelpPage()"
                  (keyup.enter)="
                    helpLink.show();
                    hide('helpLink', $event);
                    gotoHelpPage()
                  "
                >
                  <span class="spriteico helpIcon"></span>
                  <label
                    class="bm-page"
                    [class.arabic-style]="isArabicLanguage"
                    >{{
                      "HomePage.ToolsDropdown.helpAndTraining" | translate
                    }}</label
                  >
                  </li>
              </div>
              <div class="col-md-12 col-sm-12 full-width viewlist-listcontainerinner p-0"
                (click)="hide('endSession', $event)"
                [ngClass]="{ sessionActive: isEndSessionOpen, sessionInActive: !isEndSessionOpen}">
                <li
                  tabindex="0"
                  [class.arabic-style]="isArabicLanguage"
                  title="{{
                    'HomePage.ToolsDropdown.endSession' | translate
                  }} ({{ shiftControl }} + L)"
                  class="search-resultli helpClass endSessionClass"
                  (click)="endSessionConfirmation($event)"
                  (keyup.enter)="endSessionConfirmation($event)"
                >
                  <span class="spriteico endSessionIcon"></span>
                  <label
                    class="bm-page"
                    [class.arabic-style]="isArabicLanguage"
                    >{{
                      "HomePage.ToolsDropdown.endSession" | translate
                    }}</label
                  >
                </li>
              </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li>
        <span
          #connectionIndicator
          class="spriteico signal-icon high-singal-icon"
          [class.arabic-style]="isArabicLanguage"
          (mouseenter)="connectionMouseEnter()"
          (mouseleave)="connectionMouseLeave()"
          (click)="downloadImageFile()"
        ></span>
        <ng-container>
          <div
            class="connection-indicator-div"
            *ngIf="showConncetionStatus"
          >
            <p class="connection-heading">
              <b> {{ "HomePage.yourConnectionSpeed" | translate }} </b>
            </p>
            <div class="connection-child">
              <span class="spriteico green-icon"></span>
              <span class="connection-text">
                - {{ "HomePage.excellent" | translate }}</span
              >
            </div>
            <div class="connection-child">
              <span class="spriteico red-icon"></span>
              <span class="connection-text">
                - {{ "HomePage.good" | translate }}</span
              >
            </div>
            <div class="connection-child">
              <span class="spriteico slow-icon"></span>
              <span class="connection-text">
                - {{ "HomePage.slow" | translate }}</span
              >
            </div>
            <div class="connection-child">
              <span class="spriteico gray-icon"></span>
              <span class="connection-text">
                - {{ "HomePage.noSignal" | translate }}</span
              >
            </div>
          </div>
        </ng-container>
      </li>
      <div
        *ngIf="toggleArrow"
        class="spinner fa fa-arrow-up arrowForTools"
        [ngClass]="{
          toggleArrowShow: toggleArrow,
          toggleArrowHide: !toggleArrow,
          ieBrowserEnabled: ieBrowserEnabled
        }"
      ></div>
        </ul>
      </div>
    </div>
  </nav>
  <div class="gradient-bg"></div>
</header>
<ng-template #wileyArchiveOption>
  <div class="row">
    <div class="col-md-5 col-sm-4">
      <img
        class="center-block pull-left img-responsive img-fluid mx-auto d-block"
        [class.arabic-style]="isArabicLanguage"
        alt="{{ 'Alt.archiveIcon' | translate }}"
        src="assets/images/archive/u45.png"
      />
    </div>
    <div class="col-md-7 col-sm-8 padding-left-0">
      <p class="archive-content"></p>
    </div>
  </div>
</ng-template>
<ng-template #listcountPopover>
  <div id="editor"></div>
  <tabset #documentDetailsTab>
    <tab
      id="DocumentsList"
      [customClass]="'docviewer-tabs'"
      heading=""
      tabindex="0"
    >
      <ng-template tabHeading>
        <span
          [class.arabic-style]="isArabicLanguage"
          [class.arabic-style]="isArabicLanguage"
          title="{{ 'HomePage.ToolsDropdown.documentsList' | translate }}  ({{
            controlShortcut
          }} + '1')"
          >{{ "HomePage.ToolsDropdown.documentsList" | translate }} ({{
            viewListCount
          }})</span
        >
      </ng-template>
      <div class="row">
        <br />

        <div id="tempID" class="col-md-12 viewlist-container"
          *ngIf="getJSONKeys(viewList)?.length > 0" >
          <div class="viewlist-containertitle viewlist-containertitle-header">
            <h4
              class="viewlist-title list-title"
              [class.arabic-style]="isArabicLanguage"
            >
              {{ "HomePage.ToolsDropdown.yourLists" | translate }}
              <span
                class="jumpTo print-hide"
                [class.arabic-style]="isArabicLanguage"
                >{{ "HomePage.ToolsDropdown.filter" | translate }}</span
              >
            </h4>

            <select
              class="form-select list-select print-hide"
              id="sel1"
              [class.arabic-style]="isArabicLanguage"
              [(ngModel)]="selectedContentType"
              (change)="selectViewList()"
            >
              <option
                [class.arabic-style]="isArabicLanguage"
                value="All"
                selected
              >
                {{ "HomePage.ToolsDropdown.ALL" | translate }}
              </option>
             
              <option *ngFor="let contentType of getJSONKeys(viewList)">
                {{ contentType | titlecase }}
              </option>
            </select>
          </div>
          <div class="viewlist-container-parent">
            <div class="viewlist-container-parent-scroll" #container>
            
              <div
                class="viewlist-container-inner"
                *ngFor="let contentType of getJSONKeys(selectedViewList)"
              >
                <div class="viewlist-containertitle content_type_title">
                  <h4 class="">{{ contentType | titlecase }}</h4>
                </div>
                <div class="row marVal">
                <div class="col-sm-12 viewlist-container-content" id="singleDoc{{ j }}"    
                  *ngFor="let content of getJSONKeys(selectedViewList[contentType]); let j = index" >
                  <div class="row">
                    <div class="col-md-3 col-sm-3 col-lg-2 viewlist-container-thumbnail">
                      <a
                        *ngIf="
                          archivesWithShowFolderImage.includes(
                            selectedViewList[contentType][content]?.archive
                          ) == false
                        "
                        role="link"
                        title="{{
                          selectedViewList[contentType][content].docTitle
                        }}"
                        class="thumbnail-image-link-hide"
                      >
                        <img
                          [class.arabic-style]="isArabicLanguage"
                          src="{{
                            selectedViewList[contentType][content]?.thumbnails
                              ?.length == 1
                              ? selectedViewList[contentType][content]?.thumbnails
                                  ?.length > 1
                                ? selectedViewList[contentType][content]
                                    ?.thumbnails[1]
                                : selectedViewList[contentType][content]
                                    ?.thumbnails[0]
                              : contentType === 'Manuscripts' &&
                                headerContent.hasFolderImage
                              ? selectedViewList[contentType][content]
                                  ?.thumbnails[1]
                                ? selectedViewList[contentType][content]
                                    ?.thumbnails[1]
                                : noImageUrl
                              : selectedViewList[contentType][content]
                                  ?.thumbnails[1]
                              ? selectedViewList[contentType][content]
                                  ?.thumbnails[1]
                              : noImageUrl
                          }}"
                          alt="{{ 'Alt.thumbNailImageforViewList' | translate }}"
                          class="img-responsive img-fluid view-list-img"
                        />
                      </a>
                      <a
                        *ngIf="
                          archivesWithShowFolderImage.includes(
                            selectedViewList[contentType][content]?.archive
                          ) == true
                        "
                        role="link"
                        title="{{
                          selectedViewList[contentType][content].docTitle
                        }}"
                        class="thumbnail-image-link-hide"
                      >
                        <img
                          [class.arabic-style]="isArabicLanguage"
                          src="{{
                            selectedViewList[contentType][content]?.thumbnails
                              ?.length == 1
                              ? selectedViewList[contentType][content]?.thumbnails
                                  ?.length > 1
                                ? selectedViewList[contentType][content]
                                    ?.thumbnails[0]
                                : selectedViewList[contentType][content]
                                    ?.thumbnails[0]
                              : contentType === 'Manuscripts' &&
                                headerContent.hasFolderImage
                              ? selectedViewList[contentType][content]
                                  ?.thumbnails[0]
                                ? selectedViewList[contentType][content]
                                    ?.thumbnails[0]
                                : noImageUrl
                              : selectedViewList[contentType][content]
                                  ?.thumbnails[1]
                              ? selectedViewList[contentType][content]
                                  ?.thumbnails[0]
                              : noImageUrl
                          }}"
                          alt="{{ 'Alt.thumbNailImageforViewList' | translate }}"
                          class="img-responsive img-fluid"
                        />
                      </a>

                      <img
                        *ngIf="
                          archivesWithShowFolderImage.includes(
                            selectedViewList[contentType][content]?.archive
                          ) == false
                        "
                        [class.arabic-style]="isArabicLanguage"
                        src="{{
                          selectedViewList[contentType][content]?.thumbnails
                            ?.length == 1
                            ? selectedViewList[contentType][content]?.thumbnails
                                ?.length > 1
                              ? selectedViewList[contentType][content]
                                  ?.thumbnails[1]
                              : selectedViewList[contentType][content]
                                  ?.thumbnails[0]
                            : contentType === 'Manuscripts' &&
                              headerContent.hasFolderImage
                            ? selectedViewList[contentType][content]
                                ?.thumbnails[1]
                              ? selectedViewList[contentType][content]
                                  ?.thumbnails[1]
                              : noImageUrl
                            : selectedViewList[contentType][content]
                                ?.thumbnails[0]
                            ? selectedViewList[contentType][content]
                                ?.thumbnails[1]
                            : noImageUrl
                        }}"
                        alt="{{ 'Alt.thumbNailImageforViewList' | translate }}"
                        class="img-responsive img-fluid print-hide view-list-img"
                      />

                      <img
                        *ngIf="
                          archivesWithShowFolderImage.includes(
                            selectedViewList[contentType][content]?.archive
                          ) == true
                        "
                        [class.arabic-style]="isArabicLanguage"
                        src="{{
                          selectedViewList[contentType][content]?.thumbnails
                            ?.length == 1
                            ? selectedViewList[contentType][content]?.thumbnails
                                ?.length > 1
                              ? selectedViewList[contentType][content]
                                  ?.thumbnails[0]
                              : selectedViewList[contentType][content]
                                  ?.thumbnails[0]
                            : contentType === 'Manuscripts' &&
                              headerContent.hasFolderImage
                            ? selectedViewList[contentType][content]
                                ?.thumbnails[1]
                              ? selectedViewList[contentType][content]
                                  ?.thumbnails[0]
                              : noImageUrl
                            : selectedViewList[contentType][content]
                                ?.thumbnails[0]
                            ? selectedViewList[contentType][content]
                                ?.thumbnails[0]
                            : noImageUrl
                        }}"
                        alt="{{ 'Alt.thumbNailImageforViewList' | translate }}"
                        class="img-responsive img-fluid print-hide view-list-img"
                      />
                    </div>
                    <div class="col-md-8 col-sm-8 col-lg-9 viewlist-content-div">
                      <div class="col-sm-12" class="print-hide mt-documentview">
                        <h4
                          class="search-result-header-title view_doc_list"
                          role="link"
                        >
                          <a
                            appDecodeEntities
                            role="link"
                            title="{{
                              selectedViewList[contentType][content].docTitle
                            }}"
                            (click)="
                              goToDocumentDetailPage(
                                selectedViewList[contentType][content]['docID'],
                                selectedViewList[contentType][content]?.index
                              )
                            "
                            >{{
                              selectedViewList[contentType][content].docTitle
                            }}</a
                          >
                        </h4>
                        <span
                          class="content-ciation"
                          [innerHtml]="
                            selectedViewList[contentType][content].citation
                          "
                        ></span>
                      </div>
                      <div
                        class="col-md-12"
                        class="thumbnail-image-link-hide search-result-parent-div"
                      >
                        <h4
                          class="search-result-header-title thumbnail-image-link-hide"
                          role="link"
                        >
                          <a
                            role="link"
                            class="thumbnail-image-link-hide"
                            href="{{ currentOrigin }}{{ homePageUrl }}/detail/{{
                              selectedViewList[contentType][content].docID
                            }}"
                            title="{{
                              selectedViewList[contentType][content].docTitle
                            }}"
                            >{{
                              selectedViewList[contentType][content].docTitle
                            }}</a
                          >
                        </h4>
                        <div class="search-result-citation">
                          <span
                            [innerHtml]="
                              selectedViewList[contentType][content].citation
                            "
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-sm-2">
                      <i [class.arabic-style]="isArabicLanguage" class="fa fa-trash"
                        title="{{'HomePage.ToolsDropdown.removeDocument' | translate}}"
                        (click)="deleteViewlistConfirmation( $event,selectedViewList[contentType][content],contentType)"
                         aria-hidden="true"></i>
                      <!-- <a role="link" > -->
                        <i class="fa fa-share-alt" [class.arabic-style]="isArabicLanguage"
                          title="Export Document" (click)="exportSingleDocument(j)"></i>
                      <!-- </a> -->
                    </div>
                    
                    <ng-template #viewListTemplate>
                      <div id="viewListPopup">
                        <div class="modal-header" (click)="insideClick($event)">
                          <h4 class="modal-title sa-icon sa-warning pulseWarning">
                            <span class="sa-body pulseWarningIns"></span>
                            <span class="sa-dot pulseWarningIns"></span>
                          </h4>
                        </div>
                        <div class="modal-body search_body">
                          <div
                            [class.arabic-style]="isArabicLanguage"
                            class="alert_warning"
                          >
                            {{
                              "HomePage.ToolsDropdown.wouldYouDelete" | translate
                            }}
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            [class.arabic-style]="isArabicLanguage"
                            type="button"
                            class="btn btn-primary"
                            aria-label="Close"
                            (click)="deleteItem()"
                          >
                            {{ "HomePage.ToolsDropdown.Yes" | translate }}
                          </button>
                          <button
                            [class.arabic-style]="isArabicLanguage"
                            type="button"
                            class="btn-default btn"
                            (click)="viewlistCloseModal()"
                          >
                            {{ "HomePage.ToolsDropdown.No" | translate }}
                          </button>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #removeListTemplate>
                      <div id="viewListPopup">
                        <div
                          class="modal-header"
                          (click)="insideClick($event)"
                          tabindex="1"
                        >
                          <h4 class="modal-title sa-icon sa-warning pulseWarning">
                            <span class="sa-body pulseWarningIns"></span>
                            <span class="sa-dot pulseWarningIns"></span>
                          </h4>
                        </div>
                        <div class="modal-body search_body">
                          <div
                            [class.arabic-style]="isArabicLanguage"
                            class="alert_warning"
                          >
                            {{
                              "HomePage.ToolsDropdown.wouldYouDeleteAllList"
                                | translate
                            }}
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            [class.arabic-style]="isArabicLanguage"
                            type="button"
                            class="btn btn-primary"
                            aria-label="Close"
                            (click)="clearAllDocumentList()"
                            tabindex="1"
                          >
                            {{ "HomePage.ToolsDropdown.deleteAll" | translate }}
                          </button>
                          <button
                            [class.arabic-style]="isArabicLanguage"
                            type="button"
                            class="btn-default btn"
                            (click)="removeListCloseModal()"
                          >
                            {{ "HomePage.ToolsDropdown.cancel" | translate }}
                          </button>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div class="viewlist-container-footer">
            <button
              [class.arabic-style]="isArabicLanguage"
              class="btn btn-primary clearall-btn print-hide"
              (click)="clearAllLists()"
              title="{{ 'HomePage.ToolsDropdown.clearAll' | translate }}"
            >
              {{ "HomePage.ToolsDropdown.clearAll" | translate }}
            </button>
            <button
              [class.arabic-style]="isArabicLanguage"
              class="btn btn-primary export-btn print-hide"
              (click)="exportPDF()"
              title="{{ 'HomePage.ToolsDropdown.Export' | translate }}"
            >
              Export All
            </button>
          </div>
        </div>
        <p [class.arabic-style]="isArabicLanguage"
          class="no-document-message"
          *ngIf="getJSONKeys(viewList)?.length == 0">
          {{ "HomePage.ToolsDropdown.noDocuments" | translate }}
        </p>
      </div>
    </tab>
    <tab
      id="CropImageList"
      [customClass]="'docviewer-tabs'"
      heading=""
      tabindex="0"
    >
      <ng-template tabHeading>
        <span
          [class.arabic-style]="isArabicLanguage"
          title="{{ 'HomePage.ToolsDropdown.clipImageList' | translate }}  ({{
            controlShortcut
          }} + '2')"
          >{{ "HomePage.ToolsDropdown.clipImageList" | translate }} ({{
            clippedImageList?.length || 0
          }})</span
        >
      </ng-template>
      <div class="row">
        <br />
        <p
          [class.arabic-style]="isArabicLanguage"
          class="no-document-message"
          *ngIf="getJSONKeys(clippedImageList)?.length == 0"
        >
          {{ "HomePage.ToolsDropdown.noCroppedImageList" | translate }}
        </p>
        <div
          class="col-md-12"
          id="clipImages"
          *ngIf="getJSONKeys(clippedImageList)?.length > 0"
        >
          <div class="viewlist-container-parent">
            <div class="viewlist-container-parent-scroll" #container>
             
              <div
                class="viewlist-containertitle viewlist-containertitle-header viewlist-container-border"
              >
                <h4
                  [class.arabic-style]="isArabicLanguage"
                  class="viewlist-title list-title"
                >
                  {{ "HomePage.ToolsDropdown.clipImageList" | translate }}
                </h4>
              </div>
              <div class="viewlist-container-inner">
               
                <div
                  id="singleImage{{ i }}"
                  class="col-sm-12 viewlist-container-content viewlist-clipimage-content"
                  *ngFor="let clippedImage of clippedImageList; let i = index"
                >
                  <div
                    class="col-md-3 col-sm-3 col-lg-2 viewlist-container-thumbnail"
                    (click)="openClipedImage(clippedImage.image, i)"
                  >
                    <a
                      [class.arabic-style]="isArabicLanguage"
                      role="link"
                      title="{{ clippedImage.imageName }}"
                      class="thumbnail-image-link-hide clip-image-link"
                    >
                      <img
                        src="{{ clippedImage.image }}"
                        alt="{{ 'Alt.thumbNailImageforViewList' | translate }}"
                        class="img-responsive img-fluid clip-image-center"
                      />
                    </a>
                    <img
                      [class.arabic-style]="isArabicLanguage"
                      src="{{ clippedImage.image }}"
                      alt="{{ 'Alt.thumbNailImageforViewList' | translate }}"
                      class="clip-image-link img-responsive img-fluid print-hide clip-image-center"
                    />
                  </div>
                  <div class="col-md-9 col-sm-8 col-lg-9 viewlist-content-div">
                    <div class="col-sm-12" class="mt-clipped-image">
                      <h4 class="search-result-header-title" role="link">
                        <a
                          [class.arabic-style]="isArabicLanguage"
                          role="link"
                          class="clipped-image-title"
                          title="{{ clippedImage.imageName }}"
                          (click)="openClipedImage(clippedImage.image, i)"
                          >{{ clippedImage.imageName }}
                          <span
                            [class.arabic-style]="isArabicLanguage"
                            class="image-cource-span"
                          >
                            - {{ "HomePage.clippedFrom" | translate }}
                          </span>
                        </a>
                        <a
                          role="link"
                          class="clipimage-imagesource clippedimage-source"
                          href="{{ currentOrigin }}{{
                            clippedImage.originalDocUrl
                          }}"
                        >
                          <span
                            title="{{ clippedImage.imageSource }}"
                            appDecodeEntities
                          >
                            - {{ clippedImage.imageSource }}</span
                          >
                        </a>
                      </h4>
                      <p class="source-citation">
                        <span [innerHtml]="clippedImage?.imageCitation"></span>
                      </p>
                      <h4
                        class="search-result-header-title"
                        *ngIf="clippedImage?.imageNotes"
                      >
                        <p
                          class="image-notes"
                          [class.arabic-style]="isArabicLanguage"
                          title="{{ clippedImage.imageNotes }}"
                        >
                          <label
                            class="image-notes-header"
                            [class.arabic-style]="isArabicLanguage"
                          >
                            <b
                              >{{
                                "HomePage.ToolsDropdown.clipImageNotes"
                                  | translate
                              }}:&nbsp;
                            </b> </label
                          >{{ clippedImage.imageNotes }}
                        </p>
                      </h4>
                    </div>
                  </div>
                  <div class="col-md-1 col-sm-2">
                    <i
                      class="fa fa-trash"
                      [class.arabic-style]="isArabicLanguage"
                      title="{{
                        'HomePage.ToolsDropdown.removeClippedImage' | translate
                      }}"
                      (click)="removeCroppedImage(i)"
                      aria-hidden="true"
                    ></i>
                    <a
                      role="link"
                      (click)="openClipedImage(clippedImage.image, i)"
                    >
                      <i
                        class="fa fa-eye view-document"
                        [class.arabic-style]="isArabicLanguage"
                        title="{{
                          'HomePage.ToolsDropdown.preview' | translate
                        }}"
                      ></i>
                    </a>
                    <a
                      role="link"
                      (click)="exportSingleClipedImage(clippedImage.image, i)"
                    >
                      <i
                        class="fa fa-share-alt export-document"
                        [class.arabic-style]="isArabicLanguage"
                        title="Export Clip Image"
                      ></i>
                    </a>
                  </div>
                  <ng-template #viewCroppedImageListTemplate>
                    <div id="viewListPopup">
                      <div class="modal-header" (click)="insideClick($event)">
                        <h4 class="modal-title sa-icon sa-warning pulseWarning">
                          <span class="sa-body pulseWarningIns"></span>
                          <span class="sa-dot pulseWarningIns"></span>
                        </h4>
                      </div>
                      <div class="modal-body search_body">
                        <div
                          class="alert_warning"
                          [class.arabic-style]="isArabicLanguage"
                        >
                          {{
                            "HomePage.ToolsDropdown.wouldYouDeleteClipImage"
                              | translate
                          }}
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          [class.arabic-style]="isArabicLanguage"
                          class="btn btn-primary"
                          aria-label="Close"
                          (click)="
                            removeClippedImage(currentClipImageForDeleted)
                          "
                        >
                          {{ "HomePage.ToolsDropdown.delete" | translate }}
                        </button>
                        <button
                          type="button"
                          [class.arabic-style]="isArabicLanguage"
                          class="btn-default btn"
                          (click)="viewcroppedImageListCloseModal()"
                        >
                          {{ "HomePage.ToolsDropdown.cancel" | translate }}
                        </button>
                      </div>
                    </div>
                  </ng-template>

                  <ng-template #removeCroppedImageListTemplate>
                    <div id="viewListPopup">
                      <div class="modal-header" (click)="insideClick($event)">
                        <h4 class="modal-title sa-icon sa-warning pulseWarning">
                          <span class="sa-body pulseWarningIns"></span>
                          <span class="sa-dot pulseWarningIns"></span>
                        </h4>
                      </div>
                      <div class="modal-body search_body">
                        <div
                          class="alert_warning"
                          [class.arabic-style]="isArabicLanguage"
                        >
                          {{
                            "HomePage.ToolsDropdown.wouldYouDeleteAllClipImage"
                              | translate
                          }}
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          [class.arabic-style]="isArabicLanguage"
                          class="btn btn-primary"
                          aria-label="Close"
                          (click)="clearAllCroppedImageList(i)"
                        >
                          {{ "HomePage.ToolsDropdown.deleteAll" | translate }}
                        </button>
                        <button
                          type="button"
                          [class.arabic-style]="isArabicLanguage"
                          class="btn-default btn"
                          (click)="removecroppedImageListCloseModal()"
                        >
                          {{ "HomePage.ToolsDropdown.cancel" | translate }}
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </div>

              </div>
            </div>
          </div>
          <div class="viewlist-container-footer">
            <button
              [class.arabic-style]="isArabicLanguage"
              class="btn btn-primary clearall-btn print-hide"
              (click)="clearAll()"
              title="{{ 'HomePage.ToolsDropdown.clearAll' | translate }}"
            >
              {{ "HomePage.ToolsDropdown.clearAll" | translate }}
            </button>
            <button
              [class.arabic-style]="isArabicLanguage"
              class="btn btn-primary export-btn print-hide"
              (click)="exportClipImages()"
              title="{{ 'HomePage.ToolsDropdown.Export' | translate }}"
            >
              Export All
            </button>
          </div>
        </div>
      </div>
    </tab>
  </tabset>
</ng-template>

<ng-template #clearHistoryTemplate>
  <div (click)="insideClick($event)" tabindex="1">
    <div class="modal-header" id="historyModal">
      <h4 class="modal-title sa-icon sa-warning pulseWarning">
        <span class="sa-body pulseWarningIns"></span>
        <span class="sa-dot pulseWarningIns"></span>
      </h4>
    </div>
    <div [class.arabic-style]="isArabicLanguage" class="modal-body search_body">
      <div class="alert_warning">
        {{ "HomePage.ToolsDropdown.clearHistoryText" | translate }}
      </div>
    </div>
    <div class="modal-footer">
      <button
        [class.arabic-style]="isArabicLanguage"
        type="button"
        class="btn btn-primary"
        aria-label="Close"
        (click)="clearHistory()"
        tabindex="1"
      >
        {{ "HomePage.ToolsDropdown.Clear" | translate }}
      </button>
      <button
        [class.arabic-style]="isArabicLanguage"
        type="button"
        class="btn-default btn"
        (click)="clearCloseModal()"
      >
        {{ "HomePage.ToolsDropdown.doNotClear" | translate }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #endSessionTemplate>
  <div class="modal-header" (click)="insideClick($event)" tabindex="1">
    <h4 class="modal-title sa-icon sa-warning pulseWarning">
      <span class="sa-body pulseWarningIns"></span>
      <span class="sa-dot pulseWarningIns"></span>
    </h4>
  </div>
  <div class="modal-body search_body">
    <div class="alert_warning" [class.arabic-style]="isArabicLanguage">
      {{ "HomePage.ToolsDropdown.endSessionConfirmation" | translate }}
    </div>
  </div>
  <div class="modal-footer">
    <button
      [class.arabic-style]="isArabicLanguage"
      type="button"
      class="btn btn-primary"
      aria-label="Close"
      (click)="endSession()"
      tabindex="1"
      id="endSessionId"
    >
      {{ "HomePage.ToolsDropdown.endSession" | translate }}
    </button>
    <button
      [class.arabic-style]="isArabicLanguage"
      type="button"
      class="btn btn-outline-secondary"
      (click)="sessionCloseModal()"
    >
      {{ "HomePage.ToolsDropdown.doNotEnd" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #clipImageTemplate>
  <div
    class="modal-body search_body"
    tabindex="1"
    (click)="$event.stopPropagation()"
  >
    <div class="clipimage-popupbody">
      <button
        type="button"
        class="modal-close-button btn-close pull-right"
        aria-label="Close"
        (click)="closePopup()"
      >
        <!-- <span aria-hidden="true">&times;</span> -->
      </button>
      <a
        class="cropped-image-nav-btn previous-btn wiley-primary-text-color"
        (click)="gotoPreviousImage()"
        [ngClass]="{ disabled: clippedImage.index === 0 }"
        title="{{ 'Common.PreviousImage' | translate }}"
        ><i class="fa fa-angle-left" aria-hidden="true"></i
      ></a>
      <ng-container>
        <div class="">
          <p class="clipimage-title" appDecodeEntities>
            {{ clippedImage.imageName }}
          </p>
        </div>
        <div
          class="img-container"
          [class.arabic-style]="isArabicLanguage"
          style="
            height: 320px;
            display: flex;
            align-items: center;
            border: 2px solid #ccc;
          "
        >
          <img
            [src]="clippedImage.image"
            class="img-responsive center-block img-fluid mx-auto d-block mt-image-clipped"
            alt="{{ 'Alt.imageSlide' | translate }}"
            style="
              display: block;
              max-height: 300px;
              padding: 10px 10px;
              min-width: 300px;
              max-width: 750px;
            "
          />
        </div>
        <div class="clipimages-citation">
          <p class="clipimage-title" appDecodeEntities>
            {{ clippedImage.imageSource }}
          </p>
          <span
            class="clipimage-title"
            [innerHtml]="clippedImage.imageCitation"
          ></span>
          <p
            class="clipimage-imagenotes"
            *ngIf="clippedImage?.imageNotes"
            appDecodeEntities
          >
            <label>{{ "HomePage.clipImageNotes" | translate }} </label>
            {{ clippedImage.imageNotes }}
          </p>
        </div>
      </ng-container>
      <a
        class="cropped-image-nav-btn next-btn wiley-primary-text-color"
        [ngClass]="{
          disabled: clippedImage.index === clippedImageList?.length - 1
        }"
        (click)="gotoNextImage()"
        title="{{ 'Common.NextImage' | translate }}"
        ><i class="fa fa-angle-right" aria-hidden="true"></i
      ></a>
    </div>
  </div>
  <div class="modal-footer" (click)="$event.stopPropagation()">
    <button
      class="btn btn-primary export-btn print-hide"
      (click)="closePopup()"
    >
      {{ "Common.Close" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #exportClipTemplate>
  <div
    class="modal-body search_url_body"
    tabindex="1"
    (click)="$event.stopPropagation()"
  >
    <div class="clipimage-urlbody">
      <h4 class="download-export-header">Your Export is Ready!</h4>
      <button
        type="button"
        class="modal-close-button btn-close pull-right"
        aria-label="Close"
        (click)="closeExportPopup()"
      >
        <!-- <span aria-hidden="true">&times;</span> -->
      </button>
      <ng-container>
        <div class="short-url">
          <div class="input-short-url">
            <p class="note-text">
              You can download the exported file using this link:
            </p>
            <input
              type="text"
              class="shorturl-input"
              [value]="currentExportURL"
            />
            <button
              class="btn btn-short-url-btn"
              title="{{ 'Detail.copytoClipboard' | translate }}"
              (click)="copyShareUrl(currentExportURL)"
            >
              Copy to Clipboard
            </button>
            <p class="note-txt">
              <b>Note: </b> This URL is valid for 1 year.
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer" (click)="$event.stopPropagation()">
    <button
      class="btn btn-primary export-btn print-hide"
      (click)="closeExportPopup()"
    >
      {{ "Common.Close" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #exportRunTemplate>
  <div
    class="modal-body search_url_body"
    tabindex="1"
    (click)="$event.stopPropagation()"
  >
    <div class="clipimage-urlbody">
      <h4 class="download-export-header">Your Short URL is Ready!</h4>
      <button
        type="button"
        class="modal-close-button btn-close pull-right"
        aria-label="Close"
        (click)="closeExportURLPopup()"
      >
        <!-- <span aria-hidden="true">&times;</span> -->
      </button>
      <ng-container>
        <div class="short-url">
          <div class="input-short-url">
            <input
              type="text"
              class="shorturl-input"
              [value]="rulQueryShortURL"
            />
            <button
              class="btn btn-short-url-btn"
              title="{{ 'Detail.copytoClipboard' | translate }}"
              (click)="copyShareUrl(rulQueryShortURL)"
            >
              Copy to Clipboard
            </button>
            <p class="note-txt">
              <b>Note: </b> This URL is valid for 1 year.
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer" (click)="$event.stopPropagation()">
    <button
      class="btn btn-primary export-btn print-hide"
      (click)="closeExportURLPopup()"
    >
      {{ "Common.Close" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #downloadDocsTemplate>
  <div class="modal-header download-title">
    <h4 class="modal-title text-center">
      <b>Download Content</b>
    </h4>
  </div>
  <div class="modal-body url_body download-body">
    <div style="text-align: left; padding-left: 6px">
      <span class="noteText">
        <span><b>Note:</b></span> Please select "Always allow pop-ups" option in
        your browser to download multiple files from {{ weburl }}
      </span>
    </div>
    <div class="terms-scroll">
      <div class="row downPopup">
        <div class="col-md-12 docs" *ngFor="let docs of downloadDocs">
          <a
            class="linkTag"
            *ngIf="docs.signedUrl"
            target="_blank"
            href="{{ docs.signedUrl }}"
            download
            >{{ docs.title }}
          </a>
          <a
            class="linkTag"
            *ngIf="!docs.signedUrl"
            target="_blank"
            href=""
            download
          >
            -
          </a>
        </div>
       
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      [class.arabic-style]="isArabicLanguage"
      type="button"
      class="btn btn-primary"
      (click)="DownloadALlModal(downloadDocs)"
    >
      Download All
    </button>
    <button
      [class.arabic-style]="isArabicLanguage"
      type="button"
      class="btn btn-primary"
      (click)="clearAllDC()"
    >
      {{ "HomePage.ToolsDropdown.clearAll" | translate }}
    </button>
    <button
      [class.arabic-style]="isArabicLanguage"
      type="button"
      class="btn btn-secondary"
      (click)="closeDownloadModal(); $event.stopPropagation()"
    >
      {{ "Detail.Close" | translate }}
    </button>
  </div>
</ng-template>
