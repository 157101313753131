/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable } from '@angular/core';
import { NotificationModel, DEFAULT_OPTIONS, NotificationType } from './notification.model';

@Injectable()
export class NotificationService {
  private notifications: NotificationModel[] = [];

  constructor() {

  }

  getNotifications(): NotificationModel[] {
    return this.notifications;
  }

  clearAll() {
    this.notifications.splice(0, this.notifications.length);
  }

  remove(notification: NotificationModel) {
    const index = this.notifications.indexOf(notification);

    if (index >= 0) {
      this.notifications.splice(index, 1);
    }
  }

  create(alertType: NotificationType, title, content, options = DEFAULT_OPTIONS) {
    const notification = new NotificationModel(alertType, title, content,
      options.timeOut, options.autoClose, options.animate);
      /*
      * trigger only one type notification at a time
      * */
    if (this.notifications.some(e => e.alertType === notification.alertType)) {
      /* vendors contains the element we're looking for */
      // console.log('notification already exists', notification.alertType);
    } else {
      this.notifications.unshift(notification);
    }

    return notification;
  }

  info(title, content, options = DEFAULT_OPTIONS) {
    return this.create('Info', title, content, options);
  }

  success(title, content, options = DEFAULT_OPTIONS) {
    return this.create('Success', title, content, options);
  }

  error(title, content, options = DEFAULT_OPTIONS) {
    return this.create('Error', title, content, options);
  }

  warn(title, content, options = DEFAULT_OPTIONS) {
    return this.create('Warn', title, content, options);
  }
}
