
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../common/services/http.service';
import { environment, APIURLS } from '../../environments/environment';
import { Subject } from 'rxjs';
import { get as _get } from 'lodash';
import { PageSettingsService } from '../common/services/page-settings.service';
import { SearchService } from '../common/services/search.service';
import { MapData } from '../common/modules/map/map/models/map-data.modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { SearchResultByContentTypeComponent } from '../search-result-by-content-type/search-result-by-content-type.component';
import { SearchResultGraphBySubjectComponent } from '../search-result-graph-by-subject/search-result-graph-by-subject.component';
import { MapComponent } from '../common/modules/map/map/map.component';
// import Chart from 'chart.js';
// declare let $: any;
@Component({
  selector: 'app-browser-map',
  standalone:true,
  imports:[TabsModule, TranslateModule, SearchResultByContentTypeComponent,SearchResultGraphBySubjectComponent,
    MapComponent],
  templateUrl: './browser-map.component.html',
  styleUrls: ['./browser-map.component.css']
})
export class BrowserMapComponent implements AfterViewInit, OnDestroy {
  // myChart: any;
  public mapData: MapData[] = [];
  private stop$: Subject<void> = new Subject<void>();
  private advancedSearchFilterParams: any;
  @Output('spatialSearchEmitsFromBrowserMap')  spatialSearchEmitsFromBrowserMap: EventEmitter<any>;

  // lineChartParameters: any = {
  //   type: 'line',
  //   data: {
  //     labels: ['1789', '1994', '1995', '1996', '1998', '2000', '2003', '2007'],
  //     datasets: [{
  //       label: 'Manuscripts',
  //   data: [0, 59, 75, 20, 20, 55, 40, 33],
  //   lineTension: 0.3,
  //   fill: false,
  //   borderColor: 'red',
  //   backgroundColor: 'transparent',
  //   pointBorderColor: 'red',
  //   pointBackgroundColor: 'lightgreen',
  //   pointRadius: 5,
  //   pointHoverRadius: 15,
  //   pointHitRadius: 30,
  //   pointBorderWidth: 2
  //     }, {
  //       label: 'Monographs',
  //   data: [20, 15, 60, 60, 65, 30, 70, 12],
  //   lineTension: 0.3,
  //   fill: false,
  //   borderColor: 'purple',
  //   backgroundColor: 'transparent',
  //   pointBorderColor: 'purple',
  //   pointBackgroundColor: 'lightgreen',
  //   pointRadius: 5,
  //   pointHoverRadius: 15,
  //   pointHitRadius: 30,
  //   pointBorderWidth: 2
  //     }, {
  //       label: 'Photographs',
  //   data: [65, 30, 70, 20, 15, 60, 60, 80],
  //   lineTension: 0.3,
  //   fill: false,
  //   borderColor: 'green',
  //   backgroundColor: 'transparent',
  //   pointBorderColor: 'purple',
  //   pointBackgroundColor: 'lightgreen',
  //   pointRadius: 5,
  //   pointHoverRadius: 15,
  //   pointHitRadius: 30,
  //   pointBorderWidth: 2
  //     }]
  //   },
  //   options: {
  //     legend: {
  //       display: true,
  //       position: 'top',
  //       labels: {
  //         boxWidth: 80,
  //         fontColor: 'black'
  //       }
  //     },
  //     scales:
  //       {
  //           yAxes: [{
  //               gridLines : {
  //                   display : false
  //               }
  //           }]
  //       }
  //   }
  // };
  constructor(private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private searchService: SearchService ) {
      this.searchService.buildFilterParams(PageSettingsService.pageSettings.advancedFilterParam, params => {
        this.advancedSearchFilterParams = params;
      });
      this.spatialSearchEmitsFromBrowserMap = new EventEmitter();
    }
  ngAfterViewInit() {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this.stop$))
      .subscribe(params => {
        this.mapData = [];
        const requestParams = this.advancedSearchFilterParams;
          requestParams.filterParams.forEach(obj => {
            if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
              obj.condition = 'All';
              obj.fieldValue = '';
            }
          });
          // Note: this.mapData value is for heat map layer value
        this.httpService.post(environment.APIUrl + APIURLS['spatialcluster'],
          requestParams,
          (response) => {
            this.mapData = _get(response, 'results.locationCluster', []);
          }, (err) => {
          }, 'json');
      });
    // setTimeout(() => {
    // const  liceChartCanvas = $('#lineChart');
    // this.myChart = new Chart(liceChartCanvas, this.lineChartParameters);
    // });
  }
  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  onSpatialSearchEmit(event) {
    this.spatialSearchEmitsFromBrowserMap.emit(event);
  }
}
