import {
    Directive,
    ElementRef,
    Input,
    AfterViewInit,
    SimpleChanges,
    OnChanges,
    EventEmitter,
    Output,
} from '@angular/core';

declare let $: any;
// declare let slider: any;



@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[slider]'
})
export class SliderDirective implements AfterViewInit, OnChanges {

    @Input() value: number;
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Output() sliderValue: EventEmitter<any> = new EventEmitter();

    slider: any;
    private element: ElementRef;

    constructor(element: ElementRef) {
        this.element = element;
    }

    ngAfterViewInit() { this.update(); }
    /**
     * @description : detect changes from input and create/update slider
     * @param changes : detect changes from input
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            if (changes.value.currentValue) {
                // if (changes.value.currentValue.length > 0) {
                    this.update();
                // }
            }
        }
    }
    /**
     * @description :create/update slider based on min. max values
     */
    update() {
        this.slider = $(this.element.nativeElement).slider({
            min: this.min,
            max: this.max,
            step: this.step,
            value: this.value,
            range: 'min',
            stop: (event, ui) => {
                this.sliderValue.emit(ui.value);
            }
        });
    }

}



