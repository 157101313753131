
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
// import { HttpService } from './../common/services/http.service';
import { Component, Input, EventEmitter, Output, AfterContentInit, OnDestroy, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { PageSettingsService } from './../common/services/page-settings.service';
import { PageSettings } from './../common/models/pagesettings';
import { SearchService } from './../common/services/search.service';
import { Router, ActivatedRoute } from '@angular/router';
// import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../common/services/common.service';
import { SharedService } from '../common/services/sharedService';
import { Subscription ,  Subject } from 'rxjs';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BsModalRef } from 'ngx-bootstrap/modal';
// import { ModalService } from '../common/services/modal.service';
import { DisableRightClickService } from '../common/services/disable-right-click.service';

@Component({
  selector: 'app-wiley-searchresult-leftmenu-component',
  templateUrl: './wiley-searchresult-leftmenu-component.component.html',
  styleUrls: ['./wiley-searchresult-leftmenu-component.component.css'],
  preserveWhitespaces: false
})
export class WileySearchresultLeftmenuComponentComponent implements AfterContentInit, OnDestroy {
  stop$: Subject<void> = new Subject();
  checkCurrentLanguage: any;
  showAnalysisTools: Boolean = false;
  analysisData: any;
  // public config: PerfectScrollbarConfigInterface = {};
  isArabicLanguage: Boolean = false;
  fromSearchResult: any;
  currentHistory: any;
  _searchTerm: any;
  viewHistoryUrl: any;
  filterParams: any;
  isFromTATTools: any = false;
  urlParams: any;
  unSubActivatedRoute: Subscription;
  contentSubtypeHasMore: any;
  contentSubTypeCount: any;
  translatedValues: any;
  controlShortcut: any;
  shortcutKey: any;
  locationData: any;
  type = 'bar';
  data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: '',
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
  };
  options = {
    responsive: true,
    maintainAspectRatio: false
  };
  showLeftMenu: Boolean = true;
  facets: any;
  pageSettings: PageSettings;
  private togglearchiveicon: Boolean = false;
  private toggleanalysisicon: Boolean = false;
  private togglecollectionicon: Boolean = false;
  private togglecontentSubicon: Boolean = false;
  private toggledatatoolicon: Boolean = false;
  private togglelanguageicon: Boolean = false;
  private togglesubjecticon: Boolean = false;
  private togglesourceicon: Boolean = false;
  private pageContentarchivesData: any;
  archivesFacetResult: any = [];
  private contentTypeFacetResult: any;
  subjectFacetResult: any;
  collectionFacetResult: any;
  contentSubTypeFacetResult: any;
  pubDateFacetResult: any;
  languageFacetResult: any;
  sourcesFacetResult: any;
  private dateRange: Array<number> = [100, 200];
  private yearFrom: number;
  private yearTo: number;
  // private year;
  private homePageUrl: String = '';
  private isInMapView: boolean;
  currentArchive: string;
  private archivesFullName: any;
  contentTypeHasMore: any;
  archiveHasMore: any;
  subjectHasMore: any;
  collectionHasMore: any;
  docLangHasMore: any;
  sourceInstitutionHasMore: any;
  pubYearHasMore: any;
  subResultCount: any;
  collectionCount: any;
  languageCount: any;
  sourcesCount: any;
  isClearSearchTerm: any = false;
  bothRCPAvailable: boolean = false;
  bothRGSAvailable = false;
  disableFacet = false;
  @Input()
  set mapViewToggle(flag: boolean) {
    if (flag) {
      this.isInMapView = flag;
    }
  }
  get mapViewToggle() {
    return this.isInMapView;
  }
  @Input('page-content') headerContent: any;
  @Output('mapSelect') private onMapSelectEmiter: EventEmitter<any> = new EventEmitter();
  private mapView: Boolean = false;
  // @ViewChild('analysisHtmlTemplate') private analysisHtmlTemplate: TemplateRef<any>;
  @ViewChild('analysisHtmlTemplate', { static: false }) analysisHtmlTemplate: TemplateRef<any>;

  private analysismodalRef: BsModalRef;
  constructor(
    private pageSettingService: PageSettingsService,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private translate: TranslateService,
    private sharedService: SharedService,
    private rightClickDisable: DisableRightClickService,
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      this.isFromTATTools = params['fromTATTools'];
      this.fromSearchResult = params['fromSearchResult'];
      this.isClearSearchTerm = params['isClearSearchTerm'];
    });
    this.isInMapView = sessionStorage.getItem('isInMapView') === 'true' ? true : false;
    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe(data => {
      this.pageSettings = data;
      this.archivesFullName = data.customerArchives;
      // data.customerArchives.forEach(element => {
      //   this.archivesFullName = element.name;
      // });
    });
    this.pageSettings.showLeftMenu =
      sessionStorage.getItem('LefttMenuStatus') === 'false' ? false : true;
    SearchService.facetObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.facets = data;
      if (this.facets) {
        for (let index = 0; index < this.facets.length; index++) {
          const element = this.facets[index];
          if (element.facetField === 'contentType') {
            this.contentTypeFacetResult = element.facetContent;
            this.contentTypeHasMore = element.hasMore;
          } else if (element.facetField === 'archive') {
            // this.archiveHasMore = element.hasMore;
            // this.archivesFacetResult = element.facetContent;
          } else if (element.facetField === 'subject') {
            // if (!this.disableFacet) {
            this.subjectHasMore = element.hasMore;
            this.subjectFacetResult = element.facetContent;
            this.subjectFacetResult.forEach(result => {
              this.subResultCount = result.count;
            });
            // }
          } else if (element.facetField === 'collection') {
            // if (!this.disableFacet) {
            this.collectionHasMore = element.hasMore;
            this.collectionFacetResult = element.facetContent;
            this.collectionFacetResult.forEach(result => {
              this.collectionCount = result.count;
            });
            // }
          } else if (element.facetField === 'pubYear') {
            // if (!this.disableFacet) {
            this.pubYearHasMore = element.hasMore;
            this.pubDateFacetResult = element.facetContent;
            if (
              Array.isArray(this.pubDateFacetResult) &&
              this.pubDateFacetResult.length > 0
            ) {
              this.yearFrom = this.pubDateFacetResult[0].value;
              const lastIndex = this.pubDateFacetResult.length - 1;
              this.yearTo = this.pubDateFacetResult[lastIndex].value;
              this.dateRange = [this.yearFrom, this.yearTo];
            }
            // }
          } else if (element.facetField === 'docLang') {
            this.docLangHasMore = element.hasMore;
            this.languageFacetResult = element.facetContent;
            this.languageFacetResult.forEach(result => {
              this.languageCount = result.count;
            });
          } else if (element.facetField === 'sourceInstitution') {
            let rcpcount = 0;
            let rgscount = 0;
            if (this.bothRCPAvailable || this.bothRGSAvailable) {
              if (this.bothRCPAvailable) {
                element.facetContent.forEach(element => {
                  if (element.count > 0) {
                    if (element.value.includes('The Royal College of Physicians')) {
                      rcpcount += element.count;
                    }
                  }
                });

                this.sourcesFacetResult = element.facetContent.filter(item => !item.value.includes('The Royal College of Physicians'));
                let rcpObj = { value: 'The Royal College of Physicians', count: rcpcount };
                if (rcpcount > 0) {
                  this.sourcesFacetResult.push(rcpObj);
                }
              } else {
                if (this.sourcesFacetResult) {
                  this.sourcesFacetResult = this.sourcesFacetResult;
                } else {
                  this.sourcesFacetResult = element.facetContent;
                }
              }
              if (this.bothRGSAvailable) {
                element.facetContent.forEach(element => {
                  if (element.count > 0) {
                    if (element.value.includes('Royal Geographical Society')) {
                      rgscount += element.count;
                    }
                  }
                });
                if (this.sourcesFacetResult.length > 0) {
                  this.sourcesFacetResult = this.sourcesFacetResult.filter(item => !item.value.includes('Royal Geographical Society'));
                } else {
                  this.sourcesFacetResult = element.facetContent.filter(item => !item.value.includes('Royal Geographical Society'));
                }
                let rgsObj = { value: 'The Royal Geographical Society (with IBG)', count: rgscount };
                if (rgscount > 0) {
                  this.sourcesFacetResult.push(rgsObj);
                }
              } else {
                if (this.sourcesFacetResult) {
                  this.sourcesFacetResult = this.sourcesFacetResult;
                } else {
                  this.sourcesFacetResult = element.facetContent;
                }
              }
            } else {
              this.sourcesFacetResult = element.facetContent;
            }
            this.sourceInstitutionHasMore = element.hasMore;
            this.sourcesFacetResult.forEach(result => {
              this.sourcesCount = result.count;
            });
            if (this.sourcesFacetResult) {
              this.sourcesFacetResult.sort((a, b) => {
                if (a.count < b.count) return 1;
                else if (a.count > b.count) return -1;
                else return 0;
              });
            }
            // this.sourcesFacetResult.sort((a,b) => a.count > b.count);
          }
          if (element.facetField === 'contentSubType') {
            // if (!this.disableFacet) {
            this.contentSubtypeHasMore = element.hasMore;
            this.contentSubTypeFacetResult = element.facetContent;
            this.contentSubTypeFacetResult.forEach(result => {
              this.contentSubTypeCount = result.count;
            });
            // }
          }
        }
      }
      if (this.facets.length === 0) {
        this.archivesFacetResult = [];
        this.contentSubTypeFacetResult = [];
        this.sourcesFacetResult = [];
        this.languageFacetResult = [];
        this.collectionFacetResult = [];
        this.subjectFacetResult = [];
        // this.archivesFacetResult = [];
        this.pubDateFacetResult = [];
      }
    });

  }
  ngAfterContentInit() {
    this.rightClickDisable.disableRightClick();
    this.sharedService.disablefacets.pipe(takeUntil(this.stop$)).subscribe(res => {
      const checkadiyionalArchives = localStorage.getItem('fromAdditionalArchive');
      if(!res && checkadiyionalArchives === 'false' || !checkadiyionalArchives) {
        this.disableFacet = false;
      } else {
        this.disableFacet = true;
      }
    })

    let accessedArchives: any = JSON.parse(localStorage.getItem('personalizationData'));
    this.bothRCPAvailable = accessedArchives.bothRCPAvailable;
    this.bothRGSAvailable = accessedArchives.bothRGSAvailable;
    // setTimeout(() => {
      SearchService.archivefacetObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
        this.archivesFacetResult = [];
        if (this.disableFacet) {
          this.archivesFacetResult = data.facetContent;
        } else {
          if (Object.keys(data).length !== 0) {
            let rcpcount = 0;
            let rgscount = 0;
            if (this.bothRCPAvailable || this.bothRGSAvailable) {
              if (this.bothRCPAvailable) {
                data.facetContent.forEach(element => {
                  if (element.count > 0) {
                    if (element.value.includes('rcp')) {
                      rcpcount += element.count;
                    }
                  }
                });
                this.archivesFacetResult = data.facetContent.filter(item => !item.value.includes('rcp'));
                let rcpObj = { value: 'rcp', count: rcpcount };
                this.archivesFacetResult.push(rcpObj);
              } else {
                if (this.archivesFacetResult) {
                  this.archivesFacetResult = this.archivesFacetResult;
                } else {
                  this.archivesFacetResult = data.facetContent;
                }
              }
              if (this.bothRGSAvailable) {
                data.facetContent.forEach(element => {
                  if (element.count > 0) {
                    if (element.value.includes('rgs')) {
                      rgscount += element.count;
                    }
                  }
                });
                if (this.archivesFacetResult.length > 0) {
                  this.archivesFacetResult = this.archivesFacetResult.filter(item => !item.value.includes('rgs'));
                } else {
                  this.archivesFacetResult = data.facetContent.filter(item => !item.value.includes('rgs'));
                }
                let rgsObj = { value: 'rgs', count: rgscount };
                this.archivesFacetResult.push(rgsObj);
              } else {
                if (this.archivesFacetResult) {
                  this.archivesFacetResult = this.archivesFacetResult;
                } else {
                  this.archivesFacetResult = data.facetContent;
                }
              }
            } else {
              this.archivesFacetResult = data.facetContent;
            }
            
          } else {
            let rcpcount = 0;
            let rgscount = 0;
            if (sessionStorage.getItem('facetArchiveResponse')) {
              let facetRes: any = JSON.parse(sessionStorage.getItem('facetArchiveResponse'));
              if (this.bothRCPAvailable || this.bothRGSAvailable) {
                if (this.bothRCPAvailable) {
                  facetRes.facetContent.forEach(element => {
                    if (element.count > 0) {
                      if (element.value.includes('rcp')) {
                        rcpcount += element.count;
                      }
                    }
                  });
                  this.archivesFacetResult = facetRes.facetContent.filter(item => !item.value.includes('rcp'));
                  let rcpObj = { value: 'rcp', count: rcpcount };
                  this.archivesFacetResult.push(rcpObj);
                } else {
                  if (this.archivesFacetResult) {
                    this.archivesFacetResult = this.archivesFacetResult;
                  } else {
                    this.archivesFacetResult = data.facetContent;
                  }
                }
                if (this.bothRGSAvailable) {
                  facetRes.facetContent.forEach(element => {
                    if (element.count > 0) {
                      if (element.value.includes('rgs')) {
                        rgscount += element.count;
                      }
                    }
                  });
                  if (this.archivesFacetResult) {
                    this.archivesFacetResult = this.archivesFacetResult.filter(item => !item.value.includes('rgs'));
                  } else {
                    this.archivesFacetResult = facetRes.facetContent.filter(item => !item.value.includes('rgs'));
                  }
                  let rgsObj = { value: 'rgs', count: rgscount };
                  this.archivesFacetResult.push(rgsObj);
                } else {
                  if (this.archivesFacetResult) {
                    this.archivesFacetResult = this.archivesFacetResult;
                  } else {
                    this.archivesFacetResult = data.facetContent;
                  }
                }
              } else {
                this.archivesFacetResult = data.facetContent;
              }
            } else {
              this.archivesFacetResult = [];
            }
          }
          if (this.archivesFacetResult) {
            this.archivesFacetResult.sort((a, b) => {
              if (a.count < b.count) return 1;
              else if (a.count > b.count) return -1;
              else return 0;
            });
          }
        }
      });


    // }, 500);

    const currentLanguage = sessionStorage.getItem('lang');
    if (navigator.userAgent.toString().indexOf('.NET') > 0) {
      this.checkCurrentLanguage = sessionStorage.getItem('lang');
    }
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    });
    if ((navigator.userAgent.indexOf('Win') !== -1) || (navigator.userAgent.indexOf('X11') !== -1) ||
      (navigator.userAgent.indexOf('Linux') !== -1)) {
      this.controlShortcut = 'Ctrl + Alt';
      this.shortcutKey = 'Shift + Alt';
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      this.controlShortcut = 'Cmd + Option';
      this.shortcutKey = 'Shift + Option';
    }
    // this.shortcutKey = localStorage.getItem('ShortcutKey');
    //  this.controlShortcut = localStorage.getItem('ControlShortcutKey');
    setTimeout(() => {
      this.homePageUrl = '/';
      this.homePageUrl += this.currentArchive = (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
      // this.homePageUrl += '/location/';
      // this.homePageUrl += PageSettingsService.pageSettings.currentLocation;
    });
    // this.httpService.get('../assets/config/locations-config.json', (response) => {
    this.commonService.getLocationConfig(localStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(response => {
      this.locationData = response;
    }, (err) => { });
    this.translate.get('Locations').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
    });
  }
  checkAccordionStatus(event: Event, accordionGroup) {
    if (typeof (event) === 'boolean') {
      switch (accordionGroup) {
        case 'toggleanalysisicon':
          this.toggleanalysisicon = event;
          break;
        case 'togglearchiveicon':
          this.togglearchiveicon = event;
          break;
        case 'togglecollectionicon':
          this.togglecollectionicon = event;
          break;
        case 'toggledatatoolicon':
          this.toggledatatoolicon = event;
          break;
        case 'togglelanguageicon':
          this.togglelanguageicon = event;
          break;
        case 'togglesubjecticon':
          this.togglesubjecticon = event;
          break;
        case 'togglesourceicon':
          this.togglesourceicon = event;
          break;
        case 'togglecontentSubicon':
          this.togglecontentSubicon = event;
          break;
        default:
          break;

      }
    }
  }

  setContentSubtype(contentSubType) {
    const currentSearchSettings = JSON.parse(
      JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam)
    );
    currentSearchSettings.docSubType = [contentSubType.value.trim()];
    this.searchService.goToAdvancedSearchPage(
      this.pageSettings.searchTerm,
      currentSearchSettings
    );
  }
  setCollection(collection) {
    const currentSearchSettings = JSON.parse(
      JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam)
    );
    // sessionStorage.setItem('isInMapView', this.isInMapView.toString());
    currentSearchSettings.contentCollection = [collection.value.trim()];
    this.searchService.goToAdvancedSearchPage(
      this.pageSettings.searchTerm,
      currentSearchSettings
    );
  }
  setSource(source) {
    const currentSearchSettings = JSON.parse(
      JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam)
    );
    // sessionStorage.setItem('isInMapView', this.isInMapView.toString());
    currentSearchSettings.contentSources = [source.value];
    this.searchService.goToAdvancedSearchPage(
      this.pageSettings.searchTerm,
      currentSearchSettings
    );
  }
  setLanguage(language) {
    const currentSearchSettings = JSON.parse(
      JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam)
    );
    // sessionStorage.setItem('isInMapView', this.isInMapView.toString());
    currentSearchSettings.languages = [language.value];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(
      this.pageSettings.searchTerm,
      currentSearchSettings
    );
  }
  setArchive(archive) {
    // this.pageSettingService.updateSetting('currentSelectedArchives', [archive.value.toUpperCase()]);
    const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));
    currentSearchSettings.archive = [archive.value.toUpperCase()];
    // sessionStorage.setItem('isInMapView', this.isInMapView.toString());
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    setTimeout(() => {
        var personalizedData = localStorage.getItem('personalizationData');
        if (typeof personalizedData === 'string') {
          personalizedData = JSON.parse(personalizedData);
          if (archive.value === 'rcp') {
            if (personalizedData['bothRCPAvailable']) {
              this.pageSettings.advancedFilterParam.archive = ['RCP1', 'RCP2'];
            } else {
              var getWileySelectedArchives = localStorage.getItem('wileySelectedArchive');
              const havercp1 = getWileySelectedArchives.includes('RCP1');
              if (havercp1) {
                this.pageSettings.advancedFilterParam.archive = ['RCP1'];
              } else {
                this.pageSettings.advancedFilterParam.archive = ['RCP2'];
              }
            }
            localStorage.setItem('wileySelectedArchive', JSON.stringify(this.pageSettings.advancedFilterParam.archive));
            // sessionStorage.setItem('wileySelectedArchive', JSON.stringify(this.pageSettings.advancedFilterParam.archive));
          } else  if (archive.value === 'rgs') {
            if (personalizedData['bothRGSAvailable']) {
              this.pageSettings.advancedFilterParam.archive = ['RGS1', 'RGS2'];
            } else {
              var getWileySelectedArchives = localStorage.getItem('wileySelectedArchive');
              const havercp1 = getWileySelectedArchives.includes('RGS1');
              if (havercp1) {
                this.pageSettings.advancedFilterParam.archive = ['RGS1'];
              } else {
                this.pageSettings.advancedFilterParam.archive = ['RGS2'];
              }
            }
            localStorage.setItem('wileySelectedArchive', JSON.stringify(this.pageSettings.advancedFilterParam.archive));
            // sessionStorage.setItem('wileySelectedArchive', JSON.stringify(this.pageSettings.advancedFilterParam.archive));
          } else {
            const val =archive.value.toUpperCase();
            this.pageSettings.advancedFilterParam.archive = [val];
            localStorage.setItem('wileySelectedArchive', JSON.stringify(this.pageSettings.advancedFilterParam.archive));
            // sessionStorage.setItem('wileySelectedArchive', JSON.stringify(this.pageSettings.advancedFilterParam.archive));
          }
        }
      this.searchService.goToAdvancedSearchPageFromFacetArchive(
        this.pageSettings.searchTerm,
        this.pageSettings.advancedFilterParam
      );
      sessionStorage.setItem('isInGridView', 'false');
      sessionStorage.setItem('checkGridview', 'false');
    }, 500);
  }
  setSubject(subject) {
    const currentSearchSettings = JSON.parse(
      JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam)
    );
    // sessionStorage.setItem('isInMapView', this.isInMapView.toString());
    currentSearchSettings.subject = [subject.value];
    this.pageSettingService.updateSearchSettings(currentSearchSettings);
    this.searchService.goToAdvancedSearchPage(
      this.pageSettings.searchTerm,
      currentSearchSettings
    );
  }
  dateRangeChanged(event) {
    this.yearFrom = event[0][0];
    this.yearTo = event[0][1];
  }
  updateResultsFromDateRange(event) {
    this.yearFrom = event.yearFrom;
    this.yearTo = event.yearTo;
    const currentSearchSettings = JSON.parse(
      JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam)
    );
    currentSearchSettings.publishedDateFrom = this.yearFrom.toString();
    currentSearchSettings.publishedDateTo = this.yearTo.toString();
    if (this.yearFrom && this.yearTo) {
      currentSearchSettings.publishedDatePrefix = 'BETWEEN';
    } else {
      currentSearchSettings.publishedDatePrefix = 'EQUAL';
    }
    // sessionStorage.setItem('isInMapView', this.isInMapView.toString());
    this.searchService.goToAdvancedSearchPage(
      this.pageSettings.searchTerm,
      currentSearchSettings
    );
  }

  goToTATToolsPage(searchTerm, filterParam, index) {
    const searchresultFlag = true;
    this.searchService.goToTermFrequencyPage(searchTerm, filterParam, index, searchresultFlag);
    sessionStorage.removeItem('TGLastTileIfo');
    sessionStorage.removeItem('selectedGroup');
    sessionStorage.removeItem('TGTabSelection');
  }

  gotoAnalysisHub(page) {
    sessionStorage.removeItem('TGLastTileIfo');
    sessionStorage.removeItem('selectedGroup');
    sessionStorage.removeItem('TGTabSelection');
    let searchTermDatas = [];
    const isfromOtherAnalysisHub = sessionStorage.getItem('isFromAnalysisHubPage');
    if (isfromOtherAnalysisHub) {
      if (isfromOtherAnalysisHub === 'true') {
        this.isFromTATTools = true;
      } else {
        this.isFromTATTools = false;
      }
    }
    // sessionStorage.removeItem('lastResultPageSearchHistory');
    this.searchService.buildFilterParams(this.pageSettings.advancedFilterParam, (paramsBuilt) => {
      this.searchService.buildURL(paramsBuilt, (url) => {
        if (!this.isFromTATTools) {
          sessionStorage.setItem('lastResultPageSearchHistory', window.location.pathname);
        }
        const data = [];
        data['preventLimiters'] = true;
        data['filterParam'] = url;
        data['invokedFrom'] = 'searchResults';
        if (this.isFromTATTools) {
          let searchTerm;
          if (sessionStorage.getItem('sessionFilterInput')) {
            searchTerm = sessionStorage.getItem('sessionFilterInput');
            sessionStorage.setItem('sessionFilterInput', searchTerm);
            sessionStorage.setItem('termFrequency', searchTerm);
          }
          data['searchTerm'] = searchTerm && !this.isClearSearchTerm ? searchTerm : '';
          sessionStorage.setItem('isfromAnalysisHub', 'true');
        } else {
          if (this.pageSettings.searchTerm) {
            const booleanOperators = ['AND', 'OR', 'NOT'];
            const searchTermData = this.searchService.splitTerms(this.pageSettings.searchTerm);
            searchTermData.forEach(element => {
              if (booleanOperators.indexOf(element.toString()) > -1) {
                element = '';
              } else {
                if (searchTermDatas.indexOf(element.toLowerCase()) !== -1) {
                } else {
                  searchTermDatas.push(element.toLowerCase());
                }
                // searchTermDatas.push(element);
              }
            });
          }
          if (this.pageSettings.advancedFilterParam.searchKeywords) {
            const tempArray = [];
            this.pageSettings.advancedFilterParam.searchKeywords.forEach(term => {
              // authors field is not a search term
              if (term.fieldName !== 'authors') {
                if (term.fieldValue) {
                  tempArray.push(term.fieldValue.toLowerCase());
                }
              }
            });
            if (searchTermDatas.length > 0) {
              searchTermDatas = searchTermDatas.concat(tempArray);
            } else {
              searchTermDatas = tempArray;
            }
          }
          if (searchTermDatas.length > 0) {
            sessionStorage.setItem('wileySuggestedterms', JSON.stringify(searchTermDatas));
            sessionStorage.setItem('termFrequency', JSON.stringify(searchTermDatas.splice(0, 8)));
            sessionStorage.setItem('sessionFilterInput', JSON.stringify(searchTermDatas.splice(0, 8)));
          }
          if (sessionStorage.getItem('isfromAnalysisHub')) {
            sessionStorage.removeItem('isfromAnalysisHub');
            sessionStorage.setItem('isfromAnalysisHub', 'false');
          }
        }
        data['searchTerm'] = this.pageSettings.searchTerm && !this.isClearSearchTerm ? this.pageSettings.searchTerm : '';
        this.router.navigate([`${this.homePageUrl}/analysis-hub/${page}`, data]);
      });
    });
  }

  goToTermFrequency(index) {
    if (this.analysismodalRef) {
      this.analysismodalRef.hide();
    }
    this.sharedService.setTextAnalysisTabIndex(index);
    sessionStorage.removeItem('currentAvtiveIndex');
    if (!this.isFromTATTools) {
      sessionStorage.removeItem('TFLimiter');
      sessionStorage.removeItem('TATLimiters');
      sessionStorage.removeItem('termFrequency');
      sessionStorage.removeItem('termPopularity');
      sessionStorage.removeItem('termGroups');
      sessionStorage.removeItem('TGLastTileIfo');
      sessionStorage.removeItem('selectedGroup');
      sessionStorage.removeItem('TGTabSelection');
      const searchresultFlag = true;
      let searchTerm = null;
      this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => { searchTerm = params.searchTerm; });
      const filterParam = PageSettingsService.pageSettings.advancedFilterParam;
      this.searchService.goToTermFrequencyPage(searchTerm, filterParam, index, searchresultFlag, this.isFromTATTools);
    } else {
      const previousSearchTerm = JSON.parse(localStorage.getItem('HistoryList')) || [];
      if (previousSearchTerm) {
        if (previousSearchTerm.length > 0) {
          this.currentHistory = previousSearchTerm.pop();
          this.viewHistoryUrl = this.currentHistory.url;
          // if (!this.isFromTATTools) {
          // if (this.fromSearchResult) {
          //   this._searchTerm = this.currentHistory.searchParams;
          //   } else {
          this._searchTerm = this.currentHistory.searchParams;
          // }
          // }
          this.filterParams = this.currentHistory.filterParams;
          const searchresultFlag = true;
          const filterParam = PageSettingsService.pageSettings.advancedFilterParam;
          filterParam['pubStartDate'] = this.yearFrom;
          filterParam['pubEndDate'] = this.yearTo;
          // tslint:disable-next-line:max-line-length
          this.searchService.goToTermFrequencyPage(this._searchTerm, this.filterParams || filterParam, index, searchresultFlag, this.isFromTATTools);
        } else {
          const searchresultFlag = true;
          let searchTerm = null;
          // if (!this.isFromTATTools) {
          this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => { searchTerm = params.searchTerm; });
          // }
          const filterParam = PageSettingsService.pageSettings.advancedFilterParam;
          filterParam['pubStartDate'] = this.yearFrom;
          filterParam['pubEndDate'] = this.yearTo;
          this.searchService.goToTermFrequencyPage(searchTerm, filterParam, index, searchresultFlag, this.isFromTATTools);
        }
      } else {
        const searchresultFlag = true;
        let searchTerm = null;
        this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => { searchTerm = params.searchTerm; });
        const filterParam = PageSettingsService.pageSettings.advancedFilterParam;
        filterParam['pubStartDate'] = this.yearFrom;
        filterParam['pubEndDate'] = this.yearTo;
        this.searchService.goToTermFrequencyPage(searchTerm, filterParam, index, searchresultFlag, this.isFromTATTools);
      }
    }
    // setTimeout(() => {
    //   this.sharedService.setTextAnalysisTabIndex(index);
    // }, 2000);
    // const termFrequencyUrl = sessionStorage.getItem('termFrequencyUrl');
    // const termPopularityUrl = sessionStorage.getItem('termPopularityUrl');
    // sessionStorage.removeItem('termFrequency');
    // sessionStorage.removeItem('termPopularity');
    // sessionStorage.removeItem('termGroups');
    // if (termFrequencyUrl) {
    //   if (index === 0) {
    //     let url = termFrequencyUrl;
    //     url = url.replace('ActiveIndex=1', 'ActiveIndex=0');
    //     sessionStorage.setItem('currentAvtiveIndex', '0');
    //     window.location.replace(termFrequencyUrl);
    //   } else {
    //     let url = termFrequencyUrl;
    //     url = url.replace('ActiveIndex=0', 'ActiveIndex=1');
    //     sessionStorage.setItem('currentAvtiveIndex', '1');
    //     window.location.replace(url);
    //   }
    // } else if (termPopularityUrl) {
    //   if (index === 1) {
    //     let url = termPopularityUrl;
    //     url = url.replace('ActiveIndex=0', 'ActiveIndex=1');
    //     sessionStorage.setItem('currentAvtiveIndex', '1');
    //     window.location.replace(url);
    //   } else if (index === 0) {
    //     let url = termPopularityUrl;
    //     url = url.replace('ActiveIndex=1', 'ActiveIndex=0');
    //     sessionStorage.setItem('currentAvtiveIndex', '0');
    //     window.location.replace(url);
    //   } else {
    //     let url = termPopularityUrl;
    //     url = url.replace('ActiveIndex=1', 'ActiveIndex=0');
    //     sessionStorage.setItem('currentAvtiveIndex', '0');
    //     window.location.replace(url);
    //   }
    // } else {
    // sessionStorage.setItem('currentAvtiveIndex', index);
    // let searchTerm = null;
    // this.activatedRoute.params.subscribe(params => {
    //   searchTerm = params.searchTerm;
    // });
    // const filterParam = PageSettingsService.pageSettings.advancedFilterParam;
    // // filterParam['pubStartDate'] = this.yearFrom;
    // // filterParam['pubEndDate'] = this.yearTo;
    // const searchresultFlag = true;
    // this.searchService.goToTermFrequencyPage(
    //   searchTerm,
    //   filterParam,
    //   index,
    //   searchresultFlag
    // );
    // }
  }

  onMapClick() {
    this.searchService.loadMapPos = [];
    this.isInMapView = !this.isInMapView;
    this.onMapSelectEmiter.emit(this.isInMapView);
    sessionStorage.setItem('isInMapView', this.isInMapView.toString());
    // let searchTerm = null;
    // this.activatedRoute.params.subscribe((params) => {
    //   searchTerm = params.searchTerm;
    //   const currentSearchSettings = JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam));

    // this.searchService.goToAdvancedSearchPage(this.pageSettings.searchTerm, currentSearchSettings, this.isInMapView);
    // this.location.replaceState(this.router.createUrlTree([currentParams], { relativeTo: this.activatedRoute }).toString());
    // });
  }

  // getAnalysisDetailsFromSearchTerm() {
  //   this.modalService.showLoader();
  //   this.activatedRoute.params.subscribe(params => {
  //     if (params['filter']) {
  //       this.searchService.getAnalysisDetailsForAdvancedSearch(PageSettingsService.pageSettings.advancedFilterParam, PageSettingsService.pageSettings.searchTerm, (response) => {
  //         if (response) {
  //           this.modalService.hideLoader();
  //           this.analysisData = response['rows'];
  //           this.analysismodalRef = this.bsModalService.show(this.analysisHtmlTemplate,
  //              { backdrop: true, ignoreBackdropClick: true, class: 'analysis-popup' });
  //         } else {
  //           this.modalService.showNotification('WARNING', 'Analysis', 'Analysis Data Not Found');
  //           this.modalService.hideLoader();
  //         }
  //       });
  //     } else {
  //       this.searchService.getAnalysisDetailsForSimpleSearch(PageSettingsService.pageSettings.searchTerm, (response) => {
  //         if (response) {
  //           this.modalService.hideLoader();
  //           this.analysisData = response['rows'];
  //           this.analysismodalRef = this.bsModalService.show(this.analysisHtmlTemplate,
  //              { backdrop: true, ignoreBackdropClick: true, class: 'analysis-popup' });
  //         } else {
  //           this.modalService.showNotification('WARNING', 'Analysis', 'No Results Found');
  //           this.modalService.hideLoader();
  //         }
  //       });
  //     }
  //   });

  // }
  closeanalysisModal() {
    this.analysismodalRef.hide();
  }

  viewMoreNaalysisTools() {
    this.showAnalysisTools = true;
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(e: KeyboardEvent) {
    if (e.shiftKey && e.altKey && e.code === "KeyJ") {
      this.gotoAnalysisHub('frequency');
    } else if (e.shiftKey && e.altKey && e.code === "KeyY") {
      this.gotoAnalysisHub('popularity');
    } else if (e.shiftKey && e.altKey && e.code === "KeyG") {
      this.gotoAnalysisHub('groups');
    } else if (e.shiftKey && e.altKey && e.code === "KeyO") {
      this.gotoAnalysisHub('collocations');
    } else if (e.shiftKey && e.altKey && e.code === "KeyN") {
      this.gotoAnalysisHub('concordance');
    } else if (e.shiftKey && e.altKey && e.code === "KeyW") {
      this.gotoAnalysisHub('word-cloud');
    } else if (e.ctrlKey && e.altKey && e.code === "KeyZ") {
      this.onMapClick();
    }
  }
  parsedText(text) {
    const dom = new DOMParser().parseFromString(
      '<!doctype html><body>' + text,
      'text/html');
    const decodedString = dom.body.textContent;
    return decodedString;
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
