/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalComponent } from './../../common/components/modal/modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../../common/modules/notification';
import { LoaderComponent } from '../modules/loader/loader/loader.component';
import { BehaviorSubject, Observable } from 'rxjs';;
const NOTIFICATIONTYPES = { SUCCESS: 'success', ERROR: 'error', INFO: 'info', WARNING: 'warn' };
@Injectable({
	providedIn: 'root'
})

export class ModalService {
  messagemodalRef: BsModalRef;
  // tslint:disable-next-line:member-ordering
  // @ViewChild('popupHolder', { read: ViewContainerRef }) public static popupHolder;
  @ViewChild('popupHolder', { static: false, read: ViewContainerRef }) public static popupHolder;

  // public static alertTemplate: TemplateRef<any>;
  // public static alertConfig: any = {
  //   animated: true,
  //   keyboard: false,
  //   backdrop: true,
  //   ignoreBackdropClick: false
  // };
  private componentRef: any;
  public pendingRequestNumber: number;
  public pendingRequests:Observable<number>;
  private prSubject: BehaviorSubject<number>;
  constructor(private bsModalService: BsModalService,
    private notificationService: NotificationService) {
      this.prSubject = new BehaviorSubject<number>(0);
      this.pendingRequests = this.prSubject.asObservable();
      this.pendingRequestNumber = 0;
  }

  showAlertPopup(title, content, okCallBack) {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    // this.componentRef = ModalService.popupHolder.createComponent(componentFactory);
    this.componentRef = ModalService.popupHolder.createComponent(ModalComponent);
    const modalInstance = <ModalComponent>this.componentRef.instance;
    modalInstance.openModal(title, content, true, () => {
      if (typeof okCallBack === 'function') {
        okCallBack();
        this.componentRef.destroy();
      }
    }, () => { });
  }

  showConfirmPopup(title, content, okCallBack, cancelCallBack) {
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    // const componentRef = ModalService.popupHolder.createComponent(componentFactory);
    const componentRef = ModalService.popupHolder.createComponent(ModalComponent)
    const modalInstance = <ModalComponent>componentRef.instance;
    modalInstance.openModal(title, content, false, () => {
      if (typeof okCallBack === 'function') {
        okCallBack();
        componentRef.destroy();
      }
    }, () => {
      if (typeof cancelCallBack === 'function') {
        cancelCallBack();
        componentRef.destroy();
      }
    });
  }

  showLoader() {
    LoaderComponent.showLoader();
  }

  hideLoader() {
    LoaderComponent.hideLoader();
  }
  addRequest() {
    this.prSubject.next(++this.pendingRequestNumber);
  }
  removeRequest() {
      this.prSubject.next(--this.pendingRequestNumber);
  }
  getRequestCount() {
    return this.pendingRequestNumber;
  }
  showNotification(type, title, content, timeOut = 6000) {
    if (NOTIFICATIONTYPES[type]) {
      this.notificationService[NOTIFICATIONTYPES[type]](title, content, { timeOut: timeOut });
    } else {

    }
  }
  hideNotification() {
    this.notificationService.clearAll();
  }

  hideModel() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  showWelcomePopup(messageTemplate) {
    this.messagemodalRef = this.bsModalService.show(messageTemplate,
      Object.assign({}, { backdrop: true, ignoreBackdropClick: false }, { class: 'gray modal-lg' })
    );
  }

  hideWelcomePopup() {
    if (this.messagemodalRef) {
      this.messagemodalRef.hide();
    }
  }
}
