<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<div class="notification has-icon {{notification.alertType.toLowerCase()}}"
[@enterLeave]="notification.animate" (click)="close()">
	<div class="notification-progress-loader">
		<span [ngStyle]="{'width': progress + '%'}"></span>
	</div>
	<div class="notification-title" [innerHTML]="notification.title"></div>

	<div class="notification-content" [innerHTML]="notification.content"></div>
	<div class="icon" [innerHTML]="icons[notification.alertType]"></div>
</div>
