
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import {
  Component, Input, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { PageSettingsService } from './../../common/services/page-settings.service';
// import { Subscription } from 'rxjs/Subscription';
import { orderBy as _orderBy, some as _some } from 'lodash';
import { Subject ,  Subscription } from 'rxjs';
import { SearchService } from '../../common/services/search.service';
import { CommonService } from '../services/common.service';
import { SharedService } from '../../common/services/sharedService';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgChartjsModule } from 'ng-chartjs';
import { RangeSliderModule } from '../../common/modules/range-slider/range-slider.module';


@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule, NgChartjsModule, RangeSliderModule],
  selector: 'app-term-popularity',
  templateUrl: './term-popularity.component.html',
  styleUrls: ['./term-popularity.component.css'],
})

export class TermPopularityComponent implements OnInit, OnDestroy, AfterContentInit {
  isLimiterChanged = false;
  unSubPrintObservable: Subscription;
   _filterInputs: any;
   private subjectObserver: Subject<void> = new Subject<void>();
  isArabicLanguage: Boolean = false;
  lang: string;
  inlinePlugin: any;
  textPlugin: any;
  @Input('filterInputs')
  set filterInputs(value) {
    this._filterInputs = value;
    this.checkIsLimitedDocs(this._filterInputs['filterParams']);
  }
  newFilterParams = [];
  showChartLoader: Boolean = false;
  chartData: any[];
  graphData: any = [];
  incomingArray: any;
  xaxisValuesArray: any = [];
  linerChartData: Boolean = false;
  private chartColors: any = ['#009891', '#4e6af1', '#1bbec6', '#61165E ', '#8a6d3b', '#e168f7', '#bc5e0b', '#860000'];
  public lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
        }
      }
    }
  };
  public lineChartColors = [];
  xaxisArray: any = [];
  yaxisArray: any = [];
  sortedArray: any = [];
  requestParams: any = [];
  filterArray: Array<any> = [];
  public lineChartData: any = [];
  public lineChartLabels: any = [];
  public lineChartLegend: Boolean = true;
  public lineChartType: String = 'line';
  // Range Slider
  facetRangeGap: any;
  private facetYearRange: any = [];
  yearToChart: Number = 100;
  yearFromChart: Number = 1;
  rangeArray: Number[] = [0, 0];
  yearFrom: any;
  yearTo: any;
  pageContent: any;
  constructor(
    private searchService: SearchService,
    private commonService: CommonService,
    private sharedService: SharedService
  ) {

    PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.subjectObserver)).subscribe((data) => {
      this.pageContent = data;
    }, err => {
    });
   }

  ngOnInit() {
    this.textPlugin = [{
      id: 'linePlugin',
      beforeDraw(chart: any): any {
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';
        const text = '';
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }];
    this.inlinePlugin = this.textPlugin;
    if (this._filterInputs.terms.length > 0) {
      this.reGraph();
    } else {
      this.createEmptyGraph();
    }
  }
  ngAfterContentInit() {
    this.commonService.printChart.pipe(takeUntil(this.subjectObserver)).subscribe(res => {
      if (res.status) {
        this.commonService.printTermFrequencyChart('Term Popularity', res.filterParams);
      }
    });
    const currentLanguage = sessionStorage.getItem('lang');
    this.lang = currentLanguage;
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      // tslint:disable-next-line:max-line-length
      takeUntil(this.subjectObserver)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
  }

  checkIsLimitedDocs(filterParams) {
    this.isLimiterChanged = _some(filterParams.filterParams, res => {
      return ((res.fieldName === 'contentType') || (res.fieldName === 'contentType') || (res.fieldName === 'collection')
      || (res.fieldName === 'sourceInstitution') || (res.fieldName === 'docLang') || (res.fieldName === 'pubStartDate'));
    });
  }
  chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if ( activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._datasetIndex;
        const label = chart.data.datasets[clickedElementIndex].label;
        const year = chart.data.labels[activePoints[0]._index];
        const searchTermFlag = [];
        const currentFilterParams = JSON.parse(JSON.stringify(this._filterInputs.filterParams.filterParams));
        currentFilterParams.archive = [];
        currentFilterParams.searchKeywords = [];
        currentFilterParams.publishedDateFrom = year.toString();
        currentFilterParams.publishedDatePrefix = 'ON';
        currentFilterParams.publishedDateIncludesNull = false;
        currentFilterParams.publishedDateTo = '';
        const newArchiveFilterArray = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
        if (newArchiveFilterArray.length > 0) {
          currentFilterParams.archive = newArchiveFilterArray[0].fieldValues;
        } else {
          currentFilterParams.archive = [];
        }
        // this.modalService.showLoader();
        this.searchService.goToAdvancedSearchPageFromTextAnalysis(label, currentFilterParams, searchTermFlag);
      }
    }
  }

  reGraph() {
    this.showChartLoader = true;
    if (this._filterInputs.preventLimiters) {
      const newArchiveFilterArray = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
      this.newFilterParams = newArchiveFilterArray;
    } else {
      this.newFilterParams = this._filterInputs.filterParams.filterParams;
    }
    if (this.newFilterParams.length > 0) {
      for(let k =0; k < this.newFilterParams.length; k++) {
        if(this.newFilterParams[k].fieldName === "archive") {
         const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
         const haveRGS = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RGS'); 
         if (haveRGS) {
          const haveRGS1 = getSelectedArchives.includes('RGS1');
          if(haveRGS1) {
            this.newFilterParams[k].fieldValues.push('RGS1');
          }
          const haveRGS2 = getSelectedArchives.includes('RGS2');
          if(haveRGS2) {
            this.newFilterParams[k].fieldValues.push('RGS2');
          }
          this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RGS',this.newFilterParams[k].fieldValues);
         }
         const haveRCP = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RCP'); 
         if (haveRCP) {
          const haveRCP1 = getSelectedArchives.includes('RCP1');
          if(haveRCP1) {
            this.newFilterParams[k].fieldValues.push('RCP1');
          }
          const haveRCP2 = getSelectedArchives.includes('RCP2');
          if(haveRCP2) {
            this.newFilterParams[k].fieldValues.push('RCP2');
          }
          this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RCP',this.newFilterParams[k].fieldValues);
         }
        }
      }
// this.newFilterParams[0].fieldValues = ['RGS1',"RCP1", "RCP2","RGS2"]
    }
    this.requestParams =  {
        groupLimit: this._filterInputs.filterParams.groupLimit,
        searchParams : this._filterInputs.filterParams.searchParams,
        filterParams: this.newFilterParams,
        groupOffset: this._filterInputs.filterParams.groupOffset,
        terms: this._filterInputs.terms,
        pubStartDate: this.pageContent.pubDates.startDate,
        pubEndDate: this.pageContent.pubDates.endDate,
    };

    const sortArray: any = [];
    this.rangeArray = [];
    this.commonService.gettermPopularityData(this.requestParams).pipe(takeUntil(this.subjectObserver)).subscribe(response => {
      this.graphData = response.results;
      response.results.forEach((element, index) => {
        this.yaxisArray = [];
        this.xaxisArray = [];
        this.lineChartOptions = [];
        this.incomingArray = element.statsResults[0].facetResults[0].facetContent;
        this.sortedArray = _orderBy(this.incomingArray,  ['value'], ['asc']);
        this.sortedArray.forEach(element1 => {
          this.xaxisArray.push(element1.value);
          this.yaxisArray.push(element1.percent);
        });
        sortArray[index] = this.sortedArray;
        this.lineChartData.push({data: this.yaxisArray, label: this._filterInputs.terms[index]});
        this.lineChartColors.push({
          borderColor: this.chartColors[index],
          pointBackgroundColor: this.chartColors[index],
          backgroundColor: 'transparent',
          fill: false,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: this.chartColors[index],
          pointHoverBorderColor: this.chartColors[index],
        });
        this.lineChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: true },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const pointData = sortArray[tooltipItem.datasetIndex][tooltipItem.index];
                // tslint:disable-next-line:max-line-length
              return data.datasets[tooltipItem.datasetIndex].label + ' : ' + pointData.count + ' of ' + pointData.totalCount + ` Document(s) (${pointData.percent}%)`;
              }
            }
          },
          scales: {
            yAxes: {
              ticks: {
                beginAtZero: true,
              }
            }
          }
        };
        this.lineChartType = 'line';
        this.linerChartData = true;
      });
      this.showChartLoader = false;
      this.rangeArray = [];
      if (this.xaxisArray.length > 0) {
        const chartArry = this.xaxisArray;
        this.yearFromChart = Math.min.apply(Math, chartArry);
        this.yearToChart = Math.max.apply(Math, chartArry);
        this.yearTo = this.yearToChart;
        this.yearFrom = this.yearFromChart;
        this.rangeArray.push(Math.min.apply(Math, chartArry));
        this.rangeArray.push(Math.max.apply(Math, chartArry));
      }
      this.lineChartLabels = this.xaxisArray;
    }, (error) => {
    });
  }
  removeSelectedValFromArray(val, arr) {
    let value = val;
    arr = arr.filter(item => item !== value)
    return arr;
  }

  sliderRangeChanged(event) {
    this.yearFrom = event[0][0];
    this.yearTo = event[0][1];
    this.filterChartData();
  }
  filterChartData() {
   this.chartData = [];
   this.filterArray = [];
    const sortArray: any = [];
    this.graphData.forEach((element, index) => {
      this.xaxisArray = [];
      this.yaxisArray = [];
      this.lineChartOptions = [];
      this.incomingArray = element.statsResults[0].facetResults[0].facetContent;
      for (const filterObj of this.incomingArray) {
        if (filterObj.value >= this.yearFrom && filterObj.value <= this.yearTo) {
          this.filterArray.push(filterObj);
        }
      }
      this.sortedArray = _orderBy(this.filterArray,  ['value'], ['asc']);
      if (this.sortedArray.length > 0) {
        for (const facetPubdateObj of this.sortedArray) {
             this.xaxisArray.push(facetPubdateObj.value);
            this.yaxisArray.push(facetPubdateObj.percent);
        }
        sortArray[index] = this.sortedArray;
        this.chartData.push({data: this.yaxisArray, label: this._filterInputs.terms[index]});
        this.lineChartColors.push({
          borderColor: this.chartColors[index],
          pointBackgroundColor: this.chartColors[index],
          backgroundColor: 'transparent',
          fill: false,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: this.chartColors[index],
          pointHoverBorderColor: this.chartColors[index],
        });
        this.lineChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: true },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const pointData = sortArray[tooltipItem.datasetIndex][tooltipItem.index];
                // tslint:disable-next-line:max-line-length
              return data.datasets[tooltipItem.datasetIndex].label + ' : ' + pointData.count + ' of ' + pointData.totalCount + ` Document(s) (${pointData.percent}%)`;
              }
            }
          },
          scales: {
            yAxes: {
              ticks: {
                beginAtZero: true,
              }
            }
          }
        };
         this.lineChartType = 'line';
      }
    });
    this.lineChartLabels = this.xaxisArray;
         setTimeout(() => {
           this.lineChartData = this.chartData;
           this.lineChartColors = this.lineChartColors;
         }, 500);
  }
  createEmptyGraph() {
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: true },
      scales : {
        yAxes: {
            ticks: {
             beginAtZero: true,
              // stepValue: 0,
              // steps: 10,
              // max : 10,
            }
        }
      }
    };
    this.lineChartLegend = false;
    this.lineChartLabels = [];
    this.lineChartData = [
      { data: [], label: '' },
    ];
    this.linerChartData = true;
  }
  ngOnDestroy() {
    this.subjectObserver.next();
    this.subjectObserver.complete();
  }
  }

