/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
/*
* envName used to configure api for EZProxy
*/
export const environment = {
    production: false,
    APIUrl: 'https://qaapi.wileydigitalarchives.com/',
    // assetsURL: '',
    email: 'prasath.arumugam@htcindia.com',
    defaultArchive: 'wiley',
    defaultLocation: 'demo',
    envName: 'qa',
    version: '3.4.0.20230919',
    techsupportMailAddress: 'techsupport@wileydigitalarchives.com',
    // tslint:disable-next-line:max-line-length
    tmpAccessToken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkb2NJZCI6Ik5ZQVNGQTAwMS1DMDAwMS1NQTAwMDAwMiIsInNjb3BlIjpbInJlc291cmNlLXNlcnZlci1kb2N1bWVudC1yZWFkIl0sImV4cCI6MzY5MjI4MjU5MSwiYXV0aG9yaXRpZXMiOlsiUk9MRV9HVUVTVCJdLCJqdGkiOiJhYjRjMTlkZi0zMDE4LTQ0MmItYmU5Zi1jOTNiYjVhM2U3MTciLCJjbGllbnRfaWQiOiJndWVzdCJ9.KxLf5mqG7AKUfiL6393y5QaEspCmeC-V4QwdGUnjS7VwLQMRwWXOaPd_Mp10asnC0japdzdZT_TtwsVmAzS9XPDsUQy3Tkyi04icLi1qirsK2mnmJizO4t8n7NWq3bjh3ZrwzK1dDjkSfkB4qgGNx3eYTCjTWfK1vsPjnxoJXqthFHEb5on1uM7xojGQT8qcyEj30HWTSszBdmTcW5XXpJrefTWzWRMIw2GL6cVLVFsJzIdMehyJi92uj6SigRdq9l6orEkliGTKlsMsVs0voVPj5ZwMFpp7ZvWB0RmVa5gv_dHKLA7UE5V1BB852yQDyzCwZ5pMsR766PQbx7vWcQ',
    versionCheckURL: './version.json',
    siteKey: '6LcAKpwUAAAAAM6kwffm3iy4CPhZS49ZnMl_66jZ',
    // siteKeyV3: '6Ld_8KkUAAAAAAyQWKbDWwoCd9p7i3HXitXBMh9K'
};
export const APIURLS = {
    explorerminmaxsearch: 'docs/explorerminmaxsearch/map',
  estimateSearchCount: 'docs/searchcount',
  advancedSearch: 'docs/advancedsearch',
//   getIllustrationsV1: 'document/illustrations/',
  getDocumentFullText: 'documents/documentfulltext?docId=',
  getRelevantPages: 'page/relevantcontents',
  fileUrlThumb: 'file/thumb/',
  fileUrlImage: 'file/imagev2/',
//   getDocumentItembyTOCV1: 'document/toc/',
//   getDocumentByItemV1: 'document/items/',
//   getDocumentItembyTypeV1: 'document/itemsbytype/',
//   getDocumentDetailV1: 'document/v3/docinfo/',
  search: 'docs/search',
  downloadFullText: 'file/fulltext/',
  saveAsTextFile: 'file/text/',
  translateEntireDoc: 'file/fulltext/',
//   translateDoc: 'file/text/',
//   getFoamClustersV1: 'documents/cluster',
  spatialSearch: 'docs/spatialsearch',
  limiters: 'limiters/',
  sendToMailClient: 'util/mail',
  statssearch: 'docs/statssearch',
  termPopularity: 'docs/termpopularity',
  shareShortUrl: 'util/shorturl',
  bookmarkShortUrl: 'util/bookmark',
  personalization: 'personalization/',
  getIp: 'auth/authtype/ip',
  config: './assets/config/locations-config.json',
  termfreqbysub: 'docs/termfreqbysub',
  spatialcluster: 'docs/spatialcluster',
//   archiveCollectionsDetails: './content/files/archive-collections-details.json',
  detectLocation: 'auth/authtype/getClientInfo',
//   getUserLocation: 'auth/authtype/getClientInfoByUser/',
//   getLocationByInstitution: 'auth/authtype/getClientInfoByInst/',
//   addThisAPIUrl: 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b459ec0a33ad8f5',
  fileTranslateText: 'file/translatetext',
//   getRestrictedCollection: 'util/restrictedcollection',
//   getRestrictedDocuments: 'util/restrictedcollectiondocs',
//   getFullTextDocumentTranslation: 'file/translatefulltext',
  getCollectionDropdownValues: 'personalization/dropdown',
  getCollectionDetails: 'personalization/collectiondetails',
  getCollectionDetailsById: 'personalization/collectionIdContent',
  authUrl2: 'auth/usercreds/v2/login',
//   getItemNotes: 'document/notes',
  getRelatedSubjects: 'subjects',
  getMetaDatasFromKeyword: 'docs/docmetadata',
  getDocumentImagesById: 'docimages',
  getDocumentNotes: 'notes',
  getGuestToken: 'auth/guestauth/',
  getPageLevelFullText: 'page/pagemetadata',
  downloadTextV2: 'file/texturl/',
  downloadTableUrlV2: 'file/tableurl/',
  downloadFullTextV2: 'file/fulltexturl',
  tileSourceServer: 'file/iiif',
//   getIllustrationsbypageV1: 'document/illustrationsbypage/',
//   getVolumesV1: '/document/volumes/',
  supportedLanguages: '../assets/json/supported-languages.json',
  wileyOnlineLibraryURL: 'https://onlinelibrary.wiley.com/action/doSearch?AllField',
//   wileyOnlineSearchAuditV1: 'documents/auditwolsearch',
  getPhotoExplorer: 'docs/explorersearch/photograph',
//   getPhotoExplorerOld: 'documents/explorersearch/Photograph',
  getTranslatefulltextstatus: 'file/translatefulltextstatus',
//   getPageFullText: 'documents/pagefulltext',
  getMapExplorer: 'docs/explorersearch/map',
//   getMapExplorerOld: 'documents/explorersearch/Map',
  getCitationRISFile: 'citation/ris/',
  getCollectionNotes: 'notes/collection',
  getFoamClusters: 'docs/cluster',
  wileyOnlineSearchAudit: 'docs/auditwolsearch',
  getDocumentItembyTOC: 'doc/toc/',
  getDocumentByItem: 'doc/items/',
  getDocumentItembyType: 'doc/itemsbytype/',
  getDocumentDetail: 'doc/v3/docinfo/',
  getIllustrations: 'doc/illustrations/',
  getIllustrationsbypage: 'doc/illustrationsbypage/',
  getVolumes: 'doc/volumes/',
//   getAnalysisOld: 'analysis/collocations',
//   getWordCloudOld: 'analysis/wordcloud',
  getArchiveCollocation: '../assets/config/archives-based-collocates.json',
  getWordCloudArchiveInit: '../assets/config/word-cloud-archive-',
//   frequencyDistributionOld: 'analysis/frequencydistribution',
//   getConcordanceOld: '/analysis/concordance',
//   getFrequencyDistributionInit: '../assets/config/fd_archive_',
//   checkCaptchaAuthentication: 'auth/authtype/validategooglecaptcha',
//   getCustomCaptcha: 'auth/authtype/captchaImg',
//   getCaptchaReload: 'auth/authtype/reloadCaptchaImg/',
//   customCaptchaVerfication: 'auth/authtype/validateCaptcha/',
  bandWidthCheckImgUrl: 'assets/config/wiley/band_width.jpg',
//   fetchWordCloudSearchCount: 'analysis/wordcloudcount',
//   fetchDistribtionSearchCount: 'analysis/frequencydistributioncount',
//   fetchCollocationSearchCount: 'analysis/collocationscount',
//   fetchConcordanceSearchCount: 'analysis/concordancecount',
  logout: 'util/signout',
  errorLogging: 'util/errorlog',
  getSuggestedTerms: 'docs/suggester',
  tileSourceServerV2: 'file/iiifv2',
  tileSourceServerV3: 'file/iiifv3',
//   fileUrlImageV1: 'file/image/',
  getTrobleshootByLocation: 'auth/authtype/',
  helpAndTrainingLink: 'https://www.wiley.com/network/wdatraining',
  getAnalysis: 'analysis/v2/collocations',
  getWordCloud: 'analysis/v2/wordcloud',
//   frequencyDistribution: 'analysis/v2/frequencydistribution',
  getConcordance: 'analysis/v2/concordance',
  // tslint:disable-next-line:max-line-length
  learnMoreLink: ' https://www.wileydigitalarchives.com/index?utm_source=WDA%20Platform%3A%20Shared%20Content&utm_medium=Link&utm_campaign=WDA%20Platform_Shared%20Content#contact',
  downloadGEOtiff: 'pagemapper/tiff/',
//   getSuggestedTermsV2: 'docs/suggesterv2',
//   getArchives: 'personalization/archives',
  shibbolethLogin: 'auth/shibboleth',
  downloadTableCSV: 'file/csvurl/',
  downloadCollection: 'file/collectionurl',
  personalizationV2: 'personalization/',
  getArchivesV2: 'personalization/archivesv2',
  getCollectionFolderContent: 'personalization/collectionFolder',
  getcollectionSeries: 'personalization/collectionSeries',
  getInsetMaps: 'docs/insetmap',
  getInsetmapPage: 'docs/insetmappage',
  shareAudit: 'pagemapper/addaudit',
  getDocumentIDReference: 'personalization/docprefix',
  getArchivesCountForFacet: 'docs/archivecountsearch',
  exportClipImages: 'util/storeclipimage',
  downloadDocument:'util/contentlinks'
};

export const GenericWarningArchives = ['NYAS', 'RCP'];

export const COMMONPATHS = {
    imageNotAvailable: './assets/images/noimage_large.jpg'
};

export const rtlLanguages = ['PERSIAN', 'URDU', 'URUDU', 'ARABIC', 'PASHTO', 'HEBREW', 'KASHMIRI'];

export const stopWords = ['a', 'about', 'above', 'after', 'again', 'against', 'all', 'am', 'an', 'and', 'any',
    'are', 'as', 'at', 'be', 'because', 'been', 'before', 'being', 'below', 'between', 'both', 'but', 'by', 'cannot', 'could',
    'did', 'do', 'does', 'doing', 'down', 'during', 'each', 'few', 'for', 'from', 'further', 'had', 'has', 'have', 'having',
    'he', 'her', 'here', 'hers', 'herself', 'him', 'himself', 'his', 'how', 'i', 'if', 'in', 'into', 'is', 'it', 'its', 'itself',
    'me', 'more', 'most', 'my', 'myself', 'no', 'nor', 'not', 'of', 'off', 'on', 'once', 'only', 'or', 'other', 'ought', 'our',
    'ours', 'ourselves', 'out', 'over', 'own', 'same', 'she', 'should', 'so', 'some', 'such', 'than', 'that', 'the', 'their',
    'theirs', 'them', 'themselves', 'then', 'there', 'these', 'they', 'this', 'those', 'through', 'to', 'too', 'under', 'until',
    'up', 'very', 'was', 'we', 'were', 'what', 'when', 'where', 'which', 'while', 'who', 'whom', 'why', 'with', 'would', 'you',
    'your', 'yours', 'yourself', 'yourselves'];

    export const DOCUMENT_ID_REFERENCE = [ 'NYAS', 'RAI', 'RCP', 'RGS', 'NYBG', 'BAAS', 'BSAU', 'BLDG', 'SHLC', 'LEED', 'UCL' ];

    export const availableArchives = ['NYAS', 'RAI', 'BAAS', 'ENV','RCP1', 'RCP2', 'RGS1', 'RGS2', 'WPA', 'WPA1'];

// tileSourceServer: 'http://ec2-34-228-143-117.compute-1.amazonaws.com/loris/'
// tileSourceServer: 'https://iiif-test.wileydigitalarchives.com/',
// tileSourceServer: 'http://wda-iiif-external-test-1170702741.us-east-1.elb.amazonaws.com/loris/',
// tileSourceServer: 'https://iiif-test.wileydigitalarchives.com/',
