<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->

<div class="col-md-12 col-sm-12">
  <div class="backto-hom-div">
    <i aria-hidden="true" class="fa fa-angle-left backto-homeicn"></i>
    <a
      class="backto-home-link"
      [routerLink]="homePageUrl"
      (click)="backtoHome()"
      >{{ "Common.BackToHome" | translate }}</a
    >
  </div>
</div>
<div class="unauthorized-page-body">
  <div class="unauthorized-page-div">
    <h2 class="unauthorized-page-content">
      {{ "Common.unauthorized" | translate }}.
    </h2>
  </div>
</div>
