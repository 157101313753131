
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { AdvancedFilterParams } from '../../common/models/AdvancedFilterParams';
// import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { ExplorerCoverflowService } from './services/explorer-coverflow.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchService } from '../../common/services/search.service';
import { PageSettingsService } from '../../common/services/page-settings.service';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedService } from '../../common/services/sharedService';
import { UrlUtilityService } from '../../common/services/url-utility.service';
declare let $: any;

const defaultRowSize = 3;
@Component({
  selector: 'app-explorer-coverflow',
  templateUrl: './explorer-coverflow.component.html',
  styleUrls: ['./explorer-coverflow.component.css'],
  providers: [ExplorerCoverflowService]
})
export class ExplorerCoverflowComponent implements OnInit, AfterViewInit, OnDestroy {
  ignoreFullTexts: Boolean = false;
  mapCoordinates: Boolean = false;
  // coverflowLoader: Boolean = false;
  currentSelecteddocId: any;
  _toggleCoverFlow: any = { show: false };
  currentDocumentsCountInViewPort = 0;
  currentStartValue = 0;
  isFromResultsPage: Boolean = false;
  // currentOffsetForCoverflowDocument = 0;
  isHidePrevious: Boolean = false;
  isHideNext: Boolean = false;
  currentSelectedpageId: any = null;
  previousOffset: any = 0;
  startValueforCoverflow = 0;
  // public config: PerfectScrollbarConfigInterface = {};
  isArabicLanguage = false;
  homePageUrl: string;
  isFromMapExplorer: Boolean = false;
  isFromPhotoExplorer: Boolean = false;
  copyAdvancedFilterParam: any;
  totalRecoards: any = 0;
  _albums = [];
  searchTerm: String = '';
  _currentContentType;
  @Input() spatialFilterParams: any;

  @Input()
  set toggleCoverFlow(val: any) {
    if (val) {
      if (this._toggleCoverFlow.show) {
        this._toggleCoverFlow = { show: false };
      } else {
        this._toggleCoverFlow = { show: true };
        if (!this._albums || this._albums.length === 0) {
          this.getExplorerData(this.searchTerm, this.startValueforCoverflow);
        }
      }
    }
  }
  @Input()
  set hideCoverFlow(val: any) {
    // this._toggleCoverFlow = { show: false };
  }
  @Input('contentType')
  set currentContentType(contentType: any) {
    if (contentType) {
      this._currentContentType = contentType;
    }
  }
  get currentContentType() {
    return this._currentContentType;
  }

  _advParamsForSearchResults;
  @Input('advancedFilterParamForCoverFlow')
  set advancedFilterParamForCoverFlow(advParams: any) {
    if (advParams) {
      this._advParamsForSearchResults = advParams;
    }
  }
  get advancedFilterParamForCoverFlow() {
    return this._advParamsForSearchResults;
  }
  @Input('start')
  set start(value: any) {
    if (value) {
      this.previousOffset = this.startValueforCoverflow = +value;
    }
  }
  get advancedFilterParamFstartorCoverFlow() {
    return this.previousOffset = this.startValueforCoverflow;
  }

  @Input('documentData')
  set documentData(documentData: any) {
    if (documentData) {
      this.currentSelecteddocId = documentData['wileyId'];
    }
  }
  get documentData() {
    return this.currentSelecteddocId;
  }
@Input('mapCoordinatesNotNull')
set mapCoordinatesNotNull(mapData: any) {
  this.mapCoordinates = mapData;
}
get mapCoordinatesNotNull() {
  return this.mapCoordinates;
}
@Input('ignoreFullText')
set ignoreFullText(fulltext: any) {
  this.ignoreFullTexts = fulltext;
}
get ignoreFullText() {
  return this.ignoreFullTexts;
}



  @Input('dynamicComponentData')
  set _dynamicComponentData(params) {
    if (params) {
      if (params['mapCoordinatesNotNull']) {
        this.mapCoordinates = params['mapCoordinatesNotNull'];
      }
      if (params['ignoreFullText']) {
        this.ignoreFullTexts = params['ignoreFullText'];
      }
      if (params['searchTerm']) {
        this.searchTerm = params['searchTerm'];
      }
      if (params['spatialFilterParams'] && params['spatialFilterParams'] !== 'undefined') {
        this.spatialFilterParams = JSON.parse(params['spatialFilterParams']);
      }
      if (params['start']) {
        this.previousOffset = this.startValueforCoverflow = +params['start'];
      }
      if (params['pageId']) {
        this.currentSelectedpageId = params['pageId'];
      }
      if (params['docId']) {
        this.currentSelecteddocId = params['docId'];
      }
      if (params['isFromResultsPage']) {
        this.isFromResultsPage = true;
      }
    }
  }
  get _dynamicComponentData() {
    return this.currentSelecteddocId;
  }
  private stop$: Subject<void> = new Subject<void>();
  constructor(
    private explorerService: ExplorerCoverflowService,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private router: Router,
    private sharedService: SharedService,
    private urlUtilityService: UrlUtilityService,
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      if (params['isFromPhotoExplorer'] || params['isFromMapExplorer']) {
        this.copyAdvancedFilterParam = new AdvancedFilterParams(JSON.parse(sessionStorage.getItem('explorerLimiter')));
      } else {
        const filterParams = params['filterParam'] || params['filter'];
        this.urlUtilityService.parseUrl(filterParams).pipe(takeUntil(this.stop$)).subscribe((data) => {
          this.copyAdvancedFilterParam = new AdvancedFilterParams(data);
        });
      }
    });

    setTimeout(() => {
      this.homePageUrl = '/';
      this.homePageUrl += (PageSettingsService.pageSettings.currentArchive + '').toLowerCase();
    });
  }
  ngOnInit() {
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      if (params['searchTerm']) {
        this.searchTerm = params['searchTerm'];
      }
      if (params['isFromPhotoExplorer']) {
        this.isFromPhotoExplorer = true;
      }
      if (params['isFromMapExplorer']) {
        this.isFromMapExplorer = true;
      }
      if (params['spatialFilterParams'] && params['spatialFilterParams'] !== 'undefined') {
        this.spatialFilterParams = JSON.parse(params['spatialFilterParams']);
      }
      if (params['start']) {
        this.previousOffset = this.startValueforCoverflow = +params['start'];
        // this.currentOffsetForCoverflowDocument = +params['start'];
      }
      if (params['pageId']) {
        this.currentSelectedpageId = params['pageId'];
      }
      if (params['docID']) {
        this.currentSelecteddocId = params['docID'];
      }
      if (params['isFromResultsPage']) {
        this.isFromResultsPage = true;
      }
    });
  }
  ngAfterViewInit() {
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
   }
  /*
  * @DESC:
  * validate request service either photo / map / search service
  * remove empty space from search term
  * @params: search term, start value
  * */
  getExplorerData(searchTerm, startValue) {
    this.activatedRoute.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      if (params['isFromResultsPage']) {
        this.isFromResultsPage = true;
      }
    });
    // this.modlService.showLoader();
    // this.showLoader();
    if (searchTerm) {
      searchTerm = searchTerm.replace(/\s\s+/g, '');
    }
    this.getExplorerDataFromExplorerService(searchTerm, startValue);
  }

  /*
  * @DESC: get photo from service
  * @params: search term, start offset, adv.param, spacial filters if it`s avail
  * @output: array of data with image urls
  * */
  getPhotoExplorerData(searchTerm, startValue) {
    this.explorerService.getPhotoExplorer(this.copyAdvancedFilterParam, searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        this._albums = res.imageUrls;
        this.currentStartValue = +startValue;
        this.currentDocumentsCountInViewPort = res.imageUrls.length;
      }
      // this.modlService.hideLoader();
      // this.hideLoader();
    }, (error) => {
      // this.modlService.hideLoader();
        // this.hideLoader();
    }, startValue, this.spatialFilterParams);
  }
  /*
  * @DESC: get map from service
  * @params: search term, start offset, adv.param, spacial filters if it`s avail
  * @output: array of data with image urls
  * */
  getMapExplorerData(searchTerm, startValue) {
    this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, searchTerm, res => {
      // this.modlService.hideLoader();
      // this.hideLoader();
      if (res) {
        this.totalRecoards = res.totalRecoards;
        this._albums = res.imageUrls;
        this.currentStartValue = +startValue;
        this.currentDocumentsCountInViewPort = res.imageUrls.length;
        if (this.previousOffset + this._albums.length === this.totalRecoards) {
          this.isHideNext = true;
          return true;
        }
      }
    }, (error) => {
      // this.modlService.hideLoader();
        // this.hideLoader();
    }, startValue, this.spatialFilterParams, this.mapCoordinates, this.ignoreFullTexts );
  }
  /*
  * @DESC: get documents from service
  * @params: search term, start offset, adv.param, spacial filters if it`s avail
  * @output: array of data with document list
  * */
  getSearchResultData(searchTerm, startValue) {
    this.explorerService.advancedSearch(searchTerm, this._advParamsForSearchResults, res => {
      if (res) {
        this.totalRecoards = res.count;
        this._albums = res.data;
        this.currentStartValue = +startValue;
        if (res.data) {
          this.currentDocumentsCountInViewPort = res.data.length;
        }
      }
      // this.modlService.hideLoader();
      // this.hideLoader();
    }, (error) => {
      // this.modlService.hideLoader();
        // this.hideLoader();
      }, startValue, this._currentContentType, defaultRowSize , this.spatialFilterParams);
  }
  /*
  * @desc: validate requested data and redirect to specific function
  * @input: search term and start index
  */

  getExplorerDataFromExplorerService(searchTerm, startValue) {
    switch (true) {
      case this.isFromPhotoExplorer:
        this.getPhotoExplorerData(searchTerm, startValue);
        break;
      case this.isFromMapExplorer:
        this.getMapExplorerData(searchTerm, startValue);
        break;
      case this.isFromResultsPage:
        this.getSearchResultData(searchTerm, startValue);
        break;
    }
  }
  /*
  * @desc: navigate to document details page based on document id and specific page number
  * @input: document id, pageid and index of the document
  */
  gotoDocumentDetails(documentId, pageId, index) {
    const data = { searchTerm: this.searchTerm || '' };
    let advancedFilterParams: any;
    if (this.isFromMapExplorer || this.isFromPhotoExplorer) {
      advancedFilterParams = new AdvancedFilterParams(JSON.parse(sessionStorage.getItem('explorerLimiter')));
    } else {
      advancedFilterParams = this.copyAdvancedFilterParam;
    }
    this.searchService.buildFilterParams(advancedFilterParams, (param) => {
      this.searchService.buildURL(param, (url) => {
        data['filterParam'] = url;
        data['start'] = this.previousOffset + index;
        data['pageId'] = pageId ? pageId : '';
        if (documentId) {
          data['viewPage'] = documentId;
          data['docID'] = documentId;
        }
        if (this.spatialFilterParams) {
          data['spatialFilterParams']  = this.spatialFilterParams;
        }
        switch (true) {
          case (this.isFromPhotoExplorer):
            data['isFromPhotoExplorer'] = true;
            break;
          case (this.isFromMapExplorer):
            data['isFromMapExplorer'] = true;
            break;
          case (this.isFromResultsPage):
            data['isFromResultsPage'] = true;
            break;
        }
        this.router.navigate([`${this.homePageUrl}/detail/${documentId}`, data]);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        this._toggleCoverFlow = { show: false };
      });
    });
  }
  /*
  * @desc: scroll coverflow display (perfect scroll bar) area to right side
  * */
  coverFlowScrollToRight() {
    const scrollPosition = $('.search-results-image').scrollLeft();
    const coverFlowImageParantDivWidth = $('#coverFlow0').width();
    if ((window.innerWidth <= 1320) && (window.innerWidth >= 768)) {
      $('.search-results-image').animate({ scrollLeft: scrollPosition + (coverFlowImageParantDivWidth * 2) }, 'linear');
    } else if ((window.innerWidth <= 768)) {
      $('.search-results-image').animate({ scrollLeft: scrollPosition + (coverFlowImageParantDivWidth * 1) }, 'linear');
    } else {
      $('.search-results-image').animate({ scrollLeft: scrollPosition + (coverFlowImageParantDivWidth * 3) }, 'linear');
    }
  }
  /*
  * @desc: scroll coverflow display (perfect scroll bar) area to left side
  * */
  coverFlowScrollToLeft() {
    const scrollPosition = $('.search-results-image').scrollLeft();
    const coverFlowImageParantDivWidth = $('#coverFlow0').width();
    $('.search-results-image').animate({ scrollLeft: 0 }, 'linear');
  }
  /*
  * @desc: scroll coverflow display (perfect scroll bar) area to right side end
  * */
  coverFlowScrollToRightEnd() {
    setTimeout(() => {
      const scrollPosition = $('.search-results-image').scrollLeft();
      const coverFlowImageParantDivWidth = $('#coverFlow0').width();
      $('.search-results-image').animate({ scrollLeft: scrollPosition + (coverFlowImageParantDivWidth * this._albums.length) }, 'linear');
    }, 2000);
  }
  /*
  * @desc: scroll coverflow display (perfect scroll bar) area scroll tpo 3px
  * We faced problem on loading previous document, at this time scroll not generated
  * */
  coverFlowScrollInit() {
    $('.search-results-image').animate({ scrollLeft: 3 }, 'linear');
  }
  /*
  * @DESC: get previous photos from service based offset
  * @params: search term, start offset, adv.param, spacial filters if it`s avail
  * @output: array of data with image urls
  * */
  loadPreviousPhotos() {
    // this.modlService.showLoader();
    // this.showLoader();
    let rows;
    if (window.innerWidth <= 768) {
      this.currentStartValue = this.previousOffset -= 1;
    } else {
      if (this.previousOffset < 3) {
        rows = this.previousOffset;
        this.previousOffset = this.currentStartValue = 0;
      } else {
        this.currentStartValue = this.previousOffset -= 3;
      }
    }
    this.coverFlowScrollInit();
    this.explorerService.getPhotoExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        this._albums = [...res.imageUrls, ...this._albums];
      }
      this.coverFlowScrollToLeft();
      // this.modlService.hideLoader();
      // this.hideLoader();
    }, (error) => {
      this.coverFlowScrollToLeft();
      // this.modlService.hideLoader();
        // this.hideLoader();
    }, this.previousOffset, this.spatialFilterParams, rows);
  }
    /*
  * @DESC: get previous map from service based offset
  * @params: search term, start offset, adv.param, spacial filters if it`s avail
  * @output: array of data with image urls
  * */
  loadPreviousMaps() {
    // this.modlService.showLoader();
    // this.showLoader();
    let rows;
    if (window.innerWidth <= 768) {
      this.currentStartValue = this.previousOffset -= 1;
    } else {
      if (this.previousOffset < 3) {
        rows = this.previousOffset;
        this.previousOffset = this.currentStartValue = 0;
      } else {
        this.currentStartValue = this.previousOffset -= 3;
      }
    }
    this.coverFlowScrollInit();
    this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
      if (res) {
        this.totalRecoards = res.totalRecoards;
        this._albums = [...res.imageUrls, ...this._albums];
      }
      this.coverFlowScrollToLeft();
      // this.modlService.hideLoader();
      // this.hideLoader();
    }, (error) => {
      this.coverFlowScrollToLeft();
      // this.modlService.hideLoader();
        // this.hideLoader();
    }, this.previousOffset, this.spatialFilterParams, this.mapCoordinates, this.ignoreFullTexts, rows);
  }
    /*
  * @DESC: get documents based on offet value and rows from service
  * @params: search term, start offset, adv.param, spacial filters if it`s avail
  * @output: array of data with document list
  * */
 loadPreviousDocuments() {
  //  this.modlService.showLoader();
  //  this.showLoader();
   let rows;
   if (window.innerWidth <= 768) {
    this.currentStartValue = this.previousOffset -= 1;
   } else {
    if (this.previousOffset < 3) {
      rows = this.previousOffset;
      this.previousOffset = this.currentStartValue = 0;
    } else {
      this.currentStartValue = this.previousOffset -= 3;
    }
   }
  //  if (this.previousOffset < 3) {
  //    rows = this.previousOffset;
  //    this.previousOffset = this.currentStartValue = 0;
  //  } else {
  //    this.currentStartValue = this.previousOffset -= 3;
  //  }
   this.coverFlowScrollInit();
   this.explorerService.advancedSearch(this.searchTerm, this._advParamsForSearchResults, res => {
     if (res) {
       this.totalRecoards = res.count;
       this._albums = [...res.data, ...this._albums];
     }
     this.coverFlowScrollToLeft();
    //  this.modlService.hideLoader();
    //  this.hideLoader();
   }, (error) => {
     this.coverFlowScrollToLeft();
    //  this.modlService.hideLoader();
      //  this.hideLoader();
     }, this.previousOffset, this._currentContentType, rows, this.spatialFilterParams);
 }
  /*
  * @desc: validate requested data and redirect to specific function to load previous set of values
  * @input: search term and start index
  */
  loadPrevious() {
    if (this._albums.length === 0 || this.previousOffset === 0 || this._albums.length === this.totalRecoards) {
      this.isHidePrevious = true;
      return true;
    }
    switch (true) {
      case this.isFromPhotoExplorer:
        this.loadPreviousPhotos();
        break;
      case this.isFromMapExplorer:
        this.loadPreviousMaps();
        break;
      case (this.isFromResultsPage):
        this.loadPreviousDocuments();
        break;
    }

  }
  /*
  * @desc: validate requested data and redirect to specific function to load next set of values
  * @input: search term and start index
  */
  loadNext() {
    if ((this.previousOffset + this._albums.length) === this.totalRecoards || this._albums.length === this.totalRecoards) {
        this.isHideNext = true;
        return true;
    }
    switch (true) {
      case this.isFromPhotoExplorer:
        this.loadMorePhotos();
        break;
      case this.isFromMapExplorer:
        this.loadMoreMaps();
        break;
      case (this.isFromResultsPage):
        this.loadMoreDocuments();
        break;
    }
  }

  /*
  * @desc: load next set of photos
  * @input: search term, start offset, Adv.parmas and filter params if avail
  * */
 loadMorePhotos() {
   let startOffset = this.startValueforCoverflow;
   if (window.innerWidth <= 768) {
    this.startValueforCoverflow += 1;
   } else {
    if (this.startValueforCoverflow < 3) {
      this.startValueforCoverflow += 3;
    } else {
      this.startValueforCoverflow += 3;
    }
  }
   this.coverFlowScrollToRightEnd();
   startOffset = this.currentStartValue = this.startValueforCoverflow;
  //  this.modlService.showLoader();
  //  this.showLoader();
   this.explorerService.getPhotoExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
     if (res) {
       this.totalRecoards = res.totalRecoards;
       this._albums = this._albums.concat(res.imageUrls);
     }
    //  this.modlService.hideLoader();
    //  this.hideLoader();
     if (this.previousOffset + this._albums.length === this.totalRecoards) {
       this.isHideNext = true;
       return true;
     }
     this.coverFlowScrollToRight();
   }, (error) => {
     this.coverFlowScrollToRight();
    //  this.modlService.hideLoader();
    // this.hideLoader();
   }, startOffset, this.spatialFilterParams);
 }
  /*
  * @desc: load next set of maps
  * @input: search term, start offset, Adv.parmas and filter params if avail
  * */
 loadMoreMaps() {
   let startOffset = this.startValueforCoverflow;
   if (window.innerWidth <= 768) {
    this.startValueforCoverflow += 1;
   } else {
    if (this.startValueforCoverflow < 3) {
      this.startValueforCoverflow += 3;
    } else {
      this.startValueforCoverflow += 3;
    }
   }
   startOffset = this.startValueforCoverflow;
   if (startOffset >= this.totalRecoards) {
     this.isHideNext = true;
     return true;
   }
   this.coverFlowScrollToRightEnd();
   startOffset = this.currentStartValue = this.startValueforCoverflow;
  //  this.modlService.showLoader();
  //  this.showLoader();
   this.explorerService.getMapExplorer(this.copyAdvancedFilterParam, this.searchTerm, res => {
     if (res) {
       this.totalRecoards = res.totalRecoards;
       this._albums = this._albums.concat(res.imageUrls);
     }
    //  this.modlService.hideLoader();
    //  this.hideLoader();
     this.coverFlowScrollToRight();
   }, (error) => {
     this.coverFlowScrollToRight();
    //  this.modlService.hideLoader();
      //  this.hideLoader();
   }, startOffset, this.spatialFilterParams, this.mapCoordinates, this.ignoreFullTexts);
 }
   /*
  * @desc: load next set of photos
  * @input: search term, start offset, Adv.parmas and filter params if avail
  * */
 loadMoreDocuments() {
   let startOffset = this.startValueforCoverflow;

  if (window.innerWidth <= 768) {
    if (this.startValueforCoverflow < 3) {
      this.startValueforCoverflow += 1;
    } else {
      this.startValueforCoverflow += 1;
    }
    if (startOffset >= this.totalRecoards) {
      this.isHideNext = true;
      return true;
    }
    startOffset = this.currentStartValue = this.startValueforCoverflow;
    this.coverFlowScrollToRightEnd();
    // this.showLoader();
    this.explorerService.advancedSearch(this.searchTerm, this._advParamsForSearchResults, res => {
      if (res) {
        this.totalRecoards = res.count;
        this._albums = this._albums.concat(res.data);
      }
      // this.hideLoader();
      this.coverFlowScrollToRight();
    }, (error) => {
      this.coverFlowScrollToRight();
        // this.hideLoader();
      }, startOffset, this._currentContentType, defaultRowSize, this.spatialFilterParams);
  } else {
    if (this.startValueforCoverflow < 3) {
      this.startValueforCoverflow += 3;
    } else {
      this.startValueforCoverflow += 3;
    }
    if (startOffset >= this.totalRecoards) {
      this.isHideNext = true;
      return true;
    }
    startOffset = this.currentStartValue = this.startValueforCoverflow;
    this.coverFlowScrollToRightEnd();
    // this.showLoader();
    this.explorerService.advancedSearch(this.searchTerm, this._advParamsForSearchResults, res => {
      if (res) {
        this.totalRecoards = res.count;
        this._albums = this._albums.concat(res.data);
      }
      // this.hideLoader();
      this.coverFlowScrollToRight();
    }, (error) => {
      this.coverFlowScrollToRight();
        // this.hideLoader();
      }, startOffset, this._currentContentType, defaultRowSize, this.spatialFilterParams);
  }


 }
 /*
 * @desc: close coverflow panel
 */
  closeCoverFlow() {
    this._toggleCoverFlow = { show: false };
    this.explorerService.coverFlowClosed();
  }
  // showLoader() {
  //   this.coverflowLoader = true;
  // }
  // hideLoader() {
  //   this.coverflowLoader = false;
  // }
  ngOnDestroy(): void {
    this.totalRecoards = null;
    this._albums = [];
    this.stop$.next();
    this.stop$.complete();
  }
}
