
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable, OnDestroy } from '@angular/core';
import { ModalService } from '../../common/services/modal.service';
import { CARROT_CIRCLE_OBJECT } from './models/carrotCircles.constant';
import { FOAM_TREE_OBJECT } from './models/formTree.constant';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../common/services/sharedService';
import { OnInit } from '@angular/core';
import { HttpService } from '../../common/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { PageSettingsService } from '../../common/services/page-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
declare let $: any;
@Injectable()
export class TermGroupService implements OnDestroy, OnInit {
    public static dataLoader = new Subject();
    public static detailsLoader = new Subject();
    selectedTerm: any;
    translatedValues: any;
    private advancedParams: any;
    private groups: any;
    private foamTreeObject: any;
    private carrotSearchCirclesObject: any;
    private resultSet: any[] = [];
    accessToken: any;
    resetToggle = new Subject();
    noResultFound: any;
    private loader = false;
    clusterCache$ = {};
    // stop$: Subject<any> = new Subject();
    stop$: Subject<void> = new Subject<void>();
    stopAdv$: Subject<any> = new Subject();
    constructor(
        private modalService: ModalService,
        private sharedService: SharedService,
        private httpService: HttpService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private commonService: CommonService
    ) {
        this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
            this.translatedValues = res;
        });
        this.resultSet = [];
        this.foamTreeObject = null;
        this.carrotSearchCirclesObject = null;
        const TGLastTileIfo = sessionStorage.getItem('TGLastTileIfo');
        if (TGLastTileIfo) {
            const TGLastTileIfoObj: any = JSON.parse(TGLastTileIfo);
            TermGroupService.detailsLoader.next(true);
            this.getClusterDetails(TGLastTileIfoObj.params, TGLastTileIfoObj.advancedParams);
        }
    }

    ngOnInit() {
        this.resultSet = [];
        this.foamTreeObject = null;
        this.carrotSearchCirclesObject = null;

    }
    /*
    * Initalize carrot circles if it`s supported
    */
    initCircles() {
        if (CarrotSearchCircles.supported) {
            if (this.carrotSearchCirclesObject) {
                this.carrotSearchCirclesObject.dispose();
            }
            this.carrotSearchCirclesObject = new CarrotSearchCircles(CARROT_CIRCLE_OBJECT);
            const classObject = this;
            this.carrotSearchCirclesObject.set('onGroupClick', function (info) {
                if (info) {
                    classObject.selectedTerm = '"' + info.group.label + '"';
                }
                classObject.carrotSearchCirclesObject.set('selection', { all: true, selected: false });
                classObject.carrotSearchCirclesObject.set('selection', { groups: [info.group.id], selected: true });
                sessionStorage.setItem('selectedGroup', JSON.stringify(info.group));
                classObject.getClusterDetails(info.group.docs, classObject.advancedParams);
            });
            this.loadCircleData();
        } else {
            this.modalService.showNotification('ERROR', 'ERROR', this.translatedValues.visualizationSupported);
        }
        TermGroupService.dataLoader.next(false);
    }
    /*
    * Initalize carrot foam tree if it`s supported
    */
    initFoamTree() {
        if (CarrotSearchFoamTree.supported) {
            if (this.foamTreeObject) {
                this.foamTreeObject.dispose();
            }
            this.foamTreeObject = new CarrotSearchFoamTree(FOAM_TREE_OBJECT);
            const classObject = this;
            this.foamTreeObject.set({
                backgroundColor: '#fff',
                rainbowStartColor: 'hsla(  0, 100%, 50%, 1)',
                rainbowEndColor: 'hsla(360, 100%, 50%, 1)',
                onRedraw: function () {
                    TermGroupService.dataLoader.next(false);
                },
                onGroupDoubleClick: function (e) {
                    e.preventDefault();
                    const group = e.secondary ? e.bottommostOpenGroup : e.topmostClosedGroup;
                    let toZoom;
                    if (group) {
                        this.open({ groups: group, open: !e.secondary });
                        toZoom = e.secondary ? group.parent : group;
                    } else {
                        toZoom = this.get('dataObject');
                    }
                    this.zoom(toZoom);
                },
                onGroupClick: function (event, groupIds, docIds) {
                    if (event.group.unselectable) {
                        // event.preventDefault();
                    }
                },
                onGroupSelectionChanged: function (groupIds, docIds) {

                    if (groupIds) {
                        if (groupIds.groups.length > 0) {
                            sessionStorage.setItem('selectedGroup', JSON.stringify(groupIds.groups[0]));
                            classObject.selectedTerm = '"' + groupIds.groups[0].label + '"';
                            classObject.getClusterDetails(groupIds.groups[0].docs, classObject.advancedParams);
                        } else {
                            sessionStorage.removeItem('selectedGroup');
                            sessionStorage.removeItem('TGLastTileIfo');
                            classObject.sharedService.clusterDataReceived([]);
                            classObject.resetSelectedTileInfo();
                            // classObject.commonService.setDisablePrintDownload({ disable: true });
                        }
                    }
                    classObject.resetToggle.next([]);
                }
            });
            this.loadFoamTreeData();
        } else {
            this.modalService.showNotification('ERROR', 'ERROR', this.translatedValues.visualizationSupported);
        }
    }

    loadCircleData() {
        if (this.resultSet.length > 0) {
            if (this.carrotSearchCirclesObject) {
                this.carrotSearchCirclesObject.set('dataObject',
                    { open: false, backgroundColor: 'rgba(0,0,0,0)', groupHoverHierarchy: true, groups: this.convert(this.resultSet) }
                );
                this.carrotSearchCirclesObject.redraw();
            } else {
                this.initCircles();
            }
        }
    }

    loadFoamTreeData() {
        if (this.resultSet.length > 0) {
            if (this.foamTreeObject) {
                this.foamTreeObject.set('dataObject', {
                    open: false,
                    groupHoverHierarchy: true,
                    groups: this.convert(this.resultSet),
                    keepPrevious: false
                });
                this.foamTreeObject.redraw();
            } else {
                this.initFoamTree();
            }
        }
    }
    convert(clusters) {
        const that = this;
        return clusters.map(function (cluster) {
            return {
                id: cluster.index,
                label: cluster.label,
                weight: cluster['score'] ? cluster['score'] : 0,
                groups: cluster.subcluster ? that.convert(cluster.subcluster) : [],
                unselectable: cluster.subcluster ? true : false,
                docs: cluster.docs,
                selected: cluster.selected ? true : false,
                open: cluster.open ? true : false,
                exposed: cluster.exposed ? true : false,
            };
        });
    }

    getClusterData(advancedParams: any, formTreeTabIndex?: any, terms: Array<any> = []) {
        const lastSelectedTiles: any = sessionStorage.getItem('selectedGroup');
        if (lastSelectedTiles && lastSelectedTiles !== 'undefined') {
        } else {
            setTimeout(() => {
                this.resetVisualization();
            }, 100);
        }
        this.sharedService.clusterDataReceived([]);
        this.advancedParams = advancedParams;
        if (advancedParams) {
            advancedParams.filterParams.forEach(obj => {
                if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
                    obj.condition = 'All';
                    obj.fieldValue = '';
                }
            });
            advancedParams['terms'] = terms;
            if (terms && terms.length > 0) {
                advancedParams.searchParams.push({ 'fieldName': 'ALL', 'fieldValues': terms || [], 'operator': 'AND' });
            }
            if (this.clusterCache$[JSON.stringify(advancedParams)]) {
                let lastSelectedTile: any = sessionStorage.getItem('selectedGroup');
                if (lastSelectedTile && lastSelectedTile !== 'undefined') {
                    lastSelectedTile = JSON.parse(lastSelectedTile);
                }
                this.resultSet = this.clusterCache$[JSON.stringify(advancedParams)];
                this.resultSet = this.resultSet.map((res, index) => {
                    res = this.processclusterData(res, index, lastSelectedTile);
                });
                if (formTreeTabIndex === 0) {
                    this.initFoamTree();
                } else {
                    this.initCircles();
                }
                if (this.resultSet.length === 0) {
                    $('.groups_frame-loader').addClass('hide-terms-loader');
                    this.groups = [];
                    this.sharedService.clusterDataReceived(this.groups);
                    this.commonService.setDisablePrintDownload({ disable: true });
                } else {
                    this.commonService.setDisablePrintDownload({ disable: false });
                }
                TermGroupService.dataLoader.next(false);
                return;
            }
            // this.modalService.showLoader();
            TermGroupService.dataLoader.next(true);
            this.commonService.getClusterData(advancedParams).pipe(takeUntil(this.stop$)).subscribe(response => {
                if (response.status === 'SUCCESS') {
                    this.clusterCache$[JSON.stringify(advancedParams)] = response.results.cluster;
                    if (!response.results || response.results.cluster.length === 0) {
                        this.stop$.next();
                        this.stop$.complete();
                        $('.groups_frame-loader').addClass('hide-terms-loader');
                        this.groups = [];
                        this.sharedService.clusterDataReceived([]);
                        this.commonService.setDisablePrintDownload({ disable: true });
                    } else {
                        this.commonService.setDisablePrintDownload({ disable: false });
                        let lastSelectedTile: any = sessionStorage.getItem('selectedGroup');
                        if (lastSelectedTile && lastSelectedTile !== 'undefined') {
                            lastSelectedTile = JSON.parse(lastSelectedTile);
                        }
                        response.results.cluster.forEach((res, index) => {
                            res = this.processclusterData(res, index, lastSelectedTile);
                        });
                        this.resultSet = response.results.cluster;
                        (formTreeTabIndex === 0) ? this.initFoamTree() : this.initCircles();
                    }
                } else if (response.status === 'FAILURE') {
                    this.commonService.setDisablePrintDownload({ disable: true });
                    this.modalService.showNotification(response.results[0].type, '', response.results[0].message);
                }
                TermGroupService.dataLoader.next(false);
            }, error => {
                TermGroupService.dataLoader.next(false);
            });
        }
    }


    getClusterDetails(params: any, advancedParams: any) {
        if (params.length > 50) {
            if (params.length > 150) {
                params = params.slice(0, 149);
            } else if (params.length > 100) {
                params = params;
            } else {
                params = params;
            }
        }
        sessionStorage.setItem('TGLastTileIfo', JSON.stringify({ params: params, advancedParams: advancedParams }));
        this.accessToken = this.httpService.getCurrentAccessToken();
        // this.modalService.showLoader();
        let lastSelectedTile: any = sessionStorage.getItem('selectedGroup');
        if (lastSelectedTile && lastSelectedTile !== 'undefined') {
            lastSelectedTile = JSON.parse(lastSelectedTile);
            this.resultSet.forEach((res, index) => {
                res = this.processclusterData(res, index, lastSelectedTile);
            });
        }
        this.groups = [];
        this.sharedService.clusterDataReceived(this.groups);
        const data = advancedParams || { 'searchParams': [{}] };
        data['searchParams'][0] = { fieldName: 'id', operator: 'AND', 'condition': 'EQUAL', fieldValues: params };
        data['groupLimit'] = 20;
        data.filterParams.forEach(obj => {
            if (obj.fieldName === 'pubStartDate' && obj.fieldValue === 'All') {
                obj.condition = 'All';
                obj.fieldValue = '';
            }
        });
        TermGroupService.detailsLoader.next(true);
        this.commonService.getClusterSearchResult(data).pipe(takeUntil(this.stop$ || this.stopAdv$)).subscribe(response => {
            if (response.status === 'SUCCESS') {
                const newResult = response.results.groupResults[0].groupEntry.groupEntryContent;
                if (newResult.length > 0) {
                    this.groups = response.results.groupResults[0].groupEntry.groupEntryContent.map(group => {
                        group.documents = group.documents.map(document => {
                            document.imageThumbnails = document.imageThumbnails.map((thumb) => {
                                if (!thumb.includes('?access_token')) {
                                    if (thumb.split('_').length > 1) {
                                        return environment.APIUrl + 'file/thumb/' + document.id +
                                            '/' + thumb.split('_')[0] + '?type=small&access_token=' + this.accessToken + '&WDALoc=' +
                                            localStorage.getItem('currentLocation') || environment.defaultLocation;
                                    } else {
                                        return thumb;
                                    }

                                } else {
                                    return thumb;
                                }
                            });
                            return document;
                        });
                        return group;
                    });
                }
            } else if (response.status === 'FAILURE') {
                this.groups = [];
                this.modalService.showNotification(response.results[0].type, '', response.results[0].message);
            }
            this.sharedService.clusterDataReceived(this.groups);
            TermGroupService.detailsLoader.next(false);
        }, err => {
            TermGroupService.detailsLoader.next(false);
        });
    }

    checkFoamTreeObject() {
        if (this.foamTreeObject) {
            return true;
        } else {
            return false;
        }
    }
    checkCircleObject() {
        if (this.carrotSearchCirclesObject) {
            return true;
        } else {
            return false;
        }
    }

    resetVisualization() {
        if (this.foamTreeObject) {
            this.foamTreeObject.reset();
            this.foamTreeObject.set('exposure', { groups: [], keepPrevious: false });
            this.foamTreeObject.set('selection', { all: true, selected: false });
        }
        if (this.carrotSearchCirclesObject) {
            this.carrotSearchCirclesObject.set('zoom', '1.1');
            this.carrotSearchCirclesObject.update();
            this.carrotSearchCirclesObject.redraw();
        }
        this.resultSet.forEach((res, index) => {
            res = this.processclusterData(res, index, { id: '' });
        });
        this.initCircles();
    }

    clearResults() {
        this.resultSet = [];
        this.groups = [];
    }
    ngOnDestroy() {
        this.stop$.next();
        this.stop$.complete();
        this.resultSet = [];
        if (this.foamTreeObject) {
            this.foamTreeObject.dispose();
        }
        this.foamTreeObject = null;
        this.carrotSearchCirclesObject = null;
    }

    /**
     * @description :navigate to details page
     * @param docId : document id
     * @param searchTerm :search term
     */
    goToDetailsPage(docId) {
        let lastSelectedTile: any = sessionStorage.getItem('selectedGroup');
        if (lastSelectedTile && lastSelectedTile !== 'undefined') {
            lastSelectedTile = JSON.parse(lastSelectedTile);
        }
        const homePageUrl = `/${(PageSettingsService.pageSettings.currentArchive + '').toLowerCase()}`;
        const data = { isFromTermGroupsPage: true, searchTerm: this.selectedTerm || `"${lastSelectedTile.label}"` };
        this.router.navigate([homePageUrl + '/detail/' + docId, data], { relativeTo: this.activatedRoute });
        let CurrentURL: any;
        CurrentURL = window.location.href.split(window.location.host);
        sessionStorage.setItem('termGroupsPageURL', CurrentURL[1]);
    }

    /**
     * @description: print foam tree Object
     */
    printFoamTree(filterValue): void {
        // console.log('foam tree invoked', this.foamTreeObject.get('imageData', {
        //     format: 'image/png',
        //     pixelRatio: 1.5
        // }));
        this.commonService.printTermFrequencyChart('Term Groups', filterValue, this.foamTreeObject.get('imageData', {
            format: 'image/png',
            pixelRatio: 1.5
        }), 'Foam Tree');
    }
    /**
     * @description: print Circle Object
     */
    printCircle(filterValue): void {
        // console.log('circle invoked', this.carrotSearchCirclesObject.get('imageData', {
        //     format: 'image/png',
        //     pixelRatio: 1.5
        // }));
        this.commonService.printTermFrequencyChart('Term Groups', filterValue, this.carrotSearchCirclesObject.get('imageData', {
            format: 'image/png',
            pixelRatio: 2
        }), 'Circles');

    }


    downloadCircle(filterValue) {
        this.commonService.downloadChartContent(this.carrotSearchCirclesObject.get('imageData', {
            format: 'image/png',
            pixelRatio: 2
        }), filterValue, 'Term Groups', 'Circles', 'TermGroups-Circles');
    }
    downloadFoamTree(filterValue) {
        this.commonService.downloadChartContent(this.foamTreeObject.get('imageData', {
            format: 'image/png',
            pixelRatio: 2
        }), filterValue, 'Term Groups', 'Foam Tree', 'TermGroups-FoamTree');
    }
    /**
     * @description :process cluister data and set index and select tile based on previous navigation data
     * @param res : object
     * @param parentIndex : parent index
     * @param lastSelectedTile : last clicked tile from session storage
     */
    processclusterData(res, parentIndex, lastSelectedTile): Object {
        res['index'] = `${parentIndex}`;
        if (lastSelectedTile && lastSelectedTile !== 'undefined') {

            if (res.index === lastSelectedTile.id) {
                const parentIdAry = lastSelectedTile.id.split('.');
                res.selected = true;
                res.exposed = false;
                res.open = (parentIdAry.length > 1) ? true : false;
            } else {
                res.selected = false;
                res.exposed = false;
                res.open = false;
            }
        }
        if (res.hasOwnProperty('subcluster')) {
            if (res.subcluster) {
                res.docs = res.docs instanceof Array ? res.docs : [];
                res.subcluster.map((resp, cindex) => {
                    resp['index'] = `${parentIndex}.${cindex}`;
                    if (lastSelectedTile && lastSelectedTile !== 'undefined') {
                        const childIdAry = lastSelectedTile.id.split('.');
                        if (resp.index === lastSelectedTile.id) {
                            resp.selected = true;
                            resp.exposed = false;
                            resp.open = (childIdAry.length > 1) ? true : false;
                        } else {
                            resp.selected = false;
                            resp.exposed = false;
                            resp.open = false;
                        }
                    }
                    res.docs = [...res.docs, ...resp.docs];
                });
            }
        }
        return res;
    }

    resetSelectedTileInfo() {
        this.resultSet.forEach((res, index) => {
            res = this.processclusterData(res, index, { id: '' });
        });
    }
}
