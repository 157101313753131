<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->
<div id="footer" class="col-md-12 col-sm-12 col-xs-12 col-lg-12 footer-homepage">
  <div class="footer__inner-wrap row"  [class.arabic-style]="isArabicLanguage">
    <div [class.col-md-8]="showJisclogo" [class.col-md-10]="!showJisclogo" class="col-xs-9 footer__copyright">
    {{ 'HomePage.copyright1' | translate }}{{currentDateYear}}{{'HomePage.copyright2' | translate }}</div>
    @if (showJisclogo) {
      <div class="col-md-2 col-xs-3 pull-right partner-logo footer_partner-logo"></div>
    }
    <div  class="col-md-2 col-xs-3 pull-right footer__logo"></div>
  </div>
  <!-- <div class="footer__bg-image-side"></div> -->
</div>

