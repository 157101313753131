/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject,  Subject } from 'rxjs';
// import { ActivatedRoute } from '@angular/router';
import { environment, APIURLS } from '../../../environments/environment';
import { HttpService } from './../services/http.service';

@Injectable()
export class UrlUtilityService {
    private fromContentSubType: any;
    public shortUrl: Subject<any> = new BehaviorSubject<string>(null);
    constructor(private httpService: HttpService) {
        this.fromContentSubType = false;
     }

    public parseUrl(url) {
        return new Observable((observer) => {
            const field = {};
            if (url) {
                const urlToArray = url.split('&&');
                if (Array.isArray(urlToArray) && urlToArray.length > 0) {
                    urlToArray.forEach(element => {
                        const queryFragment = element.split('=');
                        const publishedDates = ['publishedDateFrom', 'publishedDateTo', 'publishedDatePrefix', 'publishedDateIncludesNull'];
                        if (publishedDates.indexOf(queryFragment[0]) > -1) {
                            field[queryFragment[0]] = queryFragment[1];
                        } else if (queryFragment[0] === 'searchKeywords') {
                            const searchKeywords = queryFragment[1].split('|');
                            searchKeywords.forEach(keyword => {
                                const searchObject = keyword.split('#');
                                const duplicateSearchObject = keyword.split('#');
                                let fieldName = '';
                                fieldName = duplicateSearchObject[0];
                                let searchTerm;
                                duplicateSearchObject.splice(duplicateSearchObject.length - 1, 1);
                                duplicateSearchObject.splice(0, 1);
                                searchTerm = duplicateSearchObject.join();
                                if ((/^".*"$/.test(searchTerm)) || (/^".*"~[0-9]{1,5}$/.test(searchTerm))) {
                                    searchTerm = searchTerm;
                                } else {
                                    if (searchObject[0] === 'ALL') {
                                        searchTerm = searchTerm.replace(/[^a-zA-Z0-9 ?!*-]/g, '');
                                    } else {
                                        searchTerm = searchTerm;
                                    }
                                }
                                if (Array.isArray(field['searchKeywords'])) {
                                        field['searchKeywords'].push({
                                            fieldName: fieldName,
                                            fieldValue: searchTerm,
                                            operator: searchObject[2] ? searchObject[2] : 'AND'
                                        });
                                } else {
                                    field['searchKeywords'] = [];
                                    field['searchKeywords'].push({
                                        fieldName: fieldName,
                                        fieldValue: searchTerm,
                                        operator: searchObject[2] ? searchObject[2] : 'AND'
                                    });
                                }
                            });
                        } else if (queryFragment[0] === 'currentOffset') {
                            field['currentOffset'] = parseInt(queryFragment[1], 10);
                        } else if ( queryFragment[0] === 'currentManuscriptsOffset') {
                            field['currentManuscriptsOffset'] = parseInt(queryFragment[1], 10);
                        } else if (queryFragment[0] === 'currentMonographsOffset') {
                            field['currentMonographsOffset'] = parseInt(queryFragment[1], 10);
                        } else if (queryFragment[0] === 'currentPhotographsOffset') {
                            field['currentPhotographsOffset'] = parseInt(queryFragment[1], 10);
                        } else if (queryFragment[0] === 'currentContentTypeOffset') {
                            field['currentContentTypeOffset'] = parseInt(queryFragment[1], 10);
                        } else if (queryFragment[0] === 'sortby') {
                            if (queryFragment[1]) {
                                const value = queryFragment[1].split('|');
                                if (value.length > 1) {
                                    field['sortFields'] = {};
                                    field['sortFields'][value[0]] = value[1];
                                }
                            }
                        } else {
                            if (queryFragment[1]) {
                                const value = queryFragment[1].split('|');
                                field[queryFragment[0]] = value ? value : [];
                            }
                        }
                    });
                }
            }

            observer.next(field);
            observer.complete();
        });
    }

    public getCurrentUrl() {
        let url = '';
        // const port = window.location.port ? window.location.port : '';
        const host = window.location.host ? window.location.host : '';
        const protocol = window.location.protocol ? window.location.protocol : '';
        url = protocol + '//' + host;
        // + host + port ? ':' + port : '' + '/';
        return url;
    }

    getShortUrl(url, successCallback, errorCallback) {
        const requestParams = { 'url': url };
        const shortUrl = '';
        this.httpService.post(environment.APIUrl + APIURLS.shareShortUrl, requestParams, response => {
            successCallback(response);
            },
            error => {
              errorCallback(null);
            },
            'json'
          );
    }
    getBookmarkShortUrl(url, successCallback, errorCallback) {
        const requestParams = { 'url': url };
        const shortUrl = '';
        this.httpService.post(environment.APIUrl + APIURLS.bookmarkShortUrl, requestParams, response => {
            successCallback(response);
        },
            error => {
                errorCallback(null);
            },
            'json'
        );
    }

    setContentSubType(val: Boolean) {
        this.fromContentSubType  = val;
    }
    // getContentSubType() {
    //     return this.fromContentSubType;
    // }
}
