
import {takeUntil} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Observable,  Subject } from 'rxjs';
import { APIURLS, environment, stopWords, DOCUMENT_ID_REFERENCE } from '../../environments/environment';
import { HttpService } from '../common/services/http.service';
import { HttpClient } from '@angular/common/http';
import { Overlay, ImageSource } from '../new-image-viewer-frame/ImageSource';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../common/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
declare let $: any;
declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
      msSaveOrOpenBlob: (blob: any, defaultName?: string) => boolean
  }
}
@Injectable()
export class DocumentViewerService {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  splittedSearchTerms: Array<String> = [];
  translatedValues: any;
  cacheDocumentDetails: any = {};
  cache = {
    illustrationsData: {},
    fulltextData: {},
    tocData: {},
    documentByItemData: {},
    documentItembyType: {},
    documentDetails: {},
    relatedSubjectsData: {},
    collectionData: {},
    data: {}
  };
  popupBlocked = '';
  unblockPopup = '';
  printingError = '';
  deviceInfo: any;
  
  constructor(@Inject(DOCUMENT) private dom, private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private translate: TranslateService,
    private httpClient: HttpClient,
    ) {
  //   this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.translate.get('Common.Popups').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      this. translatedValues = res;
      this.popupBlocked = res['popupBlocked'];
      this.unblockPopup = res['unblockPopup'];
      this.printingError = res['printingError'];
    });
  }

  getDocumentDetail(docID) {
    if (docID) {
      return this.httpClient.get(environment.APIUrl + APIURLS['getDocumentDetail'] + docID);
    }
  }

  getCitationDetails(requestParams) {
    return this.httpClient.post(environment.APIUrl + 'citation/', requestParams);
  }

  getIllustrations(docID) {
    if (docID) {
     return this.httpClient.get(environment.APIUrl + APIURLS['getIllustrations'] + docID);
    }
  }

  getIllustrationCoordinatesV2(docId, pageId, calledFrom: String, successCallBack, errorCallback) {
    this.httpService.get(environment.APIUrl + APIURLS['getIllustrationsbypage'] + docId + '/' + pageId, res => {
      if (res) {
        const illustrations = Object.keys(res).map(key => ({ type: key, value: res[key] }));
        const illustrationsOverlays = [];
        illustrations.forEach(coordinateValues => {
          coordinateValues.value.forEach(overlayValue => {
            if (calledFrom === 'Filmstrip') {
              if (overlayValue.type === 'table') {
                illustrationsOverlays.push(new Overlay(overlayValue.width, overlayValue.height, overlayValue.hpos,
                  overlayValue.vpos, overlayValue.type, overlayValue.tableId));
              }
            } else {
              illustrationsOverlays.push(new Overlay(overlayValue.width, overlayValue.height, overlayValue.hpos,
                overlayValue.vpos, overlayValue.type, overlayValue.tableId));
            }
          });
        });
        successCallBack(illustrationsOverlays);
      }
    }, (error) => {
      errorCallback(error);
    });
  }

  getImageURLs(docID, images: Array<any>, callBack) {
    const imageURLs = [];
    const urlObservable = new Observable(observer => {
      images.forEach((img, index) => {
        const overlays: Array<Overlay> = [];
        if (Array.isArray(img.stringOrSPDetails)) {
          img.stringOrSPDetails.forEach(obj => {
            overlays.push(
              new Overlay(
                obj.width,
                obj.height,
                obj.hpos,
                obj.vpos
              )
            );
          });
        }

        const pageNumber = (img.id.split('-')[3]) / 10;
        imageURLs.push(
          new ImageSource(
            img.id,
            environment.APIUrl + APIURLS['fileUrlThumb'] + docID + '/' + img.id,
            environment.APIUrl + APIURLS['fileUrlImage'] + docID + '/' + img.id,
            overlays,
            false,
            img.itemTitle,
            pageNumber
          )
        );
        if (index === images.length - 1) {
          observer.complete();
        }
      });
    });
    if (typeof callBack === 'function') {
      urlObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        nextValue => { },
        error => {
          callBack(null);
        },
        () => {
          callBack(imageURLs);
        }
      );
    }
  }

  getCurrentRouteParams(successCallBack?, errorCallback?) {
    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(param => {
      successCallBack(param);
    }, (error) => {
      errorCallback(error);
    });
  }

  getTileSourceData(documentId, imageId, successcallBack, errorCallback) {
    let accessToken = JSON.parse(localStorage.getItem('auth_token'));
    let mapImageWidth = null;
    let mapImageHeight = null;
    let tilesourceID = null;

    let guestAuthToken = null;
    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      guestAuthToken = params.guestAuthToken;
    });

    let location = localStorage.getItem('currentLocation') || environment.defaultLocation;
    if (location) {
      location = location.trim();
    }
    if (guestAuthToken) {
      accessToken = guestAuthToken;
    }

    // const tileSourceURL = environment.APIUrl + APIURLS['tileSourceServer'] + '/' + documentId + '/' + imageId + '.jpg?WDALoc=' +
                          // location + '&access_token=' + accessToken + '&_';
    // if (/Edge/.test(navigator.userAgent) || /Firefox/.test(navigator.userAgent) ) {
      this.httpClient.get(environment.APIUrl + APIURLS['tileSourceServerV2'] + '/'
      + documentId + '/'
      + imageId + '.jpg/info.json?WDALoc=' + location + '&access_token=' + accessToken).pipe(takeUntil(this.ngUnsubscribe)).subscribe((tilesourceResponse) => {
        if (tilesourceResponse['redirectUrl']) {
          this.httpClient.get(tilesourceResponse['redirectUrl']).pipe(takeUntil(this.ngUnsubscribe)).subscribe(v2response => {
            mapImageWidth = v2response['width'];
            mapImageHeight = v2response['height'];
            tilesourceID = v2response['@id'];
            const tileSources = [{
              '@context': 'http://iiif.io/api/image/2/context.json',
              '@id': environment.APIUrl + APIURLS['tileSourceServerV3'] + '/' + documentId + '/' + imageId + '.jpg?WDALoc=' +
              location + '&access_token=' + accessToken + '&_',
              'height': mapImageHeight,
              'width': mapImageWidth,
              'profile': ['http://iiif.io/api/image/2/level2.json'],
              'protocol': 'http://iiif.io/api/image',
              'tiles': [{
                'scaleFactors': [0.25, 0.5, 1, 2, 4, 8, 16, 32],
                'width': 512
              }],
            }];
            successcallBack(tileSources);
          });
        }
      }, err => { errorCallback(err); });
    // } else {
    //   this.httpClient.get(environment.APIUrl + APIURLS['tileSourceServer'] + '/'
    //   + documentId + '/'  + imageId + '.jpg/info.json?WDALoc=' + location
    //   + '&access_token=' + accessToken).subscribe(tilesourceResponse => {
    //       mapImageWidth = tilesourceResponse['width'];
    //       mapImageHeight = tilesourceResponse['height'];
    //       tilesourceID = tilesourceResponse['@id'];
    //       const tileSources = [{
    //         '@context': 'http://iiif.io/api/image/2/context.json',
    //         '@id': environment.APIUrl + APIURLS['tileSourceServerV3'] + '/' + documentId + '/' + imageId + '.jpg?WDALoc=' +
    //         location + '&access_token=' + accessToken + '&_' + '?access_token=' + accessToken,
    //         'height': mapImageHeight,
    //         'width': mapImageWidth,
    //         'profile': ['http://iiif.io/api/image/2/level2.json'],
    //         'protocol': 'http://iiif.io/api/image',
    //         'tiles': [{
    //           'scaleFactors': [0.25, 0.5, 1, 2, 4, 8, 16, 32],
    //           'width': 512
    //         }],
    //       }];
    //       successcallBack(tileSources);
    //     }, error => { errorCallback(error); });
    // }

  }


  sanitizeAndPushTerms(term) {
    if (stopWords.indexOf(term.toLowerCase()) > -1) {
      term = '';
    }

    if (term !== '') {
      if (term.includes('"')) {
        if (this.splittedSearchTerms.indexOf(term.toLowerCase()) !== -1) {
        } else {
          if (term.includes('-')) {
            this.splittedSearchTerms.push(term);
          } else {
            this.splittedSearchTerms.push(term.toLowerCase());
          }
        }
      } else {
        if (this.splittedSearchTerms.indexOf(term.toLowerCase()) !== -1) {
        } else {
          this.splittedSearchTerms.push(term.toLowerCase());
        }
      }
    }
  }

  checkForDocumentIdPrefix(term): Boolean {
    let containsDocumentIdPrefix: Boolean = false;
    DOCUMENT_ID_REFERENCE.forEach(docIdPrefix => {
      if (term.includes(docIdPrefix)) {
        if (term.match(new RegExp('-', 'g')) || [].length === 2) {
          containsDocumentIdPrefix = true;
        }
      }
    });
    if (containsDocumentIdPrefix) {
      return true;
    }
  }


  processSearchTerm(searchKey, successCallBack) {
    if (searchKey) {
      if (searchKey.includes('“')) {
        searchKey = searchKey.replace(/“/g, '"');
      }
      if (searchKey.includes('”')) {
        searchKey = searchKey.replace(/”/g, '"');
      }
    }
    this.splittedSearchTerms = [];
    const searchTerms: Array<String> = [];
    searchKey = searchKey.match(/[^\s"]+|"([^"])+"~[0-9]{1,5}|"([^"])+"/g);
    if (searchKey) {
      searchKey.forEach(key => {
        if (this.checkForDocumentIdPrefix(key.toUpperCase())) {
          if (this.splittedSearchTerms.indexOf(key) <= -1) {
            if (/^".*"$/.test(key)) {
              this.sanitizeAndPushTerms(key);
            } else {
              this.sanitizeAndPushTerms('"' + key.toUpperCase() + '"');
            }
          }
        } else {
          if ((/^".*"$/.test(key)) || (/^".*"~[0-9]{1,5}$/.test(key))) {
            this.sanitizeAndPushTerms(key);
          } else {
            const searchTermwithSpaces = (/\s/.test(key));
            if (searchTermwithSpaces) {
              const withHyphen = key.split(' ');
              withHyphen.forEach(seperatedHypen => {
                this.sanitizeAndPushTerms(seperatedHypen.replace(/[^a-zA-Z0-9 ?*-]/g, ''));
              });
            } else {
              this.sanitizeAndPushTerms(key.replace(/[^a-zA-Z0-9 ?*-]/g, ''));
            }
          }
        }
      });
      return successCallBack(this.splittedSearchTerms);
    }
  }


  getDocumentItembyType(docID) {
    if (docID) {
     return this.httpClient.get(
        environment.APIUrl + APIURLS['getDocumentItembyType'] + docID
      );
    }
  }
  /**
   * @description : Get Items by sequence tab data
   * @param docID : document id
   * @param callBack : Items by sequence tab data
   */
  getDocumentByItem(docID) {
    if (docID) {
      return this.httpClient.get(
        environment.APIUrl + APIURLS['getDocumentByItem'] + docID
      );
    }
  }
  getRelatedSubjects(docID) {
    if (docID) {
     return this.httpClient.get(`${environment.APIUrl}${APIURLS['getRelatedSubjects']}/${docID}`);
    }
  }
  getCollectionLevelNotes(documentId, collectionId) {
    if (collectionId) {
      return this.httpClient.get(`${environment.APIUrl}${APIURLS['getCollectionNotes']}/${documentId}/${collectionId}`);
    }
  }
  getDocumentItembyTOC(docID) {
    if (docID) {
      return this.httpClient.get(
        environment.APIUrl + APIURLS['getDocumentItembyTOC'] + docID);
    }
  }
  getIllustraion(docID) {
    if (docID) {
     return this.httpClient.get(environment.APIUrl + APIURLS['getIllustrations'] + docID);
    }
  }

  /*
  * @param: document id
  * Output: object
  * DESC: get Volume for document
  * */
  getVolumeDetails(docId) {
    if (docId) {
     return this.httpClient.get(`${environment.APIUrl}${APIURLS.getVolumes}${docId}`);
    }
  }

  getMetaDatasFromKeyword(docID, searchTerm, callBack, fuzzy: Boolean = true) {
    if (searchTerm) {
      if (!searchTerm.includes('-') && !searchTerm.includes('"')) {
        const arr = searchTerm.split(' ');
        searchTerm = arr.filter(function (value, index, self) {
          return self.indexOf(value) === index;
        }).join(' ').replace(/,/g, ' ');
      }
    }
    const params = {
      searchTerm: searchTerm,
      filterParams: [
        {
          fieldName: 'id',
          fieldValues: [docID]
        }
      ],
      fuzzy: fuzzy
    };
    this.httpService.post(environment.APIUrl + APIURLS['getMetaDatasFromKeyword'] + '?docId=' + docID, params, response => {
      callBack(response);
    },
      error => {
        callBack(null);
      }, 'json', docID);
  }

  getPageFulltextFromKeyword(docID, searchTerm, callBack, fuzzy: Boolean = true) {
    if (searchTerm) {
      if (!searchTerm.includes('-') && !searchTerm.includes('"')) {
        const arr = searchTerm.split(' ');
        searchTerm = arr.filter(function (value, index, self) {
          return self.indexOf(value) === index;
        }).join(' ').replace(/,/g, ' ');
      }
    }
    const params = {
      searchTerm: searchTerm,
      filterParams: [
        {
          fieldName: 'documentId',
          fieldValues: [docID]
        }
      ],
      fuzzy: fuzzy
    };
    if (docID) {
      this.httpService.post(environment.APIUrl + APIURLS['getPageLevelFullText'] + '?docId=' + docID, params, response => {
        callBack(response);
      },
        error => {
          callBack(null);
        }, 'json', docID);
    }
  }


  getRelevantPages(pageID, searchTerm, callBack, fuzzy: any = false, docId?: any) {
    if (pageID) {
      const params = {
        searchTerm: searchTerm,
        filterParams: [
          {
            fieldName: 'pageId',
            fieldValues: [pageID]
          }
        ],
        fuzzy: fuzzy
      };
      if (docId) {
        params.filterParams.push({
          fieldName: 'documentId',
          fieldValues: [docId]
        });
      }

      if (searchTerm) {
        this.httpService.post(environment.APIUrl + APIURLS['getRelevantPages'] + '?docId=' + docId, params, response => {
          callBack(response);
        },
          (err) => {
            callBack(null);
          }, 'json', pageID);
      }
    }
  }

  getTextUrl(docId, pageId, languageCode, sourceLanguage, callBack, errorCallback) {
    if (docId && pageId) {
      let lang: any;
      if (languageCode) {
        lang = `?destLanguage=${languageCode}`;
      } else {
        lang = '?destLanguage=';
      }

      this.httpService.get(environment.APIUrl + APIURLS['downloadTextV2']
        + '/' + docId + '/'
        + pageId + lang + '&sourceLanguage=' + sourceLanguage +
        '&WDALoc=' + localStorage.getItem('currentLocation') || environment.defaultLocation, response => {
          callBack(response);
        }, error => {
          errorCallback(error);
        });
    }
  }

  getTranslateFulltextStatus(docId, lang, callBack, errorCallback) {
    if (docId) {
      this.httpService.get(environment.APIUrl + APIURLS['getTranslatefulltextstatus']
        + '/' + docId + '/' + lang
        , response => {
          callBack(response);
        }, error => {
          errorCallback(error);
        });
    }
  }

  getDownloadfulltextUrl(docId, language, sourceLanguage, callBack, errorCallback) {
    if (docId) {
      if (language) {
        this.httpService.get(environment.APIUrl + APIURLS['downloadFullTextV2']
          + '/' + docId + '?destLanguage=' + language + '&sourceLanguage=' + sourceLanguage
          , response => {
            callBack(response);
          }, error => {
            errorCallback(error);
          });
      } else {
        this.httpService.get(environment.APIUrl + APIURLS['downloadFullTextV2']
        + '/' + docId + '?WDALoc=' + localStorage.getItem('currentLocation') || environment.defaultLocation
        , response => {
          callBack(response);
        }, error => {
          errorCallback(error);
        });
      }
    }
  }

  downLoadImage(isValidationPage,
    downloadImagesModel,
    validPage,
    currentDocumentId,
    documentCurrentIndex,
    pagesToDownload,
    validPageNumber) {
    let url: any;
    if (downloadImagesModel === 'all-pages') {
      validPage = true;
      url = environment.APIUrl + 'pagemapper/pdf/' + currentDocumentId;
    } else if (downloadImagesModel === 'current-page') {
      validPage = true;
      url = environment.APIUrl + 'pagemapper/pdf/' + currentDocumentId + '/' + (documentCurrentIndex);
    } else if (downloadImagesModel === 'selected-page') {
      if (pagesToDownload) {
        if (isValidationPage) {
          validPage = true;
          url = environment.APIUrl + 'pagemapper/pdf/' + currentDocumentId + '/' + validPageNumber;
        }
      }
    }
    if (validPage === true) {
      return url;
    }
  }
  dowLoadTiffImageServiceReq( downloadTiffModel, validPage, currentDocumentId, documentCurrentIndex, isGeoTiffChecked, isMultiTifExist) {
    let url: any;
    if (downloadTiffModel === 'all-pages') {
      validPage = true;
      // tslint:disable-next-line:max-line-length
      url = environment.APIUrl + APIURLS.downloadGEOtiff + currentDocumentId + '?imageselection=' + 'all' + '&geocoordinate=' + isGeoTiffChecked;
    } else if (downloadTiffModel === 'all-geopages') {
      validPage = true;
      // tslint:disable-next-line:max-line-length
      url = environment.APIUrl + APIURLS.downloadGEOtiff + currentDocumentId + '?imageselection=' + 'all' + '&geocoordinate=' + isGeoTiffChecked + '&docgeotiff=' + true;
    } else if (downloadTiffModel === 'current-page') {
      validPage = true;
      // tslint:disable-next-line:max-line-length
      url = environment.APIUrl + APIURLS.downloadGEOtiff + currentDocumentId  + '?imageselection=' + 'current' + '&pageId=' + documentCurrentIndex + '&geocoordinate=' + isGeoTiffChecked + '&multigeotiff=' + isMultiTifExist;
    }
    if (validPage === true) {
      return url;
    }
  }
  getDownloadTableUrl(docId, tableId, callBack, errorCallback) {
    if (docId && tableId) {
      // tslint:disable-next-line:max-line-length
      this.httpService.get(environment.APIUrl + APIURLS['downloadTableUrlV2'] + docId + '/' + tableId
        + '?WDALoc=' + localStorage.getItem('currentLocation') || environment.defaultLocation, response => {
          callBack(response);
        }, (err) => {
          errorCallback(err);
        });
    }
  }

  printFromServer(requestParams, callBack, errorCallback) {
    this.httpService.get(environment.APIUrl + 'pagemapper/print/' + requestParams, (resp) => {
      const file = new Blob([resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
      } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {  // for IPAD
        window.open('data:application/json;charset=utf-8,' + encodeURIComponent(fileURL), '_blank');
      } else {
        const printwWindow = window.open(fileURL, '_blank');
        if (!printwWindow || printwWindow.closed || typeof printwWindow.closed === 'undefined') {
          this.modalService.showNotification('ERROR', this.popupBlocked, this.unblockPopup);
        }
        printwWindow.print();
      }
      callBack(resp);
      return true;
    }, (err) => {
      errorCallback(err);
      this.modalService.showNotification('ERROR', 'Error', this.printingError);
      // this.modalService.hideLoader();
    });
  }
  downloadEntireFulltext(docId, languageCode) {
    // this.modalService.showLoader();
    this.httpService.get(environment.APIUrl + APIURLS['downloadFullText'] + '/' +
      docId + '?destLanguage=' + languageCode, resp => {
        const textToSave = resp;
        const textToSaveAsBlob = new Blob([textToSave], { type: 'text/plain' });
        const fileNameToSaveAs = docId + languageCode + '_full_text';
        if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
          window.navigator.msSaveBlob(textToSaveAsBlob, fileNameToSaveAs);
        } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
          window.open('data:application/json;charset=utf-8,' + encodeURIComponent(textToSave), '_blank');
        } else {
          const textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
          const downloadLink = document.createElement('a');
          downloadLink.download = fileNameToSaveAs;
          downloadLink.innerHTML = 'Download File';
          downloadLink.href = textToSaveAsURL;
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
        // this.modalService.hideLoader();
      }, error => {
        // this.modalService.hideLoader();
      }, 'text');
  }
  exportCitation(type, docId) {
    const typeName = $(type).attr('id');
    this.httpService.downloadFile(environment.APIUrl + APIURLS['getCitationRISFile'] + docId).takeUntil(this.ngUnsubscribe).subscribe((resp) => {
      if (resp) {
        if (resp.includes('#x0027;')) {
          resp = resp.replace(/#x0027;/g, '\'');
        }
        if (resp.includes('#x0026;')) {
          resp = resp.replace(/#x0026;/g, '&');
        }
        if (resp.includes('#x0022;')) {
          resp = resp.replace(/#x0022;/g, '"');
        }
        if (resp.includes('&#38;')) {
          resp = resp.replace(/&#38;/g, ' ');
        }
      }
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        window.open('data:application/json;charset=utf-8,' + encodeURIComponent(resp), '_blank');
      }
      const res = new Blob([resp], { type: 'application/pdf' });
      const fileName = docId + '-' + typeName + '.ris';
      if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
        window.navigator.msSaveBlob(res, fileName);
      } else {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(res); // xhr.response is a blob
        a.download = fileName; // Set the file name.
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      }
    }, (err) => { });
  }
  downloadCitation(citation, docId) {
    let textToBeCopied = citation;
    if (textToBeCopied) {
      if (textToBeCopied.includes('#x0027;')) {
        textToBeCopied = textToBeCopied.replace(/#x0027;/g, '\'');
      }
      if (textToBeCopied.includes('#x0026;')) {
        textToBeCopied = textToBeCopied.replace(/#x0026;/g, '&');
      }
      if (textToBeCopied.includes('#x0022;')) {
        textToBeCopied = textToBeCopied.replace(/#x0022;/g, '"');
      }
      if (textToBeCopied.includes('&#38;')) {
        textToBeCopied = textToBeCopied.replace(/&#38;/g, ' ');
      }
    }
    const htmlCode = `<html><head><title>Citation</title><style> body{    font-family: 'Open Sans', sans-serif !important;}
     .csl-entry { color: #414246;font-size: 16px; font-weight: 300; line-height: 20px;  } i{font-weight : bold;}
         </style>
      </head><body ><div>`;
    const htmlContent = [htmlCode + textToBeCopied + '</div></body></html>'];
    const htmlContentipad = $(textToBeCopied).text();

    const blob = new Blob(htmlContent, { type: 'text/html' });
    const doc = document.createElement('a');
    if (navigator.userAgent.toString().indexOf('.NET') > 0) { // for IE browser
      window.navigator.msSaveBlob(blob, docId.toString());
    } else if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      window.open('data:application/json;charset=utf-8,' + encodeURIComponent(htmlContentipad), '_blank');
    } else {
      doc.href = URL.createObjectURL(blob);
      doc.download = docId + '.html';
      doc.hidden = true;
      document.body.appendChild(doc);
      doc.click();
    }
  }

  copyCitationText(element, citation?) {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      if (citation) {
        $(element).addClass('welcome');
        const citationEle = element;
        let textToBeCopied = (citationEle).replace(/<(?:.|\n)*?>/gm, '');
        if (textToBeCopied) {
          if (textToBeCopied.includes('#x0027;')) {
            textToBeCopied = textToBeCopied.replace(/#x0027;/g, '\'');
          }
          if (textToBeCopied.includes('#x0026;')) {
            textToBeCopied = textToBeCopied.replace(/#x0026;/g, '&');
          }
          if (textToBeCopied.includes('#x0022;')) {
            textToBeCopied = textToBeCopied.replace(/#x0022;/g, '"');
          }
          if (textToBeCopied.includes('&#38;')) {
            textToBeCopied = textToBeCopied.replace(/&#38;/g, ' ');
          }
        }
        const $input = $('.mycopyCitation');
        $input.val();
        const el = $input.get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;
        const successful = document.execCommand('copy');
        $input.blur();
        const msg = successful ? 'successful ' : 'un-successful ';
      }
    } else {
      let textToBeCopied = (element).replace(/<(?:.|\n)*?>/gm, '');
      if (textToBeCopied) {
        if (textToBeCopied.includes('#x0027;')) {
          textToBeCopied = textToBeCopied.replace(/#x0027;/g, '\'');
        }
        if (textToBeCopied.includes('#x0026;')) {
          textToBeCopied = textToBeCopied.replace(/#x0026;/g, '&');
        }
        if (textToBeCopied.includes('#x0022;')) {
          textToBeCopied = textToBeCopied.replace(/#x0022;/g, '"');
        }
        if (textToBeCopied.includes('&#38;')) {
          textToBeCopied = textToBeCopied.replace(/&#38;/g, ' ');
        }
      }
      const textarea = this.dom.createElement('textarea');
      textarea.style.height = '0px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '0px';
      this.dom.body.appendChild(textarea);
      textarea.value = textToBeCopied;
      textarea.select();
    }
    return document.execCommand('copy');
  }


  // getHeadWordsByPages(pageID, callBack, docId?: any) {
  //   if (pageID) {
  //     const params = {
  //       searchTerm: '',
  //       filterParams: [
  //         {
  //           fieldName: 'pageId',
  //           fieldValues: [pageID]
  //         },
  //         {
  //           fieldName: 'isHeadWord',
  //           fieldValues: [true],
  //           condition: 'EQUAL'
  //         }
  //       ],
  //     };
  //       this.httpService.post(environment.APIUrl + APIURLS['getRelevantPages'] + '?docId=' + docId, params, response => {
  //         callBack(response);
  //       },
  //         (err) => {
  //           callBack(null);
  //         }, 'json', pageID);
  //   }
  // }
  getDownloadTableCSV(docId, tableId, callBack, errorCallback) {
    if (docId && tableId) {
      // tslint:disable-next-line:max-line-length
      this.httpService.get(environment.APIUrl + APIURLS['downloadTableCSV'] + docId + '/' + tableId
        + '?WDALoc=' + localStorage.getItem('currentLocation') || environment.defaultLocation, response => {
          callBack(response);
        }, (err) => {
          errorCallback(err);
        });
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
