
import {takeUntil} from 'rxjs/operators';
/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
  Renderer2,
  ElementRef,
  HostListener,
  TemplateRef,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  NgZone,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
// import { HttpService } from './../common/services/http.service';
import { PageSettingsService } from './../common/services/page-settings.service';
import { AdvancedFilterParams } from './../common/models/AdvancedFilterParams';
import { SearchService } from './../common/services/search.service';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { IMultiSelectTexts, IMultiSelectSettings } from '../../assets/plugin/angular-2-dropdown-multiselect';
import { SharedService } from '../common/services/sharedService';

import { BsModalService } from 'ngx-bootstrap/modal';
import { orderBy as _orderBy, map as _map } from 'lodash';
import { TranslateService, LangChangeEvent, DefaultLangChangeEvent, TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../common/services/common.service';
// import * as cloneDeep from 'lodash/cloneDeep';
import { ModalService } from '../common/services/modal.service';
import { CommonModule } from '@angular/common';
import { FilterControlComponent } from '../filter-control/filter-control.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
declare var $: any;

@Component({
  selector: 'app-search-filter',
  standalone:true,
  imports:[CommonModule, TranslateModule,FilterControlComponent,ReactiveFormsModule,FormsModule,
    TooltipModule,TranslateModule,TooltipModule, NgMultiSelectDropDownModule
  ],
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css'],

})
export class SearchFilterComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  loading:Boolean = true;
  private stop$: Subject<void> = new Subject<void>();
  illustrationTypeTooltip: string;
  isArabicLanguage: Boolean = false;
  unsubAdvSearchArchiveSelection: Subscription;
  unsubscribeAdvSearch: Subscription;
  publicationDateLabel = 'Publication Date';
  resetVal = false;
  isFromHomePage = false;
  translatedValues: any;
  checkDate: Boolean = false;

  // tslint:disable-next-line:no-input-rename
  // @Input('search-key') private searchKey: String = '';
  estimatedCount: number;
  // tslint:disable-next-line:no-input-rename
  @Input('page-content') pageContent: any;
  toggleSearch = false;
  isFilterToggled = false;
  SelectAllTrue: boolean = false;
  SelectedDropdownValue: any;
  @Input('showPubDateval')
  public set showPubDateval(val: boolean) {
    this.showPubDate = val;

  }
  // @Input('selectedArchives')
  // public set selectedArchives(val: any[]) {
  //   // console.log(val);

  //   // this.getUpdatedDropdownData(val);

  // }
  @Input('fromHomepage')
  public set fromHomepage(val: any) {
    if (val) {
      this.isFromHomePage = val;
    } else {
      this.isFromHomePage = false;
    }
  }
  public get fromHomepage(): any {
    return this.isFromHomePage;
  }

  @Input('filterToggle')
  public set filterToggle(val: any) {
    this.isFilterToggled = val;
  }
  public get filterToggle(): any {
    return this.isFilterToggled;
  }



  @Input('resetFilter')
  public set resetFilter(val: any) {
    this.resetVal = val;
    if (this.resetVal) {
      this.resetSearchFilter();
    }
  }

  // @Input()
  // public set searchWithAdvParams(ctrl: string) {
  //   // if (ctrl) {
  //   //   // this.searchSubmit();
  //   // }
  // }

  @Input() customeClass: boolean;
  @Output('passFilterValue') private passFilterValue: EventEmitter<any> = new EventEmitter();
  // searchWithAdvParams: any;
  @Output('show-loading-text') showLoadingTxt: EventEmitter<boolean> = new EventEmitter();
  @Output('estimated-count') estimatedCountEmit: EventEmitter<any> = new EventEmitter();
  @Output('filter-open') filterOpen: EventEmitter<any> = new EventEmitter();
  @Output('filter-close') filterClose: EventEmitter<any> = new EventEmitter();
  @Output('disable-search') disableSearch: EventEmitter<any> = new EventEmitter();
  searchKeywords = new UntypedFormControl();
  private contentType = new UntypedFormControl();
  private docSubType = new UntypedFormControl();
  private contentCollection = new UntypedFormControl();
  private contentSources = new UntypedFormControl();
  private languages = new UntypedFormControl();
  publishedDateFrom = new UntypedFormControl();
  publishedDateTo = new UntypedFormControl();
  publishedDatePrefix = new UntypedFormControl();
  publishedDateIncludesNull = new UntypedFormControl();
  private groupLimit = new UntypedFormControl();
  private currentOffset = new UntypedFormControl();
  private archive = new UntypedFormControl();
  private sortFields = new UntypedFormControl();
  private facetFields = new UntypedFormControl();
  private subject = new UntypedFormControl();
  private illustrationType = new UntypedFormControl();
  // private validDate: Boolean;
  advancedSearchDocumentsText = ' documents';
  advancedSearchNoDocumentsText = ' No documents';
  filterForm = new UntypedFormGroup({
    searchKeywords: this.searchKeywords,
    contentType: this.contentType,
    docSubType: this.docSubType,
    contentCollection: this.contentCollection,
    contentSources: this.contentSources,
    languages: this.languages,
    publishedDateFrom: this.publishedDateFrom,
    publishedDateTo: this.publishedDateTo,
    publishedDatePrefix: this.publishedDatePrefix,
    publishedDateIncludesNull: this.publishedDateIncludesNull,
    archive: this.archive,
    groupLimit: this.groupLimit,
    currentOffset: this.currentOffset,
    sortFields: this.sortFields,
    facetFields: this.facetFields,
    subject: this.subject,
    illustrationType: this.illustrationType
  });
  // @ViewChild('templateConfirmPopup') private optionHTML: TemplateRef<any>;
  // @ViewChild('estimatedCountLabel', { read: ElementRef })
  // @ViewChild('templateConfirmPopup', { static: false }) private optionHTML: TemplateRef<any>;
	@ViewChild('estimatedCountLabel', { static: false, read: ElementRef }) private estimatedCountLabel: ElementRef;

  // private estimatedCountLabel: ElementRef;
  private _showFilter: any = false;
  _enableSubmit: Boolean = false;
  private estimateTimer = null;
  private showPubDate: Boolean = false;
  // private filterFormModel: AdvancedFilterParams;
  // public publishDateMask: Array<string | RegExp>;
  private config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: false
  };
  hasValuesUndated: Boolean = false;
  headerDropdownOpen: string;
  // Settings configuration
  // contentTypeSettings: IMultiSelectSettings = {
  //   enableSearch: false,
  //   checkedStyle: 'fontawesome',
  //   buttonClasses: 'btn btn-default btn-block',
  //   dynamicTitleMaxItems: 2,
  //   containerClasses: 'multiselect-dropdown-item',
  //   itemClasses: 'multiselect-dropdown-item-span'
  // };

  // contentTypeTexts: IMultiSelectTexts = {
  //   checked: 'Content Type selected',
  //   checkedPlural: 'Content Types selected',
  //   defaultTitle: 'Content Types'
  // };
  // collectionsSettings: IMultiSelectSettings = {
  //   enableSearch: true,
  //   checkedStyle: 'fontawesome',
  //   buttonClasses: 'btn btn-default btn-block',
  //   dynamicTitleMaxItems: 1,
  //   containerClasses: 'multiselect-dropdown-item',
  //   itemClasses: 'multiselect-dropdown-item-span'
  // };

  // collectionTexts: IMultiSelectTexts = {
  //   checked: 'Collection selected',
  //   checkedPlural: 'Collections selected',
  //   searchPlaceholder: 'Find',
  //   searchEmptyResult: 'Nothing found...',
  //   searchNoRenderText: 'Type in search box to see results...',
  //   defaultTitle: 'Collections'
  // };
  // contentSubTypeSettings: IMultiSelectSettings = {
  //   enableSearch: true,
  //   checkedStyle: 'fontawesome',
  //   buttonClasses: 'btn btn-default btn-block',
  //   dynamicTitleMaxItems: 1,
  //   containerClasses: 'multiselect-dropdown-item',
  //   itemClasses: 'multiselect-dropdown-item-span'
  // };

  // contentSubTypeTexts: IMultiSelectTexts = {
  //   checked: 'Content Subtype selected',
  //   checkedPlural: 'Content Subtypes selected',
  //   defaultTitle: 'Content Subtypes',
  //   searchPlaceholder: 'Find'
  // };
  // languageSettings: IMultiSelectSettings = {
  //   enableSearch: true,
  //   checkedStyle: 'fontawesome',
  //   buttonClasses: 'btn btn-default btn-block',
  //   dynamicTitleMaxItems: 2,
  //   containerClasses: 'multiselect-dropdown-item',
  //   itemClasses: 'multiselect-dropdown-item-span'
  // };

  // languageTexts: IMultiSelectTexts = {
  //   checked: 'Language selected',
  //   checkedPlural: 'Languages selected',
  //   searchPlaceholder: 'Find',
  //   searchEmptyResult: 'Nothing found...',
  //   searchNoRenderText: 'Type in search box to see results...',
  //   defaultTitle: 'Languages'
  // };
  // contentSourcesSettings: IMultiSelectSettings = {
  //   enableSearch: true,
  //   checkedStyle: 'fontawesome',
  //   buttonClasses: 'btn btn-default btn-block',
  //   dynamicTitleMaxItems: 1,
  //   containerClasses: 'multiselect-dropdown-item',
  //   itemClasses: 'multiselect-dropdown-item-span'
  // };

  // contentSourcesTexts: IMultiSelectTexts = {
  //   checked: 'Source selected',
  //   checkedPlural: 'Sources selected',
  //   searchPlaceholder: 'Find',
  //   searchEmptyResult: 'Nothing found...',
  //   searchNoRenderText: 'Type in search box to see results...',
  //   defaultTitle: 'Content Sources'
  // };
    /*
  * Configure the ss dropdown texts For Illustration Types
  * */
  // illustrationTypeText: IMultiSelectTexts = {
  //   checked: 'Illustration selected',
  //   checkedPlural: 'Illustrations selected',
  //   searchPlaceholder: 'Find',
  //   searchEmptyResult: 'Nothing found...',
  //   searchNoRenderText: 'Type in search box to see results...',
  //   defaultTitle: 'Illustration Types'
  // };
  /*
  * Configure the ss dropdown search and btn style For Illustration Types
  * */
  // illustrationTypeSettings: IMultiSelectSettings = {
  //   enableSearch: true,
  //   checkedStyle: 'fontawesome',
  //   buttonClasses: 'btn btn-default btn-block',
  //   dynamicTitleMaxItems: 1,
  //   containerClasses: 'multiselect-dropdown-item',
  //   itemClasses: 'multiselect-dropdown-item-span'
  // };

  @Input('show-filter')
  set showFilter(flag) {
    if (flag !== '') {
      this._showFilter = flag;
      if (flag !== undefined) {
        if (flag === true) {
          this.filterOpen.emit();
          this.onFilterOpen();
        } else {
          // this.onFilterClose();
          this.filterClose.emit();
        }
      }
    }
  }

  get showFilter() {
    return this._showFilter;
  }
  /*
  * To hide advanced filter from multiselect component
  */
  @Input('multiselect-dropdown-status')
  set multiselectDropdownstatus(flag: boolean) {
    this._showFilter = false;
  }

  get multiselectDropdownstatus() {
    return this._showFilter;
  }

  // private advancedFiltersDropdownSettings = {
  //   contentType: {
  //     singleSelection: false,
  //     text: 'Content Types',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     badgeShowLimit: 1,
  //     maxHeight: 200,
  //     classes: 'advancesearch-archiveDropdowns'
  //   },

  //   contentCollection: {
  //     singleSelection: false,
  //     text: 'Collection',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     enableSearchFilter: true,
  //     badgeShowLimit: 1,
  //     maxHeight: 200,
  //     classes: 'advancesearch-archiveDropdowns advancesearch-collections',
  //     limitSelection: 10
  //   },
  //   contentSubTypes: {
  //     singleSelection: false,
  //     text: 'Content Subtypes',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     badgeShowLimit: 1,
  //     maxHeight: 200,
  //     classes: 'advancesearch-archiveDropdowns',
  //     limitSelection: 10
  //   },
  //   publicationTitle: {
  //     singleSelection: false,
  //     text: 'Language',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     enableSearchFilter: true,
  //     badgeShowLimit: 1,
  //     maxHeight: 200,
  //     classes: 'advancesearch-archiveDropdowns',
  //     limitSelection: 10
  //   },
  //   contentSources: {
  //     singleSelection: false,
  //     text: 'Source',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     enableSearchFilter: true,
  //     badgeShowLimit: 1,
  //     maxHeight: 200,
  //     classes: 'advancesearch-archiveDropdowns',
  //     limitSelection: 10
  //   },
  //   illustrationType: {
  //     singleSelection: false,
  //     text: 'Source',
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     enableSearchFilter: true,
  //     badgeShowLimit: 1,
  //     maxHeight: 200,
  //     classes: 'advancesearch-archiveDropdowns',
  //     limitSelection: 10
  //   }
  // };
  private modalRef: any;
  // private advancedFilterParam: any;
  collectionsTooltip: string;
  publicationTitleTooltip: string;
  contentSourcesTooltip: string;
  contentTypeTooltip: string;
  contentSubTypesTooltip: string;
  dropdownSettingsWithoutSearch: any = {};
  // private docSubTypes: any[] = [];
  constructor(
    private renderer: Renderer2,
    // private httpService: HttpService,
    // private router: Router,
    private activatedRoute: ActivatedRoute,
    private elementReference: ElementRef,
    private modalService: BsModalService,
    private searchService: SearchService,
    private pageSettingService: PageSettingsService,
    private changeDetector: ChangeDetectorRef,
    private sharedService: SharedService,
    private translate: TranslateService,
    private commonService: CommonService,
    private notificationService: ModalService,
    private zone: NgZone
  ) {
    this.translate.get('AdvancedSearch.documents').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.advancedSearchDocumentsText = res;
    });

    this.translate.get('AdvancedSearch.noDocuments').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.advancedSearchNoDocumentsText = res;
    });
    this.estimatedCount = 0;
    // PageSettingsService.pageSettingsObservable.subscribe(data => {
    //   // this.pageContent = cloneDeep(data);
    //   // this.pageContent.availableContentSubTypes = _orderBy(this.pageContent.availableContentSubTypes, ['name'], ['asc']);
    //   // this.pageContent.availableCollections = _orderBy(this.pageContent.availableCollections, ['name'], ['asc']);
    //   // this.pageContent.availableLanguages = _orderBy(this.pageContent.availableLanguages, ['name'], ['asc']);
    //   // this.pageContent.availableSources = _orderBy(this.pageContent.availableSources, ['name'], ['asc']);
    //   // this.pageContent.availableFields = _orderBy(this.pageContent.availableFields, ['itemName'], ['asc']);
    //   // this.pageContent.availableIllustrationTypes = _orderBy(this.pageContent.availableIllustrationTypes, ['name'], ['asc']);
    // });
    this.publishedDatePrefix.valueChanges.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.publishedDateFrom.setValue('');
      this.publishedDateTo.setValue('');
      this.checkDate = false;
    });
    // this.filterFormModel = new AdvancedFilterParams();
    // this.publishDateMask = [
    //   /\d/,
    //   /\d/,
    //   /\d/,
    //   /\d/,
    //   '-',
    //   /\d/,
    //   /\d/,
    //   '-',
    //   /\d/,
    //   /\d/
    // ];
  }
  ngOnChanges(changes: SimpleChanges) {
      if(changes && changes.filterToggle && changes.filterToggle.currentValue) {
        this.filterOpen.emit();
        this.onFilterOpen();
      }
  }
  ngAfterViewInit(): void {
    const currentLanguage = sessionStorage.getItem('lang');
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res === 'ar') {
        this.isArabicLanguage = true;
      } else {
        this.isArabicLanguage = false;
      }
    });
    this.unsubAdvSearchArchiveSelection = this.sharedService.advSearchArchiveSelectionOberable.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.onRequestParamsChanged(res);
      }
    });
    this.translate.get('Common.Popups').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
    });
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
    const illArray = [];
    setTimeout(() => {
      this.pageContent.availableIllustrationTypes.forEach(element => {
          if (element.id === 'Family_table' || element.id === 'Family_table-Printed') {} else {
            illArray.push(element);
          }
      });
      this.pageContent.availableIllustrationTypes = illArray;
    }, 1000);
  }
  ngOnInit() {
    this.dropdownSettingsWithoutSearch = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
  };
    // this.translate.get('HomePage').subscribe((res: any) => {
    //   const translatedLabels = res;
    //   this.contentTypeTexts.checked = translatedLabels['contentTypes'];
    //   this.contentTypeTexts.checkedPlural = translatedLabels['contentTypes'];
    //   this.contentTypeTexts.defaultTitle = translatedLabels['contentTypes'];

    //   this.collectionTexts.checked = translatedLabels['Collections'];
    //   this.collectionTexts.checkedPlural = translatedLabels['Collections'];
    //   this.collectionTexts.defaultTitle = translatedLabels['Collections'];
    //   this.collectionTexts.searchPlaceholder = translatedLabels['Collections'];
    //   this.collectionTexts.searchEmptyResult = translatedLabels['Collections'];
    //   this.collectionTexts.searchNoRenderText = translatedLabels['Collections'];

    //   this.contentSubTypeTexts.checked = translatedLabels['contentSubtypes'];
    //   this.contentSubTypeTexts.checkedPlural = translatedLabels['contentSubtypes'];
    //   this.contentSubTypeTexts.defaultTitle = translatedLabels['contentSubtypes'];
    //   this.contentSubTypeTexts.searchPlaceholder = translatedLabels['contentSubtypes'];
    //   this.contentSubTypeTexts.searchEmptyResult = translatedLabels['contentSubtypes'];
    //   this.contentSubTypeTexts.searchNoRenderText = translatedLabels['contentSubtypes'];

    //   this.languageTexts.checked = translatedLabels['languages'];
    //   this.languageTexts.checkedPlural = translatedLabels['languages'];
    //   this.languageTexts.defaultTitle = translatedLabels['languages'];
    //   this.languageTexts.searchPlaceholder = translatedLabels['languages'];
    //   this.languageTexts.searchEmptyResult = translatedLabels['languages'];
    //   this.languageTexts.searchNoRenderText = translatedLabels['languages'];

    //   this.contentSourcesTexts.checked = translatedLabels['contentSources'];
    //   this.contentSourcesTexts.checkedPlural = translatedLabels['contentSources'];
    //   this.contentSourcesTexts.defaultTitle = translatedLabels['contentSources'];
    //   this.contentSourcesTexts.searchPlaceholder = translatedLabels['contentSources'];
    //   this.contentSourcesTexts.searchEmptyResult = translatedLabels['contentSources'];
    //   this.contentSourcesTexts.searchNoRenderText = translatedLabels['contentSources'];

    //   this.illustrationTypeText.checked = translatedLabels['illustrationType'];
    //   this.illustrationTypeText.checkedPlural = translatedLabels['illustrationType'];
    //   this.illustrationTypeText.defaultTitle = translatedLabels['illustrationType'];
    //   this.illustrationTypeText.searchPlaceholder = translatedLabels['illustrationType'];
    //   this.illustrationTypeText.searchEmptyResult = translatedLabels['illustrationType'];
    //   this.illustrationTypeText.searchNoRenderText = translatedLabels['illustrationType'];

    //   this.publicationDateLabel = translatedLabels['publicationDate'];
    // });
    this.translate.get('AdvancedSearch').pipe(takeUntil(this.stop$)).subscribe((res: any) => {
      const advancedSearchLabels = res;
      if (advancedSearchLabels['documents']) {
        this.advancedSearchDocumentsText = advancedSearchLabels['documents'];
      }
      if (advancedSearchLabels['noDocuments']) {
        this.advancedSearchNoDocumentsText = advancedSearchLabels['noDocuments'];
      }
    });
    this.unsubscribeAdvSearch = this.sharedService.invokeAdvSearchOberable.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.searchSubmit();
      }
    });
    this.translate.get('HomePage').pipe(takeUntil(this.stop$)).subscribe((response: any) => {
      const translatedLabels = response;
      // this.contentTypeTexts.checked = translatedLabels['contentTypes'];
      // this.contentTypeTexts.checkedPlural = translatedLabels['contentTypes'];
      // this.contentTypeTexts.defaultTitle = translatedLabels['contentTypes'];

      // this.collectionTexts.checked = translatedLabels['Collections'];
      // this.collectionTexts.checkedPlural = translatedLabels['Collections'];
      // this.collectionTexts.defaultTitle = translatedLabels['Collections'];
      // this.collectionTexts.searchPlaceholder = translatedLabels['Collections'];
      // this.collectionTexts.searchEmptyResult = translatedLabels['Collections'];
      // this.collectionTexts.searchNoRenderText = translatedLabels['Collections'];

      // this.contentSubTypeTexts.checked = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.checkedPlural = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.defaultTitle = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.searchPlaceholder = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.searchEmptyResult = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.searchNoRenderText = translatedLabels['contentSubtypes'];

      // this.languageTexts.checked = translatedLabels['languages'];
      // this.languageTexts.checkedPlural = translatedLabels['languages'];
      // this.languageTexts.defaultTitle = translatedLabels['languages'];
      // this.languageTexts.searchPlaceholder = translatedLabels['languages'];
      // this.languageTexts.searchEmptyResult = translatedLabels['languages'];
      // this.languageTexts.searchNoRenderText = translatedLabels['languages'];

      // this.contentSourcesTexts.checked = translatedLabels['contentSources'];
      // this.contentSourcesTexts.checkedPlural = translatedLabels['contentSources'];
      // this.contentSourcesTexts.defaultTitle = translatedLabels['contentSources'];
      // this.contentSourcesTexts.searchPlaceholder = translatedLabels['contentSources'];
      // this.contentSourcesTexts.searchEmptyResult = translatedLabels['contentSources'];
      // this.contentSourcesTexts.searchNoRenderText = translatedLabels['contentSources'];
      if (translatedLabels['illustrationType']) {
        // this.illustrationTypeText.checked = translatedLabels['illustrationType'];
        // this.illustrationTypeText.checkedPlural = translatedLabels['illustrationType'];
        // this.illustrationTypeText.defaultTitle = translatedLabels['illustrationType'];
        // this.illustrationTypeText.searchPlaceholder = translatedLabels['illustrationType'];
        // this.illustrationTypeText.searchEmptyResult = translatedLabels['illustrationType'];
        // this.illustrationTypeText.searchNoRenderText = translatedLabels['illustrationType'];
      }
      this.publicationDateLabel = translatedLabels['publicationDate'];
    });
    this.translate.get('AdvancedSearch').pipe(takeUntil(this.stop$)).subscribe((response: any) => {
      const advancedSearchLabels = response;
      if (advancedSearchLabels['documents']) {
        this.advancedSearchDocumentsText = advancedSearchLabels['documents'];
      }
      if (advancedSearchLabels['noDocuments']) {
        this.advancedSearchNoDocumentsText = advancedSearchLabels['noDocuments'];
      }
    });
    this.translate.onLangChange.pipe(takeUntil(this.stop$)).subscribe((event: LangChangeEvent) => {
      const translatedLabels = event.translations['HomePage'];
      const advancedSearchLabels = event.translations['AdvancedSearch'];
      if (advancedSearchLabels['documents']) {
        this.advancedSearchDocumentsText = advancedSearchLabels['documents'];
      }
      if (advancedSearchLabels['noDocuments']) {
        this.advancedSearchNoDocumentsText = advancedSearchLabels['noDocuments'];
      }

      // this.contentTypeTexts.checked = translatedLabels['contentTypes'];
      // this.contentTypeTexts.checkedPlural = translatedLabels['contentTypes'];
      // this.contentTypeTexts.defaultTitle = translatedLabels['contentTypes'];

      // this.collectionTexts.checked = translatedLabels['Collections'];
      // this.collectionTexts.checkedPlural = translatedLabels['Collections'];
      // this.collectionTexts.defaultTitle = translatedLabels['Collections'];
      // this.collectionTexts.searchPlaceholder = translatedLabels['Collections'];
      // this.collectionTexts.searchEmptyResult = translatedLabels['Collections'];
      // this.collectionTexts.searchNoRenderText = translatedLabels['Collections'];

      // this.contentSubTypeTexts.checked = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.checkedPlural = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.defaultTitle = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.searchPlaceholder = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.searchEmptyResult = translatedLabels['contentSubtypes'];
      // this.contentSubTypeTexts.searchNoRenderText = translatedLabels['contentSubtypes'];

      // this.languageTexts.checked = translatedLabels['languages'];
      // this.languageTexts.checkedPlural = translatedLabels['languages'];
      // this.languageTexts.defaultTitle = translatedLabels['languages'];
      // this.languageTexts.searchPlaceholder = translatedLabels['languages'];
      // this.languageTexts.searchEmptyResult = translatedLabels['languages'];
      // this.languageTexts.searchNoRenderText = translatedLabels['languages'];

      // this.contentSourcesTexts.checked = translatedLabels['contentSources'];
      // this.contentSourcesTexts.checkedPlural = translatedLabels['contentSources'];
      // this.contentSourcesTexts.defaultTitle = translatedLabels['contentSources'];
      // this.contentSourcesTexts.searchPlaceholder = translatedLabels['contentSources'];
      // this.contentSourcesTexts.searchEmptyResult = translatedLabels['contentSources'];
      // this.contentSourcesTexts.searchNoRenderText = translatedLabels['contentSources'];
      if (translatedLabels['illustrationType']) {
        // this.illustrationTypeText.checked = translatedLabels['illustrationType'];
        // this.illustrationTypeText.checkedPlural = translatedLabels['illustrationType'];
        // this.illustrationTypeText.defaultTitle = translatedLabels['illustrationType'];
        // this.illustrationTypeText.searchPlaceholder = translatedLabels['illustrationType'];
        // this.illustrationTypeText.searchEmptyResult = translatedLabels['illustrationType'];
        // this.illustrationTypeText.searchNoRenderText = translatedLabels['illustrationType'];
      }

      this.publicationDateLabel = translatedLabels['publicationDate'];
    }, err => {
      this.translate.onDefaultLangChange.pipe(takeUntil(this.stop$)).subscribe((event: DefaultLangChangeEvent) => {
        const translatedLabels = event.translations['HomePage'];

        const advancedSearchLabels = event.translations['AdvancedSearch'];
        if (advancedSearchLabels['documents']) {
          this.advancedSearchDocumentsText = advancedSearchLabels['documents'];
        }
        if (advancedSearchLabels['noDocuments']) {
          this.advancedSearchNoDocumentsText = advancedSearchLabels['noDocuments'];
        }

        // this.contentTypeTexts.checked = translatedLabels['contentTypes'];
        // this.contentTypeTexts.checkedPlural = translatedLabels['contentTypes'];
        // this.contentTypeTexts.defaultTitle = translatedLabels['contentTypes'];

        // this.collectionTexts.checked = translatedLabels['Collections'];
        // this.collectionTexts.checkedPlural = translatedLabels['Collections'];
        // this.collectionTexts.defaultTitle = translatedLabels['Collections'];
        // this.collectionTexts.searchPlaceholder = translatedLabels['Collections'];
        // this.collectionTexts.searchEmptyResult = translatedLabels['Collections'];
        // this.collectionTexts.searchNoRenderText = translatedLabels['Collections'];

        // this.contentSubTypeTexts.checked = translatedLabels['contentSubtypes'];
        // this.contentSubTypeTexts.checkedPlural = translatedLabels['contentSubtypes'];
        // this.contentSubTypeTexts.defaultTitle = translatedLabels['contentSubtypes'];
        // this.contentSubTypeTexts.searchPlaceholder = translatedLabels['contentSubtypes'];
        // this.contentSubTypeTexts.searchEmptyResult = translatedLabels['contentSubtypes'];
        // this.contentSubTypeTexts.searchNoRenderText = translatedLabels['contentSubtypes'];

        // this.languageTexts.checked = translatedLabels['languages'];
        // this.languageTexts.checkedPlural = translatedLabels['languages'];
        // this.languageTexts.defaultTitle = translatedLabels['languages'];
        // this.languageTexts.searchPlaceholder = translatedLabels['languages'];
        // this.languageTexts.searchEmptyResult = translatedLabels['languages'];
        // this.languageTexts.searchNoRenderText = translatedLabels['languages'];

        // this.contentSourcesTexts.checked = translatedLabels['contentSources'];
        // this.contentSourcesTexts.checkedPlural = translatedLabels['contentSources'];
        // this.contentSourcesTexts.defaultTitle = translatedLabels['contentSources'];
        // this.contentSourcesTexts.searchPlaceholder = translatedLabels['contentSources'];
        // this.contentSourcesTexts.searchEmptyResult = translatedLabels['contentSources'];
        // this.contentSourcesTexts.searchNoRenderText = translatedLabels['contentSources'];
        if (translatedLabels['illustrationType']) {
          // this.illustrationTypeText.checked = translatedLabels['illustrationType'];
          // this.illustrationTypeText.checkedPlural = translatedLabels['illustrationType'];
          // this.illustrationTypeText.defaultTitle = translatedLabels['illustrationType'];
          // this.illustrationTypeText.searchPlaceholder = translatedLabels['illustrationType'];
          // this.illustrationTypeText.searchEmptyResult = translatedLabels['illustrationType'];
          // this.illustrationTypeText.searchNoRenderText = translatedLabels['illustrationType'];
        }
        this.publicationDateLabel = translatedLabels['publicationDate'];
      });
    });
    // this.filterForm.get('contentType').valueChanges.subscribe(contentTypes => {
    //   if (contentTypes.length > 0) {
    //     this.docSubTypes = this.docSubType.value;
    //     if (contentTypes.indexOf('Photographs') !== -1) {
    //       if (this.docSubTypes.indexOf('Photograph') === -1) {
    //         this.docSubTypes.push('Photograph');
    //       }
    //       this.filterForm.controls['docSubType'].setValue(this.docSubTypes);
    //     }
    //   }
    // });
    this.sharedService.currentMessage.pipe(takeUntil(this.stop$)).subscribe((headerDropdownOpen) => {
      this.headerDropdownOpen = headerDropdownOpen;
      this.showFilter = false;
    });
    this.sharedService.archiveValue.pipe(takeUntil(this.stop$)).subscribe(message => {
      this.showFilter = false;
    });
    this.sharedService.toolsValue.pipe(takeUntil(this.stop$)).subscribe(message => {
      this.showFilter = false;
    });
    this.sharedService.otherArchiveValue.pipe(takeUntil(this.stop$)).subscribe(message => {
      this.showFilter = false;
    });
  }
  @Input() public set getFilterValue(value) {
    this.checkFilterFormValidation();

  }
  // @Input() public set getFilterValue(value) {
  //   debugger
  // }
  resetSearchFilter() {
    const advancedFilterParams = new AdvancedFilterParams();
    delete advancedFilterParams['currentManuscriptsOffset'];
    delete advancedFilterParams['currentMonographsOffset'];
    delete advancedFilterParams['currentPhotographsOffset'];
    delete advancedFilterParams['currentContentTypeOffset'];
    sessionStorage.removeItem('termFrequencyUrl');
    sessionStorage.removeItem('termPopularityUrl');
    sessionStorage.removeItem('termFrequency');
    sessionStorage.removeItem('termPopularity');
    this.collectionsTooltip = '';
    this.publicationTitleTooltip = '';
    this.contentSourcesTooltip = '';
    this.contentTypeTooltip = '';
    this.contentSubTypesTooltip = '';
    this.illustrationTypeTooltip = '';
    this.filterForm.setValue(advancedFilterParams);
    this.pageSettingService.updateSearchSettings(new AdvancedFilterParams());
    this.resetVal = false;
    this.loading = true;
    this.estimatedCount = 0;
    this.checkDate = false;
    this.showLoadingTxt.emit(true);
    this.searchService.estimateResultCount(advancedFilterParams, count => {
      // setTimeout(() => {
        if (count > 0) {
          this.loading = false;
          this.estimatedCount = count;
          this.showLoadingTxt.emit(false);
          this.estimatedCountEmit.emit(this.estimatedCount);
        } else {
          this.showLoadingTxt.emit(false);
          this.loading = false;
        }
      // }, 1000);
    });
  }

  populateTooltip(advancedFilters) {
    if (this.pageContent) {
      this.collectionsTooltip = '';
      this.publicationTitleTooltip = '';
      this.contentSourcesTooltip = '';
      this.illustrationTypeTooltip = '';
      this.contentTypeTooltip = '';
      this.contentSubTypesTooltip = '';
      if (advancedFilters.contentCollection) {
        for (
          let index = 0;
          index < advancedFilters.contentCollection.length;
          index++
        ) {
          const element = advancedFilters.contentCollection[index];
          if (element) {
            this.collectionsTooltip += element;
            if (index !== advancedFilters.contentCollection.length - 1) {
              this.collectionsTooltip += ', ';
            }
            if (
              advancedFilters.contentCollection.length ===
              this.pageContent.availablePublicationTitles.length &&
              advancedFilters.contentCollection.length > 20
            ) {
              this.collectionsTooltip =
                'All Content Collections Titles Selected';
            } else if (advancedFilters.contentCollection.length > 5) {
              this.collectionsTooltip =
                advancedFilters.contentCollection.length + ' Collections Selected.';
              // this.toggleLimitorsList(this.optionHTML, event);
            } else {
              if (this.collectionsTooltip.length >= 100) {
                this.collectionsTooltip = this.collectionsTooltip.substr(
                  0,
                  100
                );
                this.collectionsTooltip += '...';
                break;
              }
            }
          }
        }
      }

      if (advancedFilters.languages) {
        for (
          let index = 0;
          index < advancedFilters.languages.length;
          index++
        ) {
          const element = advancedFilters.languages[index];
          if (element) {
            this.publicationTitleTooltip += element;
            if (index !== advancedFilters.languages.length - 1) {
              this.publicationTitleTooltip += ', ';
            }
            if (
              advancedFilters.languages.length ===
              this.pageContent.availablePublicationTitles.length &&
              advancedFilters.languages.length > 20
            ) {
              this.publicationTitleTooltip = 'All languages Selected';
            } else if (advancedFilters.languages.length > 5) {
              // this.toggleLimitorsList(this.optionHTML, event);
              this.publicationTitleTooltip =
                advancedFilters.languages.length + ' languages Selected.';
            } else {
              if (this.publicationTitleTooltip.length >= 100) {
                this.publicationTitleTooltip = this.publicationTitleTooltip.substr(
                  0,
                  100
                );
                this.publicationTitleTooltip += '...';
                break;
              }
            }
          }
        }
      }

      if (advancedFilters.contentSources) {
        for (
          let index = 0;
          index < advancedFilters.contentSources.length;
          index++
        ) {
          const element = advancedFilters.contentSources[index];
          if (element) {
            this.contentSourcesTooltip += element;
            if (index !== advancedFilters.contentSources.length - 1) {
              this.contentSourcesTooltip += ', ';
            }
          }
        }
        if (
          advancedFilters.contentSources.length ===
          this.pageContent.availableSources.length &&
          advancedFilters.contentSources.length > 20
        ) {
          this.contentSourcesTooltip = 'All Sources Selected';
        } else if (advancedFilters.contentSources.length > 5) {
          this.contentSourcesTooltip =
            advancedFilters.contentSources.length + ' Sources Selected.';
        } else {
          if (this.contentSourcesTooltip.length >= 100) {
            this.contentSourcesTooltip = this.contentSourcesTooltip.substr(
              0,
              100
            );
            this.contentSourcesTooltip += '...';
          }
        }
      }
      if (advancedFilters.illustrationType) {
        for (let index = 0; index < advancedFilters.illustrationType.length; index++) {
          const element = advancedFilters.illustrationType[index];
          if (element) {
            this.illustrationTypeTooltip += element;
            if (index !== advancedFilters.illustrationType.length - 1) {
              this.illustrationTypeTooltip += ', ';
            }
          }
        }
        if (
          advancedFilters.illustrationType.length ===
          this.pageContent.availableIllustrationTypes.length &&
          advancedFilters.illustrationType.length > 20
        ) {
          this.illustrationTypeTooltip = 'All Illustrations Selected';
        } else if (advancedFilters.illustrationType.length > 5) {
          this.illustrationTypeTooltip =
            advancedFilters.illustrationType.length + ' illustrations Selected.';
        } else {
          if (this.illustrationTypeTooltip.length >= 100) {
            this.illustrationTypeTooltip = this.contentSourcesTooltip.substr(
              0,
              100
            );
            this.illustrationTypeTooltip += '...';
          }
        }
      }

      if (advancedFilters.docSubType) {
        for (
          let index = 0;
          index < advancedFilters.docSubType.length;
          index++
        ) {
          const element = advancedFilters.docSubType[index];
          if (element) {
            this.contentSubTypesTooltip += element;
            if (index !== advancedFilters.docSubType.length - 1) {
              this.contentSubTypesTooltip += ', ';
            } else if (advancedFilters.docSubType.length > 5) {
              this.contentSubTypesTooltip =
                advancedFilters.docSubType.length +
                ' Content Subtypes Selected.';
            } else {
              if (this.contentSubTypesTooltip.length >= 100) {
                this.contentSubTypesTooltip = this.contentSubTypesTooltip.substr(
                  0,
                  100
                );
                this.contentSubTypesTooltip += '...';
                break;
              }
            }
            // if (
            //   advancedFilters.docSubType.length ===
            //     this.pageContent.availableContentSubTypes.length &&
            //   advancedFilters.SubType.length > 20
            // ) {
            //   this.contentSubTypesTooltip = 'All Content Subtypes Selected';
            // }
          }
        }
      }

      if (advancedFilters.contentType) {
        for (
          let index = 0;
          index < advancedFilters.contentType.length;
          index++
        ) {
          const element = advancedFilters.contentType[index];
          if (element) {
            this.contentTypeTooltip += element;
            if (index !== advancedFilters.contentType.length - 1) {
              this.contentTypeTooltip += ', ';
            }
            if (this.contentTypeTooltip.length >= 100) {
              this.contentTypeTooltip = this.contentTypeTooltip.substr(0, 100);
              this.contentTypeTooltip += '...';
              break;
            }
          }
        }
      }
    }
  }

  toggleLimitorsList(template: TemplateRef<any>, event) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  limitorsClose() {
    this.modalRef.hide();
  }
  ngOnDestroy(): void {
    if (this.unsubAdvSearchArchiveSelection) {
      this.unsubAdvSearchArchiveSelection.unsubscribe();
    }
    if (this.unsubscribeAdvSearch) {
      this.unsubscribeAdvSearch.unsubscribe();
    }
    this.stop$.next();
    this.stop$.complete();
  }
  // checkDateValidation() {
  //   if (this.filterForm.value.publishedDateFrom && this.filterForm.value.publishedDateTo) {
  //     if (this.filterForm.value.publishedDateFrom.split('-')[0] > this.filterForm.value.publishedDateTo.split('-')[0]) {
  //       this.checkDate = true;
  //     } else {
  //       this.checkDate = false;
  //     }
  //   }
  // }
  searchSubmit() {
    // if (this.filterForm.value) {
    //   if (this.filterForm.value.searchKeywords) {
    //     this.filterForm.value.searchKeywords.forEach(obj => {
    //       if (obj.fieldValue.includes('"')) {
    //         obj.fieldValue = obj.fieldValue.trim();
    //       } else {
    //         if (obj.fieldName === 'ALL') {
    //           obj.fieldValue = obj.fieldValue.trim().replace(/[^a-zA-Z0-9 ?*-]/g, '');
    //         } else {
    //           obj.fieldValue = obj.fieldValue.trim();
    //         }
    //       }
    //     });
    //   }
    // }

    let visualBrowseMaps: any;
    this.activatedRoute.params.subscribe((params) => {
      visualBrowseMaps = params['visualBrowseMaps'];
    });
    if (this.pageContent.currentSelectedArchives.length > 0) {
      sessionStorage.removeItem('termFrequencyUrl');
      sessionStorage.removeItem('termPopularityUrl');
      sessionStorage.removeItem('termFrequency');
      sessionStorage.removeItem('termPopularity');
      this.searchService.goToAdvancedSearchPage('', this.filterForm.value, (visualBrowseMaps ? visualBrowseMaps : false));
    }
  }

  toggleDropdown() {
    if (this.isFilterToggled === true) {
      this.isFilterToggled = false;
    } else if (this.isFilterToggled === false) {
      this.isFilterToggled = true;
    }
  }

  isFiltered(form): boolean {
    let hasValues = false;
    Object.keys(form).forEach(key => {
      if (form[key]) {
        if (key === 'searchKeywords') {
          form['searchKeywords'].forEach(element => {
            // if (element.fieldValue !== '') {
              hasValues = true;
            // }
          });
        } else if (key === 'archive') {
        } else if (Array.isArray(form[key]) && form[key].length > 0) {
          hasValues = true;
        } else if (key === 'publishedDatePrefix') {
          if (form.publishedDatePrefix === 'All') {
            hasValues = true;
            this.checkDate = false;
          } else if (form.publishedDatePrefix !== '') {
            if (form.publishedDatePrefix !== 'All') {
              if (form.publishedDatePrefix === 'BETWEEN') {
                if (form.publishedDateFrom && form.publishedDateTo) {
                  this.checkDate = false;
                  hasValues = true;
                  const fullFromDate = form.publishedDateFrom.split('-');
                  const fullToDate = form.publishedDateTo.split('-');
                  if (fullFromDate[0] || fullToDate[0]) {
                    // tslint:disable-next-line:max-line-length
                    if (Math.round(fullFromDate[0]) === 0 || Math.round(fullToDate[0]) === 0 || Math.round(fullFromDate[0]) <= 99 || Math.round(fullToDate[0]) <= 99) {
                      this.checkDate = true;
                      hasValues = false;
                      return false;
                    } else if (fullFromDate[1] || fullToDate[1]) {
                      if (((fullFromDate[1] !== '' && Math.round(fullFromDate[1]) === 0) || Math.round(fullFromDate[1]) > 12) ||
                      ((fullToDate[1] !== '' && Math.round(fullToDate[1]) === 0) || Math.round(fullToDate[1]) > 12)) {
                        hasValues = false;
                        this.checkDate = true;
                        return false;
                      } else if (fullFromDate[2] || fullToDate[2]) {
                        if (((fullFromDate[2] !== '' && Math.round(fullFromDate[2]) === 0) || Math.round(fullFromDate[2]) > 31) ||
                        ((fullToDate[2] !== '' && Math.round(fullToDate[2]) === 0) || Math.round(fullToDate[2]) > 31)) {
                          hasValues = false;
                          this.checkDate = true;
                          return false;
                        }
                        if ((Math.round(fullFromDate[1]) === 4 ||
                            Math.round(fullFromDate[1]) === 6 ||
                            Math.round(fullFromDate[1]) === 9 ||
                            Math.round(fullFromDate[1]) === 11) &&
                            Math.round(fullFromDate[2]) > 30) {
                          hasValues = false;
                          this.checkDate = true;
                          return false;
                        }

                        if ((Math.round(fullToDate[1]) === 4 ||
                            Math.round(fullToDate[1]) === 6 ||
                            Math.round(fullToDate[1]) === 9 ||
                            Math.round(fullToDate[1]) === 11) &&
                            Math.round(fullToDate[2]) > 30) {
                          hasValues = false;
                          this.checkDate = true;
                          return false;
                        }

                        const checkFromLeapYear = this.leapyear(fullFromDate[0]);
                        if (checkFromLeapYear) {
                          if ((Math.round(fullFromDate[1]) === 2 && Math.round(fullFromDate[2]) > 29)) {
                            hasValues = false;
                            this.checkDate = true;
                            return false;
                          }
                        } else {
                          if ((Math.round(fullFromDate[1]) === 2 && Math.round(fullFromDate[2]) > 28)) {
                            hasValues = false;
                            this.checkDate = true;
                            return false;
                          }
                        }

                        const checkToLeapYear = this.leapyear(fullToDate[0]);
                        if (checkToLeapYear) {
                          if ((Math.round(fullToDate[1]) === 2 && Math.round(fullToDate[2]) > 29)) {
                            hasValues = false;
                            this.checkDate = true;
                            return false;
                          }
                        } else {
                          if ((Math.round(fullToDate[1]) === 2 && Math.round(fullToDate[2]) > 28)) {
                            hasValues = false;
                            this.checkDate = true;
                            return false;
                          }
                        }
                      }
                    }
                  }
                  // const fromDate = new Date(form.publishedDateFrom);
                  // const toDate = new Date(form.publishedDateTo);
                  let fromDate = new Date(form.publishedDateFrom);
                    let toDate = new Date(form.publishedDateTo);
                    const fromDateArray = form.publishedDateFrom.split('-');
                    const toDateArray = form.publishedDateTo.split('-');
                    let fDate = '';
                    let _toDate = '';
                    if (fromDateArray[0]) {
                      fDate = fromDateArray[0];
                      if (fromDateArray[1]) {
                        fDate = fromDateArray[0] + '-' +  fromDateArray[1];
                        if (fromDateArray[2]) {
                          fDate = fromDateArray[0] + '-' +  fromDateArray[1] + '-' +  fromDateArray[2];
                        }
                      }
                    }
                    if (toDateArray[0]) {
                      _toDate = toDateArray[0];
                      if (toDateArray[1]) {
                        _toDate = toDateArray[0] + '-' +  toDateArray[1];
                        if (toDateArray[2]) {
                          _toDate = toDateArray[0] + '-' +  toDateArray[1] + '-' +  toDateArray[2];
                        }
                      }
                    }
                    fromDate = new Date(fDate);
                    toDate = new Date(_toDate);
                  if ((fromDate > toDate) ||
                        fromDate.toString() === 'Invalid Date' ||
                        toDate.toString() === 'Invalid Date' ||
                        form.publishedDateFrom.length <= 2 ||
                        form.publishedDateTo.length <= 2
                      ) {
                    hasValues = false;
                    this.checkDate = true;
                    return false;
                  }
                } else {
                  hasValues = false;
                  this.checkDate = true;
                  return false;
                }
              } else {
                if (form.publishedDateFrom) {
                  if (form.publishedDateFrom.length > 2) {
                    this.checkDate = false;
                    hasValues = true;
                    const fullFromDate = form.publishedDateFrom.split('-');
                    if (fullFromDate[0]) {
                      if (Math.round(fullFromDate[0]) === 0 || Math.round(fullFromDate[0]) <= 99) {
                        hasValues = false;
                        this.checkDate = true;
                        return false;
                      } else if (fullFromDate[1]) {
                        if (Math.round(fullFromDate[1]) === 0 || Math.round(fullFromDate[1]) > 12) {
                          hasValues = false;
                          this.checkDate = true;
                          return false;
                        } else if (fullFromDate[2]) {
                          if ((fullFromDate[2] !== '' && Math.round(fullFromDate[2]) === 0) || Math.round(fullFromDate[2]) > 31) {
                            hasValues = false;
                            this.checkDate = true;
                            return false;
                          }
                          if ((Math.round(fullFromDate[1]) === 4 ||
                              Math.round(fullFromDate[1]) === 6 ||
                              Math.round(fullFromDate[1]) === 9 ||
                              Math.round(fullFromDate[1]) === 11) &&
                              Math.round(fullFromDate[2]) > 30) {
                            hasValues = false;
                            this.checkDate = true;
                            return false;
                          }
                          const checkFromLeapYear = this.leapyear(fullFromDate[0]);
                          if (checkFromLeapYear) {
                            if ((Math.round(fullFromDate[1]) === 2 && Math.round(fullFromDate[2]) > 29)) {
                              hasValues = false;
                              this.checkDate = true;
                              return false;
                            }
                          } else {
                            if ((Math.round(fullFromDate[1]) === 2 && Math.round(fullFromDate[2]) > 28)) {
                              hasValues = false;
                              this.checkDate = true;
                              return false;
                            }
                          }
                        }
                      }
                    }
                  } else {
                    hasValues = false;
                    this.checkDate = true;
                    return false;
                  }
                } else {
                  hasValues = false;
                  this.checkDate = true;
                  return false;
                }
              }
            } else {
              hasValues = true;
              this.checkDate = false;
            }
          } else {
            hasValues = true;
            this.checkDate = false;
          }
        } else if (key === 'publishedDateIncludesNull') {
          if (form.publishedDatePrefix === 'All') {
            hasValues = true;
            this.hasValuesUndated = true;
          }
        }
      }
    });
    // this.publicationTitleTooltip = '';
    if (this.filterForm.value.contentCollection.length === 0) {
      this.collectionsTooltip = '';
    }
    if (this.filterForm.value.contentSources.length === 0) {
      this.contentSourcesTooltip = '';
    }
    if (this.filterForm.value.contentType.length === 0) {
      this.contentTypeTooltip = '';
    }
    if (this.filterForm.value.docSubType.length === 0) {
      this.contentSubTypesTooltip = '';
    }
    if (this.filterForm.value.languages.length === 0) {
      this.publicationTitleTooltip = '';
    }
    if (this.filterForm.value.contentType.length === 0) {
      this.publicationTitleTooltip = '';
    }
    if (this.filterForm.value.illustrationType.length === 0) {
      this.publicationTitleTooltip = '';
    }
    return hasValues;

  }

  leapyear(year) {
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
  }

  onFilterOpen() {
    // const fromFilterOpen = true;
    // this.isAdvanceSearchOpen.emit(this.pageContent.showAdvancedFilter);
    if (this.filterForm) {
      const newSettings = new AdvancedFilterParams(
        JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam)));
      this.loading = true;
      delete newSettings.currentManuscriptsOffset;
      delete newSettings.currentMonographsOffset;
      delete newSettings.currentPhotographsOffset;
      delete newSettings.currentContentTypeOffset;
      this.filterForm.setValue(newSettings);
      this.estimatedCount = 0;
      this.showLoadingTxt.emit(true);
      // this.searchService.estimateResultCount(newSettings, count => {
      //   // setTimeout(() => {
      //     if (count > 0) {
      //       this.loading = false;
      //       this.estimatedCount = count;
      //       this.showLoadingTxt.emit(false);
      //       this.estimatedCountEmit.emit(this.estimatedCount);
      //     } else {
      //       this.showLoadingTxt.emit(false);
      //       this.loading = false;
      //     }
      //   // }, 1000);
      // });
      this.publishedDatePrefix.setValue(newSettings['publishedDatePrefix'] || newSettings['publishedDateFrom']);
      this.publishedDateFrom.setValue(newSettings['publishedDateFrom']);
      this.publishedDateTo.setValue(newSettings['publishedDateTo']);
      this._enableSubmit = this.isFiltered(this.filterForm.value);
      this.disableSearch.emit(this._enableSubmit);
      this.populateTooltip(this.filterForm.value);
      var subcribeValue = false;
        this.filterForm.valueChanges.subscribe(form => {
          subcribeValue = true;
          this.changeDetector.markForCheck();
          if (this.estimateTimer) {
            clearTimeout(this.estimateTimer);
            this.estimateTimer = null;
          }
          this.estimateTimer = setTimeout(() => {
            if (this.estimatedCountLabel) {
              this.renderer.removeClass(this.estimatedCountLabel.nativeElement, 'blink-countwords');
            }
            if (this.isFiltered(form)) {
              this._enableSubmit = true;
              this.disableSearch.emit(this._enableSubmit);
              this.loading = true;
              this.showLoadingTxt.emit(true);
             if (this.SelectAllTrue === true) {
                let array =[];
                const nameControl = this.filterForm.get(this.SelectedDropdownValue);
                if(nameControl && nameControl.value.length >0) {
                  for(let k=0; k< nameControl.value.length;k++) {
                    array.push(nameControl.value[k].id)
                  }
                }
                form[this.SelectedDropdownValue] = array;
                this.SelectAllTrue === true
             }
             this.populateTooltip(form);
              this.searchService.estimateResultCount(form, count => {
                this.loading = false;
                this.estimatedCount = count;
                this.showLoadingTxt.emit(false);
                this.estimatedCountEmit.emit(this.estimatedCount);
                this.pageSettingService.updateSetting(
                  'searchResultCount',
                  count
                );
              });
            } else {
              this._enableSubmit = false;
              this.disableSearch.emit(this._enableSubmit);
              this.showLoadingTxt.emit(false);
              this.estimatedCount = 0;
              this.estimatedCountEmit.emit(this.estimatedCount);
            }
            clearTimeout(this.estimateTimer);
          }, 300);
        });  
      if(!subcribeValue) {
         this.searchService.estimateResultCount(newSettings, count => {
        // setTimeout(() => {
          if (count > 0) {
            this.loading = false;
            this.estimatedCount = count;
            this.showLoadingTxt.emit(false);
            this.estimatedCountEmit.emit(this.estimatedCount);
          } else {
            this.showLoadingTxt.emit(false);
            this.loading = false;
          }
        // }, 1000);
      });
      }     
    }
  }

  // onFilterClose() { }
  // onContentTypeChange() { }

  togglePubDate() {
    this.showPubDate = !this.showPubDate;
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.elementReference.nativeElement.contains(event.target)) {
      if (!$(event.target).is('#publication-date')) {
        this.showPubDate = false;
      }
    } else {
      if (this._showFilter) {
        if (!this.isFromHomePage) {
          this.pageContent.showAdvancedFilter = false;
          this._showFilter = false;
        }
      }
    }
    // if ($('.modal-backdrop')) {
    //   if ($('.modal-backdrop').hasClass('in')) {
    //     } else {
    //       this.showFilter = false;
    //     }
    //     } else {
    //       this.showFilter = false;
    //       }
  }



  // Validation for pubDate, We can enable it if needed.

  //   isValidDate() {
  //     const dateString = this.filterForm.value.publishedDateFrom;
  //     const dateSplit = dateString.split('-');
  //     const date = new Date();
  //     const fullYear = date.getFullYear();
  //     if (fullYear >= dateSplit[0]) {
  //       const regEx = /^\d{4}$/;
  //       if (!dateSplit[0].match(regEx) || 12 < dateSplit[1] || 31 < dateSplit[2]) {
  //         this.validDate = false;
  //         return false;
  //        }else {
  //          if (dateSplit[1] == 2 && dateSplit[2] > 29) {
  //           return false;
  //          }else {
  //           this.validDate = true;
  //          }
  //        }
  //     }else {
  //       this.validDate = false;
  //     }
  //     const d = new Date(dateString);
  //     if (!d.getTime() && d.getTime() !== 0) {
  //       return false;
  //     }
  //     return d.toISOString().slice(0, 10) === dateString;
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 1200) {
      this.isFilterToggled = true;
    }
    // if (window.innerWidth <= 1024) {
    //   $('#toggleAboutId').addClass('showAbout');
    //   $('#aboutContainer').addClass('about-toggle');
    //    this.showAboutMenu = true;
    // } else {
    //   $('#toggleAboutId').removeClass('showAbout');
    //   $('#aboutContainer').removeClass('about-toggle');
    //   $('.openAbout').hide();
    //   this.showAboutMenu = false;
    // }
  }

  onRequestParamsChanged(archives: any): void {
    if (archives.length > 0) {
      const newSettings = new AdvancedFilterParams(
        JSON.parse(JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam)));
      delete newSettings.currentManuscriptsOffset;
      delete newSettings.currentMonographsOffset;
      delete newSettings.currentPhotographsOffset;
      delete newSettings.currentContentTypeOffset;
      this._enableSubmit = true;
      this.disableSearch.emit(this._enableSubmit);
      this.notificationService.showLoader();
      this.loading = true;
      this.showLoadingTxt.emit(true);
      this.searchService.estimateResultCount(newSettings, count => {
        this.loading = false;
        this.estimatedCount = count;
        this.showLoadingTxt.emit(false);
        this.estimatedCountEmit.emit(this.estimatedCount);
        this.pageSettingService.updateSetting('searchResultCount', count);
        this.notificationService.hideLoader();
      });
    } else {
      this.estimatedCount = 0;
      this.showLoadingTxt.emit(false);
      this.estimatedCountEmit.emit(this.estimatedCount);
      this.pageSettingService.updateSetting('searchResultCount', this.estimatedCount);
    }
    this.getUpdatedDropdownData({archive: archives});
  }
  /*
  * DESC: get new limiter values based on arhives values
  * @input: archives array
  * @output: Limiter service response as an Object
  */
  getUpdatedDropdownData(archives: any): void {
    if (archives) {
      this.commonService.getDropdownLimiters(archives.archive, res => {
        if (res) {
          this.zone.run(() => {
          this.pageContent.availableContentSubTypes = JSON.parse(JSON.stringify(_orderBy(res.availableContentSubTypes, ['name'], ['asc'])));
          this.pageContent.availableContentTypes = JSON.parse(JSON.stringify(_orderBy(res.availableContentTypes, ['name'], ['asc'])));
          this.pageContent.availableCollections = JSON.parse(JSON.stringify(_orderBy(res.availableCollections, ['name'], ['asc'])));
          this.pageContent.availableLanguages = JSON.parse(JSON.stringify(_orderBy(res.availableLanguages, ['name'], ['asc'])));
          this.pageContent.availableSources = JSON.parse(JSON.stringify(_orderBy(res.availableSources, ['name'], ['asc'])));
          // tslint:disable-next-line:max-line-length
          this.pageContent.availablePublicationTitles = JSON.parse(JSON.stringify(_orderBy(res.availablePublicationTitles, ['name'], ['asc'])));
          this.pageContent.availableIllustrationTypes = JSON.parse(JSON.stringify(_orderBy(res.availableIllustrationTypes, ['name'], ['asc'])));
          const _illArray = [];
          this.pageContent.availableIllustrationTypes.forEach(element => {
              if (element.id === 'Family_table' || element.id === 'Family_table-Printed') {} else {
                _illArray.push(element);
              }
          });
          this.pageContent.availableIllustrationTypes = _illArray;
          this.pageContent.pubDates = res.pubDates;
          this.pageContent.availableSubject = JSON.parse(JSON.stringify(res.availableSubject));
          this.pageSettingService.updatePageSettings(this.pageContent);
          this.updateFields(this.filterForm.value);
          });
        }
        this.notificationService.hideLoader();
      });
    }
  }
  /*
  * DESC: update form data values based on arhives values
  * check values in original array if it`s not then remove from selected array
  * @input: form values
  * @output: updated from values
  */
  updateFields(formData) {
    if (formData.contentType) {
      const arr = this.pageContent.availableContentTypes.filter((item) => {
        return formData.contentType.indexOf(item.name) !== -1;
      });
      formData.contentType = _map(arr, 'id');
    }
    if (formData.docSubType) {
      const arr = this.pageContent.availableContentSubTypes.filter((item) => {
        return formData.docSubType.indexOf(item.name) !== -1;
      });
      formData.docSubType = _map(arr, 'id');
    }
    if (formData.contentSources) {
      const arr = this.pageContent.availableSources.filter((item) => {
        return formData.contentSources.indexOf(item.name) !== -1;
      });
      formData.contentSources = _map(arr, 'id');
    }
    if (formData.illustrationType) {
      const arr = this.pageContent.availableIllustrationTypes.filter((item) => {
        return formData.illustrationType.indexOf(item.name) !== -1;
      });
      formData.illustrationType = _map(arr, 'id');
    }
    if (formData.contentCollection) {
      const arr = this.pageContent.availableCollections.filter((item) => {
        return formData.contentCollection.indexOf(item.name) !== -1;
      });
      formData.contentCollection = _map(arr, 'id');
    }
    if (formData.languages) {
      const arr = this.pageContent.availableLanguages.filter((item) => {
        return formData.languages.indexOf(item.name) !== -1;
      });
      formData.languages = _map(arr, 'id');
    }
    const newSettings = new AdvancedFilterParams(formData);
    delete newSettings.currentManuscriptsOffset;
    delete newSettings.currentMonographsOffset;
    delete newSettings.currentPhotographsOffset;
    delete newSettings.currentContentTypeOffset;
    this.filterForm.setValue(formData);
    this.populateTooltip(formData);
  }
  checkFilterFormValidation() {
    if (this.filterForm.value.publishedDatePrefix !== 'All' && this.filterForm.value.publishedDatePrefix !== '') {
      if (this.filterForm.value.publishedDateFrom) {
        if (this.checkDate) {
          // tslint:disable-next-line:max-line-length
          this.notificationService.showNotification('WARNING', 'WARNING', this.translatedValues.publicationDateValidation || 'To Date should be greater than From Date');
          this.passFilterValue.emit({status : true, random: Math.random()});
          return false;
        }
        if (this.filterForm.value.publishedDatePrefix === 'BETWEEN' && !this.filterForm.value.publishedDateTo) {
          // tslint:disable-next-line:max-line-length
          this.notificationService.showNotification('WARNING', 'WARNING', this.translatedValues.checkFromDateandToDate || 'Please select From Date and To Date');
          this.passFilterValue.emit({status : true, random: Math.random()});
          return false;
        } else {
          this.passFilterValue.emit({status : false, random: Math.random()});
        }
      } else {
        if (this.filterForm.value.publishedDatePrefix === 'BETWEEN') {
          // tslint:disable-next-line:max-line-length
          this.notificationService.showNotification('WARNING', 'WARNING', this.translatedValues.checkFromDateandToDate || 'Please select From Date and To Date');
          this.passFilterValue.emit({status : true, random: Math.random()});
          return false;
        } else {
          if (this.translatedValues) {
            this.notificationService.showNotification('WARNING', 'WARNING', this.translatedValues.checkFromDate || 'Please select Date');
          }
          this.passFilterValue.emit({status : true, random: Math.random()});
          return false;
        }
      }
    } else {
      this.passFilterValue.emit({status : false, random: Math.random()});
    }
  }


  onDropdownOpen() {
    setTimeout(() => {
      $('.multiselect-dropdown-item.open  .dropdown-item input').focus();
    }, 300);

  }
  onItemSelect(e, str?) {
    let array =[];
    const nameControl = this.filterForm.get(str);
    if(nameControl && nameControl.value.length >0) {
      for(let k=0; k< nameControl.value.length;k++) {
        array.push(nameControl.value[k].id)
      }
    }
    this.filterForm.get(str).setValue(array);
  }
  onSelectAll(e, str?) {
    this.SelectAllTrue = true;
    this.SelectedDropdownValue  = str;
  }
}
