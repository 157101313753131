@if (_toggleCoverFlow?.show) {
  <div class="search-results-parent-div coverflowstyle dynamic-search-results-parent-div">
    <!-- [class.dynamic-search-results-parent-div]="loadedFrom === 'wrapper'"  -->
    <span class="pull-right coverflow-close-icon" (click)="closeCoverFlow();">
      <i class="fa fa-close"></i>
    </span>
    <h4 class="search-results-parent-heading"> {{ 'SearchResult.SearchResults' | translate }}
      <span [class.arabic-style]="isArabicLanguage" class="resultset-span">
        @if ((_albums?.length < totalRecoards)) {
          <span class="coverflow-count"> -
            <b>{{previousOffset + 1}} to {{ previousOffset + _albums?.length}}</b> {{'explorer.of' | translate}} <b>{{ totalRecoards }}</b>
          </span>
        }
        @if ((_albums?.length >= totalRecoards)) {
          <span class="coverflow-count"> -
            <b>{{_albums?.length}}</b> {{'explorer.of' | translate}}
            <b>{{ totalRecoards }}</b>
          </span>
        }
        @if (isFromPhotoExplorer) {
          <span> {{'explorer.photo' | translate}}</span>
        }
        @if (isFromMapExplorer) {
          <span> {{'explorer.map' | translate}}</span>
        }
        @if (isFromResultsPage) {
          <span> {{'SearchResult.coverflowDocumentsCount' | translate}}</span>
        }
      </span>
    </h4>
    <div class="search-results-left-arrow">
      <a class="coverflow-image-leftarrow" [class.disabled]="isHidePrevious || _albums?.length === 0 || previousOffset === 0 || _albums?.length === totalRecoards"
      (click)="loadPrevious()" data-slide="prev">‹</a>
    </div>
    <div class="search-coverflow-body search-results-bdy">
      <div class="search-results-image">
        @for (image of _albums; track image; let i = $index) {
          <div class="coverflow-innerdiv" id="coverFlow{{i}}"
            [class.active-coverflow-image]="image?.pageId === currentSelectedpageId || ((image?.docId === currentSelecteddocId) && isFromResultsPage)">
            <div class="search-results-inner-div">
              <div class="coverflow-images-div">
                <div class="coverflow-thumb-image">
                  <img (click)="gotoDocumentDetails(image.docId, image.pageId, i)" [src]="image.thumb" alt="Image"
                    class="center-block coverflow-img">
                  </div>
                </div>
                <div class="coverflow-title-div">
                  <ul class="coverflow-title-list">
                    <li class="document-image-titlelbl" (click)="gotoDocumentDetails(image.docId, image.pageId, i)"
                    title="{{image?.title}}" appDecodeEntities>{{image?.title}}</li>
                    @if (image?.documentTitle) {
                      <li class="document-image-citationlbl" appDecodeEntities>
                        {{'Detail.from' | translate}}
                      {{image?.documentTitle}}</li>
                    }
                    @if (image?.captions?.length > 0) {
                      <li class="document-image-imagecount">
                        <span class="caption-title"> {{'explorer.captions' | translate}} </span>
                        <div class="caption-body">
                          @for (cap of image?.captions; track cap; let last = $last) {
                            <span title="{{image?.captions}}"> {{cap}}
                              @if (!last) {
                                <span>, &nbsp;</span>
                              }
                            </span>
                          }
                        </div>
                      </li>
                    }
                    @if (image?.citation) {
                      <li class="document-image-citationlbl">
                        {{image?.citation}}
                      </li>
                    }
                    @if (image?.source) {
                      <li class="document-image-source-lbl" [attr.title]="image?.source">
                        <span class="source-lbl">{{ 'Detail.Source' | translate }}: </span>
                        <span class="source-lbl-value">{{image?.source}}</span>
                      </li>
                    }
                    @if (image?.imageCount) {
                      <li class="document-image-imagecount" title="{{image?.imageCount}} {{ 'SearchResult.images' | translate }}">
                        <span> {{image?.imageCount}} {{ 'SearchResult.ImagesInThis' | translate }}
                          {{_currentContentType.slice(0, -1)}}
                        </span>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
        <!-- <div class="coverflow-loader" [ngStyle]="{display:coverflowLoader ? 'block':'none'}">
        <img alt="{{'Alt.loaderImage' | translate}}" class="img-icon" src="assets/images/three-dots.svg">
      </div> -->
    </div>
    <div class="search-results-right-arrow">
      <a class="coverflow-image-rightarrow" [class.disabled]="isHideNext || _albums?.length === totalRecoards" (click)="loadNext()">›</a>
    </div>
  </div>
}
