/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable} from '@angular/core';

import { Observable } from 'rxjs';
import { PageSettingsService } from './../services/page-settings.service';

@Injectable({
    providedIn: 'root'
} )
export class PageSettingsResolver  {

    constructor(private pageSettingService: PageSettingsService) {}

    resolve(): Observable<any> {
        return new Observable((observer) => {
            observer.next(this.pageSettingService.getPageSettings());
            observer.complete();
		});
    }
}
