<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->
<div class="col-md-12 no-padding">
  <p class="add-terms-text" [class.arabic-style]="isArabicLanguage" [ngClass]="{'fontResponsive': lang === 'ta'}"> {{ 'textAnalysisPage.analysisAccessedArchives' | translate }} </p>
  <div class="collocates-blurb-div">
    <h6 [class.arabic-style]="isArabicLanguage" class="chart-query-container-title">
      <span>
        <p class="small add-terms-text" [ngClass]="{'fontResponsive': lang === 'ta'}" [class.arabic-style]="isArabicLanguage">{{ 'textAnalysisPage.collocatesBlurb' |
        translate }}</p>
      </span>
    </h6>
  </div>
</div>
<!-- <div class="row"> -->
<div class="term-frequency">
  <div class="row">
    @for (term of archiveArray; track term; let i = $index) {
      <div class="col-md-4 mb20">
        <label for="test" class="text-capitalize fw-bold">
          {{ 'textAnalysisPage.topCollocates' | translate }} - {{term.archive}}</label>
        <div class="analysis-results-div">
          <table class="analysisListTable table table-sm table-hover table-bordered pad-8 collcates-table">
            <thead>
              <tr class="collocates-thead">
                <th [class.arabic-style]="isArabicLanguage" class="text-white">{{ 'textAnalysisPage.collocatesTerm' |
                translate }}</th>
                <th [class.arabic-style]="isArabicLanguage" class="text-white">{{ 'textAnalysisPage.collocate' |
                translate }}</th>
                <th [class.arabic-style]="isArabicLanguage" class="text-white text-end">{{
                'textAnalysisPage.collocatesCount' | translate }} </th>
                <th [class.arabic-style]="isArabicLanguage" class="text-white"></th>
                <!-- <th [class.arabic-style]="isArabicLanguage" class="text-white"></th> -->
              </tr>
            </thead>
            <tbody>
              @for (analysis of term.rows; track analysis; let i = $index) {
                <tr>
                  <td>{{analysis.term}}</td>
                  <td>{{analysis.collocate}}</td>
                  <td class="text-end">{{analysis.count}}</td>
                  <td>
                    <a (click)="gotoDocumentDetailsPage(analysis.term, analysis.collocate)"
                      class="primary-text-color pointer analysis-link collocates-btn"
                      title="{{ 'Common.Search' | translate}}">
                      <i class="fa fa-lg fa-search collocates-search-icn"></i>
                    </a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }

    @for (term of  _filterInputs.terms; track term; let i = $index) {
      <div class="col-lg-4 col-md-6 col-sm-12 mb20" id="{{term}}">
        <a title="{{ 'Common.download' | translate }}" href="javascript:void(0)" id="download"  [ngClass]="{'disabled': (resultset[term]?.result?.rows?.length === 0 || !resultset[term]?.result?.rows) }" (click)="collocatesService.downloadCollocation('collcationTable_' + i,  term, _advancedFilterParam)"
          class="btn btn-primary pull-right download-btn"><i class="fa fa-download"></i></a>
          <a title="{{ 'textAnalysisPage.print' | translate }}" href="javascript:void(0)" id="print" [ngClass]="{'disabled': (resultset[term]?.result?.rows?.length === 0 || !resultset[term]?.result?.rows) }"  (click)="collocatesService.printCollocation('collcationTable_' + i, term, _advancedFilterParam)"
            class="btn btn-primary pull-right print-btn"><i class="fa fa-print"></i></a>
            <label for="test" class="text-capitalize fw-bold">{{ 'textAnalysisPage.topCollocates' | translate }} - {{term}}</label>
            <div class="analysis-results-div collcationTable" id="collcationTable_{{i}}">
              <table class="analysisListTable table table-sm table-hover table-bordered pad-8 collcates-table">
                <thead>
                  <tr class="collocates-thead">
                <th title="{{ 'textAnalysisPage.collocatesTerm' |
                          translate }}" [class.arabic-style]="isArabicLanguage" class="text-white">{{ 'textAnalysisPage.collocatesTerm' |
                    translate }}</th>
                <th title="{{ 'textAnalysisPage.collocate' |
                          translate }}" [class.arabic-style]="isArabicLanguage" class="text-white">{{ 'textAnalysisPage.collocate' |
                    translate }}</th>
                <th title="{{ 'textAnalysisPage.collocatesCount' |
                          translate }}" [class.arabic-style]="isArabicLanguage" class="text-white text-end">{{
                    'textAnalysisPage.collocatesCount' | translate }} </th>
                    <th [class.arabic-style]="isArabicLanguage" class="text-white"></th>
                    <!-- <th [class.arabic-style]="isArabicLanguage" class="text-white print-hide"></th> -->
                  </tr>
                </thead>
                <tbody>
                  @if (resultset[term]?.result?.rows) {
                    @for (analysis of resultset[term]?.result?.rows; track analysis; let i = $index) {
                      <tr>
                        <td>{{analysis.term}}</td>
                        <td>{{analysis.collocate}}</td>
                        <td class="text-end">{{analysis.count}}</td>
                        <td class="print-hide">
                          <a (click)="gotoDocumentDetailsPage(analysis.term, analysis.collocate)"
                            class="primary-text-color pointer analysis-link collocates-btn"
                            title="{{ 'Common.Search' | translate}}">
                            <i class="fa fa-lg fa-search collocates-search-icn"></i>
                          </a>
                        </td>
                      </tr>
                    }
                  }
                  <ng-container>
                    @if (!resultset[term]?.result?.rows?.length > 0 && !resultset[term]?.loading) {
                      <div class="no-results">
                        <div class="no-results-text">
                          <p class="text-center">{{ 'textAnalysisPage.noResultsFound' | translate }}
                            <span class="" triggers="mouseenter:mouseleave"
                              [popover]="helpTemplate" [placement]="'top'" [outsideClick]="true" #popupRef="bs-popover">
                              <i class="fa fa-question-circle exclamation-icn" ></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    }
                  </ng-container>
                  @if (resultset[term]?.loading) {
                    <div class="no-results">
                      <div class="no-results-text">
                        <p class="text-center primary-text-color">
                          <img alt="{{'Alt.loaderImage' | translate}}" src="assets/images/dot-loader-dark.gif">
                        </p>
                      </div>
                    </div>
                  }
                  <ng-template #helpTemplate>
                    {{ 'textAnalysisPage.noResultsHelpText' | translate }}
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    </div>
  <!-- </div> -->
  <div id="img-out" style="display: none;"></div>

