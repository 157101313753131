<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  -->

@if (pageContent) {
  <ul id="showAdvFilter" class="dropdown-menu dropdown-menu-right dropdown-form" [class.homepageDropdown]="isFromHomePage"
    [class.dropdwn-minwidth]="!isFromHomePage" role="menu" [ngStyle]="{ 'display' : showFilter ? 'block' : 'none'}">
    <div class="option-list-wrapper"></div>
    <li role="presentation">
      <div class="search-filter-container">
        <form (keydown.enter)="$event.preventDefault()" [formGroup]="filterForm" class="advance-search-menu" [class.hideDropdownContent]="isFilterToggled === false && isFromHomePage === true "
          (submit)="searchSubmit()">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <span class="estimated-count-div">
              @if (!isFromHomePage) {
                <span>
                  @if (!isFromHomePage) {
                    <span [class.arabic-style]="isArabicLanguage" class="text-italic estimated-count">{{ 'AdvancedSearch.estimatedCount' | translate }} </span>
                  }
                  <span class="blink-wordlbl mb-0" #estimatedCountLabel>
                    @if (!isFromHomePage && (estimatedCount === 0 && !loading || estimatedCount > 0 && !loading)) {
                      <span>
                        <b>{{(estimatedCount == 0) ? advancedSearchNoDocumentsText : estimatedCount + advancedSearchDocumentsText}}</b>
                      </span>
                    }
                    @if (loading) {
                      <span>
                        {{'AdvancedSearch.Loading' | translate}}
                      </span>
                    }
                    <span [class.arabic-style]="isArabicLanguage" class="sr-only">{{ 'AdvancedSearch.estimatedCount' | translate }}</span>
                  </span>
                  <span class="sr-only">{{ 'AdvancedSearch.estimatedCount' | translate }}</span>
                </span>
              }
              @if (!isFromHomePage) {
                <span [class.arabic-style]="isArabicLanguage" class="pull-right reset_link" title="{{ 'AdvancedSearch.Reset' | translate }}"
                (click)="resetSearchFilter()">{{ 'AdvancedSearch.CapsReset' | translate }}</span>
              }
            </span>
          </div>
          <div class="row" [ngClass]="customeClass === 'true'? 'fliter-content-areaRevise' : 'fliter-content-area'">
            <div class="col-lg-7 col-md-12 col-sm-12 padding-right-0" [class.search-fullWidth]="!isFromHomePage">
              <label [class.arabic-style]="isArabicLanguage" class="quick-limiter">{{ 'AdvancedSearch.fieldedSearch' | translate }}</label>
              <filter-control [formControl]="searchKeywords" [field-options]="pageContent.availableFields" [operator-options]="pageContent.availableOperators"></filter-control>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 padding-right-0" [class.search-fullWidth]="!isFromHomePage">
              <label [class.arabic-style]="isArabicLanguage" class="quick-limiter">{{ 'AdvancedSearch.quickLimitors' | translate }}</label>
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 advanced-searchdiv">
                  <span [ngClass]="{'hidecontentTypeTooltip': !contentTypeTooltip}">
                    <ng-template #contentTypeTooltipText>
                      <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentTypes' | translate }}</label>
                      <br/>{{contentTypeTooltip}}
                    </ng-template>
                    <ng-multiselect-dropdown
                      name="city"
                      [placeholder]="'Content Types'"
                      [settings]="dropdownSettingsWithoutSearch"
                      [data]="pageContent.availableContentTypes"
                      formControlName="contentType"
                      (onSelect)="onItemSelect($event, 'contentType')"
                      (onSelectAll)="onSelectAll($event, 'contentType')"
                      [tooltip]="contentTypeTooltipText" placement="top">
                    </ng-multiselect-dropdown>
                  <!-- <ss-multiselect-dropdown [tooltip]="contentTypeTooltipText" placement="top" [options]="pageContent.availableContentTypes" [texts]="{ checked: contentTypeTexts.checked, checkedPlural:
contentTypeTexts.checkedPlural, defaultTitle: contentTypeTexts.defaultTitle, searchPlaceholder: contentTypeTexts.searchPlaceholder
}" [settings]="contentTypeSettings" formControlName="contentType" [class.highlight-limitors]="contentTypeTooltip ? true : false"></ss-multiselect-dropdown> -->
                </span>
                <span>
                  <div class="pubDate-div button-group custom-tooltip">
                    <ng-template #publicationdateTooltip>
                      <label>{{ 'HomePage.publicationDate' | translate }}</label>
                      <br/>
                    </ng-template>
                    <button [tooltip]="publicationdateTooltip" placement="top" type="button" data-toggle="tooltip" class="dropdown-search rightpadding-0 dropdown-element pub-date down-fa dropdown-toggle"
                      data-bs-toggle="dropdown" id="publication-date" (click)="togglePubDate()" [class.highlightpubs-limitors]="filterForm.get('publishedDatePrefix').value == '' ? false : true">
                      {{filterForm.get('publishedDatePrefix').value == 'All' ? 'All' : (filterForm.get('publishedDateFrom').value || filterForm.get('publishedDateTo').value)
                      ? (filterForm.get('publishedDateFrom').value == 'All' ? '' : filterForm.get('publishedDatePrefix').value
                      + ' : ' ) + filterForm.get('publishedDateFrom').value : publicationDateLabel}} {{ filterForm.get('publishedDateTo').value
                      ? ' & ' + filterForm.get('publishedDateTo').value : '' }} {{(filterForm.get('publishedDateIncludesNull').value
                      && filterForm.get('publishedDatePrefix').value ) ? 'And Undated' : '' }}
                    </button>
                    <ul class="dropdown-menu publicate-date-ul cus-date" (click)="$event.stopPropagation();">
                      <li>
                        <div class="date-pickeron" data-reference="publication-date">
                          <a href="javascript:void(0);" title="{{'HomePage.All' | translate}}" class="small" data-value="All">
                            <input value="All" id="select_all_pubdate" type="radio" [formControl]="publishedDatePrefix" />
                            <label for="select_all_pubdate">
                              <span [class.arabic-style]="isArabicLanguage">
                                {{ 'HomePage.ToolsDropdown.ALL' | translate }}
                              </span>
                            </label>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="date-pickeron" data-reference="publication-date">
                          <a href="javascript:void(0);" title="{{ 'HomePage.On' | translate }}" class="small" data-value="ON">
                            <input value="ON" id="on" type="radio" [formControl]="publishedDatePrefix" />
                            <label for="on">
                              <span [class.arabic-style]="isArabicLanguage">
                                {{ 'HomePage.On' | translate }}
                              </span>
                            </label>
                          </a>
                          @if (filterForm.get('publishedDatePrefix').value == 'ON') {
                            <div>
                              <input [class.arabic-style]="isArabicLanguage" type="text" mask="0000-M0-d0" [leadZeroDateTime]="true" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                class="date-picker" placeholder="{{'Common.Placeholder.date' | translate}}" [formControl]="publishedDateFrom" />
                                @if (checkDate && (publishedDateFrom.dirty || publishedDateFrom.touched)) {
                                  <p class="date-error mb-0">Enter valid Date</p>
                                }
                              </div>
                            }
                          </div>
                        </li>
                        <li>
                          <div class="date-pickeron" data-reference="publication-date">
                            <a href="javascript:void(0);" title="{{ 'HomePage.Before' | translate }}" class="small" data-value="Before">
                              <input class="test-radio" value="BEFORE" id="before" type="radio" [formControl]="publishedDatePrefix" />
                              <label for="before">
                                <span [class.arabic-style]="isArabicLanguage">
                                  {{ 'HomePage.Before' | translate }}
                                </span>
                              </label>
                            </a>
                            @if (filterForm.get('publishedDatePrefix').value == 'BEFORE') {
                              <div>
                                <input [class.arabic-style]="isArabicLanguage" mask="0000-M0-d0" [leadZeroDateTime]="true" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                  class="date-picker" placeholder="{{'Common.Placeholder.date' | translate}}" type="text" [formControl]="publishedDateFrom"/>
                                  @if (checkDate && (publishedDateFrom.dirty || publishedDateFrom.touched)) {
                                    <p class="date-error mb-0">Enter valid Date</p>
                                  }
                                </div>
                              }
                            </div>
                          </li>
                          <li>
                            <div class="date-pickeron" data-reference="publication-date">
                              <a href="javascript:void(0);" title="{{ 'HomePage.After' | translate }}" class="small" data-value="After" tabIndex="1">
                                <input value="AFTER" id="after" type="radio" [formControl]="publishedDatePrefix" />
                                <label for="after">
                                  <span [class.arabic-style]="isArabicLanguage">
                                    {{ 'HomePage.After' | translate }}
                                  </span>
                                </label>
                              </a>
                              @if (filterForm.get('publishedDatePrefix').value == 'AFTER') {
                                <div>
                                  <input [class.arabic-style]="isArabicLanguage" mask="0000-M0-d0" [leadZeroDateTime]="true" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                    class="date-picker" placeholder="{{'Common.Placeholder.date' | translate}}" type="text" [formControl]="publishedDateFrom"
                                    />
                                    @if (checkDate && (publishedDateFrom.dirty || publishedDateFrom.touched)) {
                                      <p class="date-error mb-0">Enter valid Date</p>
                                    }
                                  </div>
                                }
                              </div>
                            </li>
                            <li>
                              <div class="date-pickeron" data-reference="publication-date">
                                <a href="javascript:void(0);" title="{{ 'HomePage.Between' | translate }}" class="small" data-value="Between" tabIndex="1">
                                  <input value="BETWEEN" id="Between" type="radio" [formControl]="publishedDatePrefix" />
                                  <label for="Between">
                                    <span [class.arabic-style]="isArabicLanguage">
                                      {{ 'HomePage.Between' | translate }}
                                    </span>
                                  </label>
                                </a>
                                @if (filterForm.get('publishedDatePrefix').value == 'BETWEEN') {
                                  <div>
                                    <input [class.arabic-style]="isArabicLanguage" class="date-picker" mask="0000-M0-d0" [leadZeroDateTime]="true" [showMaskTyped]="true" [dropSpecialCharacters]="false" placeholder="{{'Common.Placeholder.date' | translate}}"
                                      type="text" [formControl]="publishedDateFrom"/>
                                      <input [class.arabic-style]="isArabicLanguage" class="date-picker" mask="0000-M0-d0" [leadZeroDateTime]="true" [showMaskTyped]="true" [dropSpecialCharacters]="false" placeholder="{{'Common.Placeholder.date' | translate}}"
                                        type="text" [formControl]="publishedDateTo"/>
                                        @if (checkDate  && (publishedDateFrom.dirty || publishedDateFrom.touched || publishedDateTo.dirty || publishedDateTo.touched)) {
                                          <p class="date-error mb-0">Enter valid Date</p>
                                        }
                                      </div>
                                    }
                                  </div>
                                </li>
                                <li>
                                  <div class="pubdate-checkboxdiv">
                                    <label [class.arabic-style]="isArabicLanguage" class="pubdate-checkbox">{{ 'AdvancedSearch.includeUndatesDocuments' | translate }}
                                      <input type="checkbox" [formControl]="publishedDateIncludesNull">
                                      <span class="checkmark"></span>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </span>
                          <span [ngClass]="{'hideCollectionTooltip': !collectionsTooltip}">
                            <!-- <tooltip-content #collectionsTooltipText>
                            <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.Collections' | translate }}</label>
                          <br/>{{collectionsTooltip}}</tooltip-content> -->
                          <ng-template #collectionsTooltipText>
                            <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.Collections' | translate }}</label>
                            <br/>{{collectionsTooltip}}
                          </ng-template>
                          <ng-multiselect-dropdown
                            name="city"
                            [placeholder]="'Collections'"
                            [settings]="dropdownSettingsWithoutSearch"
                            [data]="pageContent.availableCollections"
                            formControlName="contentCollection"
                            (onSelect)="onItemSelect($event, 'contentCollection')"
                            (onSelectAll)="onSelectAll($event, 'contentCollection')"
                            [tooltip]="collectionsTooltipText" placement="top">
                          </ng-multiselect-dropdown>
                          <!-- <ss-multiselect-dropdown class="contentCollection"  [options]="pageContent.availableCollections"
                          [tooltip]="collectionsTooltipText" lacement="top"
                      [class.highlight-limitors]="collectionsTooltip ? true : false" [texts]="{ checked: collectionTexts.checked, checkedPlural:
collectionTexts.checkedPlural, defaultTitle: collectionTexts.defaultTitle, searchPlaceholder: collectionTexts.searchPlaceholder
}" [settings]="collectionsSettings" formControlName="contentCollection" (dropdownOpened) = "onDropdownOpen()"></ss-multiselect-dropdown> -->
                        <!-- <ng-template #collectionsTooltipTemplate>Here we go: <div [innerHtml]="html"></div></ng-template> -->
                      </span>
                      <span [ngClass]="{'hideillustrationTypeTooltip': !illustrationTypeTooltip}">
                        <!-- <tooltip-content #illustrationTypeTooltipText>
                        <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.illustrationType' | translate }}</label>
                      <br />{{illustrationTypeTooltip}}</tooltip-content> -->
                      <ng-template #illustrationTypeTooltipText>
                        <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.illustrationType' | translate }}</label>
                        <br />{{illustrationTypeTooltip}}
                      </ng-template>
                      <ng-multiselect-dropdown
                        name="city"
                        [placeholder]="'Illustration Types'"
                        [settings]="dropdownSettingsWithoutSearch"
                        [data]="pageContent.availableIllustrationTypes"
                        formControlName="illustrationType"
                        (onSelect)="onItemSelect($event, 'illustrationType')"
                        (onSelectAll)="onSelectAll($event, 'illustrationType')"
                        [tooltip]="illustrationTypeTooltipText" placement="top">
                      </ng-multiselect-dropdown>
                      <!-- <ss-multiselect-dropdown class="illustrationType" [options]="pageContent.availableIllustrationTypes"
                      [tooltip]="illustrationTypeTooltipText" placement="top"
                  [texts]="{ checked: illustrationTypeText.checked, checkedPlural: illustrationTypeText.checkedPlural, defaultTitle:
              illustrationTypeText.defaultTitle, searchPlaceholder: illustrationTypeText.searchPlaceholder }"
                      [settings]="illustrationTypeSettings" formControlName="illustrationType" (dropdownOpened) = "onDropdownOpen()"
                    [class.highlight-limitors]="illustrationTypeTooltip ? true : false"></ss-multiselect-dropdown> -->
                  </span>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 advanced-searchdiv">
                  <span [ngClass]="{'hidecontentSubTypesTooltip': !contentSubTypesTooltip}">
                    <!-- <tooltip-content #contentSubTypeTooltipText>
                    <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentSubtypes' | translate }}</label>
                  <br/>{{contentSubTypesTooltip}}</tooltip-content> -->
                  <ng-template #contentSubTypeTooltipText>
                    <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentSubtypes' | translate }}</label>
                    <br/>{{contentSubTypesTooltip}}
                  </ng-template>
                  <ng-multiselect-dropdown
                    name="city"
                    [placeholder]="'Content Subtypes'"
                    [settings]="dropdownSettingsWithoutSearch"
                    [data]="pageContent.availableContentSubTypes"
                    formControlName="docSubType"
                    (onSelect)="onItemSelect($event, 'docSubType')"
                    (onSelectAll)="onSelectAll($event, 'docSubType')"
                    [tooltip]="contentSubTypeTooltipText" placement="top">
                  </ng-multiselect-dropdown>
                  <!-- <ss-multiselect-dropdown class="docSubType" [options]="pageContent.availableContentSubTypes"
                    [tooltip]="contentSubTypeTooltipText" placement="top" [texts]="{ checked: contentSubTypeTexts.checked, checkedPlural: contentSubTypeTexts.checkedPlural, defaultTitle: contentSubTypeTexts.defaultTitle,
searchPlaceholder: contentSubTypeTexts.searchPlaceholder }" (dropdownOpened) = "onDropdownOpen()" [settings]="contentSubTypeSettings" formControlName="docSubType"
                [class.highlight-limitors]="contentSubTypesTooltip ? true : false"></ss-multiselect-dropdown> -->
              </span>
              <span [ngClass]="{'hidepublicationTitleTooltip': !publicationTitleTooltip}">
                <!-- <tooltip-content #publicationTitleTooltipText>
                <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.languages' | translate }}</label>
              <br/>{{publicationTitleTooltip}}</tooltip-content> -->
              <ng-template #publicationTitleTooltipText>
                <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.languages' | translate }}</label>
                <br/>{{publicationTitleTooltip}}
              </ng-template>
              <ng-multiselect-dropdown
                name="city"
                [placeholder]="'Languages'"
                [settings]="dropdownSettingsWithoutSearch"
                [data]="pageContent.availableLanguages"
                formControlName="languages"
                (onSelect)="onItemSelect($event, 'languages')"
                (onSelectAll)="onSelectAll($event, 'languages')"
                [tooltip]="publicationTitleTooltipText" placement="top">
              </ng-multiselect-dropdown>
              <!-- <ss-multiselect-dropdown class="languages" [options]="pageContent.availableLanguages"
                    [tooltip]="publicationTitleTooltipText" placement="top" [texts]="{ checked: languageTexts.checked, checkedPlural: languageTexts.checkedPlural, defaultTitle: languageTexts.defaultTitle,
searchPlaceholder: languageTexts.searchPlaceholder }" (dropdownOpened) = "onDropdownOpen()" [settings]="languageSettings" formControlName="languages" [class.highlight-limitors]="publicationTitleTooltip ? true : false"></ss-multiselect-dropdown> -->
          </span>
          <span [ngClass]="{'hidecontentSourcesTooltip': !contentSourcesTooltip}">
            <!-- <tooltip-content #contentSourcesTooltipText class="contentsource-tooltip">
            <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentSources' | translate }}</label>
          <br/>{{contentSourcesTooltip}}</tooltip-content> -->
          <ng-template #contentSourcesTooltipText>
            <label [class.arabic-style]="isArabicLanguage">{{ 'HomePage.contentSources' | translate }}</label>
            <br/>{{contentSourcesTooltip}}
          </ng-template>
          <ng-multiselect-dropdown
            name="city"
            [placeholder]="'Content Sources'"
            [settings]="dropdownSettingsWithoutSearch"
            [data]="pageContent.availableSources"
            formControlName="contentSources"
            (onSelect)="onItemSelect($event, 'contentSources')"
            (onSelectAll)="onSelectAll($event, 'contentSources')"
            [tooltip]="contentSourcesTooltipText" placement="top">
          </ng-multiselect-dropdown>
          <!-- <ss-multiselect-dropdown class="contentSources" [options]="pageContent.availableSources"
          [tooltip]="contentSourcesTooltipText" placement="top"
                    [texts]="{ checked: contentSourcesTexts.checked, checkedPlural: contentSourcesTexts.checkedPlural, defaultTitle:
contentSourcesTexts.defaultTitle, searchPlaceholder: contentSourcesTexts.searchPlaceholder }" [settings]="contentSourcesSettings" (dropdownOpened) = "onDropdownOpen()"
        formControlName="contentSources" [class.highlight-limitors]="contentSourcesTooltip ? true : false"></ss-multiselect-dropdown> -->
      </span>
    </div>
  </div>
</div>
</div>
<button type="submit" class="sr-only"></button>
</form>
<ng-template #templateConfirmPopup>
  <div class="modal-header">
    <h4 [class.arabic-style]="isArabicLanguage" class="modal-title pull-left">{{ 'AdvancedSearch.Confirmation' | translate }}</h4>
  </div>
  <div [class.arabic-style]="isArabicLanguage" class="modal-body">
    {{ 'AdvancedSearch.exceededMaximumSection' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-lg" aria-label="Close" (click)="limitorsClose()">
      <span [class.arabic-style]="isArabicLanguage" aria-hidden="true">{{ 'AdvancedSearch.OK' | translate }}</span>
    </button>
  </div>
</ng-template>
</div>
</li>
<div class="filter-toggle-icon" [class.hide-toggle]="!isFromHomePage">
  <div class="text-center">
    <a class="search-toggle-btn" (click)="toggleDropdown()">
      <i [ngClass]="{'arrowUpIcon':isFilterToggled ,'arrowDownIcon': !isFilterToggled  }" class="spriteico arrowDownIcon" aria-hidden="true"></i>
    </a>
  </div>
</div>
</ul>
}
