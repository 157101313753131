/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
// import { SearchService } from './../common/services/search.service';
// import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../common/services/sharedService';
import { isEmpty as _isEmpty } from 'lodash';
// import { PageSettingsService } from '../common/services/page-settings.service';

@Component({
  selector: 'app-image-swipe-slider',
  templateUrl: './image-swipe-slider.component.html',
  styleUrls: ['./image-swipe-slider.component.css']
})
export class ImageSwipeSliderComponent implements AfterViewInit {
  specialParams: any;
  selectedDocImage: any;
  images: string[];
  selectedImage: string;
  selectedImageIndex: number;
  @Input() group: any;
  @Input() document: any;
  @Input() thumbnailImages: any;
  @Input() hasFolderImage;
  @Input() documentIndex: any;
  // private pageContentData: any;
  private imageObj: any;
  imageviewerframeLoader = false;
  private documentID: any;
  private pageID: any;
  noImageUrl: string;
  @Output('callDocViewerOutput') private callDocViewerOutput: EventEmitter<any> = new EventEmitter();



  constructor(
    private sharedService: SharedService
  ) {
    this.selectedImage = 'assets/images/dot-loader.gif';
    this.selectedDocImage = null;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.images = this.thumbnailImages;
      // this.selectedImage = this.images[0];
      // this.selectedImageIndex = 0;
      if (this.hasFolderImage) {
        this.selectedImage = this.images[1] ? this.images[1] : this.images[0];
        this.selectedImageIndex = 1;
      } else {
        this.selectedImage = this.images[0];
        this.selectedImageIndex = 0;
      }
      this.selectedDocImage = null;
      if (this.document) {
        if (this.document.archive) {
          if (this.document.archive.toUpperCase() === 'RAI' && this.group === 'Photographs') {
            if (this.document.folderImages) {
              this.document.folderImages.forEach(obj => {
                if (this.images.includes(obj)) {
                  if (!this.selectedDocImage) {
                    this.selectedDocImage = obj;
                    this.selectedImage = obj;
                    this.selectedImageIndex = this.images.indexOf(obj);
                  }
                }
              });
            }
          }
        }
      }
      this.loadImages(this.selectedImage);
    });
  this.sharedService.mapParam.subscribe(res => {
      if (!_isEmpty(res)) {
        this.specialParams = res;
      }
    });
  }

  loadImages(selectedImage, callBack?) {
    this.imageObj = new Image();
    this.imageObj.src = selectedImage;
    this.imageObj.onload = () => {
      if (typeof callBack === 'function') {
        setTimeout(() => {
          callBack(selectedImage);
        }, 500);
      }
    };
    this.noImageUrl = 'assets/images/noimage.jpg';
  }

  loadNextImage() {

    if ((this.selectedImageIndex + 1) <= this.images.length - 1) {
      this.selectedImage = this.images[this.selectedImageIndex + 1];
      this.selectedImageIndex += 1;
      // this.showLoader();
      this.loadImages(this.selectedImage, () => {
        // this.hideLoader();
      });
    }
  }

  loadPreviousImage() {
    if ((this.selectedImageIndex - 1) >= 0) {
      this.selectedImage = this.images[this.selectedImageIndex - 1];
      this.selectedImageIndex -= 1;
      // this.showLoader();
      this.loadImages(this.selectedImage, () => {
        // this.hideLoader();
      });
    }
  }

  showLoader() {
    this.imageviewerframeLoader = true;
  }
  hideLoader() {
    this.imageviewerframeLoader = false;
  }
  // gotoDocumentDetails() {
  //   const currentUrl = window.location.href;
  //   sessionStorage.setItem('currentUrl', currentUrl);
  //   this.documentID = this.selectedImage.toString().split('/')[this.selectedImage.toString().split('/').length - 2];
  //   this.pageID = this.selectedImage.toString().split('/')[this.selectedImage.toString().split('/').length - 1];
  //   let spatialFilterParams: any;
  //   if (this.specialParams) {
  //     spatialFilterParams = JSON.stringify(this.specialParams['spatialFilterParams']);
  //   }
  //   this.router.navigate(['../detail/' + this.documentID,
  //     {
  //       viewPage: this.pageID,
  //       docID: this.documentID,
  //       start: this.documentIndex || 0,
  //       spatialFilterParams: spatialFilterParams ? spatialFilterParams : '',
  //       isFromResultsPage: true,
  //       filterParam: JSON.stringify(PageSettingsService.pageSettings.advancedFilterParam),
  //       searchTerm: PageSettingsService.pageSettings.searchTerm ? PageSettingsService.pageSettings.searchTerm : ''
  //     }],
  //     { relativeTo: this.activatedRoute });
  // }

  gotoDocumentDetails() {
    this.documentID = this.selectedImage.toString().split('/')[this.selectedImage.toString().split('/').length - 2];
    this.pageID = this.selectedImage.toString().split('/')[this.selectedImage.toString().split('/').length - 1];
    this.pageID = this.pageID.split('?');
    const pageDetails = {'docId': this.documentID, 'pageId' : this.pageID[0], 'index': this.documentIndex};
    this.callDocViewerOutput.emit(pageDetails);
  }
}
