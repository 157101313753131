/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
export type NotificationType = 'Success' | 'Error' | 'Info' | 'Warn';
export type NotificationAnimateType = 'fade' | 'fadeOut' | 'fromTop' |
  'fromTopOut' | 'fromRight' | 'fromRightOut' | 'fromLeft' | 'fromLeftOut' |
  'fromBottom' | 'fromBottomOut' | 'scale' | 'scaleOut' | 'rotate' | 'rotateOut';

export const DEFAULT_OPTIONS = {
  timeOut: 4000,
  autoClose: true,
  animate: 'fromRight'
};

export class NotificationModel {
  alertType: NotificationType;
  title: string;
  content: string;
  timeOut: number;
  autClose: boolean;
  animate: string;

  constructor(alertType, title, msg, timeOut = DEFAULT_OPTIONS.timeOut, autoClose = DEFAULT_OPTIONS.autoClose, animate = 'fromRight') {
    this.alertType = alertType;
    this.title = title || 'Info';
    this.content = msg || '';
    this.timeOut = timeOut;
    this.autClose = autoClose;
    this.animate = animate || 'fromRight';
  }
}
