<!-- Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved -->



@if (dropDownOptions?.length > 0) {
  <div class="input-group-btn archive-btnopen">
    <button [class.arabic-style]="isArabicLanguage" type="button" title="{{'AdvancedSearch.archives' | translate}}" class="btn dropdown-toggle" (click)="toggleOptionsList();showArchives = !showArchives; $event.stopPropagation()"  >
      {{'Titles.Archives' | translate}}
      <span [ngClass]="{ 'fa-chevron-up' :showArchives, 'fa-chevron-down' :!showArchives} " class="fa dropdown-fa"></span>
      @if (selectedOptions?.length > 0) {
        <p [class.arabic-style]="isArabicLanguage" class="archives-count"> {{selectedOptions?.length}} <span class="text_lowercase"> {{ 'Detail.OF' | translate }} </span> {{dropDownOptions?.length}} {{ 'AdvancedSearch.selectedArchivesCount' | translate }} </p>
      }
    </button>
    <ul #optionsList class="dropdown-menu option-list" (click)="$event.stopPropagation()"  [ngStyle]="{'display': showArchives ? 'block': 'none'}">
      @if (dropDownOptions?.length > 0) {
        <li #selectAll>
          <a [class.arabic-style]="isArabicLanguage" class="select_all text-decoration-none"
            title="{{isAllSelected ? 'Click to unselect archive option(s)' : translateAdvancedSearch.selectAll }}"
            (click)="toggleSelectAll()">{{isAllSelected ? 'Unselect All' : 'Select All' }}
          </a>
        </li>
      }
      @for (option of dropDownOptions; track option; let i = $index) {
        <li>
          @if (!optionHTML) {
            <a [class.arabic-style]="isArabicLanguage" title="{{'AdvancedSearch.selectOption' | translate}}" [attr.data-item]="i" class="small small-select text-decoration-none" tabIndex="-1" [ngClass]="{'selected-option': selectedOptions.indexOf(option[valueKey]) > -1}" (click)="onOptionClick(option,templateConfirmPopup)">
              {{option[textKey]}}
            </a>
          }
        </li>
      }
    </ul>
  </div>
}