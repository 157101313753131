import { Injectable } from '@angular/core';

import * as L from 'leaflet';
// import * as HeatmapOverlay from 'leaflet-heatmap';
import * as MarkerCluster from 'leaflet.markercluster';

import { MAP_CONFIG } from './models/map.config';
import { FacetService } from './../../../../common/services/facet.service';
import { MAP_TYPE } from './models/map-type.constant';
import { MapData } from './models/map-data.modal';

import { get as _get } from 'lodash';
// import { SearchService } from '../../../../common/services/search.service';
declare const HeatmapOverlay: any;

@Injectable()
export class MapService {
    private heatData: MapData[];
    // private marker: any;
    private map: L.map;
    private markers: L.marker[] = [];

    private markerGroup: MarkerCluster.MarkerClusterGroup;
    // private heatmapLayer: HeatmapOverlay;
    heatmapLayer: any;

    private _valueField: string;
    private layerGroup = L.layerGroup();

    set valueField(value: string) {
        this._valueField = value;
    }
    get valueField(): string {
        return this._valueField || MAP_CONFIG.DEFAULT_FIELDS.VALUE;
    }

    constructor(private facetService: FacetService,
        //  private searchService: SearchService
        ) { }

    createHeatMap(map: L.map) {
        const cfg = {
            // radius should be small ONLY if scaleRadius is true (or small radius is intended)
            // if scaleRadius is false it will be the constant radius used in pixels
            radius: 1,
            maxOpacity: 0.8,
            minOpacity: 0.5,
            // scales the radius based on map zoom
            scaleRadius: true,
            minZoom: MAP_CONFIG.MIN_ZOOM,
            maxZoom: MAP_CONFIG.MAX_ZOOM,
            // if set to false the heatmap uses the global maximum for colorization
            // if activated: uses the data maximum within the current map boundaries
            //   (there will always be a red spot with useLocalExtremas true)
            useLocalExtrema: false,
            latField: MAP_CONFIG.DEFAULT_FIELDS.LAT,
            lngField: MAP_CONFIG.DEFAULT_FIELDS.LON,
            valueField: this.valueField || MAP_CONFIG.DEFAULT_FIELDS.VALUE
        };

        this.map = map;

        // create heat map layer
        this.heatmapLayer = new HeatmapOverlay(cfg);
        this.map.addLayer(this.heatmapLayer);

        // create marker group
        this.markerGroup = new MarkerCluster.MarkerClusterGroup();
        this.map.addLayer(this.markerGroup);

        return this.heatmapLayer;
    }

    drawHeatMapOverlay() {
        const max = this.getMax();
        // const defaultRadius = 40;
        const scale = Math.pow(2, this.map.getZoom());

        this.heatData
            .forEach(data => {
                const latLon = [data.lat, data.lon];
                let overlay;
                if (data.type === MAP_TYPE.CLUSTER) {
                    const radius = (data[this.valueField] / max.count) * scale;
                    overlay = L.circle(latLon, {
                        color: '',
                        fillColor: '',
                        fillOpacity: 0,
                        radius: Math.max(900 * scale, radius * 1200)
                    });
                    overlay.addTo(this.map);
                } else {
                    overlay = L.circle(latLon, {
                        color: '',
                        fillColor: '#2c44a2',
                        fillOpacity: 0.5,
                        radius: 100000
                    }).addTo(this.map);
                    /*
                        // commenting this as rajeev suggested not to show marker for sigle cluster
                        // create marker
                        overlay = L.marker(latLon).addTo(this.map);

                        this.markerGroup.addLayer(overlay);
                        this.markers.push(overlay);
                    */
                }

                // this.attachPopup(overlay, data);
            });
    }

    attachPopup(overlay: any, data: MapData): L.popup {
        const popup = overlay.bindPopup().getPopup();
        let title: string;

        overlay.on('click', (e) => {
            popup.setContent('<small>Loading...</small>');
            if (overlay.getBounds) {
                const bound = overlay.getBounds().toBBoxString();
                const param: any = bound.split(',');
                param.push(e.target.type);
                param.push(e.target.options.radius);
                this.facetService.getFacetByLatLonRange.apply(this.facetService, param);
                title = MAP_TYPE.CLUSTER;
            } else {
                const radius = overlay.getRadius ? overlay.getRadius() : 1;
                this.facetService.getFacetByLatLon(data.lat, data.lon, radius);
                title = radius === 1 ? MAP_TYPE.SINGLE : MAP_TYPE.CLUSTER;
            }
            FacetService.facetObservable.subscribe((facetData) => {
                    popup.setContent(this.getPopupTemplate(title, facetData));
                });
        });

        overlay.on('popupclose', () => {
            if (FacetService.facetObservable) {
                FacetService.facetObservable.next([]);
            }
        });

        return popup;
    }

    getPopupTemplate(title: string, facetData: any): string {
        let template = [`<div>
            <div class="popup-title">${title}</div>
            <ul>`];

        template = template.concat(facetData.map((content) => {
                return `<li>${content.count} ${content.value}</li>`;
            }).join(''));

        if (!facetData.length) {
            template.push(`<li><small>No Results found...</small></li>`);
        }

        template.push('</ul></div>');

        return template.join('');
    }

    setHeatMapData(heatData) {
        this.heatData = heatData || [];
        const max = this.getMax().count;

        this.heatmapLayer.setData({
            max,
            data: this.getHeatMapData()
        });
        this.drawHeatMapOverlay();
    }

    getHeatMapData(): any[] {
        return (this.heatData || [])
            .filter((data) => {
                return data.type === MAP_TYPE.CLUSTER;
            });
    }

    getMax() {
        let maxCount = 0;
        const initMax = { lat: 0, lon: 0, count: 0 };

        return this.getHeatMapData()
            .reduce((max, mapVal) => {
                if (mapVal.count > maxCount) {
                    maxCount = mapVal[this.valueField];
                    max.lat = mapVal.lat;
                    max.lon = mapVal.lon;
                    max.count = maxCount;
                }
                return max;
            }, initMax);
    }

    reset() {
        // clear heatmap layer
        if (this.heatmapLayer) {
            this.heatmapLayer.setData({
                data: []
            });
        }

        // clear marker layer
        if (this.markerGroup) {
            this.markerGroup.clearLayers();
            this.markers.forEach(marker => this.map.removeLayer(marker));
            this.markers = [];
        }
    }

    // getFacetDataForShapes(event) {
    // }

    createMarker(coordinates: any) {
        this.clearMarkers();
        const layerGroup = this.layerGroup.addTo(this.map);
        // const myIcon = L.divIcon({ className: 'my-div-icon'});
        delete L.Icon.Default.prototype._getIconUrl;

            L.Icon.Default.mergeOptions({
            iconRetinaUrl:'assets/images/icons/marker-icon-2x.png',
            iconUrl: 'assets/images/icons/marker-icon.png',
            shadowUrl: 'assets/images/icons/marker-shadow.png',
            });
        const myIcon = new L.Icon.Default();
        for (let i = 0; i < coordinates.length; i++) {
            const marker = L.marker([coordinates[i][0], coordinates[i][1]], { icon: myIcon });
            layerGroup.addLayer(marker);
            this.markers.push(marker);
        }

        // const overlay = { 'markers': layerGroup };
        // L.control.layers(null, overlay).addTo(this.map);
    //     const myIcon = L.divIcon({ className: 'my-div-icon'});
    //     const overlay = L.marker(latLon, { icon: myIcon }).addTo(this.map);
    //     this.marker = this.markerGroup.addLayer(overlay);
    //     this.markers.push(overlay);
    }
    // clear marker layer
    clearMarkers() {
        if (this.layerGroup) {
            this.layerGroup.clearLayers();
            this.markers.forEach(marker => this.map.removeLayer(marker));
            this.markers = [];
        }
    }

}
