
import {takeUntil} from 'rxjs/operators';
/* Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved */
import { Component, OnInit, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { some as _some } from 'lodash';
import { TermGroupService } from './term-group.service';
import { SharedService } from '../../common/services/sharedService';
import { PageSettingsService } from '../../common/services/page-settings.service';
import { Subscription ,  Subject } from 'rxjs';
import { CommonService } from '../services/common.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';

@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule, TabsModule],
  selector: 'app-term-groups',
  templateUrl: './term-groups.component.html',
  styleUrls: ['./term-groups.component.css'],
  providers: [TermGroupService]
})
export class TermGroupsComponent implements OnInit, AfterViewInit, OnDestroy {
  ENVArchiveFullName = 'Environmental Science and History';
  archivefullName = 'British Association for the Advancement of Science (Collections on the History of Science: 1830 - 1970)';
  showRelevanceHelp = false;
  documentHistory: any;
  visitedDocList = [];
  lang: string;
  isArabicLanguage = false;
  isLimiterChanged = false;
  togglesearchicon: any[] = [];
  config: any;
  _filterInputs: any;
  groups: any[];
  formTreeTabIndex: Number = 0;
  unSubPageSettingsObservable: Subscription;
  pageContentData: any;
  unsubClusterDetailsData: Subscription;
  dataLoader = false;
  detailsLoader = false;
  newFilterParams: any;
  showHelpContainer = false;
  @Input('filterInputs')
  set filterInputs(value) {
    this._filterInputs = value;
    if (this._filterInputs.preventLimiters) {
      this._filterInputs.filterParams.filterParams = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
    }
    this.checkIsLimitedDocs(this._filterInputs['filterParams']);
  }
  // stop$: Subject<any> = new Subject();
  stop$: Subject<void> = new Subject<void>();
  constructor(
    public termGroupService: TermGroupService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private cdRef: ChangeDetectorRef,
    ) {
      const index = sessionStorage.getItem('TGTabSelection');
      if (index) {
        this.formTreeTabIndex = +index;
      }
    }


  ngOnInit() {
    /**
     * Receive language changes and if it arabic then enable the flag
     */
    this.sharedService.language.pipe(
    takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });
    /** print foam tree object */
    this.commonService.printFoamTree.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res.option === 'print') {
        if (this.formTreeTabIndex) {
          this.termGroupService.printCircle(res.filterParams);
        } else {
          this.termGroupService.printFoamTree(res.filterParams);
        }
      } else {
        if (this.formTreeTabIndex) {
          this.termGroupService.downloadCircle(res.filterParams);
        } else {
          this.termGroupService.downloadFoamTree(res.filterParams);
        }
      }

    });
  }

  ngAfterViewInit(): void {
    const currentLanguage = sessionStorage.getItem('lang');
    this.lang = currentLanguage;
    this.groups = [];
    if (!this.dataLoader) {
      this.dataLoader = true;
      this.cdRef.detectChanges();
    }
    this.newFilterParams = this._filterInputs.filterParams.filterParams;
    if (this.newFilterParams.length > 0) {
      for(let k =0; k < this.newFilterParams.length; k++) {
        if(this.newFilterParams[k].fieldName === "archive") {
         const getSelectedArchives = localStorage.getItem('wileySelectedArchive');
         const haveRGS = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RGS'); 
         if (haveRGS) {
          const haveRGS1 = getSelectedArchives.includes('RGS1');
          if(haveRGS1) {
            this.newFilterParams[k].fieldValues.push('RGS1');
          }
          const haveRGS2 = getSelectedArchives.includes('RGS2');
          if(haveRGS2) {
            this.newFilterParams[k].fieldValues.push('RGS2');
          }
          this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RGS',this.newFilterParams[k].fieldValues);
         }
         const haveRCP = this.newFilterParams[k].fieldValues && this.newFilterParams[k].fieldValues.includes('RCP'); 
         if (haveRCP) {
          const haveRCP1 = getSelectedArchives.includes('RCP1');
          if(haveRCP1) {
            this.newFilterParams[k].fieldValues.push('RCP1');
          }
          const haveRCP2 = getSelectedArchives.includes('RCP2');
          if(haveRCP2) {
            this.newFilterParams[k].fieldValues.push('RCP2');
          }
          this.newFilterParams[k].fieldValues = this.removeSelectedValFromArray('RCP',this.newFilterParams[k].fieldValues);
         }
        }
      }
      if(this.newFilterParams) {
        this._filterInputs.filterParams.filterParams = this.newFilterParams;
      }
    }
    this.termGroupService.getClusterData(this._filterInputs.filterParams, this.formTreeTabIndex, this._filterInputs['terms']);
    this.unSubPageSettingsObservable = PageSettingsService.pageSettingsObservable.pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.pageContentData = data;
    }, err => {});
    this.unsubClusterDetailsData = this.sharedService.clusterDetailsData.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.groups = res;
    });
    this.termGroupService.resetToggle.pipe(takeUntil(this.stop$)).subscribe(res => {
      this.togglesearchicon = [];
    });
    TermGroupService.dataLoader.pipe(takeUntil(this.stop$)).subscribe((res: boolean) => {
      this.dataLoader = res;
    });
    TermGroupService.detailsLoader.pipe(takeUntil(this.stop$)).subscribe((res: boolean) => {
      this.detailsLoader = res;
    });

    setTimeout(() => {
      if (localStorage.getItem('detailsHistory')) {
        this.documentHistory = JSON.parse(localStorage.getItem('detailsHistory'));
        this.documentHistory.forEach(doc => {
          this.visitedDocList.push(doc.id);
        });
      }
    });
  }
  removeSelectedValFromArray(val, arr) {
    let value = val;
    arr = arr.filter(item => item !== value)
    return arr;
  }

  /**
   * @description: Check if the limiter is changed or not
   * @param filterParams :filterParams values
   */
  checkIsLimitedDocs(filterParams) {
    this.groups = [];
    this.isLimiterChanged = _some(filterParams.filterParams, res => {
      return ((res.fieldName === 'contentType') || (res.fieldName === 'contentType') || (res.fieldName === 'collection')
      || (res.fieldName === 'sourceInstitution') || (res.fieldName === 'docLang') || (res.fieldName === 'pubStartDate'));
    });
  }

  /**
   * @description: Activate circle tab
   */
  circleActivated() {
    this.formTreeTabIndex = 1;
    sessionStorage.setItem('TGTabSelection', this.formTreeTabIndex.toString());
    // circle initlization
    setTimeout(() => {
      // this.groups = [];
      this.termGroupService.initCircles();
    }, 50);
  }

  /**
   * @description :Activate Foamtree tab
   */
  foamTreeActivated() {
    this.formTreeTabIndex = 0;
    sessionStorage.setItem('TGTabSelection', this.formTreeTabIndex.toString());
    setTimeout(() => {
      // this.groups = [];
      this.termGroupService.initFoamTree();
    }, 50);
  }

  /**
   * @description :toggle accordian
   * @param index :index of the group item
   */
  checkAccordionStatus(index) {
    this.togglesearchicon[index] = !this.togglesearchicon[index];
  }

  /**
   * @description :reset all the data and visualization
   */
  resetAll() {
    this.groups = [];
    this.togglesearchicon = [];
    this.termGroupService.resetVisualization();
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
    if (this.unSubPageSettingsObservable) {
      this.unSubPageSettingsObservable.unsubscribe();
    }
    if (this.unsubClusterDetailsData) {
      this.unsubClusterDetailsData.unsubscribe();
    }
  }
  openRelevanceHelp(event) {
    this.showRelevanceHelp = !this.showRelevanceHelp;
    if (this.showRelevanceHelp) {
      this.showHelpContainer = true;
    } else {
      this.showHelpContainer = false;
    }
  }
  gotoMapsExplorer(docID) {
    const CurrentURL = window.location.href;
    sessionStorage.setItem('isMapsWithGeoCoordiates', 'true');
    const data = {};
    data['searchTerm'] = docID;
    // this.router.navigate(['../explorer/map', data], { relativeTo: this.activatedRoute });
  }
  imgsliderToggle(event) {

  }
  callDocViewerFromOutput(event) {

  }
}
