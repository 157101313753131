/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from '../services/http.service';
import { environment, APIURLS } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { map as _map } from 'lodash';

@Injectable()
export class UtilityService {

    public locationConfig = new Subject<any> ();
    private configValue: any;

    constructor(
        private httpService: HttpService,
        private title: Title
     ) {
        this.httpService.get(APIURLS['config'], (res) => {
            this.configValue = res;
            this.locationConfig.next(this.configValue);
        }, err => {});
     }

    // public htmlToStr(str: any) {
    //    if (str === null || str === '') {
    //        return false;
    //     } else {
    //         str = str.toString();
    //     }
    //     return str.replace(/<[^>]*>/g, '');
    // }

    public sendToMailClient(subject: any, content: any, to?: any , from?: String): Promise<any> {
      const params: Object = {
        to: to,
        subject: subject,
        fromAdd: from,
        content: `<html><head><title>Wiley</title></head><body>
                  <pre style="font-size:14px;">${content}</pre><br>
                  </body></html>`,
       };
        return this.httpService.post(environment.APIUrl + APIURLS['sendToMailClient'], params, (response) => {
        }, (err) => {}, 'text');
    }

    public configValues() {
        if (this.locationConfig) {
            return this.locationConfig.next(this.configValue);
        }
    }

    public setTitle(title) {
        this.title.setTitle(title);
    }

}
