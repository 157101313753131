import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WordCloudDirective } from '../../directives/word-cloud';
import { SliderDirective } from '../../directives/slider';
import { ImageSwipeSliderComponent } from '../../../image-swipe-slider/image-swipe-slider.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    WordCloudDirective,
    SliderDirective,
    ImageSwipeSliderComponent
  ],
  exports: [WordCloudDirective, SliderDirective, ImageSwipeSliderComponent]
})
export class SharedModule { }
