/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */
export class ImageSource {
    imageId: number;
    imageURL: string;
    thumbURL: string;
    overlays: Array<Overlay>;
    isRelevant: boolean;
    title: String;
    pageNumber: number;

    constructor(id, thumbUrl, imageUrl, overlays: Array<Overlay>, isRelevant: boolean = false, title?: String, pageNumber: number = null) {
        this.imageId = id;
        this.imageURL = imageUrl;
        this.thumbURL = thumbUrl;
        this.overlays = overlays;
        this.isRelevant = isRelevant;
        this.title = title || 'Untitled';
        this.pageNumber = pageNumber;
    }
}

export class Overlay {
    width: number;
    height: number;
    xPos: number;
    yPos: number;
    type: string;
    tableId: any;
    keyword: String;
    cc: any;
    constructor(width = null, height = null, x = null, y = null, type?, tableId?, keyword?, cc?) {
        this.width = width;
        this.height = height;
        this.xPos = x;
        this.yPos = y;
        this.type = type;
        this.tableId = tableId;
        this.keyword = keyword;
        this.cc = cc;
    }
}
