import {
    Component,
    ViewChild,
    Input,
    SimpleChanges,
    OnChanges,
    AfterViewInit,
    ViewEncapsulation,
    Output,
    EventEmitter,
    OnDestroy} from '@angular/core';

import * as L from 'leaflet';
import { MAP_CONFIG } from './models/map.config';
import { MapData } from './models/map-data.modal';
import { MapService } from './map.service';
import { SearchService } from './../../../../common/services/search.service';


import { get as _get } from 'lodash';
import { SharedService } from './../../../../common/services/sharedService';
import { PageSettingsService } from './../../../../common/services/page-settings.service';
import { isArray } from 'util';
import { TranslateModule } from '@ngx-translate/core';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare let $: any;
@Component({
    selector: 'app-map',
    standalone:true,
    imports:[TranslateModule, LeafletModule,LeafletDrawModule],
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [MapService]
})
export class MapComponent implements AfterViewInit, OnChanges, OnDestroy {
    _isFromSearchResults: any;
    facetResultSet: any;
    layer: any;
    private popup: any;
    private map: L.map;
    private featureGroup: L.featureGroup;
    private _heatData: MapData[];
    pointerEvent: Boolean = true;
    @Output('spatialSearchEmits') private spatialSearchEmits: EventEmitter<any>;
    isControlClicked = true;
    @Output() messageToMapExplorer = new EventEmitter<Boolean>();
    @Output() deleteEvent = new EventEmitter<any>();
    polygonLayers: any = [];
    stop$: Subject<void> = new Subject();
    

    @Input('removeDropPin')
    public set removeDropPin(value: string) {
        this.removePolygon();
    }

    @Input()
    set heatData(value: MapData[]) {
        this._heatData = value;
    }
    get heatData(): MapData[] {
    //   if (this._heatData.length) {
    //     // this.pointerEvent = false;
    //   }
        return this._heatData || [];
    }

    @Input()
    set valueField(value: string) {
        this.heatMapService.valueField = value;
    }
    get valueField(): string {
        return this.heatMapService.valueField;
    }

    @Input()
    set resetMapData(arg: any) {
        this.resetMap();
    }

    private resultsCount: any;
    @Input()
    set resultCount(val: any) {
        if (val) {
            this.resultsCount = val.value;
            this.setResultCountToolTip();
        }
    }

    @Input() componentName: any;

    @Input()
    set zoomToCoOrdinates(val: any) {
        if (val) {
            if (val.coordinates.length > 0) {
                this.zoomMap(val.coordinates);
            } else {
                this.heatMapService.clearMarkers();
            }

        }

    }

    @Input()
    set isFromSearchResults(val) {
        this._isFromSearchResults = val;
    }
    get isFromSearchResults() {
        return this._isFromSearchResults;
    }
    // @ViewChild('mapCanvas') mapCanvas;
    @ViewChild('mapCanvas', { static: false }) mapCanvas;

    polygonDetails: any;
    isMapDraw: Boolean = false;
    constructor(
        private heatMapService: MapService,
        private searchService: SearchService,
        private sharedService: SharedService,
        private pageSettingService: PageSettingsService
    ) {
        this.spatialSearchEmits = new EventEmitter();
    }

    ngAfterViewInit() {
        this.initMap();
        this.sharedService.heatMapZoomView.pipe(takeUntil(this.stop$)).subscribe((data) => {
            this.zoomMap(data);
        })
        /* TODO change adv params to search param*/
        this.sharedService.mapRequestParams(PageSettingsService.pageSettings.advancedFilterParam);
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.heatData && this.map) {
            this.initMap();
        }
    }

    initMap() {
        this.resetMap();

        if (!this.map) {
            this.createMap();
        }

        this.heatMapService.setHeatMapData(this.heatData);
        this.resetPosition();
    }

    resetMap() {
        if (this.popup) {
            this.popup.setContent('');
        }
        if (this.map) {
            this.heatMapService.reset();

            if (this.featureGroup) {
                this.featureGroup.clearLayers();
                this.heatMapService.clearMarkers();
            }
        }
    }

    createMap() {
        const baseLayer = L.tileLayer(MAP_CONFIG.BASE_MAP_URL, {
            attribution: MAP_CONFIG.ATTRIBUTION,
            maxZoom: MAP_CONFIG.MAX_ZOOM,
           // noWrap: true,
        });

        this.featureGroup = new L.FeatureGroup();
        this.map = new L.Map(this.mapCanvas.nativeElement, {
            center: new L.LatLng(0, 0),
            zoom: 2,
            maxZoom: 18,
            minZoom: 2,
            zoomControl: false,
            layers: [baseLayer, this.featureGroup]
        });
        this.map.options.minZoom = MAP_CONFIG.MIN_ZOOM;
        this.map.options.maxZoom = MAP_CONFIG.MAX_ZOOM;
        this.map.options.zoom = MAP_CONFIG.ZOOM;
        this.heatMapService.createHeatMap(this.map);

        this.createControls();
    }

    createControls() {
        const zoomControl = L.control.zoom({
            position: MAP_CONFIG.CONTROL_POSITION
        });

        this.map.addControl(zoomControl);
        this.createDrawingControls();
    }
    createDrawingControls() {
        const controlOptions = {
            position: 'topright',
            draw: {
                polyline: false,
                polygon: false,
                rectangle: true,
                circle: true,
                circlemarker: false,
                marker: false
            }, edit: {
                featureGroup: this.featureGroup,
                remove: true
            }
        };
        const drawControl = new L.Control.Draw(controlOptions);
        this.map.addControl(drawControl);
        this.map.on(L.Draw.Event.DRAWSTART, (e) => {
            this.sendMessage();
            this.featureGroup.clearLayers();
        });
        this.map.on(L.Draw.Event.CREATED, (e) => {
            this.layer = e.layer;
            this.layer.type = e.layerType;
            const drawnLayerData = {
                                    'type': this.layer.type,
                                    'latlng': null,
                                    'radius': null,
                                    'northEast': {
                                                    'lat': null,
                                                    'lng': null
                                                },
                                    'southWest': {
                                                    'lat': null,
                                                    'lng': null
                                                }
                                    };
            if (this.layer.type === 'circle') {
                drawnLayerData.latlng = this.layer._latlng;
                drawnLayerData.radius = this.layer.options.radius;
                this.isMapDraw = true;
            } else if (this.layer.type === 'rectangle') {
                drawnLayerData.northEast.lat = this.layer._bounds._northEast.lat;
                drawnLayerData.northEast.lng = this.layer._bounds._northEast.lng;
                drawnLayerData.southWest.lat = this.layer._bounds._southWest.lat;
                drawnLayerData.southWest.lng = this.layer._bounds._southWest.lng;
                this.isMapDraw = true;
            }
            // this.spatialSearchEmits.emit(drawnLayerData);
            const geo = e.layer.toGeoJSON();
            const data = new MapData();
            this.popup = this.layer.bindPopup().getPopup();
            this.popup.setContent('<small>Loading...</small>');
            data.lat = _get(geo, 'coordinates[0]');
            data.lon = _get(geo, 'coordinates[1]');

            this.featureGroup.addLayer(this.layer);
            if (this._isFromSearchResults) {
                this.popup.setContent('');
                this.searchService.spatialSearch(drawnLayerData);
                SearchService.spatialSearchFacetObservable.pipe(takeUntil(this.stop$)).subscribe((facetResultData) => {
                    this.facetResultSet = facetResultData;
                    if (facetResultData) {
                        this.popup.setContent('<small>Loading...</small>');
                        if (facetResultData.results) {
                            facetResultData.results.facetResults.forEach(obj => {
                                if (obj.facetField === 'contentType') {
                                    let contentTypeUl: any = '<ul>';
                                    if (obj.facetContent.length > 0) {
                                        obj.facetContent.forEach(facetContent => {
                                            contentTypeUl += `<li>${facetContent.value} : ${facetContent.count}</li>`;
                                        });
                                    } else {
                                        contentTypeUl += `<li>No Results found.</li>`;
                                    }
                                    contentTypeUl += '</ul>';
                                    this.popup.setContent(contentTypeUl);
                                }
                            });
                        }
                    }
                });
            } else {
                this.mapCoordinatesToSpacialParams(drawnLayerData);
            }
            this.layer.fire('click');
        });
        const classObject = this;
        this.featureGroup.on('click', function (event) {
            classObject.layer = event.layer;
            classObject.popup = classObject.layer.bindPopup().getPopup();
            if (classObject.facetResultSet) {
                classObject.popup.setContent('<small>Loading...</small>');
                if (classObject.facetResultSet.results) {
                    classObject.facetResultSet.results.facetResults.forEach(obj => {
                        if (obj.facetField === 'contentType') {
                            let contentTypeUl: any = '<ul>';
                            if (obj.facetContent.length > 0) {
                                obj.facetContent.forEach(facetContent => {
                                    contentTypeUl += `<li>${facetContent.value} : ${facetContent.count}</li>`;
                                });
                            } else {
                                contentTypeUl += `<li>No Results found.</li>`;
                            }
                            contentTypeUl += '</ul>';
                            classObject.popup.setContent(contentTypeUl);
                        }
                    });
                }
            }
            classObject.layer.fire('click');
            });
    }

    resetPosition() {
        const latLonList = this.heatData.map((data) => {
            return [data.lat, data.lon];
        });
        let matPos: Array<any> = [];
        if (this._isFromSearchResults) {
        matPos = this.searchService.loadMapPos ? this.searchService.loadMapPos : '';
        }
        if (latLonList.length) {
            const bounds = new L.LatLngBounds(latLonList);

            this.map.fitBounds(bounds);
            this.map.invalidateSize();
        }
        if (matPos.length > 0) {
            if (matPos[0] && matPos[1]) {
                if (matPos[0].indexOf(',') >= 0) {
                    this.map.setView(matPos[0].split(','), 6);
                } else {
                    this.map.setView([matPos[0], matPos[1]], 6);
                }
            }
        }
    }

    deleteAllDrawnLayers() {
        if (this.isMapDraw) {
            // this.heatMapService.clearMarkers();
            this.featureGroup.clearLayers();
            if (this._isFromSearchResults) {
                this.pageSettingService.updateSearchSettings(PageSettingsService.pageSettings.advancedFilterParam);
                this.sharedService.mapRequestParams(PageSettingsService.pageSettings.advancedFilterParam);
                this.searchService.spatialSearch('');
            }
            this.isMapDraw = false;
            this.mapCoordinatesToSpacialParams('');
        }
        $('.browsemap_icon').addClass('defaultColor');
        this.heatMapService.clearMarkers();
        this.deleteEvent.emit(Math.random());
        this.polygonLayers.forEach(el => {
            this.map.removeLayer(el);
        });
    }

    zoomMap(val: any) {
        this.heatMapService.clearMarkers();
        if (this._isFromSearchResults) {
            if (isArray(val)) {
                this.map.setView((<string>val[0]).split(','), 6);
                setTimeout(() => {
                    const arry = [];
                    for (let i = 0; i < val.length; i++) {
                        arry.push((<string>val[i]).split(','));
                    }
                    this.heatMapService.createMarker(arry);
                }, 1000);
            }
        } else {
            if (this.polygonDetails && this.map) {
                this.polygonLayers.forEach((el: any) => {
                    this.map.removeLayer(el);
                });
                this.polygonLayers = [];
            }
            if (val) {
                val.forEach(element => {
                    this.drawPolygon(element);
                });
            }
        }
    }

    mapCoordinatesToSpacialParams(spatialSearchParams) {
        if (this.popup) {
            this.popup.setContent('Loading ...');
        }
        let params: any = [];
        if (spatialSearchParams.type === 'circle') {
            params = {
                'spatialFilterParams': [{
                    'fieldName': 'latlon',
                    'shape': spatialSearchParams.type,
                    'radius': (spatialSearchParams.radius / 1852),
                    'location': {
                        'lat': spatialSearchParams.latlng.lat,
                        'lon': spatialSearchParams.latlng.lng
                    }
                }
                ]
            };
        } else if (spatialSearchParams.type === 'rectangle') {
            params = {
                'spatialFilterParams': [{
                    'fieldName': 'latlon',
                    'shape': spatialSearchParams.type,
                    'lowerLeft': {
                        'lat': spatialSearchParams.southWest.lat,
                        'lon': spatialSearchParams.southWest.lng,
                    },
                    'topRight': {                    // Rectangle top right latitude and longitude
                        'lat': spatialSearchParams.northEast.lat,
                        'lon': spatialSearchParams.northEast.lng
                    }
                }
                ]
            };
        }
        this.spatialSearchEmits.emit(params);
    }

    setResultCountToolTip() {
        let contentTypeUl: any = '<ul>';
        contentTypeUl += `<li> ${this.resultsCount} ${this.componentName || 'Map'}(s) Found.</li>`;
        contentTypeUl += '</ul>';
        if (this.popup) {
            this.popup.setContent(contentTypeUl);
        }
    }


    drawPolygon(val) {
        this.polygonDetails = new L.Polygon([val]);
        if (this.polygonDetails) {
            this.polygonDetails.setStyle({
                fillColor: '#DDA0DD',
                weight: 2,
                opacity: 0.5,
                color: '#DA70D6',
                fillOpacity: 0.3,
                zoomAnimation: true,
                dashArray: '5, 5'
            });
            if (this.map) {
                this.polygonDetails.addTo(this.map);
            }
            const centerPoint = this.polygonDetails.getBounds().getCenter();
            const layerPerimeter = (this.polygonDetails.getBounds()
                                        ._northEast.distanceTo(this.polygonDetails
                                        .getBounds()._southWest) / 1000);
            let zoomLevel = 3;
            // console.log(layerPerimeter);
            if (layerPerimeter <= 1) {
                zoomLevel = 16;
            } else if (layerPerimeter <= 5) {
                zoomLevel = 14;
            } else if (layerPerimeter <= 20) {
                zoomLevel = 12;
            } else if (layerPerimeter <= 50) {
                zoomLevel = 10;
            } else if (layerPerimeter <= 100) {
                zoomLevel = 9;
            } else if (layerPerimeter <= 300) {
                zoomLevel = 8;
            } else if (layerPerimeter <= 500) {
                zoomLevel = 7;
            } else if (layerPerimeter <= 1000) {
                zoomLevel = 6;
            } else if (layerPerimeter <= 2500) {
                zoomLevel = 4;
            } else if (layerPerimeter <= 5000) {
                zoomLevel = 3;
            } else if (layerPerimeter <= 7500) {
                zoomLevel = 2;
            } else if (layerPerimeter <= 10000) {
                zoomLevel = 1;
            } else if (layerPerimeter <= 15000) {
                zoomLevel = 0.75;
            }

            if (centerPoint.lat && centerPoint.lng && this.map) {
                this.map.setView([centerPoint.lat, centerPoint.lng], zoomLevel);
            }
            this.polygonLayers.push(this.polygonDetails);
        }
    }

    removePolygon() {
        if (this.polygonDetails) {
            this.polygonLayers.forEach(el => {
                this.map.removeLayer(el);
            });
        }
    }
    sendMessage() {
        this.messageToMapExplorer.emit(this.isControlClicked);
      }
    ngOnDestroy(): void {
        this.stop$.next();
        this.stop$.complete();
    }
}
