
import { takeUntil } from 'rxjs/operators';
/* Copyright(C) 2018 HTC Global Services, Inc. - All Rights Reserved */
import {
  Component, OnInit, OnDestroy, ViewChild, HostListener, Input, ElementRef, ChangeDetectorRef
} from '@angular/core';
import { PageSettingsService } from '../../common/services/page-settings.service';
import { CommonService } from '../../common/services/common.service';
import { WordCloudService } from './word-cloud.service';
import { Subject } from 'rxjs';
import { SharedService } from '../../common/services/sharedService';
import { ModalService } from '../../common/services/modal.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../common/modules/shared/shared.module';
declare let $: any;
declare let html2canvas: any;
// const SliderValue = 500;

@Component({
  standalone: true,
  imports: [SharedModule,TranslateModule, CommonModule],
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.css'],
  providers: [WordCloudService]
})
export class WordCloudComponent implements OnInit, OnDestroy {
  currentArchiveName: string;
  currentAvailableArchives: string;
  showArchivesDropDownList: Boolean = false;
  // @ViewChild('wordcloudText', { read: ElementRef }) wordcloudText: ElementRef;
  @ViewChild('wordcloudText', { static: false, read: ElementRef }) wordcloudText: ElementRef;

  lang: string;
  _filterInputs: any;
  locationConfigArchives: any;
  showLimitorLabel: boolean;
  resultSet: any = {};
  sliderValue = 500;
  wordCloudData: any = {};
  maxWordCount: number;
  updateWordCloud = { isUpdate: false, random: 0 };
  showWordCloudLoader: Boolean;
  noResultsFound: Boolean = true;
  newData: any;
  newArchivesList = [];
  newArchivesLists = [];
  step = 50;
  minValue = 50;
  maxValue = 500;
  rangeArray: Number[] = [0, 0];
  isArabicLanguage: Boolean = false;
  _advancedFilterParam: any;
  worldcloudText: any = '';
  translatedValues: string;
  @Input('filterInputs')
  set filterInputs(value) {
    this._filterInputs = value;
    this._advancedFilterParam = this._filterInputs.advancedFilterParam;
    this._filterInputs['filterParams']['filterParams'].forEach(obj => {
      if (obj.fieldName === 'archive') {
        this.archives = obj['fieldValues'];
      }
    });
    if (this._filterInputs.preventLimiters) {
      this._filterInputs.filterParams.filterParams = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
    }
  }
   stop$: Subject<void> = new Subject<void>();
  // @ViewChild('canvas') canvas: ElementRef;
  // @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('downloadLink', { static: false }) downloadLink: ElementRef;

  archives: Array<String> = [];
  constructor(
    private pageSettingService: PageSettingsService,
    private commonService: CommonService,
    private wordCloudService: WordCloudService,
    private sharedService: SharedService,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private translate: TranslateService
  ) {
    this.translate.get('textAnalysisPage').pipe(takeUntil(this.stop$)).subscribe((res: string) => {
      this.translatedValues = res;
    });
  }

  ngOnInit() {
    this.validateArchive();
    const currentLanguage = sessionStorage.getItem('lang');
    this.lang = currentLanguage;
    if (currentLanguage) {
      if (currentLanguage.toLowerCase() === 'ar') {
        this.isArabicLanguage = true;
      }
    }
    this.sharedService.language.pipe(
      takeUntil(this.stop$)).subscribe(res => { (res === 'ar') ? (this.isArabicLanguage = true) : (this.isArabicLanguage = false); });

  }
  validateArchive() {
    this.commonService.getLocationConfig(sessionStorage.getItem('lang')).pipe(takeUntil(this.stop$)).subscribe(res => {
      this.locationConfigArchives = res['locations'];
      const locationArchives = Object.keys(this.locationConfigArchives);
      const accessRevokedArchives = [];
      locationArchives.forEach(archive => {
        if (!this.locationConfigArchives[archive].isWordCloudAvailable) {
          accessRevokedArchives.push(archive);
        }
      });
      const archives = this.archives.filter(archive => {
        return !accessRevokedArchives.includes(archive);
      });
      if (archives.length === 0) {
        this.noResultsFound = false;
      } else {
        if (this._filterInputs.filterParams.filterParams.length > 1) {
          this.callWordCloud();
        } else {
          this.callWordCloud('init');
        }
      }
    });
  }
  callWordCloud(param?) {
    this.currentArchiveName = this.pageSettingService.getPageSettings().currentArchive;
    this.showWordCloudLoader = true;
    const currentArchive = this.pageSettingService.getPageSettings().currentArchive;
    this.currentAvailableArchives = currentArchive;
    this.noResultsFound = true;
    const advParam = JSON.parse(JSON.stringify(this._filterInputs.filterParams));
    const accessibleArchives = [];
    const newFilterArray = this._filterInputs.filterParams.filterParams.filter(t => t.fieldName === 'archive');
    const newArchives = newFilterArray[0].fieldValues;
    this.newArchivesLists = [...newFilterArray[0].fieldValues];
    // if (this.newArchivesLists && this.newArchivesLists.length && this.newArchivesLists.includes('ENV')) {
    //   const fromIndex = this.newArchivesLists.indexOf("ENV");  // Finding the ENV archives
    //   const toIndex = this.newArchivesLists.length - 1;
    //   this.newArchivesList = this.moveElement(this.newArchivesLists, fromIndex, toIndex);  //  move the ENV archive to the last
    // } else {
      // Normal flow data
      this.newArchivesList = newFilterArray[0].fieldValues;
    // }
    // WPA and WPA1 archives data push to last 
    if (this.newArchivesLists && this.newArchivesLists.length && this.newArchivesLists.includes('WPA')) {
      const fromIndex = this.newArchivesLists.indexOf("WPA");  // Finding the WPA archives
      const toIndex = this.newArchivesLists.length - 1;
      this.newArchivesList = this.moveElement(this.newArchivesLists, fromIndex, toIndex);  //  move the WPA archive to the last
    }
    if (this.newArchivesLists && this.newArchivesLists.length && this.newArchivesLists.includes('WPA1')) {
      const fromIndex = this.newArchivesLists.indexOf("WPA1");  // Finding the WPA1 archives
      const toIndex = this.newArchivesLists.length - 1;
      this.newArchivesList = this.moveElement(this.newArchivesLists, fromIndex, toIndex);  //  move the WPA1 archive to the last
    }
    if (this.locationConfigArchives[currentArchive].isWordCloudAvailable) {
      for (let index = 0; index < newArchives.length; index++) {
        advParam.archive = [newArchives[index]];
        if (!this.resultSet[newArchives[index]]) {
          this.resultSet[newArchives[index]] = {};
        }
        this.resultSet[newArchives[index]]['isWordCloudAvailable'] = false;
        this.resultSet[newArchives[index]]['name'] = newArchives[index];
        try {
          if (this.locationConfigArchives[newArchives[index]].isWordCloudAvailable) {
            accessibleArchives.push(newArchives[index]);
            this.resultSet[newArchives[index]]['isNoResult'] = false;
            this.resultSet[newArchives[index]]['isWordCloudAvailable'] = true;
            if (param === 'init') {
              this.resultSet[newArchives[index]]['sliderValue'] = this.sliderValue;
            } else {
              if (!this.resultSet[newArchives[index]]['sliderValue']) {
                this.resultSet[newArchives[index]]['sliderValue'] = this.sliderValue;
              }
            }
            this.removeExistingWordcloud(newArchives[index]);
            this.resultSet[newArchives[index]]['sliderValue'] = this.sliderValue;
            // this.sliderValue = 500;
            this.resultSet[newArchives[index]]['loader'] = true;
            // tslint:disable-next-line:max-line-length
            this.wordCloudService.getWordCloudData([], advParam, param, newArchives[index], this.resultSet[newArchives[index]]['sliderValue'])
              .pipe(takeUntil(this.stop$)).subscribe(res => {
                this.wordCloudData[newArchives[index]] = res;
                setTimeout(() => {
                  this.processData(res, newArchives[index]);
                }, 500);
              }, err => {
                this.resultSet[newArchives[index]]['loader'] = false;
                this.resultSet[newArchives[index]]['isNoResult'] = true;
                this.showWordCloudLoader = false;
                this.noResultsFound = false;
              });
          }
        } catch (error) { }
      }
    }
    if (accessibleArchives.length === 0) {
      this.showWordCloudLoader = false;
    }
  }
  removeExistingWordcloud(archive: string) {
    if ($('#' + archive)) {
      $('#' + archive).empty();
    }
  }
  processData(response: any, archive: string) {
    const combinedResultarray = [];
    if (response) {
      if (response['analysisResponse']) {
        if (response.analysisResponse[0].rows) {
          this.maxWordCount = Math.max.apply(Math, response.analysisResponse[0].rows.map(function (o) { return o.count; }));
          this.resultSet[archive]['maxWordCount'] = this.maxWordCount;
        }
        response['analysisResponse'].forEach(element => {
          if (element.rows) {
            element.rows.forEach(elementRow => {
              combinedResultarray.push({
                'word': elementRow['term'], 'weight': elementRow['count'],
                maxWordCount: this.maxWordCount
              });
            });
          }
        });
        this.cdr.markForCheck();
      }
    }
    setTimeout(() => {
      this.resultSet[archive]['loader'] = false;
    }, 200);
    if (combinedResultarray.length === 0) {
      if (this.resultSet[archive]['isNoResult'] = false) {
        this.resultSet[archive]['isNoResult'] = true;
        this.cdr.detectChanges();
      }
      this.resultSet[archive]['data'] = combinedResultarray;
      this.updateWordCloud = { isUpdate: true, random: 1 };
    } else {
      combinedResultarray.forEach(res => {
        res.weight = Math.floor((res.weight / this.maxWordCount) * 50);
      });
      this.resultSet[archive]['data'] = combinedResultarray;
      this.updateWordCloud = { isUpdate: true, random: 1 };
    }
    this.newData = this.resultSet[archive]['data'];
  }
  wordCloudLoaded(event) {
    this.showWordCloudLoader = false;
    this.resultSet[event.archive]['loader'] = false;
  }
  receiveTerm(termObj) {
    sessionStorage.setItem('tatOldTerm', this._filterInputs['searchTerm']);
    this.wordCloudService.gotoSearchResultPage(termObj.term, this._filterInputs.filterParams);
    sessionStorage.setItem('isFromAnalysisHubPage', 'true');
  }
  receiveSliderValue(event: number, archive: string) {
    this.resultSet[archive]['sliderValue'] = event;
    const response = {
      analysisResponse: [{
        archive: `'${archive}'`,
        rows: []
      }]
    };
    if (this.wordCloudData[archive]['analysisResponse']) {
      if (this.wordCloudData[archive].analysisResponse[0].rows) {
        response.analysisResponse[0].rows =
          this.wordCloudData[archive].analysisResponse[0].rows.slice(0, this.resultSet[archive]['sliderValue']);
      }
      this.processData(response, archive);
    }
    if (this.wordcloudText) {
      if (this.wordcloudText.nativeElement) {
        this.wordcloudText.nativeElement.value = '';
      }
    }
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
    $('.wordcloud-parentdiv').empty();
  }

  downloadWordCloud(id, archive) {
    const element: any = document.getElementById(id);
    html2canvas(element).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = `word-cloud-${archive}.png`;
      this.downloadLink.nativeElement.click();
    });
  }
  findWordCloudWord(worldcloudText) {
    worldcloudText = worldcloudText.trim();
    if (worldcloudText) {
      $('.WordClass').removeClass('word-cloud-selected-term');
      if ($(`.WordClass:contains(${worldcloudText.toLowerCase()})`).length > 0) {
        $(`.WordClass:contains(${worldcloudText.toLowerCase()})`).addClass('word-cloud-selected-term');
      } else {
        // tslint:disable-next-line:max-line-length
        this.modalService.showNotification('WARNING', this.translatedValues['noResultFoundWordcloud'] || 'Entered Search term is not found inside word cloud.', '');
      }
    }
  }
  onSearchChange(term) {
    if (term.length === 0) {
      $('.WordClass').removeClass('word-cloud-selected-term');
    }
  }
  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (this.worldcloudText) {
      this.findWordCloudWord(this.worldcloudText);
    }
  }
  selectWordcloudBasedOnArchives() {
    this.showArchivesDropDownList = !this.showArchivesDropDownList;
  }
  toggleArchiveSelection(event, archive) {
    if (event.target.checked === true) {
      $(`#content-box${archive}`).show();
    } else {
      $(`#content-box${archive}`).hide();
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.elRef.nativeElement.contains(event.target)) {
      // this.showArchivesDropDownList = true;
    } else {
      this.showArchivesDropDownList = false;
    }
  }
  parsedText(text) {
    const dom = new DOMParser().parseFromString(
      '<!doctype html><body>' + text,
      'text/html');
    const decodedString = dom.body.textContent;
    return decodedString;
  }
  // move the ENV archive to the last
  moveElement(array, fromIndex, toIndex) {
    const element = array.splice(fromIndex, 1)[0];
    this.newArchivesLists.splice(toIndex, 0, element);
    return this.newArchivesLists;
  }
}
